import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertService, CallsService, DataService, KYBService } from './../../../_services';
import { DocumentManagerService } from './documentManager.service';
import { BrandingService } from '../../../branding/branding.service';
import { BrandingSetup } from '../../../branding/branding.model';
import {
  FormControl,
  Validators
} from '@angular/forms';

/**
 * Implements the user detail page
*/
@Component({
  moduleId: module.id,
  templateUrl: 'documentManager.component.html',
  // tslint:disable-next-line:component-selector
  selector: 'document-manager',
  styleUrls: ['./style.css']
})

export class DocumentManagerComponent implements OnInit {

  requiredDocuments: any[] = [];
  entities: any[] = [];
  documents: any[] = [];
  packages: any[] = [];
  fileSelected: boolean;
  stepper: any = '';
  alternateContactFormControl = new FormControl('', [Validators.required]);
  relationToIntituteFormControl = new FormControl('', [Validators.required]);
  overviewOfRelationFormControl = new FormControl('', [Validators.required]);
  nameAndPositionFormControl = new FormControl('', [Validators.required]);
  ownershipStructureFormControl = new FormControl('', [Validators.required]);
  overviewOfInstitutionFormControl = new FormControl('', [Validators.required]);
  anticipatedTransactionFormControl = new FormControl('', [Validators.required]);
  naturBusinessFormControl = new FormControl('', [Validators.required]);
  anticipatedAnnualRevenueFormControl = new FormControl('', [Validators.required]);
  sourceOfCapitalFormControl = new FormControl('', [Validators.required]);
  expectedTransactionTypeFormControl = new FormControl('', [Validators.required]);
  beneficalOwnershipFormControl = new FormControl('', [Validators.required]);
  documentFile: File;
  documentResponse: any;
  formData: any[] = [];
  documentId: any;
  currentLabelName: any = "Personal&nbsp;&nbsp;--->";
  document1: any;
  selectedTabIndex: any = 0;
  isveryfing = false;

  constructor(
    private callsService: CallsService,
    private route: ActivatedRoute,
    private alertService: AlertService,
    private dataService: DataService,
    private kybService: KYBService,
    private brandingService: BrandingService,
    private router: Router,
    private documentService: DocumentManagerService,
    private ref: ChangeDetectorRef
  ) {  }

  ngOnInit() {
    this.brandingService.brandingSetup.subscribe((res: BrandingSetup) => {
    if (!res) { return; }
    this.stepper = res.mainColor;
    });
    this.documentService.getPackages();
    this.documentService.packages.subscribe((data: any) => {
      this.handlePackages(data);
    });
  }

  moveToNextTab() {
    if (this.selectedTabIndex >= document.querySelectorAll('.mat-tab-label-content').length - 1) {
      (<HTMLElement>document.querySelectorAll('.mat-tab-label')[0]).click();
    } else {
      (<HTMLElement>document.querySelectorAll('.mat-tab-label')[this.selectedTabIndex + 1]).click();
    }
  }

  tabClick(index) {
    this.selectedTabIndex = index.index;
    if (document.querySelectorAll('.mat-tab-label')) {
      if (this.selectedTabIndex === 0) {
        (<HTMLElement>document.querySelectorAll('.mat-tab-label')[1]).style.borderBottomColor = `black`;
        (<HTMLElement>document.querySelectorAll('.mat-tab-label')[1]).style.borderRight = `3px solid #aaa`;
        (<HTMLElement>document.querySelectorAll('.mat-tab-label')[1]).style.backgroundColor = `white`;
        (<HTMLElement>document.querySelectorAll('.mat-tab-label')[1]).style.color = `black`;
        (<HTMLElement>document.querySelectorAll('.mat-tab-label')[2]).style.borderBottomColor = `black`;
        (<HTMLElement>document.querySelectorAll('.mat-tab-label')[2]).style.borderRight = `3px solid #aaa`;
        (<HTMLElement>document.querySelectorAll('.mat-tab-label')[2]).style.backgroundColor = `white`;
        (<HTMLElement>document.querySelectorAll('.mat-tab-label')[2]).style.color = `black`;
        (<HTMLElement>document.querySelectorAll('.mat-tab-label')[3]).style.borderBottomColor = `black`;
        (<HTMLElement>document.querySelectorAll('.mat-tab-label')[3]).style.borderRight = `3px solid #aaa`;
        (<HTMLElement>document.querySelectorAll('.mat-tab-label')[3]).style.color = `black`;
        (<HTMLElement>document.querySelectorAll('.mat-tab-label')[3]).style.backgroundColor = `white`;
        (<HTMLElement>document.querySelectorAll('.mat-tab-label')[this.selectedTabIndex]).style.borderBottomColor = `${this.stepper}`;
        (<HTMLElement>document.querySelectorAll('.mat-tab-label')[this.selectedTabIndex]).style.borderRight = `3px solid ${this.stepper}`;
        (<HTMLElement>document.querySelectorAll('.mat-tab-label')[this.selectedTabIndex]).style.color = `${this.stepper}`;
        (<HTMLElement>document.querySelectorAll('.mat-tab-label')[this.selectedTabIndex]).style.backgroundColor = `#efefef`;
      } else if (this.selectedTabIndex === 1) {
        (<HTMLElement>document.querySelectorAll('.mat-tab-label')[0]).style.borderBottomColor = `black`;
        (<HTMLElement>document.querySelectorAll('.mat-tab-label')[0]).style.borderRight = `3px solid #aaa`;
        (<HTMLElement>document.querySelectorAll('.mat-tab-label')[0]).style.backgroundColor = `white`;
        (<HTMLElement>document.querySelectorAll('.mat-tab-label')[0]).style.color = `black`;
        (<HTMLElement>document.querySelectorAll('.mat-tab-label')[2]).style.borderBottomColor = `black`;
        (<HTMLElement>document.querySelectorAll('.mat-tab-label')[2]).style.borderRight = `3px solid #aaa`;
        (<HTMLElement>document.querySelectorAll('.mat-tab-label')[2]).style.backgroundColor = `white`;
        (<HTMLElement>document.querySelectorAll('.mat-tab-label')[2]).style.color = `black`;
        (<HTMLElement>document.querySelectorAll('.mat-tab-label')[3]).style.borderBottomColor = `black`;
        (<HTMLElement>document.querySelectorAll('.mat-tab-label')[3]).style.borderRight = `3px solid #aaa`;
        (<HTMLElement>document.querySelectorAll('.mat-tab-label')[3]).style.backgroundColor = `white`;
        (<HTMLElement>document.querySelectorAll('.mat-tab-label')[3]).style.color = `black`;
        (<HTMLElement>document.querySelectorAll('.mat-tab-label')[this.selectedTabIndex]).style.borderBottomColor = `${this.stepper}`;
        (<HTMLElement>document.querySelectorAll('.mat-tab-label')[this.selectedTabIndex]).style.borderRight = `3px solid ${this.stepper}`;
        (<HTMLElement>document.querySelectorAll('.mat-tab-label')[this.selectedTabIndex]).style.color = `${this.stepper}`;
        (<HTMLElement>document.querySelectorAll('.mat-tab-label')[this.selectedTabIndex]).style.backgroundColor = `#efefef`;
      } else if (this.selectedTabIndex === 2) {
        (<HTMLElement>document.querySelectorAll('.mat-tab-label')[0]).style.borderBottomColor = `black`;
        (<HTMLElement>document.querySelectorAll('.mat-tab-label')[0]).style.borderRight = `3px solid #aaa`;
        (<HTMLElement>document.querySelectorAll('.mat-tab-label')[0]).style.backgroundColor = `white`;
        (<HTMLElement>document.querySelectorAll('.mat-tab-label')[0]).style.color = `black`;
        (<HTMLElement>document.querySelectorAll('.mat-tab-label')[1]).style.borderBottomColor = `black`;
        (<HTMLElement>document.querySelectorAll('.mat-tab-label')[1]).style.borderRight = `3px solid #aaa`;
        (<HTMLElement>document.querySelectorAll('.mat-tab-label')[1]).style.backgroundColor = `white`;
        (<HTMLElement>document.querySelectorAll('.mat-tab-label')[1]).style.color = `black`;
        (<HTMLElement>document.querySelectorAll('.mat-tab-label')[3]).style.borderBottomColor = `black`;
        (<HTMLElement>document.querySelectorAll('.mat-tab-label')[3]).style.borderRight = `3px solid #aaa`;
        (<HTMLElement>document.querySelectorAll('.mat-tab-label')[3]).style.backgroundColor = `white`;
        (<HTMLElement>document.querySelectorAll('.mat-tab-label')[3]).style.color = `black`;
        (<HTMLElement>document.querySelectorAll('.mat-tab-label')[this.selectedTabIndex]).style.borderBottomColor = `${this.stepper}`;
        (<HTMLElement>document.querySelectorAll('.mat-tab-label')[this.selectedTabIndex]).style.borderRight = `3px solid ${this.stepper}`;
        (<HTMLElement>document.querySelectorAll('.mat-tab-label')[this.selectedTabIndex]).style.color = `${this.stepper}`;
        (<HTMLElement>document.querySelectorAll('.mat-tab-label')[this.selectedTabIndex]).style.backgroundColor = `#efefef`;
      } else if (this.selectedTabIndex === 3) {
        (<HTMLElement>document.querySelectorAll('.mat-tab-label')[0]).style.borderBottomColor = `black`;
        (<HTMLElement>document.querySelectorAll('.mat-tab-label')[0]).style.borderRight = `3px solid #aaa`;
        (<HTMLElement>document.querySelectorAll('.mat-tab-label')[0]).style.backgroundColor = `white`;
        (<HTMLElement>document.querySelectorAll('.mat-tab-label')[0]).style.color = `black`;
        (<HTMLElement>document.querySelectorAll('.mat-tab-label')[2]).style.borderBottomColor = `black`;
        (<HTMLElement>document.querySelectorAll('.mat-tab-label')[2]).style.borderRight = `3px solid #aaa`;
        (<HTMLElement>document.querySelectorAll('.mat-tab-label')[2]).style.backgroundColor = `white`;
        (<HTMLElement>document.querySelectorAll('.mat-tab-label')[2]).style.color = `black`;
        (<HTMLElement>document.querySelectorAll('.mat-tab-label')[1]).style.borderBottomColor = `black`;
        (<HTMLElement>document.querySelectorAll('.mat-tab-label')[1]).style.borderRight = `3px solid #aaa`;
        (<HTMLElement>document.querySelectorAll('.mat-tab-label')[1]).style.backgroundColor = `white`;
        (<HTMLElement>document.querySelectorAll('.mat-tab-label')[1]).style.color = `black`;
        (<HTMLElement>document.querySelectorAll('.mat-tab-label')[this.selectedTabIndex]).style.borderBottomColor = `${this.stepper}`;
        (<HTMLElement>document.querySelectorAll('.mat-tab-label')[this.selectedTabIndex]).style.borderRight = `3px solid ${this.stepper}`;
        (<HTMLElement>document.querySelectorAll('.mat-tab-label')[this.selectedTabIndex]).style.color = `${this.stepper}`;
        (<HTMLElement>document.querySelectorAll('.mat-tab-label')[this.selectedTabIndex]).style.backgroundColor = `#efefef`;
      }
    }
  }

  saveDataFromForm(event) {
    const allinputs = document.querySelectorAll('input[type="text"]');
    const emptyString = '';
    const emptyArray: any[] = [];
    if (this.formData.length > 0) {
      this.formData = emptyArray;
    }
    for (let i = 0; i < allinputs.length; i++) {
      if ((<HTMLInputElement>allinputs[i])) {
        this.formData.push((<HTMLInputElement>allinputs[i]).value);
      }
      if (!(<HTMLInputElement>allinputs[i])) {
        this.formData.push(emptyString);
      }
    }
    console.log(this.formData);
  }

  handlePackages(data: any) {
    this.packages = [];
    this.entities = [];
    this.packages = data;
    this.documents = [];

    if (data.packages && data.packages.length) {
      for (const p of data.packages) {
        for (const entity of p.entities) {
          this.kybService.getEntity(entity.entityId).subscribe((res: any) => {
            this.entities.push(res.data);
          });

          for (const role of entity.roles) {
            for (const doc of role.requestedDocuments) {
              if (doc.documentStatus !== 'EMPTY') {
                continue;
              }

              this.kybService.getDocument(doc.documentId).subscribe((res: any) => {
                this.documents.push(
                  {
                    entityId: entity.entityId,
                    docId: doc.documentId,
                    role,
                    doc: res.data
                  }
                );

                this.ref.detectChanges();
              });
            }
          }
        }
      }
    }
  }

  getEntity(id) {
    for (const e of this.entities) {
      if (e.id === id) {
        return e.name;
      }
    }
  }

  processDocument(files: FileList, fileName: any) {
    if (files.item(0) == null) {
      this.fileSelected = false;
    } else {
      this.document1 = new Document();
      this.document1.docTypeId = 2;
      this.document1.mimeType = 'image/jpeg';
      this.document1.retainTo = '2026-01-01T11:52:05.381';
      this.document1.validTo = '2026-01-01T11:52:05.381';
      this.documentFile = files.item(0);
      this.fileSelected = true;
    }
  }

  submitFile(entityId, roleId, documentId) {
    this.isveryfing = true;

    this.kybService
      .saveFileNoId(this.documentFile, documentId)
      .subscribe(res => {
        this.documentService.getPackages();
        this.alertService.showSuccess('Document has been saved');
        this.isveryfing = false;
      });
  }
}
