import { Component, OnInit } from '@angular/core';
import { CallsService } from '../../../_services/calls.service';
import { FormDataService } from '../data/formData.service';
import { AlertService } from '../../../_services/alert.service';
import { Router, ActivatedRoute } from '@angular/router';
import { DataService } from '../../../_services/data.service';
import { KYBService } from '../../../_services';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { AddModalComponent } from '../../enterprise/enterprise.component';
import * as $ from 'jquery';

/**
 * Implements the personal step of the registering form
*/
@Component({
    // tslint:disable-next-line:component-selector
    selector: 'add-dossier',
    styleUrls: ['./addDossier.component.css'],
    templateUrl: './addDossier.component.html'
})

export class AddDossierComponent implements OnInit {
    title = 'Structure Builder';
    entityId = '';
    entityType = '';
    isPerson = false;
    isCompany = false;
    form: any;
    roleNo = 5;
    documentNo = 6;
    addDossierState: any;
    currentStack: any;
    delegates: any;
    thirdParty: any;
    addModal: BsModalRef;
    todoFinished = false;
    entitiesDataTable: any;
    rolesDataTable: any;
    documentsDataTable: any;
    currentToDo: any;
    isRegistration: any;
    entityIdsList: any = [];

    rolesResponse: any = {};
    removeDuplicateEntities: any = [];
    currentToDoWithoutDuplicates: any = [];

    constructor(
        private kybService: KYBService,
        private dataService: DataService,
        private callsService: CallsService,
        private formDataService: FormDataService,
        private alertService: AlertService,
        private modalService: BsModalService,
        private router: Router,
        private route: ActivatedRoute
    ) {}

    ngOnInit() {
        this.dataService.isRegistration.subscribe(message => this.isRegistration = message);

        this.route.queryParams.subscribe(params => {
            if (params['entityId']) {
                this.entityId = params['entityId'];

                this.kybService.getRolesForEntity(this.entityId).subscribe(
                    data => {
                        this.rolesResponse = data;

                        for (let i = 0; i < this.rolesResponse.data.length; i++) {
                            if (this.rolesResponse.data[i].e1Instance !== null && this.rolesResponse.data[i].e2Instance !== null) {
                                // tslint:disable-next-line:max-line-length
                                this.currentStack.roles.push({ 'entity1': this.rolesResponse.data[i].e1Instance.primaryName, 'entity2': this.rolesResponse.data[i].e2Instance.primaryName, 'type': this.rolesResponse.data[i].roleType.defaultRoleName, 'delegated': false, 'id': this.rolesResponse.data[i].id });
                            } else if (this.rolesResponse.data[i].e1Instance !== null && this.rolesResponse.data[i].e2Instance === null) {
                                // tslint:disable-next-line:max-line-length
                                this.currentStack.roles.push({ 'entity1': this.rolesResponse.data[i].e1Instance.primaryName, 'entity2': '', 'type': this.rolesResponse.data[i].roleType.defaultRoleName, 'delegated': false, 'id': this.rolesResponse.data[i].id });
                            } else {
                                this.currentStack.roles.push({ 'entity1': '', 'entity2': this.rolesResponse.data[i].e2Instance.primaryName, 'type': this.rolesResponse.data[i].roleType.defaultRoleName, 'delegated': false, 'id': this.rolesResponse.data[i].id });
                            }

                            if (this.rolesResponse.data[i].e1Instance !== null) {
                                // tslint:disable-next-line:max-line-length
                                this.currentStack.entities.push({ 'primaryName': this.rolesResponse.data[i].e1Instance.primaryName, 'type': this.rolesResponse.data[i].e1Instance.entityType.entityType, 'delegated': false, 'id': this.rolesResponse.data[i].e1Instance.id });
                            }

                            if (this.rolesResponse.data[i].e2Instance !== null) {
                                // tslint:disable-next-line:max-line-length
                                this.currentStack.entities.push({ 'primaryName': this.rolesResponse.data[i].e2Instance.primaryName, 'type': this.rolesResponse.data[i].e2Instance.entityType.entityType, 'delegated': false, 'id': this.rolesResponse.data[i].e2Instance.id });
                            }

                            for (let j = 0; j < this.rolesResponse.data[i].requiredDocuments.length; j++) {
                                // tslint:disable-next-line:max-line-length
                                this.currentStack.documents.push({ 'description': this.rolesResponse.data[i].requiredDocuments[j].documentType.description, 'role': 'Role with ID ' + this.rolesResponse.data[i].id, 'type': this.rolesResponse.data[i].requiredDocuments[j].mimeType });
                            }

                            for (let k = 0; k < this.rolesResponse.data[i].additionalDocuments.length; k++) {
                                // tslint:disable-next-line:max-line-length
                                this.currentStack.documents.push({ 'description': this.rolesResponse.data[i].additionalDocuments[k].documentType.description, 'role': 'Role with ID ' + this.rolesResponse.data[i].id, 'type': this.rolesResponse.data[i].additionalDocuments[k].mimeType });
                            }
                        }

                        this.removeDuplicateEntities = this.removeDuplicates(this.currentStack.entities);
                        this.currentStack.entities = this.removeDuplicateEntities;
                        this.dataService.setCurrentStack(this.currentStack);
                    },
                    error => {}
                );
            }
        });

        this.dataService.stack.subscribe(
            message => {
                this.currentStack = message;
                if (this.currentStack.entities === undefined) {
                    this.currentStack.entities = [];
                }

                if (this.currentStack.entities.length > 0) {
                    const empty = (document.getElementsByClassName('dataTables_empty')) as HTMLCollectionOf<Element>;
                    empty[0].setAttribute('style', 'display: none;');
                }

                if (this.currentStack.roles === undefined) {
                    this.currentStack.roles = [];
                }

                if (this.currentStack.roles.length > 0) {
                    const empty = (document.getElementsByClassName('dataTables_empty')) as HTMLCollectionOf<Element>;
                    empty[1].setAttribute('style', 'display: none;');
                }

                if (this.currentStack.documents === undefined) {
                    this.currentStack.documents = [];
                }

                if (this.currentStack.documents.length > 0) {
                    const empty = (document.getElementsByClassName('dataTables_empty')) as HTMLCollectionOf<Element>;
                    empty[2].setAttribute('style', 'display: none;');
                }
            }
        );

        this.dataService.refreshEvent.subscribe(
            message => {
                for (let i = 0; i < this.currentStack.entities.length; i++) {
                    this.entityIdsList.push(this.currentStack.entities[i].id);
                }

                this.dataService.updateToDo(this.entityIdsList);
                this.initEntitiesTable();
                this.initRolesTable();
                this.initDocumentsTable();
            }
        );

        this.dataService.currentToDo.subscribe(
            message => {
                this.currentToDo = message;
            }
        );

        this.initEntitiesTable();
        this.initRolesTable();
        this.initDocumentsTable();
    }

    deleteEntity(entityId: any, primaryName: any) {
        if (window.confirm(`Are you sure you want to delete this entity? All roles attached to this entity that you have added will be lost. This can't be taken back.`)) {
            this.kybService.deleteEntity(entityId).subscribe(
                data => {
                    this.alertService.showSuccess('Entity deleted.');

                    for (let i = 0; i < this.currentStack.entities.length; i++) {
                        if (this.currentStack.entities[i].id === entityId) {
                            this.currentStack.entities.splice(i, 1);
                        }
                    }

                    for (let j = 0; j < this.currentStack.roles.length; j++) {
                        // tslint:disable-next-line:max-line-length
                        if ((this.currentStack.roles[j].entity1 === (primaryName)) || this.currentStack.roles[j].entity2 === (primaryName)) {
                            this.currentStack.roles.splice(j, 1);
                        }
                    }

                    for (let j = 0; j < this.currentStack.roles.length; j++) {
                        if ((this.currentStack.roles[j].entity1 === (primaryName))
                        || this.currentStack.roles[j].entity2 === (primaryName)) {
                            this.currentStack.roles.splice(j, 1);
                        }
                    }

                    for (let k = 0; k < this.currentToDo.roles.length; k++) {
                        if (this.currentToDo.roles[k].entityId === entityId) {
                            this.currentToDo.roles.splice(k, 1);
                        }
                    }

                    this.initEntitiesTable();
                    this.initRolesTable();
                    this.initDocumentsTable();
                },
                error => {
                }
            );
        }
    }

    deleteRole(roleId: any) {
        if (window.confirm(`Are you sure you want to delete this role? All documents that you have uploaded for this role will be lost. This can't be taken back.`)) {
            this.kybService.deleteRole(roleId).subscribe(
                data => {
                    this.alertService.showSuccess('Role deleted.');

                    for (let i = 0; i < this.currentStack.roles.length; i++) {
                        if (this.currentStack.roles[i].id === roleId) {
                            this.currentStack.roles.splice(i, 1);
                        }
                    }

                    for (let j = 0; j < this.currentStack.documents.length; j++) {
                        if (this.currentStack.documents[j].role.split(' ')[3] === roleId) {
                            this.currentStack.documents.splice(j, 1);
                        }
                    }

                    for (let k = 0; k < this.currentToDo.documents.length; k++) {
                        if (this.currentToDo.documents[k].roleId === roleId) {
                            this.currentStack.documents.splice(k, 1);
                        }
                    }

                    this.initEntitiesTable();
                    this.initRolesTable();
                    this.initDocumentsTable();
                },
                error => {
                }
            );
        }
    }

    removeDuplicates(obj) {
        const uniques = [];
        const stringify = {};

        for (let i = 0; i < obj.length; i++) {
            const keys = Object.keys(obj[i]);

            keys.sort((a: any, b: any) => a - b);

            let str = '';

            for (let j = 0; j < keys.length; j++) {
                str += JSON.stringify(keys[j]);
                str += JSON.stringify(obj[i][keys[j]]);
            }

            if (!stringify.hasOwnProperty(str)) {
                uniques.push(obj[i]);
                stringify[str] = true;
            }
        }

        return uniques;
    }

    initEntitiesTable() {
        const entitiesTable: any = $('.table-entities');

        this.entitiesDataTable = entitiesTable.DataTable(
            {
                'oLanguage': { 'sEmptyTable': 'No entities added yet.' },
                'orderCellsTop': true,
                'columnDefs': [
                    { 'orderable': false, 'targets': 0 },
                    { 'orderable': false, 'targets': 1 },
                    { 'orderable': false, 'targets': 2 },
                    { 'orderable': false, 'targets': 3 },
                    { 'orderable': false, 'targets': 4 }
                ],
                'order': [],
                'retrieve': true,
                'paging': false,
                'lengthChange': false,
                'lengthMenu': [[30, 50, 100, -1], [30, 50, 100, 'All']],
                'dom': `<'top'l<'clear'>>rt<'bottom'<'clear'>>`,
                'select': { style: 'os' }
            }
        );

        if (this.currentStack.entities.length > 0) {
            const empty = (document.getElementsByClassName('dataTables_empty')) as HTMLCollectionOf<Element>;
            empty[0].setAttribute('style', 'display: none;');
        }
    }

    initRolesTable() {
        const rolesTable: any = $('.table-roles');

        this.rolesDataTable = rolesTable.DataTable(
            {
                'oLanguage': { 'sEmptyTable': 'No roles added yet.' },
                'orderCellsTop': true,
                'columnDefs': [
                    { 'orderable': false, 'targets': 0 },
                    { 'orderable': false, 'targets': 1 },
                    { 'orderable': false, 'targets': 2 },
                    { 'orderable': false, 'targets': 3 },
                    { 'orderable': false, 'targets': 4 }
                ],
                'order': [],
                'paging': false,
                'retrieve': true,
                'lengthChange': false,
                'lengthMenu': [[30, 50, 100, -1], [30, 50, 100, 'All']],
                'dom': `<'top'l<'clear'>>rt<'bottom'<'clear'>>`,
                'select': { style: 'os' }
            }
        );

        const empty = (document.getElementsByClassName('dataTables_empty')) as HTMLCollectionOf<Element>;
        empty[1].setAttribute('style', 'display: none;');
    }

    initDocumentsTable() {
        const documentsTable: any = $('.table-documents');

        this.documentsDataTable = documentsTable.DataTable(
            {
                'oLanguage': { 'sEmptyTable': 'No documents added yet.' },
                'orderCellsTop': true,
                'columnDefs': [
                    { 'orderable': false, 'targets': 0 },
                    { 'orderable': false, 'targets': 1 },
                    { 'orderable': false, 'targets': 2 }
                ],
                'order': [],
                'paging': false,
                'retrieve': true,
                'lengthChange': false,
                'lengthMenu': [[30, 50, 100, -1], [30, 50, 100, 'All']],
                'dom': `<'top'l<'clear'>>rt<'bottom'<'clear'>>`,
                'select': { style: 'os' }
            }
        );

        if (this.currentStack.documents.length > 0) {
            const empty = (document.getElementsByClassName('dataTables_empty')) as HTMLCollectionOf<Element>;
            empty[2].setAttribute('style', 'display: none;');
        }
    }

    addEntity() {
        const initialState = {
            'mode': 'add-entity',
            'title': 'Add Entity'
        };

        this.addModal = this.modalService.show(AddModalComponent, { initialState, backdrop: 'static' });

        this.dataService.closeModalEvent.subscribe(
            message => {
                this.addModal.hide();
            }
        );
    }

    addRole() {
        const initialState = {
            'mode': 'add-role',
            'title': 'Add Role',
            'entities': this.currentStack.entities
        };

        this.addModal = this.modalService.show(AddModalComponent, { initialState, backdrop: 'static' });

        this.dataService.closeModalEvent.subscribe(
            message => {
                this.addModal.hide();
            }
        );
    }

    addDocument() {
        const initialState = {
            'mode': 'add-document',
            'title': 'Add Document',
            'roles': this.currentStack.roles,
            'entityId': ''
        };

        this.addModal = this.modalService.show(AddModalComponent, { initialState, backdrop: 'static' });

        this.dataService.closeModalEvent.subscribe(
            message => {
                this.addModal.hide();
            }
        );
    }

    save() {
        window.scrollTo(0, 0);
        this.alertService.showSuccess('Registration successful.');
        this.router.navigate(['/login']);
    }

    upload() {}

    finish() {
        if (this.entityId !== '') {
            this.router.navigate(['/detail/' + this.entityId]);
        } else {
            if (this.isRegistration === true) {
                window.alert('Registration successful. You will be redirected to the login screen.');
                this.router.navigate(['/login']);
                this.dataService.setIsRegistration(false);
            } else {
                this.router.navigate(['/enterprise']);
            }
        }
    }

    goBack() {
        this.router.navigate(['/detail/' + this.entityId]);
    }
}
