import 'hammerjs';
import 'mousetrap';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient } from '@angular/common/http';
import { ConfigService } from './_services/config.service';
var ɵ0 = { appearance: 'fill' }, ɵ1 = function (config) { return function () { return config.load(); }; };
var AppModule = /** @class */ (function () {
    function AppModule() {
    }
    return AppModule;
}());
export { AppModule };
// required for AOT compilation
export function HttpLoaderFactory(http) {
    return new TranslateHttpLoader(http);
}
export { ɵ0, ɵ1 };
