import { Component, OnInit } from '@angular/core';
import { AlertService, KYBService } from '../../../_services';

/**
* Implements password changing directive
*/
@Component({
    moduleId: module.id,
    selector: 'app-email-server',
    templateUrl: 'emailServer.component.html',
    styleUrls: [ './emailServer.component.css' ]
})

export class EmailServerComponent implements OnInit {

  response: any = {};
  mailServer: any = {};

  settingsResponse: any = {};

  constructor (
    private kybService: KYBService,
    private alertService: AlertService
  ) {}

  ngOnInit() {
    this.kybService.getEmailSettings().subscribe(
      data => {
        this.settingsResponse = data;
        this.mailServer = JSON.parse(this.settingsResponse.data.value);
      },
      error => {
        this.alertService.showWarning(`Email server hasn't been set up yet.`);
      }
    );
  }

  /**
  * Function used for changing the password
  */
  saveServer(form: any) {
    this.kybService.updateEmailSettings(this.mailServer.host, this.mailServer.username,
      this.mailServer.password, this.mailServer.from).subscribe(
      data => {
        this.alertService.showSuccess('Settings saved successfully.');
        this.ngOnInit();
      }
    );
  }
}
