
    <div class="modal-header">
      <button type="button" class="close pull-right" aria-label="Close" (click)="close()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body" style="overflow:hidden;">
      Would you like to choose Azure SSO or Google SSO?
    </div>
    <div class="modal-footer" style="text-align: right;">
      <button class="btn btn-primary" (click)="chooseAzure()">AzureSSO</button>
      <button class="btn btn-primary" (click)="chooseGoogle()">GoogleSSO</button>
    </div>
  