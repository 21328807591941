import { Component, OnInit } from '@angular/core';
import { FormDataService } from '../../data/formData.service';
import { AlertService } from '../../../../_services/alert.service';
import { Router } from '@angular/router';
import { DataService } from '../../../../_services/data.service';
import { KYBService } from '../../../../_services/kyb.service';
import { Subject } from 'rxjs';
import { ConfigService } from '../../../../_services/config.service';

/**
 * Implements the personal step of the registering form
*/
@Component({
    selector: 'app-add-entity',
    templateUrl: './addEntity.component.html'
})

export class AddEntityComponent implements OnInit {

    title = 'Adding Third Parties';
    entityType = '';
    entityTypeName = '';
    entityTypeGeneral = '';
    isPerson = false;
    isCompany = false;
    entityTypes: any = [];
    entityTypesResponse: any = {};
    tagResponse: any = {};
    tags: any = [];
    entityStatus = '';
    selfRoleId = '';
    roleTypeResponse: any = {};
    primaryName = '';
    selectedTags: any = [];
    createTagResponse: any = {};
    personPhone = '+39';
    personEmail = '';
    addEntityPhoneNumber = true;
    addEntityEmail = true;
    eventsSubject: Subject<any> = new Subject<any>();

    currentStack: any = [];
    currentEntity: any = {};
    filteredEntityTypes: any = [];

    entityByNameResponse: any = {};

    legalPersonType = '';

    enabledSecoundaryName = false;

    constructor(
        private dataService: DataService,
        private formDataService: FormDataService,
        private kybService: KYBService,
        private alertService: AlertService,
        private router: Router,
        private configService: ConfigService
    ) {
        if (configService.get('phonePrefix') !== undefined) {
            this.personPhone = configService.get('phonePrefix');
        }

        if (configService.get('addEntityPhoneNumber') !== undefined) {
            this.addEntityPhoneNumber = configService.get('addEntityPhoneNumber');
        }

        if (configService.get('addEntityEmail') !== undefined) {
            this.addEntityEmail = configService.get('addEntityEmail');
        }
    }

    ngOnInit() {
        this.entityTypeGeneral = 'PERSON';
        this.dataService.setSelfRoleId('');
        this.dataService.setEntityStatus('');
        this.dataService.setEntityType('');
        this.dataService.setEntityTags([]);

        this.kybService.getEntityTypes().subscribe(
            data => {
                this.entityTypesResponse = data;
                this.entityTypes = this.entityTypesResponse.data;
                this.generalTypeChanged();
            },
            error => {}
        );

        this.isPerson = false;
        this.isCompany = false;
        this.entityType = '';
        this.entityTypeName = '';

        this.kybService.getAllTags().subscribe(
            data => {
                this.tagResponse = data;
                this.tags = this.tagResponse.data;
            },
            error => {}
        );

        // TODO: Change this once the backend is adjusted - entityStatus needs to be removed and tags need to be introduced instead.
        this.entityStatus = 'Counterparty';
        this.dataService.setEntityStatus(this.entityStatus);
    }

    addTagFn(name) {
        return { name: name, tag: true, active: true };
    }

    createTag(event) {
        let exists = false;
        for (let i = 0; i < this.tags.length; i++) {
            if (this.tags[i].name === event.name) {
                exists = true;
            }
        }

        if (!exists) {
            this.kybService.createTag(event.name).subscribe(
                data => {
                    this.createTagResponse = data;

                    for (let j = 0; j < this.selectedTags.length; j++) {
                        if (this.selectedTags[j].name === event.name) {
                            this.selectedTags[j].id = this.createTagResponse.data.id;
                        }
                    }
                },
                error => {
                }
            );
        }
    }

    typeChanged() {
        for (let i = 0; i < this.entityTypes.length; i++) {
            if (this.entityTypes[i].id === Number(this.entityType)) {
                for (let j = 0; j < this.entityTypes[i].requiredRoles.requiredE1Roles.length; j++) {
                    if (this.entityTypes[i].requiredRoles.requiredE1Roles[j].roleType.self === true) {
                      this.selfRoleId = this.entityTypes[i].requiredRoles.requiredE1Roles[j].roleType.id;
                    }
                }
            }
        }
        this.dataService.setSelfRoleId(this.selfRoleId);
        this.dataService.setEntityType(this.entityType);
    }

    entityStatusChanged() {
        this.dataService.setEntityStatus(this.entityStatus);
    }

    generalTypeChanged() {
        this.filteredEntityTypes = [];
        this.entityType = '';
        this.isPerson = false;
        this.isCompany = false;

        for (let i = 0; i < this.entityTypes.length; i++) {
            if (this.entityTypes[i].entityType === this.entityTypeGeneral) {
                this.entityTypeName = this.entityTypes[i].entityType;
                this.filteredEntityTypes.push(this.entityTypes[i]);
            }
        }

        console.log('filteredEntityTypes: ', this.filteredEntityTypes);

        this.entityType = this.filteredEntityTypes[0].id;
        this.dataService.setEntityType(this.entityType);

        if (this.entityTypeName === 'PERSON' || this.entityTypeName === 'GEO') {
            this.isPerson = true;
            this.isCompany = false;
        } else if (this.entityTypeName === 'LEGAL' || this.entityTypeName === 'GROUP'
        || this.entityTypeName === 'TREATY' || this.entityTypeName === 'STATE') {
            this.isPerson = false;
            this.isCompany = true;
        } else {
            this.isPerson = false;
            this.isCompany = false;
        }
        if (this.entityTypeName === 'GROUP'
        || this.entityTypeName === 'TREATY' || this.entityTypeName === 'STATE') {
            this.enabledSecoundaryName = false;
        } else {
            this.enabledSecoundaryName = true;
        }
    }

    tagsChanged(event) {
        this.dataService.setEntityTags(this.selectedTags);
    }

    addRole() {
        this.dataService.setAddDossierState(1);
        this.isPerson = false;
        this.isCompany = false;
        this.entityType = '';
    }

    test(event) {}

    goToPrevious() {
        this.formDataService.setRegisterState(4);
    }

    save() {
        window.scrollTo(0, 0);
        this.alertService.showSuccess('Registration successful.');
        this.router.navigate(['/login']);
    }

    saveEntity() {
        this.typeChanged();

        if (this.primaryName.indexOf(',') === -1) {
            this.kybService.getEntityByName(this.primaryName).subscribe(
                data => {
                    this.entityByNameResponse = data;

                    if (this.entityByNameResponse.data.length === 0) {
                        this.dataService.setPrimaryName(this.primaryName);
                        this.dataService.setPersonPhone(this.personPhone);
                        this.dataService.setPersonEmail(this.personEmail);
                        this.eventsSubject.next();
                    } else {
                        if (window.confirm('Entity with the same name already exists. Are you sure you want to create a duplicate?')) {
                            this.dataService.setPrimaryName(this.primaryName);
                            this.dataService.setPersonPhone(this.personPhone);
                            this.dataService.setPersonEmail(this.personEmail);
                            this.eventsSubject.next();
                        }
                    }
                },
                error => {
                    window.scrollTo(0, 0);
                    this.alertService.showError('Something went wrong.');
                }
            );
        } else {
            window.alert('Special characters are not allowed in the names of entities. Please remove them.');
        }
    }
}
