import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

/**
 * Implements guard preventing unauthorized user access
*/
@Injectable()
export class AuthGuard implements CanActivate {

    constructor(private router: Router) {}

    /**
     * Function deciding whether the user can or can't go through. (Is he logged in or not?)
     * @returns {boolean} can user activate this URL?
    */
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        if (JSON.parse(localStorage.getItem('login_token'))) {
            // logged in so return true
            return true;
        }

        // not logged in so redirect to login page with the return url
        this.router.navigate(['/login'], { queryParams: { returnUrl: state.url }});
        return false;
    }
}
