import { Component, AfterViewInit, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CallsService, AlertService } from '../../_services';
import { DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';

/**
 * Implements the user account page
*/
@Component({
    moduleId: module.id,
    templateUrl: 'documentChecker.component.html',
    styleUrls: [ './documentChecker.component.css' ]
})

export class DocumentCheckerComponent implements OnInit, AfterViewInit {

  safePassportFront: SafeResourceUrl = null;
  safePassportBack: SafeResourceUrl = null;
  safeProofOfResidence: SafeResourceUrl = null;
  safeExampleIdFront: SafeResourceUrl = null;
  safeExampleIdBack: SafeResourceUrl = null;
  safeExamplePassport: SafeResourceUrl = null;
  examplePassports: any;
  exampleIdsFront: any;
  exampleIdsBack: any;
  passports: any = [];
  idsFront: any = [];
  idsBack: any = [];
  currentPassportIndex = 0;
  currentIdBackIndex = 0;
  currentIdFrontIndex = 0;

  userResponse: any = {};
  tempUsers: any = [];
  users: any = [];
  selectedUser: any;

  model: any = {};
  exampleResponse: any = {};

  constructor (
    private callsService: CallsService,
    private route: ActivatedRoute,
    private domSanitizer: DomSanitizer,
    private alertService: AlertService,
    private router: Router
  ) {}

  ngOnInit() {
    this.getAllUser();
  }

  ngAfterViewInit() {}

  /**
   * Function for getting the information of all users - without files. Contains configuration of dataTables package.
  */
  getAllUser() {
    // Getting users from the database
    this.callsService.getAllUser('STANDARD_USER')
      .subscribe(data => {
          // Removing the admin user
          this.userResponse = data;
          this.users = this.userResponse.data;
          this.selectedUser = 0;
          this.getAccount(this.users[0].email);
        },
        error => {
          let messageError = '';

          if (error.error && error.error.messages) {
            error.error.messages.forEach((element, index, array) => {
              messageError += element.message + '\n';
            });
          } else {
            messageError = 'Something went wrong. Please try again.';
          }

          this.alertService.showError(messageError);
          this.router.navigate(['/login']);
        }
      );
  }

  changeSelectedUser(selectedUser: any) {
    this.getAccount(this.users[selectedUser].email);
  }

  getAccount(username: any) {}

  getDocuments(model: any) {

    this.safePassportFront = null;
    this.safePassportBack = null;

    this.callsService.getDocument(model.data.documents.fileMapping.passportFrontFileId).subscribe(
      data => {
      },
      error => {
        this.safePassportFront = this.domSanitizer.bypassSecurityTrustResourceUrl('data:image/jpg;base64,' + error.error.text);

        if (model.data.systemMetadata.identityDocumentsStatus === 'gray') {
          (document.getElementById('passport-front')).setAttribute('style', 'border: 3px solid #D3D3D3; height: 250px;');
        } else if (model.data.systemMetadata.identityDocumentsStatus === 'red') {
          (document.getElementById('passport-front')).setAttribute('style', 'border: 3px solid crimson; height: 250px;');
        } else if (model.data.systemMetadata.identityDocumentsStatus === 'yellow') {
          (document.getElementById('passport-front')).setAttribute('style', 'border: 3px solid yellow; height: 250px;');
        } else {
          (document.getElementById('passport-front')).setAttribute('style', 'border: 3px solid green; height: 250px;');
        }
      }
    );

    if (model.data.documents.fileMapping.passportBackFileId) {
      this.callsService.getDocument(model.data.documents.fileMapping.passportBackFileId).subscribe(
        data => {
        },
        error => {
          this.safePassportBack = this.domSanitizer.bypassSecurityTrustResourceUrl('data:image/jpg;base64,' + error.error.text);

          if (model.data.systemMetadata.identityDocumentsStatus === 'gray') {
            (document.getElementById('passport-back')).setAttribute('style', 'border: 3px solid #D3D3D3; height: 250px;');
          } else if (model.data.systemMetadata.identityDocumentsStatus === 'red') {
            (document.getElementById('passport-back')).setAttribute('style', 'border: 3px solid crimson; height: 250px;');
          } else if (model.data.systemMetadata.identityDocumentsStatus === 'yellow') {
            (document.getElementById('passport-back')).setAttribute('style', 'border: 3px solid yellow; height: 250px;');
          } else {
            (document.getElementById('passport-back')).setAttribute('style', 'border: 3px solid green; height: 250px;');
          }
        }
      );
    }

    this.callsService.getDocument(model.data.documents.fileMapping.proofOfResidenceFileId).subscribe(
      data => {
      },
      error => {
        this.safeProofOfResidence = this.domSanitizer.bypassSecurityTrustResourceUrl('data:image/jpg;base64,' + error.error.text);
        const proofOfResidence = document.getElementById('proof-of-residence');

        if (model.data.systemMetadata.proofOfResidenceStatus === 'gray') {
          if (proofOfResidence !== null) {
            (document.getElementById('proof-of-residence')).setAttribute('style', 'border: 3px solid #D3D3D3');
          }
        } else if (model.data.systemMetadata.proofOfResidenceStatus === 'red') {
          if (proofOfResidence !== null) {
            (document.getElementById('proof-of-residence')).setAttribute('style', 'border: 3px solid crimson');
          }
        } else if (model.data.systemMetadata.proofOfResidenceStatus === 'yellow') {
          if (proofOfResidence !== null) {
            (document.getElementById('proof-of-residence')).setAttribute('style', 'border: 3px solid yellow');
          }
        } else {
          if (proofOfResidence !== null) {
            (document.getElementById('proof-of-residence')).setAttribute('style', 'border: 3px solid green');
          }
        }
      }
    );
  }

  nextUser() {
    if (this.selectedUser === this.users.length - 1) {
      this.selectedUser = 0;
      this.getAccount(this.users[this.selectedUser].email);
    } else {
      this.selectedUser++;
      this.getAccount(this.users[this.selectedUser].email);
    }
  }

  previousUser() {
    if (this.selectedUser === 0) {
      this.selectedUser = this.users.length - 1;
      this.getAccount(this.users[this.selectedUser].email);
    } else {
      this.selectedUser--;
      this.getAccount(this.users[this.selectedUser].email);
    }
  }

  previousPassport() {
    if (this.currentPassportIndex === 0) {
      this.currentPassportIndex = this.passports.length - 1;
      this.safeExamplePassport = this.domSanitizer.bypassSecurityTrustResourceUrl('data:image/jpg;base64,'
      + this.passports[this.currentPassportIndex]);
    } else {
      this.currentPassportIndex--;
      this.safeExamplePassport = this.domSanitizer.bypassSecurityTrustResourceUrl('data:image/jpg;base64,'
      + this.passports[this.currentPassportIndex]);
    }
  }

  nextPassport() {
    if (this.currentPassportIndex === this.passports.length - 1) {
      this.currentPassportIndex = 0;
      this.safeExamplePassport = this.domSanitizer.bypassSecurityTrustResourceUrl('data:image/jpg;base64,'
      + this.passports[this.currentPassportIndex]);
    } else {
      this.currentPassportIndex++;
      this.safeExamplePassport = this.domSanitizer.bypassSecurityTrustResourceUrl('data:image/jpg;base64,'
      + this.passports[this.currentPassportIndex]);
    }
  }

  previousIdFront() {
    if (this.currentIdFrontIndex === 0) {
      this.currentIdFrontIndex = this.idsFront.length - 1;
      this.safeExampleIdFront = this.domSanitizer.bypassSecurityTrustResourceUrl('data:image/jpg;base64,'
      + this.idsFront[this.currentIdFrontIndex]);
    } else {
      this.currentIdFrontIndex--;
      this.safeExampleIdFront = this.domSanitizer.bypassSecurityTrustResourceUrl('data:image/jpg;base64,'
      + this.idsFront[this.currentIdFrontIndex]);
    }
  }

  nextIdFront() {
    if (this.currentIdFrontIndex === this.idsFront.length - 1) {
      this.currentIdFrontIndex = 0;
      this.safeExampleIdFront = this.domSanitizer.bypassSecurityTrustResourceUrl('data:image/jpg;base64,'
      + this.idsFront[this.currentIdFrontIndex]);
    } else {
      this.currentIdFrontIndex++;
      this.safeExampleIdFront = this.domSanitizer.bypassSecurityTrustResourceUrl('data:image/jpg;base64,'
      + this.idsFront[this.currentIdFrontIndex]);
    }
  }

  previousIdBack() {
    if (this.currentIdBackIndex === 0) {
      this.currentIdBackIndex = this.idsBack.length - 1;
      this.safeExampleIdBack = this.domSanitizer.bypassSecurityTrustResourceUrl('data:image/jpg;base64,'
      + this.idsBack[this.currentIdBackIndex]);
    } else {
      this.currentIdBackIndex--;
      this.safeExampleIdBack = this.domSanitizer.bypassSecurityTrustResourceUrl('data:image/jpg;base64,'
      + this.idsBack[this.currentIdBackIndex]);
    }
  }

  nextIdBack() {
    if (this.currentIdBackIndex === this.idsBack.length - 1) {
      this.currentIdBackIndex = 0;
      this.safeExampleIdBack = this.domSanitizer.bypassSecurityTrustResourceUrl('data:image/jpg;base64,'
      + this.idsBack[this.currentIdBackIndex]);
    } else {
      this.currentIdBackIndex++;
      this.safeExampleIdBack = this.domSanitizer.bypassSecurityTrustResourceUrl('data:image/jpg;base64,'
      + this.idsBack[this.currentIdBackIndex]);
    }
  }

  setIdentityDocumentsStatus(status: any) {
    this.callsService.setIdentityDocumentStatus(this.users[this.selectedUser].email, status).subscribe(
      data => {
        this.getAccount(this.users[this.selectedUser].email);
      },
      error => {}
    );
  }

  setProofOfResidenceStatus(status: any) {
    this.callsService.setProofOfResidenceStatus(this.users[this.selectedUser].email, status).subscribe(
      data => {
        this.getAccount(this.users[this.selectedUser].email);
      },
      error => {}
    );
  }
}
