
      <div class='modal-header modal-dialog-centered'>
          <h4 class='modal-title pull-left'>{{modalTitle}}</h4>
          <button type='button' class='close pull-right' aria-label='Close' (click)='bsModalRef.hide()'>
          <span aria-hidden='true'>&times;</span>
          </button>
      </div>
      <div class='modal-body' style='overflow-x: scroll;'>
          <p style='color: black;padding: 20px;' [innerHTML]='text'>
          </p>
      </div>
      <div class='modal-footer'>
          <button type='button' class='btn btn-default' (click)='bsModalRef.hide()'>{{closeBtnName}}</button>
      </div>
  