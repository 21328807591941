<div class="col-md-12" xmlns:height="http://www.w3.org/1999/xhtml" style="padding: 0px;">
  <div *ngIf="!isEmpty" class="container" style="background-color: #fff; padding: 1.5rem !important; font-family: 'RedHatDisplay', sans-serif;">
    <p class="text-center" style="font-size: 22px;color: #000;font-weight: 600;">Notifications</p> 
    <table id="notification-table" class="table role-detail-table__table table-responsive table-hover" style="color: black;"> 
      <thead>
        <tr style="background-color: #f2f1f9;">
          <th>Notification Type</th>
          <th>Description</th>
          <th style="text-align: center;">Entity Name</th>
          <th style="text-align: center;">Entity Owner</th>
          <th style="text-align: center;">Date/Time Of Notification Creation</th>
          <th style="text-align: center;">Actions</th>
        </tr>
      </thead>
      <tbody>
        <tr style="" class="main-rows" *ngFor="let notification of notifications; let i = index">
          <td *ngIf="notification.type === 'DossierExpirationNotification'">Entity Refresh Date Expired</td>
          <td *ngIf="notification.type === 'DocumentNotificationDto'">Document Expired</td>
          <td *ngIf="notification.type !== 'DossierExpirationNotification' && notification.type !== 'DocumentNotificationDto'">{{notification.type}}</td>
          <td>{{notification.description}}</td>
          <td style="text-align: center;"><a style='color:black' [routerLink]="['/detail', notification.entityId]"><u>{{notification.entityName}}</u></a></td>
          <td style="text-align: center;">{{notification.ownerName}}</td>
          <td style="text-align: center;">{{notification.createdAt | dateParser}}</td>
          <td style="text-align: center; vertical-align: middle;">
            <button (click)="closeNotification(notification)" style="border: none; background-color: transparent;"><i  class="fa fa-lg fa-close" style=""></i></button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="container">
    <p class="text-center" style="font-size: 22px; margin-top: 20px; font-weight: 600; font-family: 'RedHatDisplay', sans-serif;">Statistics</p>
    <button class="btn btn-primary btn-filter" style="font-family: 'RedHatDisplay', sans-serif;" (click)="toggleFilters()">Filters&nbsp;<mat-icon>filter_list</mat-icon></button>
  <div *ngIf="displayFilters">
    <div style="height: 20px;"></div>
    Select the tag(s) that you would like to see:
    <div style="height: 10px;"></div>
    <div class="row" style="margin: 0px; display: flex; align-items: center;" >
      <div class="col-md-6" style="padding-left: 0px;">
        <ng-select
        class="custom"
        [items]="userTags"
        [multiple]="true"
        bindLabel="name"
        [closeOnSelect]="false"
        bindValue="id"
        [clearable]="false"
        (change)="tagsChanged($event)"
        [(ngModel)]="includedTags">
        <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
            <input id="item-{{index}}" type="checkbox" [ngModel]="item$.selected"/> {{item.name}}
        </ng-template>
        </ng-select>
      </div>
      <div class="col-md-6" style="margin-left: -20px;">
        <button (click)="selectAllTags()" class="btn btn-success btn-select-clear">Select all</button>&nbsp;&nbsp;
        <button (click)="deselectAllTags()" class="btn btn-success btn-select-clear">Clear all</button>
      </div>
    </div>
  </div>
  <div style="height: 30px"></div>
  <div class="" style="margin: 0 auto;">
    <div class="row completenessProbablePie" style="margin: 0px; display: flex; justify-content: center;">
      <div style="" class="col-md-6 col-12 dashboard-piechart-1"><div [chart]="completenessPie"></div></div>
      <div style="" class="col-md-6 col-12 dashboard-piechart-2"><div [chart]="probableMatchesPie"></div></div>
    </div>
    <div style="height: 20px"></div>
    <div class="row entityAssessmentPie" style="margin: 0px; display: flex; justify-content: center;">
      <div style="" class="col-md-6 col-12 dashboard-piechart-3"><div [chart]="entityTypePie"></div></div>
      <div style="" class="col-md-6 col-12 dashboard-piechart-4"><div [chart]="assessmentResultPie"></div></div>
    </div>
    <div style="height: 20px"></div>
    <div class="row newStats">
      <div class="col-md-3 col-sm-6 dashboard-piechart-5"><div [chart]="numberOfActiveEntitiesPie"></div></div>
      <div class="col-md-3 col-sm-6 dashboard-piechart-6"><div [chart]="numberOfActiveUserPie"></div></div>
      <div class="col-md-3 col-sm-6 dashboard-piechart-7"><div [chart]="deltaDataPie"></div></div>
      <div class="col-md-3 col-sm-6 dashboard-piechart-8"><div [chart]="screeningStatsPie"></div></div>
    </div>
  </div>
  <div style="height: 80px"></div>
  </div>
</div>
