import { Component, OnInit } from '@angular/core';
import { DataService, KYBService } from '../../_services';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { config } from '../../../assets/configuration';
import { Chart } from 'angular-highcharts';
import { AddModalComponent } from '../enterprise';
import { CloseNotificationComponent } from '../../modals/close-notification/closeNotification.component';
import { Router } from '@angular/router';

/**
 * Implements the user detail page
*/
@Component({
    moduleId: module.id,
    templateUrl: 'dashboard.component.html',
    styleUrls: [ './dashboard.component.scss' ]
})
export class DashboardComponent implements OnInit {

  completenessChart: Chart;
  probableMatchesChart: Chart;
  entityTypeChart: Chart;
  assessmentResultChart: Chart;
  entityStatusChart: Chart;
  completenessPie: Chart;
  numberOfActiveEntitiesPie: Chart;
  numberOfActiveUserPie: Chart;
  deltaDataPie: Chart;
  screeningStatsPie: Chart;
  probableMatchesPie: Chart;
  entityTypePie: Chart;
  assessmentResultPie: Chart;
  dateRange: any;
  reportLogo: any = config.reportLogo;
  reportPreviewModal: BsModalRef;
  previewClicked = false;
  loadingImg: any = config.loadingImg;
  loading = false;
  loading2 = false;
  dashboardStatsResponse: any = {};
  entityCompletenessStats: any = {};
  entityStatusStats: any = {};
  entityWithProbableMatchesStats: any = {};
  entityTypesStats: any = {};
  assessmentResultStats: any = {};
  numberOfActiveEntities: any = {};
  numberOfActiveUser: any = {};
  deltaData: any = {};
  screeningStats: any = {};
  entityId = '';
  screeningStatDate = '';
  deltaDataDate = '';
  entities: any = [];
  entitiesLoading = false;
  pdfResponse: any = {};
  userResponse: any = {};
  tagsResponse: any = {};
  allTags: any = [];
  hiddenTags: any = [];
  tmpTags: any = [];
  userTags: any = [];
  displayFilters = false;

  allEntitiesResponse: any = {};

  includedTags: any = [];

  probableMatches: any = [];
  probablePageSize = 10;
  probableTotalItems = 10;
  probableCurrentPage = 0;
  probableMaxSize = 10;

  notifications: any = [];
  notificationsResponse: any = {};
  pageSize = 10;
  sortingOption = 'created_at,desc';
  isEmpty = false;

  replaceDocumentModal: BsModalRef;

  closeNotificationModal: BsModalRef;

  constructor (
    private dataService: DataService,
    private kybService: KYBService,
    private modalService: BsModalService,
    private router: Router
  ) {
    if (!localStorage.getItem('auth_token') && !localStorage.getItem('login_token')) {
      this.router.navigate(['/login']);
    }
  }

  ngOnInit() {
    this.entitiesLoading = true;

    this.kybService.getCurrentUser().subscribe(
      data => {
        this.userResponse = data;

        this.kybService.getAllTags().subscribe(
          tagsData => {
            this.tagsResponse = tagsData;

            for (let i = 0; i < this.tagsResponse.data.length; i++) {
              this.allTags.push(this.tagsResponse.data[i].id);
            }

            if (this.userResponse && this.userResponse.data && this.userResponse.data.hiddenTags) {
              for (let j = 0; j < this.userResponse.data.hiddenTags.length; j++) {
                this.hiddenTags.push(this.userResponse.data.hiddenTags[j].id);
              }
            }

            for (let k = 0; k < this.allTags.length; k++) {
              if (this.hiddenTags.indexOf(this.allTags[k]) === -1) {
                this.tmpTags.push(this.allTags[k]);
              }
            }

            for (let l = 0; l < this.tagsResponse.data.length; l++) {
              if (this.tmpTags.indexOf(this.tagsResponse.data[l].id) !== -1) {
                this.userTags = [...this.userTags, { id: this.tagsResponse.data[l].id, name: this.tagsResponse.data[l].name }];
              }
            }
          }
        );
      },
      error => {}
    );

    this.getData(null);

    this.kybService.getProbableMatchesForUser(this.probableCurrentPage, this.probablePageSize).subscribe(
      (value: any) => {
        this.probableMatches = value.data.content;
        this.probableTotalItems = value.data.totalElements;
      }
    );

    this.getNotificationsForCurrentUser(this.pageSize, 0, this.sortingOption);
  }

  probablePageChanged(event: any): void {
    this.kybService.getProbableMatchesForUser(event.page - 1, this.probablePageSize).subscribe(
      (value: any) => this.probableMatches = value.data.content
    );
  }

  probablePageSizeChanged(): void {
    this.probableCurrentPage = 0;

    this.kybService.getProbableMatchesForUser(this.probableCurrentPage, this.probablePageSize).subscribe(
      (value: any) => this.probableMatches = value.data.content
    );
  }

  closeNotification(notification: any) {
    const initialState = {
      'notification': notification
    };

    this.closeNotificationModal = this.modalService.show(CloseNotificationComponent, { initialState, backdrop: 'static' });
    this.dataService.closeModalEvent.subscribe(
      message => {
        this.closeNotificationModal.hide();
        this.ngOnInit();
      }
    );
  }

  getNotificationsForCurrentUser(size: any, page: any, sort: any) {
    this.kybService.getNotificationsForCurrentUser(size, page, sort, {}).subscribe(
      data => {
        this.notifications = [];
        this.notificationsResponse = data;
        this.notifications = this.notificationsResponse.data.notifications;

        if (!this.notifications || this.notifications.length === 0) {
          this.isEmpty = true;
        } else {
          this.isEmpty = false;
        }
      }
    );
  }

  replaceDocument(document: any): void {
    const initialState = {
      'mode': 'replace-document',
      'title': 'Replace Document',
      'documentId': document.documentsInformation.documentId,
      'documentTypeId': document.documentsInformation.documentTypeId,
      'entityId': document.ownerInformation.entityId,
      'roleId': document.documentsInformation.roleId
    };

    this.replaceDocumentModal = this.modalService.show(AddModalComponent, { initialState, backdrop: 'static' });

    this.dataService.closeModalEvent.subscribe(
      message => {
        this.replaceDocumentModal.hide();
        this.ngOnInit();
      }
    );
  }

  toggleFilters() {
    if (this.displayFilters === true) {
      this.displayFilters = false;
    } else {
      this.displayFilters = true;
    }
  }

  tagsChanged(event) {
    this.getData(this.includedTags);
  }

  selectAllTags() {
    this.includedTags = this.tmpTags;

    this.getData(this.includedTags);
  }

  deselectAllTags() {
    this.includedTags = [];

    this.getData(this.includedTags);
  }

  getData(tags: any) {
    this.kybService.getDashboardStatistics(tags).subscribe(
      data => {
          this.dashboardStatsResponse = data;
          this.entityCompletenessStats = this.dashboardStatsResponse.data.entityCompletenessStats;
          this.entityStatusStats = this.dashboardStatsResponse.data.entityStatusStats;
          this.entityWithProbableMatchesStats = this.dashboardStatsResponse.data.entityWithProbableMatchesStats;
          this.entityTypesStats = this.dashboardStatsResponse.data.entityTypesStats;
          this.assessmentResultStats = this.dashboardStatsResponse.data.assessmentResultStats;
          this.numberOfActiveEntities = this.dashboardStatsResponse.data.entityStats;
          this.numberOfActiveUser = this.dashboardStatsResponse.data.userStats;
          this.screeningStats = this.dashboardStatsResponse.data.screeningStats;
          this.deltaData = this.dashboardStatsResponse.data.deltaStats;

          this.drawCompletenessChart();
          this.drawProbableMatchesChart();
          this.drawEntityTypeChart();
          this.drawAssessmentResultChart();
          this.drawCompletenessPie();
          this.drawNumberOfActiveEntities();
          this.drawNumberOfActiveUser();
          this.drawDeltaData();
          this.drawScreeningStats();
          this.drawProbableMatchesPie();
          this.drawEntityTypePie();
          this.drawAssessmentResultPie();
      },
      error => {}
    );
  }

  dateParserFunction(dateVal: string) {
    if (dateVal !== null && dateVal !== undefined) {
      const date = dateVal.split('T')[0];

      return date;
    }
  }

  prepareDeltaData() {
    const color = [];
    const names = [];
    const y = [];
    const pieData = [];
    this.deltaDataDate = this.dateParserFunction(this.deltaData.updatedAt);
    color[0] = '#000';
    names[0] = `Delta Data Size ${this.deltaDataDate}`;
    y[0] = Math.floor(this.deltaData.deltaSize);
    for (let j = 0; j < 1; j++) {
      pieData.push({ 'name': names[j], 'color': color[j], 'y': y[j]});
    }
    return pieData;
  }

  drawDeltaData() {
    const total = this.deltaData.deltaSize;

    this.deltaDataPie = new Chart({
      chart: {
        height: '400',
      },
      credits: {
        enabled: false
      },
      title: {
        text: ``,
        style: {
          color: 'black',
          fontSize: '18px',
          fontWeight: '900'
        },
        align: 'center'
      },
      subtitle: {
        useHTML: true,
        text: `<span style='font-size: 20px;'>${total}</span><br/><span style='font-size: 18px; color: #000; font-weight: bold;'>Latest Delta Size <br/> (${this.dateParserFunction(this.deltaData.updatedAt)}) </span>`,
        floating: true,
        verticalAlign: 'middle',
        align: 'center',
        y: -10,
        style: {
          textAlign: 'center'
        }
      },
      tooltip: {
        style: {
          fontSize: '16px'
        },
      },
      colors: ['#bdbdbd', '#5ea138', '#FDD32B', '#D60000'],
      plotOptions: {
        pie: {
          innerSize: '75%',
          showInLegend: true,
          dataLabels: {
            enabled: false
          },
        },
      },
      series: [{
        type: 'pie',
        name: '',
        data: this.prepareDeltaData()
    }],
    exporting: {
      enabled: false
    }
    });
  }

  prepareScreeningStats() {
    const color = [];
    const names = [];
    const y = [];
    const pieData = [];
    this.screeningStatDate = this.dateParserFunction(this.screeningStats.completedAt);
    color[0] = '#6560f0';
    names[0] = `Number of Hits ${this.screeningStatDate}`;
    y[0] = Math.floor(this.screeningStats.numberOfMatches);
    for (let j = 0; j < 1; j++) {
      pieData.push({ 'name': names[j], 'color': color[j], 'y': y[j]});
    }
    return pieData;
  }

  drawScreeningStats() {
    const total = this.screeningStats.numberOfMatches;

    this.screeningStatsPie = new Chart({
      chart: {
        height: '400',
      },
      credits: {
        enabled: false
      },
      title: {
        text: ``,
        style: {
          color: 'black',
          fontSize: '18px',
          fontWeight: '900'
        },
        align: 'center'
      },
      subtitle: {
        useHTML: true,
        text: `<span style='font-size: 20px;'>${total}</span><br/><span style='font-size: 18px; color: #000; font-weight: bold;'> Hits in Latest <br/> Screening <br/> (${this.dateParserFunction(this.screeningStats.completedAt)}) </span>`,
        floating: true,
        verticalAlign: 'middle',
        align: 'center',
        y: -10,
        style: {
          textAlign: 'center'
        }
      },
      tooltip: {
        style: {
          fontSize: '16px'
        },
      },
      colors: ['#bdbdbd', '#5ea138', '#FDD32B', '#D60000'],
      plotOptions: {
        pie: {
          innerSize: '75%',
          showInLegend: true,
          dataLabels: {
            enabled: false
          },
        },
      },
      series: [{
        type: 'pie',
        name: '',
        data: this.prepareScreeningStats()
    }],
    exporting: {
      enabled: false
    }
    });
  }

prepareNumberOfActiveUser() {
  const color = [];
  const names = [];
  const y = [];
  const pieData = [];
  color[0] = '#06b48a';
  names[0] = 'Number of Active Users';
  y[0] = Math.floor(this.numberOfActiveUser.numberOfActiveUsers);
  for (let j = 0; j < 1; j++) {
    pieData.push({ 'name': names[j], 'color': color[j], 'y': y[j]});
  }
  return pieData;
}

  drawNumberOfActiveUser() {
    const total = this.numberOfActiveUser.numberOfActiveUsers;

    this.numberOfActiveUserPie = new Chart({
      chart: {
        height: '400',
      },
      credits: {
        enabled: false
      },
      title: {
        text: ``,
        style: {
          color: 'black',
          fontSize: '18px',
          fontWeight: '900'
        },
        align: 'center'
      },
      subtitle: {
        useHTML: true,
        text: `<span style='font-size: 20px;'>${total}</span><br/><span style='font-size: 18px; color: #000; font-weight: bold;'> Active Users<span>`,
        floating: true,
        verticalAlign: 'middle',
        align: 'center',
        y: -10,
        style: {
          textAlign: 'center'
        }
      },
      tooltip: {
        style: {
          fontSize: '16px'
        },
      },
      colors: ['#bdbdbd', '#5ea138', '#FDD32B', '#D60000'],
      plotOptions: {
        pie: {
          innerSize: '75%',
          showInLegend: true,
          dataLabels: {
            enabled: false
          },
        },
      },
      series: [{
        type: 'pie',
        name: '',
        data: this.prepareNumberOfActiveUser()
    }],
    exporting: {
      enabled: false
    }
    });
  }


  prepareNumberOfActiveEntries() {
    const color = [];
    const names = [];
    const y = [];
    const pieData = [];
    names[0] = 'Number of Active Entities';
    color[0] = '#6fd3f7';
    y[0] = Math.floor(this.numberOfActiveEntities.numberOfActiveEntities);
    for (let j = 0; j < 1; j++) {
      pieData.push({ 'name': names[j], 'color': color[j], 'y': y[j]});
    }
    return pieData;
  }

  drawNumberOfActiveEntities() {
    const total = this.numberOfActiveEntities.numberOfActiveEntities;

    this.numberOfActiveEntitiesPie = new Chart({
      chart: {
        height: '400',
      },
      credits: {
        enabled: false
      },
      title: {
        text: ``,
        style: {
          color: 'black',
          fontSize: '18px',
          fontWeight: '900'
        },
        align: 'center'
      },
      subtitle: {
        useHTML: true,
        text: `<span style='font-size: 20px;'>${total}</span><br/><span style='font-size: 18px; color: #000; font-weight: bold;'> Active Entities<span>`,
        floating: true,
        verticalAlign: 'middle',
        align: 'center',
        y: -10,
        style: {
          textAlign: 'center'
        }
      },
      tooltip: {
        style: {
          fontSize: '16px'
        },
      },
      colors: ['#bdbdbd', '#5ea138', '#FDD32B', '#D60000'],
      plotOptions: {
        pie: {
          innerSize: '75%',
          showInLegend: true,
          dataLabels: {
            enabled: false
          },
        },
      },
      series: [{
        type: 'pie',
        name: '',
        data: this.prepareNumberOfActiveEntries()
    }],
    exporting: {
      enabled: false
    }
    });
  }

  prepareCompletenessPie() {
    const colors = [];
    const names = [], y = [];

    names[0] = 'Complete';
    names[1] = 'Unapproved';
    names[2] = 'Incomplete';

    colors[0] = '#5ea138';
    colors[1] = '#d68616';
    colors[2] = '#D60000';

    y[0] = Math.floor(this.entityCompletenessStats.COMPLETE);
    y[1] = Math.floor(this.entityCompletenessStats.ROLES_COMPLETE);
    y[2] = Math.floor(this.entityCompletenessStats.INCOMPLETE);

    // Putting the data in the correct format for highcharts - JSON
    const pieData = [];

    for (let j = 0; j < 3; j++) {
      pieData.push({ 'name': names[j], 'color': colors[j], 'y': y[j] });
    }

    return pieData;
  }

  drawCompletenessPie() {
    const semiTotal = (this.entityCompletenessStats.COMPLETE + this.entityCompletenessStats.ROLES_COMPLETE);
    const total = (semiTotal + this.entityCompletenessStats.INCOMPLETE);
    const totalMinusComplete = total - this.entityCompletenessStats.COMPLETE;

    this.completenessPie = new Chart({
      chart: {
        height: '400',
      },
      credits: {
        enabled: false
      },
      title: {
        text: 'Entity completeness',
        style: {
          color: 'black',
          fontSize: '18px',
          fontWeight: '900'
        },
        align: 'center'
      },
      subtitle: {
        useHTML: true,
        text: `<span style='font-size: 20px; text-wrap: wrap;'>${totalMinusComplete}</span><br/><span style='font-size: 18px; color: #000; font-weight: bold;'>Incomplete Entities <span>`,
        floating: true,
        verticalAlign: 'middle',
        y: 40,
        style: {
          textAlign: 'center'
        }
      },
      tooltip: {
        style: {
          fontSize: '16px'
        },
      },
      colors: ['#bdbdbd', '#5ea138', '#FDD32B', '#D60000'],
      plotOptions: {
        pie: {
          innerSize: '75%',
          showInLegend: false,
          dataLabels: {
            enabled: true
          },
        },
      },
      series: [{
        type: 'pie',
        name: '',
        data: this.prepareCompletenessPie()
    }],
    exporting: {
      enabled: false
    }
    });
  }

  prepareProbableMatchesPie() {
    const colors = [];
    const names = [], y = [];

    names[0] = 'No Matches';
    names[1] = 'Probable Matches';

    colors[0] = '#bdbdbd';
    colors[1] = '#FDD32B';

    y[0] = Math.floor(this.entityWithProbableMatchesStats.entitiesWithoutProbableMatches);
    y[1] = Math.floor(this.entityWithProbableMatchesStats.entitiesWithProbableMatches);

    // Putting the data in the correct format for highcharts - JSON
    const pieData = [];

    for (let j = 0; j < 2; j++) {
      pieData.push({ 'name': names[j], 'color': colors[j], 'y': y[j] });
    }

    return pieData;
  }

  drawProbableMatchesPie() {
    const semiTotal = (this.entityWithProbableMatchesStats.entitiesWithoutProbableMatches);
    const total = (semiTotal + this.entityWithProbableMatchesStats.entitiesWithProbableMatches);
    const totalMinusEntities = total - this.entityWithProbableMatchesStats.entitiesWithoutProbableMatches;

    this.probableMatchesPie = new Chart({
      chart: {
        height: '400',
      },
      credits: {
        enabled: false
      },
      title: {
        text: 'Entities with probable matches',
        style: {
          color: 'black',
          fontSize: '18px',
          fontWeight: '900'
        },
        align: 'center'
      },
      subtitle: {
        useHTML: true,
        text: `<span style='font-size: 20px;'>${totalMinusEntities}</span><br/><span style='font-size: 18px; color: #000; font-weight: bold;'> Entities to Review<span>`,
        floating: true,
        verticalAlign: 'middle',
        y: 40,
        style: {
          textAlign: 'center'
        }
      },
      tooltip: {
        style: {
          fontSize: '16px'
        },
      },
      colors: ['#bdbdbd', '#5ea138', '#FDD32B', '#D60000'],
      plotOptions: {
        pie: {
          innerSize: '75%',
          showInLegend: false,
          dataLabels: {
            enabled: true
          },
        },
      },
      series: [{
        type: 'pie',
        name: '',
        data: this.prepareProbableMatchesPie()
    }],
    exporting: {
      enabled: false
    }
    });
  }

  prepareEntityTypePie() {
    const colors = [];
    const names = [], y = [];

    names[0] = 'Person';
    names[1] = 'Group';
    names[2] = 'Legal';
    names[3] = 'State';
    names[4] = 'Treaty';

    colors[0] = '#2D3945';
    colors[1] = '#E5B35F';
    colors[2] = '#E77B34';
    colors[3] = '#BDA62F';
    colors[4] = '#84463E';

    y[0] = Math.floor(this.entityTypesStats.person);
    y[1] = Math.floor(this.entityTypesStats.group);
    y[2] = Math.floor(this.entityTypesStats.legal);
    y[3] = Math.floor(this.entityTypesStats.state);
    y[4] = Math.floor(this.entityTypesStats.treaty);

    // Putting the data in the correct format for highcharts - JSON
    const pieData = [];

    for (let j = 0; j < 5; j++) {
      pieData.push({ 'name': names[j], 'color': colors[j], 'y': y[j] });
    }

    return pieData;
  }

  drawEntityTypePie() {
    const total = this.numberOfActiveEntities.numberOfActiveEntities;

    this.entityTypePie = new Chart({
      chart: {
        type: "variablepie",
        height: '400',
      },
      credits: {
        enabled: false
      },
      title: {
        text: 'Entity Types',
        style: {
          color: 'black',
          fontSize: '18px',
          fontWeight: '900'
        },
        align: 'center'
      },
      subtitle: {
        useHTML: true,
        text: `<span style='font-size: 20px;'>${total}</span><br/><span style='font-size: 18px; color: #000; font-weight: bold;'>Active Entities<span>`,
        floating: true,
        verticalAlign: 'middle',
        y: 40,
        style: {
          textAlign: 'center'
        }
      },
      tooltip: {
        style: {
          fontSize: '16px'
        },
      },
      colors: ['#bdbdbd', '#5ea138', '#FDD32B', '#D60000'],
      plotOptions: {
        pie: {
          innerSize: '75%',
          showInLegend: false,
          dataLabels: {
            enabled: true
          },
        },
      },
      series: [{
        type: 'pie',
        name: '',
        data: this.prepareEntityTypePie()
    }],
    exporting: {
      enabled: false
    }
    });
  }

  prepareAssessmentResultPie() {
    const colors = [];
    const names = [], y = [];

    names[0] = 'Low';
    names[1] = 'Medium';
    names[2] = 'High';
    names[3] = 'Rejected';

    colors[0] = '#5ea138';
    colors[1] = '#FDD32B';
    colors[2] = '#d6860f';
    colors[3] = '#D60000';

    y[0] = Math.floor(this.assessmentResultStats.low);
    y[1] = Math.floor(this.assessmentResultStats.medium);
    y[2] = Math.floor(this.assessmentResultStats.high);
    y[3] = Math.floor(this.assessmentResultStats.rejected);

    // Putting the data in the correct format for highcharts - JSON
    const pieData = [];

    for (let j = 0; j < 4; j++) {
      pieData.push({ 'name': names[j], 'color': colors[j], 'y': y[j] });
    }

    return pieData;
  }

  drawAssessmentResultPie() {
    const total = this.assessmentResultStats.low + this.assessmentResultStats.medium + this.assessmentResultStats.high;

    this.assessmentResultPie = new Chart ({
      chart: {
        height: '400',
      },
      credits: {
        enabled: false
      },
      title: {
        text: 'Assessment Results',
        style: {
          color: 'black',
          fontSize: '18px',
          fontWeight: '900'
        },
        align: 'center'
      },
      subtitle: {
        useHTML: true,
        text: `<span style='font-size: 20px;'>${total}</span><br/><span style='font-size: 18px; color: #000; font-weight: bold;'>Accepted Entities<span>`,
        floating: true,
        verticalAlign: 'middle',
        y: 30,
        style: {
          textAlign: 'center'
        }
      },
      tooltip: {
        style: {
          fontSize: '16px'
        },
      },
      colors: ['#bdbdbd', '#5ea138', '#FDD32B', '#D60000'],
      plotOptions: {
        pie: {
          innerSize: '75%',
          showInLegend: false,
          dataLabels: {
            enabled: true
          },
        },
      },
      series: [{
        type: 'pie',
        name: '',
        data: this.prepareAssessmentResultPie()
    }],
    exporting: {
      enabled: false
    }
    });
  }

  prepareCompletenessChart(type: any) {
    const chartData = [];

    if (type === 'Counterparties') {
      chartData.push(5);
      chartData.push(3);
    }

    if (type === 'Third Parties') {
      chartData.push(2);
      chartData.push(1);
    }

    return chartData;
  }

  drawCompletenessChart() {
    this.completenessChart = new Chart(
      {
        chart: {
            type: 'column'
        },
        credits: {
          enabled: false
        },
        title: {
            text: 'Entity completeness'
        },
        xAxis: {
            categories: ['Complete', 'Incomplete']
        },
        yAxis: {
            min: 0,
            title: {
                text: 'Type of entity distribution'
            },
            stackLabels: {
                enabled: true,
                style: {
                    fontWeight: 'bold',
                    color: ( 'gray')
                }
            }
        },
        legend: {
            align: 'right',
            x: -30,
            verticalAlign: 'top',
            y: 25,
            floating: true,
            backgroundColor: 'white',
            borderColor: '#CCC',
            borderWidth: 1,
            shadow: false
        },
        tooltip: {
            headerFormat: '<b>{point.x}</b><br/>',
            pointFormat: '{series.name}: {point.y}<br/>Total: {point.stackTotal}'
        },
        plotOptions: {
            column: {
                stacking: 'normal',
                dataLabels: {
                    enabled: true
                }
            }
        },
        series: [{
            type: 'column',
            name: 'Counterparties',
            data: this.prepareCompletenessChart('Counterparties')
        }, {
            type: 'column',
            name: 'Third Parties',
            data: this.prepareCompletenessChart('Third Parties')
        }]
    }
    );
  }

  prepareProbableMatchesChart(type: any) {
    const chartData = [];

    if (type === 'Counterparties') {
      chartData.push(5);
      chartData.push(3);
    }

    if (type === 'Third Parties') {
      chartData.push(2);
      chartData.push(1);
    }

    return chartData;
  }

  drawProbableMatchesChart() {
    this.probableMatchesChart = new Chart(
      {
        chart: {
            type: 'column'
        },
        credits: {
          enabled: false
        },
        title: {
            text: 'Entities with probable matches'
        },
        xAxis: {
            categories: ['Probable matches', 'No matches']
        },
        yAxis: {
            min: 0,
            title: {
                text: 'Type of entity distribution'
            },
            stackLabels: {
                enabled: true,
                style: {
                    fontWeight: 'bold',
                    color: ( 'gray')
                }
            }
        },
        legend: {
            align: 'right',
            x: -30,
            verticalAlign: 'top',
            y: 25,
            floating: true,
            backgroundColor: 'white',
            borderColor: '#CCC',
            borderWidth: 1,
            shadow: false
        },
        tooltip: {
            headerFormat: '<b>{point.x}</b><br/>',
            pointFormat: '{series.name}: {point.y}<br/>Total: {point.stackTotal}'
        },
        plotOptions: {
            column: {
                stacking: 'normal',
                dataLabels: {
                    enabled: true
                }
            }
        },
        series: [{
            type: 'column',
            name: 'Counterparties',
            data: this.prepareProbableMatchesChart('Counterparties')
        }, {
            type: 'column',
            name: 'Third Parties',
            data: this.prepareProbableMatchesChart('Third Parties')
        }]
    }
    );
  }

  prepareAssessmentResultChart(type: any) {
    const chartData = [];

    if (type === 'Counterparties') {
      chartData.push(5);
      chartData.push(3);
      chartData.push(5);
      chartData.push(3);
    }

    if (type === 'Third Parties') {
      chartData.push(2);
      chartData.push(1);
      chartData.push(2);
      chartData.push(1);
    }

    return chartData;
  }

  drawAssessmentResultChart() {
    this.assessmentResultChart = new Chart(
      {
        chart: {
            type: 'column'
        },
        credits: {
          enabled: false
        },
        title: {
            text: 'Assessment results'
        },
        xAxis: {
            categories: ['Low', 'Medium', 'High', 'Rejected']
        },
        yAxis: {
            min: 0,
            title: {
                text: 'Type of entity distribution'
            },
            stackLabels: {
                enabled: true,
                style: {
                    fontWeight: 'bold',
                    color: ( 'gray')
                }
            }
        },
        legend: {
            align: 'right',
            x: -30,
            verticalAlign: 'top',
            y: 25,
            floating: true,
            backgroundColor: 'white',
            borderColor: '#CCC',
            borderWidth: 1,
            shadow: false
        },
        tooltip: {
            headerFormat: '<b>{point.x}</b><br/>',
            pointFormat: '{series.name}: {point.y}<br/>Total: {point.stackTotal}'
        },
        plotOptions: {
            column: {
                stacking: 'normal',
                dataLabels: {
                    enabled: true
                }
            }
        },
        series: [{
            type: 'column',
            name: 'Counterparties',
            data: this.prepareAssessmentResultChart('Counterparties')
        },
        {
            type: 'column',
            name: 'Third Parties',
            data: this.prepareAssessmentResultChart('Third Parties')
        }]
    }
    );
  }

  prepareEntityTypeChart(type: any) {
    const chartData = [];

    if (type === 'Counterparties') {
      chartData.push(5);
      chartData.push(3);
      chartData.push(5);
      chartData.push(3);
      chartData.push(5);
      chartData.push(3);
    }

    if (type === 'Third Parties') {
      chartData.push(2);
      chartData.push(1);
      chartData.push(2);
      chartData.push(1);
      chartData.push(2);
      chartData.push(1);
    }

    return chartData;
  }

  drawEntityTypeChart() {
    this.entityTypeChart = new Chart(
      {
        chart: {
            type: 'column'
        },
        credits: {
          enabled: false
        },
        title: {
            text: 'Entity Types'
        },
        xAxis: {
            categories: ['Geo', 'Person', 'Group', 'Legal', 'State', 'Treaty']
        },
        yAxis: {
            min: 0,
            title: {
                text: 'Type of entity distribution'
            },
            stackLabels: {
                enabled: true,
                style: {
                    fontWeight: 'bold',
                    color: ( 'gray')
                }
            }
        },
        legend: {
            align: 'right',
            x: -30,
            verticalAlign: 'top',
            y: 25,
            floating: true,
            backgroundColor: 'white',
            borderColor: '#CCC',
            borderWidth: 1,
            shadow: false
        },
        tooltip: {
            headerFormat: '<b>{point.x}</b><br/>',
            pointFormat: '{series.name}: {point.y}<br/>Total: {point.stackTotal}'
        },
        plotOptions: {
            column: {
                stacking: 'normal',
                dataLabels: {
                    enabled: true
                }
            }
        },
        series: [{
            type: 'column',
            name: 'Counterparties',
            data: this.prepareEntityTypeChart('Counterparties')
        },
        {
            type: 'column',
            name: 'Third Parties',
            data: this.prepareEntityTypeChart('Third Parties')
        }]
    }
    );
  }
}
