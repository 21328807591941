import * as tslib_1 from "tslib";
import { Router } from '@angular/router';
import { AlertService, CallsService, AuthenticationService } from '../../../_services';
import { config } from '../../../../assets/configuration';
/**
* Implements password changing directive
*/
var CreateAdminComponent = /** @class */ (function () {
    function CreateAdminComponent(router, callsService, alertService, authenticationService) {
        this.router = router;
        this.callsService = callsService;
        this.alertService = alertService;
        this.authenticationService = authenticationService;
        this.userName = '';
        this.email = '';
        this.response = {};
        this.mainColor = config.mainColor;
    }
    /**
    * Function used for changing the password
    */
    CreateAdminComponent.prototype.createAdmin = function (form) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                this.callsService.createAdmin(this.email).subscribe(function (data) {
                    if (data !== null) {
                        _this.response = data;
                        if (_this.response.data.password !== null) {
                            _this.alertService.showSuccess('Admin created successfully. Password is <b>' + _this.response.data.password + '</b>');
                            _this.userName = '';
                            _this.email = '';
                        }
                        else {
                            _this.alertService.showError('Something went wrong.');
                        }
                    }
                }, function (error) {
                    _this.alertService.showError(error.error.message);
                });
                return [2 /*return*/];
            });
        });
    };
    return CreateAdminComponent;
}());
export { CreateAdminComponent };
