import { OnInit, ChangeDetectorRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertService, CallsService, DataService, KYBService } from './../../../_services';
import { DocumentManagerService } from './documentManager.service';
import { BrandingService } from '../../../branding/branding.service';
import { FormControl, Validators } from '@angular/forms';
/**
 * Implements the user detail page
*/
var DocumentManagerComponent = /** @class */ (function () {
    function DocumentManagerComponent(callsService, route, alertService, dataService, kybService, brandingService, router, documentService, ref) {
        this.callsService = callsService;
        this.route = route;
        this.alertService = alertService;
        this.dataService = dataService;
        this.kybService = kybService;
        this.brandingService = brandingService;
        this.router = router;
        this.documentService = documentService;
        this.ref = ref;
        this.requiredDocuments = [];
        this.entities = [];
        this.documents = [];
        this.packages = [];
        this.stepper = '';
        this.alternateContactFormControl = new FormControl('', [Validators.required]);
        this.relationToIntituteFormControl = new FormControl('', [Validators.required]);
        this.overviewOfRelationFormControl = new FormControl('', [Validators.required]);
        this.nameAndPositionFormControl = new FormControl('', [Validators.required]);
        this.ownershipStructureFormControl = new FormControl('', [Validators.required]);
        this.overviewOfInstitutionFormControl = new FormControl('', [Validators.required]);
        this.anticipatedTransactionFormControl = new FormControl('', [Validators.required]);
        this.naturBusinessFormControl = new FormControl('', [Validators.required]);
        this.anticipatedAnnualRevenueFormControl = new FormControl('', [Validators.required]);
        this.sourceOfCapitalFormControl = new FormControl('', [Validators.required]);
        this.expectedTransactionTypeFormControl = new FormControl('', [Validators.required]);
        this.beneficalOwnershipFormControl = new FormControl('', [Validators.required]);
        this.formData = [];
        this.currentLabelName = "Personal&nbsp;&nbsp;--->";
        this.selectedTabIndex = 0;
        this.isveryfing = false;
    }
    DocumentManagerComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.brandingService.brandingSetup.subscribe(function (res) {
            if (!res) {
                return;
            }
            _this.stepper = res.mainColor;
        });
        this.documentService.getPackages();
        this.documentService.packages.subscribe(function (data) {
            _this.handlePackages(data);
        });
    };
    DocumentManagerComponent.prototype.moveToNextTab = function () {
        if (this.selectedTabIndex >= document.querySelectorAll('.mat-tab-label-content').length - 1) {
            document.querySelectorAll('.mat-tab-label')[0].click();
        }
        else {
            document.querySelectorAll('.mat-tab-label')[this.selectedTabIndex + 1].click();
        }
    };
    DocumentManagerComponent.prototype.tabClick = function (index) {
        this.selectedTabIndex = index.index;
        if (document.querySelectorAll('.mat-tab-label')) {
            if (this.selectedTabIndex === 0) {
                document.querySelectorAll('.mat-tab-label')[1].style.borderBottomColor = "black";
                document.querySelectorAll('.mat-tab-label')[1].style.borderRight = "3px solid #aaa";
                document.querySelectorAll('.mat-tab-label')[1].style.backgroundColor = "white";
                document.querySelectorAll('.mat-tab-label')[1].style.color = "black";
                document.querySelectorAll('.mat-tab-label')[2].style.borderBottomColor = "black";
                document.querySelectorAll('.mat-tab-label')[2].style.borderRight = "3px solid #aaa";
                document.querySelectorAll('.mat-tab-label')[2].style.backgroundColor = "white";
                document.querySelectorAll('.mat-tab-label')[2].style.color = "black";
                document.querySelectorAll('.mat-tab-label')[3].style.borderBottomColor = "black";
                document.querySelectorAll('.mat-tab-label')[3].style.borderRight = "3px solid #aaa";
                document.querySelectorAll('.mat-tab-label')[3].style.color = "black";
                document.querySelectorAll('.mat-tab-label')[3].style.backgroundColor = "white";
                document.querySelectorAll('.mat-tab-label')[this.selectedTabIndex].style.borderBottomColor = "" + this.stepper;
                document.querySelectorAll('.mat-tab-label')[this.selectedTabIndex].style.borderRight = "3px solid " + this.stepper;
                document.querySelectorAll('.mat-tab-label')[this.selectedTabIndex].style.color = "" + this.stepper;
                document.querySelectorAll('.mat-tab-label')[this.selectedTabIndex].style.backgroundColor = "#efefef";
            }
            else if (this.selectedTabIndex === 1) {
                document.querySelectorAll('.mat-tab-label')[0].style.borderBottomColor = "black";
                document.querySelectorAll('.mat-tab-label')[0].style.borderRight = "3px solid #aaa";
                document.querySelectorAll('.mat-tab-label')[0].style.backgroundColor = "white";
                document.querySelectorAll('.mat-tab-label')[0].style.color = "black";
                document.querySelectorAll('.mat-tab-label')[2].style.borderBottomColor = "black";
                document.querySelectorAll('.mat-tab-label')[2].style.borderRight = "3px solid #aaa";
                document.querySelectorAll('.mat-tab-label')[2].style.backgroundColor = "white";
                document.querySelectorAll('.mat-tab-label')[2].style.color = "black";
                document.querySelectorAll('.mat-tab-label')[3].style.borderBottomColor = "black";
                document.querySelectorAll('.mat-tab-label')[3].style.borderRight = "3px solid #aaa";
                document.querySelectorAll('.mat-tab-label')[3].style.backgroundColor = "white";
                document.querySelectorAll('.mat-tab-label')[3].style.color = "black";
                document.querySelectorAll('.mat-tab-label')[this.selectedTabIndex].style.borderBottomColor = "" + this.stepper;
                document.querySelectorAll('.mat-tab-label')[this.selectedTabIndex].style.borderRight = "3px solid " + this.stepper;
                document.querySelectorAll('.mat-tab-label')[this.selectedTabIndex].style.color = "" + this.stepper;
                document.querySelectorAll('.mat-tab-label')[this.selectedTabIndex].style.backgroundColor = "#efefef";
            }
            else if (this.selectedTabIndex === 2) {
                document.querySelectorAll('.mat-tab-label')[0].style.borderBottomColor = "black";
                document.querySelectorAll('.mat-tab-label')[0].style.borderRight = "3px solid #aaa";
                document.querySelectorAll('.mat-tab-label')[0].style.backgroundColor = "white";
                document.querySelectorAll('.mat-tab-label')[0].style.color = "black";
                document.querySelectorAll('.mat-tab-label')[1].style.borderBottomColor = "black";
                document.querySelectorAll('.mat-tab-label')[1].style.borderRight = "3px solid #aaa";
                document.querySelectorAll('.mat-tab-label')[1].style.backgroundColor = "white";
                document.querySelectorAll('.mat-tab-label')[1].style.color = "black";
                document.querySelectorAll('.mat-tab-label')[3].style.borderBottomColor = "black";
                document.querySelectorAll('.mat-tab-label')[3].style.borderRight = "3px solid #aaa";
                document.querySelectorAll('.mat-tab-label')[3].style.backgroundColor = "white";
                document.querySelectorAll('.mat-tab-label')[3].style.color = "black";
                document.querySelectorAll('.mat-tab-label')[this.selectedTabIndex].style.borderBottomColor = "" + this.stepper;
                document.querySelectorAll('.mat-tab-label')[this.selectedTabIndex].style.borderRight = "3px solid " + this.stepper;
                document.querySelectorAll('.mat-tab-label')[this.selectedTabIndex].style.color = "" + this.stepper;
                document.querySelectorAll('.mat-tab-label')[this.selectedTabIndex].style.backgroundColor = "#efefef";
            }
            else if (this.selectedTabIndex === 3) {
                document.querySelectorAll('.mat-tab-label')[0].style.borderBottomColor = "black";
                document.querySelectorAll('.mat-tab-label')[0].style.borderRight = "3px solid #aaa";
                document.querySelectorAll('.mat-tab-label')[0].style.backgroundColor = "white";
                document.querySelectorAll('.mat-tab-label')[0].style.color = "black";
                document.querySelectorAll('.mat-tab-label')[2].style.borderBottomColor = "black";
                document.querySelectorAll('.mat-tab-label')[2].style.borderRight = "3px solid #aaa";
                document.querySelectorAll('.mat-tab-label')[2].style.backgroundColor = "white";
                document.querySelectorAll('.mat-tab-label')[2].style.color = "black";
                document.querySelectorAll('.mat-tab-label')[1].style.borderBottomColor = "black";
                document.querySelectorAll('.mat-tab-label')[1].style.borderRight = "3px solid #aaa";
                document.querySelectorAll('.mat-tab-label')[1].style.backgroundColor = "white";
                document.querySelectorAll('.mat-tab-label')[1].style.color = "black";
                document.querySelectorAll('.mat-tab-label')[this.selectedTabIndex].style.borderBottomColor = "" + this.stepper;
                document.querySelectorAll('.mat-tab-label')[this.selectedTabIndex].style.borderRight = "3px solid " + this.stepper;
                document.querySelectorAll('.mat-tab-label')[this.selectedTabIndex].style.color = "" + this.stepper;
                document.querySelectorAll('.mat-tab-label')[this.selectedTabIndex].style.backgroundColor = "#efefef";
            }
        }
    };
    DocumentManagerComponent.prototype.saveDataFromForm = function (event) {
        var allinputs = document.querySelectorAll('input[type="text"]');
        var emptyString = '';
        var emptyArray = [];
        if (this.formData.length > 0) {
            this.formData = emptyArray;
        }
        for (var i = 0; i < allinputs.length; i++) {
            if (allinputs[i]) {
                this.formData.push(allinputs[i].value);
            }
            if (!allinputs[i]) {
                this.formData.push(emptyString);
            }
        }
        console.log(this.formData);
    };
    DocumentManagerComponent.prototype.handlePackages = function (data) {
        var _this = this;
        this.packages = [];
        this.entities = [];
        this.packages = data;
        this.documents = [];
        if (data.packages && data.packages.length) {
            for (var _i = 0, _a = data.packages; _i < _a.length; _i++) {
                var p = _a[_i];
                var _loop_1 = function (entity) {
                    this_1.kybService.getEntity(entity.entityId).subscribe(function (res) {
                        _this.entities.push(res.data);
                    });
                    var _loop_2 = function (role) {
                        var _loop_3 = function (doc) {
                            if (doc.documentStatus !== 'EMPTY') {
                                return "continue";
                            }
                            this_1.kybService.getDocument(doc.documentId).subscribe(function (res) {
                                _this.documents.push({
                                    entityId: entity.entityId,
                                    docId: doc.documentId,
                                    role: role,
                                    doc: res.data
                                });
                                _this.ref.detectChanges();
                            });
                        };
                        for (var _i = 0, _a = role.requestedDocuments; _i < _a.length; _i++) {
                            var doc = _a[_i];
                            _loop_3(doc);
                        }
                    };
                    for (var _i = 0, _a = entity.roles; _i < _a.length; _i++) {
                        var role = _a[_i];
                        _loop_2(role);
                    }
                };
                var this_1 = this;
                for (var _b = 0, _c = p.entities; _b < _c.length; _b++) {
                    var entity = _c[_b];
                    _loop_1(entity);
                }
            }
        }
    };
    DocumentManagerComponent.prototype.getEntity = function (id) {
        for (var _i = 0, _a = this.entities; _i < _a.length; _i++) {
            var e = _a[_i];
            if (e.id === id) {
                return e.name;
            }
        }
    };
    DocumentManagerComponent.prototype.processDocument = function (files, fileName) {
        if (files.item(0) == null) {
            this.fileSelected = false;
        }
        else {
            this.document1 = new Document();
            this.document1.docTypeId = 2;
            this.document1.mimeType = 'image/jpeg';
            this.document1.retainTo = '2026-01-01T11:52:05.381';
            this.document1.validTo = '2026-01-01T11:52:05.381';
            this.documentFile = files.item(0);
            this.fileSelected = true;
        }
    };
    DocumentManagerComponent.prototype.submitFile = function (entityId, roleId, documentId) {
        var _this = this;
        this.isveryfing = true;
        this.kybService
            .saveFileNoId(this.documentFile, documentId)
            .subscribe(function (res) {
            _this.documentService.getPackages();
            _this.alertService.showSuccess('Document has been saved');
            _this.isveryfing = false;
        });
    };
    return DocumentManagerComponent;
}());
export { DocumentManagerComponent };
