import { Component, OnInit } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { WebCallsService, WebDataService } from '../../../_services/index';
import { CreditAlertComponent } from '../creditAlert/creditAlert.component';

@Component({
    moduleId: module.id,
    templateUrl: 'fullReport.component.html',
    styleUrls: [ './fullReport.component.css' ]
})

export class FullReportComponent implements OnInit {

  firstName = '';
  lastName = '';
  dateOfBirth = '';
  country = '';
  encodedQuery = '';
  searchQuery = '';
  count = 0;
  results: any = {};
  isFullSearch = false;
  step: any;
  credits: any;
  bsModalRef: BsModalRef;

  constructor (
    private modalService: BsModalService,
    private webCallsService: WebCallsService,
    private webDataService: WebDataService,
  ) {}

  ngOnInit() {
    this.webCallsService.getCreditsForUser().subscribe(
      data => {
        this.credits = data;
        this.credits = this.credits.credits;

        if (this.credits <= 20) {
          this.webCallsService.addToAutopilotJourney(localStorage.getItem('username'), '0014').subscribe(
            error => {}
          );
          this.showAlert(this.credits);
        }
      },
      error => {}
    );

    this.webDataService.isFullSearch.subscribe(
      message => this.isFullSearch = message
    );
  }

  showAlert(credits: any) {
    const initialState = {
      credits: credits,
      class: 'modal-custom'
    };

    this.bsModalRef = this.modalService.show(CreditAlertComponent, { initialState });
    this.bsModalRef.content.closeBtnName = 'Close';
  }

  onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  }
}
