import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './user_management/login';
import { RegisterComponent } from './user_management/register';
import { AuthGuard, UserGroupGuard } from './_guards';
import { EnterpriseComponent } from './user_management/enterprise';
import { BatchComponent } from './user_management/batch';
import { DocumentCheckerComponent } from './user_management/document-checker/documentChecker.component';
import { SettingsComponent } from './user_management/settings';
import { UserUploaderComponent } from './user_management/settings/userManagement/userUploader/userUploader.component';
import { NonAdminComponent } from './user_management/nonadmin/nonAdmin.component';
import { MyAccountComponent } from './user_management/myAccount/myAccount.component';
import { ScreeningComponent } from './user_management/screening/screening.component';
import { AddDossierComponent } from './user_management/register/addDossier/addDossier.component';
import { DocumentEditorComponent } from './user_management/document-editor/documentEditor.component';
import { Error404Component } from './error404.component';
import { MenuComponent } from './user_management/menu/menu.component';
import { SearchComponent } from './user_management/menu/search';
import { FullReportComponent } from './user_management/menu/fullReport';
import { PackageManagerComponent } from './user_management/package-manager/packageManager.component';
import { NotificationsComponent } from './user_management/notifications';

const appRoutes: Routes = [
  {
    path: '',
    redirectTo: '/dashboard',
    pathMatch: 'full',
    canActivate: [AuthGuard]
  },
  { path: 'login', component: LoginComponent },

  { path: 'editor', component: DocumentEditorComponent },
  { path: 'register', redirectTo: 'register/en', pathMatch: 'full' },
  { path: 'register/:lang/:redirectUrl', component: RegisterComponent },
  { path: 'register/:lang', component: RegisterComponent },
  { path: 'onboard/:token', component: RegisterComponent },
  { path: 'onboard', component: RegisterComponent },
  { path: 'account', component: NonAdminComponent, canActivate: [AuthGuard] },
  {
    path: 'enterprise',
    component: EnterpriseComponent,
    canActivate: [AuthGuard, UserGroupGuard],
    data: { enterpriseGuardRedirect: 'account/:username', roles: ['admin'] }
  },
  {
    path: 'notifications',
    canActivate: [AuthGuard, UserGroupGuard],
    component: NotificationsComponent
  },
  {
    path: 'fullReport',
    canActivate: [AuthGuard, UserGroupGuard],
    component: FullReportComponent
  },
  {
    path: 'research',
    component: MenuComponent,
    canActivate: [AuthGuard, UserGroupGuard]
  },
  {
    path: 'search',
    canActivate: [AuthGuard, UserGroupGuard],
    component: SearchComponent },
  {
    path: 'settings',
    component: SettingsComponent,
    canActivate: [AuthGuard, UserGroupGuard],
    data: { enterpriseGuardRedirect: 'account/:username' }
  },
  {
    path: 'detail/:username',
    loadChildren: () => import('./user_management/detail/detail.module').then(module => module.DetailModule),
    canActivate: [AuthGuard, UserGroupGuard],
    data: { enterpriseGuardRedirect: 'account/:username' }
  },
  {
    path: 'batch',
    component: BatchComponent,
    canActivate: [AuthGuard],
    data: { enterpriseGuardRedirect: 'account/:username' }
  },
  {
    path: 'document-checker',
    component: DocumentCheckerComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'structure-builder',
    component: AddDossierComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'user-loader',
    component: UserUploaderComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'screening',
    component: ScreeningComponent,
    canActivate: [AuthGuard, UserGroupGuard]
  },
  {
    path: 'reporting',
    loadChildren: () => import('./user_management/reporting/reporting.module').then(module => module.ReportingModule),
    canActivate: [AuthGuard, UserGroupGuard]
  },
  {
    path: 'dashboard',
    loadChildren: () => import('./user_management/dashboard/dashboard.module').then(module => module.DashboardModule),
    canActivate: [AuthGuard, UserGroupGuard]
  },
  {
    path: 'my-account',
    component: MyAccountComponent,
    canActivate: [AuthGuard, UserGroupGuard],
    data: { enterpriseGuardRedirect: 'account/:username' }
  },
  {
    path: 'package-manager',
    component: PackageManagerComponent,
    canActivate: [AuthGuard, UserGroupGuard]
  },
  {
    path: '404',
    component: Error404Component
  },
  { path: '**', redirectTo: '' }
];

export const routing = RouterModule.forRoot(appRoutes);
