/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./risk-assessment.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./risk-assessment.component";
import * as i3 from "../../../../_services/webData.service";
var styles_RiskAssessmentComponent = [i0.styles];
var RenderType_RiskAssessmentComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_RiskAssessmentComponent, data: {} });
export { RenderType_RiskAssessmentComponent as RenderType_RiskAssessmentComponent };
export function View_RiskAssessmentComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "col-md-3"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "div", [["style", "height: 5px;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 2, "div", [["class", "label"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["MARK AS:"])), (_l()(), i1.ɵeld(5, 0, null, null, 2, "div", [["class", "col-md-3"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "button", [["class", "btn btn-success btn-save-information"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.addRiskAssessment("approved") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Approved"])), (_l()(), i1.ɵeld(8, 0, null, null, 2, "div", [["class", "col-md-4"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 1, "button", [["class", "btn btn-warning btn-yellow"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.addRiskAssessment("further") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Further Analysis"])), (_l()(), i1.ɵeld(11, 0, null, null, 2, "div", [["class", "col-md-2"]], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 1, "button", [["class", "btn btn-danger btn-delete"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.addRiskAssessment("rejected") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Rejected\u00A0"]))], null, null); }
export function View_RiskAssessmentComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "risk-assessment", [], null, null, null, View_RiskAssessmentComponent_0, RenderType_RiskAssessmentComponent)), i1.ɵdid(1, 114688, null, 0, i2.RiskAssessmentComponent, [i3.WebDataService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var RiskAssessmentComponentNgFactory = i1.ɵccf("risk-assessment", i2.RiskAssessmentComponent, View_RiskAssessmentComponent_Host_0, {}, { riskAssessmentEvent: "riskAssessmentEvent" }, []);
export { RiskAssessmentComponentNgFactory as RiskAssessmentComponentNgFactory };
