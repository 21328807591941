<form #disclaimersForm="ngForm" class="editForm" novalidate>
    <div class="form-horizontal">
        <div class="tab-pane fade in active" style="padding: 15px 15px 25px; border: 1px solid #bbb;">
            <h4 class="head text-center">{{"DISCLAIMERS" | translate}}</h4>
            <br />
            <div class='row'>
                <div class='col-xs-offset-1 col-xs-10 col-sm-offset-1 col-sm-10 desclaimer-section-1' style="margin-left: 20px; padding: 0px;">
                    <label class="disclaimer-heading">{{ "DISC_DESC" | translate }}</label>
                    <ol>
                        <li>{{ "DISC_FIN_1" | translate}}</li>
                        <li>{{ "DISC_FIN_2" | translate}}</li>
                        <li>{{ "DISC_FIN_3" | translate}}</li>
                    </ol>
                    <div class="form-group" style="margin: 0px;">
                        <div class="checkbox">
                            <label>
                                <input id="onOwnBehalf" (change)="checkDisclaimers()" type="checkbox"
                                    #onOwnBehalf="ngModel" required name="onOwnBehalf"
                                    [(ngModel)]="disclaimers.onOwnBehalf" value="onOwnBehalf">
                                <ng-container *ngIf="!customOnboarding"><div [innerHTML]="disclaimerWithLinks"></div></ng-container>
                                <ng-container *ngIf="customOnboarding"> {{ "DISCLAIMERS_MAIN" | translate}}
                                </ng-container>
                            </label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</form>
<style>
@media (min-width: 768px){
    .desclaimer-section-1{
        margin: 0px !important;
        width: 100% !important;
    }
    .checkbox{
        width: fit-content;
        margin: 0 auto;
    }
    ol{
        width: fit-content;
        margin: 0 auto;
    }
    .disclaimer-heading{
        display: flex;
        justify-content: center;
    }
}
</style>
