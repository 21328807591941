import { Component, OnInit, ChangeDetectionStrategy, Input, Output, EventEmitter } from '@angular/core';
import { Document, EntityUpdate } from '../../_models';
import { AlertService, DataService, KYBService } from '../../_services';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

interface JsonFormValidators {
  min?: number;
  max?: number;
  required?: boolean;
  requiredTrue?: boolean;
  email?: boolean;
  minLength?: number;
  maxLength?: number;
  pattern?: string;
  nullValidator?: boolean;
}

interface JsonFormControlOptions {
  min?: string;
  max?: string;
  step?: string;
  icon?: string;
}

interface JsonFormControls {
  name: string;
  label: string;
  value: string;
  type: string;
  options?: JsonFormControlOptions;
  required?: boolean;
  validators: JsonFormValidators;
}

export interface JsonFormData {
  controls: JsonFormControls[];
}

@Component({
  selector: 'app-document-editor',
  templateUrl: 'documentEditor.component.html',
  styleUrls: [ './documentEditor.component.css' ],
  changeDetection: ChangeDetectionStrategy.Default
})

export class DocumentEditorComponent implements OnInit {

  @Input() jsonFormData: JsonFormData;
  @Input() documentId: any;
  @Input() roleId: any;
  @Input() entityId: any;
  @Input() e1Id: any;
  @Input() e2Id: any;
  @Input() isGeoEntity: boolean;
  @Input() mode: string;
  @Input() entityTypeId: any;
  @Input() primaryName: string;
  @Input() documentFieldId: string;
  @Output() reloadGeoEntitiesList = new EventEmitter<any>();

  fileId: any;

  documentMetadataResponse: any = {};
  documentContentResponse: any = {};
  fileContent: any = {};
  formDefinition: any = {};
  documentTypeId: any;
  documentTypeName: any;
  loading: any = false;
  description: string;
  validTo: string;
  document: Document;
  documentResponse: any = {};
  file: File;
  updatedEntity: EntityUpdate;

  public formData: JsonFormData;
  public myForm: FormGroup = this.fb.group({});

  constructor (
    private kybService: KYBService,
    private alertService: AlertService,
    private fb: FormBuilder,
    private dataService: DataService
  ) {}

  ngOnInit() {
    if (!this.documentFieldId) {
      this.documentFieldId = this.documentId;
    }

    this.kybService.getDocumentMetadata(this.documentFieldId).subscribe(
      data => {
        this.documentMetadataResponse = data;
        this.description = this.documentMetadataResponse.data.description;
        this.validTo = this.documentMetadataResponse.data.validTo;
        this.fileId = this.documentMetadataResponse.data.fileIds[0];
        this.documentTypeId = this.documentMetadataResponse.data.documentType.id;
        this.documentTypeName = this.documentMetadataResponse.data.documentType.documentTypeName;

        this.kybService.getFile(this.documentFieldId, this.fileId).subscribe(
          documentContentResponse => {
            this.documentContentResponse = documentContentResponse;

            if (!this.isGeoEntity) {
              this.fileContent = Object.assign({ description: this.description, validTo: this.validTo });
            }

            if (this.documentMetadataResponse.data.mimeType === 'application/json' || this.documentMetadataResponse.data.mimeType === 'text/plain') {
              if (JSON.parse(atob(this.documentContentResponse.data.content))) {
                this.fileContent = Object.assign(JSON.parse(atob(this.documentContentResponse.data.content)), this.fileContent);
              }

              // Removing empty key-value pairs from JSON object
              for (const key in this.fileContent) {
                if (this.fileContent.hasOwnProperty(key)) {
                  const value = this.fileContent[key];

                  if (value !== null) {
                    if (value.toString() === '' || key === 'primaryName') {
                      delete this.fileContent[key];
                    }

                    if (this.isGeoEntity) {
                      if (key === 'description' || key === 'validTo') {
                        delete this.fileContent[key];
                      }
                    }
                  }
                }
              }
            }

            this.formData = this.inputTransform(this.fileContent);

            if (!this.isGeoEntity) {
              if (this.formData.controls.findIndex((item) => item.name === 'description') === -1) {
                this.formData.controls.push({
                  label: 'description',
                  name: 'description',
                  type: 'text',
                  validators: {
                    required: true
                  },
                  value: this.description
                });
              } else if (this.formData.controls.findIndex((item) => item.name === 'validTo') === -1) {
                this.formData.controls.push({
                  label: 'expiration date',
                  name: 'validTo',
                  type: 'text',
                  validators: {
                    required: true
                  },
                  value: this.validTo
                });
              }
            }

            this.createForm(this.formData.controls);
          }, error => {}
        );
      }, error => {}
    );
  }

  inputTransform(fileContent: any) {
    const output = {'controls': []};

    for (const key in fileContent) {
      if (this.fileContent.hasOwnProperty(key)) {
        let type;

        if (typeof(fileContent[key]) === 'number') {
          type = 'number';
        } else {
          type = 'text';
        }

        output.controls.push({ 'name': key, 'label': key, 'value': fileContent[key], 'type': type, 'validators': { 'required': true } });
      }
    }

    return output;
  }

  createForm(controls: JsonFormControls[]) {
    for (const control of controls) {
      const validatorsToAdd = [];

      for (const [key, value] of Object.entries(control.validators)) {
        switch (key) {
          case 'min':
            validatorsToAdd.push(Validators.min(value));
            break;
          case 'max':
            validatorsToAdd.push(Validators.max(value));
            break;
          case 'required':
            if (value) {
              validatorsToAdd.push(Validators.required);
            }

            break;
          case 'requiredTrue':
            if (value) {
              validatorsToAdd.push(Validators.requiredTrue);
            }

            break;
          case 'email':
            if (value) {
              validatorsToAdd.push(Validators.email);
            }

            break;
          case 'minLength':
            validatorsToAdd.push(Validators.minLength(value));
            break;
          case 'maxLength':
            validatorsToAdd.push(Validators.maxLength(value));
            break;
          case 'pattern':
            validatorsToAdd.push(Validators.pattern(value));
            break;
          case 'nullValidator':
            if (value) {
              validatorsToAdd.push(Validators.nullValidator);
            }

            break;
          default:
            break;
        }
      }

      if (control.name === 'description') {
        control.value = this.description;
      } else if (control.name === 'validTo') {
        control.value = this.validTo;
      }

      this.myForm.addControl(
        control.name,
        this.fb.control(control.value, validatorsToAdd)
      );
    }
  }

  testJSON(text) {
    if (typeof text !== 'string') {
        return false;
    }

    try {
        JSON.parse(text);
        return true;
    } catch (error) {
        return false;
    }
  }

  onSubmit() {
    this.loading = true;
    this.kybService.updateDocumentMetadata(this.documentId, 'Document of type ' + this.documentTypeId
    + ' was edited.', null, this.myForm.value.description, null, null, this.myForm.value.validTo).subscribe(
      (updateDocumentData: any) => {
        if (updateDocumentData.data.mimeType !== 'image/json') {
          const file = new Blob([JSON.stringify(this.myForm.value)], { type: updateDocumentData.data.mimeType });
          this.file = this.blobToFile(file, this.documentTypeName);
          this.kybService.saveFile(this.file, this.documentId, this.fileId).subscribe(
            resSaveFile => {
              if (this.isGeoEntity === true) {
                this.updatedEntity = new EntityUpdate();
                this.updatedEntity.entityId = this.entityId;
                this.updatedEntity.primaryName = this.myForm.value.name;
                this.updatedEntity.entityTypeId = this.entityTypeId;

                this.kybService.updateEntity(this.updatedEntity).subscribe(
                  entityData => {
                    this.alertService.showSuccess('Document edited successfully.');
                    this.loading = false;
                    this.dataService.triggerCloseModalEvent(3);
                    this.reloadGeoEntitiesList.emit(true);
                  },
                  error => {
                  }
                );
              } else {
                this.updatedEntity = new EntityUpdate();
                this.updatedEntity.entityId = this.entityId;
                this.updatedEntity.primaryName = this.primaryName;
                this.updatedEntity.entityTypeId = this.entityTypeId;

                if (this.myForm.value.personPhone !== null) {
                  this.updatedEntity.entityPhoneNumber = this.myForm.value.personPhone;
                }

                if (this.myForm.value.contactPhone !== null) {
                  this.updatedEntity.entityPhoneNumber = this.myForm.value.contactPhone;
                }

                if (this.myForm.value.personEmail !== null) {
                  this.updatedEntity.entityEmail = this.myForm.value.personEmail;
                }

                if (this.myForm.value.contactEmail !== null) {
                  this.updatedEntity.entityEmail = this.myForm.value.contactEmail;
                }

                this.kybService.updateEntity(this.updatedEntity).subscribe(
                  entityData => {
                    this.alertService.showSuccess('Document edited successfully.');
                    this.loading = false;
                    this.dataService.triggerCloseModalEvent(2);
                  },
                  error => {
                    this.alertService.showError(error.error.errors[0].message);
                  }
                );
              }
            });
        }
      });
  }

  blobToFile(theBlob, fileName) {
    theBlob.lastModifiedDate = new Date();
    theBlob.name = fileName;
    return theBlob;
  }
}
