import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { config } from '../../../assets/configuration';
import { CallsService, KYBService } from '../../_services';
import { FormGroup, FormArray, FormBuilder } from '@angular/forms';
import { RequiredDoc } from '../../_models/requiredDoc.model';

@Component({
  moduleId: module.id,
  templateUrl: 'packageManager.component.html',
  styleUrls: [ './packageManager.component.css' ]
})

export class PackageManagerComponent implements OnInit, OnChanges  {

  onboardingEntity: any = {};
  representativeEntity: any = {};
  package: any = {};
  createPackageResponse: any = {};
  onboardingUrlResponse: any = {};
  documentTypeResponse: any = {};
  documentTypes: any = [];
  mainColor: string = config.mainColor;
  loading = false;

  @Input() requiredLink: RequiredLink;
  @Input() representativeRequiredLink: RequiredLink;
  requiredLinkForm: FormGroup;
  representativeRequiredDocsForm: FormGroup;

  get links(): FormArray {
    return this.requiredLinkForm.get('links') as FormArray;
  }

  get representativeLinks(): FormArray {
    return this.representativeRequiredDocsForm.get('representativeLinks') as FormArray;
  }

  constructor(
    private callsService: CallsService,
    private kybService: KYBService,
    private formBuilder: FormBuilder
  ) {
    this.createForm();
  }

  ngOnInit() {
    this.kybService.getDocumentTypes().subscribe(
      data => {
        this.documentTypeResponse = data;
        this.documentTypes = this.documentTypeResponse.data;
      },
      error => console.log(error)
    );
  }

  createPackage() {
    this.loading = true;
    this.onboardingEntity.ownerEmail = localStorage.getItem("username");
    this.onboardingEntity.entityType = "Counterparty";
    this.onboardingEntity.externalEntityId = Math.floor(Math.random() * 10000);
    this.representativeEntity.ownerEmail = localStorage.getItem("username");
    this.representativeEntity.phoneNumber = "+111";
    this.representativeEntity.entityType = "Representative";
    this.representativeEntity.externalEntityId = Math.floor(Math.random() * 10000);
    this.onboardingEntity.roles = [
      {
        "e1externalId": this.onboardingEntity.externalEntityId,
        "e2externalId": "",
        "roleType": "Identity:Counterparty",
        "requestedDocs": this.requiredLinkForm.value.links
      },
      {
        "e1externalId": this.onboardingEntity.externalEntityId,
        "e2externalId": this.representativeEntity.externalEntityId,
        "roleType": "Representative",
        "requestedDocs": this.representativeRequiredDocsForm.value.representativeLinks
      }
    ];
    this.package.onboardingEntities = [];
    this.package.onboardingEntities.push(this.onboardingEntity);
    this.package.representativeEntity = this.representativeEntity;
    console.log(this.package);
    this.callsService.createPackage(this.package).subscribe(
      data => {
        this.createPackageResponse = data;
        this.callsService.getOnboardingUrl(this.createPackageResponse.packageId).subscribe(
          onboardData => {
            this.onboardingUrlResponse = onboardData;
            window.alert(this.onboardingUrlResponse.url);
            this.loading = false;
          },
          error => {
            this.loading = false;
          }
        );
      },
      error => console.log(error)
    );
  }

  createForm() {
    this.requiredLinkForm = this.formBuilder.group({
      'links': this.formBuilder.array([])
    });
    this.representativeRequiredDocsForm = this.formBuilder.group({
      'representativeLinks': this.formBuilder.array([])
    });
  }

  ngOnChanges() {
    this.rebuildForm();
  }

  rebuildForm() {
    this.requiredLinkForm.reset({});
    this.setDocuments(this.requiredLink.links);
    this.representativeRequiredDocsForm.reset({});
    this.setRepresentativeDocuments(this.representativeRequiredLink.links);
  }

  setDocuments(requiredDocuments: RequiredDoc[]) {
    const requiredDocumentsFA = this.formBuilder.group(requiredDocuments);

    this.requiredLinkForm.setControl('links', requiredDocumentsFA);
  }

  setRepresentativeDocuments(requiredRepresentativeDocuments: RequiredDoc[]) {
    const requiredRepresentativeDocumentsFA = this.formBuilder.group(requiredRepresentativeDocuments);

    this.representativeRequiredDocsForm.setControl('links', requiredRepresentativeDocumentsFA);
  }

  addDocument() {
    this.links.push(this.formBuilder.group(new RequiredDoc(null, null, null)));
  }

  addRepresentativeDocument() {
    this.representativeLinks.push(this.formBuilder.group(new RequiredDoc(null, null, null)));
  }

  deleteDocument(requiredDocument) {
    this.links.controls.forEach((link, index, object) => {
      if (link.value === requiredDocument) {
        object.splice(index, 1);
      }
    });

    this.links.value.forEach((link, index, object) => {
      if (link === requiredDocument) {
        object.splice(index, 1);
      }
    });
  }

  deleteRepresentativeDocument(requiredRepresentativeDocument) {
    this.representativeLinks.controls.forEach((link, index, object) => {
      if (link.value === requiredRepresentativeDocument) {
        object.splice(index, 1);
      }
    });

    this.representativeLinks.value.forEach((link, index, object) => {
      if (link === requiredRepresentativeDocument) {
        object.splice(index, 1);
      }
    });
  }
}

export class RequiredLink {
  public links: RequiredDoc[];
}
