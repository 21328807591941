<form #personalForm="ngForm" class="editForm" novalidate>
    <div class="tab-pane fade in active">
        <div class='row'>
            <div class='col-sm-12'> I am creating a <select [(ngModel)]="entityTypeGeneral"
                    (change)="generalTypeChanged()" name="entityTypeGeneral">
                    <option value="PERSON">person</option>
                    <option value="LEGAL">legal</option>
                    <option value="GROUP">group</option>
                    <option value="STATE">state</option>
                    <option value="TREATY">treaty</option>
                </select>
                <br>
                <br>
                <ng-container *ngIf="entityTypeGeneral === '' || entityTypeGeneral === 'PERSON' || entityTypeGeneral === 'GROUP' || entityTypeGeneral === 'STATE' || entityTypeGeneral === 'TREATY' || entityTypeGeneral === 'GEO'">
                    <div *ngIf="entityTypeGeneral == '' || entityTypeGeneral == 'PERSON' || entityTypeGeneral == 'GEO'">
                        <label>Name: <b class="required">*</b></label>
                        <br>
                        <input name="primaryName" placeholder="Name" type="text" [(ngModel)]="primaryName">
                        <div class="alert alert-danger" [hidden]="primaryName">Name is required.</div>
                        <br>
                        <br *ngIf="primaryName">
                    </div>
                    <div *ngIf="entityTypeGeneral =='GROUP' || entityTypeGeneral == 'STATE' || entityTypeGeneral == 'TREATY'">
                        <label>Name: <b class="required">*</b></label>
                        <br>
                        <input name="primaryName" placeholder="Name" type="text" [(ngModel)]="primaryName">
                        <div class="alert alert-danger" [hidden]="primaryName">Name is required.</div>
                        <br>
                        <br *ngIf="primaryName">
                    </div>
                </ng-container>

                <ng-container *ngIf="entityTypeGeneral == 'LEGAL'">
                    <label>Company Name: <b class="required">*</b></label>
                    <br>
                    <input name="primaryName" placeholder="Company name" type="text" [(ngModel)]="primaryName">
                    <div class="alert alert-danger" [hidden]="primaryName">Company Name is required.</div>
                    <br *ngIf="primaryName">
                </ng-container>   
                <br />
                <ng-container *ngIf="entityType">
                    <div style="width: fit-content;">
                        with the type&nbsp;
                        <select style="width: 100%;" [(ngModel)]="entityType" (change)="typeChanged()" name="entityType">
                            <option *ngFor="let entityType of filteredEntityTypes" [value]="entityType.id">
                                {{entityType.legalPersonType}}
                            </option>
                        </select>
                    </div>
                </ng-container>
                <div *ngIf="entityTypeGeneral == 'PERSON' && (addEntityPhoneNumber || addEntityEmail )">
                    <br>
                    <ng-container *ngIf="addEntityPhoneNumber">
                        <label> Phone number: <b class="required">*</b> </label><br><input type="text" placeholder="Phone number" [(ngModel)]="personPhone" name="personPhone">&#160;&#160; 
                        <div class="alert alert-danger" [hidden]="personPhone">Phone number is required.</div>
                    </ng-container>
                    <div style="height: 10px;"></div>
                    <ng-container *ngIf="addEntityEmail">
                        <label> Email: <b class="required">*</b> </label><br><input type="text" placeholder="Email" [(ngModel)]="personEmail" name="personEmail">&#160;&#160; 
                        <div class="alert alert-danger" [hidden]="personEmail">Email is required.</div>
                    </ng-container>
                    <div style="height: 20px;"></div>
                </div>
                <div style="height: 10px;"></div> The entity has the following tags: <ng-select
                    class="custom" [items]="tags" [multiple]="true" [addTag]="addTagFn" (add)="createTag($event)"
                    bindLabel="name" [hideSelected]="true" [clearable]="false" (change)="tagsChanged($event)"
                    [(ngModel)]="selectedTags" appendTo="body" [ngModelOptions]="{standalone: true}">
                    <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                        <input id="item-{{index}}" type="checkbox" [ngModel]="item$.selected"
                            [ngModelOptions]="{standalone: true}" /> {{item.name}}
                    </ng-template>
                </ng-select>
                <app-person-form *ngIf="isPerson" [events]="eventsSubject.asObservable()"></app-person-form>
                <app-company-form *ngIf="isCompany" [events]="eventsSubject.asObservable()"></app-company-form>
                <div style="height: 30px"></div>
                <button [disabled]="(entityTypeGeneral === 'PERSON' && (!primaryName || !personPhone || !personEmail)) || (entityTypeGeneral !== 'PERSON' && (!primaryName))" class="btn btn-primary" style="background-color: #355B89;border-color: #355B89"
                    (click)="saveEntity()">Save Entity</button>
            </div>
        </div>
    </div>
</form>
