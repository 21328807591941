
    <div class='modal-header'>
      <h4 class='modal-title pull-left'>About</h4>
      <button type='button' class='close pull-right' aria-label='Close' (click)='bsModalRef.hide()'>
        <span aria-hidden='true'>&times;</span>
      </button>
    </div>
    <div class='modal-body'>
      <p>Version: 5efeaade 2024-03-23T12:15:59+00:00</p>
      <p>Release: 5efeaade</p>
    </div>
    <div class='modal-footer' style='text-align: right;'>
      <button type='button' class='btn btn-default btn-noBorder' (click)='bsModalRef.hide()'>Close</button>
    </div>
  