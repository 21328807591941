import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DetailComponent } from './detail.component';
import { DetailRoutingModule } from './detail-routing';
import { FormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { GeoRoleNameImportModule } from '../../../app/geoRoleName.pipe';
import { NgxOrgChartModule } from 'ngx-org-chart';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient } from '@angular/common/http';
import { ChartModule } from 'angular-highcharts';
import { MatchesImportModule } from '../matches/matches.component';


@NgModule({
  declarations: [
    DetailComponent
  ],

  imports: [
    CommonModule,
    DetailRoutingModule,
    MatchesImportModule,
    FormsModule,
    NgSelectModule,
    ChartModule,
    GeoRoleNameImportModule.forRoot(),
    NgxOrgChartModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })
  ],
  exports: [
    DetailComponent
  ]
})
export class DetailModule { }

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
