import * as tslib_1 from "tslib";
import { Router } from '@angular/router';
import { AlertService, CallsService, AuthenticationService } from '../../../_services';
/**
* Implements password changing directive
*/
var EmailTemplateComponent = /** @class */ (function () {
    function EmailTemplateComponent(router, callsService, alertService, authenticationService) {
        this.router = router;
        this.callsService = callsService;
        this.alertService = alertService;
        this.authenticationService = authenticationService;
        this.userName = '';
        this.email = '';
        this.response = {};
        this.template = {};
        this.registrationTemplate = null;
        this.whitelistTemplate = null;
        this.blacklistTemplate = null;
        this.moreInformationTemplate = null;
        this.registrationTemplateFilled = false;
        this.whitelistTemplateFilled = false;
        this.blacklistTemplateFilled = false;
        this.moreInformationTemplateFilled = false;
    }
    /**
    * Function used for processing registration email template
    * @param {FileList} files - input file
    */
    EmailTemplateComponent.prototype.processRegistrationTemplate = function (files) {
        var _this = this;
        if (files.item(0).type === 'text/html') {
            this.registrationTemplateFilled = true;
            var fileReader_1 = new FileReader();
            fileReader_1.onload = function (e) {
                _this.registrationTemplate = fileReader_1.result;
            };
            fileReader_1.readAsText(files.item(0));
        }
        else {
            this.alertService.showError('All templates must be HTML.');
        }
    };
    /**
    * Function used for processing whitelist email template
    * @param {FileList} files - input file
    */
    EmailTemplateComponent.prototype.processWhitelistTemplate = function (files) {
        var _this = this;
        if (files.item(0).type === 'text/html') {
            this.whitelistTemplateFilled = true;
            var fileReader_2 = new FileReader();
            fileReader_2.onload = function (e) {
                _this.whitelistTemplate = fileReader_2.result;
            };
            fileReader_2.readAsText(files.item(0));
        }
        else {
            this.alertService.showError('All templates must be HTML.');
        }
    };
    /**
    * Function used for processing blacklist email template
    * @param {FileList} files - input file
    */
    EmailTemplateComponent.prototype.processBlacklistTemplate = function (files) {
        var _this = this;
        if (files.item(0).type === 'text/html') {
            this.blacklistTemplateFilled = true;
            var fileReader_3 = new FileReader();
            fileReader_3.onload = function (e) {
                _this.blacklistTemplate = fileReader_3.result;
            };
            fileReader_3.readAsText(files.item(0));
        }
        else {
            this.alertService.showError('All templates must be HTML.');
        }
    };
    /**
    * Function used for processing more information email template
    * @param {FileList} files - input file
    */
    EmailTemplateComponent.prototype.processMoreInfoTemplate = function (files) {
        var _this = this;
        if (files.item(0).type === 'text/html') {
            this.moreInformationTemplateFilled = true;
            var fileReader_4 = new FileReader();
            fileReader_4.onload = function (e) {
                _this.moreInformationTemplate = fileReader_4.result;
            };
            fileReader_4.readAsText(files.item(0));
        }
        else {
            this.alertService.showError('All templates must be HTML.');
        }
    };
    /**
    * Function used for changing the password
    */
    EmailTemplateComponent.prototype.saveTemplate = function (form) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                // tslint:disable-next-line:max-line-length
                if (this.registrationTemplateFilled || this.whitelistTemplateFilled || this.blacklistTemplateFilled || this.moreInformationTemplateFilled) {
                    if (this.registrationTemplateFilled) {
                    }
                    if (this.whitelistTemplateFilled) { }
                    if (this.blacklistTemplateFilled) { }
                    if (this.moreInformationTemplateFilled) { }
                }
                else {
                    this.alertService.showError('Please upload at least one file.');
                }
                return [2 /*return*/];
            });
        });
    };
    return EmailTemplateComponent;
}());
export { EmailTemplateComponent };
