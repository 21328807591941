<div class="col-md-3">
    <div style="height: 5px;"></div>
    <div class="label">
            <span>RISK LEVEL:</span>
    </div>
</div>
<div class="col-md-3">
    <button type="button" class="btn btn-success btn-save-information" (click)="addRiskLevel('low')">Low Risk&nbsp;</button>
</div>
<div class="col-md-4">
    <button type="button" class="btn btn-warning btn-yellow" (click)="addRiskLevel('medium')">&nbsp;&nbsp;Medium Risk&nbsp;&nbsp;&nbsp;</button>
</div>
<div class="col-md-2">
    <button type="button" class="btn btn-danger btn-delete" (click)="addRiskLevel('high')">High Risk</button>
</div>
