/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./addEntitySimple.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../register/addDossier/addEntity/addEntity.component.ngfactory";
import * as i3 from "../../register/addDossier/addEntity/addEntity.component";
import * as i4 from "../../../_services/data.service";
import * as i5 from "../../register/data/formData.service";
import * as i6 from "../../../_services/kyb.service";
import * as i7 from "../../../_services/alert.service";
import * as i8 from "@angular/router";
import * as i9 from "../../../_services/config.service";
import * as i10 from "./addEntitySimple.component";
import * as i11 from "../../../_services/calls.service";
import * as i12 from "ngx-bootstrap/modal";
import * as i13 from "@angular/platform-browser";
var styles_AddEntitySimpleComponent = [i0.styles];
var RenderType_AddEntitySimpleComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_AddEntitySimpleComponent, data: {} });
export { RenderType_AddEntitySimpleComponent as RenderType_AddEntitySimpleComponent };
export function View_AddEntitySimpleComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [[":xmlns:height", "http://www.w3.org/1999/xhtml"], ["class", "col-lg-12 box"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "app-add-entity", [], null, null, null, i2.View_AddEntityComponent_0, i2.RenderType_AddEntityComponent)), i1.ɵdid(3, 114688, null, 0, i3.AddEntityComponent, [i4.DataService, i5.FormDataService, i6.KYBService, i7.AlertService, i8.Router, i9.ConfigService], null, null), (_l()(), i1.ɵeld(4, 0, null, null, 0, "div", [["class", "row"]], null, null, null, null, null))], function (_ck, _v) { _ck(_v, 3, 0); }, null); }
export function View_AddEntitySimpleComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "add-entity-simple", [], null, null, null, View_AddEntitySimpleComponent_0, RenderType_AddEntitySimpleComponent)), i1.ɵdid(1, 114688, null, 0, i10.AddEntitySimpleComponent, [i8.Router, i11.CallsService, i7.AlertService, i4.DataService, i6.KYBService, i1.ChangeDetectorRef, i12.BsModalService, i13.DomSanitizer], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var AddEntitySimpleComponentNgFactory = i1.ɵccf("add-entity-simple", i10.AddEntitySimpleComponent, View_AddEntitySimpleComponent_Host_0, { entityId: "entityId", primaryName: "primaryName" }, {}, []);
export { AddEntitySimpleComponentNgFactory as AddEntitySimpleComponentNgFactory };
