import { Component, OnInit, Output, EventEmitter, OnDestroy } from '@angular/core';
import { SearchService, WebCallsService, WebDataService } from '../../../../_services';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
    moduleId: module.id,
    // tslint:disable-next-line:component-selector
    selector: 'full-search-risk',
    templateUrl: 'fullSearchRisk.component.html',
    styleUrls: [ './fullSearchRisk.component.css' ],
})

export class FullSearchRiskComponent implements OnInit, OnDestroy {

  searchQuery = '';
  isFullSearch = false;
  firstName = '';
  lastName = '';
  dateOfBirth = '';
  country = '';
  fullSearchRisk: any = {};
  pepHits: any = [];
  pepHitsNo = 0;
  sanctionHits: any = [];
  sanctionHitsNo = 0;
  pep = false;
  sanction = false;
  reportResults: any = {};
  pepHitsNoHelper: any = {};
  sanctionHitsNoHelper: any = {};
  sourcesMetadata: any = [];

  @Output() fullRiskEvent = new EventEmitter();
  @Output() searchQueryChangeEvent = new EventEmitter();

  private isFullSearchSub: Subscription;
  private routeSub: Subscription;
  private fullSearchSub: Subscription;
  private currentQuerySub: Subscription;
  private currentPepHitsSub: Subscription;
  private currentSanctionHitsSub: Subscription;
  private reportResultsSub: Subscription;

  constructor (
    private callsService: WebCallsService,
    private dataService: WebDataService,
    private route: ActivatedRoute,
    private searchService: SearchService
  ) {}

  ngOnInit() {
    this.routeSub = this.route.queryParams.subscribe(params => {
      this.firstName = params['firstName'];
      this.lastName = params['lastName'];
      this.dateOfBirth = params['dateOfBirth'];
      this.country = params['country'];
    });

    this.callsService.getSources().subscribe(
      data => this.sourcesMetadata = data,
      error => {}
    );

    this.pepHits = [];
    this.sanctionHits = [];
    this.fullSearchRisk = {};
    this.reportResults = {};
    this.dataService.setCurrentReportResults(this.reportResults);
    this.reportResults.pepHits = [];
    this.pep = false;
    this.sanction = false;
    this.pepHitsNo = this.pepHits.length;

    this.isFullSearchSub = this.dataService.isFullSearch.subscribe(message => this.isFullSearch = message);

    if (this.firstName !== null && this.lastName !== null && this.dateOfBirth !== null && this.country !== null) {
      this.isFullSearch = true;
    } else {
      this.isFullSearch = false;
    }

    if (!this.dateOfBirth) {
      this.dateOfBirth = '';
    }

    if (!this.country) {
      this.country = '';
    }

    if (this.isFullSearch === true) {
        this.fullSearchSub = this.searchService.fullSearch(this.firstName, this.lastName, this.dateOfBirth, this.country).subscribe(
          data => {
            this.fullSearchRisk = data;

            if (this.fullSearchRisk.pepDetails.length !== 0) {
              this.processPEPDetails();
            } else {
              this.dataService.setPEPHitsNo(0);
            }

            if (this.fullSearchRisk.sanctionDetails.length !== 0) {
              this.processSanctionDetails();
            } else {
              this.dataService.setSanctionHitsNo(0);
            }
          },
          error => {}
        );
    }
  }

  processPEPDetails() {
    this.pepHits = [];
    let searchQuery = '';
    this.currentQuerySub = this.dataService.currentQuery.subscribe(message => searchQuery = message);
    this.pep = true;
    this.pepHits = this.fullSearchRisk.pepDetails;
    this.dataService.setCurrentPEPHits(this.pepHits);

    for (let i = 0; i < this.pepHits.length; i++) {
      for (let j = 0; j < this.sourcesMetadata.length; j++) {
        if (this.sourcesMetadata[j].source === this.pepHits[i].source) {
          this.pepHits[i].source = this.sourcesMetadata[j].displayName;
        }
      }
    }

    this.isFullSearch = true;
    this.dataService.setPEPHitsNo(this.pepHits.length);
    this.pepHitsNo = this.pepHits.length;
    this.pepHits.sort((x, y) => x.entityName === searchQuery ? -1 : y.entityName === searchQuery ? 1 : 0);
  }

  processSanctionDetails() {
    this.sanction = false;
    this.sanctionHits = [];
    let searchQuery = '';
    this.currentQuerySub = this.dataService.currentQuery.subscribe(message => searchQuery = message);
    this.sanction = true;
    this.isFullSearch = true;
    this.sanctionHits = this.fullSearchRisk.sanctionDetails;
    this.dataService.setCurrentSanctionHits(this.sanctionHits);

    for (let i = 0; i < this.sanctionHits.length; i++) {
      for (let j = 0; j < this.sourcesMetadata.length; j++) {
        if (this.sourcesMetadata[j].source === this.sanctionHits[i].source) {
          this.sanctionHits[i].source = this.sourcesMetadata[j].displayName;
        }
      }
    }

    this.dataService.setSanctionHitsNo(this.sanctionHits.length);
    this.sanctionHitsNo = this.sanctionHits.length;
    this.sanctionHits.sort((x, y) => x.entityName === searchQuery ? -1 : y.entityName === searchQuery ? 1 : 0);
  }

  runFullCheck($event) {
    this.firstName = $event.firstName;
    this.lastName = $event.lastName;
    this.dateOfBirth = $event.dob;
    this.country = $event.country;
    this.dataService.setfirstName(this.firstName);
    this.dataService.setlastName(this.lastName);
    this.dataService.setDob(this.dateOfBirth);
    this.reportResults = this.dataService.reportResults.getValue();
    this.reportResults.pepHits = [];
    this.dataService.setCurrentPEPHits(this.reportResults.pepHits);
    this.reportResults.sanctionHits = [];
    this.dataService.setCurrentSanctionHits(this.reportResults.sanctionHits);
    this.fullSearchRisk = {};
    this.pep = false;
    this.sanction = false;

    this.fullSearchSub = this.searchService.fullSearch(this.firstName, this.lastName, this.dateOfBirth, this.country).subscribe(
      data => {
        this.fullSearchRisk = data;

        if (this.fullSearchRisk.pepDetails.length !== 0) {
          this.processPEPDetails();
        } else {
          this.dataService.setPEPHitsNo(0);
        }

        if (this.fullSearchRisk.sanctionDetails.length !== 0) {
          this.processSanctionDetails();
        } else {
          this.dataService.setSanctionHitsNo(0);
        }
      },
      error => {}
    );

    this.searchQueryChangeEvent.emit({ firstName: this.firstName, lastName: this.lastName, dob: this.dateOfBirth, country: this.country });
  }

  receiveNewSearchEvent() {
    this.pep = false;
    this.sanction = false;
  }

  reset() {
    this.pep = false;
    this.sanction = false;
  }

  addNotePEP(item, note) {
    this.currentPepHitsSub = this.dataService.currentPepHits.subscribe(
      message => {
        this.pepHits = message;

        for (let i = 0; i < this.pepHits.length; i++) {
          if (this.pepHits[i].description === item.description) {
            this.pepHits[i].note = note;
          }
        }
      }
    );

    this.dataService.setCurrentPEPHits(this.pepHits);
  }

  addNoteSanction(item, note) {
    this.currentSanctionHitsSub = this.dataService.currentSanctionHits.subscribe(
      message => {
        this.sanctionHits = message;

        for (let i = 0; i < this.sanctionHits.length; i++) {
          if (this.sanctionHits[i].description === item.description) {
            this.sanctionHits[i].note = note;
          }
        }
      }
    );

    this.dataService.setCurrentSanctionHits(this.sanctionHits);
  }


  addToReportPEP(hit, status) {
    if (this.reportResults === null) {
      this.reportResults = [];
      this.reportResults.articles = [];
      this.reportResults.pepHits = [];
      this.reportResults.sanctionHits = [];
    }

    this.reportResultsSub = this.dataService.report_results.subscribe(
      message => {
        this.reportResults.pepHits = message.pepHits;
    });

    // Adding status to hit
    if (status === 'HIT') {
      hit.status = 'HIT';
    }

    if (status === 'FURTHER RESEARCH') {
      hit.status = 'FURTHER RESEARCH';
    }

    if (status === 'FALSE POSITIVE') {
      hit.status = 'FALSE POSITIVE';
    }

    if (this.reportResults.pepHits === undefined) {
      this.reportResults.pepHits = [];
    }

    this.reportResults.pepHits.push(hit);
    this.pepHitsNo = this.pepHitsNo - 1;
    this.discardResultPEP(hit.entityName);
    this.dataService.setCurrentReportResults(this.reportResults);
    // Send risk event to report preview
    this.fullRiskEvent.emit();
  }

  addToReportSanction(hit, status) {
    if (this.reportResults === null) {
      this.reportResults = [];
      this.reportResults.articles = [];
      this.reportResults.pepHits = [];
      this.reportResults.sanctionHits = [];
    }

    if (this.reportResults.sanctionHits === undefined) {
      this.reportResults.sanctionHits = [];
    }

    this.reportResultsSub = this.dataService.report_results.subscribe(
      message => {
        this.reportResults.sanctionHits = message.sanctionHits;
      }
    );

    if (status === 'HIT') {
      hit.status = 'HIT';
    }

    if (status === 'FURTHER RESEARCH') {
      hit.status = 'FURTHER RESEARCH';
    }

    if (status === 'FALSE POSITIVE') {
      hit.status = 'FALSE POSITIVE';
    }

    this.reportResults.sanctionHits.push(hit);
    this.discardResultSanction(hit.entityName);
    this.dataService.setCurrentReportResults(this.reportResults);
    this.fullRiskEvent.emit();
  }

  addAllToReportPEP(status) {
    if (this.reportResults === null) {
      this.reportResults = [];
      this.reportResults.articles = [];
      this.reportResults.pepHits = [];
      this.reportResults.sanctionHits = [];
    }

    this.reportResultsSub = this.dataService.report_results.subscribe(
      message => this.reportResults.pepHits = message.pepHits
    );

    if (this.reportResults.pepHits === undefined) {
      this.reportResults.pepHits = [];
    }

    this.currentPepHitsSub = this.dataService.currentPepHits.subscribe(
      message => this.pepHits = message
    );

    if (status === 'HIT') {
      for (let i = 0; i < this.pepHits.length; i++) {
        this.pepHits[i].status = 'HIT';
      }
    }

    if (status === 'FURTHER RESEARCH') {
      for (let i = 0; i < this.pepHits.length; i++) {
        this.pepHits[i].status = 'FURTHER RESEARCH';
      }
    }

    if (status === 'FALSE POSITIVE') {
      for (let i = 0; i < this.pepHits.length; i++) {
        this.pepHits[i].status = 'FALSE POSITIVE';
      }
    }

    for (let j = 0; j < this.pepHits.length; j++) {
      this.reportResults.pepHits.push(this.pepHits[j]);
    }

    this.pepHitsNo = 0;
    this.dataService.setCurrentReportResults(this.reportResults);
    this.fullRiskEvent.emit();
    this.discardallResultPEP();
  }

  addAllToReportSanction(status) {
    this.reportResults = this.dataService.reportResults.getValue();

    if (this.reportResults === null) {
      this.reportResults = [];
      this.reportResults.articles = [];
      this.reportResults.pepHits = [];
      this.reportResults.sanctionHits = [];
    }

    this.reportResults = this.dataService.report_results.subscribe(
      message => this.reportResults.sanctionHits = message.sanctionHits
    );

    if (this.reportResults.sanctionHits === undefined) {
      this.reportResults.sanctionHits = [];
    }

    if (status === 'HIT') {
      for (let i = 0; i < this.sanctionHits.length; i++) {
        this.sanctionHits[i].status = 'HIT';
      }
    }

    if (status === 'FURTHER RESEARCH') {
      for (let i = 0; i < this.sanctionHits.length; i++) {
        this.sanctionHits[i].status = 'FURTHER RESEARCH';
      }
    }

    if (status === 'FALSE POSITIVE') {
      for (let i = 0; i < this.sanctionHits.length; i++) {
        this.sanctionHits[i].status = 'FALSE POSITIVE';
      }
    }

    for (let j = 0; j < this.sanctionHits.length; j++) {
      this.reportResults.sanctionHits.push(this.sanctionHits[j]);
    }

    this.sanctionHitsNo = 0;
    this.dataService.setCurrentReportResults(this.reportResults);
    this.fullRiskEvent.emit();
    this.discardallResultSanction();
  }

  discardResultPEP(entityName: any) {
    this.currentPepHitsSub = this.dataService.currentPepHits.subscribe(
      message => this.pepHits = message
    );

    for (let i = 0; i < this.pepHits.length; i++) {
        if (this.pepHits[i].entityName === entityName) {
          this.pepHits.splice(i, 1);

          if (this.pepHits.length === 0) {
            this.discardallResultPEP();
          }
        }
    }

    this.dataService.setCurrentPEPHits(this.pepHits);
    this.pepHitsNo = this.pepHits.length;
  }

  discardResultSanction(entityName: any) {
    for (let i = 0; i < this.sanctionHits.length; i++) {
        if (this.sanctionHits[i].entityName === entityName) {
          this.sanctionHits.splice(i, 1);

          if (this.sanctionHits.length === 0) {
            this.discardallResultSanction();
          }
        }
    }

    this.dataService.setCurrentSanctionHits(this.sanctionHits);
    this.sanctionHitsNo = this.sanctionHits.length;
  }

  discardallResultPEP() {
    this.pepHits.length = 0;
    this.pepHitsNo = 0;
  }

  discardallResultSanction() {
    this.sanctionHits.length = 0;
    this.sanctionHitsNo = 0;
  }

  ngOnDestroy() {
    if (this.isFullSearchSub !== undefined) {
      this.isFullSearchSub.unsubscribe();
    }

    if (this.routeSub !== undefined) {
      this.routeSub.unsubscribe();
    }

    if (this.fullSearchSub !== undefined) {
      this.fullSearchSub.unsubscribe();
    }

    if (this.currentQuerySub !== undefined) {
      this.currentQuerySub.unsubscribe();
    }

    if (this.currentPepHitsSub !== undefined) {
      this.currentPepHitsSub.unsubscribe();
    }

    if (this.currentSanctionHitsSub !== undefined) {
      this.currentSanctionHitsSub.unsubscribe();
    }

    if (this.reportResultsSub !== undefined) {
      this.reportResultsSub.unsubscribe();
    }
  }
}
