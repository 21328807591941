import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SearchService, WebDataService } from '../../../../_services/index';

@Component({
    moduleId: module.id,
    // tslint:disable-next-line:component-selector
    selector: 'information',
    templateUrl: 'information.component.html',
    styleUrls: [ './information.component.css' ],
})

export class InformationComponent implements OnInit {

  searchQuery: string;
  encodedQuery: string;
  firstName: any = '';
  lastName: any = '';
  dateOfBirth: any = '';
  dob: any = '';
  country: any = '';
  results: any = {};
  count: any = 0;

  @Output() gotResultsEvent = new EventEmitter();

  constructor(
    private route: ActivatedRoute,
    private searchService: SearchService,
    private dataService: WebDataService
  ) {}

  ngOnInit() {
    this.count = 0;

    this.route.queryParams.subscribe(params => {
      this.firstName = params['firstName'];
      this.lastName = params['lastName'];
      this.dateOfBirth = params['dateOfBirth'];
      this.country = params['country'];

      if (this.firstName !== undefined || this.lastName !== undefined || this.dateOfBirth || undefined || this.country !== undefined) {
        this.getResults();
      }
    });
  }

  receiveEvents($event) {
    this.encodedQuery = this.dataService.searchQuery.getValue();

    this.searchService.mediaEntityName(this.encodedQuery).subscribe(
      data => {
        if (data !== null) {
          this.results = data;

          for (let i = 0; i < this.results.topics.length; i++) {
            this.count += this.results.topics[i].count;
          }

          this.dataService.setCount(this.count);
          this.dataService.setCurrentResults(this.results.topics);
          this.dataService.setInitialResults(this.results.topics);
          this.gotResultsEvent.emit();
        }
      },
      error => {
      }
    );
  }

  getResults() {
    this.count = 0;
    this.dataService.setCount(this.count);
    this.dataService.setCount(this.count);
    this.dataService.setfirstName(this.firstName);
    this.dataService.setlastName(this.lastName);
    this.dataService.setDob(this.dateOfBirth);
    this.searchQuery = this.firstName + ' ' + this.lastName;
    this.dataService.setQuery(this.searchQuery);

    if (this.searchQuery) {
      if (this.firstName !== null && this.lastName !== null && this.dateOfBirth !== null && this.country !== null) {
        this.dataService.setIsFullSearch(true);
      } else {
        this.dataService.setIsFullSearch(false);
      }

      this.encodedQuery = encodeURIComponent(this.searchQuery);
      this.searchQuery = this.searchQuery.replace(/\s+/g, '');
      this.searchQuery = this.searchQuery.toLowerCase();

      localStorage.removeItem('initialResults');

      this.searchService.mediaEntityName(this.encodedQuery).subscribe(
        data => {
          if (data !== null) {
            this.results = data;

            for (let i = 0; i < this.results.topics.length; i++) {
              this.count += this.results.topics[i].count;
            }

            this.dataService.setCount(this.count);
            this.dataService.setCurrentResults(this.results.topics);
            this.dataService.setInitialResults(this.results.topics);
            this.gotResultsEvent.emit();
          }
        },
        error => {
        }
      );
    }
  }
}
