import { Component, OnInit } from '@angular/core';
import { AlertService } from '../../../../_services';
import { BrandingSetup } from './../../../../branding/branding.model';
import { BrandingService } from './../../../../branding/branding.service';

@Component({
  moduleId: module.id,
  selector: 'app-branding-settings',
  templateUrl: 'brandingSettings.component.html',
  styleUrls: ['./brandingSettings.component.css']
})

export class BrandingSettingsComponent implements OnInit {

  brandingSetup: BrandingSetup;
  imageLoaded: boolean;
  iconColor: any;
  overlayColor: any;
  loaded: boolean;
  imageSrc: any;

  constructor(
    private brandingService: BrandingService,
    private alertService: AlertService,
  ) {}

  ngOnInit() {
    this.brandingService.brandingSetup.subscribe((b: BrandingSetup) => this.brandingSetup = b);
  }

  public updateBranding(): void {
    this.brandingService.setBranding(this.brandingSetup).subscribe(res => {
      this.brandingService.getBranding();
      this.alertService.showSuccess('Branding has been updated');
    });
  }

  public updateColor(data: any) {
    this.brandingSetup.mainColor = data.color.hex;
  }


  handleImageLoad() {
    this.imageLoaded = true;
    this.iconColor = this.overlayColor;
  }

  handleInputChange(e) {
    const file = e.dataTransfer ? e.dataTransfer.files[0] : e.target.files[0];

    const pattern = /image-*/;
    const reader = new FileReader();

    if (!file.type.match(pattern)) {
      alert('invalid format');
      return;
    }

    this.loaded = false;

    reader.onload = this._handleReaderLoaded.bind(this);
    reader.readAsDataURL(file);
  }

  handleInputChangeReport(e) {
    const file = e.dataTransfer ? e.dataTransfer.files[0] : e.target.files[0];

    const pattern = /image-*/;
    const reader = new FileReader();

    if (!file.type.match(pattern)) {
      alert('invalid format');
      return;
    }

    this.loaded = false;

    reader.onload = this._handleReaderLoadedReport.bind(this);
    reader.readAsDataURL(file);
  }

  _handleReaderLoaded(e) {
    const reader = e.target;
    this.brandingSetup.websiteLogo = reader.result;
    this.loaded = true;
  }

  _handleReaderLoadedReport(e) {
    const reader = e.target;
    this.brandingSetup.reportLogo = reader.result;
    this.loaded = true;
  }

  cancel() {
    this.imageSrc = 'null';
  }
}
