import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';

@Injectable()
export class ConfigService {

    private _config: Object;

    constructor(private http: HttpClient) {}

    load() {
        return new Promise((resolve, reject) => {
            this.http.get('assets/config/configuration.json')
                .subscribe((data) => {
                    this._config = data;
                    console.log(this._config);
                    resolve(true);
                },
                (error: any) => {
                    console.error(error);
                    return throwError(error.json().error || 'Server error');
                });
        });
    }

    // Gets a value of specified property in the configuration file
    get(key: any) {
        return this._config[key];
    }
}
