import { NgModule } from '@angular/core';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'orgLinksPipe'})
export class OrgLinksPipe implements PipeTransform {
  transform(value: string): string[] {
    let result = [];

    result = value.split(' ');

    return result;
  }
}

@NgModule({
  declarations: [
    OrgLinksPipe
  ],
  exports: [
    OrgLinksPipe
  ]
})

export class OrgLinksImportModule {
  static forRoot() {
    return {
        ngModule: OrgLinksImportModule,
        providers: [],
    };
 }

}
