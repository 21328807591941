<div class="col-md-12" style="padding-left: 0px; padding-right: 0px;" xmlns:height="http://www.w3.org/1999/xhtml">
  <div class="row">
    <div class="col-md-12" style="">
      <div style="height: 20px"></div>
      <p class="text-center" style="color: black;font-family: 'Open Sans', sans-serif; font-weight: 400;">Entities with probable matches
      </p>
      <div style="height: 20px"></div>
      <button *ngIf="userPermissions.indexOf('UpdateMatch') !== -1" class="btn btn-primary" [disabled]="noResults"
        style="padding: 8px;background-color:#355B89;border-color: #355B89"
        (click)="displayScreenerModal(probableMatchesCollection)">Start Evaluating&nbsp;&nbsp;<i class="fa fa-play"
          aria-hidden="true" style="font-size: 12px;"></i>
      </button>
      <div style="height: 20px"></div>
      <button class="btn btn-primary btn-filter" (click)="toggleFilters()">Filters&nbsp;<mat-icon>filter_list</mat-icon></button>
      <div *ngIf="displayFilters">
        <ul class="list-unstyled list-inline" style="width: 100%;padding-left: 5px; padding-right: 0px;">
          <div style="height: 20px;"></div> Match status:&nbsp;&nbsp;
          <li class="list-inline-item">
            <ng-select style="width: 275px;" [(ngModel)]="matchStatus" class="custom" [items]="matchStatuses"
              [clearable]="false" bindLabel="name" bindValue="id" appendTo="body" (change)="tagsChanged($event)">
            </ng-select>
          </li>
        </ul>
        <div style="height: 10px"></div>
        <ul class="list-unstyled list-inline" style="width: 100%;padding-left: 0px;">
          <li class="list-inline-item" style="width: 100%; padding-left: 5px; padding-right: 0px;">
            <div style="height: 10px;"></div> Select the tag(s) that you would like to see: <div style="height: 10px;">
            </div>
            <ng-select class="custom" [items]="userTags" [multiple]="true" bindLabel="name" [closeOnSelect]="false"
              bindValue="id" [clearable]="false" (change)="tagsChanged($event)" [(ngModel)]="includedTags">
              <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                <input id="item-{{index}}" type="checkbox" [ngModel]="item$.selected" /> {{item.name}}
              </ng-template>
            </ng-select>
          </li>
          <div class="" style="display: flex; grid-column-gap: 10px; margin-top: 10px; padding-left: 5px; padding-right: 0px;">
            <li class="list-inline-item"><button (click)="selectAllTags()"
              class="btn btn-success btn-select-clear">Select all</button></li>
            <li class="list-inline-item"><button (click)="deselectAllTags()"
                class="btn btn-success btn-select-clear">Clear all</button></li>
          </div>
        </ul>
      </div>
      <div style="height: 20px"></div>
      <div class="table-responsive" style="padding-left: 0px;padding-right: 0px;overflow-x: visible;">
        <div class="row">
          <div class="col-sm-6" style="text-align: left;"> Show <select [(ngModel)]="pageSize"
              (ngModelChange)="pageSizeChanged()">
              <option [value]="30">30</option>
              <option [value]="50">50</option>
              <option [value]="100">100</option>
              <option [value]="totalItems">All</option>
            </select> entities </div>
          <div class="col-sm-6" style="text-align: right;"> Search: <input type="text" [(ngModel)]="searchQuery"
              (ngModelChange)="search()" />
          </div>
        </div>
        <div style="height: 20px"></div>
        <table id="entity-table" class="table table-responsive table-hover" style="color: black;">
          <thead>
            <tr>
              <th>ID</th>
              <th>Name&nbsp;<i id="name-asc-sorticon" class="fa fa-long-arrow-up" aria-hidden="true"
                  style="opacity: 0.4;font-size: 13px;" (click)="sort('name','asc')"></i>&nbsp;<i
                  id="name-desc-sorticon" class="fa fa-long-arrow-down" aria-hidden="true"
                  style="opacity: 0.4;font-size: 13px;" (click)="sort('name','desc')"></i></th>
              <th>Probable matches&nbsp;<i id="probableMatchesCount-asc-sorticon" class="fa fa-long-arrow-up"
                  aria-hidden="true" style="opacity: 0.4;font-size: 13px;"
                  (click)="sort('probableMatchesCount','asc')"></i>&nbsp;<i id="probableMatchesCount-desc-sorticon"
                  class="fa fa-long-arrow-down" aria-hidden="true" style="opacity: 0.4;font-size: 13px;"
                  (click)="sort('probableMatchesCount','desc')"></i></th>
              <th>Confirmed matches&nbsp;<i id="confirmedMatchesCount-asc-sorticon" class="fa fa-long-arrow-up"
                  aria-hidden="true" style="opacity: 0.4;font-size: 13px;"
                  (click)="sort('confirmedMatchesCount','asc')"></i>&nbsp;<i id="confirmedMatchesCount-desc-sorticon"
                  class="fa fa-long-arrow-down" aria-hidden="true" style="opacity: 0.4;font-size: 13px;"
                  (click)="sort('confirmedMatchesCount','desc')"></i></th>
              <th>Rejected matches&nbsp;<i id="whiteListedMatchesCount-asc-sorticon" class="fa fa-long-arrow-up"
                  aria-hidden="true" style="opacity: 0.4;font-size: 13px;"
                  (click)="sort('whiteListedMatchesCount','asc')"></i>&nbsp;<i
                  id="whiteListedMatchesCount-desc-sorticon" class="fa fa-long-arrow-down" aria-hidden="true"
                  style="opacity: 0.4;font-size: 13px;" (click)="sort('whiteListedMatchesCount','desc')"></i></th>
              <th>Further research matches&nbsp;<i id="toFurtherResearchMatchesCount-asc-sorticon"
                  class="fa fa-long-arrow-up" aria-hidden="true" style="opacity: 0.4;font-size: 13px;"
                  (click)="sort('toFurtherResearchMatchesCount','asc')"></i>&nbsp;<i
                  id="toFurtherResearchMatchesCount-desc-sorticon" class="fa fa-long-arrow-down" aria-hidden="true"
                  style="opacity: 0.4;font-size: 13px;" (click)="sort('toFurtherResearchMatchesCount','desc')"></i></th>
              <th>Detail</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngIf="isEmpty" aria-colspan="7">
              <td colspan="7" style="text-align: center;">
                <div style="height: 20px;"></div>
                <p style="text-align: center;font-family: Arial,sans-serif;font-size: 14px;">No entities found.</p>
                <div style="height: 20px;"></div>
              </td>
            </tr>
            <tr *ngFor="let entity of entities">
              <td>{{entity.id}}</td>
              <td>{{entity.entityName}}</td>
              <td>{{entity.probableMatches}}</td>
              <td>{{entity.confirmedMatches}}</td>
              <td>{{entity.whitelistedMatches}}</td>
              <td>{{entity.furtherResearchMatches}}</td>
              <td><a style="color: black" [routerLink]="['/detail', entity.id]"><u>Detail</u></a></td>
            </tr>
          </tbody>
          <tfoot style="border: none;">
            <tr aria-colspan="7" style="border: none;">
              <td colspan="7" style="border: none;">
                <div style="height: 5px;"></div>
                <p
                  style="font-family: Arial,sans-serif;font-size: 14px;color: #333;margin-bottom: -15px;margin-left: -10px;">
                  Showing <b>{{numberOfElements}}</b> of <b>{{totalItems}}</b> entities</p>
              </td>
            </tr>
          </tfoot>
        </table>
        <pagination [maxSize]="5" [totalItems]="totalItems" [itemsPerPage]="pageSize" [(ngModel)]="currentPage"
          (pageChanged)="pageChanged($event)"></pagination>
        <div style="height: 20px"></div>
        <button class="btn btn-primary" style="padding: 8px;background-color:#355B89;border-color: #355B89"
          (click)="exportCSV()">Export CSV&nbsp;&nbsp;<i class="fa fa-file-text-o" aria-hidden="true"
            style="font-size: 12px;"></i></button>
      </div>
    </div>
  </div>
</div>