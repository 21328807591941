<form #passwordForm="ngForm" class="editForm" novalidate>
        <h3 class="text-center">PEP/Sanction Screener Settings</h3><br>
        <div style="height: 10px;"></div>
        <span style="font-size: 16px;">Current settings:</span>
            <div style="height: 5px;"></div>
            <ul>
                <li><b>Periodicity:</b> {{screenerConfig?.periodicity}}</li>
                <li><b>Weekend operations:</b> {{screenerConfig?.includeScreeningOnWeekend}}</li>
                <li><b>Mass screening:</b> {{screenerConfig?.massiveScreening}}</li>
                <li><b>Stop words:</b> {{screenerConfig?.stopWords}}</li>
                <li><b>Confidence threshold:</b> {{screenerConfig?.confidenceThreshold}}</li>
            </ul>
        <div style="height: 10px;"></div>
        <p style="font-size: 18px;"><b>WARNING:</b> Saving these settings will overwrite all current screener settings in the database and reset your screening jobs.</p>
        <div class="form-group">
            <label class="control-label" for="periodicity"><b>Periodicity:</b>&nbsp;</label>  
            <select style="color: black;" id="periodicity" class="ng-control" name="risk" [(ngModel)]="periodicity" value="periodicity" #periodicityy="ngModel">
                <option value="DAILY">Daily</option>
                <option value="WEEKLY">Weekly</option>
                <option value="MONTHLY">Monthly</option>
                <option value="QUARTERLY">Quarterly</option>
            </select>
        </div>
        <div class="form-group">
            <label class="control-label" for="weekend"><b>Weekend operations:</b></label>  
            <input type="checkbox" style="color: black;" id="weekend" class="ng-control" name="weekend" [(ngModel)]="weekend" value="weekend" #weekendd="ngModel">
        </div>
        <div class="form-group">
            <label class="control-label" for="massScreening"><b>Mass screening:</b></label>  
            <input type="checkbox" style="color: black;" id="massScreening" class="ng-control" name="massScreening" 
            [(ngModel)]="massScreening" value="massScreening" #massScreeningg="ngModel">
        </div>
        <div class="form-group">
            <label class="control-label" for="stopwords"><b>Stop words:</b></label><br>
            ​<textarea id="stopwords" rows="5" style="height:auto; max-width: 920px;" class="ng-control md-textarea form-control"
                placeholder="use ; to separate each word"
                name="stopwords" [(ngModel)]="screenerConfig.stopWords" #stopwordss="ngModel">
            </textarea>
        </div>
        <div class="form-group">
            <label class="control-label" for="confidence"><b>Confidence threshold (use slider to select):</b> {{confidence}}</label><br>
            <br>
            <input type="range" min="70" value="confidence" max="100" step="1" style="color: black; max-width: 920px;" id="confidence" class="ng-control" name="confidence" [(ngModel)]="confidence" value="confidence" #confidencee="ngModel">
        </div>
        <div style="height: 10px;"></div>
        <div class="form-group">
        <button type="button" class="btn btn-primary" [disabled]="!passwordForm.valid" [style.background-color]="mainColor" (click)="saveSettings()"> Save Settings </button>
        </div>
</form>
