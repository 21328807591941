import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AlertService, CallsService, AuthenticationService, KYBService } from '../../../_services';
import { BsModalService } from 'ngx-bootstrap/modal';
import { config } from '../../../../assets/configuration';
import * as FileSaver from 'file-saver';
import { formatDate } from '@angular/common';
import { Chart } from 'angular-highcharts';
import * as svg from 'save-svg-as-png';
import * as htmlToImage from 'html-to-image';

@Component({
    moduleId: module.id,
    // tslint:disable-next-line:component-selector
    selector: 'app-entity-report',
    templateUrl: 'entityReport.component.html',
    styleUrls: ['./entityReport.component.css']
})

export class EntityReportComponent implements OnInit {

  allEntitiesResponse: any = {};
  entities: any = [];
  entitiesLoading = false;
  entityResponse: any = {};
  rolesResponse: any = {};
  documentContentResponse: any = {};
  basicInformation: any = {};
  pdfResponse: any = {};
  loading2 = false;
  eventsForEntityResponse: any = {};
  reportLogo: any = config.reportLogo;
  entityId = '';
  loadingImg: any = config.loadingImg;
  timestampFrom: any = null;
  timestampTo: any = null;
  testFile: any;

  controlChartString: any;

  tags: any = [];
  entityName = '';

  relationResponse: any = {};
  controlResponse: any = {};
  UBOResponse: any = {};
  exclamationImage = '../../../assets/images/exclamation.png';
  chart: Chart;
  chart2: Chart;
  controlChart: Chart;
  uboChart: Chart;
  emptyUBO = false;
  emptyControl = false;
  entityType: any;
  relationshipGraphFile: File;
  controlChartFile: File;
  uboChartFile: File;

  relationshipGraphData: any = [];
  relationshipGraphString: any;

  newChilds = [];
  nodes: any = [];

  controls: any = [];
  controlData: any = [];
  controlNodes: any = [];

  owners: any = [];
  uboData: any = [];
  uboNodes: any = [];
  entityLevel = 0;
  titles: any = [];

  uboLevels: any = [];
  index: any;
  uboChartString: any;

  uboChartEncodedImage = '';
  controlChartEncodedImage = '';

  counter = 0;

  exportResponse: any;

  constructor(
      private router: Router,
      private callsService: CallsService,
      private alertService: AlertService,
      private kybService: KYBService,
      private authenticationService: AuthenticationService,
      private modalService: BsModalService
  ) {}

  ngOnInit() {
      this.testFile = '../../../../assets/images/exclamation.png';
      this.timestampTo = new Date();
      this.timestampFrom = new Date();

      this.timestampFrom.setDate(this.timestampFrom.getDate() - 7);

      this.timestampFrom = this.timestampFrom.toISOString();
      this.timestampTo = this.timestampTo.toISOString();

      this.kybService.getAllEntities({}).subscribe(
          data => {
              this.allEntitiesResponse = data;
              this.entities = this.allEntitiesResponse.data;
              this.entitiesLoading = false;
          },
          error => { }
      );
  }

  blobToFile(data: any, type: string, fileName: string) {
    const a = document.createElement('a');
    document.body.appendChild(a);
    a.style.display = 'none';
    const blob = new Blob([data], { type: type });
    const url = window.URL.createObjectURL(blob);
    a.href = url;
    a.download = fileName;
    a.click();
    window.URL.revokeObjectURL(url);
  }

  prettyTimestamp(timestamp: any) {
      return formatDate(timestamp, 'dd/MM/yyyy, h:mm:ss a z', 'en');
  }

  clientDossierForEntity(entityId: any) {
    this.kybService.downloadClientDossier(entityId).subscribe(data => {
      if (data) {
        this.blobToFile(data, 'text/xml', 'KYC3_Client_Dossier.zip');
      }
    });
  }

  reportForEntity(entityId: any) {
      this.entityId = entityId;

      for (let i = 0; i < this.entities.length; i++) {
          if (this.entities[i].id === this.entityId) {
              this.entityName = this.entities[i].name;
              this.entityType = this.entities[i].entityType;
          }
      }

      this.tags = [];
      this.loading2 = true;

      this.getGraphs();
  }

  getGraphs() {
      this.relationResponse = {};

      this.kybService.getRelationships(this.entityId).subscribe(
        data => {
          this.relationResponse = data;
          if (this.relationResponse.data.roles.length === 0) {
              this.relationshipGraphString = null;
              this.export();
          } else {
              this.drawRelationshipGraph(this.relationResponse.data.roles);
          }
        },
        error => {}
      );

      this.kybService.getUBOGraph(this.entityId, 'control').subscribe(
        data => {
          this.controlResponse = data;
          if (this.controlResponse.data.controls.length === 0 && this.controlResponse.data.owners.length === 0) {
              this.controlChartString = null;
          } else {
              this.drawControlChart(this.controlResponse.data);
              this.drawNewControlChart(this.controlResponse.data);
          }
        },
        error => {}
      );

      this.kybService.getUBOGraph(this.entityId, 'owner').subscribe(
        data => {
          this.UBOResponse = data;

          if (this.UBOResponse.data.owners.length === 0 && this.UBOResponse.data.controls.length === 0) {
            this.nodes = [];
            this.uboChartString = null;
          } else {
            this.drawUBOChart(this.UBOResponse.data);
            this.drawNewUBOChart(this.UBOResponse.data);
          }
        },
        error => {}
      );
  }

  drawRelationshipGraph(relationship: any) {
    this.relationshipGraphData = [];

    if (relationship.length === 0) {
      this.relationshipGraphData.push({ 'from': this.entityName, 'to': this.entityName });
    } else {
      for (let i = 0; i < relationship.length; i++) {
        let currentColorFrom = '';
        let currentColorTo = '';
        let currentStatusFrom = '';
        let currentStatusTo = '';

        if (relationship[i].entity1.customOverallRisk.riskStatus !== null) {
          currentStatusFrom = relationship[i].entity1.customOverallRisk.riskStatus;
        } else {
          currentStatusFrom = relationship[i].entity1.combinedOverallRisk.riskStatus;
        }

        if (relationship[i].entity2.customOverallRisk.riskStatus !== null) {
          currentStatusTo = relationship[i].entity2.customOverallRisk.riskStatus;
        } else {
          currentStatusTo = relationship[i].entity2.combinedOverallRisk.riskStatus;
        }

        switch (currentStatusFrom) {
          case 'HIGH':
            currentColorFrom = 'rgb(214, 134, 15)';
            break;
          case 'LOW':
            currentColorFrom = '#72C245';
            break;
          case 'MEDIUM':
            currentColorFrom = '#FDD32B';
            break;
          case 'REJECTED':
            currentColorFrom = '#D60000';
            break;
          default:
            currentColorFrom = '#bdbdbd';
        }

        switch (currentStatusTo) {
          case 'HIGH':
            currentColorTo = 'rgb(214, 134, 15)';
            break;
          case 'LOW':
            currentColorTo = '#72C245';
            break;
          case 'MEDIUM':
            currentColorTo = '#FDD32B';
            break;
          case 'REJECTED':
            currentColorTo = '#D60000';
            break;
          default:
            currentColorTo = '#bdbdbd';
        }

        // tslint:disable-next-line:max-line-length
        this.relationshipGraphData.push({ 'from': relationship[i].entity1.entityName, 'to': relationship[i].entity2.entityName, 'name': relationship[i].roleName, 'colorFrom': currentColorFrom, 'colorTo': currentColorTo });
      }
    }

    this.chart = new Chart({
      chart: {
        type: 'networkgraph',
        events: {
          load() {
            const series = this.series[0];
            series.data.forEach(function(point, i) {
              if ((point as any).colorFrom && (series as any).nodes[i]) {
                (series as any).nodes[i].color = (point as any).colorFrom;
              }

              (series as any).nodes[i].name = (point as any).from + ' (' + (point as any).name + ' of ' + (point as any).to + ')';

              if ((point as any).colorTo && (series as any).nodes[i + 1]) {
                (series as any).nodes[i + 1].color = (point as any).colorTo;
              }
            });
          }
        },
        height: '100%'
      },
      title: {
        text: ''
      },
      credits: {
        enabled: false
      },
      plotOptions: {
        networkgraph: {
          marker: {
            radius: 20
          },
          keys: ['from', 'to'],
          layoutAlgorithm: {
            enableSimulation: false,
            linkLength: 40,
            friction: -0.9
          },
          lineWidth: 4
        }
      },
      series: [{
        type: 'networkgraph',
        dataLabels: {
          enabled: true,
          linkTextPath: {
            attributes: {
              dy: 12
            }
          },
          linkFormat: 'is {point.name} of'
        },
        id: 'lang-tree',
        color: '#68799c',
        data: this.relationshipGraphData
      }],
      exporting: {
        enabled: false
      }
    });

    const parentThis = this;

    setTimeout(() => {
        parentThis.export();
    }, 3000);
  }

  prepareControlData(startEntity: any, entities: any) {
    for (let i = 0; i < entities.length; i++) {
      this.controls.push({ from: startEntity, to: entities[i].entityName });

      this.controlNodes.push({ id: entities[i].entityName, title: entities[i].entityName,
        name: 'E' + (entities[i].distance + 1) + '-' + entities[i].roleName + '-' +
        entities[i].entityType, detailId: entities[i].id });

      if (entities[i].entities.length > 0) {
        this.prepareControlData(entities[i].entityName, entities[i].entities);
      }
    }

    return this.controls;
  }

  drawNewControlChart(controlData: any) {
    const newChilds: any = [];

    if (controlData.controls.length === 0) {
      this.emptyControl = true;
      this.controlNodes = [];
    } else {
      this.emptyControl = false;
      this.controlNodes = [];

      for (let i = 0; i < controlData.controls.length; i++) {
        newChilds.push({ id: controlData.controls[i].id, title: controlData.controls[i].entityName,
          name: 'E' + (controlData.controls[i].distance + 1) + '-' +
          controlData.controls[i].roleName });
      }

      controlData.controls.forEach(element => {
        newChilds.forEach(child => {
          let currentStatus = '';

          if (element.customOverallRisk.riskStatus !== null) {
            currentStatus = element.customOverallRisk.riskStatus;
          } else {
            currentStatus = element.combinedOverallRisk.riskStatus;
          }

          switch (currentStatus) {
            case 'HIGH':
              child.cssClass = 'OR-high';
              break;
            case 'LOW':
              child.cssClass = 'OR-low';
              break;
            case 'MEDIUM':
              child.cssClass = 'OR-medium';
              break;
            case 'REJECTED':
              child.cssClass = 'OR-rejected';
              break;
            default:
              child.cssClass = 'OR-unevaluated';
          }

          if (element.entityType === 'PERSON') {
            child.image = '../../assets/images/person.svg';
          } else {
            child.image = '../../assets/images/building.svg';
          }
        });
      });

      this.controlNodes.push({ id: controlData.entity.id, title: controlData.entity.entityName, name: 'FOCUS Company', childs: newChilds });
    }
  }

  drawControlChart(controlData: any) {
    if (this.controlChart !== undefined) {
      this.controlChart.destroy();
    }

    if (controlData.controls.length === 0) {
      this.emptyControl = true;
      this.controlData = [];
      this.controlNodes = [];
      this.controls = [];
    } else {
      this.emptyControl = false;
      this.controlData = [];
      this.controlNodes = [];
      this.controls = [];
      this.controlData = this.prepareControlData(controlData.entity.entityName, controlData.controls);
      this.controlNodes.push({ id: controlData.entity.entityName, title: controlData.entity.entityName,
        name: 'FOCUS Company', detailId: controlData.entity.id });
    }

    this.controlChartString = JSON.stringify(
        {
            chart: {
              inverted: true,
              style: {
                cursor: 'pointer'
              }
            },
            title: {
                text: ''
            },
            series: [{
              type: 'organization',
              name: controlData.entity.entityName,
              keys: ['from', 'to'],
              data: this.controlData,
              levels: [{
                level: 0,
                color: 'silver',
                dataLabels: {
                  color: 'white',
                },
              }, {
                level: 1,
                color: 'green',
                dataLabels: {
                  color: 'white'
                },
              }, {
                level: 2,
                color: 'green',
                dataLabels: {
                  color: 'white'
                }
              }, {
                level: 3,
                color: 'green',
                dataLabels: {
                  color: 'white'
                }
              }, {
                level: 4,
                color: 'green',
                dataLabels: {
                  color: 'white'
                }
              }],
              nodes: this.controlNodes,
              events: {
              },
              colorByPoint: false,
              color: '#007ad0',
              dataLabels: {
                color: 'white'
              },
              borderColor: 'white',
              nodeWidth: 65
            }],
            exporting: {
              enabled: true,
              allowHTML: true,
              sourceWidth: 800
            },
            credits: {
              enabled: false
            },
          }
    );
  }

  prepareUBOData(startEntity: any, entities: any) {
    this.entityLevel++;

    let overallRisk = '';

    for (let i = 0; i < entities.length; i++) {
      let cyclesWith = '';

      this.owners.push({ from: entities[i].entityName, to: startEntity });

      if (entities[i].customOverallRisk.riskStatus !== null && entities[i].customOverallRisk.riskStatus !== null) {
        overallRisk = entities[i].customOverallRisk.riskStatus;
      } else {
        overallRisk = entities[i].combinedOverallRisk.riskStatus;
      }

      if (entities[i].cycles.length > 0) {
        for (let j = 0; j < entities[i].cycles.length; j++) {
          cyclesWith += entities[i].cycles[j].name + ',';
        }
      }

      if (cyclesWith !== '') {
        for (let z = 0; z < this.uboNodes.length; z++) {
          if (this.uboNodes[z].title === entities[i].entityName) {
            this.uboNodes[z].color = '#cc0000';
            this.uboNodes[z].cycles = cyclesWith.replace(/,([^,]*)$/, '$1');
            this.uboNodes[z].image = this.exclamationImage;
          }
        }

        if (this.titles.indexOf(entities[i].entityName) === -1) {
          this.titles.push(entities[i].entityName);

          this.uboNodes.push({ id: entities[i].entityName, title: entities[i].entityName, overallRisk, name: 'E' +
          (entities[i].distance + 1) + '-' + entities[i].roleName, type: entities[i].entityType, detailId:
          entities[i].id, color: '#cc0000', cycles: cyclesWith.replace(/,([^,]*)$/, '$1'),
          image: this.exclamationImage, layout: 'hanging' });
        }
      } else {
        if (this.titles.indexOf(entities[i].entityName) === -1) {
          this.titles.push(entities[i].entityName);

          if (entities[i].distance > 1) {
            this.uboNodes.push({ id: entities[i].entityName, title: entities[i].entityName, overallRisk, name: 'E' +
            (entities[i].distance + 1) + '-' + entities[i].roleName, type: entities[i].entityType, detailId:
            entities[i].id, cycles: '', layout: 'hanging' });
          } else {
            this.uboNodes.push({ id: entities[i].entityName, title: entities[i].entityName, overallRisk, name: 'E' +
            (entities[i].distance + 1) + '-' + entities[i].roleName, type: entities[i].entityType, detailId:
            entities[i].id, cycles: '' });
          }
        }
      }

      if (entities[i].entities.length > 0) {
        this.prepareUBOData(entities[i].entityName, entities[i].entities);
      }
    }

    return this.owners;
  }

  prepareNewUBOData(entity: any) {
    const childIds = [];

    if (entity.length > 0) {
      entity.forEach(element => {
        this.prepareNewUBOData(element);
      });
    } else if (entity.entities && entity.entities.length > 0) {
        this.prepareNewUBOData(entity.entities);

        for (let i = 0; i < entity.entities.length; i++) {
          if (entity.entities[i].entities.length > 0) {
            entity.entities[i].entities.forEach(element => {
              childIds.push({ childs: [{ id: element.id }], id: entity.entities[i].id });
            });
          } else {
            childIds.push({ id: entity.entities[i].id });
          }

          this.newChilds.push({ childs: childIds, id: entity.id });
        }
      } else {
        this.newChilds.push({ id: entity.id });
      }
  }

  putImageNameAndTitle(uboNode: any, child: any) {
    if (child.length > 0) {
      child.forEach(element => {
        this.putImageNameAndTitle(uboNode, element);
      });
    }

    if (uboNode.detailId === child.id) {
      child.name = uboNode.name;
      child.title = uboNode.title;

      switch (uboNode.overallRisk) {
        case 'HIGH':
          child.cssClass = 'OR-high';
          break;
        case 'LOW':
          child.cssClass = 'OR-low';
          break;
        case 'MEDIUM':
          child.cssClass = 'OR-medium';
          break;
        case 'REJECTED':
          child.cssClass = 'OR-rejected';
          break;
        default:
          child.cssClass = 'OR-unevaluated';
      }

      if (uboNode.type === 'PERSON') {
        child.image = '../../assets/images/person.svg';
      } else {
        child.image = '../../assets/images/building.svg';
      }
    }

    if (child.childs && child.childs.length > 0) {
      this.putImageNameAndTitle(uboNode, child.childs);
    } else if (uboNode.detailId === child.id) {
      child.name = uboNode.name;
      child.title = uboNode.title;

      switch (uboNode.overallRisk) {
        case 'HIGH':
          child.cssClass = 'OR-high';
          break;
        case 'LOW':
          child.cssClass = 'OR-low';
          break;
        case 'MEDIUM':
          child.cssClass = 'OR-medium';
          break;
        case 'REJECTED':
          child.cssClass = 'OR-rejected';
          break;
        default:
          child.cssClass = 'OR-unevaluated';
      }

      if (uboNode.type === 'PERSON') {
        child.image = '../../assets/images/person.svg';
      } else {
        child.image = '../../assets/images/building.svg';
      }
    }
  }

  drawNewUBOChart(UBOdata: any) {
    let currentChilds = [];
    const newNodes = [];

    UBOdata.owners.forEach(owner => {
      currentChilds = [];
      if (owner.entities.length > 0) {
        owner.entities.forEach(entity => {
          this.newChilds = [];
          this.prepareNewUBOData(entity);
          currentChilds.push(this.newChilds[this.newChilds.length - 1]);
        });
      } else {
        currentChilds = [];
      }

      this.nodes.push({ childs: currentChilds, id: owner.id });
    });

    this.uboNodes.forEach(uboNode => {
      this.nodes.forEach(node => {
        if (uboNode.detailId === node.id) {
            node.name = uboNode.name;
            node.title = uboNode.title;

            switch (uboNode.overallRisk) {
              case 'HIGH':
                node.cssClass = 'OR-high';
                break;
              case 'LOW':
                node.cssClass = 'OR-low';
                break;
              case 'MEDIUM':
                node.cssClass = 'OR-medium';
                break;
              case 'REJECTED':
                node.cssClass = 'OR-rejected';
                break;
              default:
                node.cssClass = 'OR-unevaluated';
            }

            if (uboNode.type === 'PERSON') {
              node.image = '../../assets/images/person.svg';
            } else {
              node.image = '../../assets/images/building.svg';
            }
        }

        if (node.childs) {
          this.putImageNameAndTitle(uboNode, node.childs);
        }
      });

      if (uboNode.detailId === UBOdata.entity.id) {
          newNodes.push({ id: uboNode.detailId, name: uboNode.name, title: uboNode.title, childs: this.nodes });
        }
    });

    this.nodes = newNodes;
  }

  drawUBOChart(UBOdata: any) {
    this.titles = [];

    if (this.uboChart !== undefined) {
      this.uboChart.destroy();
    }

    if (UBOdata.owners.length === 0) {
      this.emptyUBO = true;
      this.uboData = [];
      this.uboNodes = [];
      this.uboLevels = [];
      this.entityLevel = 0;
      this.owners = [];
    } else {
      this.emptyUBO = false;
      this.uboData = [];
      this.uboNodes = [];
      this.uboLevels = [];
      this.entityLevel = 0;
      this.owners = [];
      this.uboData = this.prepareUBOData(UBOdata.entity.entityName, UBOdata.owners);

      // Check this for the colors
      for (let i = 0; i < this.entityLevel; i++) {
        this.uboLevels.push({ level: i, color: 'blue', dataLabels: { color: 'white' } });
      }

      this.uboNodes.push({ id: UBOdata.entity.entityName, title: UBOdata.entity.entityName,
        name: 'FOCUS Company', detailId: UBOdata.entity.id, color: 'silver' });

      let title = '';

      for (let x = 0; x < this.uboNodes.length; x++) {
        if (this.uboNodes[x].name === 'FOCUS Company') {
          title = this.uboNodes[x].title;
          this.index = x;
        }
      }

      for (let y = 0; y < this.uboNodes.length; y++) {
        if (this.uboNodes[y].title === title && this.uboNodes[y].name !== 'FOCUS Company') {
          this.uboNodes[this.index].color = '#cc0000';
          this.uboNodes[this.index].cycles = this.uboNodes[y].cycles;
          this.uboNodes[this.index].image = this.uboNodes[y].image;
          this.uboNodes.splice(y, 1);
        }
      }

      let cycleEntities = [];

      for (let p = 0; p < this.uboNodes.length; p++) {
        if (this.uboNodes[p].name === 'FOCUS Company') {
          this.index = p;
        }

        if (this.uboNodes[p].cycles !== '') {
          if (this.uboNodes[p].cycles !== undefined) {
            cycleEntities = this.uboNodes[p].cycles.split(',');
          }
        }
      }

      for (let q = 0; q < this.uboData.length; q++) {
        if (this.uboData[q].from === this.uboNodes[this.index].title && cycleEntities.indexOf(this.uboData[q].to) !== -1) {
          this.uboData.splice(q, 1);
        }
      }

      this.uboChartString = JSON.stringify({
        chart: {
          inverted: true
        },
        title: {
          text: ''
        },
        series: [{
          type: 'organization',
          name: UBOdata.entity.entityName,
          keys: ['from', 'to'],
          data: this.uboData,
          levels: this.uboLevels,
          nodes: this.uboNodes,
          events: {
          },
          colorByPoint: false,
          color: '#007ad0',
          dataLabels: {
            color: 'white'
          },
          borderColor: 'white'
        }],
        exporting: {
          enabled: true,
          allowHTML: true,
          sourceWidth: 800
        },
        credits: {
          enabled: false
        },
      });
    }
  }

  prepareOrganizationalChart(relationship: any, inputData: any) {
    if (relationship.length === 0) {
      inputData.push([this.entityName, this.entityName]);
    } else {
      this.counter += relationship.length;

      for (let i = 0; i < relationship.length; i++) {
        if (relationship[i].documentStatus === 'UNEVALUATED') {
          inputData.push({ 'from': relationship[i].entity1.entityName, 'to':
          relationship[i].entity2.entityName, 'name': relationship[i].roleName,
          'color': '#bdbdbd' });
        } else if (relationship[i].documentStatus === 'ACCEPTED') {
          inputData.push({ 'from': relationship[i].entity1.entityName,
          'to': relationship[i].entity2.entityName, 'name': relationship[i].roleName,
          'color': '#72C245' });
        } else if (relationship[i].documentStatus === 'ESCALATED') {
          inputData.push({ 'from': relationship[i].entity1.entityName,
          'to': relationship[i].entity2.entityName, 'name': relationship[i].roleName,
          'color': '#FDD32B' });
        } else {
          inputData.push({ 'from': relationship[i].entity1.entityName,
          'to': relationship[i].entity2.entityName, 'name':
          relationship[i].roleName, 'color': '#D60000' });
        }

        if (relationship[i].entity1.roles.length > 0) {
          this.prepareOrganizationalChart(relationship[i].entity1.roles, inputData);
        }

        if (relationship[i].entity2.roles.length > 0) {
          this.prepareOrganizationalChart(relationship[i].entity2.roles, inputData);
        }
      }
    }

    this.drawOrganizationalChart(inputData);
  }

  drawOrganizationalChart(inputData: any) {
    this.chart2 = new Chart({
      chart: {
        inverted: true,
        type: 'organization',
      },
      plotOptions: {
        organization: {
          dataLabels: {
            enabled: true
          },
        }
      },
      title: {
        text: ''
      },
      credits: {
        enabled: false
      },
      series: [{
        type: undefined,
        keys: ['from', 'to', 'name', 'color'],
        label: {
          enabled: true
        },
        data: inputData,
        colorByPoint: false,
        color: '#007ad0',
        dataLabels: {
          enabled: true,
          style: {
            color: 'white',
            fontSize: '8px'
          }
        },
        borderColor: 'white',
        nodeWidth: 65
      }]
    }
    );
  }

  export() {
    const uboChart = document.getElementById('newUboChart');
    const controlChart = document.getElementById('newControlChart');

    htmlToImage.toPng(uboChart).then(uboDataUrl => {
      if (this.uboChartString) {
        this.uboChartEncodedImage = uboDataUrl.split('data:image/png;base64,')[1];
      } else {
        this.uboChartEncodedImage = null;
      }

      htmlToImage.toPng(controlChart).then(controlDataUrl => {
        if (this.controlChartString) {
          this.controlChartEncodedImage = controlDataUrl.split('data:image/png;base64,')[1];
        } else {
          this.controlChartEncodedImage = null;
        }

        if (this.entityType === 'LEGAL') {
          this.relationshipGraphString = null;
        }

        if (this.relationResponse.data.roles.length > 0) {
          svg.svgAsPngUri(document.getElementsByClassName('highcharts-root')[0], {}, (uri) => {
              if (this.entityType !== 'LEGAL') {
                this.relationshipGraphString = uri.split('data:image/png;base64,')[1];
              }

              this.kybService.getPdfReport2(this.entityId, localStorage.getItem('username').toUpperCase(),
                this.relationshipGraphString, null, this.controlChartEncodedImage, null, this.uboChartEncodedImage).subscribe(
                  data => {
                      this.pdfResponse = data;
                      const binaryImg = atob(this.pdfResponse.data.reportData);
                      const length = binaryImg.length;
                      const arrayBuffer = new ArrayBuffer(length);
                      const uintArray = new Uint8Array(arrayBuffer);

                      for (let i = 0; i < length; i++) {
                          uintArray[i] = binaryImg.charCodeAt(i);
                      }

                      const file = new Blob([uintArray], { type: 'application/pdf' });

                      FileSaver.saveAs(file, 'entity-risk-report-' + this.pdfResponse.data.reportName + '.pdf');

                      this.loading2 = false;
                      this.entityId = '';
                  },
                  error => {
                      this.loading2 = false;
                  }
              );
          });
        } else {
            this.kybService.getPdfReport2(this.entityId, localStorage.getItem('username').toUpperCase(),
              this.relationshipGraphString, null, this.controlChartEncodedImage, null, this.uboChartEncodedImage).subscribe(
                data => {
                    this.pdfResponse = data;
                    const binaryImg = atob(this.pdfResponse.data.reportData);
                    const length = binaryImg.length;
                    const arrayBuffer = new ArrayBuffer(length);
                    const uintArray = new Uint8Array(arrayBuffer);

                    for (let i = 0; i < length; i++) {
                        uintArray[i] = binaryImg.charCodeAt(i);
                    }

                    const file = new Blob([uintArray], { type: 'application/pdf' });

                    FileSaver.saveAs(file, 'entity-risk-report-' + this.pdfResponse.data.reportName + '.pdf');

                    this.loading2 = false;
                    this.entityId = '';
                },
                error => {
                    this.loading2 = false;
                }
            );
        }
      });
    });
  }
}
