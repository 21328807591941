import { OnDestroy } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ApiService } from './../../../api.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../../../api.service";
var DocumentManagerService = /** @class */ (function () {
    function DocumentManagerService(http, apiService) {
        this.http = http;
        this.apiService = apiService;
        this._data = new BehaviorSubject({});
        this.packages = this._data.asObservable();
        this.subs = [];
    }
    DocumentManagerService.prototype.getPackages = function () {
        var _this = this;
        var sub = this.apiService.get('public/packages').subscribe(function (res) {
            _this._data.next(res);
        });
        this.subs.push(sub);
    };
    DocumentManagerService.prototype.ngOnDestroy = function () {
        for (var _i = 0, _a = this.subs; _i < _a.length; _i++) {
            var s = _a[_i];
            s.unsubscribe();
        }
    };
    DocumentManagerService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function DocumentManagerService_Factory() { return new DocumentManagerService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.ApiService)); }, token: DocumentManagerService, providedIn: "root" });
    return DocumentManagerService;
}());
export { DocumentManagerService };
