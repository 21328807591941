
    <div class='modal-header'>
      <h4 class='modal-title pull-left'>Rename Entity</h4>
      <button type='button' class='close pull-right' aria-label='Close' (click)='bsModalRef.hide()'>
        <span aria-hidden='true'>&times;</span>
      </button>
    </div>
    <div class='modal-body'>
      <p>Current name is: <b>{{primaryName}}</b></p>
      <p><b><i>Please note that renaming an entity will trigger a new check against our database. Any new potential hits will be displayed in the screening results and the risk level of the entity will be adjusted accordingly.</i></b></p>
      <form [formGroup]="disableRenameBtn">
       <label> New name: *
          <div style="display: flex; align-items: center;">
            <input class='form-control input-md' style='border: 1px black solid; border-radius: 0px; margin-right: 10px;' [(ngModel)]='newPrimary' formControlName="newName">
            <mat-icon *ngIf="!disableRenameBtn.valid" style="color: red;">close</mat-icon>
            <mat-icon *ngIf="disableRenameBtn.valid" style="color: green;">check</mat-icon>
          </div>
        </label><br><br>
      </form>
    </div>
    <div class='modal-footer' style='text-align: left;'>
        <button class='btn btn-success' (click)='rename()' [disabled]='!disableRenameBtn.valid' style='width: 20%;padding: 2px;margin-top: -3px;'>Rename</button>
    </div>
  