import RecordRTC from 'recordrtc';
import * as moment from 'moment';
import { Subject } from 'rxjs';
var VideoRecordingService = /** @class */ (function () {
    function VideoRecordingService() {
        this._stream = new Subject();
        this._recorded = new Subject();
        this._recordedUrl = new Subject();
        this._recordingTime = new Subject();
        this._recordingFailed = new Subject();
    }
    Object.defineProperty(VideoRecordingService.prototype, "agent", {
        get: function () { return navigator.userAgent || navigator.vendor; },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(VideoRecordingService.prototype, "chrome", {
        get: function () { return !!this.agent.toLowerCase().match(/(chrome)/i); },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(VideoRecordingService.prototype, "safari", {
        get: function () { return !!this.agent.toLowerCase().match(/(safari)/i); },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(VideoRecordingService.prototype, "firefox", {
        get: function () { return !!this.agent.toLowerCase().match(/(firefox)/i); },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(VideoRecordingService.prototype, "firefoxIOS", {
        get: function () { return !!navigator.userAgent.toLowerCase().match(/(fxios)/i); },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(VideoRecordingService.prototype, "chromeIOS", {
        get: function () { return !!navigator.userAgent.toLowerCase().match(/(crios)/i); },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(VideoRecordingService.prototype, "operaIOS", {
        get: function () { return !!navigator.userAgent.toLowerCase().match(/(opios)/i); },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(VideoRecordingService.prototype, "samsungAndroid", {
        get: function () { return !!navigator.userAgent.toLowerCase().match(/(samsungbrowser)/i); },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(VideoRecordingService.prototype, "android", {
        get: function () { return !!this.agent.match(/(android)/i); },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(VideoRecordingService.prototype, "ios", {
        get: function () { return !!this.agent.match(/(iPhone)|(iPod)/i); },
        enumerable: true,
        configurable: true
    });
    VideoRecordingService.prototype.getRecordedUrl = function () {
        return this._recordedUrl.asObservable();
    };
    VideoRecordingService.prototype.getRecordedBlob = function () {
        return this._recorded.asObservable();
    };
    VideoRecordingService.prototype.getRecordedTime = function () {
        return this._recordingTime.asObservable();
    };
    VideoRecordingService.prototype.recordingFailed = function () {
        return this._recordingFailed.asObservable();
    };
    VideoRecordingService.prototype.getStream = function () {
        return this._stream.asObservable();
    };
    VideoRecordingService.prototype.startRecording = function (conf) {
        var _this = this;
        var browser = navigator;
        if (this.recorder) {
            // It means recording is already started or it is already recording something
            return;
        }
        this._recordingTime.next('00:00');
        return new Promise(function (resolve, reject) {
            browser.mediaDevices.getUserMedia(conf).then(function (stream) {
                _this.stream = stream;
                resolve(_this.stream);
            }).catch(function (error) {
                _this._recordingFailed.next(error);
            });
        });
    };
    VideoRecordingService.prototype.abortRecording = function () {
        this.stopMedia();
    };
    VideoRecordingService.prototype.record = function () {
        var _this = this;
        var x = {
            type: 'video'
        };
        this.recorder = new RecordRTC(this.stream, x);
        this.recorder.startRecording();
        this.startTime = moment();
        this.interval = setInterval(function () {
            var currentTime = moment();
            var diffTime = moment.duration(currentTime.diff(_this.startTime));
            var time = _this.toString(diffTime.minutes()) + ':' + _this.toString(diffTime.seconds());
            _this._recordingTime.next(time);
            _this._stream.next(_this.stream);
        }, 500);
    };
    VideoRecordingService.prototype.toString = function (value) {
        var val = value;
        if (!value) {
            val = '00';
        }
        if (value < 10) {
            val = '0' + value;
        }
        return val;
    };
    VideoRecordingService.prototype.stopRecording = function () {
        if (this.recorder) {
            this.recorder.stopRecording(this.processVideo.bind(this));
        }
    };
    VideoRecordingService.prototype.processVideo = function (audioVideoWebMURL) {
        var recordedBlob = this.recorder.getBlob();
        this.recorder.getDataURL(function (dataURL) { });
        var recordedName = encodeURIComponent('video_' + new Date().getTime() + '.webm');
        this._recorded.next({ blob: recordedBlob, url: audioVideoWebMURL, title: recordedName });
        this.stopMedia();
    };
    VideoRecordingService.prototype.stopMedia = function () {
        if (this.recorder) {
            this.recorder = null;
            clearInterval(this.interval);
            this.startTime = null;
            if (this.stream) {
                this.stream.getAudioTracks().forEach(function (track) { return track.stop(); });
                this.stream.getVideoTracks().forEach(function (track) { return track.stop(); });
                this.stream.stop();
                this.stream = null;
            }
        }
    };
    return VideoRecordingService;
}());
export { VideoRecordingService };
