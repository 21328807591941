/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./piechart.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "angular-highcharts";
import * as i3 from "./piechart.component";
import * as i4 from "../../../../_services/webData.service";
var styles_PiechartComponent = [i0.styles];
var RenderType_PiechartComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PiechartComponent, data: {} });
export { RenderType_PiechartComponent as RenderType_PiechartComponent };
export function View_PiechartComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [], null, null, null, null, null)), i1.ɵdid(1, 737280, null, 0, i2.ɵb, [i1.ElementRef], { chart: [0, "chart"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.pieChart; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_PiechartComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "piechart", [], null, null, null, View_PiechartComponent_0, RenderType_PiechartComponent)), i1.ɵdid(1, 245760, null, 0, i3.PiechartComponent, [i4.WebDataService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PiechartComponentNgFactory = i1.ɵccf("piechart", i3.PiechartComponent, View_PiechartComponent_Host_0, { events: "events" }, {}, []);
export { PiechartComponentNgFactory as PiechartComponentNgFactory };
