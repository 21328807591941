/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./reporting.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./activityReport/activityReport.component.ngfactory";
import * as i3 from "./activityReport/activityReport.component";
import * as i4 from "@angular/router";
import * as i5 from "../../_services/calls.service";
import * as i6 from "../../_services/alert.service";
import * as i7 from "../../_services/kyb.service";
import * as i8 from "../../_services/authentication.service";
import * as i9 from "ngx-bootstrap/modal";
import * as i10 from "./entityReport/entityReport.component.ngfactory";
import * as i11 from "./entityReport/entityReport.component";
import * as i12 from "./screeningReport/screeningReport.component.ngfactory";
import * as i13 from "./screeningReport/screeningReport.component";
import * as i14 from "../../../../node_modules/ngx-bootstrap/tabs/ngx-bootstrap-tabs.ngfactory";
import * as i15 from "ngx-bootstrap/tabs";
import * as i16 from "@angular/common";
import * as i17 from "./reporting.component";
import * as i18 from "../../_services/data.service";
var styles_ReportingComponent = [i0.styles];
var RenderType_ReportingComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ReportingComponent, data: {} });
export { RenderType_ReportingComponent as RenderType_ReportingComponent };
function View_ReportingComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-activity-report", [], null, null, null, i2.View_ActivityReportComponent_0, i2.RenderType_ActivityReportComponent)), i1.ɵdid(1, 114688, null, 0, i3.ActivityReportComponent, [i4.Router, i5.CallsService, i6.AlertService, i7.KYBService, i8.AuthenticationService, i9.BsModalService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
function View_ReportingComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-entity-report", [], null, null, null, i10.View_EntityReportComponent_0, i10.RenderType_EntityReportComponent)), i1.ɵdid(1, 114688, null, 0, i11.EntityReportComponent, [i4.Router, i5.CallsService, i6.AlertService, i7.KYBService, i8.AuthenticationService, i9.BsModalService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
function View_ReportingComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-screening-report", [], null, null, null, i12.View_ScreeningReportComponent_0, i12.RenderType_ScreeningReportComponent)), i1.ɵdid(1, 114688, null, 0, i13.ScreeningReportComponent, [i4.Router, i5.CallsService, i6.AlertService, i7.KYBService, i8.AuthenticationService, i9.BsModalService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
export function View_ReportingComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 17, "div", [["class", "col-md-12 reporting-page"], ["style", ""]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 16, "tabset", [["class", "main-topic-tabs"], ["type", "pills"]], [[2, "tab-container", null]], null, null, i14.View_TabsetComponent_0, i14.RenderType_TabsetComponent)), i1.ɵdid(2, 180224, [["staticTabs", 4]], 0, i15.TabsetComponent, [i15.TabsetConfig, i1.Renderer2, i1.ElementRef], { type: [0, "type"] }, null), (_l()(), i1.ɵeld(3, 0, null, 0, 4, "tab", [["heading", "Activity Reports"]], [[1, "id", 0], [2, "active", null], [2, "tab-pane", null], [1, "role", 0], [1, "aria-labelledby", 0]], [[null, "selectTab"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("selectTab" === en)) {
        var pd_0 = ((_co.tab1 = true) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(4, 212992, null, 0, i15.TabDirective, [i15.TabsetComponent, i1.ElementRef, i1.Renderer2], { heading: [0, "heading"] }, { selectTab: "selectTab" }), (_l()(), i1.ɵeld(5, 0, null, null, 2, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ReportingComponent_1)), i1.ɵdid(7, 16384, null, 0, i16.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(8, 0, null, 0, 4, "tab", [["heading", "Entity Reports"]], [[1, "id", 0], [2, "active", null], [2, "tab-pane", null], [1, "role", 0], [1, "aria-labelledby", 0]], [[null, "selectTab"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("selectTab" === en)) {
        var pd_0 = ((_co.tab2 = true) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(9, 212992, null, 0, i15.TabDirective, [i15.TabsetComponent, i1.ElementRef, i1.Renderer2], { heading: [0, "heading"] }, { selectTab: "selectTab" }), (_l()(), i1.ɵeld(10, 0, null, null, 2, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ReportingComponent_2)), i1.ɵdid(12, 16384, null, 0, i16.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(13, 0, null, 0, 4, "tab", [["heading", "Screening Reports"]], [[1, "id", 0], [2, "active", null], [2, "tab-pane", null], [1, "role", 0], [1, "aria-labelledby", 0]], [[null, "selectTab"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("selectTab" === en)) {
        var pd_0 = ((_co.tab3 = true) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(14, 212992, null, 0, i15.TabDirective, [i15.TabsetComponent, i1.ElementRef, i1.Renderer2], { heading: [0, "heading"] }, { selectTab: "selectTab" }), (_l()(), i1.ɵeld(15, 0, null, null, 2, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ReportingComponent_3)), i1.ɵdid(17, 16384, null, 0, i16.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = "pills"; _ck(_v, 2, 0, currVal_1); var currVal_7 = "Activity Reports"; _ck(_v, 4, 0, currVal_7); var currVal_8 = _co.tab1; _ck(_v, 7, 0, currVal_8); var currVal_14 = "Entity Reports"; _ck(_v, 9, 0, currVal_14); var currVal_15 = _co.tab2; _ck(_v, 12, 0, currVal_15); var currVal_21 = "Screening Reports"; _ck(_v, 14, 0, currVal_21); var currVal_22 = _co.tab3; _ck(_v, 17, 0, currVal_22); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 2).clazz; _ck(_v, 1, 0, currVal_0); var currVal_2 = i1.ɵnov(_v, 4).id; var currVal_3 = i1.ɵnov(_v, 4).active; var currVal_4 = i1.ɵnov(_v, 4).addClass; var currVal_5 = i1.ɵnov(_v, 4).role; var currVal_6 = i1.ɵnov(_v, 4).ariaLabelledby; _ck(_v, 3, 0, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); var currVal_9 = i1.ɵnov(_v, 9).id; var currVal_10 = i1.ɵnov(_v, 9).active; var currVal_11 = i1.ɵnov(_v, 9).addClass; var currVal_12 = i1.ɵnov(_v, 9).role; var currVal_13 = i1.ɵnov(_v, 9).ariaLabelledby; _ck(_v, 8, 0, currVal_9, currVal_10, currVal_11, currVal_12, currVal_13); var currVal_16 = i1.ɵnov(_v, 14).id; var currVal_17 = i1.ɵnov(_v, 14).active; var currVal_18 = i1.ɵnov(_v, 14).addClass; var currVal_19 = i1.ɵnov(_v, 14).role; var currVal_20 = i1.ɵnov(_v, 14).ariaLabelledby; _ck(_v, 13, 0, currVal_16, currVal_17, currVal_18, currVal_19, currVal_20); }); }
export function View_ReportingComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ng-component", [], null, null, null, View_ReportingComponent_0, RenderType_ReportingComponent)), i1.ɵdid(1, 114688, null, 0, i17.ReportingComponent, [i6.AlertService, i5.CallsService, i18.DataService, i7.KYBService, i9.BsModalService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ReportingComponentNgFactory = i1.ɵccf("ng-component", i17.ReportingComponent, View_ReportingComponent_Host_0, {}, {}, []);
export { ReportingComponentNgFactory as ReportingComponentNgFactory };
