import { Component, OnInit } from '@angular/core';
import { AlertService, CallsService, DataService, KYBService } from '../../_services';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import * as $ from 'jquery';
import { config } from '../../../assets/configuration';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'modal-content',
  template: `
      <div class='modal-header'>
      <p class='modal-title pull-left' style='font-size: 16px;'><b>Screening Results Evaluator</b></p>
      <button type='button' class='close pull-right' aria-label='Close' (click)='close()'>
        <span aria-hidden='true' style='color: gray; font-size: 35px'>&times;</span>
      </button>
    </div>
    <div class='modal-body'>
      <div class='row'>
        <div class='col-md-1'></div>
        <div class='col-md-5' style='font-size: 16px;'>
          Entity name: <a href='/detail/{{probableMatches[currentMatch].entityId}}' target='_blank'><b>{{probableMatches[currentMatch].entityName}}</b></a>&nbsp;&nbsp;<img *ngIf='loading' [src]='loadingImg' style='width: 20px;padding-bottom: 3px;'/><br>
        </div>
        <div class='col-md-5' style='font-size: 16px;'>
        </div>
        <div class='col-md-1'></div>
      </div>
      <div class='row'>
        <div class='col-md-1'>
        </div>
        <div class='col-md-10'>
            <div style='height: 20px;'></div>
            <div class='pep-hits-detail'>
                  <table width='100%' style='border: 1px solid whitesmoke'>
                    <tr>
                      <td width='100%'>
                          <button class='btn btn-link btn-block clearfix' style='align-items: center;background-color: none;border-bottom: 3px solid #daaa0e;color: white; display: flex;font-weight: bold;' accordion-heading>
                              <div *ngIf="probableMatches[currentMatch].matches.matchType == 'MEDIA'" class='pull-left float-left'><i class='fa fa-newspaper-o' aria-hidden='true' title='News Article' style='color: #daaa0e;font-size: 18px;'></i>&nbsp;&nbsp;</div>
                              <div *ngIf="probableMatches[currentMatch].matches.matchType == 'MEDIA'" class='pull-left float-left' style='color: #daaa0e;'><span style='font-size: 13px;'>[{{probableMatches[currentMatch].matches.riskType}}]</span> <a href='{{probableMatches[currentMatch].matches.mediaRiskDescriptor.url}}' target='_blank'> {{probableMatches[currentMatch].matches.mediaRiskDescriptor.headline | shorterText}}</a></div>
                              <div *ngIf="probableMatches[currentMatch].matches.matchType == 'PEP_OR_SANCTION' || probableMatches[currentMatch].matches.matchType == null" class='pull-left float-left'><i class='fa fa-user' aria-hidden='true' title='PEP/Sanction hit' style='color: #daaa0e;font-size: 18px;'></i>&nbsp;&nbsp;</div>
                              <div *ngIf="probableMatches[currentMatch].matches.matchType == 'SEARCH_ENGINE'" class='pull-left float-left'><i class='fa fa-search' aria-hidden='true' title='Search Engine hit' style='color: #daaa0e;font-size: 18px;'></i>&nbsp;&nbsp;</div>
                              <div *ngIf="probableMatches[currentMatch].matches.entityName != null && (probableMatches[currentMatch].matches.matchType == 'PEP_OR_SANCTION' || probableMatches[currentMatch].matches.matchType == null)" class='pull-left float-left pep-hit-probable screening-text' style='color: #daaa0e'>{{probableMatches[currentMatch].matches.entityName | shorterText}}</div>
                              <div *ngIf="probableMatches[currentMatch].matches.entityName == null && probableMatches[currentMatch].matches.entityRiskDescriptor.names.length > 0 && (probableMatches[currentMatch].matches.matchType == 'PEP_OR_SANCTION' || probableMatches[currentMatch].matches.matchType == null)" class='pull-left float-left pep-hit-probable screening-text' style='color: #daaa0e'>{{probableMatches[currentMatch].matches.entityRiskDescriptor.names[0] | shorterText}}</div>
                              <div *ngIf="probableMatches[currentMatch].matches.entityName == null && probableMatches[currentMatch].matches.entityRiskDescriptor.names.length == 0 && (probableMatches[currentMatch].matches.matchType == 'PEP_OR_SANCTION' || probableMatches[currentMatch].matches.matchType == null)" class='pull-left float-left pep-hit-probable screening-text' style='color: #daaa0e'></div>
                              <div *ngIf="probableMatches[currentMatch].matches.matchType == 'SEARCH_ENGINE'" class='pull-left float-left screening-text' style='color: #daaa0e;'><span style='font-size: 13px;'>[{{probableMatches[currentMatch].matches.riskType}}]</span> <a href='{{probableMatches[currentMatch].matches.searchEngineRiskDescriptor.url}}' target='_blank'> {{probableMatches[currentMatch].matches.searchEngineRiskDescriptor.headline | shorterText}}</a></div>
                              <div class='pull-right float-right' style='padding-left: 5px;'><img src="../../../assets/images/icons/add_further.png" width='20px' class='float-right' tooltip='Move to Further Research Matches' (click)="moveToFurther(probableMatches[currentMatch].entityId,probableMatches[currentMatch].matches?.hashedEntryId,'FURTHER_RESEARCH')" /></div>
                              <div class='pull-right float-right' style='padding-left: 5px;'><img src="../../../assets/images/icons/add_false.png" width='20px' class='float-right' tooltip='Move to Rejected Matches' (click)="moveToWhitelisted(probableMatches[currentMatch].entityId,probableMatches[currentMatch].matches?.hashedEntryId,'WHITE_LISTED')" /></div>
                              <div class='pull-right float-right' style='padding-left: 5px;'><img src="../../../assets/images/icons/add_hit.png" width='20px' class='float-right' tooltip='Move to Confirmed Matches' (click)="moveToConfirmed(probableMatches[currentMatch].entityId,probableMatches[currentMatch].matches?.hashedEntryId,'CONFIRMED')" /></div>
                          </button>
                      </td>
                    </tr>
                    <div style='height: 15px;'></div>
                    <tr>
                      <td width='100%' style='padding-left: 20px;'>
                        <span *ngIf="probableMatches[currentMatch].matches.note != null && probableMatches[currentMatch].matches.note != ''" class='note' style='background-color: wheat;padding: 5px;text-align: center;'>
                        {{probableMatches[currentMatch].matches.note}}</span>
                      </td>
                    </tr>
                    <div style='height: 10px;'></div>
                    <tr *ngIf="probableMatches[currentMatch].matches.matchType == 'MEDIA'">
                      <td style='color: black;width: 100%' width='100%'>
                          <div style='height: 10px;'></div>
                          <ul>
                              <li><b>Headline:</b> {{probableMatches[currentMatch].matches.mediaRiskDescriptor.headline}}</li>
                              <li *ngIf='probableMatches[currentMatch].matches.mediaRiskDescriptor.publicationDate != null'><b>Publication date:</b> {{probableMatches[currentMatch].matches.mediaRiskDescriptor.publicationDate | dateParser}}</li>
                              <li><b>Topic:</b> {{probableMatches[currentMatch].matches.mediaRiskDescriptor.topicLabel}}</li>
                              <li><b>Score:</b> <ng-container *ngIf='probableMatches[currentMatch].matches.score != 0'> {{probableMatches[currentMatch].matches.score}}</ng-container><ng-container *ngIf='probableMatches[currentMatch].matches.score == 0'> Not calculated</ng-container></li>
                              <div style='height: 10px;'></div>
                              <input style='background-color: #e6e6e6;border-radius: 0px;border: none;height: 27px;width: 95%;'
                               id='note' name='note' type='text' [(ngModel)]='note' placeholder='Enter note here...'>
                          </ul>
                            <div style='height: 10px'></div>
                      </td>
                    </tr>
                    <tr *ngIf="probableMatches[currentMatch].matches.matchType == 'SEARCH_ENGINE'">
                    <td style='color: black;width: 100%' width='100%'>
                        <div style='height: 10px;'></div>
                        <ul>
                            <li><b>Headline:</b> {{probableMatches[currentMatch].matches.searchEngineRiskDescriptor.headline}}</li>
                            <li *ngIf='probableMatches[currentMatch].matches.searchEngineRiskDescriptor.publicationDate != null'><b>Publication date:</b> {{probableMatches[currentMatch].matches.searchEngineRiskDescriptor.publicationDate | dateParser}}</li>
                            <li>{{probableMatches[currentMatch].matches.searchEngineRiskDescriptor.text}}</li>
                            <li><b>Score:</b> <ng-container *ngIf='probableMatches[currentMatch].matches.score != 0'> {{probableMatches[currentMatch].matches.score}}</ng-container><ng-container *ngIf='probableMatches[currentMatch].matches.score == 0'> Not calculated</ng-container></li>
                            <div style='height: 10px;'></div>
                            <input style='background-color: #e6e6e6;border-radius: 0px;border: none;height: 27px;width: 95%;'
                             id='note' name='note' type='text' [(ngModel)]='note' placeholder='Enter note here...'>
                        </ul>
                          <div style='height: 10px'></div>
                    </td>
                  </tr>
                    <tr *ngIf="probableMatches[currentMatch].matches.matchType == 'PEP_OR_SANCTION' || probableMatches[currentMatch].matches.matchType == null">
                      <td style='color: black;width: 100%' width='100%'>
                          <div style='height: 10px;'></div>
                          <ul>
                              <li *ngIf='probableMatches[currentMatch].matches.entityRiskDescriptor.descriptions != null && probableMatches[currentMatch].matches.entityRiskDescriptor.descriptions.length > 0'>{{probableMatches[currentMatch].matches.entityRiskDescriptor.descriptions[0]}}</li>
                              <li><b>First seen:</b> {{probableMatches[currentMatch].matches.entityRiskDescriptor.firstSeen | date}}</li>
                              <li><b>Last seen:</b> {{probableMatches[currentMatch].matches.entityRiskDescriptor.lastSeen | date}}</li>
                              <li><b>AKA Names:</b> {{probableMatches[currentMatch].matches.entityRiskDescriptor.names}}</li>
                              <li *ngIf='probableMatches[currentMatch].matches?.entityRiskDescriptor.birthDates && probableMatches[currentMatch].matches?.entityRiskDescriptor.birthDates.length > 0'><b>Birth date:</b> {{probableMatches[currentMatch].matches.entityRiskDescriptor.birthDates}}</li>
                              <li><b>Sanction List:</b> {{probableMatches[currentMatch].matches.entityRiskDescriptor.entryId | sanctionListPipe}}</li>
                              <li><b>Score:</b> <ng-container *ngIf='probableMatches[currentMatch].matches.score != 0'> {{probableMatches[currentMatch].matches.score}}</ng-container><ng-container *ngIf='probableMatches[currentMatch].matches.score == 0'> Not calculated</ng-container></li>
                              <div style='height: 10px;'></div>
                              <input style='background-color: #e6e6e6;border-radius: 0px;border: none;height: 27px;width: 95%;'
                               id='note' name='note' type='text' [(ngModel)]='note' placeholder='Enter note here...'>
                          </ul>
                            <div style='height: 10px'></div>
                      </td>
                    </tr>
                  </table>
            </div>
        </div>
        <div class='col-md-1'>
          </div>
      </div>
    </div>
    <div class='modal-footer'>
      <button class='btn btn-danger' (click)='close()'>Close</button>
    </div>
  `,
  styleUrls: ['./screening.component.css']
})

export class ScreenerResultsModalComponent implements OnInit  {
  probableMatches: any;
  currentEntity: any;
  currentMatch: any = 0;
  entityResponse: any = {};
  note: any = '';
  currentName: any = '';
  probableMatchesResponse: any = {};
  limit: any;
  start: any;
  loading: any = false;
  loadingImg: any = config.loadingImg;

  constructor(
    private kybService: KYBService,
    private dataService: DataService,
    private alertService: AlertService,
    private modalService: BsModalService
  ) {}

  ngOnInit() {
    this.currentMatch = 0;
    this.currentName = this.probableMatches[this.currentMatch].entityName;
  }

  getMoreMatches(limit: any, start: any) {
    this.kybService.getProbableMatches(limit, start).subscribe(
      data => {
        this.probableMatchesResponse = data;
        this.probableMatches = this.probableMatchesResponse.data;

        if (this.probableMatchesResponse.data.length === 0) {
          this.alertService.showSuccess('All matches have been evaluated.');
          this.close();
        } else {
          this.currentMatch = 0;

          if (this.probableMatches[this.currentMatch].entityName !== this.currentName) {
            this.alertService.showInfo('Now evaluating matches for <b>' + this.probableMatches[this.currentMatch].entityName + '</b>');
            this.currentName = this.probableMatches[this.currentMatch].entityName;
          }
        }
      }
    );
  }

  moveToFurther(entityId: any, entryId: any, newMatchStatus: any) {
    this.loading = true;
    this.kybService.moveMatch(entityId, entryId, newMatchStatus).subscribe(
      () => {
        this.loading = false;

        if (this.note !== null && this.note !== '') {
          this.kybService.updateMatchNote(entityId, entryId, this.note).subscribe(
            data => {
              this.note = '';

              if (this.currentMatch === (this.probableMatches.length - 1)) {
                this.start += this.limit;
                this.getMoreMatches(this.limit, this.start);
              } else {
                this.currentMatch++;
                if (this.probableMatches[this.currentMatch].entityName !== this.currentName) {
                  this.alertService.showInfo('Now evaluating matches for <b>' +
                  this.probableMatches[this.currentMatch].entityName + '</b>');
                  this.currentName = this.probableMatches[this.currentMatch].entityName;
                }
              }
            },
            error => {
              this.loading = false;
            }
          );
        } else {
          if (this.currentMatch === (this.probableMatches.length - 1)) {
            this.start += this.limit;
            this.getMoreMatches(this.limit, this.start);
          } else {
            this.currentMatch++;

            if (this.probableMatches[this.currentMatch].entityName !== this.currentName) {
              this.alertService.showInfo('Now evaluating matches for <b>' + this.probableMatches[this.currentMatch].entityName + '</b>');
              this.currentName = this.probableMatches[this.currentMatch].entityName;
            }
          }
        }
      },
      error => {
        this.loading = false;
      }
    );

  }

  moveToConfirmed(entityId: any, entryId: any, newMatchStatus: any) {
    this.loading = true;
    this.kybService.moveMatch(entityId, entryId, newMatchStatus).subscribe(
      data => {
        this.loading = false;
        if (this.note !== null && this.note !== '') {
          this.kybService.updateMatchNote(entityId, entryId, this.note).subscribe(
            () => {
              this.note = '';

              if (this.currentMatch === (this.probableMatches.length - 1)) {
                this.start += this.limit;
                this.getMoreMatches(this.limit, this.start);
              } else {
                this.currentMatch++;

                if (this.probableMatches[this.currentMatch].entityName !== this.currentName) {
                  this.alertService.showInfo('Now evaluating matches for <b>' +
                  this.probableMatches[this.currentMatch].entityName + '</b>');
                  this.currentName = this.probableMatches[this.currentMatch].entityName;
                }
              }
            },
            error => {
              this.loading = false;
            }
          );
        } else {
          if (this.currentMatch === (this.probableMatches.length - 1)) {
            this.start += this.limit;
            this.getMoreMatches(this.limit, this.start);
          } else {
            this.currentMatch++;

            if (this.probableMatches[this.currentMatch].entityName !== this.currentName) {
              this.alertService.showInfo('Now evaluating matches for <b>' + this.probableMatches[this.currentMatch].entityName + '</b>');
              this.currentName = this.probableMatches[this.currentMatch].entityName;
            }
          }
        }
      },
      error => {
        this.loading = false;
      }
    );

  }

  moveToWhitelisted(entityId: any, entryId: any, newMatchStatus: any) {
    this.loading = true;

    this.kybService.moveMatch(entityId, entryId, newMatchStatus).subscribe(
      data => {
        this.loading = false;

        if (this.note != null && this.note !== '') {
          this.kybService.updateMatchNote(entityId, entryId, this.note).subscribe(
            () => {
              this.note = '';
              if (this.currentMatch === (this.probableMatches.length - 1)) {
                this.start += this.limit;
                this.getMoreMatches(this.limit, this.start);
              } else {
                this.currentMatch++;

                if (this.probableMatches[this.currentMatch].entityName !== this.currentName) {
                  this.alertService.showInfo('Now evaluating matches for <b>' +
                  this.probableMatches[this.currentMatch].entityName + '</b>');
                  this.currentName = this.probableMatches[this.currentMatch].entityName;
                }
              }
            },
            error => {
              this.loading = false;
            }
          );
        } else {
          if (this.currentMatch === (this.probableMatches.length - 1)) {
            this.start += this.limit;
            this.getMoreMatches(this.limit, this.start);
          } else {
            this.currentMatch++;

            if (this.probableMatches[this.currentMatch].entityName !== this.currentName) {
              this.alertService.showInfo('Now evaluating matches for <b>' + this.probableMatches[this.currentMatch].entityName + '</b>');
              this.currentName = this.probableMatches[this.currentMatch].entityName;
            }
          }
        }
      },
      error => {
        this.loading = false;
      }
    );
  }

  addNote(entityId: any, entryId: any, note: any) {
    if (this.note !== null && this.note !== '') {
      this.kybService.updateMatchNote(entityId, entryId, note).subscribe(
        data => {
          this.note = '';
          this.probableMatches[this.currentMatch].matches.note = note;
        },
        error => {}
      );
    }
  }

  seeDetails(name: any) {
    const encoded = encodeURIComponent(name);
    const origin = window.location.origin;
    const url = origin + '/search?q=' + encoded;
    window.open(url, '_blank');
  }

  close() {
    this.modalService.hide(1);
    this.dataService.triggerScreeningRefresh(1);
    // tslint:disable-next-line:max-line-length
    this.addNote(this.probableMatches[this.currentMatch].entityId, this.probableMatches[this.currentMatch].matches.hashedEntryId, this.note);
  }
}

/**
 * Implements batch user importing (by uploading and parsing a csv file with user details) - not currently used
*/
@Component({
    moduleId: module.id,
    templateUrl: 'screening.component.html',
    styleUrls: ['./screening.component.css']
})

export class ScreeningComponent implements OnInit {

  allRiskResponse: any = {};
  entityIds: any = [];
  entities: any = [];
  dataTable: any;
  probableMatchesCollection: any = [];
  probableMatchesResponse: any = {};
  noResults: any = true;
  pageSize: any = 30;
  currentPage: any = 0;
  sortingOption: any = null;
  searchQuery: any = null;
  isEmpty: any = false;
  userResponse: any = {};
  tagsResponse: any = {};
  tmpTags: any = [];
  allTags: any = [];
  hiddenTags: any = [];
  userTags: any = [];
  includedTags: any = [];
  excludedTags: any = [];
  displayFilters: any = false;

  matchStatus: any = null;
  matchStatuses = ['SHOW ALL ENTITIES', 'SHOW ALL WHITELISTED', 'SHOW ALL CONFIRMED', 'SHOW ALL FURTHER RESEARCH', 'SHOW ALL PROBABLE'];
  status: any = null;
  userPermissions: any = [];

  page: number;
  totalItems: any = 0;
  numberOfElements: any = 0;

  displayModal: BsModalRef;

  constructor(
    private alertService: AlertService,
    private callService: CallsService,
    private dataService: DataService,
    private kybService: KYBService,
    private modalService: BsModalService
  ) {}

  ngOnInit() {
    this.kybService.getUserPermissions().subscribe(
      value => this.userPermissions = value.data.permissions
    );

    this.kybService.getCurrentUser().subscribe(
      data => {
        this.userResponse = data;
        this.kybService.getAllTags().subscribe(
          tagData => {
            this.tagsResponse = tagData;
            this.includedTags = [];
            this.excludedTags = [];
            this.tmpTags = [];
            this.userTags = [];
            this.allTags = [];

            for (let i = 0; i < this.tagsResponse.data.length; i++) {
              this.allTags.push(this.tagsResponse.data[i].id);
            }

            if (this.userResponse && this.userResponse.data && this.userResponse.data.hiddenTags) {
              for (let j = 0; j < this.userResponse.data.hiddenTags.length; j++) {
                this.hiddenTags.push(this.userResponse.data.hiddenTags[j].id);
              }
            }

            for (let k = 0; k < this.allTags.length; k++) {
              if (this.hiddenTags.indexOf(this.allTags[k]) === -1) {
                this.tmpTags.push(this.allTags[k]);
              }
            }

            for (let l = 0; l < this.tagsResponse.data.length; l++) {
              if (this.tmpTags.indexOf(this.tagsResponse.data[l].id) !== -1) {
                this.userTags = [...this.userTags, {id: this.tagsResponse.data[l].id, name: this.tagsResponse.data[l].name}];
              }
            }
          }
        );
      }, error => {}
    );

    this.entities = [];
    this.entityIds = [];
    this.probableMatchesCollection = [];

    this.sortingOption = 'name,asc';
    this.excludedTags = this.hiddenTags;
    this.matchStatus = this.matchStatuses[4];
    this.getAllRiskWithStatusCheck();

    $('#name-asc-sorticon').css('opacity', '1.0');
  }

  toggleFilters() {
    if (this.displayFilters === true) {
      this.displayFilters = false;
    } else {
      this.displayFilters = true;
    }
  }

  getAllRiskWithStatusCheck() {
    switch (this.matchStatus) {
      case 'SHOW ALL WHITELISTED':
        this.status = 'WHITE_LISTED';
        break;
      case 'SHOW ALL CONFIRMED':
        this.status = 'CONFIRMED';
        break;
      case 'SHOW ALL FURTHER RESEARCH':
        this.status = 'FURTHER_RESEARCH';
        break;
      case 'SHOW ALL PROBABLE':
        this.status = 'PROBABLE';
        break;
      default:
        this.status = null;
    }

    // tslint:disable-next-line:max-line-length
    this.getAllRisk(this.pageSize, this.currentPage, this.sortingOption, this.searchQuery, this.status, this.includedTags, this.excludedTags);
  }

  tagsChanged(event) {
    this.entities = [];
    this.currentPage = 0;

    this.getAllRiskWithStatusCheck();
  }

  selectAllTags() {
    this.entities = [];
    this.includedTags = this.tmpTags;
    this.currentPage = 0;

    this.getAllRiskWithStatusCheck();
  }

  deselectAllTags() {
    this.entities = [];
    this.includedTags = [];

    this.getAllRiskWithStatusCheck();
  }

  pageChanged(event: any) {
    this.getAllRiskWithStatusCheck();
  }

  sort(column: any, direction: any) {
    this.currentPage = 0;
    this.sortingOption = column + ',' + direction;
    $(`i[id*='-sorticon']`).css('opacity', '0.4');
    $('#' + column + '-' + direction + '-sorticon').css('opacity', '1.0');

    this.getAllRiskWithStatusCheck();
  }

  pageSizeChanged() {
    this.currentPage = 0;

    this.getAllRiskWithStatusCheck();
  }

  search() {
    this.currentPage = 0;

    this.getAllRiskWithStatusCheck();
  }

  // tslint:disable-next-line:max-line-length
  getAllRisk(pageSize: any, currentPage: any, sortingOption: any, searchQuery: any, matchStatus: any, includedTags: any, excludedTags: any) {
    this.entities = [];
    this.entityIds = [];
    this.probableMatchesCollection = [];
    this.probableMatchesResponse = {};

    this.kybService.getAllRisk(pageSize, currentPage, sortingOption, searchQuery, matchStatus, includedTags, excludedTags).subscribe(
      data => {
        this.allRiskResponse = data;
        this.totalItems = this.allRiskResponse.data.totalElements;
        this.numberOfElements = this.allRiskResponse.data.numberOfElements;

        if (this.totalItems === 0 || this.numberOfElements === 0) {
          this.isEmpty = true;
        } else {
          this.isEmpty = false;
        }

        for (let i = 0; i < this.allRiskResponse.data.content.length; i++) {
            this.entityIds.push(this.allRiskResponse.data.content[i].entityId);
            // tslint:disable-next-line:max-line-length
            this.entities.push({'id': this.allRiskResponse.data.content[i].entityId, 'entityName': this.allRiskResponse.data.content[i].entityName, 'probableMatches': this.allRiskResponse.data.content[i].probableMatchesCount, 'confirmedMatches': this.allRiskResponse.data.content[i].confirmedMatchesCount, 'furtherResearchMatches': this.allRiskResponse.data.content[i].toFurtherResearchMatchesCount, 'whitelistedMatches': this.allRiskResponse.data.content[i].whiteListedMatchesCount});
        }

        if (this.entities.length !== 0) {
          this.noResults = false;

          this.kybService.getProbableMatches(50, 0).subscribe(
            match => {
              this.probableMatchesResponse = match;

              if (this.probableMatchesResponse.data.length === 0) {
                this.noResults = true;
                this.alertService.showInfo('There are no results to review at the moment.');
              } else {
                this.noResults = false;
                this.probableMatchesCollection = this.probableMatchesResponse.data;
              }
            }
          );
        } else {
          this.noResults = true;
          this.alertService.showInfo('There are no results to review at the moment.');
        }
      },
      error => {
        this.isEmpty = true;
      }
    );
  }

  exportCSV() {
    let csvData = 'ID,Name,Probable Matches,Confirmed Matches,Rejected Matches,Further Research Matches\n';
    for (let i = 0; i < this.entities.length; i++) {
      if (this.entities[i] !== null) {
        // tslint:disable-next-line:max-line-length
        csvData += (this.entities[i].id + ',' + this.entities[i].entityName + ',' + this.entities[i].probableMatches + ',' + this.entities[i].confirmedMatches + ',' + this.entities[i].whitelistedMatches + ',' + this.entities[i].furtherResearchMatches + '\n');
      }
    }

    const hiddenElement = document.createElement('a');
    hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURI(csvData);
    hiddenElement.target = '_blank';
    hiddenElement.download = 'entities_export.csv';
    hiddenElement.click();
  }

  displayScreenerModal(probableMatchesCollection: any) {
    if (probableMatchesCollection.length > 0) {
      const initialState = {
        'probableMatches': probableMatchesCollection,
        'limit': 50,
        'start': 0
      };

      this.displayModal = this.modalService.show(ScreenerResultsModalComponent, { initialState, class: 'modal-lg screening', backdrop: 'static' });
      this.modalService.onHide.subscribe(message => {
        if (document.querySelectorAll('body.modal-open')) {
          document.querySelectorAll('body.modal-open')[0].classList.remove('modal-open');
        }
        this.entities = [];
        this.includedTags = [];
        this.currentPage = 0;
        this.ngOnInit();
      });
    } else {
      window.alert(`There is nothing to evaluate at the moment. Maybe a screening hasn't been run yet.`);
    }
  }
}
