import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReportingComponent } from './reporting.component';
import { ActivityReportingModule } from './activityReport/activity-reporting.module';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { EntityReportModule } from './entityReport/entity-report.module';
import { ScreeningReportModule } from './screeningReport/screening-report.module';
import { ReportingRoutingModule } from './reporting-routing';

@NgModule({
  declarations: [
    ReportingComponent
  ],
  imports: [
    CommonModule,
    ActivityReportingModule,
    EntityReportModule,
    ScreeningReportModule,
    TabsModule,
    ReportingRoutingModule
  ],
  exports: [
    ReportingComponent
  ]
})
export class ReportingModule { }
