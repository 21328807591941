import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AlertService, CallsService, AuthenticationService } from '../../../_services';

/**
* Implements password changing directive
*/
@Component({
    moduleId: module.id,
    selector: 'app-user-management',
    templateUrl: 'userManagement.component.html',
    styleUrls: [ './userManagement.component.css' ]
})

export class UserManagementComponent implements OnInit {

  constructor (
    private router: Router,
    private callsService: CallsService,
    private alertService: AlertService,
    private authenticationService: AuthenticationService
  ) {}

  ngOnInit() {}
}
