import { Component, OnInit, ElementRef, EventEmitter, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { AlertService, CallsService, KYBService, DataService } from '../../../../_services';
import { Personal } from '../../data/formData.model';
import { DeviceDetectorService } from 'ngx-device-detector';
import { FormDataService } from '../../data/formData.service';
import { config } from '../../../../../assets/configuration';
import { WebcamImage, WebcamInitError, WebcamUtil } from 'ngx-webcam';
import { Subject, Observable } from 'rxjs';
import { BsModalRef, ModalOptions } from 'ngx-bootstrap/modal';
import { TranslateService } from '@ngx-translate/core';

declare var MediaRecorder: any;

/**
 * Implements the login page
*/
@Component({
    moduleId: module.id,
    // tslint:disable-next-line:component-selector
    selector: 'mobile-document-onboarding-image-capture',
    templateUrl: 'mobile-document-onboarding-image-capture.component.html',
    styleUrls: ['./mobile-document-onboarding-image-capture.component.css']
})

export class MobileDocumentOnboardingImageCaptureComponent implements OnInit, OnDestroy {

    public get triggerObservable(): Observable<void> {
        return this.trigger.asObservable();
    }

    public get nextWebcamObservable(): Observable<boolean | string> {
        return this.nextWebcam.asObservable();
    }

    // toggle webcam on/off
    public showWebcam = true;

    public allowCameraSwitch = false;
    public multipleWebcamsAvailable = false;
    public deviceId: string;
    public videoOptions: MediaTrackConstraints = {
        facingMode: { ideal: 'environment' },
        width: { ideal: 764 },
        height: { ideal: 1246 }
    };
    public errors: WebcamInitError[] = [];
    captureEnabled = true;
    // latest snapshot
    public webcamImage: WebcamImage = null;

    // webcam snapshot trigger
    private trigger: Subject<void> = new Subject<void>();
    // switch to next / previous / specific webcam; true/false: forward/backwards, string: deviceId
    private nextWebcam: Subject<boolean | string> = new Subject<boolean | string>();
    public get ios(): boolean { return !!this.agent.match(/(iPhone)|(iPod)/i); }
    public get agent(): any { return navigator.userAgent || navigator.vendor; }
    CAMERA_MIC_ACCESS_BLOCKED = '';

    imageText = 'Identity Document Photo';
    personal: Personal;
    form: any;
    nationalityFilled = false;
    nationality = '';
    minDate: Date;
    maxDate: Date;
    chunks: any = [];
    loading = false;
    loadingImg: any = config.loadingImg;
    documentType = '';
    identityDocument = '';
    text: String;
    passportFront: File;
    front: File;
    back: File;
    public event: EventEmitter<any> = new EventEmitter();
    permissionDenied = false;

    captures: any = [];
    passportCapture = '';
    passportCaptured = false;
    passportStream: any;
    idStream: any;
    isMobile = false;
    email = '';
    documentIds: any;
    currentStack: any = {};
    isOnMobile = false;
    width = window.innerWidth * 1.1;
    height = this.width / 2;
    imageCapture = '';
    imageScr = '../../../../assets/images/id.png';

    selfRoleId: any;
    onboardingEntityId: any;

    constructor(
        private translate: TranslateService,
        public bsModalRef: BsModalRef,
        private router: Router,
        private formDataService: FormDataService,
        private kybService: KYBService,
        private eRef: ElementRef,
        private callsService: CallsService,
        private alertService: AlertService,
        private dataService: DataService,
        private deviceDetectorService: DeviceDetectorService,
        public options: ModalOptions
    ) {
        this.minDate = new Date();
        this.maxDate = new Date();
        this.minDate.setDate(this.minDate.getDate() - 42705);
        this.maxDate.setDate(this.maxDate.getDate() - 6570);
        const x = options.initialState as any;
        this.documentType = x.documentType;
        this.setImageText('FRONT');
    }

    ngOnDestroy(): void {
        this.showWebcam = false;
    }

    setImageText(text: string) {
        if (this.documentType === 'passport') {
            this.imageText = this.translate.instant('PASSPORT');
        } else if (this.documentType === 'id') {
            this.imageText = this.translate.instant(text + '_ID');
        } else {
            this.imageText = this.translate.instant(text + '_DRIVER_LICENSE');
        }
    }

    ngOnInit() {
        if (this.ios ) {
            this.CAMERA_MIC_ACCESS_BLOCKED = this.translate.instant('CAMERA_MIC_ACCESS_BLOCKED_IOS');
        } else {
            this.CAMERA_MIC_ACCESS_BLOCKED = this.translate.instant('CAMERA_MIC_ACCESS_BLOCKED_ANDROID');
        }

        WebcamUtil.getAvailableVideoInputs()
            .then((mediaDevices: MediaDeviceInfo[]) => {
                this.multipleWebcamsAvailable = mediaDevices && mediaDevices.length > 1;
            });
    }

    /**
    * Function used for validating the form
    * @param {any} form - form data
    * @returns {boolean} is form valid?
    */
    blobToFile(theBlob, fileName) {
        theBlob.lastModifiedDate = new Date();
        theBlob.name = fileName;
        return theBlob;
    }
    public triggerSnapshot(): void {
        this.captureEnabled = false;
        this.trigger.next();
    }

    public toggleWebcam(): void {
        this.showWebcam = !this.showWebcam;
    }

    public handleInitError(error: WebcamInitError): void {
        this.errors.push(error);
        if (error.mediaStreamError && error.mediaStreamError.name === 'NotAllowedError') {
            this.permissionDenied = true;
        }
    }

    public showNextWebcam(directionOrDeviceId: boolean | string): void {
        // true => move forward through devices
        // false => move backwards through devices
        // string => move to device with given deviceId
        this.nextWebcam.next(directionOrDeviceId);
    }

    public handleImage(webcamImage: WebcamImage): void {
        this.webcamImage = webcamImage;

        if (this.documentType === 'passport') {
            const imageBase64 = webcamImage.imageAsBase64;
            this.identityDocument = webcamImage.imageAsDataUrl;
            const base64 = this.identityDocument.split(',');
            const imageBlob = this.dataURItoBlob(base64[1]);
            this.passportCapture = this.identityDocument;
            this.passportFront = new File([imageBlob], 'passportFront.png', { type: 'image/png' });
        }

        if (this.documentType === 'id') {
            if (this.captures.length >= 2) {}

            // Changing the overlay according to if it is the front or back side

            this.identityDocument = webcamImage.imageAsDataUrl;
            this.captures.push(this.identityDocument);

            if (this.captures[0] !== undefined) {
                const base64front = this.captures[0].split(',');
                const imageBlobFront = this.dataURItoBlob(base64front[1]);
                this.front = new File([imageBlobFront], 'idFront.png', { type: 'image/png' });
                this.passportFront = new File([imageBlobFront], 'idFront.png', { type: 'image/png' });
            }

            if (this.captures[1] !== undefined) {
                const base64front = this.captures[1].split(',');
                const imageBlobBack = this.dataURItoBlob(base64front[1]);
                this.back = new File([imageBlobBack], 'idBack.png', { type: 'image/png' });
            }
        }

        if (this.documentType === 'driversLicense') {
            this.identityDocument = webcamImage.imageAsDataUrl;
            this.captures.push(this.identityDocument);

            if (this.captures[0] !== undefined) {
                const base64front = this.captures[0].split(',');
                const imageBlobFront = this.dataURItoBlob(base64front[1]);
                this.front = new File([imageBlobFront], 'driversFront.png', { type: 'image/png' });
                this.passportFront = new File([imageBlobFront], 'idFront.png', { type: 'image/png' });
            }

            if (this.captures[1] !== undefined) {
                const base64front = this.captures[1].split(',');
                const imageBlobBack = this.dataURItoBlob(base64front[1]);
                this.back = new File([imageBlobBack], 'driversBack.png', { type: 'image/png' });
            }
        }
    }

    public cameraWasSwitched(deviceId: string): void {
        this.deviceId = deviceId;
    }

    use() {
        if (this.documentType === 'passport') {
            this.close();
        } else {
            this.setImageText('BACK');
            this.imageScr = '../../../../assets/images/id_back.png';
            this.captureEnabled = true;
        }

        if (this.captures[1] !== undefined) {
            this.close();
        }
    }

    retake() {
        this.captureEnabled = true;

        if (this.captures[1] === undefined) {
            if (this.captures[0] !== undefined) {
                this.captures = [];
            }
        } else if (this.captures[1] !== undefined) {
            this.captures.splice(1, 1);
        }
    }

    close() {
        this.captureEnabled = false;
        this.event.emit({ passportFront: this.passportFront, front: this.front, back: this.back });
        this.bsModalRef.hide();
    }

    cancel() {
        this.bsModalRef.hide();
    }

    dataURItoBlob(dataURI) {
        const byteString = atob(dataURI);
        const arrayBuffer = new ArrayBuffer(byteString.length);
        const int8Array = new Uint8Array(arrayBuffer);

        for (let i = 0; i < byteString.length; i++) {
            int8Array[i] = byteString.charCodeAt(i);
        }

        const blob = new Blob([arrayBuffer], { type: 'image/jpeg' });

        return blob;
    }
}
