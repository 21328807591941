import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { CallsService } from '../../../_services/calls.service';
import { FormDataService } from '../data/formData.service';
import { Disclaimers } from '../data/formData.model';
import { AlertService, DataService } from '../../../_services';
import { config } from '../../../../assets/configuration';
import { NgForm } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { BrandingService } from '../../../branding/branding.service';
import { BrandingSetup } from '../../../branding/branding.model';

/**
 * Implements the disclaimers (final) step of the registering form
*/
@Component({
    selector: 'app-disclaimers',
    templateUrl: './disclaimers.component.html'
})

export class DisclaimersComponent implements OnInit, AfterViewInit {
    title = 'DISCLAIMERS';
    disclaimers: Disclaimers;
    form: any;
    formData: any;
    loading = false;
    loadingImg = 'data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==';
    captchaOk = false;
    companyName = config.companyName;
    captchaKey = config.captchaKey;
    captcha = '';
    stepper: any = '';
    thirdParty = 0;
    disclaimerWithLinks = '';

    @ViewChild('disclaimersForm', { static: false }) public disclaimersForm: NgForm;
    @Output() notifyParent: EventEmitter<boolean> = new EventEmitter();
    @Input() customOnboarding = false;

    constructor(
        private router: Router,
        private formDataService: FormDataService,
        private translate: TranslateService,
        private callsService: CallsService,
        private alertService: AlertService,
        private brandingService: BrandingService,
        private dataService: DataService
    ) {}

    ngOnInit() {
        this.brandingService.brandingSetup.subscribe((res: BrandingSetup) => {
        if (!res) { return; }
        this.stepper = res.mainColor;
        });
        if (document.querySelectorAll('.mat-step-icon-selected')) {
            (<HTMLElement>document.querySelectorAll('.mat-step-icon-selected')[0]).style.backgroundColor = `${this.stepper}`;
        }
        this.disclaimers = this.formDataService.getDisclaimers();
        this.disclaimerWithLinks = this.translate.instant('DISCLAIMER_ITEM_WITH_LINKS');
        this.dataService.thirdParty.subscribe(message => this.thirdParty = message);
    }

    ngAfterViewInit() {
        this.disclaimersForm.statusChanges
            .subscribe(() => this.notifyParent.emit(this.disclaimersForm.valid));
    }

    /**
    * Function used for validating the form
    * @param {any} form - form data
    * @returns {boolean} is form valid?
    */
    save(form: any): boolean {
        if (!form.valid) {
            return false;
        }

        this.formDataService.setDisclaimers(this.disclaimers);

        return true;
    }

    /**
    * Function used to catch the successful reCAPTCHA event
    */
    resolved(captchaResponse: string) {
        this.captcha = captchaResponse;
        this.captchaOk = true;
    }

    /**
    * Function used for navigation
    */
    goToPrevious(form: any) {
        this.formDataService.setRegisterState('3');
    }

    /**
    * Function used to check all disclaimer checkboxes
    */
    checkAll() {
        (document.getElementById('onOwnBehalf') as HTMLInputElement).checked = true;
        this.disclaimers.onOwnBehalf = true;
        (document.getElementById('nonUs') as HTMLInputElement).checked = true;
        this.disclaimers.nonUs = true;
        (document.getElementById('fullAndFactual') as HTMLInputElement).checked = true;
        this.disclaimers.fullAndFactual = true;
        this.disclaimers.exclusionStatement = true;
        this.disclaimers.nonFATF = true;
        this.disclaimers.acceptanceOfRiskDisclaimer = true;
        this.checkDisclaimers();
    }

    /**
    * Function used to submit the registration form
    */
    submit(form: any) {
        this.loading = true;

        if (this.save(form)) {
            this.alertService.showSuccess('Registration successful');
            window.alert('Registration successful, you will be redirected to the login page.');
            window.location.href = '/login';
        }
    }

    checkDisclaimers() {
        if (this.disclaimers.onOwnBehalf) {
            this.formDataService.setdisclaimersState(true);
        } else {
            this.formDataService.setdisclaimersState(false);
        }
    }

    goToNext(form: any) {
        if (this.save(form)) {
            this.formDataService.setRegisterState('5');
        }
    }
}
