
    <div class='modal-header'>
      <h4 class='modal-title pull-left'>Manual {{type}} Risk Adjustment</h4>
      <button type='button' class='close pull-right' aria-label='Close' (click)='bsModalRef.hide()'>
        <span aria-hidden='true'>&times;</span>
      </button>
    </div>
    <div class='modal-body'>
      <p style='font-size: 14px;font-weight: bold;'> Do you want to clear the manual risk assesment? <br>This will reset the '{{type}}' risk.</p>
      <label>Please enter the justification for this action: *</label><textarea class='form-control input-md' style='border: 1px black solid; border-radius: 0px;font-weight: normal;' [(ngModel)]='justification'></textarea>
    </div>
    <div class='modal-footer' style='text-align: right;'>
      <button class='btn btn-danger btn-delete' (click)='cancel()' style='width: 20%;padding: 5px;margin-top: -3px;' [disabled]='loading'>Cancel</button>
      <button class='btn btn-success' (click)='adjustAndLog()' [disabled]='justification == "" || loading' style='width: 20%;padding: 5px;margin-top: -3px; text-wrap: wrap'>Adjust and Log</button>
    </div>
  