import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';

/**
 * Implements authentication functions
*/
@Injectable()
export class AuthenticationService {

    constructor(
      private http: HttpClient,
      private router: Router
    ) {}

    /**
      * Function that creates a cookie
      * @param {String} cookieName - name of the cookie
      * @param {String} cookieNameValue - value of the cookie
      * @param {number} expiresinSecs - expiration time of cookie in seconds
    */
    setCookie(cookieName: string, cookienameValue: string, expiresinSecs: number) {
      const now = new Date();
      const exp = new Date(now.getTime() + expiresinSecs * 1000);
      document.cookie = cookieName + '=' + cookienameValue + '; expires=' + exp.toUTCString();
    }

    /**
      * Function that deletes a cookie
      * @param {String} cookieName - name of the cookie to be deleted
      * @param {String} cookieNameValue - value of the cookie to be deleted
    */
    deleteCookie(cookieName: string, cookienameValue: string) {
      document.cookie = cookieName + '=' + cookienameValue + ';expires=Thu, 01 Jan 1970 00:00:01 GMT';
    }

    /**
      * Function for logging in
      * @param {String} username - username of the user
      * @param {String} password - password of the user
    */
    login(username: string, password: string) {
      return this.http.post<any>('/api/v3/auth', { email: username, password: password });
    }

    /**
      * Function for logging out
    */
    logout() {
      let logout = false;

      // remove user from local storage to log user out
      // tslint:disable-next-line:max-line-length
      if (localStorage.getItem('auth_token') && localStorage.getItem('auth_token').length > 0 && localStorage.getItem('login_token') && localStorage.getItem('login_token').length > 0) {
        localStorage.removeItem('auth_token');
        localStorage.removeItem('login_token');
        logout = true;
      }

      // remove expires-at
      if (localStorage.getItem('expires_at') && localStorage.getItem('expires_at').length > 0) {
        localStorage.removeItem('expires_at');
        logout = true;
      }

      // remove username
      if (localStorage.getItem('username') && localStorage.getItem('username').length > 0) {
        localStorage.removeItem('username');
        logout = true;
      }

      // remove logout cookie
      if (document.cookie && document.cookie.indexOf('logoutCookie') !== -1) {
        this.deleteCookie('logoutCookie', localStorage.getItem('login_token'));
        logout = true;
      }

      if (sessionStorage.getItem('pageSize') !== null) {
        sessionStorage.removeItem('pageSize');
      }

      if (sessionStorage.getItem('sortingOption') !== null) {
        sessionStorage.removeItem('sortingOption');
      }

      if (sessionStorage.getItem('includedTags') !== null) {
        sessionStorage.removeItem('includedTags');
      }

      if (sessionStorage.getItem('excludedTags') !== null) {
        sessionStorage.removeItem('excludedTags');
      }

      if (sessionStorage.getItem('selectedOwner') !== null) {
        sessionStorage.removeItem('selectedOwner');
      }

      // redirect to login page
      if (logout) {
        this.router.navigate(['/login']);
      }
    }

  public postTfaSetup(code: any): any {
   const  httpOptions = {
      headers: new HttpHeaders()
        .append('Content-Type', 'application/json')
        .append('X-Auth-Token', JSON.parse(localStorage.getItem('auth_token')))
    };
    return this.http.put('/api/v3/auth/mfa/enable', {
      "code": code
    }, httpOptions);
  }

  public getTfaImage(email: any): any {
   const  httpOptions = {
      headers: new HttpHeaders()
        .append('Content-Type', 'application/json')
        .append('X-Auth-Token', JSON.parse(localStorage.getItem('auth_token')))
    };
    return this.http.post('/api/v3/user/mfa/qr', {}, httpOptions);
  }


  public loginUserTfa(usern, pass, code: any) {
    const userId = localStorage.getItem('identifier');

    const  httpOptions = {
      headers: new HttpHeaders()
        .append('Content-Type', 'application/json')
    };

    return this.http.post<any>('/api/v3/auth/mfa', { "email": usern, "password": pass, "mfaCode": code }, httpOptions);
  }

  public disableTfa() {
    const userId = localStorage.getItem('identifier');

    const  httpOptions = {
      headers: new HttpHeaders()
        .append('Content-Type', 'application/json')
        .append('X-Auth-Token', JSON.parse(localStorage.getItem('auth_token')))

    };
    return this.http.put('/api/v3/user/mfa/' + userId + '/reset', { }, httpOptions);
  }

  public recoverTfa(codes) {
    const userId = localStorage.getItem('identifier');

    const  httpOptions = {
      headers: new HttpHeaders()
        .append('Content-Type', 'application/json')
    };
    return this.http.put<any>('/api/v3/user/mfa/reset', codes, httpOptions);
  }
}
