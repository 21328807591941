import { Component, OnInit } from '@angular/core';
import { KYBService } from '../../../_services';

@Component({
    moduleId: module.id,
    selector: 'app-tag-management',
    templateUrl: 'tagManagement.component.html',
    styleUrls: ['./tagManagement.component.css']
})

export class TagManagementComponent implements OnInit {

  tagsResponse: any = {};
  tags: any = [];
  selectedTags: any = [];

  createdTagId = 0;

  constructor(
    private kybService: KYBService
  ) {}

  ngOnInit() {
    this.kybService.getAllTags().subscribe(
      data => {
        this.tagsResponse = data;
        this.tags = this.tagsResponse.data;
        this.selectedTags = this.tags;
      }
    );
  }

  addTagFn(name) {
    return { name: name, tag: true };
  }

  addTagEvent(event) {
    this.kybService.createTag(event.name).subscribe(
      (tagData: any) => {
        this.createdTagId = tagData.data.id;
      },
      error => {
      }
    );
  }

  removeTagEvent(event) {
    if (event.value.id) {
      this.createdTagId = event.value.id;
    }

    this.kybService.deleteTag(this.createdTagId).subscribe(
      data => {},
      error => {}
    );
  }
}
