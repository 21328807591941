import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BsDaterangepickerConfig } from 'ngx-bootstrap/datepicker';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { config } from '../../../../assets/config/configuration';
import { SearchService, WebCallsService, WebDataService } from '../../../_services';
import { CreditAlertComponent } from '../creditAlert/creditAlert.component';

@Component({
  moduleId: module.id,
  template: `
      <div class='modal-header modal-dialog-centered'>
          <h4 class='modal-title pull-left'>{{modalTitle}}</h4>
          <button type='button' class='close pull-right' aria-label='Close' (click)='bsModalRef.hide()'>
          <span aria-hidden='true'>&times;</span>
          </button>
      </div>
      <div class='modal-body' style='overflow-x: scroll;'>
          <p style='color: black;padding: 20px;' [innerHTML]='text'>
          </p>
      </div>
      <div class='modal-footer'>
          <button type='button' class='btn btn-default' (click)='bsModalRef.hide()'>{{closeBtnName}}</button>
      </div>
  `,
})

export class EntityDetailComponent implements OnInit {

  modalTitle: string;
  closeBtnName: string;
  id: any;
  name: any;
  type: any;
  relations: any;
  text: any;
  newString: any;

  constructor(public bsModalRef: BsModalRef) {}

  ngOnInit() {
      this.text = this.text.replace(/(\r\n|\n|\r)/gm, '<br />');
  }
}

@Component({
    moduleId: module.id,
    templateUrl: 'search.component.html',
    styleUrls: [ './search.component.css' ]
})

export class SearchComponent implements OnInit {
  bsModalRef: BsModalRef;
  credits: any;
  currentFilterOption = '';
  currentSortOption = '';
  currentPage: any;
  dateRange: any;
  encodedQuery: string;
  filtered = false;
  filters = [];
  filterSearch = '';
  hits = 0;
  loading = false;
  loadingImg: any = config.loadingGif;
  maxSize = 10;
  modalRef: BsModalRef;
  noResults = false;
  page: number;
  pageSize = 25;
  pep = false;
  results: any = {};
  sanction = false;
  searchQuery: string;
  selected_count = 0;
  selected_sources: any;
  sourcesMetadata: any = [];
  sources: any;
  time = 0;
  totalItems = 0;

  dpConfig: Partial<BsDaterangepickerConfig> = new BsDaterangepickerConfig();

  constructor(
    private searchService: SearchService,
    private modalService: BsModalService,
    private route: ActivatedRoute,
    private router: Router,
    private webCallsService: WebCallsService,
    private webDataService: WebDataService
  ) {}

  ngOnInit() {
    this.dpConfig.containerClass = 'theme-dark-blue';
    this.dpConfig.rangeInputFormat = 'DD/MM/YYYY';
    this.dpConfig.showWeekNumbers = false;

    this.webCallsService.getCreditsForUser().subscribe(
      data => {
        this.credits = data;
        this.credits = this.credits.credits;

        if (this.credits <= 20) {
          this.webCallsService.addToAutopilotJourney(localStorage.getItem('username'), '0014').subscribe(
            error => {}
          );

          this.showAlert(this.credits);
        }
      },
      error => {}
    );

    this.webCallsService.getSources().subscribe(
      data => this.sourcesMetadata = data,
      error => {}
    );

    this.webDataService.isPepSanctionFilter.subscribe(
      message => {
        if (message === true) {
          this.filtered = true;
        } else {
          this.filtered = false;
        }
      }
    );

    this.webDataService.setFilterOption(null);
    this.webDataService.setSortOption(null);
    this.webDataService.setCurrentLanguageFilter(null);
    this.webDataService.setCurrentStart(null);
    this.webDataService.setCurrentEnd(null);
    this.webDataService.setCurrentPageIndex(0);

    this.route.queryParams.subscribe(params => {
        if (params['q']) {
          this.results = {};
          this.loading = true;
          this.pep = false;
          this.sanction = false;
          this.searchQuery = params['q'];

          if (params['sort']) {
            this.webDataService.setSortOption(params['sort']);
          }

          this.encodedQuery = encodeURIComponent(params['q']);

          if (params['filters']) {
              this.filters = params['filters'].toString();
              this.filter(this.filters);
          } else {
              this.results = {};
              this.encodedQuery = encodeURIComponent(this.searchQuery);
              this.webDataService.setFilterOption(null);
              this.webDataService.setSortOption('rel');
              this.webDataService.setCurrentLanguageFilter(null);
              this.webDataService.setCurrentStart(null);
              this.webDataService.setCurrentEnd(null);
              this.webDataService.setCurrentPageIndex(null);
              this.pep = false;
              this.sanction = false;
              this.filtered = false;
              this.loading = true;
              this.noResults = false;

              this.searchService.search(this.encodedQuery, 0, 'rel', null, null, null, null).subscribe(
                data => {
                    this.sources = [];
                    this.results = data;

                    if (this.results.totalHits === 0) {
                        this.noResults = true;
                    }

                    for (let i = 0; i < this.results.sourceAggregation.sourceAgg.buckets.length; i++) {
                        for (let j = 0; j < this.sourcesMetadata.length; j++) {
                            if (this.results.sourceAggregation.sourceAgg.buckets[i].key === this.sourcesMetadata[j].source) {
                              // tslint:disable-next-line:max-line-length
                              this.sources.push({'name': this.sourcesMetadata[j].shortDisplayName, 'id': this.results.sourceAggregation.sourceAgg.buckets[i].key, 'fullName': this.sourcesMetadata[j].displayName, 'selected' : false, 'docCount': this.results.sourceAggregation.sourceAgg.buckets[i].doc_count});
                            }
                        }
                    }

                    this.getSelected();

                    if (this.results.displayPepWarning === true) {
                      this.pep = true;
                    }

                    if (this.results.displaySanctionWarning === true) {
                      this.sanction = true;
                    }

                    for (let k = 0; k < this.results.results.length; k++) {
                      if (this.results.results[k].fields['source-display-name'] !== undefined) {
                        if (this.results.results[k].type === 'Entity' && this.results.results[k].fields['source-display-name'].value === 'EU Global Sanction List') {
                          this.results.results[k] = this.parseEUSanctionDescription(this.results.results[k]);
                        // tslint:disable-next-line:max-line-length
                        } else if (this.results.results[k].type === 'Entity' && this.results.results[k].fields['source-display-name'].value === 'US Treasury Consolidated Sanctions') {
                          this.results.results[k] = this.parseUSTreasuryConsolidatedDescription(this.results.results[k]);
                        // tslint:disable-next-line:max-line-length
                        } else if (this.results.results[k].type === 'Entity' && (this.results.results[k].fields['source-display-name'].value === 'United Nations 1988 Sanction List' || this.results.results[k].fields['source-display-name'].value === 'United Nations 1267 Sanction List')) {
                          this.results.results[k] = this.parseUNDescription(this.results.results[k]);
                        // tslint:disable-next-line:max-line-length
                        } else if (this.results.results[k].type === 'Entity' && this.results.results[k].fields['source-display-name'].value === 'Interpol Wanted List') {
                          this.results.results[k] = this.parseInterpolDescription(this.results.results[k]);
                        }
                    } else {
                        if (this.results.results[k].entities[0] !== undefined) {
                          if (this.results.results[k].entities[0].id.indexOf('turkishpolice') !== -1) {
                            this.results.results[k] = this.parseTurkishPoliceDescription(this.results.results[k]);
                          }

                          if (this.results.results[k].entities[0].id.indexOf('bce') !== -1) {
                            this.results.results[k] = this.parseBelgianCompanyRegistryDescription(this.results.results[k]);
                          }

                          if (this.results.results[k].entities[0].id.indexOf('companieshouse:') !== -1) {
                            this.results.results[k] = this.parseUKCompanyRegistryDescription(this.results.results[k]);
                          }

                          if (this.results.results[k].entities[0].id.indexOf('resa:') !== -1) {
                            this.results.results[k] = this.parseResaDescription(this.results.results[k]);
                          }

                          if (this.results.results[k].entities[0].id.indexOf('companieshousepeople:') !== -1) {
                            this.results.results[k] = this.parseUKCompanyRegistryPeopleDescription(this.results.results[k]);
                          }

                          if (this.results.results[k].entities[0].id.indexOf('shab') !== -1) {
                            this.results.results[k] = this.parseSHABDescription(this.results.results[k]);
                          }

                          if (this.results.results[k].entities[0].id.indexOf('wikipeps:') !== -1) {
                            this.results.results[k] = this.parseWikipepsDescription(this.results.results[k]);
                          }
                        }
                    }
                  }

                    this.hits = this.results.totalHits;
                    this.time = this.results.time;
                    this.totalItems = this.results.totalHits;
                    this.webDataService.setSortOption('rel');
                    this.webDataService.setFilterOption('');
                    this.loading = false;
                },
                error => {}
              );
            }
          }
    });
  }

  filter(key: any) {
    const start = this.webDataService.start.getValue();
    const end = this.webDataService.end.getValue();

    this.loading = true;
    this.noResults = false;
    this.webDataService.setFilterOption(key);
    this.results = {};
    this.webDataService.sort_option.subscribe(message => this.currentSortOption = message);
    const currentLanguageFilter = this.webDataService.languageFilter.getValue();
    const filters = key.toString();

    this.searchService.search(this.encodedQuery, 0, this.currentSortOption, start, end, filters, currentLanguageFilter).subscribe(
      data => {
        this.results = data;

        if (this.results.totalHits === 0) {
            this.noResults = true;
        }

        this.totalItems = this.results.totalHits;
        this.loading = false;

        for (let k = 0; k < this.results.results.length; k++) {
          if (this.results.results[k].fields['source-display-name'] !== undefined) {
            if (this.results.results[k].type === 'Entity' && this.results.results[k].fields['source-display-name'].value === 'EU Global Sanction List') {
              this.results.results[k] = this.parseEUSanctionDescription(this.results.results[k]);
            } else if (this.results.results[k].type === 'Entity' && this.results.results[k].fields['source-display-name'].value === 'US Treasury Consolidated Sanctions') {
              this.results.results[k] = this.parseUSTreasuryConsolidatedDescription(this.results.results[k]);
            } else if (this.results.results[k].type === 'Entity' && (this.results.results[k].fields['source-display-name'].value === 'United Nations 1988 Sanction List' || this.results.results[k].fields['source-display-name'].value === 'United Nations 1267 Sanction List')) {
              this.results.results[k] = this.parseUNDescription(this.results.results[k]);
            } else if (this.results.results[k].type === 'Entity' && this.results.results[k].fields['source-display-name'].value === 'Interpol Wanted List') {
              this.results.results[k] = this.parseInterpolDescription(this.results.results[k]);
            }
          } else {
              if (this.results.results[k].entities[0] !== undefined) {
                if (this.results.results[k].entities[0].id.indexOf('turkishpolice') !== -1) {
                  this.results.results[k] = this.parseTurkishPoliceDescription(this.results.results[k]);
                }

                if (this.results.results[k].entities[0].id.indexOf('bce') !== -1) {
                  this.results.results[k] = this.parseBelgianCompanyRegistryDescription(this.results.results[k]);
                }

                if (this.results.results[k].entities[0].id.indexOf('companieshouse:') !== -1) {
                  this.results.results[k] = this.parseUKCompanyRegistryDescription(this.results.results[k]);
                }

                if (this.results.results[k].entities[0].id.indexOf('resa:') !== -1) {
                  this.results.results[k] = this.parseResaDescription(this.results.results[k]);
                }

                if (this.results.results[k].entities[0].id.indexOf('companieshousepeople:') !== -1) {
                  this.results.results[k] = this.parseUKCompanyRegistryPeopleDescription(this.results.results[k]);
                }

                if (this.results.results[k].entities[0].id.indexOf('wikipeps:') !== -1) {
                  this.results.results[k] = this.parseWikipepsDescription(this.results.results[k]);
                }
              }
            }
        }
      }
    );
  }

  getSelected() {
    this.selected_sources = this.sources.filter(s => {
      return s.selected;
    });

    this.selected_count = this.selected_sources.length;
 }

  clearSelection() {
    this.filterSearch = '';

    if (this.sources !== undefined) {
      this.sources = this.sources.filter(g => {
        g.selected = false;

        return true;
      });

      this.sourceFilter();
    }

    this.results = {};
    this.noResults = false;

    this.webDataService.setCurrentStart(null);
    this.webDataService.setCurrentEnd(null);
    this.webDataService.setFilterOption(null);

    const sortOption = this.webDataService.sortOption.getValue();
    const currentLanguageFilter = this.webDataService.languageFilter.getValue();

    this.searchService.search(this.searchQuery, 0, sortOption, null, null, null, currentLanguageFilter).subscribe(
      data => {
        this.results = data;

        if (this.results.totalHits === 0) {
          this.noResults = true;
        }

        this.totalItems = this.results.totalHits;
        this.loading = false;

        for (let k = 0; k < this.results.results.length; k++) {
            if (this.results.results[k].fields['source-display-name'] !== undefined) {
                if (this.results.results[k].type === 'Entity' && this.results.results[k].fields['source-display-name'].value === 'EU Global Sanction List') {
                    this.results.results[k] = this.parseEUSanctionDescription(this.results.results[k]);
                } else if (this.results.results[k].type === 'Entity' && this.results.results[k].fields['source-display-name'].value === 'US Treasury Consolidated Sanctions') {
                    this.results.results[k] = this.parseUSTreasuryConsolidatedDescription(this.results.results[k]);
                } else if (this.results.results[k].type === 'Entity' && (this.results.results[k].fields['source-display-name'].value === 'United Nations 1988 Sanction List' || this.results.results[k].fields['source-display-name'].value === 'United Nations 1267 Sanction List')) {
                    this.results.results[k] = this.parseUNDescription(this.results.results[k]);
                } else if (this.results.results[k].type === 'Entity' && this.results.results[k].fields['source-display-name'].value === 'Interpol Wanted List') {
                    this.results.results[k] = this.parseInterpolDescription(this.results.results[k]);
                }
            } else {
                if (this.results.results[k].entities[0] !== undefined) {
                    if (this.results.results[k].entities[0].id.indexOf('turkishpolice') !== -1) {
                        this.results.results[k] = this.parseTurkishPoliceDescription(this.results.results[k]);
                    }

                    if (this.results.results[k].entities[0].id.indexOf('bce') !== -1) {
                        this.results.results[k] = this.parseBelgianCompanyRegistryDescription(this.results.results[k]);
                    }

                    if (this.results.results[k].entities[0].id.indexOf('companieshouse:') !== -1) {
                        this.results.results[k] = this.parseUKCompanyRegistryDescription(this.results.results[k]);
                    }

                    if (this.results.results[k].entities[0].id.indexOf('resa:') !== -1) {
                        this.results.results[k] = this.parseResaDescription(this.results.results[k]);
                    }

                    if (this.results.results[k].entities[0].id.indexOf('companieshousepeople:') !== -1) {
                        this.results.results[k] = this.parseUKCompanyRegistryPeopleDescription(this.results.results[k]);
                    }

                    if (this.results.results[k].entities[0].id.indexOf('wikipeps:') !== -1) {
                        this.results.results[k] = this.parseWikipepsDescription(this.results.results[k]);
                    }
                }
            }
        }
      },
      error => {
        this.results = {};
        this.loading = false;
      }
    );
  }

  dateRangeChanged() {
    const start = this.dateRange[0].getTime();
    const end = this.dateRange[1].getTime();

    this.loading = true;

    this.webDataService.setCurrentStart(start);
    this.webDataService.setCurrentEnd(end);
    const sortOption = this.webDataService.sortOption.getValue();
    const filterOption = this.webDataService.filterOption.getValue();
    const currentLanguageFilter = this.webDataService.languageFilter.getValue();
    this.results = {};
    this.noResults = false;

    this.searchService.search(this.searchQuery, 0, sortOption, start, end, filterOption, currentLanguageFilter).subscribe(
      data => {
        this.results = data;

        if (this.results.totalHits === 0) {
            this.noResults = true;
        }

        this.totalItems = this.results.totalHits;
        this.loading = false;

        for (let k = 0; k < this.results.results.length; k++) {
            if (this.results.results[k].fields['source-display-name'] !== undefined) {
                if (this.results.results[k].type === 'Entity' && this.results.results[k].fields['source-display-name'].value === 'EU Global Sanction List') {
                    this.results.results[k] = this.parseEUSanctionDescription(this.results.results[k]);
                } else if (this.results.results[k].type === 'Entity' && this.results.results[k].fields['source-display-name'].value === 'US Treasury Consolidated Sanctions') {
                    this.results.results[k] = this.parseUSTreasuryConsolidatedDescription(this.results.results[k]);
                } else if (this.results.results[k].type === 'Entity' && (this.results.results[k].fields['source-display-name'].value === 'United Nations 1988 Sanction List' || this.results.results[k].fields['source-display-name'].value === 'United Nations 1267 Sanction List')) {
                    this.results.results[k] = this.parseUNDescription(this.results.results[k]);
                } else if (this.results.results[k].type === 'Entity' && this.results.results[k].fields['source-display-name'].value === 'Interpol Wanted List') {
                    this.results.results[k] = this.parseInterpolDescription(this.results.results[k]);
                }
            } else {
                if (this.results.results[k].entities[0] !== undefined) {
                    if (this.results.results[k].entities[0].id.indexOf('turkishpolice') !== -1) {
                        this.results.results[k] = this.parseTurkishPoliceDescription(this.results.results[k]);
                    }

                    if (this.results.results[k].entities[0].id.indexOf('bce') !== -1) {
                        this.results.results[k] = this.parseBelgianCompanyRegistryDescription(this.results.results[k]);
                    }

                    if (this.results.results[k].entities[0].id.indexOf('companieshouse:') !== -1) {
                        this.results.results[k] = this.parseUKCompanyRegistryDescription(this.results.results[k]);
                    }

                    if (this.results.results[k].entities[0].id.indexOf('resa:') !== -1) {
                        this.results.results[k] = this.parseResaDescription(this.results.results[k]);
                    }

                    if (this.results.results[k].entities[0].id.indexOf('companieshousepeople:') !== -1) {
                        this.results.results[k] = this.parseUKCompanyRegistryPeopleDescription(this.results.results[k]);
                    }

                    if (this.results.results[k].entities[0].id.indexOf('wikipeps:') !== -1) {
                        this.results.results[k] = this.parseWikipepsDescription(this.results.results[k]);
                    }
                }
            }
        }
      },
      error => {
        this.results = {};
        this.loading = false;
      }
    );
  }

  openDetail(index: any) {
    const initialState = {
      modalTitle: 'Full Text',
      text: this.results.results[index].fields.text.value
    };

    this.modalRef = this.modalService.show(EntityDetailComponent, { initialState, class: 'modal-lg' });
    this.modalRef.content.closeBtnName = 'Close';
  }

  pageChanged(event: any): void {
    this.loading = true;
    this.webDataService.sort_option.subscribe(message => this.currentSortOption = message);
    this.webDataService.filter_option.subscribe(message => this.currentFilterOption = message);
    const start = this.webDataService.start.getValue();
    const end = this.webDataService.end.getValue();
    const currentLanguageFilter = this.webDataService.languageFilter.getValue();
    this.page = event.page;
    this.webDataService.setCurrentPageIndex(this.page - 1);
    this.results = {};
    this.noResults = false;

    // tslint:disable-next-line:max-line-length
    this.searchService.search(this.encodedQuery, this.page - 1, this.currentSortOption, start, end, this.currentFilterOption, currentLanguageFilter).subscribe(
      data => {
        this.results = data;

        if (this.results.totalHits === 0) {
            this.noResults = true;
        }

        this.loading = false;

        for (let k = 0; k < this.results.results.length; k++) {
          if (this.results.results[k].fields['source-display-name'] !== undefined) {
            if (this.results.results[k].type === 'Entity' && this.results.results[k].fields['source-display-name'].value === 'EU Global Sanction List') {
              this.results.results[k] = this.parseEUSanctionDescription(this.results.results[k]);
            } else if (this.results.results[k].type === 'Entity' && this.results.results[k].fields['source-display-name'].value === 'US Treasury Consolidated Sanctions') {
              this.results.results[k] = this.parseUSTreasuryConsolidatedDescription(this.results.results[k]);
            } else if (this.results.results[k].type === 'Entity' && (this.results.results[k].fields['source-display-name'].value === 'United Nations 1988 Sanction List' || this.results.results[k].fields['source-display-name'].value === 'United Nations 1267 Sanction List')) {
              this.results.results[k] = this.parseUNDescription(this.results.results[k]);
            } else if (this.results.results[k].type === 'Entity' && this.results.results[k].fields['source-display-name'].value === 'Interpol Wanted List') {
              this.results.results[k] = this.parseInterpolDescription(this.results.results[k]);
            }
          } else {
              if (this.results.results[k].entities[0] !== undefined) {
                if (this.results.results[k].entities[0].id.indexOf('turkishpolice') !== -1) {
                  this.results.results[k] = this.parseTurkishPoliceDescription(this.results.results[k]);
                }

                if (this.results.results[k].entities[0].id.indexOf('bce') !== -1) {
                  this.results.results[k] = this.parseBelgianCompanyRegistryDescription(this.results.results[k]);
                }

                if (this.results.results[k].entities[0].id.indexOf('companieshouse:') !== -1) {
                  this.results.results[k] = this.parseUKCompanyRegistryDescription(this.results.results[k]);
                }

                if (this.results.results[k].entities[0].id.indexOf('resa:') !== -1) {
                  this.results.results[k] = this.parseResaDescription(this.results.results[k]);
                }

                if (this.results.results[k].entities[0].id.indexOf('companieshousepeople:') !== -1) {
                  this.results.results[k] = this.parseUKCompanyRegistryPeopleDescription(this.results.results[k]);
                }

                if (this.results.results[k].entities[0].id.indexOf('wikipeps:') !== -1) {
                  this.results.results[k] = this.parseWikipepsDescription(this.results.results[k]);
                }
              }
            }
        }
      },
      error => {}
    );
  }

  resetFilter() {
    this.results = {};
    this.webDataService.setCurrentStart(null);
    this.webDataService.setCurrentEnd(null);
    const sortOption = this.webDataService.sortOption.getValue();
    const filterOption = this.webDataService.filterOption.getValue();
    const currentLanguageFilter = this.webDataService.languageFilter.getValue();
    this.dateRange = null;
    this.noResults = false;

    this.searchService.search(this.searchQuery, 0, sortOption, null, null, filterOption, currentLanguageFilter).subscribe(
      data => {
        this.results = data;

        if (this.results.totalHits === 0) {
            this.noResults = true;
        }

        this.totalItems = this.results.totalHits;
        this.loading = false;

        for (let k = 0; k < this.results.results.length; k++) {
            if (this.results.results[k].fields['source-display-name'] !== undefined) {
                if (this.results.results[k].type === 'Entity' && this.results.results[k].fields['source-display-name'].value === 'EU Global Sanction List') {
                    this.results.results[k] = this.parseEUSanctionDescription(this.results.results[k]);
                } else if (this.results.results[k].type === 'Entity' && this.results.results[k].fields['source-display-name'].value === 'US Treasury Consolidated Sanctions') {
                    this.results.results[k] = this.parseUSTreasuryConsolidatedDescription(this.results.results[k]);
                } else if (this.results.results[k].type === 'Entity' && (this.results.results[k].fields['source-display-name'].value === 'United Nations 1988 Sanction List' || this.results.results[k].fields['source-display-name'].value === 'United Nations 1267 Sanction List')) {
                    this.results.results[k] = this.parseUNDescription(this.results.results[k]);
                } else if (this.results.results[k].type === 'Entity' && this.results.results[k].fields['source-display-name'].value === 'Interpol Wanted List') {
                    this.results.results[k] = this.parseInterpolDescription(this.results.results[k]);
                }
            } else {
                if (this.results.results[k].entities[0] !== undefined) {
                    if (this.results.results[k].entities[0].id.indexOf('turkishpolice') !== -1) {
                        this.results.results[k] = this.parseTurkishPoliceDescription(this.results.results[k]);
                    }

                    if (this.results.results[k].entities[0].id.indexOf('bce') !== -1) {
                        this.results.results[k] = this.parseBelgianCompanyRegistryDescription(this.results.results[k]);
                    }

                    if (this.results.results[k].entities[0].id.indexOf('companieshouse:') !== -1) {
                        this.results.results[k] = this.parseUKCompanyRegistryDescription(this.results.results[k]);
                    }

                    if (this.results.results[k].entities[0].id.indexOf('resa:') !== -1) {
                        this.results.results[k] = this.parseResaDescription(this.results.results[k]);
                    }

                    if (this.results.results[k].entities[0].id.indexOf('companieshousepeople:') !== -1) {
                        this.results.results[k] = this.parseUKCompanyRegistryPeopleDescription(this.results.results[k]);
                    }

                    if (this.results.results[k].entities[0].id.indexOf('wikipeps:') !== -1) {
                        this.results.results[k] = this.parseWikipepsDescription(this.results.results[k]);
                    }
                }
            }
        }
      },
      error => {
        this.results = {};
        this.loading = false;
      }
    );
  }

  sort(sortOption: any) {
    if (sortOption === 'rel') {
      const sortRel = document.getElementsByClassName('rel') as HTMLCollectionOf<HTMLLinkElement>;
      const sortAsc = document.getElementsByClassName('asc') as HTMLCollectionOf<HTMLLinkElement>;
      const sortDesc = document.getElementsByClassName('desc') as HTMLCollectionOf<HTMLLinkElement>;
      sortRel[0].setAttribute('style', 'color: #0377B8; text-decoration: underline');
      sortAsc[0].setAttribute('style', 'color: #474646; text-decoration: none');
      sortDesc[0].setAttribute('style', 'color: #474646; text-decoration: none');
    }

    if (sortOption === 'asc') {
      const sortRel = document.getElementsByClassName('rel') as HTMLCollectionOf<HTMLLinkElement>;
      const sortAsc = document.getElementsByClassName('asc') as HTMLCollectionOf<HTMLLinkElement>;
      const sortDesc = document.getElementsByClassName('desc') as HTMLCollectionOf<HTMLLinkElement>;
      sortRel[0].setAttribute('style', 'color: #474646; text-decoration: none');
      sortAsc[0].setAttribute('style', 'color: #0377B8; text-decoration: underline');
      sortDesc[0].setAttribute('style', 'color: #474646; text-decoration: none');
    }

    if (sortOption === 'desc') {
      const sortRel = document.getElementsByClassName('rel') as HTMLCollectionOf<HTMLLinkElement>;
      const sortAsc = document.getElementsByClassName('asc') as HTMLCollectionOf<HTMLLinkElement>;
      const sortDesc = document.getElementsByClassName('desc') as HTMLCollectionOf<HTMLLinkElement>;
      sortRel[0].setAttribute('style', 'color: #474646; text-decoration: none');
      sortAsc[0].setAttribute('style', 'color: #474646; text-decoration: none');
      sortDesc[0].setAttribute('style', 'color: #0377B8; text-decoration: underline');
    }

    this.loading = true;
    this.noResults = false;
    this.webDataService.filter_option.subscribe(message => this.currentFilterOption = message);
    const start = this.webDataService.start.getValue();
    const end = this.webDataService.end.getValue();
    this.currentPage = 1;
    this.results = {};
    this.webDataService.setSortOption(sortOption);
    const currentLanguageFilter = this.webDataService.languageFilter.getValue();

    this.searchService.search(this.encodedQuery, 0, sortOption, start, end, this.currentFilterOption, currentLanguageFilter).subscribe(
        data => {
            this.results = data;

            if (this.results.totalHits === 0) {
                this.noResults = true;
            }

            this.loading = false;

            for (let k = 0; k < this.results.results.length; k++) {
                if (this.results.results[k].fields['source-display-name'] !== undefined) {
                    if (this.results.results[k].type === 'Entity' && this.results.results[k].fields['source-display-name'].value === 'EU Global Sanction List') {
                        this.results.results[k] = this.parseEUSanctionDescription(this.results.results[k]);
                    } else if (this.results.results[k].type === 'Entity' && this.results.results[k].fields['source-display-name'].value === 'US Treasury Consolidated Sanctions') {
                        this.results.results[k] = this.parseUSTreasuryConsolidatedDescription(this.results.results[k]);
                    } else if (this.results.results[k].type === 'Entity' && (this.results.results[k].fields['source-display-name'].value === 'United Nations 1988 Sanction List' || this.results.results[k].fields['source-display-name'].value === 'United Nations 1267 Sanction List')) {
                        this.results.results[k] = this.parseUNDescription(this.results.results[k]);
                    } else if (this.results.results[k].type === 'Entity' && this.results.results[k].fields['source-display-name'].value === 'Interpol Wanted List') {
                        this.results.results[k] = this.parseInterpolDescription(this.results.results[k]);
                    }
                } else {
                    if (this.results.results[k].entities[0] !== undefined) {
                        if (this.results.results[k].entities[0].id.indexOf('turkishpolice') !== -1) {
                            this.results.results[k] = this.parseTurkishPoliceDescription(this.results.results[k]);
                        }

                        if (this.results.results[k].entities[0].id.indexOf('bce') !== -1) {
                            this.results.results[k] = this.parseBelgianCompanyRegistryDescription(this.results.results[k]);
                        }

                        if (this.results.results[k].entities[0].id.indexOf('companieshouse:') !== -1) {
                            this.results.results[k] = this.parseUKCompanyRegistryDescription(this.results.results[k]);
                        }

                        if (this.results.results[k].entities[0].id.indexOf('resa:') !== -1) {
                            this.results.results[k] = this.parseResaDescription(this.results.results[k]);
                        }

                        if (this.results.results[k].entities[0].id.indexOf('companieshousepeople:') !== -1) {
                            this.results.results[k] = this.parseUKCompanyRegistryPeopleDescription(this.results.results[k]);
                        }

                        if (this.results.results[k].entities[0].id.indexOf('wikipeps:') !== -1) {
                            this.results.results[k] = this.parseWikipepsDescription(this.results.results[k]);
                        }
                    }
                }
            }
        }
    );
  }

  sourceFilter() {
    this.loading = true;
    this.results = {};

    this.getSelected();

    this.currentSortOption = this.webDataService.sortOption.getValue();
    const currentLanguageFilter = this.webDataService.languageFilter.getValue();

    if (this.selected_sources.length !== 0) {
      let filter = this.selected_sources[0].id;

      for (let i = 1; i < this.selected_sources.length; i++) {
        filter += ',' + this.selected_sources[i].id;
      }

      const start = this.webDataService.start.getValue();
      const end = this.webDataService.end.getValue();
      this.webDataService.setFilterOption('source%3A' + filter);
      this.results = {};
      this.noResults = false;

      // tslint:disable-next-line:max-line-length
      this.searchService.search(this.encodedQuery, 0, this.currentSortOption, start, end, 'source%3A' + filter, currentLanguageFilter).subscribe(
        data => {
          this.results = data;

          if (this.results.totalHits === 0) {
            this.noResults = true;
          }

          this.totalItems = this.results.totalHits;
          this.loading = false;

          for (let k = 0; k < this.results.results.length; k++) {
            if (this.results.results[k].fields['source-display-name'] !== undefined) {
                if (this.results.results[k].type === 'Entity' && this.results.results[k].fields['source-display-name'].value === 'EU Global Sanction List') {
                    this.results.results[k] = this.parseEUSanctionDescription(this.results.results[k]);
                } else if (this.results.results[k].type === 'Entity' && this.results.results[k].fields['source-display-name'].value === 'US Treasury Consolidated Sanctions') {
                    this.results.results[k] = this.parseUSTreasuryConsolidatedDescription(this.results.results[k]);
                } else if (this.results.results[k].type === 'Entity' && (this.results.results[k].fields['source-display-name'].value === 'United Nations 1988 Sanction List' || this.results.results[k].fields['source-display-name'].value === 'United Nations 1267 Sanction List')) {
                    this.results.results[k] = this.parseUNDescription(this.results.results[k]);
                } else if (this.results.results[k].type === 'Entity' && this.results.results[k].fields['source-display-name'].value === 'Interpol Wanted List') {
                    this.results.results[k] = this.parseInterpolDescription(this.results.results[k]);
                }
            } else {
                if (this.results.results[k].entities[0] !== undefined) {
                    if (this.results.results[k].entities[0].id.indexOf('turkishpolice') !== -1) {
                        this.results.results[k] = this.parseTurkishPoliceDescription(this.results.results[k]);
                    }

                    if (this.results.results[k].entities[0].id.indexOf('bce') !== -1) {
                        this.results.results[k] = this.parseBelgianCompanyRegistryDescription(this.results.results[k]);
                    }

                    if (this.results.results[k].entities[0].id.indexOf('companieshouse:') !== -1) {
                        this.results.results[k] = this.parseUKCompanyRegistryDescription(this.results.results[k]);
                    }

                    if (this.results.results[k].entities[0].id.indexOf('resa:') !== -1) {
                        this.results.results[k] = this.parseResaDescription(this.results.results[k]);
                    }

                    if (this.results.results[k].entities[0].id.indexOf('companieshousepeople:') !== -1) {
                        this.results.results[k] = this.parseUKCompanyRegistryPeopleDescription(this.results.results[k]);
                    }

                    if (this.results.results[k].entities[0].id.indexOf('wikipeps:') !== -1) {
                        this.results.results[k] = this.parseWikipepsDescription(this.results.results[k]);
                    }
                }
            }
          }
        }
      );
    }
  }

  parseTurkishPoliceDescription(result: any) {
    result.entities[0].fields['first-seen'] = null;
    result.entities[0].fields['last-seen'] = null;
    result.entities[0].fields['id-number'] = '';
    result.entities[0].fields['remark'] = null;
    result.entities[0].fields['id-number'] = result.entities[0].fields.description.split('ID Number: ')[1].split('Mother name: ')[0];
    result.entities[0].fields['more-information'] = result.entities[0].fields.description.split(result.entities[0].fields['id-number'])[1];

    return result;
  }

  parseSHABDescription(result: any) {
    result.entities[0].fields['first-seen'] = null;
    result.entities[0].fields['last-seen'] = null;
  }

  parseBelgianCompanyRegistryDescription(result: any) {
    result.entities[0].fields['first-seen'] = null;
    result.entities[0].fields['last-seen'] = null;

    return result;
  }

  parseWikipepsDescription(result: any) {
    result.entities[0].fields['source-url'] = result.entities[0].fields['description'].split('Source url: ')[1];

    const cleanDescription = result.entities[0].fields['description'].split('Status: ')[0];
    const restOfDescription = result.entities[0].fields['description'].split('Status: ')[1];

    result.entities[0].fields['description'] = cleanDescription;
    result.entities[0].fields['status'] = restOfDescription.split('Date of death: ')[0];
    result.entities[0].fields['date-of-death'] = restOfDescription.split('Date of death: ')[1].split('Type of PEP: ')[0];
    result.entities[0].fields['type'] = restOfDescription.split('Type of PEP: ')[1].split('Nationality: ')[0];
    result.entities[0].fields['nationality'] = restOfDescription.split('Nationality: ')[1].split('Source url: ')[0];

    return result;
  }

  parseUKCompanyRegistryPeopleDescription(result: any) {
    result.entities[0].fields['first-seen'] = null;
    result.entities[0].fields['last-seen'] = null;
    result.entities[0].fields['occupation'] = '';
    result.entities[0].fields['country-of-residence'] = '';
    result.entities[0].fields['comment'] = result.entities[0].fields['description'];

    return result;
  }

  parseUKCompanyRegistryDescription(result: any) {
    result.entities[0].fields['first-seen'] = null;
    result.entities[0].fields['last-seen'] = null;
    result.entities[0].fields['category'] = '';
    result.entities[0].fields['status'] = '';
    result.entities[0].fields['country-of-origin'] = '';
    result.entities[0].fields['dissolution-date'] = '';
    result.entities[0].fields['incorporation-date'] = '';
    result.entities[0].fields['accounts-category'] = '';
    result.entities[0].fields['outstanding-mortgages'] = '';
    result.entities[0].fields['general-partnerships'] = '';
    result.entities[0].fields['limited-partnerships'] = '';

    if (result.entities[0].fields['description'].split('Status: ')[0] !== undefined) {
      result.entities[0].fields['category'] = result.entities[0].fields['description'].split('Status: ')[0].split('Category: ')[1];
    }

    if (result.entities[0].fields['description'].split('Status: ')[1] !== undefined) {
      result.entities[0].fields['status'] = result.entities[0].fields['description'].split('Status: ')[1].split('Country of Origin: ')[0];
    }

    if (result.entities[0].fields['description'].split('Country of Origin: ')[1] !== undefined) {
      result.entities[0].fields['country-of-origin'] = result.entities[0].fields['description'].split('Country of Origin: ')[1].split('Dissolution Date: ')[0];
    }

    if (result.entities[0].fields['description'].split('Dissolution Date: ')[1] !== undefined) {
      result.entities[0].fields['dissolution-date'] = result.entities[0].fields['description'].split('Dissolution Date: ')[1].split('Incorporation Date: ')[0];
    }

    if (result.entities[0].fields['description'].split('Incorporation Date: ')[1] !== undefined) {
      result.entities[0].fields['incorporation-date'] = result.entities[0].fields['description'].split('Incorporation Date: ')[1].split('Accounts Category: ')[0];
    }

    if (result.entities[0].fields['description'].split('Accounts Category: ')[1] !== undefined) {
      result.entities[0].fields['accounts-category'] = result.entities[0].fields['description'].split('Accounts Category: ')[1].split('Outstanding Mortgages: ')[0];
    }

    if (result.entities[0].fields['description'].split('Outstanding Mortgages: ')[1] !== undefined) {
      // tslint:disable-next-line:max-line-length
      result.entities[0].fields['outstanding-mortgages'] = result.entities[0].fields['description'].split('Outstanding Mortgages: ')[1].split('Number of General Partnerships: ')[0];
    }

    if (result.entities[0].fields['description'].split('Number of General Partnerships: ')[1] !== undefined) {
      // tslint:disable-next-line:max-line-length
      result.entities[0].fields['general-partnerships'] = result.entities[0].fields['description'].split('Number of General Partnerships: ')[1].split('Number of Limited Partnerships: ')[0];
    }

    if (result.entities[0].fields['description'].split('Number of Limited Partnerships: ')[1] !== undefined) {
      // tslint:disable-next-line:max-line-length
      result.entities[0].fields['limited-partnerships'] = result.entities[0].fields['description'].split('Number of Limited Partnerships: ')[1];
    }

    result.entities[0].fields['description'] = null;

    return result;
  }

  parseEUSanctionDescription(result: any) {
    result.entities[0].fields['remark'] = '';
    result.entities[0].fields['citizenship'] = '';
    result.entities[0].fields['identity-documents'] = '';
    result.entities[0].fields['publication-date'] = '';
    result.entities[0].fields['entry-into-force-date'] = '';
    if (result.entities[0].fields.description.includes("Remark: ")) {
      result.entities[0].fields['remark'] = result.entities[0].fields.description.split('Remark: ')[1].split('Citizenship: ')[0];
    }
    if (result.entities[0].fields.description.includes("Citizenship: ")) {
      // tslint:disable-next-line:max-line-length
      result.entities[0].fields['citizenship'] = result.entities[0].fields.description.split('Citizenship: ')[1].split('Identity documents: ')[0].split(' ')[0];
    }
    if (result.entities[0].fields.description.includes("Identity documents: ")) {
      result.entities[0].fields['identity-documents'] = result.entities[0].fields.description.split('Identity documents: ')[1].split('Publication date: ')[0];
    }
    if (result.entities[0].fields.description.includes("Publication date: ")) {
      result.entities[0].fields['publication-date'] = result.entities[0].fields.description.split('Publication date: ')[1].split('Entry into force date: ')[0].split(' ')[0];
    }
    if (result.entities[0].fields.description.includes("Entry into force date: ")) {
      result.entities[0].fields['entry-into-force-date'] = result.entities[0].fields.description.split('Entry into force date: ')[1];
    }
    const parser = new DOMParser();
    const xmlDoc = parser.parseFromString(result.entities[0].fields['identity-documents'], 'text/html');
    const documents = xmlDoc.getElementsByTagName('identification') as HTMLCollectionOf<Element>;
    const documentArray = [];

    for (let i = 0; i < documents.length; i++) {
      const diplomatic = documents[i].getAttribute('diplomatic');
      const knownFalse = documents[i].getAttribute('knownFalse');
      const reportedLost = documents[i].getAttribute('reportedLost');
      const revokedByIssuer = documents[i].getAttribute('revokedByIssuer');
      const issuedBy = documents[i].getAttribute('issuedBy');
      const nameOnDocument = documents[i].getAttribute('nameOnDocument');
      const number = documents[i].getAttribute('number');
      const country = documents[i].getAttribute('countryDescription');
      const type = documents[i].getAttribute('identificationTypeDescription');
      const remarks = documents[i].getElementsByTagName('remark') as HTMLCollectionOf<Element>;
      let remark = '';

      if (remarks.length > 0) {
        remark = remarks[0].innerHTML;
      }

      documentArray.push({'Type': type, 'Number': number, 'Name on document': nameOnDocument, 'Country': country, 'Issued by': issuedBy, 'Is diplomatic': diplomatic, 'Is known false': knownFalse, 'Is reported lost': reportedLost, 'Revoked by issuer': revokedByIssuer, 'Remark': remark});
    }

    result.entities[0].fields['identity-documents'] = documentArray;

    return result;
  }

  parseUSTreasuryConsolidatedDescription(result: any) {
    result.entities[0].fields['identity-documents-details'] = '';
    result.entities[0].fields['more-information'] = '';
    result.entities[0].fields['sanction-programs'] = '';
    // tslint:disable-next-line:max-line-length
    result.entities[0].fields['more-information'] = result.entities[0].fields.description.split('Remark: ')[1].split('ID Document Details: ')[0];

    if (result.entities[0].entityType === 'Organization:Entity') {
      // tslint:disable-next-line:max-line-length
      result.entities[0].fields['identity-documents-details'] = result.entities[0].fields.description.split('ID Document Details: ')[1].split('Program:')[0];
    } else {
      result.entities[0].fields['citizenship'] = '';
      // tslint:disable-next-line:max-line-length
      result.entities[0].fields['identity-documents-details'] = result.entities[0].fields.description.split('ID Document Details: ')[1].split('Citizenships:')[0];
      result.entities[0].fields['citizenship'] = result.entities[0].fields.description.split('Citizenships: ')[1].split('Program:')[0];
    }

    result.entities[0].fields['sanction-programs'] = result.entities[0].fields.description.split('Program:')[1].split(' ')[1];

    return result;
  }

  parseUNDescription(result: any) {
    result.entities[0].fields['un-list-type'] = '';
    result.entities[0].fields['listed-on'] = '';
    result.entities[0].fields['comment'] = '';
    result.entities[0].fields['title'] = '';
    result.entities[0].fields['identity-documents-details'] = '';

    result.entities[0].fields['un-list-type'] = result.entities[0].fields.description.split('UN List Type: ')[1].split('Listed on: ')[0];
    result.entities[0].fields['listed-on'] = result.entities[0].fields.description.split('Listed on: ')[1].split('Comment: ')[0];

    if (result.entities[0].entityType === 'Organization:Entity' || result.entities[0].entityType === 'OrganizationEntity') {
      result.entities[0].fields['comment'] = result.entities[0].fields.description.split('Comment: ')[1];
    } else {
      result.entities[0].fields['comment'] = result.entities[0].fields.description.split('Comment: ')[1].split('Title: ')[0];
      result.entities[0].fields['title'] = result.entities[0].fields.description.split('Title: ')[1].split('ID Documents:')[0];
      result.entities[0].fields['identity-documents-details'] = result.entities[0].fields.description.split('ID Documents:')[1];
    }

    result.entities[0].fields['description'] = '';

    return result;
  }

  parseResaDescription(result: any) {
    // tslint:disable-next-line:max-line-length
    const description = `Source URL: <a href='' + result.entities[0].fields['description'].split('URL: ')[1] + '' target='_blank'>` + result.entities[0].fields['description'].split('URL: ')[1] + '</a>';

    if (result.entities[0].fields['description'] === 'Source URL:') {
      result.entities[0].fields['description'] = '';
    } else {
      result.entities[0].fields['description'] = '';
      result.entities[0].fields['resa-description'] = description;
    }

    return result;
  }

  parseInterpolDescription(result: any) {
    result.entities[0].fields['sanctioned'] = true;
    result.entities[0].fields['spoken-languages'] = '';
    result.entities[0].fields['charges'] = '';
    result.entities[0].fields['place-of-birth'] = '';
    result.entities[0].fields['country-of-birth'] = '';
    result.entities[0].fields['distinguishing-marks'] = '';
    // tslint:disable-next-line:max-line-length
    result.entities[0].fields['spoken-languages'] = result.entities[0].fields.description.split('Spoken languages: ')[1].split('Charges: ')[0];
    result.entities[0].fields['charges'] = result.entities[0].fields.description.split('Charges: ')[1].split('Place of Birth: ')[0];
    result.entities[0].fields['place-of-birth'] = result.entities[0].fields.description.split('Place of Birth: ')[1].split('Country of birth: ')[0];
    result.entities[0].fields['country-of-birth'] = result.entities[0].fields.description.split('Country of birth: ')[1].split('Distinguishing marks: ')[0];
    result.entities[0].fields['distinguishing-marks'] = result.entities[0].fields.description.split('Distinguishing marks: ')[1].replace('null', '');

    return result;
  }

  search() {
    this.router.navigate(['/search'], { queryParams: { q: this.searchQuery } });
  }

  showAlert(credits: any) {
    const initialState = {
      credits: credits,
      class: 'modal-custom'
    };

    this.bsModalRef = this.modalService.show(CreditAlertComponent, { initialState });
    this.bsModalRef.content.closeBtnName = 'Close';
  }

  showAll() {
    this.webDataService.setPepSanctionFilter(false);
    this.filtered = false;
    this.currentSortOption = this.webDataService.sortOption.getValue();
    this.currentFilterOption = this.webDataService.filterOption.getValue();
    const start = this.webDataService.start.getValue();
    const end = this.webDataService.end.getValue();
    const currentLanguageFilter = this.webDataService.languageFilter.getValue();

    this.searchService.search(this.encodedQuery, 0, this.currentSortOption, start, end, null, currentLanguageFilter).subscribe(
      data => {
        this.results = data;
        this.totalItems = this.results.totalHits;
        this.loading = false;
      },
      error => {
      }
    );
  }

  showPEP() {
    this.loading = true;
    this.webDataService.setFilterOption('pep');
    this.webDataService.setPepSanctionFilter(true);
    this.noResults = false;

    this.searchService.search(this.encodedQuery, 0, 'rel', 0, 0, 'pep', null).subscribe(
      data => {
        this.results = data;

        if (this.results.totalHits === 0) {
            this.noResults = true;
        }

        this.totalItems = this.results.totalHits;
        this.loading = false;

        for (let k = 0; k < this.results.results.length; k++) {
          if (this.results.results[k].fields['source-display-name'] !== undefined) {
            if (this.results.results[k].type === 'Entity' && this.results.results[k].fields['source-display-name'].value === 'EU Global Sanction List') {
              this.results.results[k] = this.parseEUSanctionDescription(this.results.results[k]);
            } else if (this.results.results[k].type === 'Entity' && this.results.results[k].fields['source-display-name'].value === 'US Treasury Consolidated Sanctions') {
              this.results.results[k] = this.parseUSTreasuryConsolidatedDescription(this.results.results[k]);
            } else if (this.results.results[k].type === 'Entity' && (this.results.results[k].fields['source-display-name'].value === 'United Nations 1988 Sanction List' || this.results.results[k].fields['source-display-name'].value === 'United Nations 1267 Sanction List')) {
              this.results.results[k] = this.parseUNDescription(this.results.results[k]);
            } else if (this.results.results[k].type === 'Entity' && this.results.results[k].fields['source-display-name'].value === 'Interpol Wanted List') {
              this.results.results[k] = this.parseInterpolDescription(this.results.results[k]);
            }
          } else {
              if (this.results.results[k].entities[0] !== undefined) {
                if (this.results.results[k].entities[0].id.indexOf('turkishpolice') !== -1) {
                  this.results.results[k] = this.parseTurkishPoliceDescription(this.results.results[k]);
                }

                if (this.results.results[k].entities[0].id.indexOf('bce') !== -1) {
                  this.results.results[k] = this.parseBelgianCompanyRegistryDescription(this.results.results[k]);
                }

                if (this.results.results[k].entities[0].id.indexOf('companieshouse:') !== -1) {
                  this.results.results[k] = this.parseUKCompanyRegistryDescription(this.results.results[k]);
                }

                if (this.results.results[k].entities[0].id.indexOf('resa:') !== -1) {
                  this.results.results[k] = this.parseResaDescription(this.results.results[k]);
                }

                if (this.results.results[k].entities[0].id.indexOf('companieshousepeople:') !== -1) {
                  this.results.results[k] = this.parseUKCompanyRegistryPeopleDescription(this.results.results[k]);
                }

                if (this.results.results[k].entities[0].id.indexOf('wikipeps:') !== -1) {
                  this.results.results[k] = this.parseWikipepsDescription(this.results.results[k]);
                }
            }
          }
        }
      }
    );
  }

  showSanction() {
    this.loading = true;
    this.webDataService.setFilterOption('sanction');
    this.webDataService.setPepSanctionFilter(true);
    this.noResults = false;

    this.searchService.search(this.encodedQuery, 0, 'rel', 0, 0, 'sanction', null).subscribe(
      data => {
        this.results = data;

        if (this.results.totalHits === 0) {
            this.noResults = true;
        }

        this.totalItems = this.results.totalHits;
        this.loading = false;

        for (let k = 0; k < this.results.results.length; k++) {
          if (this.results.results[k].fields['source-display-name'] !== undefined) {
            if (this.results.results[k].type === 'Entity' && this.results.results[k].fields['source-display-name'].value === 'EU Global Sanction List') {
              this.results.results[k] = this.parseEUSanctionDescription(this.results.results[k]);
            } else if (this.results.results[k].type === 'Entity' && this.results.results[k].fields['source-display-name'].value === 'US Treasury Consolidated Sanctions') {
              this.results.results[k] = this.parseUSTreasuryConsolidatedDescription(this.results.results[k]);
            } else if (this.results.results[k].type === 'Entity' && (this.results.results[k].fields['source-display-name'].value === 'United Nations 1988 Sanction List' || this.results.results[k].fields['source-display-name'].value === 'United Nations 1267 Sanction List')) {
              this.results.results[k] = this.parseUNDescription(this.results.results[k]);
            } else if (this.results.results[k].type === 'Entity' && this.results.results[k].fields['source-display-name'].value === 'Interpol Wanted List') {
              this.results.results[k] = this.parseInterpolDescription(this.results.results[k]);
            }
          } else {
              if (this.results.results[k].entities[0] !== undefined) {
                if (this.results.results[k].entities[0].id.indexOf('turkishpolice') !== -1) {
                  this.results.results[k] = this.parseTurkishPoliceDescription(this.results.results[k]);
                }

                if (this.results.results[k].entities[0].id.indexOf('bce') !== -1) {
                  this.results.results[k] = this.parseBelgianCompanyRegistryDescription(this.results.results[k]);
                }

                if (this.results.results[k].entities[0].id.indexOf('companieshouse:') !== -1) {
                  this.results.results[k] = this.parseUKCompanyRegistryDescription(this.results.results[k]);
                }

                if (this.results.results[k].entities[0].id.indexOf('resa:') !== -1) {
                  this.results.results[k] = this.parseResaDescription(this.results.results[k]);
                }

                if (this.results.results[k].entities[0].id.indexOf('companieshousepeople:') !== -1) {
                  this.results.results[k] = this.parseUKCompanyRegistryPeopleDescription(this.results.results[k]);
                }

                if (this.results.results[k].entities[0].id.indexOf('wikipeps:') !== -1) {
                  this.results.results[k] = this.parseWikipepsDescription(this.results.results[k]);
                }
              }
            }
          }
        }
    );
  }
}
