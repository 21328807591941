import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'telesignAssessment'})
export class TelesignAssessmentPipe implements PipeTransform {
    transform(value: any): string {
        const json = JSON.parse(value);

        return this.prettify(json, '');
    }

    private prettify(obj: any, indent: string = ''): string {
        if (typeof obj !== 'object' || obj === null) {
            return this.formatValue(obj);
        }

        let result = '';
        const keys = Object.keys(obj);

        keys.forEach((key, index) => {
            const value = obj[key];
            const formattedKey = this.formatKey(key, indent);

            if (typeof value === 'object' && value !== null) {
                if (result) {
                    result += '<br>';
                }

                result += formattedKey + this.prettify(value, `${indent}  `);
            } else {
                result += formattedKey + this.formatValue(value);
            }

            if (index < keys.length - 1) {
                result += '<br>';
            }
        });

        return result;
    }

    private formatKey(key: string, indent: string): string {
        return `${indent}<b>${key}:</b> `;
    }

    private formatValue(value: any): string {
        return value === null ? 'null' : value.toString();
    }
}
