<div *ngIf="!isOnMobile  && isReady">
        <form #personalForm="ngForm" class="editForm" novalidate>
                <div class="tab-pane fade in active">
                        <h4 class="head identity-doc-heading">{{ "IDENTITY_DOC_PHOTO" | translate }}</h4>
                        <br />
                        <div class='row' style="margin: 0px;">
                                <div class='col-xs-offset-1 col-xs-10 col-sm-offset-2 col-sm-8 identity-doc-section-1' style="margin-left: 0px; width: 100%;">
                                        <div class="identity-doc-section-1-left">
                                                <div class="row" style="font-size: 16px;"> <a *ngIf="!customOnboarding" tooltip="{{ 'WHY_TOOLTIP' | translate }}">{{"WHY" |translate}}</a>
                                                        <div style="height: 15px;">
                                                        </div>
                                                        <div class="radio">
                                                                <label style="font-size: 15px;">
                                                                        <input type="radio" #documentTypee="ngModel" required
                                                                                name="documentType" [(ngModel)]="documentType"
                                                                                value="passport"
                                                                                (change)="processType('passport')"> {{
                                                                        "PASSPORT" | translate }} </label>
                                                        </div>
                                                        <div class="radio">
                                                                <label style="font-size: 15px;">
                                                                        <input type="radio" #documentTypee="ngModel" required
                                                                                name="documentType" [(ngModel)]="documentType"
                                                                                value="id" (change)="processType('id')"> {{
                                                                        "ID_CARD" | translate }} </label>
                                                        </div>
                                                        <div class="radio">
                                                                <label style="font-size: 15px;">
                                                                        <input type="radio" #documentTypee="ngModel" required
                                                                                name="documentType" [(ngModel)]="documentType"
                                                                                value="driversLicense"
                                                                                (change)="processType('driversLicense')"> {{
                                                                        "DRIVERS_LICENSE" | translate }} </label>
                                                        </div>
                                                </div>
                                                <ol class="doc-ol">
                                                        <li>{{ "DOC_INSTRUCTION_1" | translate }}</li>
                                                        <li>{{ "DOC_INSTRUCTION_2" | translate }}</li>
                                                        <li>{{ "DOC_INSTRUCTION_3" | translate }}</li>
                                                </ol>
                                                <div *ngIf="documentType == 'passport'" class="text-center passport-image-desktop" style="padding: 0px; margin-left: -42px;">
                                                        <img src="../../../../assets/images/instructions.png" width="90%" height="auto" />
                                                </div>
                                                <div *ngIf="documentType == 'id'" class="text-center passport-image-desktop" style="padding: 0px; margin-left: -42px; width: 533px; height: 174px;">
                                                </div>
                                                <div *ngIf="documentType == 'driversLicense'" class="text-center passport-image-desktop" style="padding: 0px; margin-left: -42px; width: 533px; height: 174px;">
                                                </div>
                                        </div>
                                        <div *ngIf="documentType == 'id'" class="identity-doc-section-1-right identity-doc-section-1-right-id">
                                                <div class="row" style="margin: 0px;">
                                                        <div class="col-sm-12" style="padding: 0px;">
                                                                <button *ngIf="!isMobile" type="button"
                                                                        class=" btn btn-outline-rounded btn-info capture-button identity-start-button"
                                                                        style="margin: 0 auto; z-index: 999;"
                                                                        (click)="manualcapture()">{{"CAPTURE"| translate
                                                                        }} </button>
                                                                <div style="height: 20px; text-align: center;"> {{
                                                                        "REPEAT" | translate }}</div>
                                                                <div class="row text-center" style="margin: 0px; position: relative;">
                                                                        <div class="col-sm-8 text-center id-image-placeholder-parent"
                                                                                style="margin-top: 93px;padding-left: 0px;">
                                                                                <div class="overlay-id"
                                                                                        (click)="manualcapture()">
                                                                                        <img id="overlay-image"
                                                                                                src="../../../../assets/images/id.png"
                                                                                                class="image-placeholder image-placeholder-id"
                                                                                                width="90%" style="    opacity: 0.6;
                                                                                                z-index: 2;
                                                                                                position: absolute;
                                                                                                margin-top: 0px;" />
                                                                                        <video #idVideo id="video"
                                                                                                width="100%"
                                                                                                class="passport-video"
                                                                                                autoplay></video>
                                                                                        <div style="height: 140px;">
                                                                                        </div>
                                                                                </div>
                                                                        </div>
                                                                        <div style="margin-top: 73px;"
                                                                        class="id-canvas"
                                                                        >
                                                                                <canvas #idCanvas id=" canvas"
                                                                                        width="980" height="540"
                                                                                        style="display:none;"></canvas>
                                                                                <div *ngIf="captures.length == 0">
                                                                                        <div id="preview-panel"
                                                                                                class="text-center"
                                                                                                style="margin-top: -278px;">
                                                                                                <ul class="text-center"
                                                                                                        style="list-style-type: none;margin-left: -6px;margin-top: -6px;">
                                                                                                        <li class="text-center"
                                                                                                                style="display: inline-block">
                                                                                                        </li>
                                                                                                        <div
                                                                                                                style="height: 12px;">
                                                                                                        </div>
                                                                                                        <li class="text-center"
                                                                                                                style="display: inline-block">
                                                                                                        </li>
                                                                                                </ul>
                                                                                        </div>
                                                                                </div>
                                                                                <div *ngIf="captures.length == 1">
                                                                                        <div id="preview-panel"
                                                                                                class="text-center"
                                                                                                style="margin-top: 28px;">
                                                                                                <ul class="text-center"
                                                                                                        style="list-style-type: none;margin: 0px; padding: 0px;">
                                                                                                        <li class="text-center id-canvas-image-parent"
                                                                                                                style="display: inline-block">
                                                                                                                <img src="{{captures[0]}}"
                                                                                                                        width="117%"
                                                                                                                        height="auto" class="id-canvas-image"  />
                                                                                                        </li>
                                                                                                        <div
                                                                                                                style="height: 12px;">
                                                                                                        </div>
                                                                                                        <li class="text-center"
                                                                                                                style="display: inline-block"></li>
                                                                                                </ul>
                                                                                        </div>
                                                                                </div>
                                                                                <div *ngIf="captures.length == 2">
                                                                                        <div id="preview-panel"
                                                                                                class="text-center"
                                                                                                style="margin-top: 28px;">
                                                                                                <ul class="text-center id-second-image-canvas"
                                                                                                        style="list-style-type: none;margin: 0px; padding: 0px;">
                                                                                                        <li class="text-center id-canvas-image-parent"
                                                                                                                style="display: inline-block"
                                                                                                                *ngFor="let c of captures | slice:0:2">
                                                                                                                <img src="{{c}}"
                                                                                                                        width="117%"
                                                                                                                        height="auto" class="id-canvas-image" />
                                                                                                                <div
                                                                                                                        style="height: 12px;">
                                                                                                                </div>
                                                                                                        </li>
                                                                                                </ul>
                                                                                        </div>
                                                                                </div>
                                                                        </div>
                                                                </div>
                                                                <div *ngIf="isMobile"><button id="startButton"
                                                                                class="btn btn-success btn-take-photo"
                                                                                style="z-index: 999;"
                                                                                (click)="capture('id')">{{"CAPTURE" |
                                                                                translate }}</button>
                                                                </div>
                                                        </div>
                                                </div>
                                        </div>
                                        <div *ngIf="documentType == 'driversLicense'" class="identity-doc-section-1-right identity-doc-section-1-right-drivers">
                                                <div class="row" style="margin: 0px;">
                                                        <div class="col-sm-12">
                                                                <button *ngIf="!isMobile" type="button"
                                                                        class=" btn btn-outline-rounded btn-info capture-button identity-start-button"
                                                                        style="margin: 0 auto; z-index: 999;"
                                                                        (click)="manualcapture()">{{"CAPTURE"| translate
                                                                        }} </button>
                                                                <div style="height: 20px; text-align: center;"> {{
                                                                        "REPEAT" | translate }} </div>
                                                                <div class="row text-center" style="margin: 0px; position: relative;">
                                                                        <div class="col-sm-8 text-center drivers-image-placeholder-parent"
                                                                                style="margin-top: 93px;padding-left: 0px;">
                                                                                <div class="overlay-id"
                                                                                        (click)="manualcapture()">
                                                                                        <img id="overlay-image"
                                                                                                src="../../../../assets/images/id.png"
                                                                                                class="image-placeholder image-placeholder-driver"
                                                                                                width="90%" style="    opacity: 0.6;
                                                                                                z-index: 2;
                                                                                                position: absolute;
                                                                                                margin-top: 0px;" />
                                                                                        <video #driversVideo id="video"
                                                                                                width="100%"
                                                                                                class="passport-video"
                                                                                                autoplay></video>
                                                                                        <div style="height: 140px;">
                                                                                        </div>
                                                                                </div>
                                                                        </div>
                                                                        <div style="margin-top: 73px;" class="drivers-canvas">
                                                                                <canvas #driversCanvas id="canvas"
                                                                                        width="980" height="540"
                                                                                        style="display:none;"></canvas>
                                                                                <div *ngIf="captures.length == 0">
                                                                                        <div id="preview-panel"
                                                                                                class="text-center"
                                                                                                style="margin-top: -278px;">
                                                                                                <ul class="text-center"
                                                                                                        style="list-style-type: none;margin-left: -6px;margin-top: -6px;">
                                                                                                        <li class="text-center"
                                                                                                                style="display: inline-block">
                                                                                                        </li>
                                                                                                        <div
                                                                                                                style="height: 12px;">
                                                                                                        </div>
                                                                                                        <li class="text-center"
                                                                                                                style="display: inline-block">
                                                                                                        </li>
                                                                                                </ul>
                                                                                        </div>
                                                                                </div>
                                                                                <div *ngIf="captures.length == 1">
                                                                                        <div id="preview-panel"
                                                                                                class="text-center"
                                                                                                style="margin-top: 28px;">
                                                                                                <ul class="text-center"
                                                                                                        style="list-style-type: none;margin: 0px; padding: 0px;">
                                                                                                        <li class="text-center id-canvas-image-parent"
                                                                                                                style="display: inline-block">
                                                                                                                <img src="{{captures[0]}}"
                                                                                                                        width="117%"
                                                                                                                        height="auto" class="id-canvas-image" />
                                                                                                        </li>
                                                                                                        <div
                                                                                                                style="height: 12px;">
                                                                                                        </div>
                                                                                                        <li class="text-center"
                                                                                                                style="display: inline-block">
                                                                                                        </li>
                                                                                                </ul>
                                                                                        </div>
                                                                                </div>
                                                                                <div *ngIf="captures.length == 2">
                                                                                        <div id="preview-panel"
                                                                                                class="text-center"
                                                                                                style="margin-top: 28px;">
                                                                                                <ul class="text-center id-second-image-canvas"
                                                                                                        style="list-style-type: none;margin: 0px; padding: 0px;">
                                                                                                        <li class="text-center id-canvas-image-parent"
                                                                                                                style="display: inline-block"
                                                                                                                *ngFor="let c of captures | slice:0:2">
                                                                                                                <img src="{{c}}"
                                                                                                                        width="117%"
                                                                                                                        height="auto" class="id-canvas-image" />
                                                                                                                <div
                                                                                                                        style="height: 12px;">
                                                                                                                </div>
                                                                                                        </li>
                                                                                                </ul>
                                                                                        </div>
                                                                                </div>
                                                                        </div>
                                                                </div>
                                                                <div *ngIf="isMobile"><button id="startButton"
                                                                                class="btn btn-success btn-take-photo"
                                                                                style="z-index: 999;"
                                                                                (click)="capture('driversLicense')">{{"
                                                                                CAPTURE" | translate }}</button>
                                                                </div>
                                                        </div>
                                                </div>
                                        </div>
                                        <div *ngIf="documentType == 'passport'" class="identity-doc-section-1-right identity-doc-section-1-right-passport">
                                                <div style="height: 15px"></div>
                                                <div class="row passport-image-mobile" style="margin: 0px;">
                                                        <div class="col-sm-12 text-center" style="padding-left: 0px;">
                                                                <img src="../../../../assets/images/instructions.png"
                                                                        width="90%" height="auto" />
                                                        </div>
                                                </div>
                                                <button *ngIf="!isMobile" type="button"
                                                        class=" btn btn-outline-rounded btn-info capture-button identity-start-button"
                                                        style="margin: 0 auto; z-index: 999;"
                                                        (click)="manualcapture()">{{"CAPTURE"| translate }} </button>
                                                <div style="height: 20px; text-align: center;"> {{ "REPEAT" | translate
                                                        }}</div>
                                                <div class="row" style="margin: 0px; position: relative;">
                                                        <div class="col-sm-7 text-center image-parent-container"
                                                                style="margin-top: 93px;padding-left: 0px;">
                                                                <div class="overlay-id" (click)="manualcapture()">
                                                                        <img id="overlay-image"
                                                                                src="../../../../assets/images/id.png"
                                                                                class="image-placeholder image-placeholder-passport"
                                                                                width="90%" style="    opacity: 0.6;
                                                                                z-index: 2;
                                                                                position: absolute;
                                                                                margin-top: 1px;" />
                                                                        <video #passportVideo id="video" width="100%"
                                                                                class="passport-video" autoplay></video>
                                                                        <div style="height: 50px;"></div>
                                                                </div>
                                                        </div>
                                                        <div class="col-sm-5 text-center passport-canvas" style="float: unset;">
                                                                <canvas style="width: 100%;" #passportCanvas id="canvas"
                                                                        width="980" height="540"
                                                                        style="display:none;"></canvas>
                                                                <div *ngIf="!passportCaptured"
                                                                        style="margin-top: -98px">
                                                                </div>
                                                                <div *ngIf="passportCaptured">
                                                                        <div style=""></div>
                                                                        <img src="{{passportCapture}}" width="100%"
                                                                                style="" class="passport-capture" height="auto" />
                                                                </div>
                                                        </div>
                                                </div>
                                                <div *ngIf="isMobile"><button id="startButton" class="btn btn-success"
                                                                (click)="capture('passport')"
                                                                style="font-size: 16px;background-color: #1F4694;border:none; z-index: 999;">{{
                                                                "CAPTURE" | translate }}</button></div>
                                                <div style="height: 20px;"></div>
                                        </div>
                                        <div class="form-group text-center">
                                                <img [hidden]="!loading" src="{{loadingImg}}" width="35" />
                                        </div>
                                </div>
                        </div>
                </div>
        </form>
</div>
<div *ngIf="isOnMobile  && isReady">
        <app-mobile-document-onboarding (documentReady)="documentReady.emit(true)"></app-mobile-document-onboarding>
</div>
