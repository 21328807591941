import { Component, OnInit, TemplateRef } from '@angular/core';
import { Router } from '@angular/router';
import { AlertService, CallsService, AuthenticationService, KYBService } from '../../../_services';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { config } from '../../../../assets/configuration';
import * as FileSaver from 'file-saver';

/**
* Implements password changing directive
*/
@Component({
  moduleId: module.id,
  selector: 'app-activity-report',
  templateUrl: 'activityReport.component.html',
  styleUrls: ['./activityReport.component.css']
})

export class ActivityReportComponent implements OnInit {

  allEntitiesResponse: any = {};
  entities: any = [];
  entityResponse: any = {};
  riskResponse: any = {};
  rolesResponse: any = {};
  documentContentResponse: any = {};
  basicInformation: any = {};
  pdfResponse: any = {};
  loading2: any = false;
  loading: any = false;
  eventsForEntityResponse: any = {};
  reportLogo: any = config.reportLogo;
  entityId: any = null;
  loadingImg: any = config.loadingImg;
  reportPreviewModal: BsModalRef;
  usersResponse: any = {};
  users: any = [];
  entitiesResponse: any = {};
  haveResults: any = false;
  pageSize = 30;
  currentPage = 0;
  sortingOption: any = null;
  searchQuery: any = null;
  createdBy: any = null;
  totalItems = 0;
  numberOfElements = 0;
  page: number;
  pageChangeTrigger = false;

  htmlInput = '';
  timestampFrom: any;
  timestampTo: any;
  groupOption = '';
  events: any = {};
  analystValueEvents: any = {};
  entityValueEvents: any = {};
  analysts: any = [];
  dossiers: any = [];
  analystsUnique: any = [];
  dossiersUnique: any = [];
  eventsByAnalyst: any = {};
  eventsByDossier: any = {};
  prettyTimestampFrom: any = [];
  prettyTimestampTo: any = [];
  counter = 0;
  eventsResponse: any = {};
  entityIds: any = [];
  dateRange: any;
  previewClicked = false;
  analyst: any;
  dossier: any;
  displayFilters = false;
  isEmpty = false;

  entitiesLoading: any = false;
  usersLoading: any = false;

  usersTmp: any = [];
  entitiesTmp: any = [];

  constructor(
    private router: Router,
    private callsService: CallsService,
    private alertService: AlertService,
    private kybService: KYBService,
    private authenticationService: AuthenticationService,
    private modalService: BsModalService
  ) {}

  ngOnInit() {}

  datesChanged(value: any): void {
    if (!value) {
      return;
    }

    this.dateRange = value;
    this.loading = true;
    this.timestampFrom = new Date(this.dateRange[0]);
    this.prettyTimestampFrom = this.timestampConverter(this.dateRange[0]).toString().split(' ');
    this.timestampFrom.setHours(0, 0, 0, 0);
    const tzoffset = this.timestampFrom.getTimezoneOffset() * 60000; // offset in milliseconds
    const localISOTimeFrom = (new Date(this.timestampFrom - tzoffset)).toISOString().slice(0, -1);
    this.timestampFrom = localISOTimeFrom + 'Z';
    this.timestampTo = new Date(this.dateRange[1]);
    this.prettyTimestampTo = this.timestampConverter(this.dateRange[1]).toString().split(' ');
    this.timestampTo.setHours(23, 59, 59, 999);

    const tzoffsetTo = this.timestampTo.getTimezoneOffset() * 60000; // offset in milliseconds
    const localISOTimeTo = (new Date(this.timestampTo - tzoffsetTo)).toISOString().slice(0, -1);
    this.timestampTo = localISOTimeTo + 'Z';
  }

  getEvents() {
    this.currentPage = 0;
    this.events = [];

    if (this.analyst) {
      // tslint:disable-next-line:max-line-length
      this.getPaginatedEvents(this.pageSize, this.currentPage, this.sortingOption, this.timestampFrom, this.timestampTo, this.analyst, this.entityId);
    } else {
      // tslint:disable-next-line:max-line-length
      this.getPaginatedEvents(this.pageSize, this.currentPage, this.sortingOption, this.timestampFrom, this.timestampTo, this.createdBy, this.entityId);
    }}

  // tslint:disable-next-line:max-line-length
  getPaginatedEvents(pageSize: any, currentPage: any, sortingOption: any, timestampFrom: any, timestampTo: any, createdBy: any, entityId: any) {
    this.entitiesLoading = true;
    this.usersLoading = true;

    this.kybService.getEventsPaginated(pageSize, currentPage, sortingOption, timestampFrom
      , timestampTo, createdBy ? createdBy : null, entityId ? entityId : null).subscribe(
      (res: any) => {
        this.eventsResponse = { ...res.data.page, totalElements: res.data.totalElements, numberOfElements: res.data.totalElements };
        this.events = res.data.page;

        if (this.events.length === 0) {
          this.isEmpty = true;
        } else {
          this.isEmpty = false;
        }

        this.haveResults = true;
        this.totalItems = res.data.totalElements;
        this.numberOfElements = res.data.numberOfElements;

        this.getValuesForAnalystAndEntity(res.data.page);

        this.entitiesLoading = false;
        this.usersLoading = false;
      },
      error => {
      }
    );
  }

  containsObject(obj, list) {
    for (let i = 0; i < list.length; i++) {
      if (list[i] === obj) {
        return true;
      }
    }

    return false;
  }

  getEventsForAnalyst() {
    this.currentPage = 0;
    this.getPaginatedEvents(this.pageSize, this.currentPage, this.sortingOption, this.timestampFrom,
      this.timestampTo, this.analyst, this.entityId);
  }

  getEventsForEntity() {
    this.currentPage = 0;
    this.getPaginatedEvents(this.pageSize, this.currentPage, this.sortingOption, this.timestampFrom,
      this.timestampTo, this.analyst, this.entityId);
  }

  getValuesForAnalystAndEntity(valueEvents: any) {
    if (this.pageChangeTrigger) {
      this.entities = [];
      this.entitiesTmp = [];
      this.users = [];
      this.usersTmp = [];
      this.pageChangeTrigger = false;
    }
    this.analystValueEvents = valueEvents;
    this.entityValueEvents = valueEvents;

    for (let i = 0; i < this.analystValueEvents.length; i++) {
      if (!(this.usersTmp.includes(this.analystValueEvents[i].actor))) {
        if (this.analystValueEvents[i].actor) {
          this.usersTmp.push(this.analystValueEvents[i].actor);
          this.users.push({ 'name': this.analystValueEvents[i].actor });
        }
      }
    }

    for (let i = 0; i < this.entityValueEvents.length; i++) {
      if (!(this.entitiesTmp.includes(this.entityValueEvents[i].entityId))) {
        if (this.entityValueEvents[i].entityNames.length !== 0 && this.entityValueEvents[i].entityId) {
          this.entitiesTmp.push(this.entityValueEvents[i].entityId);
          this.entities.push({ 'name': this.entityValueEvents[i].entityNames[0], 'id': this.entityValueEvents[i].entityId });
        }
      }
    }
  }

  toggleFilters() {
    if (this.displayFilters === true) {
      this.displayFilters = false;
    } else {
      this.displayFilters = true;
    }
  }

  pageChanged(event: any) {
    this.pageChangeTrigger = true;
    if (this.analyst) {
      // tslint:disable-next-line:max-line-length
      this.getPaginatedEvents(this.pageSize, event.page - 1, this.sortingOption, this.timestampFrom, this.timestampTo, this.analyst, this.entityId);
    } else {
      // tslint:disable-next-line:max-line-length
      this.getPaginatedEvents(this.pageSize, event.page - 1, this.sortingOption, this.timestampFrom, this.timestampTo, this.createdBy, this.entityId);
    }
  }

  pageSizeChanged() {
    this.currentPage = 0;
    this.pageChangeTrigger = true;
    this.getPaginatedEvents(this.pageSize, this.currentPage, this.sortingOption, this.timestampFrom,
      this.timestampTo, this.createdBy, this.entityId);
  }

  exportCSV() {
    let csvData = 'Analyst,Entity,Event,Timestamp\n';

    for (let i = 0; i < this.events.length; i++) {
      csvData += this.events[i].actor + ',' + this.events[i].entityNames[0] + ',' +
      this.events[i].message + ',' + this.events[i].timestamp.split('T')[0] + ' ' +
      this.events[i].timestamp.split('T')[1].split('.')[0].replace('Z', '') + '\n';
    }

    const hiddenElement = document.createElement('a');
    hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURI(csvData);
    hiddenElement.target = '_blank';
    hiddenElement.download = 'activity_report.csv';
    hiddenElement.click();
  }

  generateReport() {}

  prettyTimestamp(timestamp: any) {
    const tmp = timestamp.split('.')[0];
    const prettyTimestamp = tmp.split('T')[0] + ' ' + tmp.split('T')[1];
    return prettyTimestamp;
  }

  generateCSV() {}

  onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  }

  generatePDF(htmlInput: any) {
    this.counter++;

    if (this.counter === this.analysts.length || this.counter === this.entityIds.length) {
      htmlInput += '</body></html>';

      if (this.previewClicked === true) {
        const div = (document.getElementById('report-content') as HTMLElement);

        div.innerHTML = htmlInput;
        this.previewClicked = false;
        this.counter = 0;
        this.analysts = [];
        this.dossiers = [];
        this.htmlInput = '';
        this.loading = false;
      } else {
        this.callsService.getPdfReport(htmlInput).subscribe(
          data => {
            this.pdfResponse = data;
            const binaryImg = atob(this.pdfResponse.data);
            const length = binaryImg.length;
            const arrayBuffer = new ArrayBuffer(length);
            const uintArray = new Uint8Array(arrayBuffer);

            for (let i = 0; i < length; i++) {
              uintArray[i] = binaryImg.charCodeAt(i);
            }

            const file = new Blob([uintArray], { type: 'application/pdf' });

            FileSaver.saveAs(file, 'event-log-report.pdf');
            this.counter = 0;
            this.analysts = [];
            this.dossiers = [];
            this.htmlInput = '';
            this.loading = false;
          },
          error => {}
        );
      }
    }
  }

  displayReportPreviewModal(template: TemplateRef<any>) {
    this.previewClicked = true;
    this.reportPreviewModal = this.modalService.show(template, Object.assign({}, { class: 'modal-xl' }));
    this.generateReport();
  }

  closeModal() {
    this.reportPreviewModal.hide();
  }

  timestampConverter(timestamp: any) {
    const date = new Date(timestamp);
    return date.toUTCString();
  }
}
