/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./screeningRiskSettings.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/forms";
import * as i3 from "@angular/common";
import * as i4 from "./screeningRiskSettings.component";
import * as i5 from "@angular/router";
import * as i6 from "../../../_services/calls.service";
import * as i7 from "../../../_services/alert.service";
import * as i8 from "../../../_services/kyb.service";
import * as i9 from "../../../_services/authentication.service";
import * as i10 from "ngx-bootstrap/modal";
var styles_ScreeningRiskSettingsComponent = [i0.styles];
var RenderType_ScreeningRiskSettingsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ScreeningRiskSettingsComponent, data: {} });
export { RenderType_ScreeningRiskSettingsComponent as RenderType_ScreeningRiskSettingsComponent };
function View_ScreeningRiskSettingsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "tr", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "td", [], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), (_l()(), i1.ɵeld(3, 0, null, null, 1, "td", [], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""])), (_l()(), i1.ɵeld(5, 0, null, null, 3, "td", [], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 2, "button", [["class", "btn btn-warning btn-yellow"], ["style", "padding: 6px;"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.edit(i1.ɵnov(_v.parent, 18), _v.context.$implicit) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Edit \u00A0"])), (_l()(), i1.ɵeld(8, 0, null, null, 0, "i", [["aria-hidden", "true"], ["class", "fa fa-pencil"]], null, null, null, null, null))], null, function (_ck, _v) { var currVal_0 = _v.context.$implicit.riskType; _ck(_v, 2, 0, currVal_0); var currVal_1 = _v.context.$implicit.riskFactor; _ck(_v, 4, 0, currVal_1); }); }
function View_ScreeningRiskSettingsComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "modal-header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h4", [["class", "modal-title pull-left"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Adjust Risk Factor"])), (_l()(), i1.ɵeld(3, 0, null, null, 2, "button", [["aria-label", "Close"], ["class", "close pull-right"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.editModalRef.hide() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "span", [["aria-hidden", "true"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u00D7"])), (_l()(), i1.ɵeld(6, 0, null, null, 12, "div", [["class", "modal-body"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 1, "label", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Risk Factor: "])), (_l()(), i1.ɵeld(9, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 0, "div", [["style", "height: 10px;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 6, "input", [["min", "0"], ["type", "number"]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"], [null, "input"], [null, "blur"], [null, "compositionstart"], [null, "compositionend"], [null, "change"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("input" === en)) {
        var pd_0 = (i1.ɵnov(_v, 12)._handleInput($event.target.value) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i1.ɵnov(_v, 12).onTouched() !== false);
        ad = (pd_1 && ad);
    } if (("compositionstart" === en)) {
        var pd_2 = (i1.ɵnov(_v, 12)._compositionStart() !== false);
        ad = (pd_2 && ad);
    } if (("compositionend" === en)) {
        var pd_3 = (i1.ɵnov(_v, 12)._compositionEnd($event.target.value) !== false);
        ad = (pd_3 && ad);
    } if (("change" === en)) {
        var pd_4 = (i1.ɵnov(_v, 13).onChange($event.target.value) !== false);
        ad = (pd_4 && ad);
    } if (("input" === en)) {
        var pd_5 = (i1.ɵnov(_v, 13).onChange($event.target.value) !== false);
        ad = (pd_5 && ad);
    } if (("blur" === en)) {
        var pd_6 = (i1.ɵnov(_v, 13).onTouched() !== false);
        ad = (pd_6 && ad);
    } if (("ngModelChange" === en)) {
        var pd_7 = ((_co.currentTopic.riskFactor = $event) !== false);
        ad = (pd_7 && ad);
    } return ad; }, null, null)), i1.ɵdid(12, 16384, null, 0, i2.DefaultValueAccessor, [i1.Renderer2, i1.ElementRef, [2, i2.COMPOSITION_BUFFER_MODE]], null, null), i1.ɵdid(13, 16384, null, 0, i2.NumberValueAccessor, [i1.Renderer2, i1.ElementRef], null, null), i1.ɵprd(1024, null, i2.NG_VALUE_ACCESSOR, function (p0_0, p1_0) { return [p0_0, p1_0]; }, [i2.DefaultValueAccessor, i2.NumberValueAccessor]), i1.ɵdid(15, 671744, null, 0, i2.NgModel, [[8, null], [8, null], [8, null], [6, i2.NG_VALUE_ACCESSOR]], { model: [0, "model"] }, { update: "ngModelChange" }), i1.ɵprd(2048, null, i2.NgControl, null, [i2.NgModel]), i1.ɵdid(17, 16384, null, 0, i2.NgControlStatus, [[4, i2.NgControl]], null, null), (_l()(), i1.ɵeld(18, 0, null, null, 0, "div", [["style", "height: 10px;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(19, 0, null, null, 2, "div", [["class", "modal-footer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(20, 0, null, null, 1, "button", [["class", "btn btn-success btn-save-information"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.save() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Save"]))], function (_ck, _v) { var _co = _v.component; var currVal_7 = _co.currentTopic.riskFactor; _ck(_v, 15, 0, currVal_7); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 17).ngClassUntouched; var currVal_1 = i1.ɵnov(_v, 17).ngClassTouched; var currVal_2 = i1.ɵnov(_v, 17).ngClassPristine; var currVal_3 = i1.ɵnov(_v, 17).ngClassDirty; var currVal_4 = i1.ɵnov(_v, 17).ngClassValid; var currVal_5 = i1.ɵnov(_v, 17).ngClassInvalid; var currVal_6 = i1.ɵnov(_v, 17).ngClassPending; _ck(_v, 11, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }); }
export function View_ScreeningRiskSettingsComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 17, "div", [["class", "col-md-12"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "div", [["style", "height: 20px"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "h3", [["class", "text-center"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Screening Risk Settings"])), (_l()(), i1.ɵeld(4, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 0, "div", [["style", "height: 15px"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 11, "table", [["class", "table table-responsive table-hover"], ["style", "text-align: left;"], ["width", "100%"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 7, "thead", [], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 6, "tr", [], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 1, "th", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Risk Type"])), (_l()(), i1.ɵeld(11, 0, null, null, 1, "th", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Risk Factor"])), (_l()(), i1.ɵeld(13, 0, null, null, 1, "th", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Action"])), (_l()(), i1.ɵeld(15, 0, null, null, 2, "tbody", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ScreeningRiskSettingsComponent_1)), i1.ɵdid(17, 278528, null, 0, i3.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), (_l()(), i1.ɵand(0, [["editTemplate", 2]], null, 0, null, View_ScreeningRiskSettingsComponent_2))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.screeningRisks; _ck(_v, 17, 0, currVal_0); }, null); }
export function View_ScreeningRiskSettingsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-screening-risk-settings", [], null, null, null, View_ScreeningRiskSettingsComponent_0, RenderType_ScreeningRiskSettingsComponent)), i1.ɵdid(1, 114688, null, 0, i4.ScreeningRiskSettingsComponent, [i5.Router, i6.CallsService, i7.AlertService, i8.KYBService, i9.AuthenticationService, i10.BsModalService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ScreeningRiskSettingsComponentNgFactory = i1.ɵccf("app-screening-risk-settings", i4.ScreeningRiskSettingsComponent, View_ScreeningRiskSettingsComponent_Host_0, {}, {}, []);
export { ScreeningRiskSettingsComponentNgFactory as ScreeningRiskSettingsComponentNgFactory };
