import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable()
export class WebCallsService {

  private httpOptions = {};
  private httpOptionsCancel = {};
  private xAuthToken = 'c13043dc-288f-420e-be0c-4b1822744a3d';

  constructor(private http: HttpClient) {}

  getCreditsForUser() {
    this.httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'X-Auth-Token': JSON.parse(localStorage.getItem('auth_token')) })
    };

    return this.http.get('/api/webserver/user/getcredits', this.httpOptions);
  }

  getcreditPackages() {
    this.httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'X-Auth-Token': this.xAuthToken })
    };

    return this.http.get('/webui/uiapi/payments/packages', this.httpOptions);
  }

  startStripeCheckoutSessionOnetime(packageId: any, email: any) {
    this.httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'X-Auth-Token': this.xAuthToken })
    };

    return this.http.post('/webui/uiapi/stripe/session/onetime?packageId=' + packageId + '&email=' + email, this.httpOptions);
  }

  getSources() {
    this.httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'X-Auth-Token': JSON.parse(localStorage.getItem('auth_token')) })
    };

    return this.http.get('/api/webserver/sources/getSources', this.httpOptions);
  }

  endTrial() {
    this.httpOptionsCancel = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'X-Auth-Token': this.xAuthToken })
    };

    return this.http.post('/webui/uiapi/subscription/endTrial', null, this.httpOptionsCancel);
  }

  isTrialUser() {
    this.httpOptionsCancel = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json', 'X-Auth-Token': this.xAuthToken })
    };

    return this.http.post('/webui/uiapi/subscription/isTrialUser', null, this.httpOptionsCancel);
  }

  addToAutopilotJourney(email: any, triggerId: any) {
    this.httpOptions = {};

    return this.http.post('https://api2.autopilothq.com/v1/trigger/' + triggerId + '/contact/' + email + '?apikey=bb21595f61e84ff7be6cbddf6772f430', this.httpOptions);
  }
}
