import { NgModule } from '@angular/core';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'geoRoleName'})
export class GeoRoleNamePipe implements PipeTransform {
  transform(value: any): string {
    let result = '';

    if (value === 'residentOf') {
      result = 'resident of';
    }

    if (value === 'citizenOf') {
      result = 'citizen of';
    }

    if (value === 'bornIn') {
      result = 'born in';
    }

    if (value === 'registeredIn') {
      result = 'registered in';
    }

    if (value === 'locatedIn') {
      result = 'located in';
    }

    if (value === 'incorporatedIn') {
      result = 'incorporated in';
    }

    return result;
  }
}


@NgModule({
  declarations: [
    GeoRoleNamePipe
  ],
  exports: [
    GeoRoleNamePipe
  ]
})

export class GeoRoleNameImportModule {
  static forRoot() {
    return {
        ngModule: GeoRoleNameImportModule,
        providers: [],
    };
 }

}
