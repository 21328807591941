import { Component, OnInit } from '@angular/core';
import { DataService, KYBService } from '../../_services';
import * as $ from 'jquery';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { CloseNotificationComponent } from '../../modals/close-notification/closeNotification.component';

/**
 * Implements the notification page
*/
@Component({
  moduleId: module.id,
  templateUrl: 'notifications.component.html',
  styleUrls: ['./notifications.component.scss']
})

export class NotificationsComponent implements OnInit {

  currentPage = 0;
  notifications: any = [];
  notificationsResponse: any = {};
  pageSize = 30;
  sortingOption = 'id,asc';

  displayFilters = false;

  isEmpty = false;
  numberOfElements = 0;
  totalItems = 0;

  notificationOwner = '';
  notificationOwners: any = [];

  notificationType = '';
  notificationTypes: any = [];

  notificationName = '';
  notificationNames: any = [];

  closeNotificationModal: BsModalRef;

  filters: any = {};

  constructor(
    private dataService: DataService,
    private kybService: KYBService,
    private modalService: BsModalService
  ) {}

  ngOnInit() {
    this.notifications = [];

    if (sessionStorage.getItem('selectedNotificationOwner') !== null) {
      this.notificationOwner = sessionStorage.getItem('selectedNotificationOwner');
    }

    if (sessionStorage.getItem('selectedNotificationType') !== null) {
      this.notificationType = sessionStorage.getItem('selectedNotificationType');
    }

    if (sessionStorage.getItem('selectedNotificationName') !== null) {
      this.notificationName = sessionStorage.getItem('selectedNotificationName');
    }

    if (localStorage.getItem('sortingNotificationOption') !== null) {
      const sortingOption = localStorage.getItem('sortingNotificationOption');
      let column = '';
      let direction = '';

      column = sortingOption.substring(0, sortingOption.indexOf(','));
      direction = sortingOption.substring(sortingOption.indexOf(',') + 1, sortingOption.length);

      this.sort(column, direction);
    } else {
      this.sort('createdAt', 'desc');
    }

    this.kybService.getAllUsers().subscribe(
      data => {
        for (let i = 0; i < data.data.length; i++) {
          this.notificationOwners.push({name: data.data[i].email});
        }
      },
      error => {}
    );

    this.kybService.getAllEntities({}).subscribe(
      (data: any) => {
        for (let i = 0; i < data.data.length; i++) {
          this.notificationNames.push({name: data.data[i].name});
        }
      },
      error => {}
    );

    this.kybService.getNotificationTypes().subscribe(
      (data: any) => {
        for (let i = 0; i < data.data.length; i++) {
          let name = '';

          if (data.data[i] === 'DossierExpirationNotification') {
            name = 'Entity Refresh Date Expired';
          } else if (data.data[i] === 'DocumentNotificationDto') {
            name = 'Document Expired';
          }

          this.notificationTypes.push({ name });
        }
      }
    );

    if (this.notificationOwner) {
      this.filters.entityOwner = [ this.notificationOwner ];
    } else {
      this.filters.entityOwner = [];
    }

    if (this.notificationType) {
      if (this.notificationType === 'Entity Refresh Date Expired') {
        this.filters.notificationType = [ 'DossierExpirationNotification' ];
      } else if (this.notificationType === 'Document Expired') {
        this.filters.notificationType = [ 'DocumentNotificationDto' ];
      } else {
        this.filters.notificationType = [ this.notificationType ];
      }
    } else {
      this.filters.notificationType = [];
    }

    if (this.notificationName) {
      this.filters.entityName = [ this.notificationName ];
    } else {
      this.filters.entityName = [];
    }

    this.getAllNotifications(this.pageSize, this.currentPage, this.sortingOption);
  }

  filterOwner(event: any) {
    if (event !== undefined) {
      this.currentPage = 0;
      sessionStorage.setItem('selectedNotificationOwner', this.notificationOwner);
      this.filters.entityOwner = [ this.notificationOwner ];
      this.getAllNotifications(this.pageSize, this.currentPage, this.sortingOption);
    } else {
      this.resetOwner();
    }
  }

  resetOwner() {
    this.currentPage = 0;
    this.notificationOwner = '';
    delete this.filters.entityOwner;
    sessionStorage.removeItem('selectedNotificationOwner');
    this.getAllNotifications(this.pageSize, this.currentPage, this.sortingOption);
  }

  filterType(event: any) {
    if (event !== undefined) {
      this.currentPage = 0;

      if (this.notificationType === 'Entity Refresh Date Expired') {
        this.filters.notificationType = [ 'DossierExpirationNotification' ];
      } else if (this.notificationType === 'Document Expired') {
        this.filters.notificationType = [ 'DocumentNotificationDto' ];
      } else {
        this.filters.notificationType = [ this.notificationType ];
      }

      sessionStorage.setItem('selectedNotificationType', this.notificationType);
      this.getAllNotifications(this.pageSize, this.currentPage, this.sortingOption);
    } else {
      this.resetType();
    }
  }

  resetType() {
    this.currentPage = 0;
    this.notificationType = '';
    delete this.filters.notificationType;
    sessionStorage.removeItem('selectedNotificationType');
    this.getAllNotifications(this.pageSize, this.currentPage, this.sortingOption);
  }

  filterName(event: any) {
    if (event !== undefined) {
      this.currentPage = 0;
      sessionStorage.setItem('selectedNotificationName', this.notificationName);
      this.filters.entityName = [ this.notificationName ];
      this.getAllNotifications(this.pageSize, this.currentPage, this.sortingOption);
    } else {
      this.resetName();
    }
  }

  resetName() {
    this.currentPage = 0;
    this.notificationName = '';
    delete this.filters.entityName;
    sessionStorage.removeItem('selectedNotificationName');
    this.getAllNotifications(this.pageSize, this.currentPage, this.sortingOption);
  }

  closeNotification(notification: any) {
    const initialState = {
      'notification': notification
    };

    this.closeNotificationModal = this.modalService.show(CloseNotificationComponent, { initialState, backdrop: 'static' });
    this.dataService.closeModalEvent.subscribe(
      message => {
        this.closeNotificationModal.hide();
        this.ngOnInit();
      }
    );
  }

  sort(column: any, direction: any) {
    this.currentPage = 0;
    this.sortingOption = column + ',' + direction;
    localStorage.setItem('sortingNotificationOption', this.sortingOption);
    this.getAllNotifications(this.pageSize, this.currentPage, this.sortingOption);
  }

  pageSizeChanged() {
    this.currentPage = 0;
    sessionStorage.setItem('pageSize', this.pageSize.toString());
    this.getAllNotifications(this.pageSize, this.currentPage, this.sortingOption);
  }

  pageChanged(event: any) {
    this.getAllNotifications(this.pageSize, event.page - 1, this.sortingOption);
  }

  toggleFilters() {
    if (this.displayFilters === true) {
      this.displayFilters = false;
    } else {
      this.displayFilters = true;
    }
  }

  getAllNotifications(size: any, page: any, sort: any) {
    this.kybService.getAllNotifications(size, page, sort, this.filters).subscribe(
      data => {
        this.notifications = [];
        this.notificationsResponse = data;
        this.notifications = this.notificationsResponse.data.notifications;

        this.totalItems = this.notificationsResponse.data.totalElements;
        this.numberOfElements = this.notifications.length;

        if (!this.notifications || this.totalItems === 0 || this.numberOfElements === 0) {
          this.isEmpty = true;
        } else {
          this.isEmpty = false;
        }

        if (localStorage.getItem('sortingNotificationOption') !== null) {
          sort = localStorage.getItem('sortingNotificationOption');
          $(`i[id*='-sorticon']`).css('opacity', '0.4');
          $('#' + sort.split(',')[0] + '-' + sort.split(',')[1] + '-sorticon').css('opacity', '1.0');
        }
      }
    );
  }
}
