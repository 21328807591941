<div class="modal-header">
    <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <div class="row">
        <div class="col-md-2"></div>
        <div class="col-md-8 text-center">
            <div style="height: 10px"></div>
            <i class="fas fa-exclamation-triangle" style="color: #cc0000;font-size: 70px;margin-left: 145px"></i><br/>
            <div style="height: 85px"></div>
            <div *ngIf="credits > 0">
                <h4>WARNING: Your credit balance is getting low! You only have <span style="color: #cc0000">{{credits}}</span> credits left.</h4>
            </div>
            <div *ngIf="credits == 0">
                <h4>WARNING: You have ran out of credits!</h4>
            </div>
        </div>
        <div class="col-md-2"></div>
    </div>
    <div class="row">
        <div class="col-md-2"></div>
        <div class="col-md-8 text-center">
            <div style="height: 15px"></div>
            <p style="text-align: center">Without credits you will not be able to use KYC3.</p><p><b>Please select one of the credit packages below to top up your credits.</b></p>
            <form #loadForm="ngForm" novalidate>
                <div class="form-group text-center">
                <br>
                <select class="form-control" name="packageId" [(ngModel)]="packageId" #packageIdd="ngModel" style="border: none; border-radius: 0px;background-color: #F8F8F8">
                        <option value="" disabled selected> Select credit package: </option>
                        <option *ngFor="let package of packages" [ngValue]="package.id">{{package.creditsCount | number}} credits for {{ package.price/100 | number }} euros</option>
                </select>
            </div>
            </form>
            <div style="height: 15px;"></div>
            <button class="btn btn-success" style="border: none;color:white;background-color: #F8CC20;border-radius: 0px;" (click) = "checkout()">GO TO CHECKOUT</button>
        </div>
    </div>
    <div class="row" *ngIf="isTrialUser">
        <div class="col-md-2"></div>
        <div class="col-md-8 text-center">
            <div style="height: 20px"></div>
            <p style="text-align: center" style="font-size: 16px;">OR</p><p><b>Click the button below to end your trial now and switch to the plan you chose upon subscription.</b></p>
            <div style="height: 20px;"></div>
            <button class="btn btn-success" style="border: none;color:white;background-color: #F8CC20;border-radius: 0px;" (click)="endTrial()">END TRIAL AND START SUBSCRIPTION</button>
        </div>
    </div>
        <div class="col-md-2"></div>
    </div>
<div class="modal-footer"></div>
