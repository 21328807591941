import { Component, OnInit, Output, EventEmitter} from '@angular/core';
import { WebDataService } from '../../../../_services/index';


@Component({
    moduleId: module.id,
    // tslint:disable-next-line:component-selector
    selector: 'risk-level',
    templateUrl: 'risk-level.component.html',
    styleUrls: [ './risk-level.component.css' ]
})

export class RiskLevelComponent implements OnInit {

    reportResults: any = {};
    riskAssessmentNote: any = '';

    constructor(private dataService: WebDataService) {}

    @Output() riskAssessmentEvent = new EventEmitter();

    ngOnInit() {}

    addRiskLevel(level: any) {
        this.reportResults = this.dataService.reportResults.getValue();

        if (this.reportResults === null) {
            this.reportResults = {};
            this.reportResults.riskLevel = '';
        }

        this.reportResults.riskLevel = level;
        this.dataService.setCurrentReportResults(this.reportResults);
        this.riskAssessmentEvent.emit();
    }
}
