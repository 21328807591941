<div class="col-md-12" style="padding: 0;" xmlns:height="http://www.w3.org/1999/xhtml">
  <p class="text-center">Details page for entity <span class="text-primary"
      [style.color]="mainColor">{{entityName}}</span></p>
  <div class="row">
    <div class="" style="width: 100%; text-align: center;">
      <a [routerLink]="['/enterprise']" class="text-center" [style.color]="mainColor">Back to overview</a></div>
  </div>
  <div style="height: 30px"></div>
  <div class="row" style="margin: 0px;"><div class="col-md-12" style="padding: 0px;">
      <div class="form-group" style="margin-left: -15px;" style="margin-bottom: 0px !important;">
        <ng-container *ngIf="displayPhoneNumberOnboardingTrigger && userPermissions.indexOf('OnboardingInvitationSending') !== -1"><button (click)="sendOnboardingSMS()"
            class="btn btn-primary" [style.background-color]="mainColor" [disabled]="onboardingLoading">{{ "SEND_ONBOARDING_SMS" | translate }}&nbsp;&nbsp;<i
              class="fa fa-mobile" aria-hidden="true"></i></button>&#160;&#160;&#160;
        </ng-container> <ng-container *ngIf="displayEmailOnboardingTrigger && userPermissions.indexOf('OnboardingInvitationSending') !== -1">
          <button (click)="sendOnboardingEmail()" class="btn btn-primary" [style.background-color]="mainColor" [disabled]="onboardingLoading">Send
            onboarding email&nbsp;&nbsp;<i class="fa fa-envelope" aria-hidden="true"></i></button> &#160;&#160;&#160;
        </ng-container>
          <ng-container *ngIf="displayFullEDDReport">
            <button (click)="fullReport()"
          class="btn btn-primary" [style.background-color]="mainColor">Full EDD Report</button>&nbsp;&nbsp;&nbsp;
        </ng-container>
        <ng-container *ngIf="userPermissions.indexOf('UpdateEntity') !== -1">
          <button (click)="renameEntity()" class="btn btn-primary" [style.background-color]="mainColor"
          >{{ "RENAME_ENTITY" | translate }}</button>&nbsp;&nbsp;&nbsp;
        </ng-container>
        <ng-container *ngIf="entityResponse?.data?.nextRefresh <= timestampTo">
          <button (click)="confirmRefresh()" class="btn btn-primary" [style.background-color]="mainColor">Review Completed</button>&nbsp;&nbsp;&nbsp;
        </ng-container>
        <ng-container *ngIf="isOwner && userPermissions.indexOf('UpdateEntity') !== -1">
          <button [disabled]="isOwner"
          (click)="takeOwnership()" class="btn btn-primary" [style.background-color]="mainColor"
          > You own this entity</button>&nbsp;&nbsp;&nbsp;
        </ng-container>
        <ng-container *ngIf="!isOwner && userPermissions.indexOf('UpdateEntity') !== -1">
          <button [disabled]="isOwner"
          (click)="takeOwnership()" class="btn btn-primary" [style.background-color]="mainColor"
          >{{ "TAKE_OWNERSHIP" | translate }}</button>&nbsp;&nbsp;&nbsp;
        </ng-container>
        <ng-container *ngIf="userPermissions.indexOf('DeleteEntity') !== -1">
          <button (click)="deleteEntity()" class="btn btn-danger btn-delete" style="padding: 5px;">{{ "DELETE_ENTITY" | translate }}</button>&nbsp;&nbsp;&nbsp;
        </ng-container><br>
      </div>
    </div>
  </div>
  <div class="table-responsive" style="overflow-x: hidden;">
    <div class="row">
      <div class="col-sm-6">
        <div style="height: 40px"></div>
        <div class="basic-info heading">
          Contact Information
          <button *ngIf="(showEditInformation && userPermissions.indexOf('UpdateEntity') !== -1 && userPermissions.indexOf('CreateDocument') !== -1 && userPermissions.indexOf('UpdateDocument') !== -1)" class="btn btn-primary basic-info-button" (click)="editInformation()">
            <i class="fa fa-pencil" title="Edit"></i> 
          </button>
        </div>
        <table class="table">
          <tbody>
              <tr *ngIf="entityResponse?.data?.primaryName">
                <td class="contactInformationTableRow"><b>Name:</b></td>
                <td class="contactInformationTableRow">{{entityResponse?.data?.primaryName}}</td>
              </tr>
              <tr *ngIf="basicInformation?.dateOfBirth">
                <td class="contactInformationTableRow"><b>Date of Birth:</b></td>
                <td class="contactInformationTableRow">{{basicInformation?.dateOfBirth | date:'dd/MM/yyyy'}}</td>
              </tr>
              <tr *ngIf="basicInformation?.nationality">
                <td class="contactInformationTableRow"><b>Nationality:</b></td>
                <td class="contactInformationTableRow">{{basicInformation?.nationality}}</td>
              </tr>
              <tr *ngIf="basicInformation?.address">
                <td class="contactInformationTableRow"><b>Address:</b></td>
                <td class="contactInformationTableRow">{{basicInformation?.address}}</td>
              </tr>
              <tr *ngIf="basicInformation?.addressStreet">
                <td class="contactInformationTableRow"><b>Address:</b></td>
                <td class="contactInformationTableRow">{{basicInformation?.addressStreet}} {{basicInformation?.addressNumber}},
                  {{basicInformation?.addressZIP}} {{basicInformation?.addressCity}}, {{basicInformation?.addressCountry}}
                </td>
              </tr>
              <tr *ngIf="basicInformation?.vat">
                <td><b>VAT number:</b></td>
                <td>{{basicInformation?.vat}}</td>
              </tr>
              <tr *ngIf="basicInformation?.taxCountry">
                <td class="contactInformationTableRow"><b>Tax country:</b></td>
                <td class="contactInformationTableRow">{{basicInformation?.taxCountry}}</td>
              </tr>
              <tr *ngIf="basicInformation?.contactName">
                <td class="contactInformationTableRow"><b>Contact person name:</b></td>
                <td class="contactInformationTableRow">{{basicInformation?.contactName}}</td>
              </tr>
              <tr *ngIf="basicInformation?.contactEmail">
                <td class="contactInformationTableRow"><b>Contact person email:</b></td>
                <td class="contactInformationTableRow">{{basicInformation?.contactEmail}}</td>
              </tr>
              <tr *ngIf="basicInformation?.contactPhone">
                <td class="contactInformationTableRow"><b>Contact person phone:</b></td>
                <td class="contactInformationTableRow">{{basicInformation?.contactPhone}}</td>
              </tr>
              <tr *ngIf="entityResponse?.data?.phoneNumber">
                <td class="contactInformationTableRow"><b>Phone:</b></td>
                <td class="contactInformationTableRow">{{entityResponse?.data?.phoneNumber}}</td>
              </tr>
              <tr *ngIf="entityResponse?.data?.email">
                <td class="contactInformationTableRow"><b>Email:</b></td>
                <td class="contactInformationTableRow">{{entityResponse?.data?.email}}</td>
              </tr>
          </tbody>
        </table>
        <div class="basic-info heading">
          Entity Information
        </div>
        <table class="table">
          <tbody>
              <tr *ngIf="model?.data?.formData?.telegramName">
                <td class="contactInformationTableRow"><b>Google name:</b></td>
                <td class="contactInformationTableRow">{{model?.data?.formData?.telegramName}}</td>
              </tr>
              <tr *ngIf="model?.data?.formData?.twitterName">
                <td class="contactInformationTableRow"><b>Twitter name:</b></td>
                <td class="contactInformationTableRow">{{model?.data?.formData?.twitterName}}</td>
              </tr>
              <tr *ngIf="model?.data?.formData?.linkedinProfile">
                <td class="contactInformationTableRow"><b>LinkedIn profile:</b></td>
                <td class="contactInformationTableRow">{{model?.data?.formData?.linkedinProfile}}</td>
              </tr>
              <tr *ngIf="model?.data?.formData?.facebookProfile">
                <td class="contactInformationTableRow"><b>Facebook profile:</b></td>
                <td class="contactInformationTableRow">{{model?.data?.formData?.facebookProfile}}</td>
              </tr>
              <tr *ngIf="entityResponse?.data?.owner">
                <td class="contactInformationTableRow"><b>Entity Owner:</b></td>
                <td class="contactInformationTableRow">{{entityResponse?.data?.owner.email}}</td>
              </tr>
              <tr>
                <td class="contactInformationTableRow"><b>Entity Type:</b></td>
                <td class="contactInformationTableRow" style="padding-left: 15px;">
                  <span *ngIf="entityResponse?.data?.entityType.entityType == 'PERSON'"><i title="Physical Person" style="color: black;font-size: 22px;" class="fa fa-male" aria-hidden="true"></i></span>
                  <span *ngIf="entityResponse?.data?.entityType.entityType != 'PERSON'"><i title="Moral Person" style="color: black;font-size: 22px;" class="fa fa-building" aria-hidden="true"></i></span>
                </td> </tr>
              <tr>
                <td class="contactInformationTableRow"><b>Legal Form:</b></td>
                <td>{{entityResponse?.data?.entityType.legalForm}}</td>
              </tr>
              <tr>
                <td class="contactInformationTableRow"><b>Risk Level:</b></td>
                <td>{{totalRiskLevel}}</td>
              </tr>
              <tr>
                <td class="contactInformationTableRow"><b>Last Refresh Date:</b></td>
                <td>{{entityResponse?.data?.lastRefresh}}</td>
              </tr>
              <tr [class.red-highlighted]="entityResponse?.data?.nextRefresh <= timestampTo">
                <td class="contactInformationTableRow"><b>Next Refresh Date:</b></td>
                <td>{{entityResponse?.data?.nextRefresh}}</td>
              </tr>
          </tbody>
        </table>
        <div style="height: 40px;"></div>
        <p class="heading">Comments</p>
        <div class="form-group"  *ngIf="userPermissions.indexOf('UpdateEntity') !== -1">
          <div style="height: 15px"></div>
          <textarea id="comment" type="text" class="form-control" style="max-width: 100%;" name="comment" placeholder="Type new comment here..."
            [(ngModel)]="entityComment"></textarea><br>
          <div style="height: 30 px"></div>
          <button class="btn btn-primary" (click)="saveComment()" [style.background-color]="mainColor">Save
            comment</button>&#160;&#160;&#160; <br>
          <div style="height: 30 px"></div>
        </div>
        <table width="100%" style="width: 353px;word-break: keep-all;">
          <tbody>
            <tr *ngIf="comments?.length > 0">
              <ul style="font-size: 13px;width: 150%;margin-left: -15px;">
                <li *ngFor="let comment of comments | slice:0:7; let i=index">
                  <div *ngIf="comment.autogenerated;else other_content">
                    {{comment?.createdByEmail}}<b>"{{comment?.comment}}"</b> at {{comment?.createdAt |
                      date:'dd/MM/yyyy, h:mm:ss a'}} UTC
                </div>
                
                <ng-template #other_content>{{comment?.createdByEmail}} said <b>"{{comment?.comment}}"</b> at {{comment?.createdAt |
                  date:'dd/MM/yyyy, h:mm:ss a'}} UTC</ng-template></li>
                
                <li style="list-style: none;text-align: left;margin-left: -20px;margin-top: 10px;font-size: 13px;"><a
                    (click)="showComments(showCommentsTemplate)">See all comments</a></li>
              </ul>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="col-sm-6">
        <div class="row" style="margin: 0px;">
          <div style="height: 40px"></div>
          <p class="heading">Assesment actions</p>
          <table class="table" style="overflow-x: hidden;">
            <tbody>
              <tr>
                <td width="20%"><b>Entity status:</b></td>
                <td *ngIf="entityResponse?.data?.entityStatus2E && userPermissions.indexOf('UpdateEntity') !== -1">
                  <select style="color: black;" id="entityStatus2E" class="ng-control" name="entityStatus2E"
                    [(ngModel)]="entityResponse.data.entityStatus2E"
                    (change)="setEntityStatus(entityResponse.data.id,entityResponse.data.entityStatus2E)">
                    <option value="null"></option>
                    <option value="APPLICANT">Applicant</option>
                    <option value="ARCHIVED">Archived</option>
                    <option value="IN_REVIEW">In Review</option>
                    <option value="ACCEPTED">Accepted</option>
                    <option value="NON_COMPLIANT">Non Compliant</option>
                    <option value="FROZEN">Frozen</option>
                    <option value="CLOSED">Closed</option>
                  </select>
                </td>
                <td *ngIf="entityResponse?.data?.entityStatus2E && userPermissions.indexOf('UpdateEntity') === -1">
                  {{entityResponse?.data?.entityStatus2E}}
                </td>
              </tr>
              <tr>
                <td><b>Entity tags:</b></td>
                <td>
                  <ng-select *ngIf="userPermissions.indexOf('UpdateEntity') !== -1" class="custom" [items]="tags" [addTag]="addTagFn" (add)="addTagEvent($event)"
                    (remove)="removeTagEvent($event)" [hideSelected]="true" multiple="true" bindLabel="name"
                    [clearable]="false" [(ngModel)]="selectedTags" style="flex-wrap: wrap;width: 90%">
                  </ng-select>
                  <div *ngIf="userPermissions.indexOf('UpdateEntity') === -1">
                    <ng-container *ngFor="let tag of tags">
                      <span style="font-size: .9em;color: #fff;margin-bottom: 5px;background-color: #007bff;padding: 3px;border-radius: 2px;margin-right: 5px;">
                        {{tag.name}}
                      </span>
                    </ng-container>
                  </div>
                </td>
              </tr>
              <tr>
                <td><b>Dossier:</b></td>
                <td>
                  <span *ngIf="entityResponse?.data?.completeness == 'COMPLETE'"><i title="Completed"
                      style="color: #72C245" class="fa fa-circle" aria-hidden="true"></i> Complete</span>
                  <span *ngIf="entityResponse?.data?.completeness == 'ROLES_COMPLETE'"><i title="Unapproved"
                      style="color: rgb(214, 134, 15)" class="fa fa-circle" aria-hidden="true"></i> Unapproved</span>
                  <span *ngIf="entityResponse?.data?.completeness == 'INCOMPLETE'"><i title="Not completed"
                      style="color: #D60000" class="fa fa-circle" aria-hidden="true"></i> Incomplete</span>
                </td>
              </tr>
              <tr *ngIf="riskAnalysis != null">
                <td><b>Screening:</b></td>
                <td>
                  <span *ngIf="riskAnalysis?.probableMatches?.length > 0"><i title="Needs review" style="color: #FDD32B"
                      class="fa fa-circle" aria-hidden="true"></i> Potential matches to review</span>
                  <span *ngIf="riskAnalysis?.probableMatches?.length == 0"><i title="Nothing to review"
                      style="color: #72C245" class="fa fa-circle" aria-hidden="true"></i> Nothing to review</span>
                </td>
              </tr>
              <tr *ngIf="riskAnalysis != null">
                <td><b>Total risk:</b></td>
                <td [ngSwitch]="totalRiskLevel">
                  <span *ngSwitchCase="'LOW'">
                    <i title="Low Risk" style="color: #72C245" class="fa fa-circle" aria-hidden="true"></i>
                    {{totalRisk}} </span>
                  <span *ngSwitchCase="'MEDIUM'">
                    <i title="Medium Risk" style="color: #FDD32B" class="fa fa-circle" aria-hidden="true"></i>
                    {{totalRisk}} </span>
                  <span *ngSwitchCase="'HIGH'">
                    <i title="High Risk" style="color: rgb(214, 134, 15)" class="fa fa-circle" aria-hidden="true"></i>
                    {{totalRisk}} </span>
                  <span *ngSwitchCase="'REJECTED'">
                    <i title="Unacceptable" style="color: #D60000" class="fa fa-circle" aria-hidden="true"></i>
                    {{totalRisk}} </span>
                </td>
              </tr>
              <tr *ngIf="riskAnalysis != null">
                <td><b>Manual risk:</b></td>
                <td *ngIf="riskAnalysis?.customOverallRisk?.riskStatus == null"><i title="Not applied" style="color: gray"
                    class="fa fa-circle" aria-hidden="true"></i> - Not applied&nbsp;&nbsp; <i *ngIf="userPermissions.indexOf('UpdateRisk') !== -1" class="fa fa-pencil btn-yellow"
                    style="color: #f0ad4e;font-size: 16px;"
                    (click)="showManualTotalRiskAdjustment(manualTotalRiskAdjustmentTemplate)"
                    title="Edit"></i>&nbsp;&nbsp;<i *ngIf="userPermissions.indexOf('DeleteRisk') !== -1" class="fa fa-times btn-delete" style="color: #d9534f;font-size: 16px;"
                    (click)="showRemoveManualRiskAdjustment('Total')" title="Delete"></i>
                </td>
                <td *ngIf="riskAnalysis?.customOverallRisk?.riskStatus != null">
                  <div [ngSwitch]="riskAnalysis?.customOverallRisk?.riskStatus" style="display: inline;">
                    <span *ngSwitchCase="'LOW'">
                      <i title="Low Risk" style="color: #72C245" class="fa fa-circle" aria-hidden="true"></i>
                    </span>
                    <span *ngSwitchCase="'MEDIUM'">
                      <i title="Medium Risk" style="color: #FDD32B" class="fa fa-circle" aria-hidden="true"></i>
                    </span>
                    <span *ngSwitchCase="'HIGH'">
                      <i title="High Risk" style="color: rgb(214, 134, 15)" class="fa fa-circle" aria-hidden="true"></i>
                    </span>
                    <span *ngSwitchCase="'REJECTED'">
                      <i title="Unacceptable" style="color: #D60000" class="fa fa-circle" aria-hidden="true"></i>
                    </span>
                  </div> {{riskAnalysis?.customOverallRisk?.risk}} Applied&nbsp;&nbsp;<i *ngIf="userPermissions.indexOf('UpdateRisk') !== -1" class="fa fa-pencil btn-yellow"
                    style="color: #f0ad4e;font-size: 16px;"
                    (click)="showManualTotalRiskAdjustment(manualTotalRiskAdjustmentTemplate)"
                    title="Edit"></i>&nbsp;&nbsp;<i *ngIf="userPermissions.indexOf('DeleteRisk') !== -1" class="fa fa-times btn-delete" style="color: #d9534f;font-size: 16px;"
                    (click)="showRemoveManualRiskAdjustment('Total')" title="Delete"></i>
                </td>
              </tr>
              <tr *ngIf="riskAnalysis != null">
                <td><b>Manual PEP:</b></td>
                <td *ngIf="riskAnalysis?.customPepRisk?.riskStatus == null"><i title="Not applied" style="color: gray"
                  class="fa fa-circle" aria-hidden="true"></i> - Not applied&nbsp;&nbsp; <i *ngIf="userPermissions.indexOf('UpdateRisk') !== -1" class="fa fa-pencil btn-yellow"
                  style="color: #f0ad4e;font-size: 16px;"
                  (click)="showManualPEPRiskAdjustment(manualTotalRiskAdjustmentTemplate)"
                  title="Edit"></i>&nbsp;&nbsp;<i *ngIf="userPermissions.indexOf('DeleteRisk') !== -1" class="fa fa-times btn-delete" style="color: #d9534f;font-size: 16px;"
                  (click)="showRemoveManualRiskAdjustment('PEP')" title="Delete"></i>
              </td>
              <td *ngIf="riskAnalysis?.customPepRisk?.riskStatus != null">
                <div [ngSwitch]="riskAnalysis?.customPepRisk?.riskStatus" style="display: inline;">
                  <span *ngSwitchCase="'LOW'">
                    <i title="Low Risk" style="color: #72C245" class="fa fa-circle" aria-hidden="true"></i>
                  </span>
                  <span *ngSwitchCase="'MEDIUM'">
                    <i title="Medium Risk" style="color: #FDD32B" class="fa fa-circle" aria-hidden="true"></i>
                  </span>
                  <span *ngSwitchCase="'HIGH'">
                    <i title="High Risk" style="color: rgb(214, 134, 15)" class="fa fa-circle" aria-hidden="true"></i>
                  </span>
                  <span *ngSwitchCase="'REJECTED'">
                    <i title="Unacceptable" style="color: #D60000" class="fa fa-circle" aria-hidden="true"></i>
                  </span>
                </div> {{riskAnalysis?.customPepRisk?.risk}} Applied&nbsp;&nbsp;<i *ngIf="userPermissions.indexOf('UpdateRisk') !== -1" class="fa fa-pencil btn-yellow"
                  style="color: #f0ad4e;font-size: 16px;"
                  (click)="showManualPEPRiskAdjustment(manualTotalRiskAdjustmentTemplate)"
                  title="Edit"></i>&nbsp;&nbsp;<i *ngIf="userPermissions.indexOf('DeleteRisk') !== -1" class="fa fa-times btn-delete" style="color: #d9534f;font-size: 16px;"
                  (click)="showRemoveManualRiskAdjustment('PEP')" title="Delete"></i>
              </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="row" style="margin: 0px;" *ngIf="riskAnalysis != null">
          <div style="height: 20px"></div>
          <p class="heading">Calculated Risk Detail</p>
          <table class="table" style="overflow-x: hidden;">
            <tbody>
              <tr>
                <td><b>Sanctions:</b></td>
                <td [ngSwitch]="riskAnalysis?.sanctionRisk?.riskStatus">
                  <span *ngSwitchCase="'LOW'">
                    <i title="Low Risk" style="color: #72C245" class="fa fa-circle" aria-hidden="true"></i>
                    {{riskAnalysis?.sanctionRisk?.risk}} </span>
                  <span *ngSwitchCase="'MEDIUM'">
                    <i title="Medium Risk" style="color: #FDD32B" class="fa fa-circle" aria-hidden="true"></i>
                    {{riskAnalysis?.sanctionRisk?.risk}} </span>
                  <span *ngSwitchCase="'HIGH'">
                    <i title="High Risk" style="color: rgb(214, 134, 15)" class="fa fa-circle" aria-hidden="true"></i>
                    {{riskAnalysis?.sanctionRisk?.risk}} </span>
                  <span *ngSwitchCase="'REJECTED'">
                    <i title="Unacceptable" style="color: #D60000" class="fa fa-circle" aria-hidden="true"></i>
                    {{riskAnalysis?.sanctionRisk?.risk}} </span>
                </td>
                <td><b>Jurisdictions:</b></td>
                <td [ngSwitch]="riskAnalysis?.jurisdictionalRisk?.riskStatus">
                  <span *ngSwitchCase="'LOW'">
                    <i title="Low Risk" style="color: #72C245" class="fa fa-circle" aria-hidden="true"></i> {{riskAnalysis?.jurisdictionalRisk?.risk}} </span>
                  <span *ngSwitchCase="'MEDIUM'">
                    <i title="Medium Risk" style="color: #FDD32B" class="fa fa-circle" aria-hidden="true"></i> {{riskAnalysis?.jurisdictionalRisk?.risk}} </span>
                  <span *ngSwitchCase="'HIGH'">
                    <i title="High Risk" style="color: rgb(214, 134, 15)" class="fa fa-circle" aria-hidden="true"></i> {{riskAnalysis?.jurisdictionalRisk?.risk}}
                  </span>
                  <span *ngSwitchCase="'REJECTED'">
                    <i title="Unacceptable" style="color: #D60000" class="fa fa-circle" aria-hidden="true"></i> {{riskAnalysis?.jurisdictionalRisk?.risk}}
                  </span>
                </td>
                <td></td>
              </tr>
              <tr>
                <td><b>PEP:</b></td>
                <td [ngSwitch]="riskAnalysis?.pepRisk?.riskStatus">
                  <span *ngSwitchCase="'LOW'">
                    <i title="Low Risk" style="color: #72C245" class="fa fa-circle" aria-hidden="true"></i>
                    {{riskAnalysis?.pepRisk?.risk}} </span>
                  <span *ngSwitchCase="'MEDIUM'">
                    <i title="Medium Risk" style="color: #FDD32B" class="fa fa-circle" aria-hidden="true"></i>
                    {{riskAnalysis?.pepRisk?.risk}} </span>
                  <span *ngSwitchCase="'HIGH'">
                    <i title="High Risk" style="color: rgb(214, 134, 15)" class="fa fa-circle" aria-hidden="true"></i>
                    {{riskAnalysis?.pepRisk?.risk}} </span>
                  <span *ngSwitchCase="'REJECTED'">
                    <i title="Unacceptable" style="color: #D60000" class="fa fa-circle" aria-hidden="true"></i>
                    {{riskAnalysis?.pepRisk?.risk}} </span>
                </td>
                <td><b>Risk Assessment Document Total:</b></td>
                <td [ngSwitch]="riskAnalysis?.totalRisk?.riskStatus">
                  <span *ngSwitchCase="'LOW'">
                    <i title="Low Risk" style="color: #72C245" class="fa fa-circle" aria-hidden="true"></i>
                    {{riskAnalysis?.totalRisk?.risk}}
                  </span>
                  <span *ngSwitchCase="'MEDIUM'">
                    <i title="Medium Risk" style="color: #FDD32B" class="fa fa-circle" aria-hidden="true"></i>
                    {{riskAnalysis?.totalRisk?.risk}}
                  </span>
                  <span *ngSwitchCase="'HIGH'">
                    <i title="High Risk" style="color: rgb(214, 134, 15)" class="fa fa-circle" aria-hidden="true"></i>
                    {{riskAnalysis?.totalRisk?.risk}}
                  </span>
                  <span *ngSwitchCase="'REJECTED'">
                    <i title="Unacceptable" style="color: #D60000" class="fa fa-circle" aria-hidden="true"></i>
                    {{riskAnalysis?.totalRisk?.risk}}
                  </span>
                </td>
                <td></td>
              </tr>
              <tr>
                <td><b>Laundering:</b></td>
                <td [ngSwitch]="riskAnalysis?.moneyLaunderingRisk?.riskStatus">
                  <span *ngSwitchCase="'LOW'">
                    <i title="Low Risk" style="color: #72C245" class="fa fa-circle" aria-hidden="true"></i>
                    {{riskAnalysis?.moneyLaunderingRisk?.risk}} </span>
                  <span *ngSwitchCase="'MEDIUM'">
                    <i title="Medium Risk" style="color: #FDD32B" class="fa fa-circle" aria-hidden="true"></i>
                    {{riskAnalysis?.moneyLaunderingRisk?.risk}} </span>
                  <span *ngSwitchCase="'HIGH'">
                    <i title="High Risk" style="color: rgb(214, 134, 15)" class="fa fa-circle" aria-hidden="true"></i>
                    {{riskAnalysis?.moneyLaunderingRisk?.risk}} </span>
                  <span *ngSwitchCase="'REJECTED'">
                    <i title="Unacceptable" style="color: #D60000" class="fa fa-circle" aria-hidden="true"></i>
                    {{riskAnalysis?.moneyLaunderingRisk?.risk}} </span>
                </td>
                <td><b>Roles:</b></td>
                <td [ngSwitch]="riskAnalysis?.rolesRisk?.riskStatus">
                  <span *ngSwitchCase="'LOW'">
                    <i title="Low Risk" style="color: #72C245" class="fa fa-circle" aria-hidden="true"></i> {{riskAnalysis?.rolesRisk?.risk}} </span>
                  <span *ngSwitchCase="'MEDIUM'">
                    <i title="Medium Risk" style="color: #FDD32B" class="fa fa-circle" aria-hidden="true"></i> {{riskAnalysis?.rolesRisk?.risk}} </span>
                  <span *ngSwitchCase="'HIGH'">
                    <i title="High Risk" style="color: rgb(214, 134, 15)" class="fa fa-circle" aria-hidden="true"></i> {{riskAnalysis?.rolesRisk?.risk}}
                  </span>
                  <span *ngSwitchCase="'REJECTED'">
                    <i title="Unacceptable" style="color: #D60000" class="fa fa-circle" aria-hidden="true"></i> {{riskAnalysis?.rolesRisk?.risk}}
                  </span>
                </td>
                <td></td>
              </tr>
              <tr>
                <td><b>Terrorism:</b></td>
                <td [ngSwitch]="riskAnalysis?.terrorismRisk?.riskStatus">
                  <span *ngSwitchCase="'LOW'">
                    <i title="Low Risk" style="color: #72C245" class="fa fa-circle" aria-hidden="true"></i>
                    {{riskAnalysis?.terrorismRisk?.risk}} </span>
                  <span *ngSwitchCase="'MEDIUM'">
                    <i title="Medium Risk" style="color: #FDD32B" class="fa fa-circle" aria-hidden="true"></i>
                    {{riskAnalysis?.terrorismRisk?.risk}} </span>
                  <span *ngSwitchCase="'HIGH'">
                    <i title="High Risk" style="color: rgb(214, 134, 15)" class="fa fa-circle" aria-hidden="true"></i>
                    {{riskAnalysis?.terrorismRisk?.risk}} </span>
                  <span *ngSwitchCase="'REJECTED'">
                    <i title="Unacceptable" style="color: #D60000" class="fa fa-circle" aria-hidden="true"></i>
                    {{riskAnalysis?.terrorismRisk?.risk}} </span>
                </td>
                <td><b>Contributing role types:</b><br><span
                  style="text-align: center;">
                  <ul>
                    <li *ngFor="let roleType of riskAnalysis.contributingRoleTypes">{{roleType}}</li>
                  </ul>
                </span></td>
                <td></td>
              </tr>
              <tr>
                <td><b>Corruption:</b></td>
                <td [ngSwitch]="riskAnalysis?.corruptionRisk?.riskStatus">
                  <span *ngSwitchCase="'LOW'">
                    <i title="Low Risk" style="color: #72C245" class="fa fa-circle" aria-hidden="true"></i>
                    {{riskAnalysis?.corruptionRisk?.risk}} </span>
                  <span *ngSwitchCase="'MEDIUM'">
                    <i title="Medium Risk" style="color: #FDD32B" class="fa fa-circle" aria-hidden="true"></i>
                    {{riskAnalysis?.corruptionRisk?.risk}} </span>
                  <span *ngSwitchCase="'HIGH'">
                    <i title="High Risk" style="color: rgb(214, 134, 15)" class="fa fa-circle" aria-hidden="true"></i>
                    {{riskAnalysis?.corruptionRisk?.risk}} </span>
                  <span *ngSwitchCase="'REJECTED'">
                    <i title="Unacceptable" style="color: #D60000" class="fa fa-circle" aria-hidden="true"></i>
                    {{riskAnalysis?.corruptionRisk?.risk}} </span>
                </td>
                <td></td>
              </tr>
              <tr>
                <td><b>Reputation:</b></td>
                <td [ngSwitch]="riskAnalysis?.reputationalRisk?.riskStatus">
                  <span *ngSwitchCase="'LOW'">
                    <i title="Low Risk" style="color: #72C245" class="fa fa-circle" aria-hidden="true"></i>
                    {{riskAnalysis?.reputationalRisk?.risk}} </span>
                  <span *ngSwitchCase="'MEDIUM'">
                    <i title="Medium Risk" style="color: #FDD32B" class="fa fa-circle" aria-hidden="true"></i>
                    {{riskAnalysis?.reputationalRisk?.risk}} </span>
                  <span *ngSwitchCase="'HIGH'">
                    <i title="High Risk" style="color: rgb(214, 134, 15)" class="fa fa-circle" aria-hidden="true"></i>
                    {{riskAnalysis?.reputationalRisk?.risk}} </span>
                  <span *ngSwitchCase="'REJECTED'">
                    <i title="Unacceptable" style="color: #D60000" class="fa fa-circle" aria-hidden="true"></i>
                    {{riskAnalysis?.reputationalRisk?.risk}} </span>
                </td>
                <td></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
  <div class="table-responsive" style="overflow-x: hidden;">
    <div class="row">
      <div class="col-sm-12">
        <div style="height: 40px"></div>
        <div *ngIf="displayRolesAndDocumentation" class="heading" style="margin-bottom: 15px;">
          <div>
            Roles and Documentation
            <button class="btn btn-warning"
            (click)="ngOnInit()"
            style="font-size: 13px;padding-top: 3px;padding-bottom: 3px;padding-left: 6px;padding-right: 6px;background-color: transparent"><i
            class="fa fa-refresh" title="Refresh"></i></button>&nbsp;<button class="btn btn-primary btn-evaluate btn-evaluate-all-document"
            *ngIf="allDocuments.length > 0 && displayEvaluateAllDocuments && userPermissions.indexOf('UpdateDocument') !== -1" style="background-color: rgb(73, 197, 222);margin-bottom: 5px;"
            (click)="checkDocuments()">Evaluate all documents</button>
          </div>
          <ng-container *ngIf="displayAddRole && userPermissions.indexOf('CreateRole') !== -1">
            <button (click)="addRole()" class="btn btn-primary add-role btn-add-role">Add Role&nbsp;&nbsp;<i
              class="fa fa-plus" aria-hidden="true"></i>
            </button> 
          </ng-container>
        </div>
        <div *ngFor="let role of rolesDisplay">
          <table width="100%" class="subheading"
            *ngIf="role.e2Instance != null && role.e2Instance != '' && role.e1Instance != entityName">
            <tr>
              <td
                *ngIf="role.roleName == 'residentOf' || role.roleName == 'citizenOf' || role.roleName == 'bornIn' || role.roleName == 'registeredIn' || role.roleName == 'locatedIn' || role.roleName == 'incorporatedIn'; else notGeo"
                style="text-align: left;padding: 5px;padding-left: 10px;">is {{role.roleName | geoRoleName}} <span
                  style="text-decoration: underline;cursor: pointer;"
                  (click)="openEntityDetail(role.e1Id)">{{role.e1Instance}}</span>&nbsp;&nbsp;<button
                  class="btn btn-danger btn-delete btn-delete-document" title="Delete role" (click)="deleteRole(role.id)"
                  *ngIf="userPermissions.indexOf('DeleteRole') !== -1"><i class="fa fa-times"></i></button>
              </td>
              <ng-template #notGeo>
                <td style="text-align: left;padding: 5px;padding-left: 10px;">is {{role.roleName}} of <span
                    style="text-decoration: underline;cursor: pointer;"
                    (click)="openEntityDetail(role.e1Id)">{{role.e1Instance}}</span>&nbsp;&nbsp;<button
                    class="btn btn-danger btn-delete btn-delete-document" title="Delete role" (click)="deleteRole(role.id)"
                    *ngIf="userPermissions.indexOf('DeleteRole') !== -1"><i class="fa fa-times"></i></button>
                </td>
              </ng-template>
              <td *ngIf="displayAddGeneralDocument && userPermissions.indexOf('CreateDocument') !== -1" style="text-align: right;padding: 10px 5px 5px 5px"><button class="btn btn-success btn-save-information"
                  style="margin-bottom: 5px;margin-left: 10px;padding: 4px;font-size: 13px;"
                  (click)="addDocumentToRole(role)">Add General Document&nbsp;&nbsp;<i class="fa fa-plus"
                    aria-hidden="true"></i></button></td>
            </tr>
          </table>
          <table width="100%" class="subheading"
            *ngIf="role.e2Instance != null && role.e2Instance != '' && role.e1Instance == entityName">
            <tr>
              <td
                *ngIf="(role.roleName == 'residentOf' || role.roleName == 'citizenOf' || role.roleName == 'bornIn' || role.roleName == 'registeredIn' || role.roleName == 'locatedIn' || role.roleName == 'incorporatedIn') && role.e2Type == 'GEO'; else notGeo2"
                style="text-align: left;padding: 5px;padding-left: 10px;">is {{role.roleName | geoRoleName}} <span
                  style="text-decoration: underline;cursor: pointer;">{{role.e2Instance}}</span>&nbsp;&nbsp;<button
                  class="btn btn-danger btn-delete btn-delete-document" title="Delete role" (click)="deleteRole(role.id)"
                  *ngIf="userPermissions.indexOf('DeleteRole') !== -1"><i class="fa fa-times"></i></button>
              </td>
              <ng-template #notGeo2>
                <td style="text-align: left;padding: 5px;padding-left: 10px;">has {{role.roleName}} <span
                    style="text-decoration: underline;cursor: pointer;"
                    (click)="openEntityDetail(role.e2Id)">{{role.e2Instance}}</span>&nbsp;&nbsp;<button
                    class="btn btn-danger btn-delete btn-delete-document" title="Delete role" (click)="deleteRole(role.id)"
                    *ngIf="userPermissions.indexOf('DeleteRole') !== -1"><i class="fa fa-times"></i></button>
                </td>
              </ng-template>
              <td *ngIf="displayAddGeneralDocument && userPermissions.indexOf('CreateDocument') !== -1" style="text-align: right;padding: 10px 5px 5px 5px"><button class="btn btn-success btn-save-information"
                  style="margin-bottom: 5px;margin-left: 10px;padding: 4px;font-size: 13px;"
                  (click)="addDocumentToRole(role)">Add General Document&nbsp;&nbsp;<i class="fa fa-plus"
                    aria-hidden="true"></i></button></td>
            </tr>
          </table>
          <table width="100%" class="subheading" *ngIf="role.e2Instance == null || role.e2Instance == ''">
            <tr>
              <td style="text-align: left;padding: 5px;padding-left: 10px;">{{role.roleName}}&nbsp;&nbsp;<button
                  class="btn btn-danger btn-delete btn-delete-document" title="Delete role" (click)="deleteRole(role.id)"
                  *ngIf="userPermissions.indexOf('DeleteRole') !== -1"><i class="fa fa-times"></i></button>
              </td>
              <td *ngIf="displayAddGeneralDocument && userPermissions.indexOf('CreateDocument') !== -1" style="text-align: right;padding: 10px 5px 5px 5px"><button class="btn btn-success btn-save-information"
                  style="margin-bottom: 5px;margin-left: 10px;padding: 4px;font-size: 13px;"
                  (click)="addDocumentToRole(role)">Add General Document&nbsp;&nbsp;<i class="fa fa-plus"
                    aria-hidden="true"></i></button></td>
              <td *ngIf="displayControlPanel" style="text-align: right;padding: 10px 5px 5px 5px"><button class="btn btn-success btn-save-information"
                style="margin-bottom: 5px;margin-left: 10px;padding: 4px;font-size: 13px;" [disabled]="controlloLoading"
                (click)="controlloOK()">Controllo OK&nbsp;&nbsp;</button><button class="btn btn-danger btn-delete"
                style="margin-bottom: 5px;margin-left: 10px;padding: 4px;font-size: 13px;" [disabled]="controlloLoading"
                (click)="controlloKO()">Controllo KO&nbsp;&nbsp;</button></td>
            </tr>
          </table>
          <div style="margin-top: -15px;font-size: 14px;width: 100%" *ngIf="role?.documents?.length > 0">
            <table width="100%" *ngIf="role.documents.length > 0">
              <thead>
                <th title="Document ID" style="border-left: none;padding-left: 10px;">ID</th>
                <th title="Parent ID" style="border-left: none;padding-left: 10px;">Parent ID</th>
                <th title="Document description">{{ "DESCRIPTION" | translate }}</th>
                <th *ngIf="displayValidUntil" title="Document expiration date">Valid until</th>
                <th title="Document format">Format</th>
                <th style="text-align: center;" title="Document status">{{ "STATUS" | translate }}</th>
                <th style="text-align: center;" title="Document risk level">{{ "RISK" | translate }}</th>
                <th></th>
              </thead>
              <tbody>
                <tr *ngFor="let document of role.documents">
                  <ng-container *ngIf="document.status != 'REPLACED' && document.status != 'DELETED' && document.status != 'EMPTY'">
                    <ng-container *ngIf="(displayPersonalContactFormAndData && document.description == 'Personal contact form and data') || (displayMRZ && document.description.includes('MRZ')) || (document.description != 'Personal contact form and data' && !document.description.includes('MRZ'))">
                      <td *ngIf="document.expired == true" style="padding: 7px;color: red">&nbsp;{{document.documentId}}</td>
                      <td *ngIf="document.expired == false" style="padding: 7px;">&nbsp;{{document.documentId}}</td>
                      <td *ngIf="document.expired == true" style="padding: 7px;color: red">&nbsp;{{document.parentDocumentId}}</td>
                      <td *ngIf="document.expired == false" style="padding: 7px;">&nbsp;{{document.parentDocumentId}}</td>
                      <td *ngIf="document.expired == true"
                        style="padding: 7px;color: red;"><i
                          class="fa fa-exclamation-triangle" title="Expired document"
                          aria-hidden="true"></i>{{document.description}}</td>
                      <td *ngIf="document.expired == false" style="padding: 7px;">
                        {{document.description}}</td>
                      <td *ngIf="displayValidUntil && document.expired == false" style="padding: 7px;">{{document.expires |
                        date:'dd/MM/yyyy'}}</td>
                      <td *ngIf="displayValidUntil && document.expired == true" style="padding: 7px;color: red;">{{document.expires
                        | date:'dd/MM/yyyy'}}</td>
                      <td *ngIf="document.expired == false" style="padding: 7px;">{{document.mimeType}}</td>
                      <td *ngIf="document.expired == true" style="padding: 7px;color: red;">
                        {{document.mimeType}}</td>
                      <td [ngSwitch]="document.status" style="padding: 7px;text-align: center;">
                        <i *ngSwitchCase="'UNEVALUATED'" title="Unevaluated" style="color: #bdbdbd" class="fa fa-circle"
                          aria-hidden="true"></i>
                        <i *ngSwitchCase="'ACCEPTED'" title="Accepted" style="color: #72C245" class="fa fa-circle"
                          aria-hidden="true"></i>
                        <i *ngSwitchCase="'ESCALATED'" title="Escalated" style="color: #FDD32B" class="fa fa-circle"
                          aria-hidden="true"></i>
                        <i *ngSwitchCase="'REJECTED'" title="Rejected" style="color: #D60000" class="fa fa-circle"
                          aria-hidden="true"></i>
                      </td>
                      <td [ngSwitch]="document.riskLevel" style="padding: 7px;text-align: center;">
                        <i *ngSwitchCase="null" title="Unevaluated" style="color: #bdbdbd" class="fa fa-circle"
                        aria-hidden="true"></i>
                        <i *ngSwitchCase="'LOW'" title="Low" style="color: #72C245" class="fa fa-circle"
                          aria-hidden="true"></i>
                        <i *ngSwitchCase="'MEDIUM'" title="Medium" style="color: #FDD32B" class="fa fa-circle"
                          aria-hidden="true"></i>
                        <i *ngSwitchCase="'HIGH'" title="High" style="color: rgb(214, 134, 15)" class="fa fa-circle"
                          aria-hidden="true"></i>
                        <i *ngSwitchCase="'REJECTED'" title="Rejected" style="color: #D60000" class="fa fa-circle"
                          aria-hidden="true"></i>
                      </td>
                      <td style="display: flex; grid-row-gap: 10px; justify-content: end; align-items: center; padding: 7px;text-align: left;width: auto;">
                        <ng-container *ngIf="userPermissions.indexOf('UpdateDocument') !== -1" [ngSwitch]="document.status">
                          <button class="btn btn-warning btn-evaluate btn-yellow" *ngSwitchCase="'UNEVALUATED'"
                            (click)="viewDocument(document.documentId,document.mimeType,document.name,document.contextualData)">{{ "EVALUATE" | translate }}</button>
                          <button class="btn btn-warning btn-evaluate btn-yellow" *ngSwitchCase="'ESCALATED'"
                            (click)="viewDocument(document.documentId,document.mimeType,document.name,document.contextualData)">{{ "EVALUATE" | translate }}</button>
                          <button class="btn btn-warning btn-evaluate btn-yellow" *ngSwitchCase="'REJECTED'"
                            (click)="viewDocument(document.documentId,document.mimeType,document.name,document.contextualData)">{{ "EVALUATE" | translate }}</button>
                          <button class="btn btn-success btn-save-information btn-evaluate" *ngSwitchCase="'ACCEPTED'"
                            (click)="viewDocument(document.documentId,document.mimeType,document.name,document.contextualData)">{{ "EVALUATE" | translate }}</button>
                          <button class="btn btn-warning btn-evaluate btn-yellow" *ngSwitchCase="'EXPIRED'"
                            (click)="viewDocument(document.documentId,document.mimeType,document.name,document.contextualData)">{{ "EVALUATE" | translate }}</button>
                        </ng-container>&nbsp;&nbsp;
                        <ng-container *ngIf="displayReplaceDocument && userPermissions.indexOf('UpdateDocument') !== -1" [ngSwitch]="document.expired">
                          <button *ngSwitchCase="false" class="btn btn-warning btn-yellow" [disabled]="document.mimeType === 'application/json'"
                            (click)="replaceDocumentTypeForRole(role.id,document.documentId,document.documentTypeId)"
                            style="font-size: 13px;padding-top: 3px;padding-bottom: 3px;padding-left: 6px;padding-right: 6px;"><i
                              class="fa fa-refresh" title="Replace document"></i></button>
                          <button *ngSwitchCase="true" class="btn btn-warning btn-yellow" [disabled]="document.mimeType === 'application/json'"
                            (click)="replaceDocumentTypeForRole(role.id,document.documentId,document.documentTypeId)"
                            style="font-size: 13px;padding-top: 3px;padding-bottom: 3px;padding-left: 6px;padding-right: 6px;background-color: rgb(251, 107, 73);"><i
                              class="fa fa-refresh" title="Replace expired document"></i></button>
                              &nbsp;&nbsp;
                        </ng-container>
                        <button *ngIf="displayEditDocument && userPermissions.indexOf('UpdateDocument') !== -1" class="btn btn-warning btn-yellow"
                          (click)="editJsonDocument(role.id,document.documentId,document.documentTypeId,role.e1Id,role.e2Id)"
                          style="font-size: 13px;padding-top: 3px;padding-bottom: 3px;padding-left: 6px;padding-right: 6px;"><i
                          class="fa fa-pencil" title="Edit document"></i>
                        </button>&nbsp;&nbsp;&nbsp;
                        <button class="btn btn-danger btn-delete btn-delete-document" title="Delete document"
                          (click)="deleteDocument(document)"
                          *ngIf="document.expired == false && displayDeleteDocument && userPermissions.indexOf('DeleteDocument') !== -1"><i class="fa fa-times"></i></button>
                      </td>
                    </ng-container>
                  </ng-container>
                </tr>
              </tbody>
            </table>
            <div style="height: 10px;"></div>
          </div>
          <table width="100%">
            <tr>
              <td>
                <div style="height: 10px;"></div>
              </td>
            </tr>
            <ng-container *ngFor="let task of toDoListResponse?.data?.todos; let i = index">
              <tr>
                <td *ngIf="task.entityId == role.e1Id && task.roleTypeId == 0 && task.roleId == role.id"
                  style="font-size: 14px;color: red;padding-left: 10px;"> TODO: {{task?.message}} </td>
                <td
                  *ngIf="task.entityId == role.e1Id && task.roleTypeId == 0 && task.roleId == role.id && userPermissions.indexOf('CreateDocument') !== -1"
                  style="text-align: right;padding-bottom: 5px;padding-right: 5px;">
                  <button
                    *ngIf="isAddThisDocumentEntityId(toDoListResponse?.data?.todos[i - 1], toDoListResponse?.data?.todos[i], toDoListResponse?.data?.todos[i + 1])"
                    class="btn btn-success btn-save-information"
                    style="padding: 4px; font-size: 13px;"
                    (click)="addDocumentTypeToRole(i, toDoListResponse?.data?.todos, false)">Add This Document&nbsp;&nbsp;
                    <i class="fa fa-plus" aria-hidden="true"></i>
                  </button>
                  <button
                    *ngIf="isAddDocumentEntityId(toDoListResponse?.data?.todos[i - 1], toDoListResponse?.data?.todos[i], toDoListResponse?.data?.todos[i + 1])"
                    class="btn btn-success add-document btn-save-information"
                    [class.last-add-document]="(toDoListResponse?.data?.todos[i + 1] && toDoListResponse?.data?.todos[i + 1].entityId !== role.e1Id) || !toDoListResponse?.data?.todos[i + 1]"
                    style="padding: 4px; font-size: 13px;"
                    (click)="addDocumentTypeToRole(i, toDoListResponse?.data?.todos, true)">Add Document&nbsp;&nbsp;
                    <i class="fa fa-plus" aria-hidden="true"></i>
                  </button>
                </td>
              </tr>
              <span *ngIf="task.entityId == role.e1Id && task.roleTypeId == 0 && task.roleId == role.id && toDoListResponse?.data?.todos[i + 1] &&
                toDoListResponse?.data?.todos[i + 1].entityId == role.e1Id && toDoListResponse?.data?.todos[i + 1].roleTypeId == 0 && toDoListResponse?.data?.todos[i + 1].roleId == role.id &&
                toDoListResponse?.data?.todos[i].documentGroup === toDoListResponse?.data?.todos[i + 1].documentGroup" class="separator">
                - OR -
              </span>
            </ng-container>
            <ng-container *ngFor="let task of toDoListResponse?.data?.todos; let i = index">
              <tr>
                <td
                  *ngIf="role.id == task.roleId && task.entityId == -1 && task.roleTypeId == 0 && task.documentTypeId != 0"
                  style="font-size: 14px;color: red;padding-left: 10px;"> TODO: {{task?.message}} </td>
                <td
                  *ngIf="role.id == task.roleId && task.entityId == -1 && task.roleTypeId == 0 && task.documentTypeId != 0 && userPermissions.indexOf('CreateDocument') !== -1"
                  style="text-align: right;padding-bottom: 5px;padding-right: 5px;">
                  <button
                    *ngIf="isAddThisDocumentRoleId(toDoListResponse?.data?.todos[i - 1], toDoListResponse?.data?.todos[i], toDoListResponse?.data?.todos[i + 1])"
                    class="btn btn-success btn-save-information"
                    style="padding: 4px; font-size: 13px;"
                    (click)="addDocumentTypeToRole(i, toDoListResponse?.data?.todos, false)">Add This Document&nbsp;&nbsp;
                    <i class="fa fa-plus" aria-hidden="true"></i>
                  </button>
                  <button
                    *ngIf="isAddDocumentRoleId(toDoListResponse?.data?.todos[i - 1], toDoListResponse?.data?.todos[i], toDoListResponse?.data?.todos[i + 1])"
                    class="btn btn-success add-document btn-save-information"
                    [class.last-add-document]="(toDoListResponse?.data?.todos[i + 1] && toDoListResponse?.data?.todos[i + 1].entityId !== role.e1Id) || !toDoListResponse?.data?.todos[i + 1]"
                    style="padding: 4px; font-size: 13px;"
                    (click)="addDocumentTypeToRole(i, toDoListResponse?.data?.todos, true)">Add Document&nbsp;&nbsp;
                    <i class="fa fa-plus" aria-hidden="true"></i>
                  </button>
                </td>
              </tr>
              <span *ngIf="role.id == task.roleId && task.entityId == -1 && task.roleTypeId == 0 && task.documentTypeId != 0 && toDoListResponse?.data?.todos[i + 1] &&
                role.id == toDoListResponse?.data?.todos[i + 1].roleId && toDoListResponse?.data?.todos[i + 1].entityId == -1 && toDoListResponse?.data?.todos[i + 1].roleTypeId == 0 && toDoListResponse?.data?.todos[i + 1].documentTypeId != 0 &&
                toDoListResponse?.data?.todos[i].documentGroup === toDoListResponse?.data?.todos[i + 1].documentGroup" class="separator">
                - OR -
              </span>
            </ng-container>
          </table>
          <table width="100%">
            <tr>
              <td>
                <div style="height: 10px;"></div>
              </td>
            </tr>
            <tr *ngFor="let task of toDoListResponse?.data?.todos">
              <td
                *ngIf="task.entityId != -1 && task.entityId == role.e1Id && task.roleTypeId != 0 && role.e2Id == undefined"
                style="font-size: 14px;color: red;padding-bottom: 5px;padding-left: 10px;"> TODO: {{task?.message}}
              </td>
              <td
                *ngIf="task.entityId != -1 && task.entityId == role.e1Id && task.roleTypeId != 0 && role.e2Id == undefined && userPermissions.indexOf('CreateRole') !== -1"
                style="text-align: right;padding-bottom: 5px;padding-right: 5px;"><button class="btn btn-success btn-add-role" (click)="addRoleToEntity(task.entityId,task.roleTypeId)">Add Role&nbsp;&nbsp;<i class="fa fa-plus"
                    aria-hidden="true"></i></button></td>
            </tr>
          </table>
        </div>
      </div>
    </div>
    <div style="height: 10px;"></div>
    <div class="row" *ngIf="entityType == 'LEGAL'">
      <div class="row">
        <div class="col-sm-12" *ngIf="entityType == 'LEGAL'">
          <div style="height: 40px"></div>
          <p class="heading" style="padding-left: 25px;">UBO Chart</p>
          <div id="newChart">
            <ngx-org-chart [nodes]="nodes" direction="vertical" (itemClick)="navigateToDetails($event)"></ngx-org-chart>
          </div>
          <div *ngIf="emptyUBO">
            <p style="text-align: center;font-family: Arial;font-size: 14px;">There is no UBO information to show.</p>
          </div>
        </div>
      </div>
      <div style="height: 10px;"></div>
      <p class="heading" style="padding-left: 25px;">Control Chart</p>
      <div class="row">
        <div class="col-sm-12">
          <div style="height: 40px"></div>
          <div id="newControlChart">
            <ngx-org-chart [nodes]="controlNodes" direction="vertical" (itemClick)="navigateToDetails($event)"></ngx-org-chart>
          </div>
          <div *ngIf="emptyControl">
            <p style="text-align: center;font-family: Arial;font-size: 14px;">There is no Control information to show.
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="row" *ngIf="entityType != 'LEGAL'">
      <div class="col-sm-12">
        <div style="height: 40px"></div>
        <p class="heading">Relationships</p>
        <div [chart]="chart"></div>
      </div>
    </div>
    <div class="row" *ngIf="riskAnalysis != null">
      <div class="col-sm-12">
        <div style="height: 40px"></div>
        <p class="heading">Screening Results</p>
        <div class="table-responsive">
          <div style="height: 20px;"></div>
          <app-matches (moveEvent)="receiveEvent($event)" [entityId]="username" style="width: 90%"></app-matches>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12">
        <div style="height: 40px"></div>
        <p class="heading">Event Log</p>
        <div class="table-responsive">
          <table class="table">
            <tr *ngFor="let event of eventLog" style="border-bottom: 0.5px solid lightgray;font-size: 12px;">
              <td style="text-align: left;cursor: pointer;">
                <b>[{{event.title}}]</b> {{event.message}}
              </td>
              <td style="text-align: right;">{{event.timestamp | date:'dd/MM/yyyy, h:mm:ss a'}} UTC</td>
            </tr>
            <tr>
              <td>
                <div style="height: 10px;"></div>
              </td>
            </tr>
            <tr>
              <td><b>DISCLAIMER: These are only the 30 latest events. If you would like to see more, please use the <a
                    [routerLink]="['/reporting']">Activity Log Report</a> functionality.</b></td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>
