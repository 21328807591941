import { OnDestroy } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ApiService } from '../api.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../api.service";
var BrandingService = /** @class */ (function () {
    function BrandingService(http, apiService) {
        this.http = http;
        this.apiService = apiService;
        this._data = new BehaviorSubject(null);
        this.brandingSetup = this._data.asObservable();
        this.subs = [];
    }
    BrandingService.prototype.getBranding = function () {
        var _this = this;
        var sub = this.apiService.get('branding').subscribe(function (res) {
            _this._data.next(res.data);
            _this.processBranding(res.data);
        });
        this.subs.push(sub);
    };
    BrandingService.prototype.setBranding = function (brandingSetup) {
        return this.apiService.put('branding', brandingSetup);
    };
    BrandingService.prototype.processBranding = function (b) {
        document.documentElement.style
            .setProperty('--mainColor', b.mainColor);
    };
    BrandingService.prototype.ngOnDestroy = function () {
        for (var _i = 0, _a = this.subs; _i < _a.length; _i++) {
            var s = _a[_i];
            s.unsubscribe();
        }
    };
    BrandingService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function BrandingService_Factory() { return new BrandingService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.ApiService)); }, token: BrandingService, providedIn: "root" });
    return BrandingService;
}());
export { BrandingService };
