import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { AlertService, CallsService, DataService, KYBService } from '../../../../../_services';
import { Observable, Subscription } from 'rxjs';
import { Document, Entity, Role, RoleUpdate } from '../../../../../_models/kyb';
import { Router } from '@angular/router';
import { config } from '../../../../../../assets/configuration';

const CryptoJS = require('crypto-js');

/**
 * Implements batch user importing (by uploading and parsing a csv file with user details) - not currently used
*/
@Component({
    moduleId: module.id,
    selector: 'app-person-form',
    templateUrl: 'personForm.component.html',
    styleUrls: ['./personForm.component.css']
})

export class PersonFormComponent implements OnInit, OnDestroy {

  @Input() currentEntity: any;
  selfRoleId: any;
  entityType: any;
  entityStatus: any;
  primaryName = '';
  dateOfBirth = '';
  address = '';
  nationality = '';
  entityTypes: any = [];
  entityTypesResponse: any = {};
  autoLinkedEntitiesString = '';
  isocountries: any = config.isocountries;
  nationalities: any = config.nationalities;
  loadingImg: any = config.loadingImg;
  loading = false;

  delegateName = '';
  delegateEmail = '';
  entity: any = {};
  entityPlaceholder: any = {};
  currentEntityLevel: any;
  currentEntityId: any;
  currentRole: any;
  currentStack: any;
  delegates: any;
  roleStructure: any;
  role: Role;
  currentToDo: any;
  tags: any = [];
  personPhone: any;
  personEmail: any;

  identityDocument: Document;
  proofOfResidenceDocument: Document;
  document1Response: any = {};
  document2Response: any = {};
  document3Response: any = {};
  documentIds: any = [];
  newEntity: Entity;
  selfRole: Role;
  entityResponse: any = {};
  roleResponse: any = {};
  nationalityFilled = false;
  roleTypeResponse: any = {};

  document: Document;
  formDataFile: File;
  documentResponse: any = {};
  documentId: any;
  getRoleResponse: any = {};
  updatedRole: RoleUpdate;
  additionalDocumentIds: any = [];
  requiredDocumentIds: any = [];
  additionalDocumentTypes: any = [];
  requiredDocumentTypes: any = [];

  private eventsSubscription: Subscription;

  @Input() events: Observable<any>;

  constructor(
    private callService: CallsService,
    private dataService: DataService,
    private kybService: KYBService,
    private alertService: AlertService,
    private router: Router
  ) {}

  ngOnInit() {
    this.loading = false;

    this.eventsSubscription = this.events.subscribe(() => {
      this.primaryName = this.dataService.primaryName.getValue();
      this.personPhone = this.dataService.personPhone.getValue();
      this.personEmail = this.dataService.personEmail.getValue();

      this.save();
    });

    this.dataService.currentSelfRoleId.subscribe(
      message => {
        this.selfRoleId = message;
      }
    );

    this.dataService.currentEntityType.subscribe(
      message => {
        this.entityType = message;
      }
    );

    this.dataService.currentEntityStatus.subscribe(
      message => {
        this.entityStatus = message;
      }
    );

    this.dataService.currentEntityTags.subscribe(
      message => {
        this.tags = message;
      }
    );

    this.dataService.currentToDo.subscribe(message => this.currentToDo = message);

    this.kybService.getEntityTypes().subscribe(
      data => {
        this.entityTypesResponse = data;
        this.entityTypes = this.entityTypesResponse.data;

        for (let i = 0; i < this.entityTypes.length; i++) {
          if (this.entityTypes[i].id === this.entityType) {
            this.autoLinkedEntitiesString = this.entityTypes[i].autoLinkedEntities;
            for (let j = 0; j < this.entityTypes[i].requiredRoles.requiredE1Roles.length; j++) {
              if (this.entityTypes[i].requiredRoles.requiredE1Roles[j].roleType.self === true) {
                this.dataService.setSelfRoleId(this.entityTypes[i].requiredRoles.requiredE1Roles[j].roleType.id);
              }
            }
          }
        }
      }
    );

    this.dataService.currentRoleStructure.subscribe(message => this.roleStructure = message);

    this.dataService.currentEntityId.subscribe(message => {
      this.currentEntityId = message;
    });

    this.dataService.currentRole.subscribe(message => {
      this.currentRole = message;
    });

    this.dataService.stack.subscribe(
      message => {
        this.currentStack = message;
      }
    );

    if (this.currentEntity !== undefined) {
      this.primaryName = this.currentEntity.primaryName;
      this.dateOfBirth = this.currentEntity.dateOfBirth;
      this.address = this.currentEntity.address;
      this.nationality = this.currentEntity.nationality;
    }
  }

  isNationalityFilled() {
    this.nationalityFilled = true;
  }

  save() {
    this.loading = true;
    this.newEntity = new Entity();
    this.newEntity.entityTypeId = this.entityType;
    this.newEntity.primaryName = this.primaryName;
    this.newEntity.roles = [];
    this.newEntity.delegateEmail = null;
    this.newEntity.entityStatus = this.entityStatus;
    this.newEntity.phoneNumber = this.personPhone;
    this.newEntity.email = this.personEmail;
    const tagIds = [];

    if (this.tags !== null) {
      for (let i = 0; i < this.tags.length; i++) {
        tagIds.push(this.tags[i].id);
      }
    }

    this.newEntity.tagIds = tagIds;
    this.newEntity.ownerId = localStorage.getItem('identifier');

    this.kybService.createEntity(this.newEntity).subscribe(
        data => {
          this.entityResponse = data;

          this.kybService.getToDoList(this.entityResponse.data.entityId).subscribe(
            () => {},
            error => {
            }
          );

          if (this.currentStack !== undefined) {
            // tslint:disable-next-line:max-line-length
            this.currentStack.entities.push({ 'primaryName': this.primaryName, 'type': 'person', 'delegated': false, 'id': this.entityResponse.data.entityId });
          }

          this.loading = false;
          this.dataService.triggerCloseModalEvent(22);
          this.dataService.triggerRefreshEvent(23);
          this.nationalityFilled = false;
          this.alertService.showSuccess('Entity created.');
        },
        error => {
          this.loading = false;
          this.alertService.showError(error.error.fieldErrors[0].message);
        }
    );
  }

  reset() {
    this.primaryName = '';
    this.dateOfBirth = '';
    this.address = '';
    this.nationality = '';
  }

  validate() {
    if (this.primaryName !== '') {
      return true;
    } else {
      return false;
    }
  }

  delegate() {
    this.delegates.push({ name: this.delegateName, email: this.delegateEmail, entity: this.primaryName });
    this.newEntity = new Entity();
    this.newEntity.entityTypeId = 1;
    this.newEntity.primaryName = this.primaryName;
    this.newEntity.roles = [];
    this.newEntity.delegateEmail = this.delegateEmail;
    this.newEntity.ownerId = CryptoJS.SHA256(this.delegateEmail).toString(CryptoJS.enc.Hex);

    this.kybService.createEntity(this.newEntity).subscribe(
        data => {
          window.alert('Entity successfully delegated. You will be redirected to the login page.');
          this.router.navigate(['/login']);
        },
        error => {}
    );
  }

  validateDelegation() {
    if (this.primaryName !== '' && this.delegateName !== '' && this.delegateEmail !== '') {
      return true;
    } else {
      return false;
    }
  }

  ngOnDestroy() {
    this.eventsSubscription.unsubscribe();
  }

}
