import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { UserList, DocumentMetadataUpdate, UserPermissions } from '../_models';

/**
 * Implements API calls to the backend.
 */
@Injectable()
export class KYBService {

  private httpOptions = {};
  createFormdata: FormData;
  formData: FormData;
  exportFormData: FormData;

  constructor(private http: HttpClient) {}

  createEntity(entity: any) {
    this.httpOptions = {
      headers: new HttpHeaders()
        .append('Content-Type', 'application/json')
        .append('X-Auth-Token', JSON.parse(localStorage.getItem('auth_token')))
    };

    return this.http.post('api/v3/entity', entity, this.httpOptions);
  }

  getEntity(entityId: any) {
    this.httpOptions = {
      headers: new HttpHeaders()
        .append('Content-Type', 'application/json')
        .append('X-Auth-Token', JSON.parse(localStorage.getItem('auth_token')))
    };

    return this.http.get('api/v3/entity/' + entityId, this.httpOptions);
  }

  getEntityTags(entityId: any) {
    this.httpOptions = {
      headers: new HttpHeaders()
        .append('Content-Type', 'application/json')
        .append('X-Auth-Token', JSON.parse(localStorage.getItem('auth_token')))
    };

    return this.http.get(`api/v3/entity/${entityId}/tag`, this.httpOptions);
  }

  getRole(roleId: any) {
    this.httpOptions = {
      headers: new HttpHeaders()
        .append('Content-Type', 'application/json')
        .append('X-Auth-Token', JSON.parse(localStorage.getItem('auth_token')))
    };

    return this.http.get('api/v3/role/' + roleId, this.httpOptions);
  }

  getAllowableRoleTypes(entityId: any) {
    this.httpOptions = {
      headers: new HttpHeaders()
        .append('Content-Type', 'application/json')
        .append('X-Auth-Token', JSON.parse(localStorage.getItem('auth_token')))
    };

    return this.http.get('api/v3/role/' + entityId + '/allowableRoleTypes', this.httpOptions);
  }

  getExpiredDocumentsForUser(page: number, size: number) {
    this.httpOptions = {
      headers: new HttpHeaders()
        .append('Content-Type', 'application/json')
        .append('X-Auth-Token', JSON.parse(localStorage.getItem('auth_token')))
    };

    return this.http.get('api/v3/document/details?documentStatus=EXPIRED' + '&page=' + page + '&size=' + size, this.httpOptions);
  }

  getProbableMatchesForUser(page: number, size: number) {
    this.httpOptions = {
      headers: new HttpHeaders()
        .append('Content-Type', 'application/json')
        .append('X-Auth-Token', JSON.parse(localStorage.getItem('auth_token')))
    };

    return this.http.get('api/v3/match/details?matchStatus=PROBABLE' + '&page=' + page + '&size=' + size, this.httpOptions);
  }

  getRoleTypes() {
    this.httpOptions = {
      headers: new HttpHeaders()
        .append('Content-Type', 'application/json')
        .append('X-Auth-Token', JSON.parse(localStorage.getItem('auth_token')))
    };

    return this.http.get('api/v3/role/types', this.httpOptions);
  }

  getV4RoleTypes() {
    this.httpOptions = {
      headers: new HttpHeaders()
        .append('Content-Type', 'application/json')
        .append('X-Auth-Token', JSON.parse(localStorage.getItem('auth_token')))
    };

    return this.http.get('api/v4/role/types', this.httpOptions);
  }

  getV4RoleTypesById(id: number) {
    this.httpOptions = {
      headers: new HttpHeaders()
        .append('Content-Type', 'application/json')
        .append('X-Auth-Token', JSON.parse(localStorage.getItem('auth_token')))
    };

    return this.http.get('api/v4/role/types/' + id, this.httpOptions);
  }

  createRoleType(roleType: any) {
    this.httpOptions = {
      headers: new HttpHeaders()
        .append('Content-Type', 'application/json')
        .append('X-Auth-Token', JSON.parse(localStorage.getItem('auth_token')))
    };

    return this.http.post('api/v4/role/types', roleType, this.httpOptions);
  }

  createV4RoleType(body: any) {
    this.httpOptions = {
      headers: new HttpHeaders()
        .append('Content-Type', 'application/json')
        .append('X-Auth-Token', JSON.parse(localStorage.getItem('auth_token')))
    };

    return this.http.post(
      'api/v4/role/types',
      body,
      this.httpOptions
    );
  }

  updateRoleType(roleType: any) {
    this.httpOptions = {
      headers: new HttpHeaders()
        .append('Content-Type', 'application/json')
        .append('X-Auth-Token', JSON.parse(localStorage.getItem('auth_token')))
    };

    return this.http.put(
      'api/v3/role/types/' + roleType.id,
      roleType,
      this.httpOptions
    );
  }

  updateV4RoleType(body: any) {
    this.httpOptions = {
      headers: new HttpHeaders()
        .append('Content-Type', 'application/json')
        .append('X-Auth-Token', JSON.parse(localStorage.getItem('auth_token')))
    };

    return this.http.put(
      'api/v4/role/types/' + body.id,
      body,
      this.httpOptions
    );
  }

  getDocumentTypes() {
    this.httpOptions = {
      headers: new HttpHeaders()
        .append('Content-Type', 'application/json')
        .append('X-Auth-Token', JSON.parse(localStorage.getItem('auth_token')))
    };

    return this.http.get('api/v3/document/types', this.httpOptions);
  }

  createDocumentType(documentType: any) {
    this.httpOptions = {
      headers: new HttpHeaders()
        .append('Content-Type', 'application/json')
        .append('X-Auth-Token', JSON.parse(localStorage.getItem('auth_token')))
    };

    return this.http.post(
      'api/v3/document/types',
      documentType,
      this.httpOptions
    );
  }

  createConnectionDocument(connectionRequest: any) {
    this.httpOptions = {
      headers: new HttpHeaders()
        .append('Content-Type', 'application/json')
        .append('X-Auth-Token', JSON.parse(localStorage.getItem('auth_token')))
    };

    return this.http.post(
      'api/v3/document/connection-document',
      connectionRequest,
      this.httpOptions
    );
  }

  updateDocumentType(documentType: any) {
    this.httpOptions = {
      headers: new HttpHeaders()
        .append('Content-Type', 'application/json')
        .append('X-Auth-Token', JSON.parse(localStorage.getItem('auth_token')))
    };

    return this.http.put(
      'api/v3/document/types/' + documentType.id,
      documentType,
      this.httpOptions
    );
  }

  getEntityTypes() {
    this.httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }).append(
        'X-Auth-Token',
        JSON.parse(localStorage.getItem('auth_token'))
      )
    };

    return this.http.get('api/v3/entity/types', this.httpOptions);
  }

  /**
  * Function to set up periodical batch screening
  * @param {any} periodicity - how often the screening should happen
  * @param {any} includeScreeningOnWeekend - do you want to include weekends in the screening days
  * @param {any} maxAgeOfRecordToBeConsideredValidInMonths - if the record is older than this number, it will not be considered
  * @param {any} nationalityMatch - do you want to match the nationality as well
  * @param {any} confidenceThreshold - the lower the threshold, the higher the possibility of false positives
  */
  remoteBatch(periodicity: any, includeScreeningOnWeekend: any, maxAgeOfRecordToBeConsideredValidInMonths: any,
    confidenceThreshold: any, massScreening: boolean, stopwords: string) {
    this.httpOptions = {
      headers: new HttpHeaders().append('Content-Type', 'application/json').append('X-Auth-Token', JSON.parse(localStorage.getItem('auth_token')))
    };

    return this.http.post('/api/v3/remoteBatch', {
      periodicity: periodicity,
      confidenceThreshold: confidenceThreshold,
      nationalityMatch: false,
      maxAgeOfRecordToBeConsideredValidInMonths: maxAgeOfRecordToBeConsideredValidInMonths,
      includeScreeningOnWeekend: includeScreeningOnWeekend,
      massiveScreening: massScreening,
      stopwords: stopwords
    }, this.httpOptions);
  }

  /**
  * Function for getting the current batch screening settings
  */
  getRemoteBatch() {
    this.httpOptions = {
      headers: new HttpHeaders().append('Content-Type', 'application/json').append('X-Auth-Token', JSON.parse(localStorage.getItem('auth_token')))
    };

    return this.http.get('/api/v3/remoteBatch', this.httpOptions);
  }

  /**
  * Function for adding a screening job - needs to be called when changing remote batch settings
  */
  addScreeningJob() {
    this.httpOptions = {
      headers: new HttpHeaders().append('Content-Type', 'application/json').append('X-Auth-Token', JSON.parse(localStorage.getItem('auth_token')))
    };

    return this.http.post('/api/v3/remoteBatch/screening/job', {}, this.httpOptions);
  }

  createEntityType(entityType: any) {
    this.httpOptions = {
      headers: new HttpHeaders()
        .append('Content-Type', 'application/json')
        .append('X-Auth-Token', JSON.parse(localStorage.getItem('auth_token')))
    };

    return this.http.post('api/v3/entity/types', entityType, this.httpOptions);
  }

  updateEntityType(entityType: any) {
    this.httpOptions = {
      headers: new HttpHeaders()
        .append('Content-Type', 'application/json')
        .append('X-Auth-Token', JSON.parse(localStorage.getItem('auth_token')))
    };

    return this.http.put(
      'api/v3/entity/types/' + entityType.id,
      entityType,
      this.httpOptions
    );
  }

  getRolesForEntity(entityId: any) {
    this.httpOptions = {
      headers: new HttpHeaders()
        .append('Content-Type', 'application/json')
        .append('X-Auth-Token', JSON.parse(localStorage.getItem('auth_token')))
    };

    return this.http.get('api/v3/role/' + entityId + '/list', this.httpOptions);
  }

  createUser(user: any) {
    return this.http.post('api/v3/user', {
      email: user.email,
      password: user.password
    });
  }

  fetchToken(user: any) {
    this.httpOptions = {
      headers: new HttpHeaders().append('Content-Type', 'application/json')
    };

    return this.http.post(
      'api/v3/auth',
      { email: user.email, password: user.password },
      this.httpOptions
    );
  }

  checkUserStatus(email: any) {
    this.httpOptions = {
      headers: new HttpHeaders()
        .append('Content-Type', 'application/json')
        .append('X-Auth-Token', JSON.parse(localStorage.getItem('auth_token')))
    };

    return this.http.get('api/v3/user/status/' + email, this.httpOptions);
  }

  validateRepresentative() {
    this.httpOptions = {
      headers: new HttpHeaders()
        .append('Content-Type', 'application/json')
        .append('X-Auth-Token', JSON.parse(localStorage.getItem('auth_token')))
    };

    return this.http.get('api/v3/entity/validate-representative', this.httpOptions);
  }

  packageMetadata(packageId: any) {
    this.httpOptions = {
      headers: new HttpHeaders()
        .append('Content-Type', 'application/json')
        .append('X-Auth-Token', JSON.parse(localStorage.getItem('auth_token')))

    };

    return this.http.get('api/v3/public/packages/' + packageId + '/metadata', this.httpOptions);
  }

  getIPInfo(): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'text/plain; charset=utf-8');

    return this.http.get('https://www.cloudflare.com/cdn-cgi/trace', { headers, responseType: 'text' });
  }

  createDocument(document: any, entityId: any, roleId: any, e1Id: any, e2Id: any) {
    if (entityId !== null) {
      document.entityId = entityId;
    }

    if (roleId !== null) {
      document.roleId = roleId;
    }

    if (e1Id !== null) {
      document.e1Id = e1Id;
    } else {
      document.e1Id = null;
    }

    if (e2Id !== null) {
      document.e2Id = e2Id;
    } else {
      document.e2Id = null;
    }

    this.httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }).append(
        'X-Auth-Token',
        JSON.parse(localStorage.getItem('auth_token'))
      )
    };

    return this.http.post('api/v3/document', document, this.httpOptions);
  }

  createRole(role: any) {
    this.httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }).append(
        'X-Auth-Token',
        JSON.parse(localStorage.getItem('auth_token'))
      )
    };

    return this.http.post('api/v3/role', role, this.httpOptions);
  }

  saveFile(document: File, documentId: any, fileId: any) {
    this.httpOptions = {
      headers: new HttpHeaders({
        'X-Auth-Token': JSON.parse(localStorage.getItem('auth_token'))
      })
    };

    this.formData = new FormData();

    this.formData.append('file', document);

    return this.http.put(
      'api/v3/document/' + documentId + '/file/' + fileId,
      this.formData,
      this.httpOptions
    );
  }

  saveFileNoId(document: File, documentId: any) {
    this.httpOptions = {
      headers: new HttpHeaders({
        'X-Auth-Token': JSON.parse(localStorage.getItem('auth_token'))
      })
    };

    this.formData = new FormData();

    this.formData.append('file', document);

    return this.http.post(
      'api/v3/document/' + documentId + '/file',
      this.formData,
      this.httpOptions
    );
  }

  getFile(documentId: any, fileId: any) {
    this.httpOptions = {
      headers: new HttpHeaders({
        'X-Auth-Token': JSON.parse(localStorage.getItem('auth_token'))
      })
    };

    return this.http.get(
      'api/v3/document/' + documentId + '/file/' + fileId,
      this.httpOptions
    );
  }

  getDocument(documentId: any) {
    this.httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }).append(
        'X-Auth-Token',
        JSON.parse(localStorage.getItem('auth_token'))
      )
    };

    return this.http.get(
      'api/v3/document/' + documentId + '?type=1',
      this.httpOptions
    );
  }

  getRelationships(entityId: any) {
    this.httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }).append(
        'X-Auth-Token',
        JSON.parse(localStorage.getItem('auth_token'))
      )
    };

    return this.http.get(
      'api/v3/role/' + entityId + '/relationship-graph',
      this.httpOptions
    );
  }

  getUBOGraph(entityId: any, graphType: any) {
    this.httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }).append(
        'X-Auth-Token',
        JSON.parse(localStorage.getItem('auth_token'))
      )
    };

    return this.http.get(
      'api/v3/role/' + entityId + '/ubo-control-graph?graphType=' + graphType,
      this.httpOptions
    );
  }

  getAllEntities(entityListingParameters: any) {
    this.httpOptions = {
      headers: new HttpHeaders()
        .append('Content-Type', 'application/json')
        .append('X-Auth-Token', JSON.parse(localStorage.getItem('auth_token')))
    };

    return this.http.post(
      'api/v3/entity/list',
      {
        entityStatus: entityListingParameters.entityStatus,
        havingProbableMatches: entityListingParameters.havingProbableMatches,
        ownerId: entityListingParameters.ownerId
      },
      this.httpOptions
    );
  }

  getAllEntitiesPaged(size: any, page: any, sort: any, searchQuery: any, includedTags: any,
    excludedTags: any, returnOnlyGeoEntities: any, skipGeoEntities: any, owner: string, allEntities?: any, entityStatus2E?: any) {
    this.httpOptions = {
      headers: new HttpHeaders()
        .append('Content-Type', 'application/json')
        .append('X-Auth-Token', JSON.parse(localStorage.getItem('auth_token')))
    };

    let url = 'api/v3/entity/listing' + '?size=' + size + '&page=' + page;

    if (sort !== null) {
      url += '&sort=' + sort;
    }

    if (searchQuery !== null) {
      url += '&name=' + searchQuery;
    }

    if (includedTags !== null) {
      url += '&includedTags=' + includedTags;
    }

    if (excludedTags !== null) {
      url += '&excludedTags=' + excludedTags;
    }

    if (returnOnlyGeoEntities !== null) {
      url += '&returnOnlyGeoEntities=' + returnOnlyGeoEntities;
    }

    if (skipGeoEntities !== null) {
      url += '&skipGeoEntities=' + skipGeoEntities;
    }

    if (owner !== null) {
      url += '&ownerEmail=' + owner;
    }

    if (allEntities != null) {
      url += '&allEntities=' + allEntities;
    }

    if (entityStatus2E != null) {
      url += '&entityStatus2E=' + entityStatus2E;
    }

    return this.http.get(
      url,
      this.httpOptions
    );
  }

  confirmRefresh(entityId: number, notes: string) {
    this.httpOptions = {
      headers: new HttpHeaders()
        .append('Content-Type', 'application/json')
        .append('X-Auth-Token', JSON.parse(localStorage.getItem('auth_token')))
    };

    return this.http.put(
      'api/v3/entity/' + entityId + '/refresh',
      { notes },
      this.httpOptions
    );
  }

  closeSomeNotification(id: number, notes: string) {
    this.httpOptions = {
      headers: new HttpHeaders()
        .append('Content-Type', 'application/json')
        .append('X-Auth-Token', JSON.parse(localStorage.getItem('auth_token')))
    };

    return this.http.put(
      'api/v3/notifications/' + id,
      { notes },
      this.httpOptions
    );
  }

  getNotificationTypes() {
    this.httpOptions = {
      headers: new HttpHeaders()
        .append('Content-Type', 'application/json')
        .append('X-Auth-Token', JSON.parse(localStorage.getItem('auth_token')))
    };

    return this.http.get(
      'api/v3/notification-types',
      this.httpOptions
    );
  }

  getNotificationsForUser(userId: any, size: any, page: any, sort: any, filters?: any) {
    this.httpOptions = {
      headers: new HttpHeaders()
        .append('Content-Type', 'application/json')
        .append('X-Auth-Token', JSON.parse(localStorage.getItem('auth_token')))
    };

    let url = 'api/v3/users/' + userId + '/notifications' + '?size=' + size + '&page=' + page;

    if (sort !== null) {
      url += '&sort=' + sort;
    }

    return this.http.post(
      url,
      {
        entityOwner: filters.entityOwner,
        notificationType: filters.notificationType,
        entityName: filters.entityName
      },
      this.httpOptions
    );
  }

  getNotificationsForCurrentUser(size: any, page: any, sort: any, filters?: any) {
    this.httpOptions = {
      headers: new HttpHeaders()
        .append('Content-Type', 'application/json')
        .append('X-Auth-Token', JSON.parse(localStorage.getItem('auth_token')))
    };

    let url = 'api/v3/users/self/notifications' + '?size=' + size + '&page=' + page;

    if (sort !== null) {
      url += '&sort=' + sort;
    }

    return this.http.post(
      url,
      {
        entityOwner: filters.entityOwner,
        notificationType: filters.notificationType,
        entityName: filters.entityName
      },
      this.httpOptions
    );
  }

  getAllNotifications(size: any, page: any, sort: any, filters?: any) {
    this.httpOptions = {
      headers: new HttpHeaders()
        .append('Content-Type', 'application/json')
        .append('X-Auth-Token', JSON.parse(localStorage.getItem('auth_token')))
    };

    let url = 'api/v3/notifications' + '?size=' + size + '&page=' + page;

    if (sort !== null) {
      url += '&sort=' + sort;
    }

    return this.http.post(
      url,
      {
        entityOwner: filters.entityOwner,
        notificationType: filters.notificationType,
        entityName: filters.entityName
      },
      this.httpOptions
    );
  }

  getAllGeoEntities(): any {
    this.httpOptions = {
      headers: new HttpHeaders()
        .append('Content-Type', 'application/json')
        .append('X-Auth-Token', JSON.parse(localStorage.getItem('auth_token')))
    };

    return this.http.get(
      'api/v3/geoEntity/list',
      this.httpOptions
    );
  }

  getAllUsers(): Observable<UserList> {
    this.httpOptions = {
      headers: new HttpHeaders()
        .append('Content-Type', 'application/json')
        .append('X-Auth-Token', JSON.parse(localStorage.getItem('auth_token')))
    };

    return this.http.get<UserList>('api/v3/user/list', this.httpOptions);
  }

  getUserPermissions(): Observable<UserPermissions> {
    this.httpOptions = {
      headers: new HttpHeaders()
        .append('Content-Type', 'application/json')
        .append('X-Auth-Token', JSON.parse(localStorage.getItem('auth_token')))
    };

    return this.http.get<UserPermissions>('api/v3/user/permissions', this.httpOptions);
  }

  getCurrentUser() {
    this.httpOptions = {
      headers: new HttpHeaders()
        .append('Content-Type', 'application/json')
        .append('X-Auth-Token', JSON.parse(localStorage.getItem('auth_token')))
    };

    return this.http.get('api/v3/user', this.httpOptions);
  }

  changeUserRole(userId: any, newRole: any) {
    this.httpOptions = {
      headers: new HttpHeaders()
        .append('Content-Type', 'application/json')
        .append('X-Auth-Token', JSON.parse(localStorage.getItem('auth_token')))
    };

    return this.http.put(
      'api/v3/user/' + userId,
      { role: newRole },
      this.httpOptions
    );
  }

  updateUserPassword(userId: any, newPassword: any) {
    this.httpOptions = {
      headers: new HttpHeaders()
        .append('Content-Type', 'application/json')
        .append('X-Auth-Token', JSON.parse(localStorage.getItem('auth_token')))
    };

    return this.http.put(
      'api/v3/user/' + userId,
      { password: newPassword },
      this.httpOptions
    );
  }

  updateUser(userId: any, updatedUser: any) {
    this.httpOptions = {
      headers: new HttpHeaders()
        .append('Content-Type', 'application/json')
        .append('X-Auth-Token', JSON.parse(localStorage.getItem('auth_token')))
    };

    return this.http.put(
      'api/v3/user/' + userId,
      updatedUser,
      this.httpOptions
    );
  }

  getAllRoles() {
    this.httpOptions = {
      headers: new HttpHeaders()
        .append('Content-Type', 'application/json')
        .append('X-Auth-Token', JSON.parse(localStorage.getItem('auth_token')))
    };

    return this.http.get('api/v3/role/list', this.httpOptions);
  }

  getEmailSettings() {
    this.httpOptions = {
      headers: new HttpHeaders()
        .append('Content-Type', 'application/json')
        .append('X-Auth-Token', JSON.parse(localStorage.getItem('auth_token')))
    };

    return this.http.get('api/v3/settings/email-server-credentials', this.httpOptions);
  }

  getEntitiesByOwnerId(ownerId: any) {
    this.httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }).append(
        'X-Auth-Token',
        JSON.parse(localStorage.getItem('auth_token'))
      )
    };

    return this.http.post(
      'api/v3/entity/list',
      { ownerId: ownerId },
      this.httpOptions
    );
  }

  updateRole(updatedRole: any) {
    this.httpOptions = {
      headers: new HttpHeaders()
        .append('Content-Type', 'application/json')
        .append('X-Auth-Token', JSON.parse(localStorage.getItem('auth_token')))
    };

    return this.http.put('api/v3/role', updatedRole, this.httpOptions);
  }

  updateEmailSettings(host: any, username: any, password: any, from: any) {
    this.httpOptions = {
      headers: new HttpHeaders()
        .append('Content-Type', 'application/json')
        .append('X-Auth-Token', JSON.parse(localStorage.getItem('auth_token')))
    };

    const updatedSettings = { 'type': 'JSON', 'value': JSON.stringify({ 'host': host, 'port': 587,
      'username': username, 'password': password, 'from': from }), 'hiddenInWebUi': false };

    return this.http.put('api/v3/settings/email-server-credentials', updatedSettings, this.httpOptions);
  }

  getAllSettings() {
    this.httpOptions = {
      headers: new HttpHeaders()
        .append('Content-Type', 'application/json')
        .append('X-Auth-Token', JSON.parse(localStorage.getItem('auth_token')))
    };

    return this.http.get('api/v3/settings', this.httpOptions);
  }

  getLowRiskConfig() {
    this.httpOptions = {
      headers: new HttpHeaders()
        .append('Content-Type', 'application/json')
        .append('X-Auth-Token', JSON.parse(localStorage.getItem('auth_token')))
    };

    return this.http.get('api/v3/settings/low_risk_dossier_refresh_period', this.httpOptions);
  }

  updateLowRiskConfig(lowRiskConfig: any) {
    this.httpOptions = {
      headers: new HttpHeaders()
        .append('Content-Type', 'application/json')
        .append('X-Auth-Token', JSON.parse(localStorage.getItem('auth_token')))
    };

    const updatedSettings = { type: lowRiskConfig.type, value: lowRiskConfig.value };

    return this.http.put('api/v3/settings/low_risk_dossier_refresh_period', updatedSettings, this.httpOptions);
  }

  getMediumRiskConfig() {
    this.httpOptions = {
      headers: new HttpHeaders()
        .append('Content-Type', 'application/json')
        .append('X-Auth-Token', JSON.parse(localStorage.getItem('auth_token')))
    };

    return this.http.get('api/v3/settings/medium_risk_dossier_refresh_period', this.httpOptions);
  }

  updateMediumRiskConfig(mediumRiskConfig: any) {
    this.httpOptions = {
      headers: new HttpHeaders()
        .append('Content-Type', 'application/json')
        .append('X-Auth-Token', JSON.parse(localStorage.getItem('auth_token')))
    };

    const updatedSettings = { type: mediumRiskConfig.type, value: mediumRiskConfig.value };

    return this.http.put('api/v3/settings/medium_risk_dossier_refresh_period', updatedSettings, this.httpOptions);
  }

  getHighRiskConfig() {
    this.httpOptions = {
      headers: new HttpHeaders()
        .append('Content-Type', 'application/json')
        .append('X-Auth-Token', JSON.parse(localStorage.getItem('auth_token')))
    };

    return this.http.get('api/v3/settings/high_risk_dossier_refresh_period', this.httpOptions);
  }

  updateHighRiskConfig(highRiskConfig: any) {
    this.httpOptions = {
      headers: new HttpHeaders()
        .append('Content-Type', 'application/json')
        .append('X-Auth-Token', JSON.parse(localStorage.getItem('auth_token')))
    };

    const updatedSettings = { type: highRiskConfig.type, value: highRiskConfig.value };

    return this.http.put('api/v3/settings/high_risk_dossier_refresh_period', updatedSettings, this.httpOptions);
  }

  updateEntity(updatedEntity: any) {
    this.httpOptions = {
      headers: new HttpHeaders()
        .append('Content-Type', 'application/json')
        .append('X-Auth-Token', JSON.parse(localStorage.getItem('auth_token')))
    };

    return this.http.put(
      'api/v3/entity/' + updatedEntity.entityId,
      updatedEntity,
      this.httpOptions
    );
  }

  sendOnboardingInvitation(entityId: any, sendEmail: any, sendSMS: any) {
    let options = {};

    if (sendEmail === null) {
      options = {
        'sendEmail': sendEmail,
        'sendSMS': sendSMS
      };
    } else if (sendSMS === null) {
      options = {
        'sendEmail': sendEmail,
        'sendSMS': sendSMS
      };
    } else {
      options = {
        'sendEmail': sendEmail,
        'sendSMS': sendSMS
      };
    }

    this.httpOptions = {
      headers: new HttpHeaders()
        .append('Content-Type', 'application/json')
        .append('X-Auth-Token', JSON.parse(localStorage.getItem('auth_token')))
    };

    return this.http.patch(
      'api/v3/entity/' + entityId + '/send-invitation', options,
      this.httpOptions
    );
  }

  getRiskAnalysisForEntity(entityId: any) {
    this.httpOptions = {
      headers: new HttpHeaders()
        .append('Content-Type', 'application/json')
        .append('X-Auth-Token', JSON.parse(localStorage.getItem('auth_token')))
    };

    return this.http.get('api/v3/risk/forEntity/' + entityId, this.httpOptions);
  }

  getDashboardStatistics(tags: any) {
    this.httpOptions = {
      headers: new HttpHeaders()
        .append('Content-Type', 'application/json')
        .append('X-Auth-Token', JSON.parse(localStorage.getItem('auth_token')))
    };

    return this.http.post('api/v3/dashboard', tags === null ? {} : {tags}, this.httpOptions);
  }

  /**
   * Function for adding a screening plan - needs to be called when changing remote batch settings
   */
  addScreeningPlan() {
    this.httpOptions = {
      headers: new HttpHeaders()
        .append('Content-Type', 'application/json')
        .append('X-Auth-Token', JSON.parse(localStorage.getItem('auth_token')))
    };

    return this.http.post(
      '/api/v3/remoteBatch/screening/plan',
      {},
      this.httpOptions
    );
  }

  moveMatch(entityId: any, entryId: any, newMatchStatus: any) {
    this.httpOptions = {
      headers: new HttpHeaders()
        .append('Content-Type', 'application/json')
        .append('X-Auth-Token', JSON.parse(localStorage.getItem('auth_token')))
    };

    return this.http.put(
      '/api/v3/risk/forEntity/' + entityId + '/matches/' + entryId,
      { entityId: entityId, entryId: entryId, newMatchStatus: newMatchStatus },
      this.httpOptions
    );
  }

  getAllRisk(size: any, page: any, sort: any, searchQuery: any, matchStatus: any, includedTags: any, excludedTags: any) {
    this.httpOptions = {
      headers: new HttpHeaders()
        .append('Content-Type', 'application/json')
        .append('X-Auth-Token', JSON.parse(localStorage.getItem('auth_token')))
    };

    let url = 'api/v3/risk' + '?size=' + size + '&page=' + page;

    if (sort !== null) {
      url += '&sort=' + sort;
    }

    if (searchQuery !== null) {
      url += '&name=' + searchQuery;
    }

    if (matchStatus !== null) {
      url += '&matchStatus=' + matchStatus;
    }

    if (includedTags !== null) {
      url += '&includedTags=' + includedTags;
    }

    if (excludedTags !== null) {
      url += '&excludedTags=' + excludedTags;
    }

    return this.http.get(
      url,
      this.httpOptions
    );
  }

  getDocumentMetadata(documentId: any) {
    this.httpOptions = {
      headers: new HttpHeaders()
        .append('Content-Type', 'application/json')
        .append('X-Auth-Token', JSON.parse(localStorage.getItem('auth_token')))
    };

    return this.http.get(
      '/api/v3/document/' + documentId + '/metadata',
      this.httpOptions
    );
  }

  updateDocumentMetadata(documentId: number, comment: string, documentStatus: string, description: string,
    userId: string, deleteFiles: boolean, validTo?: string) {
    this.httpOptions = {
      headers: new HttpHeaders()
        .append('Content-Type', 'application/json')
        .append('X-Auth-Token', JSON.parse(localStorage.getItem('auth_token')))
    };

    const documentMetadataUpdate = new DocumentMetadataUpdate();

    if (comment !== null) {
      documentMetadataUpdate.comment = comment;
    }

    if (documentStatus !== null) {
      documentMetadataUpdate.documentStatus = documentStatus;
    }

    if (description !== null) {
      documentMetadataUpdate.description = description;
    }

    if (documentId !== null) {
      documentMetadataUpdate.documentId = documentId;
    }

    if (userId !== null) {
      documentMetadataUpdate.userId = userId;
    }

    if (deleteFiles !== null) {
      documentMetadataUpdate.deleteFiles = deleteFiles;
    }

    if (validTo !== null) {
      documentMetadataUpdate.validTo = validTo;
    }

    return this.http.put(
      '/api/v3/document/' + documentId + '/metadata',
      documentMetadataUpdate,
      this.httpOptions
    );
  }

  getEventsPaginated(size: any, page: any, sort: any, happenedAfter: any, happenedBefore: any, createdBy: any, entityId: any) {
    this.httpOptions = {
      headers: new HttpHeaders()
        .append('Content-Type', 'application/json')
        .append('X-Auth-Token', JSON.parse(localStorage.getItem('auth_token')))
    };

    let url = 'api/v3/logs' + '?pageSize=' + size + '&pageNumber=' + page;

    if (sort !== null) {
      url += '&sort=' + sort;
    }

    if (happenedAfter !== null) {
      url += '&happenedAfter=' + happenedAfter;
    }

    if (happenedBefore !== null) {
      url += '&happenedBefore=' + happenedBefore;
    }

    if (createdBy !== null) {
      url += '&createdByEmail=' + createdBy;
    }

    if (entityId !== null) {
      url += '&entityId=' + entityId;
    }

    return this.http.get(
      url,
      this.httpOptions
    );
  }

  getMatchStatsBetweenDates(from: any, to: any, includedTags: any, excludedTags: any) {
    this.httpOptions = {
      headers: new HttpHeaders()
        .append('Content-Type', 'application/json')
        .append('X-Auth-Token', JSON.parse(localStorage.getItem('auth_token')))
    };

    let url = '/api/v3/match/report?from=' + from + '&to=' + to;

    if (includedTags !== null) {
      url += '&includedTags=' + includedTags;
    }

    if (excludedTags !== null) {
      url += '&excludedTags=' + excludedTags;
    }

    return this.http.get(url,
      this.httpOptions
    );
  }

  getCountryRisk(countryCode: any) {
    this.httpOptions = {
      headers: new HttpHeaders().append('X-Api-Key', 'hossamarkusesgeht')
    };

    return this.http.get(
      '/api/v3/risk/countryRisk/' + countryCode,
      this.httpOptions
    );
  }

  getOrphanedEntities(entityId: any) {
    this.httpOptions = {
      headers: new HttpHeaders()
        .append('Content-Type', 'application/json')
        .append('X-Auth-Token', JSON.parse(localStorage.getItem('auth_token')))
    };

    return this.http.get(
      '/api/v3/role/orphaned-entities-by/' + entityId,
      this.httpOptions
    );
  }

  deleteEntity(entityId: any) {
    this.httpOptions = {
      headers: new HttpHeaders()
        .append('Content-Type', 'application/json')
        .append('X-Auth-Token', JSON.parse(localStorage.getItem('auth_token')))
    };

    return this.http.delete('/api/v3/entity/' + entityId, this.httpOptions);
  }

  deleteRole(roleId: any) {
    this.httpOptions = {
      headers: new HttpHeaders()
        .append('Content-Type', 'application/json')
        .append('X-Auth-Token', JSON.parse(localStorage.getItem('auth_token')))
    };

    return this.http.delete('/api/v3/role/' + roleId, this.httpOptions);
  }

  updateMatchNote(entityId: any, entryId: any, note: any) {
    this.httpOptions = {
      headers: new HttpHeaders()
        .append('Content-Type', 'application/json')
        .append('X-Auth-Token', JSON.parse(localStorage.getItem('auth_token')))
    };

    return this.http.put(
      '/api/v3/risk/forEntity/' + entityId + '/matches/' + entryId + '/note',
      { entityId: entityId, entryId: entryId, note: note },
      this.httpOptions
    );
  }

  ownershipTransfer(parameters: any) {
    this.httpOptions = {
      headers: new HttpHeaders()
        .append('Content-Type', 'application/json')
        .append('X-Auth-Token', JSON.parse(localStorage.getItem('auth_token')))
    };

    return this.http.post(
      'api/v3/entity/ownership-transfer',
      parameters,
      this.httpOptions
    );
  }

  getVideoAuth(selfieVideo: any, wordList: any) {
    this.httpOptions = {
      headers: new HttpHeaders()
        .append('X-Auth-Token', JSON.parse(localStorage.getItem('auth_token')))
    };

    this.formData = new FormData();

    this.formData.append('selfieVideo', selfieVideo);
    this.formData.append('wordList', wordList);

    return this.http.post(
      'api/v3/document/video',
      this.formData,
      this.httpOptions
    );
  }

  getMediaScreeningSetings() {
    this.httpOptions = {
      headers: new HttpHeaders()
        .append('Content-Type', 'application/json')
        .append('X-Auth-Token', JSON.parse(localStorage.getItem('auth_token')))
    };

    return this.http.get('/api/v3/remoteBatch/media', this.httpOptions);
  }

  updateMediaScreeningSettings(config: any) {
    this.httpOptions = {
      headers: new HttpHeaders()
        .append('Content-Type', 'application/json')
        .append('X-Auth-Token', JSON.parse(localStorage.getItem('auth_token')))
    };

    return this.http.post('api/v3/remoteBatch/media', config, this.httpOptions);
  }

  listScreeningRisk() {
    this.httpOptions = {
      headers: new HttpHeaders()
        .append('Content-Type', 'application/json')
        .append('X-Auth-Token', JSON.parse(localStorage.getItem('auth_token')))
    };

    return this.http.get(
      '/api/v3/remoteBatch/screening-risk',
      this.httpOptions
    );
  }

  saveScreeningRisk(screeningRisk: any) {
    this.httpOptions = {
      headers: new HttpHeaders()
        .append('Content-Type', 'application/json')
        .append('X-Auth-Token', JSON.parse(localStorage.getItem('auth_token')))
    };

    return this.http.post(
      'api/v3/remoteBatch/screening-risk',
      screeningRisk,
      this.httpOptions
    );
  }

  getScreeningRiskById(id: any) {
    this.httpOptions = {
      headers: new HttpHeaders()
        .append('Content-Type', 'application/json')
        .append('X-Auth-Token', JSON.parse(localStorage.getItem('auth_token')))
    };

    return this.http.get(
      '/api/v3/remoteBatch/screening-risk/' + id,
      this.httpOptions
    );
  }

  getEntityByName(primaryName: any) {
    this.httpOptions = {
      headers: new HttpHeaders()
        .append('Content-Type', 'application/json')
        .append('X-Auth-Token', JSON.parse(localStorage.getItem('auth_token')))
    };

    return this.http.get(
      '/api/v3/entity/by-name/' +
      primaryName +
      '?primaryName=' +
      primaryName,
      this.httpOptions
    );
  }

  getSearchEngineQueries() {
    this.httpOptions = {
      headers: new HttpHeaders()
        .append('Content-Type', 'application/json')
        .append('X-Auth-Token', JSON.parse(localStorage.getItem('auth_token')))
    };

    return this.http.get(
      '/api/v3/remoteBatch/search-engine-query',
      this.httpOptions
    );
  }

  getSearchEngineScreeningConfig() {
    this.httpOptions = {
      headers: new HttpHeaders()
        .append('Content-Type', 'application/json')
        .append('X-Auth-Token', JSON.parse(localStorage.getItem('auth_token')))
    };

    return this.http.get('/api/v3/remoteBatch/search-engine', this.httpOptions);
  }

  setSearchEngineScreeningConfig(config: any) {
    this.httpOptions = {
      headers: new HttpHeaders()
        .append('Content-Type', 'application/json')
        .append('X-Auth-Token', JSON.parse(localStorage.getItem('auth_token')))
    };

    return this.http.post(
      'api/v3/remoteBatch/search-engine',
      { searchEngineScreeningInclusiveness: config },
      this.httpOptions
    );
  }

  saveSearchEngineQuery(config: any) {
    this.httpOptions = {
      headers: new HttpHeaders()
        .append('Content-Type', 'application/json')
        .append('X-Auth-Token', JSON.parse(localStorage.getItem('auth_token')))
    };

    return this.http.post(
      'api/v3/remoteBatch/search-engine-query',
      config,
      this.httpOptions
    );
  }

  createUserWithRole(user: any) {
    this.httpOptions = {
      headers: new HttpHeaders()
        .append('Content-Type', 'application/json')
        .append('X-Auth-Token', JSON.parse(localStorage.getItem('auth_token')))
    };

    return this.http.post('api/v3/user/with-role', user, this.httpOptions);
  }

  deleteSearchEngineQuery(id: any) {
    this.httpOptions = {
      headers: new HttpHeaders()
        .append('Content-Type', 'application/json')
        .append('X-Auth-Token', JSON.parse(localStorage.getItem('auth_token')))
    };

    return this.http.delete(
      '/api/v3/remoteBatch/search-engine-query/' + id,
      this.httpOptions
    );
  }

  updateAssessedRisk(entityId: any, assessedRisk: any, pepRisk: any) {
    const inputData = {};
    const userId = localStorage.getItem('username');

    inputData['entityId'] = entityId;
    inputData['userId'] = userId;

    if (pepRisk !== null) {
      inputData['customPepRisk'] = pepRisk;
    }

    if (assessedRisk !== null) {
      inputData['customAssessedRisk'] = assessedRisk;
    }

    this.httpOptions = {
      headers: new HttpHeaders()
        .append('Content-Type', 'application/json')
        .append('X-Auth-Token', JSON.parse(localStorage.getItem('auth_token')))
    };

    return this.http.put(
      'api/v3/risk/forEntity/' + entityId + '/assessed-risk', inputData,
      this.httpOptions
    );
  }

  renameEntity(entityId: any, entityTypeId: any, primaryName: any, newPrimary: any) {
    this.httpOptions = {
      headers: new HttpHeaders()
        .append('Content-Type', 'application/json')
        .append('X-Auth-Token', JSON.parse(localStorage.getItem('auth_token')))
    };

    return this.http.put(
      'api/v3/entity/' + entityId,
      { entityTypeId: entityTypeId, primaryName: newPrimary},
      this.httpOptions
    );
  }

  takeOwnership(entityId: any, owner: any, primaryName: any, entityTypeId: any) {
    this.httpOptions = {
      headers: new HttpHeaders()
        .append('Content-Type', 'application/json')
        .append('X-Auth-Token', JSON.parse(localStorage.getItem('auth_token')))
    };

    return this.http.put(
      'api/v3/entity/' + entityId,
      { entityTypeId: entityTypeId, primaryName: primaryName, ownerId: owner },
      this.httpOptions
    );
  }

  deleteAssessedRisk(entityId: any, customAssessedRisk: boolean, pepRisk: boolean) {
    this.httpOptions = {
      headers: new HttpHeaders()
        .append('Content-Type', 'application/json')
        .append('X-Auth-Token', JSON.parse(localStorage.getItem('auth_token')))
    };

    return this.http.delete(
      'api/v3/risk/forEntity/' + entityId + '/assessed-risk?customAssessedRisk=' + customAssessedRisk + '&customPepRisk=' + pepRisk,
      this.httpOptions
    );
  }

  getToDoList(entityId: any) {
    this.httpOptions = {
      headers: new HttpHeaders()
        .append('Content-Type', 'application/json')
        .append('X-Auth-Token', JSON.parse(localStorage.getItem('auth_token')))
    };

    return this.http.get('/api/v3/entity/todo/' + entityId, this.httpOptions);
  }

  getRiskAnalysisRules() {
    this.httpOptions = {
      headers: new HttpHeaders()
        .append('Content-Type', 'application/json')
        .append('X-Auth-Token', JSON.parse(localStorage.getItem('auth_token')))
    };

    return this.http.get('/api/v3/risk-analysis-rules', this.httpOptions);
  }

  updateRiskAnalysisRules(rules: any) {
    this.httpOptions = {
      headers: new HttpHeaders()
        .append('Content-Type', 'application/json')
        .append('X-Auth-Token', JSON.parse(localStorage.getItem('auth_token')))
    };

    return this.http.put('api/v3/risk-analysis-rules', rules, this.httpOptions);
  }

  getConfirmationWords(noOfWords: any): any {
    return this.http.get(
      'api/v3/user/confirmation-words?numberOfWords=' + noOfWords
    );
  }

  getRiskReport(entityId: any) {
    this.httpOptions = {
      headers: new HttpHeaders()
        .append('Content-Type', 'application/json')
        .append('X-Auth-Token', JSON.parse(localStorage.getItem('auth_token')))
    };

    return this.http.get(
      '/api/v3/risk/forEntity/' + entityId + '/report',
      this.httpOptions
    );
  }

  getPdfReport(inputHtml: string) {
    this.httpOptions = {
      headers: new HttpHeaders().append(
        'X-Auth-Token',
        JSON.parse(localStorage.getItem('auth_token'))
      )
    };

    this.exportFormData = new FormData();

    this.exportFormData.append('htmlInput', inputHtml);

    return this.http.post(
      '/api/pdf/report',
      this.exportFormData,
      this.httpOptions
    );
  }

  // tslint:disable-next-line:max-line-length
  getPdfReport2(entityId: string, username: string, relationshipGraph: any, controlChart: any, controlChartEncodedImage: any, uboChart: any, uboChartEncodedImage: any) {
    this.httpOptions = {
      headers: new HttpHeaders().append(
        'X-Auth-Token',
        JSON.parse(localStorage.getItem('auth_token'))
      )
    };

    this.formData = new FormData();

    if (uboChart !== null) {
      this.formData.append('uboChartImage', uboChart);
    }

    if (uboChartEncodedImage !== null) {
      this.formData.append('uboChartEncodedImage', uboChartEncodedImage);
    }

    if (relationshipGraph !== null) {
      this.formData.append('relationshipGraphImage', relationshipGraph);
    }

    if (controlChart !== null) {
      this.formData.append('controlChartImage', controlChart);
    }

    if (controlChartEncodedImage !== null) {
      this.formData.append('controlChartEncodedImage', controlChartEncodedImage);
    }

    return this.http.post(
      '/api/v3/entity/pdfreport' + '?entityId=' + entityId + '&username=' + username, this.formData,
      this.httpOptions
    );
  }

  getDocumentsOfDocumentTypeForEntity(documentTypeId: any, entityId: any) {
    this.httpOptions = {
      headers: new HttpHeaders()
        .append('Content-Type', 'application/json')
        .append('X-Auth-Token', JSON.parse(localStorage.getItem('auth_token')))
    };

    return this.http.get(
      '/api/v3/role/documents/' + documentTypeId + '/' + entityId,
      this.httpOptions
    );
  }

  getProbableMatches(limit: any, start: any) {
    this.httpOptions = {
      headers: new HttpHeaders()
        .append('Content-Type', 'application/json')
        .append('X-Auth-Token', JSON.parse(localStorage.getItem('auth_token')))
    };

    return this.http.get(
      '/api/v3/match?limit=' + limit + '&start=' + start,
      this.httpOptions
    );
  }

  getAllTags() {
    this.httpOptions = {
      headers: new HttpHeaders()
        .append('Content-Type', 'application/json')
        .append('X-Auth-Token', JSON.parse(localStorage.getItem('auth_token')))
    };

    return this.http.get(
      '/api/v3/tag/all',
      this.httpOptions
    );
  }
  getMRZ(passportFront: any, passportBack: any) {
    this.httpOptions = {
      headers: new HttpHeaders().append(
        "X-Auth-Token",
        JSON.parse(localStorage.getItem("auth_token"))
      )
    };
    this.formData = new FormData();
    this.formData.append("passportFront", passportFront);
    this.formData.append("passportBack", passportBack);
    return this.http.post(
      "api/v3/document/mrz",
      this.formData,
      this.httpOptions
    );
  }
  createTag(name: any) {
    this.httpOptions = {
      headers: new HttpHeaders()
        .append('Content-Type', 'application/json')
        .append('X-Auth-Token', JSON.parse(localStorage.getItem('auth_token')))
    };

    return this.http.post('api/v3/tag', { name: name }, this.httpOptions);
  }

  deleteTag(id: any) {
    this.httpOptions = {
      headers: new HttpHeaders()
        .append('Content-Type', 'application/json')
        .append('X-Auth-Token', JSON.parse(localStorage.getItem('auth_token')))
    };

    return this.http.delete('/api/v3/tag/' + id, this.httpOptions);
  }

  openMap(position: any) {
    this.httpOptions = {
      headers: new HttpHeaders()
        .append('Content-Type', 'application/json')
    };
    return this.http.get('https://nominatim.openstreetmap.org/reverse?format=json&lat=' +
    position.coords.latitude + '&lon=' + position.coords.longitude, this.httpOptions);
  }

  downloadClientDossier(entityId: any) {
    this.httpOptions = {
      headers: new HttpHeaders()
        .append('Content-Type', 'application/json')
        .append('X-Auth-Token', JSON.parse(localStorage.getItem('auth_token'))),
      responseType: 'blob'
    };

    return this.http.post('/api/v3/entity/zip-dossier?entityId=' + entityId, {}, this.httpOptions);
  }

  addComment(entityId: number, comment: string) {
    this.httpOptions = {
      headers: new HttpHeaders()
        .append('Content-Type', 'application/json')
        .append('X-Auth-Token', JSON.parse(localStorage.getItem('auth_token')))
    };
    return this.http.post('/api/v3/entity/' + entityId + '/comment', { 'comment': comment }, this.httpOptions);
  }

  getComments(entityId: number) {
    this.httpOptions = {
      headers: new HttpHeaders()
        .append('Content-Type', 'application/json')
        .append('X-Auth-Token', JSON.parse(localStorage.getItem('auth_token')))
    };
    return this.http.get('/api/v3/entity/' + entityId + '/comment', this.httpOptions);
  }
}
