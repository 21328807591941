import { OnInit, ElementRef, EventEmitter, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { AlertService, CallsService, KYBService, DataService } from '../../../../_services';
import { DeviceDetectorService } from 'ngx-device-detector';
import { FormDataService } from '../../data/formData.service';
import { config } from '../../../../../assets/configuration';
import { WebcamUtil } from 'ngx-webcam';
import { Subject } from 'rxjs';
import { BsModalRef, ModalOptions } from 'ngx-bootstrap/modal';
import { TranslateService } from '@ngx-translate/core';
/**
 * Implements the login page
*/
var MobileDocumentOnboardingImageCaptureComponent = /** @class */ (function () {
    function MobileDocumentOnboardingImageCaptureComponent(translate, bsModalRef, router, formDataService, kybService, eRef, callsService, alertService, dataService, deviceDetectorService, options) {
        this.translate = translate;
        this.bsModalRef = bsModalRef;
        this.router = router;
        this.formDataService = formDataService;
        this.kybService = kybService;
        this.eRef = eRef;
        this.callsService = callsService;
        this.alertService = alertService;
        this.dataService = dataService;
        this.deviceDetectorService = deviceDetectorService;
        this.options = options;
        // toggle webcam on/off
        this.showWebcam = true;
        this.allowCameraSwitch = false;
        this.multipleWebcamsAvailable = false;
        this.videoOptions = {
            facingMode: { ideal: 'environment' },
            width: { ideal: 764 },
            height: { ideal: 1246 }
        };
        this.errors = [];
        this.captureEnabled = true;
        // latest snapshot
        this.webcamImage = null;
        // webcam snapshot trigger
        this.trigger = new Subject();
        // switch to next / previous / specific webcam; true/false: forward/backwards, string: deviceId
        this.nextWebcam = new Subject();
        this.CAMERA_MIC_ACCESS_BLOCKED = '';
        this.imageText = 'Identity Document Photo';
        this.nationalityFilled = false;
        this.nationality = '';
        this.chunks = [];
        this.loading = false;
        this.loadingImg = config.loadingImg;
        this.documentType = '';
        this.identityDocument = '';
        this.event = new EventEmitter();
        this.permissionDenied = false;
        this.captures = [];
        this.passportCapture = '';
        this.passportCaptured = false;
        this.isMobile = false;
        this.email = '';
        this.currentStack = {};
        this.isOnMobile = false;
        this.width = window.innerWidth * 1.1;
        this.height = this.width / 2;
        this.imageCapture = '';
        this.imageScr = '../../../../assets/images/id.png';
        this.minDate = new Date();
        this.maxDate = new Date();
        this.minDate.setDate(this.minDate.getDate() - 42705);
        this.maxDate.setDate(this.maxDate.getDate() - 6570);
        var x = options.initialState;
        this.documentType = x.documentType;
        this.setImageText('FRONT');
    }
    Object.defineProperty(MobileDocumentOnboardingImageCaptureComponent.prototype, "triggerObservable", {
        get: function () {
            return this.trigger.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MobileDocumentOnboardingImageCaptureComponent.prototype, "nextWebcamObservable", {
        get: function () {
            return this.nextWebcam.asObservable();
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MobileDocumentOnboardingImageCaptureComponent.prototype, "ios", {
        get: function () { return !!this.agent.match(/(iPhone)|(iPod)/i); },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(MobileDocumentOnboardingImageCaptureComponent.prototype, "agent", {
        get: function () { return navigator.userAgent || navigator.vendor; },
        enumerable: true,
        configurable: true
    });
    MobileDocumentOnboardingImageCaptureComponent.prototype.ngOnDestroy = function () {
        this.showWebcam = false;
    };
    MobileDocumentOnboardingImageCaptureComponent.prototype.setImageText = function (text) {
        if (this.documentType === 'passport') {
            this.imageText = this.translate.instant('PASSPORT');
        }
        else if (this.documentType === 'id') {
            this.imageText = this.translate.instant(text + '_ID');
        }
        else {
            this.imageText = this.translate.instant(text + '_DRIVER_LICENSE');
        }
    };
    MobileDocumentOnboardingImageCaptureComponent.prototype.ngOnInit = function () {
        var _this = this;
        if (this.ios) {
            this.CAMERA_MIC_ACCESS_BLOCKED = this.translate.instant('CAMERA_MIC_ACCESS_BLOCKED_IOS');
        }
        else {
            this.CAMERA_MIC_ACCESS_BLOCKED = this.translate.instant('CAMERA_MIC_ACCESS_BLOCKED_ANDROID');
        }
        WebcamUtil.getAvailableVideoInputs()
            .then(function (mediaDevices) {
            _this.multipleWebcamsAvailable = mediaDevices && mediaDevices.length > 1;
        });
    };
    /**
    * Function used for validating the form
    * @param {any} form - form data
    * @returns {boolean} is form valid?
    */
    MobileDocumentOnboardingImageCaptureComponent.prototype.blobToFile = function (theBlob, fileName) {
        theBlob.lastModifiedDate = new Date();
        theBlob.name = fileName;
        return theBlob;
    };
    MobileDocumentOnboardingImageCaptureComponent.prototype.triggerSnapshot = function () {
        this.captureEnabled = false;
        this.trigger.next();
    };
    MobileDocumentOnboardingImageCaptureComponent.prototype.toggleWebcam = function () {
        this.showWebcam = !this.showWebcam;
    };
    MobileDocumentOnboardingImageCaptureComponent.prototype.handleInitError = function (error) {
        this.errors.push(error);
        if (error.mediaStreamError && error.mediaStreamError.name === 'NotAllowedError') {
            this.permissionDenied = true;
        }
    };
    MobileDocumentOnboardingImageCaptureComponent.prototype.showNextWebcam = function (directionOrDeviceId) {
        // true => move forward through devices
        // false => move backwards through devices
        // string => move to device with given deviceId
        this.nextWebcam.next(directionOrDeviceId);
    };
    MobileDocumentOnboardingImageCaptureComponent.prototype.handleImage = function (webcamImage) {
        this.webcamImage = webcamImage;
        if (this.documentType === 'passport') {
            var imageBase64 = webcamImage.imageAsBase64;
            this.identityDocument = webcamImage.imageAsDataUrl;
            var base64 = this.identityDocument.split(',');
            var imageBlob = this.dataURItoBlob(base64[1]);
            this.passportCapture = this.identityDocument;
            this.passportFront = new File([imageBlob], 'passportFront.png', { type: 'image/png' });
        }
        if (this.documentType === 'id') {
            if (this.captures.length >= 2) { }
            // Changing the overlay according to if it is the front or back side
            this.identityDocument = webcamImage.imageAsDataUrl;
            this.captures.push(this.identityDocument);
            if (this.captures[0] !== undefined) {
                var base64front = this.captures[0].split(',');
                var imageBlobFront = this.dataURItoBlob(base64front[1]);
                this.front = new File([imageBlobFront], 'idFront.png', { type: 'image/png' });
                this.passportFront = new File([imageBlobFront], 'idFront.png', { type: 'image/png' });
            }
            if (this.captures[1] !== undefined) {
                var base64front = this.captures[1].split(',');
                var imageBlobBack = this.dataURItoBlob(base64front[1]);
                this.back = new File([imageBlobBack], 'idBack.png', { type: 'image/png' });
            }
        }
        if (this.documentType === 'driversLicense') {
            this.identityDocument = webcamImage.imageAsDataUrl;
            this.captures.push(this.identityDocument);
            if (this.captures[0] !== undefined) {
                var base64front = this.captures[0].split(',');
                var imageBlobFront = this.dataURItoBlob(base64front[1]);
                this.front = new File([imageBlobFront], 'driversFront.png', { type: 'image/png' });
                this.passportFront = new File([imageBlobFront], 'idFront.png', { type: 'image/png' });
            }
            if (this.captures[1] !== undefined) {
                var base64front = this.captures[1].split(',');
                var imageBlobBack = this.dataURItoBlob(base64front[1]);
                this.back = new File([imageBlobBack], 'driversBack.png', { type: 'image/png' });
            }
        }
    };
    MobileDocumentOnboardingImageCaptureComponent.prototype.cameraWasSwitched = function (deviceId) {
        this.deviceId = deviceId;
    };
    MobileDocumentOnboardingImageCaptureComponent.prototype.use = function () {
        if (this.documentType === 'passport') {
            this.close();
        }
        else {
            this.setImageText('BACK');
            this.imageScr = '../../../../assets/images/id_back.png';
            this.captureEnabled = true;
        }
        if (this.captures[1] !== undefined) {
            this.close();
        }
    };
    MobileDocumentOnboardingImageCaptureComponent.prototype.retake = function () {
        this.captureEnabled = true;
        if (this.captures[1] === undefined) {
            if (this.captures[0] !== undefined) {
                this.captures = [];
            }
        }
        else if (this.captures[1] !== undefined) {
            this.captures.splice(1, 1);
        }
    };
    MobileDocumentOnboardingImageCaptureComponent.prototype.close = function () {
        this.captureEnabled = false;
        this.event.emit({ passportFront: this.passportFront, front: this.front, back: this.back });
        this.bsModalRef.hide();
    };
    MobileDocumentOnboardingImageCaptureComponent.prototype.cancel = function () {
        this.bsModalRef.hide();
    };
    MobileDocumentOnboardingImageCaptureComponent.prototype.dataURItoBlob = function (dataURI) {
        var byteString = atob(dataURI);
        var arrayBuffer = new ArrayBuffer(byteString.length);
        var int8Array = new Uint8Array(arrayBuffer);
        for (var i = 0; i < byteString.length; i++) {
            int8Array[i] = byteString.charCodeAt(i);
        }
        var blob = new Blob([arrayBuffer], { type: 'image/jpeg' });
        return blob;
    };
    return MobileDocumentOnboardingImageCaptureComponent;
}());
export { MobileDocumentOnboardingImageCaptureComponent };
