/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./hiddenTags.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/forms";
import * as i3 from "../../../../node_modules/@ng-select/ng-select/ng-select-ng-select.ngfactory";
import * as i4 from "@ng-select/ng-select";
import * as i5 from "./hiddenTags.component";
import * as i6 from "../../_services/kyb.service";
import * as i7 from "../../_services/alert.service";
var styles_HiddenTagsComponent = [i0.styles];
var RenderType_HiddenTagsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_HiddenTagsComponent, data: {} });
export { RenderType_HiddenTagsComponent as RenderType_HiddenTagsComponent };
function View_HiddenTagsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "input", [["type", "checkbox"]], [[8, "id", 0], [2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "change"], [null, "blur"]], function (_v, en, $event) { var ad = true; if (("change" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).onChange($event.target.checked) !== false);
        ad = (pd_0 && ad);
    } if (("blur" === en)) {
        var pd_1 = (i1.ɵnov(_v, 1).onTouched() !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 16384, null, 0, i2.CheckboxControlValueAccessor, [i1.Renderer2, i1.ElementRef], null, null), i1.ɵprd(1024, null, i2.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i2.CheckboxControlValueAccessor]), i1.ɵdid(3, 671744, null, 0, i2.NgModel, [[8, null], [8, null], [8, null], [6, i2.NG_VALUE_ACCESSOR]], { model: [0, "model"] }, null), i1.ɵprd(2048, null, i2.NgControl, null, [i2.NgModel]), i1.ɵdid(5, 16384, null, 0, i2.NgControlStatus, [[4, i2.NgControl]], null, null), (_l()(), i1.ɵted(6, null, [" ", " "]))], function (_ck, _v) { var currVal_8 = _v.context.item$.selected; _ck(_v, 3, 0, currVal_8); }, function (_ck, _v) { var currVal_0 = i1.ɵinlineInterpolate(1, "item-", _v.context.index, ""); var currVal_1 = i1.ɵnov(_v, 5).ngClassUntouched; var currVal_2 = i1.ɵnov(_v, 5).ngClassTouched; var currVal_3 = i1.ɵnov(_v, 5).ngClassPristine; var currVal_4 = i1.ɵnov(_v, 5).ngClassDirty; var currVal_5 = i1.ɵnov(_v, 5).ngClassValid; var currVal_6 = i1.ɵnov(_v, 5).ngClassInvalid; var currVal_7 = i1.ɵnov(_v, 5).ngClassPending; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7); var currVal_9 = _v.context.item.name; _ck(_v, 6, 0, currVal_9); }); }
export function View_HiddenTagsComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 26, "div", [[":xmlns:height", "http://www.w3.org/1999/xhtml"], ["class", "col-md-10 col-md-offset-1"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" Please select which tags you would like to hide. Entities with these tags will not be displayed in the system. "])), (_l()(), i1.ɵeld(2, 0, null, null, 0, "div", [["style", "height: 30px;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 20, "ng-select", [["bindLabel", "name"], ["bindValue", "id"], ["class", "custom"], ["role", "listbox"]], [[2, "ng-select", null], [2, "ng-select-single", null], [2, "ng-select-typeahead", null], [2, "ng-select-multiple", null], [2, "ng-select-taggable", null], [2, "ng-select-searchable", null], [2, "ng-select-clearable", null], [2, "ng-select-opened", null], [2, "ng-select-disabled", null], [2, "ng-select-filtered", null], [2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "ngModelChange"], [null, "keydown"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("keydown" === en)) {
        var pd_0 = (i1.ɵnov(_v, 5).handleKeyDown($event) !== false);
        ad = (pd_0 && ad);
    } if (("ngModelChange" === en)) {
        var pd_1 = ((_co.selectedTags = $event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, i3.View_NgSelectComponent_0, i3.RenderType_NgSelectComponent)), i1.ɵprd(4608, null, i4.ɵd, i4.ɵd, []), i1.ɵdid(5, 4964352, null, 12, i4.NgSelectComponent, [[8, "custom"], [8, null], i4.NgSelectConfig, i4.SELECTION_MODEL_FACTORY, i1.ElementRef, i1.ChangeDetectorRef, i4.ɵs], { bindLabel: [0, "bindLabel"], bindValue: [1, "bindValue"], closeOnSelect: [2, "closeOnSelect"], multiple: [3, "multiple"], clearable: [4, "clearable"], items: [5, "items"] }, null), i1.ɵqud(603979776, 1, { optionTemplate: 0 }), i1.ɵqud(603979776, 2, { optgroupTemplate: 0 }), i1.ɵqud(603979776, 3, { labelTemplate: 0 }), i1.ɵqud(603979776, 4, { multiLabelTemplate: 0 }), i1.ɵqud(603979776, 5, { headerTemplate: 0 }), i1.ɵqud(603979776, 6, { footerTemplate: 0 }), i1.ɵqud(603979776, 7, { notFoundTemplate: 0 }), i1.ɵqud(603979776, 8, { typeToSearchTemplate: 0 }), i1.ɵqud(603979776, 9, { loadingTextTemplate: 0 }), i1.ɵqud(603979776, 10, { tagTemplate: 0 }), i1.ɵqud(603979776, 11, { loadingSpinnerTemplate: 0 }), i1.ɵqud(603979776, 12, { ngOptions: 1 }), i1.ɵprd(1024, null, i2.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i4.NgSelectComponent]), i1.ɵdid(19, 671744, null, 0, i2.NgModel, [[8, null], [8, null], [8, null], [6, i2.NG_VALUE_ACCESSOR]], { model: [0, "model"] }, { update: "ngModelChange" }), i1.ɵprd(2048, null, i2.NgControl, null, [i2.NgModel]), i1.ɵdid(21, 16384, null, 0, i2.NgControlStatus, [[4, i2.NgControl]], null, null), (_l()(), i1.ɵand(0, [[1, 2]], null, 1, null, View_HiddenTagsComponent_1)), i1.ɵdid(23, 16384, null, 0, i4.ɵf, [i1.TemplateRef], null, null), (_l()(), i1.ɵeld(24, 0, null, null, 0, "div", [["style", "height: 20px;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(25, 0, null, null, 1, "button", [["class", "btn btn-primary"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.save() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Save"]))], function (_ck, _v) { var _co = _v.component; var currVal_17 = "name"; var currVal_18 = "id"; var currVal_19 = false; var currVal_20 = true; var currVal_21 = false; var currVal_22 = _co.tags; _ck(_v, 5, 0, currVal_17, currVal_18, currVal_19, currVal_20, currVal_21, currVal_22); var currVal_23 = _co.selectedTags; _ck(_v, 19, 0, currVal_23); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 5).useDefaultClass; var currVal_1 = !i1.ɵnov(_v, 5).multiple; var currVal_2 = i1.ɵnov(_v, 5).typeahead; var currVal_3 = i1.ɵnov(_v, 5).multiple; var currVal_4 = i1.ɵnov(_v, 5).addTag; var currVal_5 = i1.ɵnov(_v, 5).searchable; var currVal_6 = i1.ɵnov(_v, 5).clearable; var currVal_7 = i1.ɵnov(_v, 5).isOpen; var currVal_8 = i1.ɵnov(_v, 5).disabled; var currVal_9 = i1.ɵnov(_v, 5).filtered; var currVal_10 = i1.ɵnov(_v, 21).ngClassUntouched; var currVal_11 = i1.ɵnov(_v, 21).ngClassTouched; var currVal_12 = i1.ɵnov(_v, 21).ngClassPristine; var currVal_13 = i1.ɵnov(_v, 21).ngClassDirty; var currVal_14 = i1.ɵnov(_v, 21).ngClassValid; var currVal_15 = i1.ɵnov(_v, 21).ngClassInvalid; var currVal_16 = i1.ɵnov(_v, 21).ngClassPending; _ck(_v, 3, 1, [currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9, currVal_10, currVal_11, currVal_12, currVal_13, currVal_14, currVal_15, currVal_16]); }); }
export function View_HiddenTagsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-hidden-tags", [], null, null, null, View_HiddenTagsComponent_0, RenderType_HiddenTagsComponent)), i1.ɵdid(1, 114688, null, 0, i5.HiddenTagsComponent, [i6.KYBService, i7.AlertService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var HiddenTagsComponentNgFactory = i1.ɵccf("app-hidden-tags", i5.HiddenTagsComponent, View_HiddenTagsComponent_Host_0, {}, {}, []);
export { HiddenTagsComponentNgFactory as HiddenTagsComponentNgFactory };
