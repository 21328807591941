<div class="col-md-12">
  <div style="height: 40px"></div>
  <p class="" style="font-size: 20pt; font-weight: 400; text-align: center;">Risk Screening Reports</p>
  <p style="font-size: 15px; text-align: center; margin: 0 auto;">Here you can generate a report on what type of matches were detected in
    a certain time period. Start by selecting a from and to date. </p>
  <div class="table-responsive" style="overflow-x: hidden; margin: 0 auto;">
    <div class="row" style="margin: 0px;">
      <div class="">
        <div style="height: 40px"></div>
        <p>Select dates:</p>
        <div style="height: 20px"></div>
        <input type="text" style="max-width: 500px;" [(ngModel)]="dateRange" placeholder="Select dates..." class="form-control input-md"
          placement="bottom"
          [bsConfig]="{ rangeInputFormat: 'DD/MM/YYYY', dateInputFormat: 'DD/MM/YYYY', containerClass: 'theme-default', showWeekNumbers: false}"
          bsDaterangepicker (bsValueChange)="datesChanged($event)" required>
      </div>
      <div class=""></div>
    </div>
    <div class="row" *ngIf="displayFilters">
      <div style="height: 40px"></div>
      <div class="col-sm-6">
        <p>Select the tag(s) that you would like to see:</p>
        <ng-select class="custom-screening" [items]="userTags" [multiple]="true" bindLabel="name"
          [closeOnSelect]="false" bindValue="id" [clearable]="false" (change)="tagsChanged($event)"
          [(ngModel)]="includedTags">
          <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
            <input id="item-{{index}}" type="checkbox" [ngModel]="item$.selected" /> {{item.name}}
          </ng-template>
        </ng-select>
      </div>
      <div class="col-sm-1" style="">
        <div style="height: 50px;"></div>
        <button (click)="selectAllTags()"
          class="btn btn-success btn-select-clear">Select all</button>
      </div>
      <div class="col-sm-1" style="">
        <div style="height: 50px;"></div>
        <button (click)="deselectAllTags()"
          style=" margin-left: 20px;"
          class="btn btn-success btn-select-clear">Clear all</button>
      </div>
      <div style="height: 20px"></div>
    </div>
    <div style="height: 40px"></div>
    <div style="display: flex;">
      <button class="btn btn-primary" (click)="exportCSV()" [disabled]="disabledButtons">Export
        CSV</button>&nbsp;&nbsp;<button class="btn btn-primary" (click)="exportPDF()" [disabled]="disabledButtons">Export
        PDF</button>&nbsp;&nbsp;<button class="btn btn-primary btn-filter" (click)="toggleFilters()"
        [disabled]="disabledButtons">Filters&nbsp;<mat-icon>filter_list</mat-icon>
      </button>
    </div>
    <div style="height: 20px"></div>
    <p *ngIf="!disabledButtons">Summary</p>
    <div style="height: 20px"></div>
    <div class="row">
      <div class="col-sm-12" style="margin-left: -20px;">
        <table id="entity-table" class="table table-responsive table-hover" style="color: black;width: 100%;margin-left: 20px;"
          *ngIf="!disabledButtons">
          <thead>
            <tr>
              <th>Date range</th>
              <th>Probable matches</th>
              <th>Confirmed matches</th>
              <th>Rejected matches</th>
              <th>Marked for further research</th>
            </tr>
          </thead>
          <tbody>
            <tr style="background-color: #fff;">
              <td>{{ statisticsResponse?.data?.from | date:'dd/MM/yyyy, h:mm:ss a z' }} - {{
                statisticsResponse?.data?.to | date:'dd/MM/yyyy, h:mm:ss a z' }}</td>
              <td style="text-align: center;">{{ statisticsResponse?.data?.probableMatchesCount }}</td>
              <td style="text-align: center;">{{ statisticsResponse?.data?.confirmedMatchesCount }}</td>
              <td style="text-align: center;">{{ statisticsResponse?.data?.whiteListedMatchesCount }}</td>
              <td style="text-align: center;">{{ statisticsResponse?.data?.toFurtherResearchMatchesCount }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div style="height: 40px"></div>
    <div class="row">
      <div class="col-sm-12">
        <div [chart]="pieChart"></div>
      </div>
    </div>
    <div style="height: 40px"></div>
  </div>
  <div style="height: 20px"></div>
  <p *ngIf="!disabledButtons" style="text-align: center; background-color: #fff; padding-bottom: 20px; padding-top: 20px; margin-bottom: 0px;">Detailed Report</p>
  <div class="row" style="margin: 0px;">
    <div class="col-sm-12" style="padding: 0px;">
      <table *ngFor="let entity of statisticsResponse?.data?.matches" class="table table-responsive table-hover"
        style="color: black;width: 100%; margin-bottom: 0px;">
        <thead>
          <td style="padding: 10px; text-align: center; max-width: 4%;width: 100%;" class="">
            <i class="fa fa-plus-square plus-square-screeningReport" [ngStyle]="{'display': !displayTheColumn ?  'block' : displayTheColumn && displayTheColumn?.entity.name === entity?.entity.name ? 'none' : 'block'}" style="color: #fff;" (click)="displayCompleteTable(entity)"></i>
            <i class="fa fa-minus-square minus-square-screeningReport" [ngStyle]="{'display': displayTheColumn && displayTheColumn?.entity.name === entity?.entity.name ? 'block' : 'none'}" style="color: #fff;" (click)="hideCompleteTable()"></i>
          </td>
          <td style="padding: 10px; max-width: 50%; width: 100%;" class="">{{entity.entity.name}}</td>
          <td style="padding: 10px; text-align: center; max-width: 6%; width: 100%;" class="">Status</td>
          <td style="padding: 10px; max-width: 20%; width: 100%;" class="">Last Update</td>
          <td style="padding: 10px; max-width: 20%; width: 100%;" class="">Updated By</td>
        </thead>
        <tr class="screeningReport-confirmed-matches-table" [ngClass]="{'displayTheColumn': displayTheColumn && displayTheColumn?.entity.name === entity.entity.name}" style="background-color: #fff;color: #000;" *ngFor="let confirmedMatch of displayTheColumn?.confirmedMatches">
          <td style="padding: 10px; max-width: 4%;width: 100%;"></td>
          <td style="padding: 10px; max-width: 50%; width: 100%;" class="">[{{confirmedMatch.riskType}}] {{confirmedMatch.identifier}}</td>
          <td style="text-align: center; max-width: 6%; width: 100%;">
            <i title="Confirmed Matches" style="color: #cc0000" class="fa fa-circle" aria-hidden="true"></i>
          </td>
          <td style="padding: 10px; max-width: 20%; width: 100%;" class="">{{confirmedMatch.lastStatusTimestamp}}</td>
          <td style="padding: 10px; max-width: 20%; width: 100%;" class="">{{confirmedMatch.lastStatusChangedBy}}</td>
        </tr>
        <tr class="screeningReport-further-research-table" [ngClass]="{'displayTheColumn': displayTheColumn && displayTheColumn?.entity.name === entity.entity.name}" style="background-color: #fff;color: #000;"
        *ngFor="let toFurtherResearchMatch of displayTheColumn?.toFurtherResearchMatches">
          <td style="padding: 10px; max-width: 4%;width: 100%;"></td>
          <td style="padding: 10px; max-width: 50%; width: 100%;" class="">[{{toFurtherResearchMatch.riskType}}] {{toFurtherResearchMatch.identifier}}</td>
          <td style="text-align: center; max-width: 6%; width: 100%;">
            <i title="Marked for Further Research" style="color: #d6860f" class="fa fa-circle" aria-hidden="true"></i>
          </td>
          <td style="padding: 10px; max-width: 20%; width: 100%;" class="">{{toFurtherResearchMatch.lastStatusTimestamp}}</td>
          <td style="padding: 10px; max-width: 20%; width: 100%;" class="">{{toFurtherResearchMatch.lastStatusChangedBy}}</td>
        </tr>
        <tr class="screeningReport-probable-matches-table" [ngClass]="{'displayTheColumn': displayTheColumn && displayTheColumn?.entity.name === entity.entity.name}" style="background-color: #fff;color: #000;" *ngFor="let probableMatch of displayTheColumn?.probableMatches">
          <td style="padding: 10px; max-width: 4%;width: 100%;"></td>
          <td style="padding: 10px; max-width: 50%; width: 100%;" class="">[{{probableMatch.riskType}}] {{probableMatch.identifier}}</td>
          <td style="text-align: center; max-width: 6%; width: 100%;">
            <i title="Probable Matches" style="color: #daaa0e" class="fa fa-circle" aria-hidden="true"></i>
          </td>
          <td style="padding: 10px; max-width: 20%; width: 100%;" class="">{{probableMatch.lastStatusTimestamp}}</td>
          <td style="padding: 10px; max-width: 20%; width: 100%;" class="">{{probableMatch.lastStatusChangedBy}}</td>
        </tr>
        <tr class="screeningReport-whiteListed-matches-table" [ngClass]="{'displayTheColumn': displayTheColumn && displayTheColumn?.entity.name === entity.entity.name}" style="background-color: #fff;color: #000;" *ngFor="let whitelistedMatch of displayTheColumn?.whiteListedMatches">
          <td style="padding: 10px; max-width: 4%;width: 100%;"></td>
          <td style="padding: 10px; max-width: 50%; width: 100%;" class="">[{{whitelistedMatch.riskType}}] {{whitelistedMatch.identifier}}</td>
          <td style="text-align: center; max-width: 6%; width: 100%;">
            <i title="WhiteListed Matches" style="color: #1a8331" class="fa fa-circle" aria-hidden="true"></i>
          </td>
          <td style="padding: 10px; max-width: 20%; width: 100%;" class="">{{whitelistedMatch.lastStatusTimestamp}}</td>
          <td style="padding: 10px; max-width: 20%; width: 100%;" class="">{{whitelistedMatch.lastStatusChangedBy}}</td>
        </tr>
      </table>
    </div>
  </div>
  <div style="height: 40px"></div>
</div>