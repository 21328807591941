<div class="col-md-12" style="padding: 0px;">
    <tabset type="pills" #staticTabs class="main-topic-tabs">
        <tab heading="Legal Persons">
            <div class="row">
                <app-legal-persons-settings [v4RoleTypes]="v4RoleTypes" [entityTypes]="entityTypes" *ngIf="staticTabs.tabs[0]?.active"></app-legal-persons-settings>
            </div>
        </tab>
        <tab heading="Legal Roles">
            <div class="row" style="margin: 0px;"> 
                <app-legal-roles-settings [v4RoleTypes]="v4RoleTypes" [documentTypes]="documentTypes" [entityTypes]="entityTypes" *ngIf="staticTabs.tabs[1]?.active"></app-legal-roles-settings>
            </div>
        </tab>
        <tab heading="Documents">
            <div class="row">
                <app-documents-settings [documentTypes]="documentTypes" *ngIf="staticTabs.tabs[2]?.active"></app-documents-settings>
            </div>
        </tab>
        <tab heading="GEO Entities">
            <div class="row">
                <app-geo-entities-settings [documentTypesResponse]="documentTypesResponse" [v4RoleTypesResponse]="v4RoleTypesResponse" [entityTypesResponse]="entityTypesResponse" [entities]="entities" *ngIf="staticTabs.tabs[3]?.active" (reloadGeoEntitiesList)="reloadGeoEntitiesList($event)"></app-geo-entities-settings>
            </div>
        </tab>
    </tabset>
</div>
