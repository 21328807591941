import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { AlertService, DataService, KYBService } from '../../_services';

@Component({
  selector: 'app-close-notification',
  templateUrl: './closeNotification.component.html',
  styleUrls: ['./closeNotification.component.css']
})
export class CloseNotificationComponent implements OnInit {

  notification: any;

  public form: FormGroup;

  constructor(
    private alertService: AlertService,
    private dataService: DataService,
    private kybService: KYBService
  ) {}

  ngOnInit() {
    this.form = new FormGroup({
      note: new FormControl()
    });
  }

  close() {
    this.dataService.triggerCloseModalEvent(1);
  }

  onSubmit() {
    this.kybService.closeSomeNotification(this.notification.id, this.form.get('note').value).subscribe(
      data => {
        this.alertService.showSuccess('Notification closed successfully.');
      },
      error => {
        this.alertService.showError(error.message);
      }
    );

    this.dataService.triggerCloseModalEvent(this.form.value);
  }
}
