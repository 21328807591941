import { ChangeDetectorRef, OnInit, EventEmitter, Type } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthenticationService, DataService, KYBService } from '../../_services';
import { ConfigService } from '../../_services/config.service';
import { config } from '../../../assets/configuration';
import { FormDataService } from './data/formData.service';
import { FormBuilder, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { VerificationService } from './verification.service';
import { BrandingService } from './../../branding/branding.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { BsModalService } from 'ngx-bootstrap/modal';
import { DocumentManagerComponent } from './documentManager/documentManager.component';
import { DisclaimersComponent } from './disclaimers/disclaimers.component';
import { IdentityDocumentComponent } from './identityDocument/identityDocument.component';
import { VideoComponent } from './video/video.component';
/**
 * Implements the registration page
*/
var RegisterComponent = /** @class */ (function () {
    function RegisterComponent(formDataService, ref, dataService, kybService, _formBuilder, route, translate, _verificationService, brandingService, deviceDetectorService, configService, authenticationService, modalService) {
        var _this = this;
        this.formDataService = formDataService;
        this.ref = ref;
        this.dataService = dataService;
        this.kybService = kybService;
        this._formBuilder = _formBuilder;
        this.route = route;
        this.translate = translate;
        this._verificationService = _verificationService;
        this.brandingService = brandingService;
        this.deviceDetectorService = deviceDetectorService;
        this.configService = configService;
        this.authenticationService = authenticationService;
        this.modalService = modalService;
        this.errorMessage = 'Page not found, Please make sure that you use the latest onboarding URL';
        this.companyName = config.companyName;
        this.registerState = 0;
        this.deviceInfo = null;
        this.isMobile = false;
        this.thirdParty = false;
        this.initStack = {};
        this.configSteps = [];
        this.onboardingComponents = [
            { name: 'document-manager', type: DocumentManagerComponent },
            { name: 'identity-document', type: IdentityDocumentComponent },
            { name: 'video-onboarding', type: VideoComponent },
            { name: 'disclaimers', type: DisclaimersComponent },
        ];
        this.LOADING_MESSAGE = 'We are veryfing you ';
        this.disclaimersChecked = false;
        this.onboardingCompleted = false;
        this.redirectUrl = null;
        this.canFinish = false;
        this.isveryfing = true;
        this.onboardingPermissionEnabled = false;
        this.isQrEnabled = false;
        this.documentIds = [];
        this.documentReady = false;
        this.urlQrCode = '';
        this.stepper = '';
        this.isMobileDevice = false;
        this.customOnboarding = false;
        this.registrationCompleted = true;
        this.firstConnection = true;
        this.ssoHost = 'azure';
        this.imgSrc = '';
        this.onboardingLanguage = 'en';
        this.additionalDocumentIds = [];
        this.requiredDocumentIds = [];
        this.requiredDocumentTypes = [];
        this.getEntityResponse = {};
        this.getRoleResponse = {};
        this.lastStepIsDisabled = false;
        this.dataService.documentReady.subscribe(function (message) {
            _this.documentReady = message;
        });
        if (!this.configService.get('documentManagerFlow')) {
            this.configSteps = this.configService.get('onboardSteps');
        }
        else {
            this.configSteps = this.configService.get('onboardCustomSteps');
        }
        this.onboardingLanguage = this.configService.get('onboardingLanguage');
        if (this.configService.get('documentManagerFlow') && !this.configService.get('documentManagerFlow')) {
            this.customOnboarding = true;
            if (this.configService.get('azureSSO') !== undefined || this.configService.get('googleSSO') !== undefined) {
                if (this.configService.get('azureSSO') && this.configService.get('googleSSO')) {
                    // tslint:disable-next-line:no-use-before-declare
                    this.azureOrGoogleModal = this.modalService.show(AzureOrGoogleModalComponent, { backdrop: 'static' });
                    this.dataService.closeModalEvent.subscribe(function () {
                        _this.azureOrGoogleModal.hide();
                    });
                    this.azureOrGoogleModal.content.ssoEntry.subscribe(function (sso) {
                        _this.ssoHost = sso;
                        window.location.href = '/api/oauth2/authorize/' + _this.ssoHost + '?redirect_uri=' + window.location.origin + '/onboard';
                    });
                }
                else if (this.configService.get('azureSSO') && !this.configService.get('googleSSO')) {
                    this.ssoHost = 'azure';
                }
                else if (!this.configService.get('azureSSO') && this.configService.get('googleSSO')) {
                    this.ssoHost = 'google';
                }
                else {
                    this.customOnboarding = false;
                }
            }
        }
        else {
            this.customOnboarding = false;
        }
        if (document.querySelectorAll('.jumbotron')) {
            for (var i = 0; i < document.querySelectorAll('.jumbotron').length; i++) {
                document.querySelectorAll('.jumbotron')[i].style.backgroundColor = '#fff';
            }
        }
    }
    Object.defineProperty(RegisterComponent.prototype, "agent", {
        get: function () { return navigator.userAgent || navigator.vendor; },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(RegisterComponent.prototype, "chrome", {
        get: function () { return !!this.agent.toLowerCase().match(/(chrome)/i); },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(RegisterComponent.prototype, "safari", {
        get: function () { return !!this.agent.toLowerCase().match(/(safari)/i); },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(RegisterComponent.prototype, "firefox", {
        get: function () { return !!this.agent.toLowerCase().match(/(firefox)/i); },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(RegisterComponent.prototype, "firefoxIOS", {
        get: function () { return !!navigator.userAgent.toLowerCase().match(/(fxios)/i); },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(RegisterComponent.prototype, "chromeIOS", {
        get: function () { return !!navigator.userAgent.toLowerCase().match(/(crios)/i); },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(RegisterComponent.prototype, "operaIOS", {
        get: function () { return !!navigator.userAgent.toLowerCase().match(/(opios)/i); },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(RegisterComponent.prototype, "samsungAndroid", {
        get: function () { return !!navigator.userAgent.toLowerCase().match(/(samsungbrowser)/i); },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(RegisterComponent.prototype, "android", {
        get: function () { return !!this.agent.match(/(android)/i); },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(RegisterComponent.prototype, "ios", {
        get: function () { return !!this.agent.match(/(iPhone)|(iPod)/i); },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(RegisterComponent.prototype, "isBrowserSupported", {
        get: function () {
            if (this.ios && (this.safari === true && this.chromeIOS === false && this.firefoxIOS === false && this.operaIOS === false)) {
                return true;
            }
            else if (this.android && (this.chrome === true && this.samsungAndroid === false && this.firefox === false)) {
                return true;
            }
            else if (!this.deviceDetectorService.isMobile()) {
                return true;
            }
            else {
                return false;
            }
        },
        enumerable: true,
        configurable: true
    });
    RegisterComponent.prototype.disableNextButtonMobile = function () {
        if (this.isMobileDevice === true && this.registerState === 1) {
            return true;
        }
        return false;
    };
    RegisterComponent.prototype.videoOrLastStep = function () {
        if (!this.configService.get('documentManagerFlow')) {
            this.lastStepIsDisabled = true;
            if (this.configSteps[this.registerState].name === 'video-onboarding' || (this.registerState === this.configSteps.length - 1)) {
                return false;
            }
            return true;
        }
        else {
            if ((this.registerState === this.configSteps.length - 1)) {
                return false;
            }
            return true;
        }
    };
    RegisterComponent.prototype.lastStep = function () {
        if (this.registerState === this.configSteps.length - 1) {
            return true;
        }
        return false;
    };
    RegisterComponent.prototype.ngOnInit = function () {
        var _this = this;
        if (document.querySelectorAll('.navbar-header')) {
            document.querySelectorAll('.navbar-header')[0].classList.add("logoCentered");
        }
        if (document.querySelectorAll('.navbar-right')) {
            document.querySelectorAll('.navbar-right')[0].classList.add("hideNavBarOnOnboarding");
        }
        console.log("Register component on init");
        this.isMobileDevice = this.deviceDetectorService.isMobile();
        console.log(this.isMobileDevice);
        this.dataService.currentDocumentIds.subscribe(function (message) { return _this.documentIds = message; });
        this.dataService.currentPersonalForm.subscribe(function (message) {
            _this.personalForm = message;
        });
        this.route.paramMap.subscribe(function (params) {
            _this.onboardingLanguage = _this.configService.get('onboardingLanguage');
            _this.useLanguage(_this.onboardingLanguage);
            _this.redirectUrl = params.get('redirectUrl');
            _this.verifyToken = params.get('token');
            if (_this.verifyToken) {
                _this.handleToken();
            }
            _this.isQrEnabled = true;
            var ua = navigator.userAgent;
            console.log(ua);
            if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i.test(ua)) {
                _this.isMobile = true;
            }
            else if (/Chrome/i.test(ua)) {
                _this.isMobile = false;
            }
            else {
                _this.isMobile = false;
            }
            _this.urlQrCode = window.location.href;
        });
        this.route.queryParams
            .subscribe(function (params) {
            if (params.token !== undefined) {
                _this.onboardingPermissionEnabled = true;
                localStorage.setItem('auth_token', JSON.stringify(params.token));
                _this.isveryfing = false;
                _this.dataService.setOnboardingEntityId(localStorage.getItem('onboardingEntityId'));
                _this.kybService.getRolesForEntity(localStorage.getItem('onboardingEntityId')).subscribe(function (data) {
                    _this.getEntityResponse = data;
                    for (var r = 0; r < _this.getEntityResponse.data.length; r++) {
                        _this.isveryfing = false;
                        if (_this.getEntityResponse.data[r].roleName.indexOf('Identity') !== -1) {
                            _this.dataService.setOnboardingEntitySelfRoleId(_this.getEntityResponse.data[r].id);
                        }
                    }
                    _this.kybService.validateRepresentative().subscribe(function (response) {
                        var res;
                        res = response;
                        if (res.hasIdAttached === true && res.hasIdAttached === true) {
                            _this.registrationCompleted = true;
                            _this.firstConnection = false;
                            _this.formDataService.setfinishOnboarding(true);
                        }
                        else {
                            _this.firstConnection = true;
                        }
                        _this.dataService.setFirstConnection(_this.firstConnection);
                    }, function (error) {
                        _this.firstConnection = true;
                        _this.dataService.setFirstConnection(_this.firstConnection);
                    });
                    _this.isveryfing = false;
                }, function (error) { });
            }
        });
        this.firstFormGroup = this._formBuilder.group({
            firstCtrl: ['', Validators.required]
        });
        this.secondFormGroup = this._formBuilder.group({
            secondCtrl: ['', Validators.required]
        });
        this.initStack.entities = [];
        this.initStack.roles = [];
        this.initStack.documents = [];
        this.dataService.setCurrentStack(this.initStack);
        // TODO - delete this line
        this.dataService.setThirdParty('2');
        this.formDataService.resetFormData();
        this.formDataService.setRegisterState(0);
        this.formDataService.currentRegisterState.subscribe(function (message) {
            _this.registerState = message;
        });
        this.formDataService.finishOnboarding.subscribe(function (message) {
            if (message === true) {
                _this.additionalDocumentIds = [];
                _this.requiredDocumentIds = [];
                _this.requiredDocumentTypes = [];
                _this.isveryfing = false;
                // add a custom class to body to allow new design for thank you page
                if (_this.customOnboarding !== true) {
                    document.body.className += ' onboarding-finished';
                    _this.onboardingCompleted = true;
                    if (_this.redirectUrl) {
                        window.location.href = 'https://' + _this.redirectUrl;
                    }
                    _this._verificationService.deactivateToken(_this.verifyToken).subscribe(function (res) {
                        localStorage.removeItem('auth_token');
                    }, function (error) { });
                    _this.brandingService.brandingSetup.subscribe(function (response) {
                        if (!response) {
                            return;
                        }
                        _this.webUrl = response.companyUrl;
                    });
                }
                else {
                    _this.goToNext();
                    _this.ref.detectChanges();
                }
                window.scrollTo(0, 0);
            }
        });
        this.formDataService.currentDisclaimersState.subscribe(function (message) {
            _this.disclaimersChecked = message;
        });
        this.formDataService.videoRegisterState.subscribe(function (message) {
            if (message === true) {
                for (var i = 0; i < _this.configSteps.length; i++) {
                    if (_this.configSteps[i].name === 'video-onboarding') {
                        _this.formDataService.setRegisterState(i);
                    }
                }
            }
        });
        this.dataService.thirdParty.subscribe(function (message) {
            if (message === 1 || message === 2) {
                _this.thirdParty = true;
            }
            else {
                _this.thirdParty = false;
            }
        });
        this.brandingService.brandingSetup.subscribe(function (response) {
            if (!response) {
                return;
            }
            _this.webUrl = response.companyUrl;
            _this.stepper = response.mainColor;
            _this.backgroundButtons = _this.stepper.split('#');
        });
    };
    RegisterComponent.prototype.handleToken = function () {
        var _this = this;
        this.isveryfing = true;
        localStorage.removeItem('auth_token');
        this.verifiedEntity = this._verificationService.verifyToken(this.verifyToken);
        this.verifiedEntity.subscribe(function (res) {
            _this.dataService.setOnboardingEntityId(res.entityId);
            localStorage.setItem('onboardingEntityId', res.entityId);
            _this.onboardingPermissionEnabled = true;
            localStorage.setItem('auth_token', JSON.stringify(res.token));
            _this.kybService.getRolesForEntity(res.entityId).subscribe(function (data) {
                _this.dataService.setFirstConnection(_this.firstConnection);
                _this.getEntityResponse = data;
                for (var r = 0; r < _this.getEntityResponse.data.length; r++) {
                    _this.isveryfing = false;
                    if (_this.getEntityResponse.data[r].roleName.indexOf('Identity') !== -1) {
                        _this.dataService.setOnboardingEntitySelfRoleId(_this.getEntityResponse.data[r].id);
                        var currenturl = window.location.origin;
                        if (!_this.isveryfing && _this.onboardingPermissionEnabled && _this.customOnboarding) {
                            window.location.href = '/api/oauth2/authorize/' + _this.ssoHost + '?redirect_uri=' + currenturl + '/onboard';
                        }
                    }
                }
            }, function (error) { });
        }, function (e) {
            _this.isveryfing = false;
            _this.onboardingCompleted = false;
        });
    };
    RegisterComponent.prototype.captureEvent = function (event) { };
    RegisterComponent.prototype.close = function () {
        window.close();
    };
    RegisterComponent.prototype.setStepIndex = function (stepObj) {
        if (stepObj.selectedIndex === this.registerState) {
            return;
        }
        this.formDataService.setRegisterState(stepObj.selectedIndex);
    };
    RegisterComponent.prototype.hastStep = function (name) {
        for (var _i = 0, _a = this.configSteps; _i < _a.length; _i++) {
            var step = _a[_i];
            if (step.name === name) {
                return true;
            }
        }
        return false;
    };
    RegisterComponent.prototype.useLanguage = function (language) {
        this.translate.use(language);
    };
    RegisterComponent.prototype.goToNext = function () {
        var oldStep = +this.registerState + 1;
        if (oldStep < this.configSteps.length) {
            this.formDataService.setRegisterState(oldStep);
        }
        if (this.configService.get('documentManagerFlow')) {
            if (document.querySelectorAll('.mat-step-icon')) {
                document.querySelectorAll('.mat-step-icon')[oldStep].style.backgroundColor = "" + this.stepper;
            }
            if (document.querySelectorAll('.mat-tab-label')) {
                document.querySelectorAll('.mat-tab-label')[1].style.borderBottomColor = "black";
                document.querySelectorAll('.mat-tab-label')[1].style.borderRight = "3px solid #aaa";
                document.querySelectorAll('.mat-tab-label')[1].style.backgroundColor = "white";
                document.querySelectorAll('.mat-tab-label')[2].style.borderBottomColor = "black";
                document.querySelectorAll('.mat-tab-label')[2].style.borderRight = "3px solid #aaa";
                document.querySelectorAll('.mat-tab-label')[2].style.backgroundColor = "white";
                document.querySelectorAll('.mat-tab-label')[3].style.borderBottomColor = "black";
                document.querySelectorAll('.mat-tab-label')[3].style.borderRight = "3px solid #aaa";
                document.querySelectorAll('.mat-tab-label')[3].style.backgroundColor = "white";
                document.querySelectorAll('.mat-tab-label')[0].style.borderBottomColor = "" + this.stepper;
                document.querySelectorAll('.mat-tab-label')[0].style.borderRight = "3px solid " + this.stepper;
                document.querySelectorAll('.mat-tab-label')[0].style.color = "" + this.stepper;
                document.querySelectorAll('.mat-tab-label')[0].style.backgroundColor = "#efefef";
            }
        }
        else {
            if (document.querySelectorAll('.mat-step-icon')) {
                document.querySelectorAll('.mat-step-icon')[oldStep].style.backgroundColor = "" + this.stepper;
            }
        }
    };
    RegisterComponent.prototype.goToPrevious = function () {
        var oldStep = +this.registerState - 1;
        this.formDataService.setRegisterState(oldStep);
    };
    RegisterComponent.prototype.isDisabled = function () {
        if ((this.disclaimersChecked === false && this.configSteps[this.registerState].name === 'disclaimers') ||
            (!this.documentReady && this.configSteps[this.registerState].name === 'identity-document')) {
            return true;
        }
        return false;
    };
    RegisterComponent.prototype.finish = function () {
        var _this = this;
        if (this.customOnboarding === true) {
        }
        else {
            this._verificationService.deactivateToken(this.verifyToken).subscribe(function (res) {
                localStorage.removeItem('auth_token');
            }, function (error) { });
        }
        if (this.customOnboarding !== true) {
            setTimeout(function () {
                _this.additionalDocumentIds = [];
                _this.requiredDocumentIds = [];
                _this.requiredDocumentTypes = [];
                _this.isveryfing = false;
                _this.onboardingCompleted = true;
                // add a custom class to body to allow new design for thank you page
                document.body.className += 'onboarding-finished';
                setTimeout(function () {
                    if (_this.redirectUrl) {
                        window.location.href = 'https://' + _this.redirectUrl;
                    }
                }, 1000);
            }, 1000);
            this.brandingService.brandingSetup.subscribe(function (res) {
                if (!res) {
                    return;
                }
                _this.webUrl = res.companyUrl;
                _this.imgSrc = res.websiteLogo;
                console.log(res);
            });
        }
    };
    return RegisterComponent;
}());
export { RegisterComponent };
var AzureOrGoogleModalComponent = /** @class */ (function () {
    function AzureOrGoogleModalComponent(dataService) {
        this.dataService = dataService;
        this.ssoEntry = new EventEmitter();
    }
    AzureOrGoogleModalComponent.prototype.ngOnInit = function () { };
    AzureOrGoogleModalComponent.prototype.chooseAzure = function () {
        this.ssoEntry.emit('azure');
    };
    AzureOrGoogleModalComponent.prototype.chooseGoogle = function () {
        this.ssoEntry.emit('google');
    };
    AzureOrGoogleModalComponent.prototype.close = function () {
        this.dataService.triggerCloseModalEvent(1);
    };
    return AzureOrGoogleModalComponent;
}());
export { AzureOrGoogleModalComponent };
