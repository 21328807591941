import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { WebAlertService, WebDataService } from '../../../../_services/index';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { config } from '../../../../../assets/config/configuration';

@Component({
  moduleId: module.id,
  templateUrl: 'saveModal.component.html',
})

export class SaveModalComponent implements OnInit {

  fileName = '';
  success = false;
  loading = false;
  loadingImg: any = config.loadingGif;
  searchQuery = '';
  isFullSearch = false;
  firstName = '';
  lastName = '';
  dob = '';
  country = '';
  state: any = {};
  results: any = {};
  reportResults: any = {};
  saveResults: any = [];
  topicCounts = '';

  constructor(
    private alertService: WebAlertService,
    public bsModalRef: BsModalRef,
    private dataService: WebDataService,
  ) {}

  ngOnInit() {
    this.dataService.currentQuery.subscribe(message => this.searchQuery = message);
    this.dataService.currentResults.subscribe(message => this.results = message);
  }

  saveState() {
    this.dataService.isFullSearch.subscribe(message => this.isFullSearch = message);

    this.loading = true;
    let date = new Date().toLocaleString();
    this.searchQuery = encodeURIComponent(this.searchQuery);
    this.reportResults = this.dataService.reportResults.getValue();
    this.results = this.dataService.results.getValue();
    this.topicCounts = this.dataService.topicCounts.getValue();

    this.saveResults.push({'reportResults': [{'articles': this.reportResults.articles}]}, {'results': this.results}, {'risk': [{'riskAssessment': this.reportResults.riskAssessment}, {'riskAssessmentNote': this.reportResults.riskAssessmentNote}, {'riskLevel': this.reportResults.riskLevel}]}, {'topicCounts': this.topicCounts});

    date = date.replace(/\s/g, '');
    date = date.replace(',', '-');

    if (this.isFullSearch === true) {
      this.dataService.currentfirstName.subscribe(message => this.firstName = message);
      this.dataService.currentlastName.subscribe(message => this.lastName = message);
      this.dataService.currentDob.subscribe(message => this.dob = message);
      this.dataService.currentCountry.subscribe(message => this.country = message);
      this.dob = this.dob.replace('-', '/');
      this.dob = this.dob.replace('-', '/');
      // tslint:disable-next-line:max-line-length
      localStorage.setItem('state-' + this.searchQuery + '-' + date + '-' + this.fileName + '-FS' + '-' + this.firstName + '-' + this.lastName + '-' + this.dob + '-' + this.country, JSON.stringify(this.saveResults));
    } else {
      this.dataService.currentfirstName.subscribe(message => this.firstName = message);
      this.dataService.currentlastName.subscribe(message => this.lastName = message);
      // tslint:disable-next-line:max-line-length
      localStorage.setItem('state-' + this.searchQuery + '-' + date + '-' + this.fileName + '-S' + '-' + this.firstName + '-' + this.lastName, JSON.stringify(this.saveResults));
    }

    this.loading = false;
    this.saveResults = [];
    this.bsModalRef.hide();
    this.alertService.success('Saved successfully.');
  }
}

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'load-modal',
  moduleId: module.id,
  templateUrl: 'loadModal.component.html',
})

export class LoadModalComponent implements OnInit {

  @Output() loadEvent = new EventEmitter();

  loadResults: any = {};
  reportResults: any = {};
  success = false;
  loading = false;
  loadingImg: any = config.loadingGif;
  searchQuery: any;
  savedStates: any = [];
  state  = '';
  onClose: any;
  topicCounts = '';

  constructor(
    public bsModalRef: BsModalRef,
    private dataService: WebDataService
  ) {}

  ngOnInit() {
    for (let i = 0; i < localStorage.length; i++) {
      if (/^state/.test(localStorage.key(i)) === true) {
        this.savedStates.push({'name': localStorage.key(i), 'results': JSON.parse(localStorage.getItem(localStorage.key(i)))});
      }
    }
  }

  loadState() {
    this.reportResults = {};
    this.reportResults.articles = [];
    this.loading = true;
    this.loadResults = JSON.parse(localStorage[this.state]);

    this.reportResults.articles = this.loadResults[0]['reportResults'][0]['articles'];
    this.reportResults.riskLevel = this.loadResults[2]['risk'][2]['riskLevel'];
    this.reportResults.riskAssessment = this.loadResults[2]['risk'][0]['riskAssessment'];
    this.reportResults.riskAssessmentNote = this.loadResults[2]['risk'][1]['riskAssessmentNote'];
    this.topicCounts = this.loadResults[3]['topicCounts'];

    this.dataService.setCurrentResults(this.loadResults[1]['results']);
    this.dataService.setCurrentReportResults(this.reportResults);
    this.dataService.setTopicCounts(this.topicCounts);
    this.searchQuery = this.state.split('-');
    this.dataService.setQuery(decodeURIComponent(this.searchQuery[1]));

    this.loading = false;
    // tslint:disable-next-line:max-line-length
    this.onClose({ search: this.searchQuery[5], firstName: this.searchQuery[6], lastName: this.searchQuery[7], dob: this.searchQuery[8], country: this.searchQuery[9] });
  }
}

@Component({
  moduleId: module.id,
  // tslint:disable-next-line:component-selector
  selector: 'controls',
  templateUrl: 'controls.component.html',
  styleUrls: [ './controls.component.css' ],
})

export class ControlsComponent implements OnInit {

  searchQuery: string;
  results: any = {};
  saveModal: BsModalRef = null;
  loadModal: BsModalRef = null;
  fileName = '';

  @Output() gotResultsEvent = new EventEmitter();
  @Output() loadEvent = new EventEmitter();
  @Output() runChecks = new EventEmitter();

  constructor (private modalService: BsModalService) {}

  ngOnInit() {}

  showSave() {
    this.saveModal = this.modalService.show(SaveModalComponent);
  }

  showLoad() {
    this.loadModal = this.modalService.show(LoadModalComponent);
    this.loadModal.content.onClose = (myData) => {
      this.loadEvent.emit('l-' + myData.search + '-' + myData.firstName + '-' + myData.lastName + '-' + myData.dob + '-' + myData.country);
      this.loadModal.hide();
    };
  }

  emitLoadEvent() {}

  receiveEvents($event) {}
}
