/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./personForm.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./personForm.component";
import * as i3 from "../../../../../_services/calls.service";
import * as i4 from "../../../../../_services/data.service";
import * as i5 from "../../../../../_services/kyb.service";
import * as i6 from "../../../../../_services/alert.service";
import * as i7 from "@angular/router";
var styles_PersonFormComponent = [i0.styles];
var RenderType_PersonFormComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PersonFormComponent, data: {} });
export { RenderType_PersonFormComponent as RenderType_PersonFormComponent };
export function View_PersonFormComponent_0(_l) { return i1.ɵvid(0, [], null, null); }
export function View_PersonFormComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-person-form", [], null, null, null, View_PersonFormComponent_0, RenderType_PersonFormComponent)), i1.ɵdid(1, 245760, null, 0, i2.PersonFormComponent, [i3.CallsService, i4.DataService, i5.KYBService, i6.AlertService, i7.Router], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PersonFormComponentNgFactory = i1.ɵccf("app-person-form", i2.PersonFormComponent, View_PersonFormComponent_Host_0, { currentEntity: "currentEntity", events: "events" }, {}, []);
export { PersonFormComponentNgFactory as PersonFormComponentNgFactory };
