<div class="row" *ngIf="isBrowserSupported">
    <div class="col-lg-12">
        <section style="background:white;">
            <div class="container">
                <div class="board">
                    <div  class="row">
                        <div class="row">
                            <div *ngIf="isveryfing" class="verify-loader">
                                <p> {{ LOADING_MESSAGE }} </p>
                                <div class="lds-ripple">
                                    <div></div>
                                    <div></div>
                                </div> <br />
                                <p>Please wait</p>
                            </div>
                            <div class="onb-hidden" style="margin: 0 auto; padding: 50px;">
                                <!-- <img [src]="imgSrc" alt="CompanyLogo" style="display: block; margin: 0 auto 20px;" /> -->
                                <div class="" style="display: flex; align-items: center; flex-direction: column; margin: 0 auto;">
                                    <div class="circle">
                                        <mat-icon class="mat-tick">done_all</mat-icon>
                                    </div>
                                    <h4 class="head text-center onboardingThankyouTitle" style="width: fit-content;"> {{"THANK_YOU" | translate}}! </h4>
                                </div>
                                <h5 class="head text-center"> {{"THANK_YOU_1" | translate}} </h5>
                                <p class="head text-center onboardingThankyouDescription" style="width: fit-content; margin: 0 auto;">{{"CLOSE_TAB" | translate}}</p>
                                <!-- <a href="/" class="backToHomeBtn" style="margin: 20px auto 0px; padding: 15px 25px; border-radius: 15px; display: block; width: fit-content;">Go to Dashboard</a> -->
                            </div>
                            <div *ngIf="!onboardingPermissionEnabled"> {{ errorMessage }} </div>
                            <section *ngIf="!isveryfing && onboardingPermissionEnabled">
                                <div *ngIf="!onboardingCompleted && registrationCompleted" class="tab-content"
                                    class="{{ isMobile && 'mobileview'}}">
                                    <mat-horizontal-stepper #stepper (selectionChange)="setStepIndex($event)"
                                        [selectedIndex]="registerState">
                                        <mat-step *ngFor='let step of configSteps'>
                                            <ng-template matStepLabel>{{ step.stepName | translate }} </ng-template>

                                            <app-step-page-wrapper [item]="step"></app-step-page-wrapper>
                                        </mat-step>

                                    </mat-horizontal-stepper>
                                    <div class="form-group text-center" *ngIf="!onboardingCompleted">
                                        <button type="button"    
                                        [disabled]="isDisabled()"
                                        *ngIf="videoOrLastStep() && !disableNextButtonMobile() && registrationCompleted"
                                            class=" btn btn-outline-rounded btn-info no-margin-bottom next-btn"
                                            (click)="goToNext()">{{ "NEXT" | translate }} <span
                                                style="margin-left:10px;"
                                                class="glyphicon glyphicon-arrow-right"></span></button>
                                        <button  *ngIf="lastStep() && !customOnboarding" type="button"
                                            class="btn btn-outline-rounded btn-info no-margin-bottom finish-btn"
                                            [style.margin-left]="lastStepIsDisabled ? '0px' : '160px'"
                                            (click)="finish()">{{ "FINISH" | translate }} <span
                                                style="margin-left:10px;"
                                                class="glyphicon glyphicon-arrow-right"></span></button>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</div>
<div *ngIf="!isBrowserSupported">
    <p> {{ "ERROR_SUPPORTED_BROWSERS_HEADER" | translate }}: </p>
    <p> {{ "ERROR_SUPPORTED_BROWSERS_CONTENT" | translate }} </p>
</div>