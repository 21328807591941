/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./error404.component";
var styles_Error404Component = [];
var RenderType_Error404Component = i0.ɵcrt({ encapsulation: 2, styles: styles_Error404Component, data: {} });
export { RenderType_Error404Component as RenderType_Error404Component };
export function View_Error404Component_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "h2", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["Error 404"])), (_l()(), i0.ɵeld(2, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["This page does not exist"]))], null, null); }
export function View_Error404Component_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "ng-component", [], null, null, null, View_Error404Component_0, RenderType_Error404Component)), i0.ɵdid(1, 49152, null, 0, i1.Error404Component, [], null, null)], null, null); }
var Error404ComponentNgFactory = i0.ɵccf("ng-component", i1.Error404Component, View_Error404Component_Host_0, {}, {}, []);
export { Error404ComponentNgFactory as Error404ComponentNgFactory };
