import { Component, OnInit } from '@angular/core';
import { AlertService, CallsService, DataService, KYBService } from '../../_services';
import { BsModalService } from 'ngx-bootstrap/modal';

/**
 * Implements batch user importing (by uploading and parsing a csv file with user details) - not currently used
*/
@Component({
    moduleId: module.id,
    templateUrl: 'reporting.component.html',
    styleUrls: ['./reporting.component.css']
})

export class ReportingComponent implements OnInit {
tab1 = true;
tab2 = false;
tab3 = false;

  constructor(
    private alertService: AlertService,
    private callService: CallsService,
    private dataService: DataService,
    private kybService: KYBService,
    private modalService: BsModalService
  ) {}

  ngOnInit() {}
}
