<div class="col-md-12" style="padding: 0px;">
        <div style="height: 20px"></div>
        <button class="btn btn-success btn-add-new btn-save-information" (click)="addNew(addTemplate)" style="background-color:#355B89;border-color: #355B89;">Add New &nbsp;<i class="fa fa-plus" aria-hidden="true"></i></button>
        <div style="height: 15px"></div>
        <table class="table-roles table table-responsive table-hover" width="100%" style="text-align: left;">
            <thead>
                <tr class="legal-role-settings__heading">
                    <th style="padding: 1em !important;"></th>
                    <th>Role name</th>
                    <th>Risk</th>
                    <th>Self</th>
                    <th>Relation Type</th>
                    <th style="padding: 10px 2px; width: fit-content;">Action</th>
                    <th>Allowed E1</th> 
                    <th>Allowed E2</th>
                    <th>Required documents</th>
                </tr>
            </thead>
            <tbody>
                <tr class="legal-role-settings__data" *ngFor="let legalRoleType of v4RoleTypes; let i = index">
                    <td [ngClass]="{'activate-border': toggleHiddenEntries && i === toggleEntriesId}" class="details-control" style="padding: 1em !important;">
                        <i class="fa fa-plus-square" *ngIf="toggleEntriesId === -1 ? !toggleHiddenEntries && ((legalRoleType.allowableE1EntityTypes | allowedEntitiesPipe).length > 6 || (legalRoleType.allowableE2EntityTypes | allowedEntitiesPipe).length > 6) || (legalRoleType.requiredDocumentGroups | requiredDocumentsPipe).length > 6 : toggleHiddenEntries && toggleEntriesId != i && ((legalRoleType.allowableE1EntityTypes | allowedEntitiesPipe).length > 6 || (legalRoleType.allowableE2EntityTypes | allowedEntitiesPipe).length > 6 || (legalRoleType.requiredDocumentGroups | requiredDocumentsPipe).length > 6)" (click)="showRemaingInformation(i)" style="color: green; cursor: pointer;"></i>
                        <i class="fa fa-plus-square" *ngIf="(legalRoleType.allowableE1EntityTypes | allowedEntitiesPipe).length <= 6 && (legalRoleType.allowableE2EntityTypes | allowedEntitiesPipe).length <= 6 && (legalRoleType.requiredDocumentGroups | requiredDocumentsPipe).length <= 6" style="color: grey;" mute></i>
                        <i class="fa fa-minus-square" *ngIf="toggleHiddenEntries && i === toggleEntriesId" (click)="hideRemaingInformation(i)" style="color: red; cursor: pointer;"></i>
                    </td>
                    <td>{{ legalRoleType.defaultRoleName }}</td>
                    <td>{{ legalRoleType.defaultRisk }}</td>
                    <td>{{ legalRoleType.self }}</td>
                    <td>{{ legalRoleType.relationType }}</td>
                    <td style="padding: 10px 2px; width: fit-content;"><button class="btn btn-warning btn-yellow" style="padding: 6px;" (click)="edit(editTemplate, legalRoleType)"><i class="fa fa-pencil" aria-hidden="true"></i></button></td>
                    <td [ngClass]="{'legal-role-settings__data-e1-expanded': (legalRoleType.allowableE1EntityTypes | allowedEntitiesPipe).length > 60}" class="legal-role-settings__data-e1">
                        <ul class="alowedE1">
                            <li [ngClass]="{'document-elements': toggleHiddenEntries && i === toggleEntriesId, 'document-rest-elements': toggleHiddenEntries && i != toggleEntriesId && ind < 6}" style="text-align: start;" *ngFor="let e1 of (legalRoleType.allowableE1EntityTypes | allowedEntitiesPipe); index as ind" [ngStyle]="{'display': !toggleHiddenEntries && ind < 6 ? 'list-item' : 'none'}">
                                {{ e1 }}
                            </li>
                            <li [ngClass]="{'document-elements-extra-elements': toggleHiddenEntries && i === toggleEntriesId, 'document-rest-elements': toggleHiddenEntries && i != toggleEntriesId && (legalRoleType.allowableE1EntityTypes | allowedEntitiesPipe).length > 6}" [ngStyle]="{'display': (legalRoleType.allowableE1EntityTypes | allowedEntitiesPipe).length > 6 ? 'list-item' : 'none'}" style="text-align: start;">...</li>
                        </ul>
                    </td>
                    <td [ngClass]="{'legal-role-settings__data-e2-expanded': (legalRoleType.allowableE2EntityTypes | allowedEntitiesPipe).length > 60}" class="legal-role-settings__data-e2">
                        <ul class="alowedE2">
                            <li [ngClass]="{'document-elements': toggleHiddenEntries && i === toggleEntriesId, 'document-rest-elements': toggleHiddenEntries && i != toggleEntriesId && ind < 6}" style="text-align: start;" *ngFor="let e2 of (legalRoleType.allowableE2EntityTypes | allowedEntitiesPipe); index as ind" [ngStyle]="{'display': ind < 6 ? 'list-item' : 'none'}">
                                {{ e2 }}
                            </li>
                            <li [ngClass]="{'document-elements-extra-elements': toggleHiddenEntries && i === toggleEntriesId, 'document-rest-elements': toggleHiddenEntries && i != toggleEntriesId && (legalRoleType.allowableE2EntityTypes | allowedEntitiesPipe).length > 6}" [ngStyle]="{'display': (legalRoleType.allowableE2EntityTypes | allowedEntitiesPipe).length > 6 ? 'list-item' : 'none'}" style="text-align: start;">...</li>
                        </ul>
                    </td>
                    <td>
                        <div class="documents">
                            <span [ngClass]="{'legal-role-settings__data-required-documents-expanded': (legalRoleType.requiredDocumentGroups | requiredDocumentsPipe).length > 60}" class="legal-role-settings__data-required-documents">
                                <ul class="requiredDocument">
                                    <li [ngClass]="{'document-elements': toggleHiddenEntries && i === toggleEntriesId, 'document-rest-elements': toggleHiddenEntries && i != toggleEntriesId && ind < 6 && (legalRoleType.requiredDocumentGroups | requiredDocumentsPipe).length > 6}" style="text-align: start" *ngFor="let documents of (legalRoleType.requiredDocumentGroups | requiredDocumentsPipe); index as ind" [ngStyle]="{'display': ind < 6 ? 'list-item' : 'none'}" >
                                        <span>
                                            {{ documents }}
                                        </span>
                                    </li>
                                    <li [ngClass]="{'document-elements-extra-elements': toggleHiddenEntries && i === toggleEntriesId, 'document-rest-elements': toggleHiddenEntries && i != toggleEntriesId && (legalRoleType.requiredDocumentGroups | requiredDocumentsPipe).length > 6}" [ngStyle]="{'display': (legalRoleType.requiredDocumentGroups | requiredDocumentsPipe).length > 6 ? 'list-item' : 'none'}" style="text-align: start;">...</li>
                                </ul>
                            </span>
                            <button class="btn btn-warning btn-yellow" style="margin: 0 0 0 auto;" (click)="editRole(editRoleTemplate, legalRoleType.id)"><i class="fa fa-pencil" aria-hidden="true"></i></button>    
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>

    <ng-template #removeRoleTemplate>
        <div class="modal-header">
          <h4 class="modal-title pull-left">Remove Document</h4>
          <button type="button" class="close pull-right" aria-label="Close" (click)="removeModalRef.hide()">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
            <span>Are you sure, this will remove the following line from the table: OrderID - {{ removeId }}</span>
        </div>
        <div class="modal-footer">
            <button class="btn btn-success btn-save-information" (click)="remove()">YES IM SURE</button>
            <button class="btn btn-warning btn-yellow" (click)="removeModalRef.hide()">CANCEL</button>
        </div>
    </ng-template>

    <ng-template #editRoleTemplate>
        <div class="modal-header">
          <h4 class="modal-title pull-left">Edit Role</h4>
          <button type="button" class="close pull-right" aria-label="Close" (click)="editRoleModalRef.hide()">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body" [ngStyle]="{'padding' : requiredDocuments.length === 0 ? '16px' : '15px', 'min-height' : requiredDocuments.length === 0 ? '120px' : 'unset', 'display' : requiredDocuments.length === 0 ? 'flex' : 'block' }" style="justify-content: center; align-items: center;">
            <div *ngIf="requiredDocuments.length === 0" class="text-center" style="display: flex; justify-content: center; align-items: center; flex-direction: column;">
                <h5 style="margin-top: 0px; margin-bottom: 0px; font-weight: 400;">
                    No documents are required for this role type.
                </h5>
                <h5 style="margin-top: 10px; margin-bottom: 0px; font-weight: 400;">
                    Click <b>Add</b> to add document requirements.
                </h5>
                <button class="btn btn-default btn-noBorder" style="display: flex; align-items: center; margin-top: 20px; background-color: #355b89; color: #fff;" (click)="addAND(addDocumentTypeTemplate)">ADD <mat-icon style="font-size: 18px; width: 18px; height: 18px;">add</mat-icon></button>
            </div>
            <table id="document-table" *ngIf="requiredDocuments.length > 0">
                <thead>
                    <tr>
                        <th>Seq.</th>
                        <th>OrderID</th>
                        <th>Document</th>
                        <th>Risk Trigger</th>
                        <th>Expiration days</th>
                        <th>AND | OR | REMOVE</th>
                    </tr>
                </thead>
                <ng-container *ngFor="let requiredDocument of requiredDocuments; let i = index">
                    <tbody>
                        <ng-container *ngIf="requiredDocument.documents.length === 1">
                            <ng-container *ngFor="let document of requiredDocument.documents">
                                <span *ngIf="i !== 0" class="separator">- AND -</span>
                                <tr>
                                    <td>
                                        <button *ngIf="i !== 0" (click)="upClicked(i)">
                                            <i class="fa fa-arrow-up" aria-hidden="true"></i>
                                        </button>
                                        <button *ngIf="i !== requiredDocuments.length - 1" (click)="downClicked(i)">
                                            <i class="fa fa-arrow-down" aria-hidden="true"></i>
                                        </button>
                                    </td>
                                    <td>{{ document.id }}</td>
                                    <td>{{ document.description }}</td>
                                    <td>
                                        <ng-select
                                            [items]="riskLevels"
                                            [(ngModel)]="requiredDocument.riskLevel"
                                            [clearable]="false">
                                        </ng-select>
                                    </td>
                                    <td>{{ document.documentExpiration | secondsToDays }}</td>
                                    <td class="buttons">
                                        <div>
                                            <button class="btn btn-default btn-noBorder" (click)="addAND(addDocumentTypeTemplate)">AND</button>
                                            <button class="btn btn-default btn-noBorder" (click)="addOR(addDocumentTypeTemplate, document.id)">OR</button>
                                            <button class="btn btn-default btn-noBorder" (click)="removeDocument(removeRoleTemplate, document.id)">REMOVE</button>
                                        </div>
                                    </td>
                                </tr>
                            </ng-container>
                        </ng-container>
                    </tbody>
                    <tbody>
                        <ng-container *ngIf="requiredDocument.documents.length > 1">
                            <span *ngIf="i !== 0" class="separator">- AND -</span>
                            <tr>
                                <td>
                                    <button *ngIf="i !== 0" (click)="upClicked(i)">
                                        <i class="fa fa-arrow-up" aria-hidden="true"></i>
                                    </button>
                                    <button *ngIf="i !== requiredDocuments.length - 1" (click)="downClicked(i)">
                                        <i class="fa fa-arrow-down" aria-hidden="true"></i>
                                    </button>
                                </td>
                                <td>{{ requiredDocument.documents[0].id }}</td>
                                <td>{{ requiredDocument.documents[0].description }}</td>
                                <td>
                                    <ng-select
                                        [items]="riskLevels"
                                        [(ngModel)]="requiredDocument.riskLevel"
                                        [clearable]="false">
                                    </ng-select>
                                </td>
                                <td>{{ requiredDocument.documents[0].documentExpiration | secondsToDays }}</td>
                                <td class="buttons">
                                    <div>
                                        <button class="btn btn-default btn-noBorder" (click)="addAND(addDocumentTypeTemplate)">AND</button>
                                        <button class="btn btn-default btn-noBorder" (click)="addOR(addDocumentTypeTemplate, requiredDocument.documents[0].id)">OR</button>
                                        <button class="btn btn-default btn-noBorder" (click)="removeDocument(removeRoleTemplate, requiredDocument.documents[0].id)">REMOVE</button>
                                    </div>
                                </td>
                            </tr>
                            <ng-container *ngFor="let document of requiredDocument.documents; let i = index">
                                <span *ngIf="i !== 0" class="separator">- OR -</span>
                                <tr *ngIf="i !== 0">
                                    <td></td>
                                    <td></td>
                                    <td>{{ document.description }}</td>
                                    <td></td>
                                    <td>{{ document.documentExpiration | secondsToDays }}</td>
                                    <td class="buttons">
                                        <div>
                                            <button class="btn btn-default btn-noBorder" (click)="addAND(addDocumentTypeTemplate)">AND</button>
                                            <button class="btn btn-default btn-noBorder" (click)="addOR(addDocumentTypeTemplate, document.id)">OR</button>
                                            <button class="btn btn-default btn-noBorder" (click)="removeDocument(removeRoleTemplate, document.id)">REMOVE</button>
                                        </div>
                                    </td>
                                </tr>
                            </ng-container>
                        </ng-container>
                    </tbody>
                </ng-container>
            </table>
        </div>
        
        <div class="modal-footer">
            <button class="btn btn-success btn-save-information" (click)="saveRole()">Save</button>
            <button class="btn btn-warning btn-yellow" (click)="editRoleModalRef.hide()">Cancel</button>
        </div>
    </ng-template>

    <ng-template #addDocumentTypeTemplate>
        <div class="modal-header">
            <h4 class="modal-title pull-left">Add Document Type</h4>
            <button type="button" class="close pull-right" aria-label="Close" (click)="addModalRef.hide()">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <div class="row" *ngIf="isAND">
                <div class="col-md-8">
                    <ng-select [(ngModel)]="currentDocumentType" class="custom" [items]="documentTypes" bindLabel="description" bindValue="id" appendTo="body"></ng-select>
                </div>
                <div class="col-md-4">
                    <ng-select [items]="riskLevels" [(ngModel)]="currentRiskLevel" [clearable]="false"></ng-select>
                </div>
            </div>
            <ng-select *ngIf="isOR" [(ngModel)]="currentDocumentType" class="custom" [items]="documentTypes" bindLabel="description" bindValue="id" appendTo="body"></ng-select>
        </div>
        <div class="modal-footer">
            <button class="btn btn-success btn-save-information" (click)="addDocument()">Add</button>
            <button class="btn btn-warning btn-yellow" (click)="addModalRef.hide()">Cancel</button>
        </div>
    </ng-template>

    <ng-template #editTemplate>
        <form [formGroup]="editLegalRoleTypeForm">
            <div class="modal-header">
                <h4 class="modal-title pull-left">Edit Legal Role Type</h4>
                <button type="button" class="close pull-right" aria-label="Close" (click)="editModalRef.hide()">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                  <label>
                      Role name:
                  </label><br>
                  <div style="height: 10px;"></div>
                  <input type="text" formControlName="defaultRoleName">
                  <div style="height: 10px;"></div>
                  <label>
                      Default risk:
                  </label><br>
                  <div style="height: 10px;"></div>
                  <input type="number" min="0" formControlName="defaultRisk">
                  <div style="height: 10px;"></div>
                  <label>
                      Self:
                  </label>&nbsp;<input type="checkbox" formControlName="self">
                  <div style="height: 10px;"></div>
                  <label>
                      Relation type:
                  </label><br>
                  <div style="height: 10px;"></div>
                  <select formControlName="relationType" name="relationType">
                      <option value="NONE">NONE</option>
                      <option value="OWNER">OWNER</option>
                      <option value="CONTROL">CONTROL</option>
                      <option value="OWNER_AND_CONTROL">OWNER AND CONTROL</option>
                      <option value="GEO">GEO</option>
                  </select>
                  <div style="height: 10px;"></div>
                  <label>
                      Allowable entity 1 types:
                  </label><br>
                  <div style="height: 10px;"></div>
                  <ng-select
                      [items]="entityTypes"
                      [multiple]="true"
                      bindLabel="legalPersonType"
                      [selectableGroup]="false"
                      [closeOnSelect]="false"
                      bindValue="id"
                      [class.self]="editLegalRoleTypeForm.get('self').value"
                      formControlName="allowableE1EntityTypes">
                      <ng-template ng-header-tmp>
                          <div>
                              <button class="btn btn-link btn-noBorder" (click)="selectAllE1('edit')">Select All</button>
                          </div>
                      </ng-template>
                      <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                          <input id="item-{{ index }}" type="checkbox" [ngModel]="item$.selected" [ngModelOptions]="{ standalone: true }"> {{ item.legalPersonType }}
                      </ng-template>
                  </ng-select>
                  <div style="height: 10px;"></div>
                  <ng-container *ngIf="!editLegalRoleTypeForm.get('self').value">
                      <label>
                          Allowable entity 2 types:
                      </label><br>
                      <div style="height: 10px;"></div>
                      <ng-select
                          [items]="entityTypes"
                          [multiple]="true"
                          bindLabel="legalPersonType"
                          [selectableGroup]="false"
                          [closeOnSelect]="false"
                          bindValue="id"
                          formControlName="allowableE2EntityTypes">
                          <ng-template ng-header-tmp>
                              <div>
                                  <button class="btn btn-link btn-noBorder" (click)="selectAllE2('edit')">Select All</button>
                              </div>
                          </ng-template>
                          <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                              <input id="item-{{ index }}" type="checkbox" [ngModel]="item$.selected" [ngModelOptions]="{ standalone: true }"> {{ item.legalPersonType }}
                          </ng-template>
                      </ng-select>
                      <div style="height: 10px;"></div>
                  </ng-container>
              </div>
              <div class="modal-footer">
                  <button class="btn btn-success btn-save-information" type="submit" [disabled]="!editLegalRoleTypeForm.valid" (click)="save()">Save</button>
              </div>
        </form>
    </ng-template>

    <ng-template #addTemplate>
        <form [formGroup]="addLegalRoleTypeForm">
            <div class="modal-header">
              <h4 class="modal-title pull-left">Add Legal Role Type</h4>
              <button type="button" class="close pull-right" aria-label="Close" (click)="addModalRef.hide()">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
                <label>
                    Role name:
                </label><br>
                <div style="height: 10px;"></div>
                <input type="text" formControlName="defaultRoleName" (focusout)="changeRoleName()">
                <div class="alert alert-danger" [hidden]="isUnique">The role name must be unique.</div>
                <div style="height: 10px;"></div>
                <label>
                    Default risk:
                </label><br>
                <div style="height: 10px;"></div>
                <input type="number" min="0" formControlName="defaultRisk">
                <div style="height: 10px;"></div>
                <label>
                    Relation type:
                </label><br>
                <div style="height: 10px;"></div>
                <select formControlName="relationType" name="relationType">
                    <option value="NONE">NONE</option>
                    <option value="OWNER">OWNER</option>
                    <option value="CONTROL">CONTROL</option>
                    <option value="OWNER_AND_CONTROL">OWNER AND CONTROL</option>
                    <option value="GEO">GEO</option>
                </select>
                <div style="height: 10px;"></div>
                <label>
                    Allowable entity 1 types:
                </label><br>
                <div style="height: 10px;"></div>
                <ng-select
                    [items]="entityTypes"
                    [multiple]="true"
                    bindLabel="legalPersonType"
                    [selectableGroup]="false"
                    [closeOnSelect]="false"
                    bindValue="id"
                    formControlName="allowableE1EntityTypes">
                    <ng-template ng-header-tmp>
                        <div>
                            <button class="btn btn-link btn-noBorder" (click)="selectAllE1('add')">Select All</button>
                        </div>
                    </ng-template>
                    <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                        <input id="item-{{ index }}" type="checkbox" [ngModel]="item$.selected" [ngModelOptions]="{ standalone: true }"> {{ item.legalPersonType }}
                    </ng-template>
                </ng-select>
                <div style="height: 10px;"></div>
                <ng-container *ngIf="!currentRoleType.self">
                    <label>
                        Allowable entity 2 types:
                    </label><br>
                    <div style="height: 10px;"></div>
                    <ng-select
                        [items]="entityTypes"
                        [multiple]="true"
                        bindLabel="legalPersonType"
                        [selectableGroup]="false"
                        [closeOnSelect]="false"
                        bindValue="id"
                        formControlName="allowableE2EntityTypes">
                        <ng-template ng-header-tmp>
                            <div>
                                <button class="btn btn-link btn-noBorder" (click)="selectAllE2('add')">Select All</button>
                            </div>
                        </ng-template>
                        <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                            <input id="item-{{ index }}" type="checkbox" [ngModel]="item$.selected" [ngModelOptions]="{ standalone: true }"> {{ item.legalPersonType }}
                        </ng-template>
                    </ng-select>
                    <div style="height: 10px;"></div>
                </ng-container>
            </div>
            <div class="modal-footer">
                <button class="btn btn-success btn-save-information" type="submit" [disabled]="!addLegalRoleTypeForm.valid || !isUnique" (click)="add()">Add</button>
            </div>
        </form>
    </ng-template>

    <style>
        .document-elements{
            display: list-item !important;
        }
        .document-elements-extra-elements{
            display: none !important;
        }
        .document-rest-elements{
            display: list-item !important;
        }
        .legal-role-settings__data:has(.activate-border){
            border-top: none;
            border-bottom: none;
            border-right: none;
        }
        .legal-role-settings__data:has(.activate-border) > td{
            border-top: 4px double #ddd;
            border-bottom: 4px double #ddd;
        }
        .legal-role-settings__data:has(.activate-border) > td:nth-child(1){
            border-left: 4px double #ddd;
        }
        .legal-role-settings__data:has(.activate-border) > td:nth-last-child(1){
            border-right: 4px double #ddd;
        }
    </style>