import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CallsService } from '../../_services';
import { DomSanitizer } from '@angular/platform-browser';
import { config } from '../../../assets/configuration';
import { FormDataService } from '../../user_management/register/data/formData.service';

/**
 * Implements the user account page
*/
@Component({
    moduleId: module.id,
    templateUrl: 'myAccount.component.html',
    styleUrls: [ './myAccount.component.css' ]
})

export class MyAccountComponent implements OnInit {
  twofactorDisplay = false;

  passwordDisplay = true;
  hiddenTagsDisplay = false;
  mainColor: any = config.mainColor;

  constructor (
    private callsService: CallsService,
    private route: ActivatedRoute,
    private domSanitizer: DomSanitizer,
    private formDataService: FormDataService
  ) {}

  ngOnInit() {}

  menuClick(selectedItem: string): void {
    this.passwordDisplay = selectedItem === 'passwordChange';
    this.hiddenTagsDisplay = selectedItem === 'hiddenTags';
    this.twofactorDisplay = selectedItem === 'twofactorDisplay';

  }
}
