/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./mobile-video-onboarding-recording.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@ngx-translate/core";
import * as i3 from "@angular/common";
import * as i4 from "./mobile-video-onboarding-recording.component";
import * as i5 from "ngx-bootstrap/modal";
import * as i6 from "ngx-device-detector";
import * as i7 from "../../../../_services/video-recording.service";
import * as i8 from "@angular/platform-browser";
import * as i9 from "../../../../_services/kyb.service";
import * as i10 from "../../../../_services/calls.service";
import * as i11 from "../../../../_services/data.service";
import * as i12 from "../../data/formData.service";
var styles_MobileVideoOnboardingRecordingComponent = [i0.styles];
var RenderType_MobileVideoOnboardingRecordingComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_MobileVideoOnboardingRecordingComponent, data: {} });
export { RenderType_MobileVideoOnboardingRecordingComponent as RenderType_MobileVideoOnboardingRecordingComponent };
function View_MobileVideoOnboardingRecordingComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h4", [["style", "color: white; text-align: center;"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.textfromerror; _ck(_v, 2, 0, currVal_0); }); }
function View_MobileVideoOnboardingRecordingComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "div", [["class", "words"], ["style", "display: none;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "div", [["class", "overlay-desc"], ["style", "color: black; position: relative;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, [[1, 0], ["videoElement", 1]], null, 0, "video", [["autoplay", ""], ["class", "videoRecord"], ["muted", ""], ["playsinline", ""], ["style", "transform: rotateY(180deg);\n    -webkit-transform:rotateY(180deg); /* Safari and Chrome */\n    -moz-transform:rotateY(180deg); /* Firefox */"]], null, null, null, null, null))], null, null); }
function View_MobileVideoOnboardingRecordingComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["style", "text-align: center;position: relative; font-size: 16px;color: white;"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", " "])), i1.ɵpid(131072, i2.TranslatePipe, [i2.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(3, 0, null, null, 1, "b", [["class", "text-danger Blink"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["\u2B24"]))], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform("VIDEO_RECORDING")); _ck(_v, 1, 0, currVal_0); }); }
export function View_MobileVideoOnboardingRecordingComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(671088640, 1, { videoElement: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 1, "div", [["class", "modal-header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "span", [["class", "glyphicon glyphicon-arrow-left"], ["style", "color: white;"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.cancel() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 6, "div", [["class", "modal-body"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MobileVideoOnboardingRecordingComponent_1)), i1.ɵdid(5, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MobileVideoOnboardingRecordingComponent_2)), i1.ɵdid(7, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MobileVideoOnboardingRecordingComponent_3)), i1.ɵdid(9, 16384, null, 0, i3.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.permissionDenied; _ck(_v, 5, 0, currVal_0); var currVal_1 = !_co.permissionDenied; _ck(_v, 7, 0, currVal_1); var currVal_2 = _co.isVideoRecording; _ck(_v, 9, 0, currVal_2); }, null); }
export function View_MobileVideoOnboardingRecordingComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "mobile-video-onboarding-recording", [], null, null, null, View_MobileVideoOnboardingRecordingComponent_0, RenderType_MobileVideoOnboardingRecordingComponent)), i1.ɵdid(1, 114688, null, 0, i4.MobileVideoOnboardingRecordingComponent, [i2.TranslateService, i5.ModalOptions, i5.BsModalRef, i5.BsModalService, i6.DeviceDetectorService, i1.ChangeDetectorRef, i7.VideoRecordingService, i8.DomSanitizer, i9.KYBService, i10.CallsService, i11.DataService, i12.FormDataService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var MobileVideoOnboardingRecordingComponentNgFactory = i1.ɵccf("mobile-video-onboarding-recording", i4.MobileVideoOnboardingRecordingComponent, View_MobileVideoOnboardingRecordingComponent_Host_0, {}, {}, []);
export { MobileVideoOnboardingRecordingComponentNgFactory as MobileVideoOnboardingRecordingComponentNgFactory };
