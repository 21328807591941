import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'parseSource'})
export class ParseSourcePipe implements PipeTransform {

  sources: any = [];

  constructor() {}

  transform(value: any) {
    const source = value.split(':')[0];

    if (source === 'can_sanctions') {
      return 'Canadian Sanctions List';
    }

    if (source === 'turkishpolice') {
      return 'Turkish Identity File';
    }

    if (source === 'cssfsupervisedentities') {
      return 'CSSF Supervised Entities List';
    }

    if (source === 'resa') {
      return 'RESA Supervised Entities List';
    }

    if (source === 'bce') {
      return 'Belgian Company Registry';
    }

    if (source === 'companieshouse') {
      return 'UK Company Registry';
    }

    if (source === 'companieshousepeople') {
      return 'UK Company Registry People List';
    }

    if (source === 'wikipeps') {
      return 'Wikipedia PEP List';
    }

    if (source.indexOf('shab') !== -1) {
      return 'Swiss Official Gazette of Commerce (SOGC)';
    }
  }
}
