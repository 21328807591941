/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./myAccount.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../settings/passwordChange/passwordChange.component.ngfactory";
import * as i3 from "../settings/passwordChange/passwordChange.component";
import * as i4 from "@angular/router";
import * as i5 from "../../_services/calls.service";
import * as i6 from "../../_services/alert.service";
import * as i7 from "../../_services/authentication.service";
import * as i8 from "../../_services/kyb.service";
import * as i9 from "../hiddenTags/hiddenTags.component.ngfactory";
import * as i10 from "../hiddenTags/hiddenTags.component";
import * as i11 from "../settings/userManagement/setup2FA/setup2FA.component.ngfactory";
import * as i12 from "../settings/userManagement/setup2FA/setup2FA.component";
import * as i13 from "@angular/common";
import * as i14 from "./myAccount.component";
import * as i15 from "@angular/platform-browser";
import * as i16 from "../register/data/formData.service";
var styles_MyAccountComponent = [i0.styles];
var RenderType_MyAccountComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_MyAccountComponent, data: {} });
export { RenderType_MyAccountComponent as RenderType_MyAccountComponent };
function View_MyAccountComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-password-change", [], null, null, null, i2.View_PasswordChangeComponent_0, i2.RenderType_PasswordChangeComponent)), i1.ɵdid(1, 49152, null, 0, i3.PasswordChangeComponent, [i4.Router, i5.CallsService, i6.AlertService, i7.AuthenticationService, i8.KYBService], null, null)], null, null); }
function View_MyAccountComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-hidden-tags", [], null, null, null, i9.View_HiddenTagsComponent_0, i9.RenderType_HiddenTagsComponent)), i1.ɵdid(1, 114688, null, 0, i10.HiddenTagsComponent, [i8.KYBService, i6.AlertService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
function View_MyAccountComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-setup-2fa", [], null, null, null, i11.View_Setup2FAComponent_0, i11.RenderType_Setup2FAComponent)), i1.ɵdid(1, 114688, null, 0, i12.Setup2FAComponent, [i6.AlertService, i7.AuthenticationService, i8.KYBService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
export function View_MyAccountComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 25, "div", [[":xmlns:height", "http://www.w3.org/1999/xhtml"], ["class", "col-md-10 col-md-offset-1"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "div", [["style", "height: 30px"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 23, "div", [["class", "wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 14, "nav", [["class", "col-md-4"], ["id", "sidebar"], ["style", "background-color: white;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 3, "div", [["class", "sidebar-header"], ["style", "padding: 20px"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 2, "h3", [], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 0, "span", [["class", "glyphicon glyphicon-cog"], ["style", "font-size: 18px"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, [" My Account "])), (_l()(), i1.ɵeld(8, 0, null, null, 9, "ul", [["class", "list-unstyled components"], ["style", "padding-left: 20px; padding-right: 20px;padding-bottom: 5px"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 2, "li", [], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 1, "a", [], [[4, "color", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.menuClick("passwordChange") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Password Change"])), (_l()(), i1.ɵeld(12, 0, null, null, 2, "li", [], null, null, null, null, null)), (_l()(), i1.ɵeld(13, 0, null, null, 1, "a", [], [[4, "color", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.menuClick("hiddenTags") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Hidden Tags"])), (_l()(), i1.ɵeld(15, 0, null, null, 2, "li", [], null, null, null, null, null)), (_l()(), i1.ɵeld(16, 0, null, null, 1, "a", [], [[4, "color", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.menuClick("twofactorDisplay") !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["2FA"])), (_l()(), i1.ɵeld(18, 0, null, null, 7, "div", [["class", "col-md-8"], ["id", "content"], ["style", "background-color: white; padding: 20px"]], null, null, null, null, null)), (_l()(), i1.ɵeld(19, 0, null, null, 0, "div", [["style", "height: 40px"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MyAccountComponent_1)), i1.ɵdid(21, 16384, null, 0, i13.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MyAccountComponent_2)), i1.ɵdid(23, 16384, null, 0, i13.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MyAccountComponent_3)), i1.ɵdid(25, 16384, null, 0, i13.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_3 = _co.passwordDisplay; _ck(_v, 21, 0, currVal_3); var currVal_4 = _co.hiddenTagsDisplay; _ck(_v, 23, 0, currVal_4); var currVal_5 = _co.twofactorDisplay; _ck(_v, 25, 0, currVal_5); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.mainColor; _ck(_v, 10, 0, currVal_0); var currVal_1 = _co.mainColor; _ck(_v, 13, 0, currVal_1); var currVal_2 = _co.mainColor; _ck(_v, 16, 0, currVal_2); }); }
export function View_MyAccountComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "ng-component", [], null, null, null, View_MyAccountComponent_0, RenderType_MyAccountComponent)), i1.ɵdid(1, 114688, null, 0, i14.MyAccountComponent, [i5.CallsService, i4.ActivatedRoute, i15.DomSanitizer, i16.FormDataService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var MyAccountComponentNgFactory = i1.ɵccf("ng-component", i14.MyAccountComponent, View_MyAccountComponent_Host_0, {}, {}, []);
export { MyAccountComponentNgFactory as MyAccountComponentNgFactory };
