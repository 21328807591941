import * as tslib_1 from "tslib";
import { OnInit } from '@angular/core';
import { AlertService } from '../../_services';
import { Papa } from 'ngx-papaparse';
/**
 * Implements batch user importing (by uploading and parsing a csv file with user details) - not currently used
*/
var BatchComponent = /** @class */ (function () {
    function BatchComponent(alertService, papa) {
        this.alertService = alertService;
        this.papa = papa;
        this.model = {};
        this.loading = false;
        this.batchFileFilled = false;
        this.batchFile = null;
        this.csv = '';
    }
    BatchComponent.prototype.ngOnInit = function () { };
    /**
    * Function that detects if batch file is filled
    */
    BatchComponent.prototype.isBatchFileFilled = function () {
        this.batchFileFilled = true;
    };
    /**
    * Function that opens batch file input window
    */
    BatchComponent.prototype.openBatchFileInput = function () {
        document.getElementById('batchFile').click();
    };
    /**
    * Function that shows batch file name
    */
    BatchComponent.prototype.showBatchFile = function (event) {
        var _this = this;
        if (event.target.files && event.target.files.length) {
            var file = event.target.files[0];
            this.batchFile = file;
            var reader_1 = new FileReader();
            reader_1.readAsText(this.batchFile);
            reader_1.onload = function (e) {
                _this.csv = reader_1.result;
                _this.papa.parse(_this.csv, {
                    header: true,
                    chunk: function (result) {
                        var i, j, temparray;
                        var chunk = 100000;
                        for (i = 0, j = result.data.length; i < j; i += chunk) {
                            temparray = result.data.slice(i, i + chunk);
                        }
                    }
                });
            };
        }
        document.getElementById('filenameBatchFile').innerHTML = this.batchFile.name;
    };
    /**
    * Function that parses CSV
    */
    BatchComponent.prototype.parseCsv = function (fileContent) {
        var lines = fileContent.split('\n');
        for (var i = 0; i < lines.length; i++) {
            var columns = lines[i].split(',');
            this.model.firstName = columns[0];
            this.model.lastName = columns[1];
            this.model.dateOfBirth = columns[2];
            this.model.address = columns[3];
            this.model.something = columns[4];
            this.model.somethingElse = columns[5];
            this.model = {};
        }
    };
    /**
    * Function that sends parsed information to database
    */
    BatchComponent.prototype.process = function (form) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                this.loading = true;
                if (this.batchFileFilled) {
                    this.parseCsv(this.csv);
                    this.loading = false;
                }
                else {
                    this.loading = false;
                    this.alertService.showError('Please upload a batch file in CSV format.');
                }
                return [2 /*return*/];
            });
        });
    };
    return BatchComponent;
}());
export { BatchComponent };
