import * as networkgraph from 'highcharts/modules/networkgraph.src';
import * as sankey from 'highcharts/modules/sankey.src';
import * as organization from 'highcharts/modules/organization.src';
import * as exporting from 'highcharts/modules/exporting.src';
import * as serieslabel from 'highcharts/modules/series-label.src';
var ɵ0 = function () { return [
    networkgraph,
    sankey,
    organization,
    exporting,
    serieslabel,
]; };
var DashboardModule = /** @class */ (function () {
    function DashboardModule() {
    }
    return DashboardModule;
}());
export { DashboardModule };
export { ɵ0 };
