import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ScreeningReportComponent } from './screeningReport.component';
import { FormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { NgSelectModule } from '@ng-select/ng-select';
import { MatIconModule } from '@angular/material/icon';
import { ChartModule, HIGHCHARTS_MODULES } from 'angular-highcharts';
import * as networkgraph from 'highcharts/modules/networkgraph.src';
import * as sankey from 'highcharts/modules/sankey.src';
import * as organization from 'highcharts/modules/organization.src';
import * as exporting from 'highcharts/modules/exporting.src';
import * as serieslabel from 'highcharts/modules/series-label.src';

@NgModule({
  declarations: [
    ScreeningReportComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    MatInputModule,
    NgSelectModule,
    BsDatepickerModule,
    MatIconModule,
    ChartModule
  ],
  providers: [
    {
      provide: HIGHCHARTS_MODULES,
      useFactory: () => [
        networkgraph,
        sankey,
        organization,
        exporting,
        serieslabel,
      ],
    },
  ],
  exports: [
    ScreeningReportComponent
  ]
})
export class ScreeningReportModule { }
