import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'mimeTypePipe'})
export class MimeTypePipe implements PipeTransform {
  transform(value: any): string {
    let resultArr: any;
    value = value + ';';
    resultArr = value.split(';');
    resultArr.splice(-1);
    return resultArr;
  }
}
