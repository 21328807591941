/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./mobile-document-onboarding-image-capture.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../../node_modules/ngx-webcam/ngx-webcam.ngfactory";
import * as i3 from "ngx-webcam";
import * as i4 from "@angular/common";
import * as i5 from "@ngx-translate/core";
import * as i6 from "./mobile-document-onboarding-image-capture.component";
import * as i7 from "ngx-bootstrap/modal";
import * as i8 from "@angular/router";
import * as i9 from "../../data/formData.service";
import * as i10 from "../../../../_services/kyb.service";
import * as i11 from "../../../../_services/calls.service";
import * as i12 from "../../../../_services/alert.service";
import * as i13 from "../../../../_services/data.service";
import * as i14 from "ngx-device-detector";
var styles_MobileDocumentOnboardingImageCaptureComponent = [i0.styles];
var RenderType_MobileDocumentOnboardingImageCaptureComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_MobileDocumentOnboardingImageCaptureComponent, data: {} });
export { RenderType_MobileDocumentOnboardingImageCaptureComponent as RenderType_MobileDocumentOnboardingImageCaptureComponent };
function View_MobileDocumentOnboardingImageCaptureComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h4", [["style", "color: white; text-align: center;"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.CAMERA_MIC_ACCESS_BLOCKED; _ck(_v, 2, 0, currVal_0); }); }
function View_MobileDocumentOnboardingImageCaptureComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "webcam", [], null, [[null, "imageCapture"], [null, "cameraSwitched"], [null, "initError"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("imageCapture" === en)) {
        var pd_0 = (_co.handleImage($event) !== false);
        ad = (pd_0 && ad);
    } if (("cameraSwitched" === en)) {
        var pd_1 = (_co.cameraWasSwitched($event) !== false);
        ad = (pd_1 && ad);
    } if (("initError" === en)) {
        var pd_2 = (_co.handleInitError($event) !== false);
        ad = (pd_2 && ad);
    } return ad; }, i2.View_WebcamComponent_0, i2.RenderType_WebcamComponent)), i1.ɵdid(1, 4374528, null, 0, i3.WebcamComponent, [], { width: [0, "width"], height: [1, "height"], videoOptions: [2, "videoOptions"], allowCameraSwitch: [3, "allowCameraSwitch"], imageQuality: [4, "imageQuality"], trigger: [5, "trigger"], switchCamera: [6, "switchCamera"] }, { imageCapture: "imageCapture", initError: "initError", cameraSwitched: "cameraSwitched" })], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.width; var currVal_1 = _co.height; var currVal_2 = _co.videoOptions; var currVal_3 = _co.allowCameraSwitch; var currVal_4 = 1; var currVal_5 = _co.triggerObservable; var currVal_6 = _co.nextWebcamObservable; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }, null); }
function View_MobileDocumentOnboardingImageCaptureComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h4", [["style", "color: white; text-align: center;"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), (_l()(), i1.ɵeld(3, 0, null, null, 3, "div", [["class", "img-responsive"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 0, "img", [["id", "overlay-image"], ["style", "opacity: 0.6;z-index: 2;position:absolute;"], ["width", "100%"]], [[8, "src", 4]], null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MobileDocumentOnboardingImageCaptureComponent_4)), i1.ɵdid(6, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(7, 0, null, null, 0, "button", [["class", "button"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.triggerSnapshot() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_2 = _co.showWebcam; _ck(_v, 6, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.imageText; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.imageScr; _ck(_v, 4, 0, currVal_1); }); }
function View_MobileDocumentOnboardingImageCaptureComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "img", [["width", "95%"]], [[8, "src", 4]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.passportCapture; _ck(_v, 1, 0, currVal_0); }); }
function View_MobileDocumentOnboardingImageCaptureComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "img", [["width", "95%"]], [[8, "src", 4]], null, null, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.identityDocument; _ck(_v, 1, 0, currVal_0); }); }
function View_MobileDocumentOnboardingImageCaptureComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 14, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h4", [["style", "color: white; text-align: center;"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), (_l()(), i1.ɵeld(3, 0, null, null, 11, "div", [["class", "img-responsive"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MobileDocumentOnboardingImageCaptureComponent_6)), i1.ɵdid(5, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MobileDocumentOnboardingImageCaptureComponent_7)), i1.ɵdid(7, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(8, 0, null, null, 6, "div", [["class", "button-retake-use"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 2, "button", [["class", " btn btn-outline-rounded btn-info no-margin-bottom"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.retake() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(10, null, ["", " "])), i1.ɵpid(131072, i5.TranslatePipe, [i5.TranslateService, i1.ChangeDetectorRef]), (_l()(), i1.ɵeld(12, 0, null, null, 2, "button", [["class", " btn btn-outline-rounded btn-info no-margin-bottom"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.use() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(13, null, ["", " "])), i1.ɵpid(131072, i5.TranslatePipe, [i5.TranslateService, i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_1 = (_co.documentType == "passport"); _ck(_v, 5, 0, currVal_1); var currVal_2 = (_co.documentType != "passport"); _ck(_v, 7, 0, currVal_2); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.imageText; _ck(_v, 2, 0, currVal_0); var currVal_3 = i1.ɵunv(_v, 10, 0, i1.ɵnov(_v, 11).transform("RETAKE")); _ck(_v, 10, 0, currVal_3); var currVal_4 = i1.ɵunv(_v, 13, 0, i1.ɵnov(_v, 14).transform("USE")); _ck(_v, 13, 0, currVal_4); }); }
function View_MobileDocumentOnboardingImageCaptureComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MobileDocumentOnboardingImageCaptureComponent_3)), i1.ɵdid(2, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MobileDocumentOnboardingImageCaptureComponent_5)), i1.ɵdid(4, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.captureEnabled; _ck(_v, 2, 0, currVal_0); var currVal_1 = !_co.captureEnabled; _ck(_v, 4, 0, currVal_1); }, null); }
export function View_MobileDocumentOnboardingImageCaptureComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "div", [["class", "modal-header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "span", [["class", "glyphicon glyphicon-arrow-left"], ["style", "color: white;"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.cancel() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 4, "div", [["class", "modal-body"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MobileDocumentOnboardingImageCaptureComponent_1)), i1.ɵdid(4, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_MobileDocumentOnboardingImageCaptureComponent_2)), i1.ɵdid(6, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.permissionDenied; _ck(_v, 4, 0, currVal_0); var currVal_1 = !_co.permissionDenied; _ck(_v, 6, 0, currVal_1); }, null); }
export function View_MobileDocumentOnboardingImageCaptureComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "mobile-document-onboarding-image-capture", [], null, null, null, View_MobileDocumentOnboardingImageCaptureComponent_0, RenderType_MobileDocumentOnboardingImageCaptureComponent)), i1.ɵdid(1, 245760, null, 0, i6.MobileDocumentOnboardingImageCaptureComponent, [i5.TranslateService, i7.BsModalRef, i8.Router, i9.FormDataService, i10.KYBService, i1.ElementRef, i11.CallsService, i12.AlertService, i13.DataService, i14.DeviceDetectorService, i7.ModalOptions], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var MobileDocumentOnboardingImageCaptureComponentNgFactory = i1.ɵccf("mobile-document-onboarding-image-capture", i6.MobileDocumentOnboardingImageCaptureComponent, View_MobileDocumentOnboardingImageCaptureComponent_Host_0, {}, {}, []);
export { MobileDocumentOnboardingImageCaptureComponentNgFactory as MobileDocumentOnboardingImageCaptureComponentNgFactory };
