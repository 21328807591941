<div class="col-md-12" style="padding-left: 0px;" xmlns:height="http://www.w3.org/1999/xhtml">
   <div class="row">
      <div class="col-md-12">
         <p>Select the role you want to attach this document to: <b class="required">*</b></p><br>
         <select [(ngModel)]="roleId" (change)="roleIdChanged()" id="role-selector">
            <option *ngFor="let role of roles" [value]="role.id">[{{role.id}}] {{role?.roleType?.defaultRoleName}} -
               (<span *ngIf="role.e1Instance != null">Entity 1: {{role?.e1Instance?.primaryName}}</span><span *ngIf="role.e2Instance != null">, Entity 2:
                  {{role?.e2Instance?.primaryName}}</span>)</option>
         </select>
         <div style="height: 20px;"></div>
         <p>Select the type of document: <b class="required">*</b></p><br>
         <select [(ngModel)]="documentType" (change)="typeChanged()" id="document-type-selector">
            <option *ngFor="let type of documentTypes" [value]="type.id">{{type.description | mediumText}}</option>
         </select>
         <div class="alert alert-danger" [hidden]="documentType">Type of document is required.</div>
         <div style="height: 15px;"></div>
         <div>
            <label> Select file: <b class="required">*</b></label>
            <input class="form-control input-md" type="file" (change)="processDocument($event.target.files,'file1')">
            <div style="height: 15px;"></div>
            <ng-container *ngIf="multipleDocuments">
               <label> Select file:</label>
               <input class="form-control input-md" type="file" (change)="processDocument($event.target.files,'file2')">
               <div style="height: 15px;"></div>
            </ng-container>
            <div class="alert alert-danger" [hidden]="fileSelected">File is required.</div>
            <label> Valid until (YYYY-MM-DD) <b class="required">*</b>: </label> <input class="form-control input-md"
               #datepickerYMD="bsDatepicker" bsDatepicker [(ngModel)]="validTo"
               [bsConfig]="{ dateInputFormat: 'YYYY-MM-DD' }" (change)="checkValidTo()">
            <div class="alert alert-danger" [hidden]="validTo">Valid date is required.</div>
         </div>
         <div>
            <label> Description:</label> <input class="form-control input-md" type="text" [(ngModel)]="docDescription">
         </div>
         <div style="height: 20px;"></div>
         <button class="btn btn-primary" (click)="upload()" [disabled]="loading || roleId == '' || documentType == '' || validTo == '' || !fileSelected"
            style="background-color: #355B89;border-color: #355B89">Replace Document</button><img *ngIf="loading && !showForm" [src]="loadingImg" style="height: 25px"/>
      </div>
   </div>
</div>
