
  <style>
    .activeStatusButton{
      outline: 1px solid black;
      outline-offset: 2px;
      position: relative;
    }
  </style>
  <div class='modal-header modal-evaluate-document'>
  <h4 class='modal-title pull-left'>Evaluate Document</h4>
  <button type='button' class='close pull-right' aria-label='Close' (click)='bsModalRef.hide()'>
  <span aria-hidden='true'>&times;</span>
  </button>
</div>
<div class='modal-body'>
<button class='btn btn-success btn-save-information' [ngClass]="{'activeStatusButton': currentStatusBeforeUpdatingMetaData ? currentStatusBeforeUpdatingMetaData === 'ACCEPTED' : currentStatus === 'ACCEPTED'}" (click)='accept("ACCEPTED")'>Accept</button>&nbsp;&nbsp;
  <button class='btn btn-warning btn-yellow' [ngClass]="{'activeStatusButton': currentStatusBeforeUpdatingMetaData ? currentStatusBeforeUpdatingMetaData === 'ESCALATED' : currentStatus === 'ESCALATED'}" (click)='escalate("ESCALATED")'>Escalate</button>&nbsp;&nbsp;
  <button class='btn btn-danger btn-delete' [ngClass]="{'activeStatusButton': currentStatusBeforeUpdatingMetaData ? currentStatusBeforeUpdatingMetaData === 'REJECTED' : currentStatus === 'REJECTED'}" (click)='reject("REJECTED")'>Reject</button>&nbsp;&nbsp;
  <button class='btn btn-primary' *ngIf='displayPrevious' (click)='goToPrevious()'>Previous</button>&nbsp;&nbsp;
  <button class='btn btn-primary' *ngIf='displayNext' (click)='goToNext()'>Next</button>
  <div style='height: 15px;'></div>
  Document type: <b>{{documents[currentDocument]?.name}}</b>
  <div style='height: 15px;'></div>
  Current document status is:
  <ng-container [ngSwitch] = 'currentStatus'>
     <span *ngSwitchCase='"UNEVALUATED"' style='color: gray;'><b>UNEVALUATED</b></span>
     <span *ngSwitchCase='"ACCEPTED"' style='color: #5cb85c;'><b>ACCEPTED</b></span>
     <span *ngSwitchCase='"ESCALATED"' style='color: #f0ad4e;'><b>ESCALATED</b></span>
     <span *ngSwitchCase='"REJECTED"' style='color: #d9534f;'><b>REJECTED</b></span>
  </ng-container>
  <br>
  <div style='height: 15px;'></div>
  <div *ngIf='showContextualData && documents[currentDocument].contextualData'>
    Word list:&nbsp;&nbsp;<b>{{documents[currentDocument]?.contextualData}}</b>
    <div style='height: 15px;'></div>
  </div>
  Comment:&nbsp;<input type='text' [(ngModel)]='comment' style='width: 100%' />
  <div style='height: 15px;'></div>
  <div *ngIf="highestId > 0" style='margin-bottom: 15px; padding: 1em;' [style.border]="currentStatus === 'UNEVALUATED' ? '2px solid gray' : currentStatus === 'ACCEPTED' ? '2px solid #5cb85c' : currentStatus === 'ESCALATED' ? '2px solid #f0ad4e' : currentStatus === 'REJECTED' ? '2px solid #d9534f': '2px solid gray'">
    <h5 class="">Last Comment:</h5>
    {{lastComment?.createdByEmail}} commented <b> "{{lastComment?.comment}}" </b> at {{lastComment?.createdAt | date:'dd/MM/yyyy, h:mm:ss a, z'}}
  </div>
  <img *ngIf='showImage && !multiplePictures' id='safePicture' [src]='safePicture' style='width: 75%; height: auto'/>
  <div *ngIf='multiplePictures && showImage'>
    <div *ngFor="let picture of safePictures">
      <img [src]="picture" style='width: 75%; height: auto'>
      <div style='height: 15px;'></div>
    </div>
  </div>
  <video style='display:none;' id='selfie-video' width='100%' controls></video>
  <button *ngIf='showPdf' class='btn btn-primary' (click)='viewFullPdf()'>Open Full PDF</button>
  <div style='height: 5px;'></div>
  <pdf-viewer *ngIf='showPdf' [src]='pdfSrc'
  [render-text]='true'
  [show-all]='false'
  [original-size]='false'
  [autoresize]='true'
  style='display: block;'></pdf-viewer>
  <span><i>{{message}}</i></span>
  <div style='height: 15px;'></div>
  <ng-container *ngIf="currentText.length === 0">
    <table class="table table-responsive table-hover" *ngIf='showJSON' cellpadding='5' style='border: 1px solid #cacaca;background-color: #f2f2f2;width: 100%'>
      <tr *ngFor='let item of textContent | keyvalue' style='padding-left: 10px;'>
        <td style='padding: 5px;border: 1px solid #cacaca'><b>{{item.key}}</b></td>
        <td style='padding: 5px;border: 1px solid #cacaca'>{{item.value}}</td>
      </tr>
    </table>
  </ng-container>
  <ng-container *ngIf="currentText.length > 0">
    <ng-container *ngFor="let current of currentText; let i = index">
      <table class="table table-responsive table-hover" *ngIf='showJSON' cellpadding='5' style='border: 1px solid #cacaca;background-color: #f2f2f2;width: 100%'>
        <tr *ngFor='let item of current | keyvalue' style='padding-left: 10px;'>
          <td style='padding: 5px;border: 1px solid #cacaca'><b>{{item.key}}</b></td>
          <td style='padding: 5px;border: 1px solid #cacaca'>{{item.value}}</td>
        </tr>
      </table>
      <div *ngIf="i !== currentText.length - 1" style="height: 25px;"></div>
    </ng-container>
  </ng-container>
  <table class="table table-responsive table-hover" *ngIf='showPhoneJSON' cellpadding='5' style='border: 1px solid #cacaca;background-color: #f2f2f2;width: 100%'>
     <tr style='padding-left: 10px;'>
        <td style='padding: 5px;border: 1px solid #cacaca'><b>assessmentType</b></td>
        <td style='padding: 5px;border: 1px solid #cacaca'>{{textContent.assessmentType}}</td>
     </tr>
     <tr *ngIf='riskAssessmentType != "CONNECTION_ASSESSMENT"' style='padding-left: 10px;'>
        <td style='padding: 5px;border: 1px solid #cacaca'><b>description</b></td>
        <td style='padding: 5px;border: 1px solid #cacaca'>{{textContent.description}}</td>
      </tr>
      <tr *ngIf='textContent.assessment' style='padding-left: 10px;'>
        <td style='padding: 5px;border: 1px solid #cacaca'><b>assessment</b></td>
        <td style='padding: 5px;border: 1px solid #cacaca' [innerHTML]='textContent.assessment | telesignAssessment'></td>
      </tr>
      <tr *ngIf='riskAssessmentType == "PHONE_ASSESSMENT"' style='padding-left: 10px;'>
        <td style='padding: 5px;border: 1px solid #cacaca'><b>riskLevel</b></td>
        <td style='padding: 5px;border: 1px solid #cacaca'>{{textContent.riskLevel}}</td>
      </tr>
      <tr *ngIf='textContent.riskStatus' style='padding-left: 10px;'>
        <td style='padding: 5px;border: 1px solid #cacaca'><b>riskStatus</b></td>
        <td style='padding: 5px;border: 1px solid #cacaca'>{{textContent.riskStatus}}</td>
      </tr>
      <tr *ngIf='textContent.riskType' style='padding-left: 10px;'>
        <td style='padding: 5px;border: 1px solid #cacaca'><b>riskType</b></td>
        <td style='padding: 5px;border: 1px solid #cacaca'>{{textContent.riskType}}</td>
      </tr>
  </table>
</div>
<div class='modal-footer'>
  <button type='button' class='btn btn-default btn-cancel' (click)='bsModalRef.hide()'>Cancel</button>
  <button type='button' class='btn btn-save-information' style="background-color: #5cb85c; color: #fff" (click)='updateMetaData()'>Save</button>
</div>
<style>
  .btn-cancel:hover, .btn-cancel:focus{
      outline: none !important;
      border-color: #ccc !important;
      background-color: #fff !important;
    }
  </style>
  