import { Component, OnInit } from '@angular/core';
import { AlertService, KYBService } from '../../../_services';

/**
* Implements Risk Analysis Settings
*/
@Component({
    moduleId: module.id,
    selector: 'app-risk-analysis',
    templateUrl: 'riskAnalysisSettings.component.html',
    styleUrls: [ './riskAnalysisSettings.component.css' ]
})

export class RiskAnalysisSettingsComponent implements OnInit {

  riskAnalysisResponse: any = {};
  currentSettings: any = {};
  lowRiskResponse: any = {};
  lowRiskConfig: any = {};
  mediumRiskResponse: any = {};
  mediumRiskConfig: any = {};
  highRiskResponse: any = {};
  highRiskConfig: any = {};

  constructor (
    private alertService: AlertService,
    private kybService: KYBService
  ) {}

  ngOnInit() {
   this.kybService.getRiskAnalysisRules().subscribe(
     data => {
       this.riskAnalysisResponse = data;
       this.currentSettings = this.riskAnalysisResponse.data;
     },
     error => {}
   );

   this.kybService.getLowRiskConfig().subscribe(
    data => {
      this.lowRiskResponse = data;
      this.lowRiskConfig = this.lowRiskResponse.data;
    },
    error => {}
   );

   this.kybService.getMediumRiskConfig().subscribe(
    data => {
      this.mediumRiskResponse = data;
      this.mediumRiskConfig = this.mediumRiskResponse.data;
    },
    error => {}
   );

   this.kybService.getHighRiskConfig().subscribe(
    data => {
      this.highRiskResponse = data;
      this.highRiskConfig = this.highRiskResponse.data;
    },
    error => {}
   );
  }

  saveSettings() {
    // placeholder values - they are not beind used by the backend yet but parameters are required in call
    this.currentSettings.fourEyeRiskTolerance = 0;
    this.currentSettings.maxRiskTolerance = 0;
    this.currentSettings.regularRiskTolerance = 0;
    this.currentSettings.riskWeightFormula = '';

    this.kybService.updateRiskAnalysisRules(this.currentSettings).subscribe(
      data => {
        this.alertService.showSuccess('Settings saved successfully.');
        this.ngOnInit();
      },
      error => {
        this.alertService.showError('Something went wrong.');
      }
    );

    this.kybService.updateLowRiskConfig(this.lowRiskConfig).subscribe(
      data => {
        this.alertService.showSuccess('Low Risk Refresh Period updated successfully.');
        this.ngOnInit();
      },
      error => {
        this.alertService.showError('Something went wrong.');
      }
    );

    this.kybService.updateMediumRiskConfig(this.mediumRiskConfig).subscribe(
      data => {
        this.alertService.showSuccess('Medium Risk Refresh Period updated successfully.');
        this.ngOnInit();
      },
      error => {
        this.alertService.showError('Something went wrong.');
      }
    );

    this.kybService.updateHighRiskConfig(this.highRiskConfig).subscribe(
      data => {
        this.alertService.showSuccess('High Risk Refresh Period updated successfully.');
        this.ngOnInit();
      },
      error => {
        this.alertService.showError('Something went wrong.');
      }
    );
  }
}
