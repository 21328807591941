import { Component, OnInit, TemplateRef } from '@angular/core';
import { AlertService, CallsService, KYBService } from '../../../../_services';
import * as $ from 'jquery';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
    moduleId: module.id,
    selector: 'app-manage-users',
    templateUrl: 'manageUsers.component.html',
    styleUrls: ['./manageUsers.component.css']
})

export class ManageUsersComponent implements OnInit {

  users: any;
  usersDataTable: any;
  addUserDisplay = false;
  email = '';
  password = '';
  user: any = {};
  addNewUser: any;
  currentUser: any = {};

  createUserResponse: any = {};
  role = '';

  addModalRef: BsModalRef;
  editModalRef: BsModalRef;

  constructor(
    private alertService: AlertService,
    private callService: CallsService,
    private kybService: KYBService,
    private modalService: BsModalService
  ) {}

  ngOnInit() {
    this.kybService.getAllUsers().subscribe(
      data => {
        this.users = data.data;

        for (let i = 0; i < this.users.length; i++) {
          if (this.users[i].role === 'user') {
            this.users[i].role = 'ANALYST1';
          } else if (this.users[i].role === 'reader') {
            this.users[i].role = 'ANALYST2';
          }
        }

        this.initUserTable();
      },
      error => {
      }
    );
  }

  deactivate(identifier: any, username: any) {
    if (window.confirm('Are you sure you want to deactivate user ' + username + ' ?')) {
      this.kybService.changeUserRole(identifier, 'revoked').subscribe(
        data => {
          this.alertService.showSuccess('User was deactivated.');
          this.ngOnInit();
        },
        error => {}
      );
    }
  }

  initUserTable() {
    const usersTable: any = $('.table-users');

    if ( $.fn.dataTable.isDataTable( '.table-users' ) ) {
        this.usersDataTable = usersTable.DataTable();
    } else {
        this.usersDataTable = usersTable.DataTable(
            {
                'oLanguage': { 'sEmptyTable': 'No users found.'},
                'orderCellsTop': true,
                'columnDefs': [
                    { 'orderable': false, 'targets': 0 },
                    { 'orderable': false, 'targets': 1 },
                    { 'orderable': false, 'targets': 2 },
                    { 'orderable': false, 'targets': 3 }
                ],
                'order': [],
                'paging': false,
                'lengthChange': false,
                'lengthMenu': [[30, 50, 100, -1], [30, 50, 100, 'All']],
                'dom': `<'top'l<'clear'>>rt<'bottom'<'clear'>>`,
                'select': {style: 'os'}
            }
        );

        if (this.users.length > 0) {
          const empty = (document.getElementsByClassName('dataTables_empty')) as HTMLCollectionOf<Element>;
          empty[0].setAttribute('style', 'display: none;');
        }
    }
  }

  addUser() {
    this.currentUser.email = this.currentUser.email.toLowerCase();
    this.kybService.createUserWithRole(this.currentUser).subscribe(
      data => {
        this.createUserResponse = data;
        this.users.push({'email': this.currentUser.email, 'role': this.createUserResponse.data.role.toUpperCase()});
        this.addModalRef.hide();
        this.alertService.showSuccess('User created successfully.');
        this.currentUser = {};
        this.ngOnInit();
      },
      error => {}
    );
  }

  displayAddUser(addTemplate: TemplateRef<any>) {
    this.addNewUser = new FormGroup({
      email: new FormControl(null, [Validators.email, Validators.required]),
      password: new FormControl(null, Validators.required),
      userRole: new FormControl(null, Validators.required),
    });
    this.currentUser = {};
    this.addModalRef = this.modalService.show(addTemplate, { backdrop: 'static' });
  }

  edit(editTemplate: TemplateRef<any>, user: any) {
    this.currentUser = user;
    this.editModalRef = this.modalService.show(editTemplate, { backdrop: 'static' });
  }

  save() {
    if (this.currentUser.role === 'ANALYST1') {
      this.currentUser.role = 'user';
    } else if (this.currentUser.role === 'ANALYST2') {
      this.currentUser.role = 'reader';
    }

    this.currentUser.role = this.currentUser.userRole;

    this.kybService.updateUser(this.currentUser.identifier, this.currentUser).subscribe(
      data => {
        this.editModalRef.hide();
        this.alertService.showSuccess('User role edited successfully.');
        this.currentUser = {};
        this.ngOnInit();
      },
      error => {
        this.editModalRef.hide();
        this.alertService.showError('Something went wrong.');
      }
    );
  }
}
