/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./emailTemplate.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/forms";
import * as i3 from "./emailTemplate.component";
import * as i4 from "@angular/router";
import * as i5 from "../../../_services/calls.service";
import * as i6 from "../../../_services/alert.service";
import * as i7 from "../../../_services/authentication.service";
var styles_EmailTemplateComponent = [i0.styles];
var RenderType_EmailTemplateComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_EmailTemplateComponent, data: {} });
export { RenderType_EmailTemplateComponent as RenderType_EmailTemplateComponent };
export function View_EmailTemplateComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 36, "form", [["class", "editForm"], ["novalidate", ""]], [[2, "ng-untouched", null], [2, "ng-touched", null], [2, "ng-pristine", null], [2, "ng-dirty", null], [2, "ng-valid", null], [2, "ng-invalid", null], [2, "ng-pending", null]], [[null, "submit"], [null, "reset"]], function (_v, en, $event) { var ad = true; if (("submit" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2).onSubmit($event) !== false);
        ad = (pd_0 && ad);
    } if (("reset" === en)) {
        var pd_1 = (i1.ɵnov(_v, 2).onReset() !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 16384, null, 0, i2.ɵangular_packages_forms_forms_z, [], null, null), i1.ɵdid(2, 4210688, [["emailTemplateForm", 4]], 0, i2.NgForm, [[8, null], [8, null]], null, null), i1.ɵprd(2048, null, i2.ControlContainer, null, [i2.NgForm]), i1.ɵdid(4, 16384, null, 0, i2.NgControlStatusGroup, [[4, i2.ControlContainer]], null, null), (_l()(), i1.ɵeld(5, 0, null, null, 1, "h3", [["class", "text-center"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Email Template Setup"])), (_l()(), i1.ɵeld(7, 0, null, null, 0, "br", [], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 12, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 5, "div", [["class", "col-xs-12 col-sm-6"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 4, "div", [["class", "form-group"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 2, "label", [["class", "control-label"], ["for", "registrationTemplate"]], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 1, "b", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Registration email template:"])), (_l()(), i1.ɵeld(14, 0, [["registrationTemplate", 1]], null, 0, "input", [["class", "form-control input-md"], ["id", "registrationTemplate"], ["name", "registrationTemplate"], ["type", "file"]], null, [[null, "change"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = (_co.processRegistrationTemplate($event.target.files) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(15, 0, null, null, 5, "div", [["class", "col-xs-12 col-sm-6"]], null, null, null, null, null)), (_l()(), i1.ɵeld(16, 0, null, null, 4, "div", [["class", "form-group"]], null, null, null, null, null)), (_l()(), i1.ɵeld(17, 0, null, null, 2, "label", [["class", "control-label"], ["for", "whitelistTemplate"]], null, null, null, null, null)), (_l()(), i1.ɵeld(18, 0, null, null, 1, "b", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Whitelisted email template:"])), (_l()(), i1.ɵeld(20, 0, [["whitelistTemplate", 1]], null, 0, "input", [["class", "form-control input-md"], ["id", "whitelistTemplate"], ["name", "whitelistTemplate"], ["type", "file"]], null, [[null, "change"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = (_co.processWhitelistTemplate($event.target.files) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(21, 0, null, null, 12, "div", [["class", "row"]], null, null, null, null, null)), (_l()(), i1.ɵeld(22, 0, null, null, 5, "div", [["class", "col-xs-12 col-sm-6"]], null, null, null, null, null)), (_l()(), i1.ɵeld(23, 0, null, null, 4, "div", [["class", "form-group"]], null, null, null, null, null)), (_l()(), i1.ɵeld(24, 0, null, null, 2, "label", [["class", "control-label"], ["for", "moreInformationTemplate"]], null, null, null, null, null)), (_l()(), i1.ɵeld(25, 0, null, null, 1, "b", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Need more information template:"])), (_l()(), i1.ɵeld(27, 0, [["moreInformationTemplate", 1]], null, 0, "input", [["class", "form-control input-md"], ["id", "moreInformationTemplate"], ["name", "moreInformationTemplate"], ["type", "file"]], null, [[null, "change"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = (_co.processMoreInfoTemplate($event.target.files) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(28, 0, null, null, 5, "div", [["class", "col-xs-12 col-sm-6"]], null, null, null, null, null)), (_l()(), i1.ɵeld(29, 0, null, null, 4, "div", [["class", "form-group"]], null, null, null, null, null)), (_l()(), i1.ɵeld(30, 0, null, null, 2, "label", [["class", "control-label"], ["for", "blacklistTemplate"]], null, null, null, null, null)), (_l()(), i1.ɵeld(31, 0, null, null, 1, "b", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Blacklisted email template:"])), (_l()(), i1.ɵeld(33, 0, [["blacklistTemplate", 1]], null, 0, "input", [["class", "form-control input-md"], ["id", "blacklistTemplate"], ["name", "blacklistTemplate"], ["type", "file"]], null, [[null, "change"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = (_co.processBlacklistTemplate($event.target.files) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(34, 0, null, null, 2, "div", [["class", "form-group"]], null, null, null, null, null)), (_l()(), i1.ɵeld(35, 0, null, null, 1, "button", [["class", "btn btn-primary"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.saveTemplate(i1.ɵnov(_v, 2)) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Save"]))], null, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 4).ngClassUntouched; var currVal_1 = i1.ɵnov(_v, 4).ngClassTouched; var currVal_2 = i1.ɵnov(_v, 4).ngClassPristine; var currVal_3 = i1.ɵnov(_v, 4).ngClassDirty; var currVal_4 = i1.ɵnov(_v, 4).ngClassValid; var currVal_5 = i1.ɵnov(_v, 4).ngClassInvalid; var currVal_6 = i1.ɵnov(_v, 4).ngClassPending; _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }); }
export function View_EmailTemplateComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-email-template", [], null, null, null, View_EmailTemplateComponent_0, RenderType_EmailTemplateComponent)), i1.ɵdid(1, 49152, null, 0, i3.EmailTemplateComponent, [i4.Router, i5.CallsService, i6.AlertService, i7.AuthenticationService], null, null)], null, null); }
var EmailTemplateComponentNgFactory = i1.ɵccf("app-email-template", i3.EmailTemplateComponent, View_EmailTemplateComponent_Host_0, {}, {}, []);
export { EmailTemplateComponentNgFactory as EmailTemplateComponentNgFactory };
