import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AlertService, CallsService, AuthenticationService, KYBService } from '../../../_services';
import { config } from '../../../../assets/configuration';
import { BrandingService } from './../../../branding/branding.service';
import { BrandingSetup } from './../../../branding/branding.model';

/**
* Implements password changing directive
*/
@Component({
  moduleId: module.id,
  selector: 'app-media-screener',
  templateUrl: 'mediaScreenerSettings.component.html',
  styleUrls: ['./mediaScreenerSettings.component.css']
})

export class MediaScreenerSettingsComponent implements OnInit {

  oldPassword = '';
  newPassword = '';
  mainColor: any = config.mainColor;
  periodicity: any;
  weekend: any = false;
  maxAgeOfRecordToBeConsideredValidInMonths: any = 0;
  nationalityMatch: any = false;
  sensitivity: any = 0.0;
  screenerConfig: any = {};
  mediaConfig: any = {};
  msinclusiveness: any;
  seinclusiveness: any;
  tags: any = [];

  constructor(
    private router: Router,
    private callsService: CallsService,
    private alertService: AlertService,
    private authenticationService: AuthenticationService,
    private kybService: KYBService,
    private brandingService: BrandingService
  ) {}

  ngOnInit() {
    this.kybService.getRemoteBatch().subscribe(
      data => {
        this.screenerConfig = data;
      },
      error => {}
    );

    this.kybService.getMediaScreeningSetings().subscribe(
      data => {
        this.mediaConfig = data;
      },
      error => {
      }
    );

    this.getBranding();
  }

  public getBranding(): void {
    this.brandingService.brandingSetup.subscribe((setup: BrandingSetup) => {
      if (!setup) {
        return;
      }

      this.mainColor = setup.mainColor;
    });
  }

  saveSettings() {
    this.maxAgeOfRecordToBeConsideredValidInMonths = 24;
    this.mediaConfig.mediaThreshold = 0;
    this.kybService.updateMediaScreeningSettings(this.mediaConfig).subscribe(
      data => {
        window.scrollTo(0, 0);
        this.alertService.showSuccess('Settings saved successfully.');
        this.ngOnInit();
      },
      error => {
        error.error.fieldErrors.forEach(fieldError => {
          if (fieldError.field === 'mediaSensitivity') {
            this.alertService.showError('Media sensitivity threshold value should be greater than or equal to 70.');
          } else {
            this.alertService.showError('Something went wrong.');
          }
        });
      }
    );
  }
}
