import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActivityReportComponent } from './activityReport.component';
import { FormsModule } from '@angular/forms';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { MatIconModule } from '@angular/material/icon';
import { NgSelectModule } from '@ng-select/ng-select';
import { PaginationModule } from 'ngx-bootstrap/pagination';



@NgModule({
  declarations: [
    ActivityReportComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    BsDatepickerModule,
    MatIconModule,
    NgSelectModule,
    PaginationModule
  ],
  exports: [
    ActivityReportComponent
  ]
})
export class ActivityReportingModule { }
