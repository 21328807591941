import { Injectable } from '@angular/core';
import RecordRTC from 'recordrtc';
import * as moment from 'moment';
import { Observable, Subject } from 'rxjs';

interface RecordedVideoOutput {
  blob: Blob;
  url: string;
  title: string;
}

@Injectable()
export class VideoRecordingService {

  private stream;
  private recorder;
  private interval;
  private startTime;
  private _stream = new Subject<MediaStream>();
  private _recorded = new Subject<RecordedVideoOutput>();
  private _recordedUrl = new Subject<string>();
  private _recordingTime = new Subject<string>();
  private _recordingFailed = new Subject<string>();
  public get agent(): any { return navigator.userAgent || navigator.vendor; }
  public get chrome(): boolean { return !!this.agent.toLowerCase().match(/(chrome)/i); }
  public get safari(): boolean { return !!this.agent.toLowerCase().match(/(safari)/i); }
  public get firefox(): boolean { return !!this.agent.toLowerCase().match(/(firefox)/i); }
  public get firefoxIOS(): boolean { return !!navigator.userAgent.toLowerCase().match(/(fxios)/i); }
  public get chromeIOS(): boolean { return !!navigator.userAgent.toLowerCase().match(/(crios)/i); }
  public get operaIOS(): boolean { return !!navigator.userAgent.toLowerCase().match(/(opios)/i); }
  public get samsungAndroid(): boolean { return !!navigator.userAgent.toLowerCase().match(/(samsungbrowser)/i); }
  public get android(): boolean { return !!this.agent.match(/(android)/i); }
  public get ios(): boolean { return !!this.agent.match(/(iPhone)|(iPod)/i); }

  getRecordedUrl(): Observable<string> {
    return this._recordedUrl.asObservable();
  }

  getRecordedBlob(): Observable<RecordedVideoOutput> {
    return this._recorded.asObservable();
  }

  getRecordedTime(): Observable<string> {
    return this._recordingTime.asObservable();
  }

  recordingFailed(): Observable<string> {
    return this._recordingFailed.asObservable();
  }

  getStream(): Observable<MediaStream> {
    return this._stream.asObservable();
  }

  startRecording( conf: any ): Promise<any> {
    const browser = <any>navigator;

    if (this.recorder) {
      // It means recording is already started or it is already recording something
      return;
    }

    this._recordingTime.next('00:00');

    return new Promise((resolve, reject) => {
      browser.mediaDevices.getUserMedia(conf).then(stream => {
        this.stream = stream;
        resolve(this.stream);
      }).catch(error => {
        this._recordingFailed.next(error);
      });
    });
  }

  abortRecording() {
    this.stopMedia();
  }

  record() {
    const x = {
      type: 'video'
    };

    this.recorder = new RecordRTC(this.stream, x);
    this.recorder.startRecording();
    this.startTime = moment();
    this.interval = setInterval(
      () => {
        const currentTime = moment();
        const diffTime = moment.duration(currentTime.diff(this.startTime));
        const time = this.toString(diffTime.minutes()) + ':' + this.toString(diffTime.seconds());
        this._recordingTime.next(time);
        this._stream.next(this.stream);
      },
      500
    );
  }

  private toString(value) {
    let val = value;
    if (!value) {
      val = '00';
    }
    if (value < 10) {
      val = '0' + value;
    }
    return val;
  }

  stopRecording() {
    if (this.recorder) {
      this.recorder.stopRecording(this.processVideo.bind(this));
    }
  }

  private processVideo(audioVideoWebMURL) {
    const recordedBlob = this.recorder.getBlob();
    this.recorder.getDataURL(function (dataURL) { });

    const recordedName = encodeURIComponent('video_' + new Date().getTime() + '.webm');
    this._recorded.next({ blob: recordedBlob, url: audioVideoWebMURL, title: recordedName });
    this.stopMedia();
  }

  private stopMedia() {
    if (this.recorder) {
      this.recorder = null;
      clearInterval(this.interval);
      this.startTime = null;
      if (this.stream) {
        this.stream.getAudioTracks().forEach(track => track.stop());
        this.stream.getVideoTracks().forEach(track => track.stop());
        this.stream.stop();
        this.stream = null;
      }
    }
  }
}
