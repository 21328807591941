import { Component, OnInit, ViewChild, ChangeDetectorRef, EventEmitter } from '@angular/core';
import { CallsService, KYBService, DataService } from '../../../../_services';
import { Document, Entity, Role, RoleUpdate } from '../../../../_models/kyb';
import * as $ from 'jquery';
import { Personal } from '../../data/formData.model';
import { DeviceDetectorService } from 'ngx-device-detector';
import { FormDataService } from '../../data/formData.service';
import { config } from '../../../../../assets/configuration';
import { DomSanitizer } from '@angular/platform-browser';
import { VideoRecordingService } from '../../../../_services/video-recording.service';
import { BsModalService, BsModalRef, ModalOptions } from 'ngx-bootstrap/modal';
import { TranslateService } from '@ngx-translate/core';

declare var MediaRecorder: any;

/**
 * Implements the login page
*/
@Component({
  moduleId: module.id,
  // tslint:disable-next-line:component-selector
  selector: 'mobile-video-onboarding-recording',
  templateUrl: 'mobile-video-onboarding-recording.component.html',
  styleUrls: ['./mobile-video-onboarding-recording.component.css']
})

export class MobileVideoOnboardingRecordingComponent implements OnInit {

  public event: EventEmitter<any> = new EventEmitter();
  textfromerror  = '';
  @ViewChild('videoElement', { static: false }) videoElement: any;
  video: any;
  title = 'Video Recording';
  personal: Personal;
  mediaRecorder: any;
  wordsResponse: any = {};
  words: any = [];
  mimeTypeForSaving: any = '';
  loading = false;
  email = '';
  currentStack: any;
  loadingImg: any = config.loadingImg;
  CAMERA_MIC_ACCESS_BLOCKED = '';
  displayControls = true;
  isAudioRecording = false;
  isVideoRecording = false;
  videoRecordedTime;
  videoBlobUrl;
  videoBlob;
  videoName;
  permissionDenied = false;

  public get ios(): boolean { return !!this.agent.match(/(iPhone)|(iPod)/i); }
  public get agent(): any { return navigator.userAgent || navigator.vendor; }

  videoStream: MediaStream;
  videoConfIOS = {
    video: {
      facingMode: 'user',
      height: {
        min: window.innerWidth * 0.8 <= 240 ? window.innerWidth * 0.8 : 240,
        ideal: window.innerWidth * 0.8 <= 240 ? window.innerWidth * 0.8 : 240,
        max: window.innerWidth * 0.8 <= 240 ? window.innerWidth * 0.8 : 240
      }
    },
    audio: true,
    frameRate: {
      min: 15,
      ideal: 60,
      max: 120
    }
  };

  videoConfAndroid = {
    video: {
      facingMode: 'user',
      height: {
        ideal: 623,
      }
    },
    audio: true,
    frameRate: {
      min: 15,
      ideal: 60,
      max: 120
    }
  };

  videoConf = {
    video: {
        facingMode: 'user',
        height: {
          ideal: 623
        }
    },
    audio: true,
    frameRate: {
      min: 15,
      ideal: 60,
      max: 120
    }
  };

  videoSelfie: File;
  createEntityResponse: any = {};
  roleResponse: any = {};
  getRoleResponse: any = {};
  updatedRole: RoleUpdate;
  additionalDocumentIds: any = [];
  requiredDocumentIds: any = [];
  requiredDocumentTypes: any = [];
  entity: Entity;
  selfRole: Role;
  documentIds: any = [];
  selfRoleId: any;
  onboardingEntityId: any;
  captureImageModalRef: BsModalRef;

  videoAnalysisResponse: any = {};
  videoDocument: Document;
  videoFile: File;
  documentData: any = {};
  documentResponse: any = {};

  recordedBlobs: any = [];

  constructor(
    private translate: TranslateService,
    public options: ModalOptions,
    public bsModalRef: BsModalRef,
    private modalService: BsModalService,
    private deviceDetectorService: DeviceDetectorService,
    private ref: ChangeDetectorRef,
    private videoRecordingService: VideoRecordingService,
    private sanitizer: DomSanitizer,
    private kybService: KYBService,
    private callsService: CallsService,
    private dataService: DataService,
    private formDataService: FormDataService
  ) {
    if (this.ios) {
      this.videoConf = this.videoConfIOS;
    }

    this.videoRecordingService.recordingFailed().subscribe((stream) => {
      this.isVideoRecording = false;

      if (stream === 'Permission denied') {
        this.permissionDenied = true;
      } else {
        this.permissionDenied = true;
        this.textfromerror = stream;
      }

      this.ref.detectChanges();
    });

    this.videoRecordingService.getRecordedTime().subscribe((time) => {
      this.videoRecordedTime = time;
      this.ref.detectChanges();
    });

    this.videoRecordingService.getStream().subscribe((stream) => {
      this.videoStream = stream;
      this.ref.detectChanges();
    });

    this.videoRecordingService.getRecordedBlob().subscribe((data) => {
      this.videoBlob = data.blob;
      this.videoName = data.title;
      this.videoBlobUrl = window.URL.createObjectURL(data.blob);
      const x = options.initialState as any;
      this.words = x.words;
      this.ref.detectChanges();
      this.event.emit({data: data });
      this.bsModalRef.hide();
    });
  }

  ngOnInit() {
    if (this.ios) {
      this.CAMERA_MIC_ACCESS_BLOCKED = this.translate.instant('CAMERA_MIC_ACCESS_BLOCKED_IOS');
    } else {
      this.CAMERA_MIC_ACCESS_BLOCKED = this.translate.instant('CAMERA_MIC_ACCESS_BLOCKED_ANDROID');
    }
    // TODO: Disable Finish button
    this.dataService.stack.subscribe(message => this.currentStack = message);
    this.dataService.currentDocumentIds.subscribe(message => this.documentIds = message);
    this.email = localStorage.getItem('registrationEmail');
    this.personal = this.formDataService.getPersonal();

    this.selfRoleId = this.dataService.onboardingEntitySelfRoleId.getValue();
    this.onboardingEntityId = this.dataService.onboardingEntityId.getValue();
    const self = this;

    setTimeout(() => {
      self.startVideo();
    }, 1000);
  }

  cancel() {
    this.bsModalRef.hide();
  }

  recordVideo() {
    this.isVideoRecording = true;
    const self = this;
    const textOverlay = document.getElementById('overlay-text') as HTMLElement;

    if (this.isVideoRecording) {
        textOverlay.setAttribute('style', 'z-index: 2;position: absolute;color: white;');

        setTimeout(() => {
          textOverlay.innerHTML = '3';
        }, 1000);

        setTimeout(() => {
          textOverlay.innerHTML = '2';
        }, 2000);

        setTimeout(() => {
          textOverlay.innerHTML = '1';
        }, 3000);

        setTimeout(() => {
          const beep = new Audio('../../../../assets/beep33.wav');
          beep.volume = 0.2;
          beep.play();
          textOverlay.innerHTML = '';
        }, 4000);

        setTimeout(() => {
          textOverlay.innerHTML = '';
          document.getElementById('ovals').remove();
          textOverlay.setAttribute('style', 'z-index: 2;position: absolute;color: white;');
          self.videoRecordingService.record();
        }, 5000);

        setTimeout(() => {
          textOverlay.innerHTML = `<span style='font-weight: bold; font-size: 25px;'>` + self.words[0].toUpperCase() + '</span>';
        }, 6000);

        setTimeout(() => {
          textOverlay.innerHTML = '';
        }, 8500);

        setTimeout(() => {
          textOverlay.innerHTML = `<span style='font-weight: bold; font-size: 25px;'>` + self.words[1].toUpperCase() + '</span>';
        }, 9000);

        setTimeout(() => {
          textOverlay.innerHTML = '';
        }, 11500);

        setTimeout(() => {
          textOverlay.innerHTML = `<span style='font-weight: bold; font-size: 25px;'>` + self.words[2].toUpperCase() + '</span>';
        }, 12000);

        setTimeout(() => {
          textOverlay.innerHTML = '';
        }, 14500);

        setTimeout(() => {
          textOverlay.innerHTML = `<span style='font-weight: bold; font-size: 25px;'>` + self.words[3].toUpperCase() + '</span>';
        }, 15000);

        setTimeout(() => {
          textOverlay.innerHTML = '';
        }, 17500);

        setTimeout(() => {
          textOverlay.innerHTML = `<span style='font-weight: bold; font-size: 25px;'>` + self.words[4].toUpperCase() + '</span>';
        }, 18000);

        setTimeout(() => {
          textOverlay.innerHTML = '';
        }, 20500);

        setTimeout(() => {
          self.stopVideoRecording();
        }, 22500);

        setTimeout(() => {
          // tslint:disable-next-line:max-line-length
          $('.overlay-desc').append(`<div id='thank-you' style='position: absolute;z-index: 3;color: white;font-size: 24px;'>` + self.translate.instant('THANK_YOU') + '!<br>');
        }, 23500);
      }
  }

  startVideo() {
    this.video = this.videoElement.nativeElement;
    this.videoRecordingService.startRecording(this.videoConf)
      .then(stream => {
        this.video.srcObject = stream;
        const self = this;
        this.video.play();
        this.video.muted = true;

        setTimeout(() => {
          $('.overlay-desc').append(`<div id='overlay-text' style='z-index: 2;position: absolute;color: white;width: 50%;text-align: center;'></div>`);
          const textOverlay = document.getElementById('overlay-text') as HTMLElement;
          textOverlay.setAttribute('style', 'position: absolute;color: white;font-size: 12px;line-height: 1;font-weight: bold; text-align: center;width: 80%');
          $('.overlay-desc').append(`<div id='ovals' class='oval' style='position: absolute;z-index: 3;'></div>`);
          if (!self.permissionDenied) {
            self.recordVideo();
          }
        }, 500);
      }).catch((err0r) => {
      });
  }

  stopVideoRecording() {
    if (this.isVideoRecording) {
      this.videoRecordingService.stopRecording();

      this.isVideoRecording = false;
      this.video.controls = true;
    }
  }

  checkWordLength(word: String) {
    if (word.length > 7) {
      return true;
    }
    return false;
  }

  handleError(error) {
    if (error.name === 'ConstraintNotSatisfiedError') {
      const v = true;
      this.errorMsg('The resolution ${v.width.exact}x${v.height.exact} px is not supported by your device.', error);
    } else if (error.name === 'PermissionDeniedError') {
      this.errorMsg('Permissions have not been granted to use your camera and ' +
        'microphone, you need to allow the page access to your devices in ' +
        'order for the demo to work.', error);
    }

    this.errorMsg(`getUserMedia error: ${error.name}`, error);
  }

  errorMsg(msg, error) {
    console.error(error);
    console.error(msg);

    if (typeof error !== 'undefined') {
      console.error(error);
    }
  }
}
