import { Component, OnInit, ChangeDetectorRef, Input } from '@angular/core';
import { Router } from '@angular/router';
import { AlertService, CallsService, DataService, KYBService } from '../../../_services';
import { BsModalService } from 'ngx-bootstrap/modal';
import { DomSanitizer } from '@angular/platform-browser';
import { Role } from '../../../_models/kyb';

/**
 * Implements the enterprise page
*/
@Component({
    moduleId: module.id,
    // tslint:disable-next-line:component-selector
    selector: 'add-entity-simple',
    templateUrl: 'addEntitySimple.component.html',
    styleUrls: [ './addEntitySimple.component.css' ]
})

export class AddEntitySimpleComponent implements OnInit {

  @Input() entityId: any;
  @Input() primaryName: any;

  e2Instance = '';
  entities: any = [];
  entitiesResponse: any = {};
  roleTypeSelect = false;

  roleTypeResponse: any = {};
  roleTypes: any = [];
  roleType: any;
  role: Role;

  constructor (
    private router: Router,
    private callsService: CallsService,
    private alertService: AlertService,
    private dataService: DataService,
    private kybService: KYBService,
    private chRef: ChangeDetectorRef,
    private modalService: BsModalService,
    private domSanitizer: DomSanitizer
  ) {}

  ngOnInit() {
    this.kybService.getAllEntities({}).subscribe(
      data => {
        this.entitiesResponse = data;

        for (let i = 0; i < this.entitiesResponse.data.length; i++) {
          if (this.entitiesResponse.data[i].id !== this.entityId) {
            this.entities.push(this.entitiesResponse.data[i]);
          }
        }
      },
      error => {}
    );

    this.kybService.getV4RoleTypes().subscribe(
      data => {
        this.roleTypeResponse = data;
        for (let i = 0; i < this.roleTypeResponse.data.length; i++) {
          if (this.roleTypeResponse.data[i].self === false) {
            this.roleTypes.push(this.roleTypeResponse.data[i]);
          }
        }
      }
    );
  }

  roleSelected() {
    this.roleTypeSelect = true;
  }

  addRole() {
    this.role = new Role();
    this.role.e1Id = this.entityId;
    this.role.e2Id = this.e2Instance;
    this.role.roleTypeId = this.roleType;
    this.role.documents = [];
    this.role.documentIds = [];

    this.kybService.createRole(this.role).subscribe(
      data => {
        this.role = new Role();
        this.alertService.showSuccess('Role created successfully.');
      },
      error => {}
    );
  }
}
