<div class="col-lg-12 box entity-list" xmlns:height="http://www.w3.org/1999/xhtml" style="padding-left: 0px; padding-right: 0px;">
  <div style="height: 20px"></div>
  <p class="text-center" style="color: black;font-family: 'Open Sans', sans-serif; font-weight: 400;">Entity List</p> 
  <div style="height: 20px"></div>
  <div class="row">
    <div class="col-lg-12" style="padding-right: 0px;">
        <ul class="list-unstyled list-inline" style="width: 100%;">
            <li *ngIf="displayStructureBuilder" class="list-inline-item">
              <button *ngIf="userPermissions.indexOf('CreateEntity') !== -1 && userPermissions.indexOf('CreateRole') !== -1 && userPermissions.indexOf('CreateDocument') !== -1" style="padding: 8px;background-color:#355B89;border-color: #355B89" class="btn btn-success btn-entity-list" (click)="structureBuilder()" title="Go to structure builder">Structure Builder&nbsp;&nbsp;<i class="fa fa-magic" aria-hidden="true"></i>
              </button>
            </li>
            <li class="list-inline-item">
              <button style="background-color:#355B89;border-color: #355B89" class="btn btn-success btn-entity-list" (click)="addEntity()" title="Add new entity" *ngIf="userPermissions.indexOf('CreateEntity') !== -1">{{ "ADD_ENTITY" | translate }}&nbsp;&nbsp;<i class="fa fa-plus" aria-hidden="true"></i>
              </button>
            </li>
            <li *ngIf="displayAddRole" class="list-inline-item">
              <button style="background-color:#355B89;border-color: #355B89" class="btn btn-success btn-entity-list" (click)="addRole()" title="Add role" *ngIf="userPermissions.indexOf('CreateRole') !== -1">Add Role&nbsp;&nbsp;<i class="fa fa-plus" aria-hidden="true"></i>
              </button>
          </li>
          </ul>
          <button class="btn btn-primary btn-filter" (click)="toggleFilters()">{{ "FILTERS" | translate }}&nbsp;<mat-icon>filter_list</mat-icon></button>
          <ul *ngIf="displayFilters" class="list-unstyled list-inline" style="width: 100%;">
            <li class="list-inline-item" style="width: 100%; padding-left: 0px;">
              <div style="height: 20px;"></div>
              Select the tag(s) that you would like to see:
              <div style="height: 10px;"></div>
              <ng-select
              class="custom"
              [items]="userTags"
              [multiple]="true"
              bindLabel="name"
              [closeOnSelect]="false"
              bindValue="id"
              [clearable]="false"
              (add)="tagAdded($event)"
              (remove)="tagRemoved($event)"
              [compareWith]="compareFn"
              [(ngModel)]="includedTags">
              <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                  <input id="item-{{index}}" type="checkbox" [ngModel]="item$.selected"/> {{item.name}}
              </ng-template>
              </ng-select>
            </li>
            <div style="margin-top: 10px; display: flex;grid-column-gap: 10px;">
              <li class="list-inline-item"><button (click)="selectAllTags()" style="display: flex; justify-content: center; align-items: center;" class="btn btn-success btn-select-clear">Select all</button></li>
              <li class="list-inline-item"><button (click)="deselectAllTags()" style="display: flex; justify-content: center; align-items: center;" class="btn btn-success btn-select-clear">Clear all</button></li>
            </div>
          </ul>
          <ul *ngIf="displayFilters && isAdmin" class="list-unstyled list-inline" style="width: 100%;">
            <li class="list-inline-item" style="padding-left: 0px; width: 100%;">
              <div style="height: 20px;"></div>
              {{ "SELECT_THE_OWNER" | translate }}
              <div style="height: 10px;"></div>
              <ng-select class="custom-owner" [items]="users"
                  bindLabel="name"
                  bindValue="name"
                 [(ngModel)]="owner"
                 (change)="filterOwner($event)"
              >
              </ng-select>
          </li>
          </ul>
          <ul *ngIf="displayFilters && isAdmin" class="list-unstyled list-inline" style="width: 100%;">
            <li class="list-inline-item" style="padding-left: 0px; width: 100%;">
              <div style="height: 20px;"></div>
              Select the entity status you would like to see:
              <div style="height: 10px;"></div>
              <ng-select class="custom-owner" [items]="entityStatus2EList"
                  bindLabel="name"
                  bindValue="name"
                [(ngModel)]="entityStatus2E"
                (change)="filterEntityStatus2E($event)"
              >
              </ng-select>
            </li>
          </ul>

    </div>
  </div>  
      <div class="table-responsive" style="padding-left: 0px;padding-right: 0px;overflow-x: visible;"> 
        <div style="height: 40px"></div> 
        <div class="row">
          <div class="col-sm-6" style="text-align: left;">
            Show
            <select [(ngModel)]="pageSize" (ngModelChange)="pageSizeChanged()">
              <option [value]="30">30</option>
              <option [value]="50">50</option>
              <option [value]="100">100</option> 
              <option [value]="totalItems">All</option>
            </select> entities
          </div>
          <div class="col-sm-6" style="text-align: right;">
            Search: <input type="text" [(ngModel)]="searchQuery" (ngModelChange)="search()"/>  
          </div>
        </div>   
        <div style="height: 20px"></div>  
        <table id="entity-table" class="table role-detail-table__table table table-responsive table-hover" style="color: black;"> 
              <thead>
                <tr>
                    <th style="text-wrap: nowrap;"></th>
                    <th style="text-wrap: nowrap;">ID&nbsp;<i id="id-asc-sorticon" class="fa fa-long-arrow-up" aria-hidden="true" style="opacity: 0.4;font-size: 13px;" (click)="sort('id','asc')"></i>&nbsp;<i id="id-desc-sorticon" class="fa fa-long-arrow-down" aria-hidden="true" style="opacity: 0.4;font-size: 13px;" (click)="sort('id','desc')"></i></th>
                    <th style="text-wrap: nowrap;" *ngIf="userPermissions.indexOf('EntityDetailAccess') !== -1">{{ "NAME" | translate }}&nbsp;<i id="name-asc-sorticon" class="fa fa-long-arrow-up" aria-hidden="true" style="opacity: 0.4;font-size: 13px;" (click)="sort('name','asc')"></i>&nbsp;<i id="name-desc-sorticon" class="fa fa-long-arrow-down" aria-hidden="true" style="opacity: 0.4;font-size: 13px;" (click)="sort('name','desc')"></i></th>
                    <th style="text-align: center; text-wrap: nowrap;">PEP&nbsp;<i id="pepRisk-asc-sorticon" class="fa fa-long-arrow-up" aria-hidden="true" style="opacity: 0.4;font-size: 13px;" (click)="sort('pepRisk','asc')"></i>&nbsp;<i id="pepRisk-desc-sorticon" class="fa fa-long-arrow-down" aria-hidden="true" style="opacity: 0.4;font-size: 13px;" (click)="sort('pepRisk','desc')"></i></th>
                    <th style="text-align: center; text-wrap: nowrap;">Sanction&nbsp;<i id="sanctionRisk-asc-sorticon" class="fa fa-long-arrow-up" aria-hidden="true" style="opacity: 0.4;font-size: 13px;" (click)="sort('sanctionRisk','asc')"></i>&nbsp;<i id="sanctionRisk-desc-sorticon" class="fa fa-long-arrow-down" aria-hidden="true" style="opacity: 0.4;font-size: 13px;" (click)="sort('sanctionRisk','desc')"></i></th>
                    <th style="text-align: center; text-wrap: nowrap;" *ngIf="displayCompleteColumn">Complete&nbsp;<i id="complete-asc-sorticon" class="fa fa-long-arrow-up" aria-hidden="true" style="opacity: 0.4;font-size: 13px;" (click)="sort('complete','asc')"></i>&nbsp;<i id="complete-desc-sorticon" class="fa fa-long-arrow-down" aria-hidden="true" style="opacity: 0.4;font-size: 13px;" (click)="sort('complete','desc')"></i></th>
                    <th style="text-align: center; text-wrap: nowrap;" *ngIf="displayOverallStatusColumn">Overall Status&nbsp;<i id="entityStatus2E-asc-sorticon" class="fa fa-long-arrow-up" aria-hidden="true" style="opacity: 0.4;font-size: 13px;" (click)="sort('entityStatus2E','asc')"></i>&nbsp;<i id="entityStatus2E-desc-sorticon" class="fa fa-long-arrow-down" aria-hidden="true" style="opacity: 0.4;font-size: 13px;" (click)="sort('entityStatus2E','desc')"></i></th>
                    <th style="text-align: center; text-wrap: nowrap;">{{ "COMPLETE" | translate }}&nbsp;<i id="overallRisk-asc-sorticon" class="fa fa-long-arrow-up" aria-hidden="true" style="opacity: 0.4;font-size: 13px;" (click)="sort('overallRisk','asc')"></i>&nbsp;<i id="overallRisk-desc-sorticon" class="fa fa-long-arrow-down" aria-hidden="true" style="opacity: 0.4;font-size: 13px;" (click)="sort('overallRisk','desc')"></i></th>
                    <th style="text-align: center; text-wrap: nowrap;">{{ "OWNER" | translate }}&nbsp;<i id="owner-asc-sorticon" class="fa fa-long-arrow-up" aria-hidden="true" style="opacity: 0.4;font-size: 13px;" (click)="sort('owner','asc')"></i>&nbsp;<i id="owner-desc-sorticon" class="fa fa-long-arrow-down" aria-hidden="true" style="opacity: 0.4;font-size: 13px;" (click)="sort('owner','desc')"></i></th>
                    <th style="text-align: center; text-wrap: nowrap;">Type&nbsp;<i id="entityType-asc-sorticon" class="fa fa-long-arrow-up" aria-hidden="true" style="opacity: 0.4;font-size: 13px;" (click)="sort('entityType','asc')"></i>&nbsp;<i id="entityType-desc-sorticon" class="fa fa-long-arrow-down" aria-hidden="true" style="opacity: 0.4;font-size: 13px;" (click)="sort('entityType','desc')"></i></th>
                    <th *ngIf="userPermissions.indexOf('UpdateEntity') !== -1" style="text-align: center; text-wrap: nowrap;">Archive&nbsp;</th>
                  </tr>
            </thead>
            <tbody>
                <tr *ngIf="isEmpty" aria-colspan="9">
                  <td colspan="9" style="text-align: center;">
                    <div style="height: 20px;"></div>
                    <p style="text-align: center;font-family: Arial,sans-serif;font-size: 14px;">No entities found.</p>
                    <div style="height: 20px;"></div>
                  </td>
                </tr>
                <tr class="main-rows" *ngFor="let entity of entities; let i = index">
                    <td class="details-control"><i class="fa fa-plus-square" style="color: green;" (click)="getRoles(entity.id,i, entity.primaryName, entity.entityStatus, entity.entityStatus2E)"></i></td>
                    <td class="main-rows__id">{{entity.id}}</td>
                    <td class="main-rows__entity" *ngIf="userPermissions.indexOf('EntityDetailAccess') !== -1"><a style='color:black' [routerLink]="['/detail', entity.id]"><u>{{entity.primaryName}}</u></a></td>
                    <td [ngSwitch]="entity.pepRisk" style="text-align: center;">
                      <i *ngSwitchCase="'HIGH'" title="High Risk" style="color: rgb(214, 134, 15)" class="fa fa-circle" aria-hidden="true"></i>
                      <i *ngSwitchCase="'LOW'" title="Low Risk" style="color: #72C245" class="fa fa-circle" aria-hidden="true"></i>
                      <i *ngSwitchCase="'MEDIUM'" title="Medium Risk" style="color: #FDD32B" class="fa fa-circle" aria-hidden="true"></i>
                      <i *ngSwitchCase="'REJECTED'" title="Rejected" style="color: #D60000" class="fa fa-circle" aria-hidden="true"></i> 
                    </td>
                    <td [ngSwitch]="entity.sanctionRisk" style="text-align: center;">
                      <i *ngSwitchCase="'HIGH'" title="High Risk" style="color: rgb(214, 134, 15)" class="fa fa-circle" aria-hidden="true"></i>
                      <i *ngSwitchCase="'LOW'" title="Low Risk" style="color: #72C245" class="fa fa-circle" aria-hidden="true"></i>
                      <i *ngSwitchCase="'MEDIUM'" title="Medium Risk" style="color: #FDD32B" class="fa fa-circle" aria-hidden="true"></i>
                      <i *ngSwitchCase="'REJECTED'" title="Rejected" style="color: #D60000" class="fa fa-circle" aria-hidden="true"></i>
                    </td>
                    <td [ngSwitch]="entity.completeness" style="text-align: center;" *ngIf="displayCompleteColumn">
                      <i *ngSwitchCase="'INCOMPLETE'" title="Incomplete" style="color: #D60000" class="fa fa-circle" aria-hidden="true"></i>
                      <i *ngSwitchCase="'ROLES_COMPLETE'" title="Incomplete" style="color: #d68616" class="fa fa-circle" aria-hidden="true"></i>
                      <i *ngSwitchCase="'COMPLETE'" title="Complete" style="color: #72C245" class="fa fa-circle" aria-hidden="true"></i>
                    </td>
                    <td style="text-align: center;" *ngIf="displayOverallStatusColumn">{{entity.entityStatus2E}}</td>
                    <td [ngSwitch]="entity.overallRisk" style="text-align: center;">
                      <i *ngSwitchCase="'HIGH'" title="High Risk" style="color: rgb(214, 134, 15)" class="fa fa-circle" aria-hidden="true"></i>
                      <i *ngSwitchCase="'LOW'" title="Low Risk" style="color: #72C245" class="fa fa-circle" aria-hidden="true"></i>
                      <i *ngSwitchCase="'MEDIUM'" title="Medium Risk" style="color: #FDD32B" class="fa fa-circle" aria-hidden="true"></i>
                      <i *ngSwitchCase="'REJECTED'" title="Rejected" style="color: #D60000" class="fa fa-circle" aria-hidden="true"></i>
                      <i *ngSwitchCase="'UNEVALUATED'" title="Unevaluated" style="color: #bdbdbd" class="fa fa-circle" aria-hidden="true"></i>
                    </td>
                    <td style="text-align: center;">{{entity.ownerEmail}}</td>
                    <td style="text-align: center;">
                      <span *ngIf="entity.company == false"><i title="Physical Person" style="color: black;font-size: 22px;" class="fa fa-male" aria-hidden="true"></i></span>
                      <span *ngIf="entity.company == true"><i title="Moral Person" style="color: black;font-size: 22px;" class="fa fa-building" aria-hidden="true"></i></span>
                    </td>
                    <td *ngIf="userPermissions.indexOf('UpdateEntity') !== -1" style="text-align: center;">
                      <button (click)="updateArchived(entity.id)" style="border: none;background-color: white;"  [disabled]="entity.entityStatus2E=='ARCHIVED'"><i  class="fa fa-fw fa-lg fa-archive" style="color: #355B89;"></i></button>
                    </td>
                </tr>
            </tbody>
           <tfoot style="border: none;">
              <tr aria-colspan="9" style="border: none;">
                <td colspan="9" style="border: none;">
                  <div style="height: 5px;"></div>
                  <p style="font-family: Arial,sans-serif;font-size: 14px;color: #333;margin-bottom: -15px;margin-left: -10px;">Showing <b>{{numberOfElements}}</b> of <b>{{totalItems}}</b> entities</p>
                </td>
              </tr>
            </tfoot>
          </table>  
          <pagination [totalItems]="totalItems" [itemsPerPage]="pageSize" [(ngModel)]="currentPage" (pageChanged)="pageChanged($event)"></pagination>
    </div> 
    <div style="height: 10px"></div>
   
    <button style="display: none;" id="navigation-button" (click)="goToDetail()"></button>
</div>

