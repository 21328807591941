<div class="col-md-12" style="">
  <div style="height: 40px"></div>
    <p class="" style="font-size: 20pt; font-weight: 400; text-align: center;">Entity Dossier Reports</p>
    <p style="font-size: 15px; text-align: center; margin: 0 auto;">
      Here you can generate a PDF report for an entity which contains comprehensive information about the different risk levels. It includes information on any potential PEP/Sanction hits as well as an overview of roles connected to it, their completeness and documents status.
    </p>
    <div class="table-responsive" style="overflow-x: hidden;">
      <div class="row" style="margin: 0 auto;">
        <div class="col-sm-12" style="padding: 0px;"> 
            <div style="height: 40px"></div>
            <p>Select entity:</p>
            <div style="height: 20px"></div><br>
            <ng-select style="max-width: 500px;" [(ngModel)]="entityId" class="custom" [items]="entities" [loading]="entitiesLoading"  bindLabel="name" bindValue="id" appendTo="body"></ng-select>
            <div style="height: 50px;"></div>
            <button class="btn btn-primary" style="background-color: #355B89;border-color: #355B89;padding: 6px 25px;" (click)="reportForEntity(entityId)" [disabled]="entityId == '' || loading2">Generate Report for Entity</button>
            <button class="btn btn-primary" style="background-color: #355B89;border-color: #355B89;margin-left: 5px;padding: 6px 25px;" (click)="clientDossierForEntity(entityId)" [disabled]="entityId == '' || loading2">Download Client Dossier</button>
            <img *ngIf="loading2" [src]="loadingImg" style="width: 20px;"/>
        </div>
      </div>
    </div>
    <div style="overflow: hidden; height: 0;">
      <div [chart]="chart"></div>
      <div>
        <div id="newUboChart">
          <ngx-org-chart [nodes]="nodes" direction="vertical"></ngx-org-chart>
        </div>
        <div id="newControlChart">
          <ngx-org-chart [nodes]="controlNodes" direction="vertical"></ngx-org-chart>
        </div>
      </div>
    </div>
</div>