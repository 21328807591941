import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { AlertService, CallsService, DataService, KYBService } from '../../../../../_services';
import { Observable, Subscription } from 'rxjs';
import { Document, Entity, Role} from '../../../../../_models/kyb';
import { config } from '../../../../../../assets/configuration';

/**
 * Implements batch user importing (by uploading and parsing a csv file with user details) - not currently used
*/
@Component({
    moduleId: module.id,
    // tslint:disable-next-line:component-selector
    selector: 'app-company-form',
    templateUrl: 'companyForm.component.html',
    styleUrls: ['./companyForm.component.css']
})

export class CompanyFormComponent implements OnInit, OnDestroy {

  @Input() currentEntity: any;
  entityType: any;
  selfRoleId: any;
  entityStatus: any;
  primaryName = '';
  address = '';
  vat = '';
  currentEntityLevel: any;
  currentEntityId: any;
  autoLinkedEntitiesString = '';
  taxCountryFilled = false;
  loadingImg: any = config.loadingImg;
  loading: any = false;
  tags: any = [];

  delegateName = '';
  delegateEmail = '';
  entity: any = {};
  delegates: any;
  currentRole: any;
  currentEntityIdStructure: any;
  currentRoleStructure: any;
  currentStack: any;
  entityTypesResponse: any = {};
  entityTypes: any = [];
  taxCountry = '';

  newEntity: Entity;
  selfRole: Role;
  document: Document;
  companyInformationFile: File;
  documentResponse: any = {};
  documentIds: any = [];
  createEntityResponse: any = {};
  roleResponse: any = {};
  roleTypeResponse: any = {};

  private eventsSubscription: Subscription;
  @Input() events: Observable<void>;

  constructor(
    private callService: CallsService,
    private dataService: DataService,
    private kybService: KYBService,
    private alertService: AlertService
  ) {}

  ngOnInit() {
    this.loading = false;

    this.eventsSubscription = this.events.subscribe(() => {
      this.primaryName = this.dataService.primaryName.getValue();
      this.save();
    });

    this.dataService.currentSelfRoleId.subscribe(
      message => {
        this.selfRoleId = message;
      }
    );

    this.dataService.currentEntityType.subscribe(
      message => {
        this.entityType = message;

        this.kybService.getEntityTypes().subscribe(
          data => {
            this.entityTypesResponse = data;
            this.entityTypes = this.entityTypesResponse.data;

            for (let i = 0; i < this.entityTypes.length; i++) {
              if (this.entityTypes[i].id === this.entityType) {
                this.autoLinkedEntitiesString = this.entityTypes[i].autoLinkedEntities;
                for (let j = 0; j < this.entityTypes[i].requiredRoles.requiredE1Roles.length; j++) {
                  if (this.entityTypes[i].requiredRoles.requiredE1Roles[j].roleType.self === true) {
                    this.dataService.setSelfRoleId(this.entityTypes[i].requiredRoles.requiredE1Roles[j].roleType.id);
                  }
                }
              }
            }
          }
        );
      }
    );

    this.dataService.currentEntityStatus.subscribe(
      message => {
        this.entityStatus = message;
      }
    );

    this.dataService.currentEntityTags.subscribe(
      message => {
        this.tags = message;
      }
    );

    this.dataService.stack.subscribe(message => this.currentStack = message);

    this.dataService.currentRole.subscribe(message => {
      this.currentRole = message;
    });

    this.dataService.currentRoleStructure.subscribe(
      message => {
        this.currentRoleStructure = message;
      }
    );

    this.dataService.currentDelegates.subscribe(message => this.delegates = message);

    this.dataService.currentEntityLevel.subscribe(message => {
      this.currentEntityLevel = message;
    });

    this.dataService.currentEntityId.subscribe(message => {
      this.currentEntityId = message;
    });

    this.dataService.entityIdStructure.subscribe(
      message => {
          this.currentEntityIdStructure = message;
      }
    );

    if (this.currentEntity !== undefined) {
      this.primaryName = this.currentEntity.primaryName;
      this.address = this.currentEntity.address;
      this.vat = this.currentEntity.vat;
    }
  }

  receiveEvent(event) {}

  isTaxCountryFilled() {
    this.taxCountryFilled = true;
  }

  save() {
    this.loading = true;
    this.newEntity = new Entity();
    this.newEntity.entityTypeId = this.entityType;
    this.newEntity.primaryName = this.primaryName;
    this.newEntity.roles = [];
    this.newEntity.delegateEmail = null;
    this.newEntity.ownerId = localStorage.getItem('identifier');
    this.newEntity.entityStatus = this.entityStatus;

    const tagIds = [];

    if (this.tags !== null) {
      for (let i = 0; i < this.tags.length; i++) {
        tagIds.push(this.tags[i].id);
      }
    }

    this.newEntity.tagIds = tagIds;

    this.kybService.createEntity(this.newEntity).subscribe(
        data => {
          this.createEntityResponse = data;

          if (this.currentStack !== undefined) {
            // tslint:disable-next-line:max-line-length
            this.currentStack.entities.push({ 'primaryName': this.primaryName, 'type': 'legal', 'delegated': false, 'id': this.createEntityResponse.data.entityId });
          }

          this.kybService.getToDoList(this.createEntityResponse.data.entityId).subscribe(
            list => {},
            error => {
            }
          );

          this.loading = false;
          this.dataService.triggerCloseModalEvent(33);
          this.dataService.triggerRefreshEvent(26);
          this.alertService.showSuccess('Entity created.');
          this.entity = {};
          this.documentIds = [];
          this.reset();
        },
        error => {
          this.loading = false;
          this.alertService.showError(error.error.fieldErrors[0].message);
        }
    );
  }

  reset() {
    this.primaryName = '';
    this.address = '';
    this.vat = '';
  }

  validate() {
    if (this.primaryName !== '') {
      return true;
    } else {
      return false;
    }
  }

  ngOnDestroy() {
    this.eventsSubscription.unsubscribe();
  }

  validateDelegation() {
    if (this.primaryName !== '' && this.delegateName !== '' && this.delegateEmail !== '') {
      return true;
    } else {
      return false;
    }
  }

  delegate() {
    this.delegates.push({ name: this.delegateName, email: this.delegateEmail, entity: this.primaryName });
  }
}
