<form #personalForm="ngForm" class="editForm" novalidate>
    <div class="tab-pane fade in active">
        <h4 class="head text-center">{{title}}</h4>
        <br/>
        <div class='row'>
            
            <div class='col-sm-12'>
                <div class="row" *ngIf="thirdParty == 1">
                    <div class="col-sm-12">
                        <p style="font-size: 19px;">Please fill in the information of the person you represent.</p>
                        <app-person-form></app-person-form>
                    </div>
                </div>
                <div class="row">    
                    <div class="col-sm-12">
                        <button class="btn btn-succes btn-add-role btn-entity-list" style="background-color:#355B89;border-color:#355B89;" (click)="addEntity()">Add Entity&nbsp;<i class="fa fa-plus" aria-hidden="true"></i></button>&nbsp;&nbsp;
                        <button class="btn btn-success btn-add-role btn-entity-list" style="background-color:#355B89;border-color:#355B89;"  *ngIf="currentStack?.entities?.length > 0" (click)="addRole()">Add Role&nbsp;<i class="fa fa-plus" aria-hidden="true"></i></button>&nbsp;&nbsp;
                        <button class="btn btn-success btn-entity-list" style="background-color:#355B89;border-color:#355B89;"  *ngIf="currentStack?.entities?.length > 0" (click)="addDocument()">Add Document&nbsp;<i class="fa fa-plus" aria-hidden="true"></i></button>&nbsp;&nbsp;
                        <button class="btn btn-danger" *ngIf="entityId != ''" (click)="goBack()">Return to detail</button>
                        <div *ngIf="currentStack?.entities?.length == 0">
                            <br>
                            Click "Add Entity" to begin.
                        </div>
                        <div style="height: 20px;"></div>
                        <div>
                            <span style="font-weight: bold;text-decoration: underline;">To do list:</span>
                            <div style="height: 10px;"></div>
                            <div *ngFor="let todo of currentToDo">
                                <b>{{todo.entityName}}</b>
                                <ul>
                                    <li *ngFor="let item of todo.todos">{{item?.message}}</li>
                                </ul>
                            </div>
                        </div>
                        <div style="height: 20px;"></div>
                            <div style="width: 100%; border-bottom: 2px solid rgb(232, 238, 241);">
                                <h4 style="text-align: center">ENTITIES</h4>
                            </div>
                            <div class="table-responsive" style="padding-left: 0px;padding-right: 0px;overflow-x: visible;"> 
                                <div style="height: 40px"></div>         
                                <table class="table-entities" width="100%" style="text-align: left;">
                                    <thead>
                                        <tr>
                                            <th>ID</th>
                                            <th>Primary name</th>
                                            <th>Type</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let entity of currentStack.entities">
                                            <td>{{entity.id}}</td>
                                            <td>{{entity.primaryName}}</td>
                                            <td [ngSwitch] = "entity?.type" style="font-size: 22px;">
                                                <i *ngSwitchCase="'person'" title="Physical Person" style="color:black" class="fa fa-male" aria-hidden="true"></i>
                                                <i *ngSwitchCase="'legal'" title="Moral Person" style="color: black" class="fa fa-building" aria-hidden="true"></i>
                                            </td>
                                            <td style="padding: 5px;">
                                                <button class="btn btn-danger btn-delete" (click)="deleteEntity(entity.id,entity.primaryName)">Delete</button>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>  
                            </div>
                    </div>
                </div>
                <div style="height: 30px;"></div>
                <div class="row">    
                    <div class="col-sm-12">
                            <div style="width: 100%; border-bottom: 2px solid rgb(232, 238, 241);">
                                <h4 style="text-align: center">ROLES</h4>
                            </div>
                            <div class="table-responsive" style="padding-left: 0px;padding-right: 0px;overflow-x: visible;"> 
                                <div style="height: 40px"></div>         
                                <table class="table-roles" width="100%" style="text-align: left;">
                                    <thead>
                                        <tr>
                                            <th>ID</th>
                                            <th>Entity 1</th>
                                            <th>Entity 2</th>
                                            <th>Type</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let role of currentStack.roles">
                                            <td>{{role.id}}</td>
                                            <td>{{role.entity1}}</td>
                                            <td>{{role.entity2}}</td>
                                            <td>{{role.type}}</td>
                                            <td style="padding: 5px;"><button class="btn btn-danger btn-delete" (click)="deleteRole(role.id)">Delete</button></td>
                                        </tr>
                                    </tbody>
                                </table>  
                            </div>
                    </div>
                </div>
                <div style="height: 30px;"></div>
                <div class="row">    
                    <div class="col-sm-12">
                            <div style="width: 100%; border-bottom: 2px solid rgb(232, 238, 241);">
                                <h4 style="text-align: center">DOCUMENTS</h4>
                            </div>
                            <div class="table-responsive" style="padding-left: 0px;padding-right: 0px;overflow-x: visible;"> 
                                <div style="height: 40px"></div>         
                                <table class="table-documents" width="100%" style="text-align: left;">
                                    <thead>
                                        <tr>
                                            <th>Description</th>
                                            <th>Connected to</th>
                                            <th>Type</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let document of currentStack.documents">
                                            <td>{{document.description}}</td>
                                            <td>{{document.role}}</td>
                                            <td>{{document.type}}</td>
                                        </tr>
                                    </tbody>
                                </table>  
                            </div>
                    </div>
                </div>
                <div style="height: 40px"></div>
                <div class="form-group text-center">
                    <button type="button" class="btn btn-success btn-outline-rounded btn-info btn-finished"  (click)="finish()">Finished&nbsp;&nbsp;<i class="fa fa-check" aria-hidden="true"></i></button>
                </div>
            </div>
        </div>
    </div>
</form>
