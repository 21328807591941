import { Component, OnInit, ViewChild, ChangeDetectorRef, Input } from '@angular/core';
import { AlertService, CallsService, KYBService, DataService } from '../../../../_services';
import { Document, Entity, Role, RoleUpdate } from '../../../../_models/kyb';
import { Personal } from '../../data/formData.model';
import { NgForm } from '@angular/forms';
import { DeviceDetectorService } from 'ngx-device-detector';
import { FormDataService } from '../../data/formData.service';
import { config } from '../../../../../assets/configuration';
import { DomSanitizer } from '@angular/platform-browser';
import { VideoRecordingService } from '../../../../_services/video-recording.service';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { MobileVideoOnboardingRecordingComponent } from '../mobile-video-onboarding-recording/mobile-video-onboarding-recording.component';
import { TranslateService } from '@ngx-translate/core';

declare var MediaRecorder: any;

/**
 * Implements the login page
*/
@Component({
  moduleId: module.id,
  selector: 'app-mobile-video-onboarding',
  templateUrl: 'mobile-video-onboarding.component.html',
  styleUrls: ['./mobile-video-onboarding.component.css']
})

export class MobileVideoOnboardingComponent implements OnInit {

  video: any;
  @ViewChild('personalForm', { static: false }) public personalForm: NgForm;
  @Input() customOnboarding = false;
  firstConnection = true;
  title = 'Video Recording';
  personal: Personal;
  _localStreamReference: any;
  mediaRecorder: any;
  wordsResponse: any = {};
  words: any = [];
  mimeTypeForSaving = '';
  loading = false;
  email = '';
  currentStack: any;
  loadingImg: any = config.loadingImg;
  documentId: any;
  fileId: any;
  videoStarted = false;
  public get agent(): any { return navigator.userAgent || navigator.vendor; }
  public get chrome(): boolean { return !!this.agent.toLowerCase().match(/(chrome)/i); }
  public get safari(): boolean { return !!this.agent.toLowerCase().match(/(safari)/i); }
  public get firefox(): boolean { return !!this.agent.toLowerCase().match(/(firefox)/i); }
  public get firefoxIOS(): boolean { return !!navigator.userAgent.toLowerCase().match(/(fxios)/i); }
  public get chromeIOS(): boolean { return !!navigator.userAgent.toLowerCase().match(/(crios)/i); }
  public get operaIOS(): boolean { return !!navigator.userAgent.toLowerCase().match(/(opios)/i); }
  public get samsungAndroid(): boolean { return !!navigator.userAgent.toLowerCase().match(/(samsungbrowser)/i); }
  public get android(): boolean { return !!this.agent.match(/(android)/i); }
  public get ios(): boolean { return !!this.agent.match(/(iPhone)|(iPod)/i); }
  showRetry = false;

  videoBlobUrl;
  videoBlob;
  videoName;
  videoStream: MediaStream;
  videoConf = { video: { facingMode: 'user', width: 320 }, audio: true };

  recordedBlobs: any = [];

  videoSelfie: File;
  videoResponse: any = {};
  document: Document;
  createEntityResponse: any = {};
  roleResponse: any = {};
  getRoleResponse: any = {};
  updatedRole: RoleUpdate;
  additionalDocumentIds: any = [];
  requiredDocumentIds: any = [];
  requiredDocumentTypes: any = [];
  entity: Entity;
  selfRole: Role;
  documentIds: any = [];
  selfRoleId: any;
  onboardingEntityId: any;
  captureImageModalRef: BsModalRef;
  videoAnalysisResponse: any = {};
  videoDocument: Document;
  videoFile: File;
  documentData: any = {};
  documentResponse: any = {};

  constructor(
    private translate: TranslateService,
    private modalService: BsModalService,
    private deviceDetectorService: DeviceDetectorService,
    private ref: ChangeDetectorRef,
    private videoRecordingService: VideoRecordingService,
    private sanitizer: DomSanitizer,
    private kybService: KYBService,
    private callsService: CallsService,
    private dataService: DataService,
    private formDataService: FormDataService,
    private alertService: AlertService
  ) {}

  ngOnInit() {
    this.dataService.firstConnection.subscribe(message => {
      this.firstConnection = message;
    });

    // TODO: Disable Finish button

    this.dataService.stack.subscribe(message => this.currentStack = message);
    this.dataService.currentDocumentIds.subscribe(message => this.documentIds = message);
    this.email = localStorage.getItem('registrationEmail');
    this.personal = this.formDataService.getPersonal();
    this.selfRoleId = this.dataService.onboardingEntitySelfRoleId.getValue();
    this.onboardingEntityId = this.dataService.onboardingEntityId.getValue();
    this.document = new Document();
    this.document.docTypeId = 3;
    this.document.mimeType = 'video/webm';
    this.document.retainTo = '2026-01-01T11:52:05.381';
    this.document.validTo = '2026-01-01T11:52:05.381';
    this.document.totalFiles = 1;
    this.subscribeToStepChanges();
  }

  subscribeToStepChanges(): void {
    this.formDataService.currentRegisterState.subscribe(message => {
      if (message === 4) {
        this.goToNext(this.personalForm);
      }
    });
  }

  startVideo(ref: any) {
    /*  if (this.ios ) {
       this.document.mimeType = 'video/mp4';
     }
     else
     {
       this.document.mimeType = 'video/webm';
     } */
    if (this.firstConnection) {
      this.kybService.createDocument(this.document, this.onboardingEntityId, this.selfRoleId, this.onboardingEntityId, null).subscribe(
        videoResponse => {
          this.videoResponse = videoResponse;
          this.documentId = this.videoResponse.data.documentId;
          this.fileId = this.videoResponse.data.fileIds[0];
          this.words = JSON.parse(this.videoResponse.data.contextualData);
          const initialState = { words: this.words };
          this.captureImageModalRef = this.modalService.show(MobileVideoOnboardingRecordingComponent, { initialState, backdrop: 'static' });

          this.captureImageModalRef.content.event.subscribe(content => {
            this.videoBlob = content.data.blob;
            this.videoName = content.data.title;
            this.videoBlobUrl = window.URL.createObjectURL(content.data.blob);
            this.goToNext(null);
          });
        },
        error => {
          this.alertService.showError(this.translate.instant('CONNECTION_VIDEO_START_ERROR'));
        }
      );
    } else {
      this.kybService.getConfirmationWords(5).subscribe(
        data => {
          this.words = data.data;
          const initialState = { words: this.words };
          this.captureImageModalRef = this.modalService.show(MobileVideoOnboardingRecordingComponent, { initialState, backdrop: 'static' });
          this.captureImageModalRef.content.event.subscribe(content => {
            this.videoBlob = content.data.blob;
            this.videoName = content.data.title;
            this.videoBlobUrl = window.URL.createObjectURL(content.data.blob);
            this.goToNext(null);
          });
        },
        error => {}
      );
    }
  }

  goToNext(form?: any) {
    this.showRetry = false;
    this.selfRoleId = this.dataService.onboardingEntitySelfRoleId.getValue();
    this.onboardingEntityId = this.dataService.onboardingEntityId.getValue();
    const blobUrl = this.videoBlobUrl;
    this.loading = true;

    this.callsService.getSelfieBlob(blobUrl).subscribe(
      data => {
        if (!data) {
          return false;
        }

        this.videoSelfie = new File([data], 'selfieVideo', { type: 'video/webm', lastModified: Date.now() });

        if (!this.videoSelfie) {
          return false;
        }

        if (this.firstConnection) {
          this.kybService.saveFile(this.videoSelfie, this.documentId, this.fileId).subscribe(
            file => {
              this.loading = false;
              this.formDataService.setfinishOnboarding(true);
            },
            error => {
              this.loading = false;
              this.alertService.showError(this.translate.instant('CONNECTION_VIDEO_UPLOAD_ERROR'));
              this.showRetry = true;
            }
          );
        } else {
          this.getVideoAnalysis();
        }
      },
      error => {}
    );
  }

  getVideoAnalysis() {
    this.kybService.getVideoAuth(this.videoSelfie, this.words).subscribe(
      data => {
        this.videoAnalysisResponse = data;
        if (this.videoAnalysisResponse.data.faceRec.faceMatch !== true ||
          this.videoAnalysisResponse.data.faceRec.humanFace !== true) {
          this.videoSelfie = undefined;
          this.alertService.showError('User video auth failed');
          this.loading = false;

          setTimeout(() => {
          }, 1000);
        } else {
          this.loading = false;
          this.formDataService.setfinishOnboarding(true);
          this.ref.detectChanges();
        }
      },
      error => {
      }
    );
  }

  blobToFile(theBlob, fileName) {
    theBlob.lastModifiedDate = new Date();
    theBlob.name = fileName;
    return theBlob;
  }

  handleError(error) {
    if (error.name === 'ConstraintNotSatisfiedError') {
      const v = true;
      this.errorMsg('The resolution ${v.width.exact}x${v.height.exact} px is not supported by your device.', error);
    } else if (error.name === 'PermissionDeniedError') {
      this.errorMsg('Permissions have not been granted to use your camera and ' +
        'microphone, you need to allow the page access to your devices in ' +
        'order for the demo to work.', error);
    }

    this.errorMsg(`getUserMedia error: ${error.name}`, error);
  }

  errorMsg(msg, error) {
    console.error(error);
    console.error(msg);
    if (typeof error !== 'undefined') {
      console.error(error);
    }
  }
}
