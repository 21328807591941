import { HttpClient, HttpHeaders } from '@angular/common/http';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
var ApiService = /** @class */ (function () {
    function ApiService(http) {
        this.http = http;
        this.apiUrl = '/api/v3/';
        this.httpOptions = this.getHttpOptions();
    }
    ApiService.prototype.get = function (url) {
        this.httpOptions = {};
        if (localStorage.getItem('auth_token')) {
            this.httpOptions = {
                headers: new HttpHeaders()
                    .append('X-Auth-Token', JSON.parse(localStorage.getItem('auth_token')))
                    .append('Content-Type', 'application/json')
            };
        }
        else {
            this.httpOptions = {
                headers: new HttpHeaders()
                    .append('Content-Type', 'application/json')
            };
        }
        return this.http.get("" + this.apiUrl + url, this.httpOptions);
    };
    ApiService.prototype.post = function (url, body) {
        return this.http.post("" + this.apiUrl + url, body, this.httpOptions);
    };
    ApiService.prototype.put = function (url, body) {
        var httpOptions = {
            headers: new HttpHeaders()
                .append('Content-Type', 'application/json')
                .append('X-Auth-Token', JSON.parse(localStorage.getItem('auth_token')))
        };
        return this.http.put("" + this.apiUrl + url, body, httpOptions);
    };
    ApiService.prototype.delete = function (url) {
        return this.http.delete("" + this.apiUrl + url, this.httpOptions);
    };
    ApiService.prototype.getHttpOptions = function () {
        return {
            headers: new HttpHeaders()
                .append('Content-Type', 'application/json')
                .append('X-Auth-Token', JSON.parse(localStorage.getItem('token'))),
        };
    };
    ApiService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function ApiService_Factory() { return new ApiService(i0.ɵɵinject(i1.HttpClient)); }, token: ApiService, providedIn: "root" });
    return ApiService;
}());
export { ApiService };
