import { Component, OnDestroy, OnInit, Input } from '@angular/core';
import { WebDataService } from '../../../../_services/index';
import { Observable } from 'rxjs';
import { Chart } from 'angular-highcharts';

@Component({
    moduleId: module.id,
    // tslint:disable-next-line:component-selector
    selector: 'piechart',
    templateUrl: 'piechart.component.html',
    styleUrls: [ './piechart.component.css' ]
})

export class PiechartComponent implements OnDestroy, OnInit {
  error: string;

  searchQuery: string;
  results: any = {};
  reportResults: any = {};
  currentArticles: any = {};

  pieChart: Chart;

  @Input() events: Observable<void>;

  constructor(private dataService: WebDataService) {}

  ngOnInit() {}

  // function for preparing the data for pie chart
  preparePie(arr) {
    const a = [], b = [];
    let prev, y;
    let total = 0;
    const result = [];

    arr.sort();

    for (let i = 0; i < arr.length; i++) {
        if (arr[i] !== prev) {
            a.push(arr[i]);
            b.push(1);
        } else {
            b[b.length - 1]++;
        }
        prev = arr[i];
    }

    for (let k = 0; k < b.length; k++) {
      total += b[k];
    }

    for (let j = 0; j < a.length; j++) {
      y = (b[j] / total ) * 100;
      y = Math.round(y * 100) / 100;
      result.push({'name': a[j], 'y': y});
    }

    return result;
  }

  receiveEvents($event) {
    this.reportResults = this.dataService.reportResults.getValue();
    this.reportResults = this.reportResults.articles;
    this.drawPie();
  }

  receiveArticlesChangedEvent($event) {
    this.drawPie();
  }

  drawPie() {
    this.reportResults = this.dataService.reportResults.getValue();
    this.reportResults = this.reportResults.articles;
    const pieData = [];

    for (let i = 0, len = this.reportResults.length; i < len; i++) {
      pieData.push(this.reportResults[i].topic);
    }

    this.pieChart = new Chart({
      chart: {
        height: '350',
        type: 'pie'
      },
      credits: {
        enabled: false
      },
      title: {
        text: ''
      },
      colors: ['#0A3073', '#50B432', '#ED561B', '#973687', '#24CBE5', '#64E572',
      '#FF9655', '#DDDF00', '#6AF9C4', '#900C3F'],
      plotOptions: {
        pie: {
          allowPointSelect: true,
          cursor: 'pointer',
          dataLabels: {
            enabled: false
          },
          animation: false,
          showInLegend: true
        }
      },
      series: [{
        name: '%',
        data: this.preparePie(pieData)
    }] as any
    });

    const svg = document.querySelector('svg') as SVGSVGElement;

    if (svg !== null) {
      const svgSize = svg.getBoundingClientRect();
      const xml = new XMLSerializer().serializeToString(svg);
      const svg64 = btoa(unescape(encodeURIComponent(xml)));
      const b64Start = 'data:image/svg+xml;base64,';
      let image64 = b64Start + svg64;
      const canvas = document.createElement('canvas');
      canvas.width = svgSize.width;
      canvas.height = svgSize.height;
      const ctx = canvas.getContext('2d');
      const img = document.createElement('img');

      img.setAttribute('src', 'data:image/svg+xml;base64,' + svg64);
      img.setAttribute('alt', 'pieChart');
      img.onload = () => {
          ctx.drawImage(img, 0, 0);
          image64 = canvas.toDataURL('image/png');
          sessionStorage.setItem('pieChart', image64);
      };
    }
  }

  ngOnDestroy() {
    this.pieChart.destroy();
  }
}
