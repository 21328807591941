
      <div class='modal-header'>
      <p class='modal-title pull-left' style='font-size: 16px;'><b>Screening Results Evaluator</b></p>
      <button type='button' class='close pull-right' aria-label='Close' (click)='close()'>
        <span aria-hidden='true' style='color: gray; font-size: 35px'>&times;</span>
      </button>
    </div>
    <div class='modal-body'>
      <div class='row'>
        <div class='col-md-1'></div>
        <div class='col-md-5' style='font-size: 16px;'>
          Entity name: <a href='/detail/{{probableMatches[currentMatch].entityId}}' target='_blank'><b>{{probableMatches[currentMatch].entityName}}</b></a>&nbsp;&nbsp;<img *ngIf='loading' [src]='loadingImg' style='width: 20px;padding-bottom: 3px;'/><br>
        </div>
        <div class='col-md-5' style='font-size: 16px;'>
        </div>
        <div class='col-md-1'></div>
      </div>
      <div class='row'>
        <div class='col-md-1'>
        </div>
        <div class='col-md-10'>
            <div style='height: 20px;'></div>
            <div class='pep-hits-detail'>
                  <table width='100%' style='border: 1px solid whitesmoke'>
                    <tr>
                      <td width='100%'>
                          <button class='btn btn-link btn-block clearfix' style='align-items: center;background-color: none;border-bottom: 3px solid #daaa0e;color: white; display: flex;font-weight: bold;' accordion-heading>
                              <div *ngIf="probableMatches[currentMatch].matches.matchType == 'MEDIA'" class='pull-left float-left'><i class='fa fa-newspaper-o' aria-hidden='true' title='News Article' style='color: #daaa0e;font-size: 18px;'></i>&nbsp;&nbsp;</div>
                              <div *ngIf="probableMatches[currentMatch].matches.matchType == 'MEDIA'" class='pull-left float-left' style='color: #daaa0e;'><span style='font-size: 13px;'>[{{probableMatches[currentMatch].matches.riskType}}]</span> <a href='{{probableMatches[currentMatch].matches.mediaRiskDescriptor.url}}' target='_blank'> {{probableMatches[currentMatch].matches.mediaRiskDescriptor.headline | shorterText}}</a></div>
                              <div *ngIf="probableMatches[currentMatch].matches.matchType == 'PEP_OR_SANCTION' || probableMatches[currentMatch].matches.matchType == null" class='pull-left float-left'><i class='fa fa-user' aria-hidden='true' title='PEP/Sanction hit' style='color: #daaa0e;font-size: 18px;'></i>&nbsp;&nbsp;</div>
                              <div *ngIf="probableMatches[currentMatch].matches.matchType == 'SEARCH_ENGINE'" class='pull-left float-left'><i class='fa fa-search' aria-hidden='true' title='Search Engine hit' style='color: #daaa0e;font-size: 18px;'></i>&nbsp;&nbsp;</div>
                              <div *ngIf="probableMatches[currentMatch].matches.entityName != null && (probableMatches[currentMatch].matches.matchType == 'PEP_OR_SANCTION' || probableMatches[currentMatch].matches.matchType == null)" class='pull-left float-left pep-hit-probable screening-text' style='color: #daaa0e'>{{probableMatches[currentMatch].matches.entityName | shorterText}}</div>
                              <div *ngIf="probableMatches[currentMatch].matches.entityName == null && probableMatches[currentMatch].matches.entityRiskDescriptor.names.length > 0 && (probableMatches[currentMatch].matches.matchType == 'PEP_OR_SANCTION' || probableMatches[currentMatch].matches.matchType == null)" class='pull-left float-left pep-hit-probable screening-text' style='color: #daaa0e'>{{probableMatches[currentMatch].matches.entityRiskDescriptor.names[0] | shorterText}}</div>
                              <div *ngIf="probableMatches[currentMatch].matches.entityName == null && probableMatches[currentMatch].matches.entityRiskDescriptor.names.length == 0 && (probableMatches[currentMatch].matches.matchType == 'PEP_OR_SANCTION' || probableMatches[currentMatch].matches.matchType == null)" class='pull-left float-left pep-hit-probable screening-text' style='color: #daaa0e'></div>
                              <div *ngIf="probableMatches[currentMatch].matches.matchType == 'SEARCH_ENGINE'" class='pull-left float-left screening-text' style='color: #daaa0e;'><span style='font-size: 13px;'>[{{probableMatches[currentMatch].matches.riskType}}]</span> <a href='{{probableMatches[currentMatch].matches.searchEngineRiskDescriptor.url}}' target='_blank'> {{probableMatches[currentMatch].matches.searchEngineRiskDescriptor.headline | shorterText}}</a></div>
                              <div class='pull-right float-right' style='padding-left: 5px;'><img src="../../../assets/images/icons/add_further.png" width='20px' class='float-right' tooltip='Move to Further Research Matches' (click)="moveToFurther(probableMatches[currentMatch].entityId,probableMatches[currentMatch].matches?.hashedEntryId,'FURTHER_RESEARCH')" /></div>
                              <div class='pull-right float-right' style='padding-left: 5px;'><img src="../../../assets/images/icons/add_false.png" width='20px' class='float-right' tooltip='Move to Rejected Matches' (click)="moveToWhitelisted(probableMatches[currentMatch].entityId,probableMatches[currentMatch].matches?.hashedEntryId,'WHITE_LISTED')" /></div>
                              <div class='pull-right float-right' style='padding-left: 5px;'><img src="../../../assets/images/icons/add_hit.png" width='20px' class='float-right' tooltip='Move to Confirmed Matches' (click)="moveToConfirmed(probableMatches[currentMatch].entityId,probableMatches[currentMatch].matches?.hashedEntryId,'CONFIRMED')" /></div>
                          </button>
                      </td>
                    </tr>
                    <div style='height: 15px;'></div>
                    <tr>
                      <td width='100%' style='padding-left: 20px;'>
                        <span *ngIf="probableMatches[currentMatch].matches.note != null && probableMatches[currentMatch].matches.note != ''" class='note' style='background-color: wheat;padding: 5px;text-align: center;'>
                        {{probableMatches[currentMatch].matches.note}}</span>
                      </td>
                    </tr>
                    <div style='height: 10px;'></div>
                    <tr *ngIf="probableMatches[currentMatch].matches.matchType == 'MEDIA'">
                      <td style='color: black;width: 100%' width='100%'>
                          <div style='height: 10px;'></div>
                          <ul>
                              <li><b>Headline:</b> {{probableMatches[currentMatch].matches.mediaRiskDescriptor.headline}}</li>
                              <li *ngIf='probableMatches[currentMatch].matches.mediaRiskDescriptor.publicationDate != null'><b>Publication date:</b> {{probableMatches[currentMatch].matches.mediaRiskDescriptor.publicationDate | dateParser}}</li>
                              <li><b>Topic:</b> {{probableMatches[currentMatch].matches.mediaRiskDescriptor.topicLabel}}</li>
                              <li><b>Score:</b> <ng-container *ngIf='probableMatches[currentMatch].matches.score != 0'> {{probableMatches[currentMatch].matches.score}}</ng-container><ng-container *ngIf='probableMatches[currentMatch].matches.score == 0'> Not calculated</ng-container></li>
                              <div style='height: 10px;'></div>
                              <input style='background-color: #e6e6e6;border-radius: 0px;border: none;height: 27px;width: 95%;'
                               id='note' name='note' type='text' [(ngModel)]='note' placeholder='Enter note here...'>
                          </ul>
                            <div style='height: 10px'></div>
                      </td>
                    </tr>
                    <tr *ngIf="probableMatches[currentMatch].matches.matchType == 'SEARCH_ENGINE'">
                    <td style='color: black;width: 100%' width='100%'>
                        <div style='height: 10px;'></div>
                        <ul>
                            <li><b>Headline:</b> {{probableMatches[currentMatch].matches.searchEngineRiskDescriptor.headline}}</li>
                            <li *ngIf='probableMatches[currentMatch].matches.searchEngineRiskDescriptor.publicationDate != null'><b>Publication date:</b> {{probableMatches[currentMatch].matches.searchEngineRiskDescriptor.publicationDate | dateParser}}</li>
                            <li>{{probableMatches[currentMatch].matches.searchEngineRiskDescriptor.text}}</li>
                            <li><b>Score:</b> <ng-container *ngIf='probableMatches[currentMatch].matches.score != 0'> {{probableMatches[currentMatch].matches.score}}</ng-container><ng-container *ngIf='probableMatches[currentMatch].matches.score == 0'> Not calculated</ng-container></li>
                            <div style='height: 10px;'></div>
                            <input style='background-color: #e6e6e6;border-radius: 0px;border: none;height: 27px;width: 95%;'
                             id='note' name='note' type='text' [(ngModel)]='note' placeholder='Enter note here...'>
                        </ul>
                          <div style='height: 10px'></div>
                    </td>
                  </tr>
                    <tr *ngIf="probableMatches[currentMatch].matches.matchType == 'PEP_OR_SANCTION' || probableMatches[currentMatch].matches.matchType == null">
                      <td style='color: black;width: 100%' width='100%'>
                          <div style='height: 10px;'></div>
                          <ul>
                              <li *ngIf='probableMatches[currentMatch].matches.entityRiskDescriptor.descriptions != null && probableMatches[currentMatch].matches.entityRiskDescriptor.descriptions.length > 0'>{{probableMatches[currentMatch].matches.entityRiskDescriptor.descriptions[0]}}</li>
                              <li><b>First seen:</b> {{probableMatches[currentMatch].matches.entityRiskDescriptor.firstSeen | date}}</li>
                              <li><b>Last seen:</b> {{probableMatches[currentMatch].matches.entityRiskDescriptor.lastSeen | date}}</li>
                              <li><b>AKA Names:</b> {{probableMatches[currentMatch].matches.entityRiskDescriptor.names}}</li>
                              <li *ngIf='probableMatches[currentMatch].matches?.entityRiskDescriptor.birthDates && probableMatches[currentMatch].matches?.entityRiskDescriptor.birthDates.length > 0'><b>Birth date:</b> {{probableMatches[currentMatch].matches.entityRiskDescriptor.birthDates}}</li>
                              <li><b>Sanction List:</b> {{probableMatches[currentMatch].matches.entityRiskDescriptor.entryId | sanctionListPipe}}</li>
                              <li><b>Score:</b> <ng-container *ngIf='probableMatches[currentMatch].matches.score != 0'> {{probableMatches[currentMatch].matches.score}}</ng-container><ng-container *ngIf='probableMatches[currentMatch].matches.score == 0'> Not calculated</ng-container></li>
                              <div style='height: 10px;'></div>
                              <input style='background-color: #e6e6e6;border-radius: 0px;border: none;height: 27px;width: 95%;'
                               id='note' name='note' type='text' [(ngModel)]='note' placeholder='Enter note here...'>
                          </ul>
                            <div style='height: 10px'></div>
                      </td>
                    </tr>
                  </table>
            </div>
        </div>
        <div class='col-md-1'>
          </div>
      </div>
    </div>
    <div class='modal-footer'>
      <button class='btn btn-danger' (click)='close()'>Close</button>
    </div>
  