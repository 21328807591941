import { OnDestroy } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { ApiService } from '../api.service';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
import * as i2 from "../api.service";
var SettingService = /** @class */ (function () {
    function SettingService(http, apiService) {
        this.http = http;
        this.apiService = apiService;
        this._data = new BehaviorSubject(null);
        this.brandingSetup = this._data.asObservable();
        this.subs = [];
    }
    SettingService.prototype.getConfig = function () {
        var _this = this;
        var sub = this.apiService.get('settings').subscribe(function (res) {
            _this._data.next(res.data);
            _this.processBranding(res.data);
        });
        this.subs.push(sub);
    };
    SettingService.prototype.setBranding = function (brandingSetup) {
        return this.apiService.put('branding', brandingSetup);
    };
    SettingService.prototype.processBranding = function (b) {
        document.documentElement.style
            .setProperty('--mainColor', b.mainColor);
    };
    SettingService.prototype.ngOnDestroy = function () {
        for (var _i = 0, _a = this.subs; _i < _a.length; _i++) {
            var s = _a[_i];
            s.unsubscribe();
        }
    };
    SettingService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function SettingService_Factory() { return new SettingService(i0.ɵɵinject(i1.HttpClient), i0.ɵɵinject(i2.ApiService)); }, token: SettingService, providedIn: "root" });
    return SettingService;
}());
export { SettingService };
