<div class="col-md-12" xmlns:height="http://www.w3.org/1999/xhtml">
    <div style="max-width: 500px; margin: 0 auto; padding: 3em; border: 1px solid black;">
        <div style="height: 20px"></div>
        <h3 class="text-center">Tag Management</h3><br>
        Use the following field to add/remove tags from the system:
        <div style="height: 20px;"></div>
        <ng-select 
            class="custom"
            [items]="tags"
            [addTag]="addTagFn"
            (add)="addTagEvent($event)"
            (remove)="removeTagEvent($event)"
            [hideSelected]="true"
            multiple="true"
            bindLabel="name"
            [clearable]="false"
            [(ngModel)]="selectedTags">
        </ng-select>
    </div>
</div>

