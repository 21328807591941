import { Component, OnInit, HostListener, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router, NavigationStart } from '@angular/router';
import { AlertService, CallsService, DataService, KYBService } from '../../_services';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { config } from '../../../assets/configuration';
import { Chart } from 'angular-highcharts';
import { EntityUpdate } from '../../../app/_models/kyb';
import { AddModalComponent } from '../../../app/user_management/enterprise/enterprise.component';
import { Subscription } from 'rxjs';
import { ConfigService } from '../../_services/config.service';
import { MatSliderChange } from '@angular/material/slider';
import { TranslateService } from '@ngx-translate/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ConfirmRefreshComponent } from '../../modals/confirm-refresh/confirmRefresh.component';

/**
 * Implements the user detail page
*/

let doc: any;
let docStatus: any;
@Component({
  selector: 'app-modal-content-comment-is-null',
  template: `
    <div class='modal-header'>
      <h4 class='modal-title pull-left'>Comments History</h4>
      <button type='button' class='close pull-right' aria-label='Close' (click)='bsModalRef.hide()'>
        <span aria-hidden='true'>&times;</span>
      </button>
    </div>
    <div class='modal-body'>
      <div class='commentIsNull_description' style="text-align: center; padding-top: 20px;">
        <div><b>Would you like to add a comment?</b></div>
      </div>
      <div class="commentIsNull" style="display: flex; flex-direction: column; padding-top: 20px">
        <span class="commentIsNull_heading" style="padding-right: 10px">Comment:</span><input type='text' [(ngModel)]='comment' style='width: 100%' />
      </div>
      <div *ngIf="commentIsNullError" class="commentIsNull_input" style="margin-top: 1em;">
        <span class="badge badge-warning text-uppercase" style="padding: 1em !important; text-align: start; width: 100%; background-color: #ffc108 !important; text-wrap: wrap">The Comment Field is empty. Please add a comment before pressing yes!</span>
      </div>
      <div class='modal-footer d-flex justify-content-end pr-0' style="text-align: left; display: flex; justify-content: end; padding-top: 30px;">
        <button class='btn btn-light' style="width: 20%; padding: 2px; margin-top: -3px;" (click)='bsModalRef.hide()' >No</button>
        <button class='btn btn-success' style="width: 20%; padding: 2px; margin-top: -3px;" (click)='saveComment()' >Yes</button>
      </div>
    </div>
  `
})

export class CommentIsNullComponent implements OnInit {
  subscriptions: Subscription[] = [];
  comment = '';
  commentIsNullError = false;
  currentDocument: any = doc;
  currentDocumentStatus: any = docStatus;

  constructor(
    public bsModalRef: BsModalRef,
    public kybService: KYBService,
  ) {}

  ngOnInit() {}
  saveComment() {
    if (this.comment && this.currentDocument && this.currentDocumentStatus) {
      this.kybService.updateDocumentMetadata
      (this.currentDocument.documentId, this.comment, this.currentDocumentStatus, null, null, null).subscribe(
        data => {
          this.bsModalRef.hide();
        }
      );
    } else {
      this.commentIsNullError = true;
    }
  }
}
@Component({
  // tslint:disable-next-line:component-selector
  selector: 'modal-content',
  template: `
  <style>
    .activeStatusButton{
      outline: 1px solid black;
      outline-offset: 2px;
      position: relative;
    }
  </style>
  <div class='modal-header modal-evaluate-document'>
  <h4 class='modal-title pull-left'>Evaluate Document</h4>
  <button type='button' class='close pull-right' aria-label='Close' (click)='bsModalRef.hide()'>
  <span aria-hidden='true'>&times;</span>
  </button>
</div>
<div class='modal-body'>
<button class='btn btn-success btn-save-information' [ngClass]="{'activeStatusButton': currentStatusBeforeUpdatingMetaData ? currentStatusBeforeUpdatingMetaData === 'ACCEPTED' : currentStatus === 'ACCEPTED'}" (click)='accept("ACCEPTED")'>Accept</button>&nbsp;&nbsp;
  <button class='btn btn-warning btn-yellow' [ngClass]="{'activeStatusButton': currentStatusBeforeUpdatingMetaData ? currentStatusBeforeUpdatingMetaData === 'ESCALATED' : currentStatus === 'ESCALATED'}" (click)='escalate("ESCALATED")'>Escalate</button>&nbsp;&nbsp;
  <button class='btn btn-danger btn-delete' [ngClass]="{'activeStatusButton': currentStatusBeforeUpdatingMetaData ? currentStatusBeforeUpdatingMetaData === 'REJECTED' : currentStatus === 'REJECTED'}" (click)='reject("REJECTED")'>Reject</button>&nbsp;&nbsp;
  <button class='btn btn-primary' *ngIf='displayPrevious' (click)='goToPrevious()'>Previous</button>&nbsp;&nbsp;
  <button class='btn btn-primary' *ngIf='displayNext' (click)='goToNext()'>Next</button>
  <div style='height: 15px;'></div>
  Document type: <b>{{documents[currentDocument]?.name}}</b>
  <div style='height: 15px;'></div>
  Current document status is:
  <ng-container [ngSwitch] = 'currentStatus'>
     <span *ngSwitchCase='"UNEVALUATED"' style='color: gray;'><b>UNEVALUATED</b></span>
     <span *ngSwitchCase='"ACCEPTED"' style='color: #5cb85c;'><b>ACCEPTED</b></span>
     <span *ngSwitchCase='"ESCALATED"' style='color: #f0ad4e;'><b>ESCALATED</b></span>
     <span *ngSwitchCase='"REJECTED"' style='color: #d9534f;'><b>REJECTED</b></span>
  </ng-container>
  <br>
  <div style='height: 15px;'></div>
  <div *ngIf='showContextualData && documents[currentDocument].contextualData'>
    Word list:&nbsp;&nbsp;<b>{{documents[currentDocument]?.contextualData}}</b>
    <div style='height: 15px;'></div>
  </div>
  Comment:&nbsp;<input type='text' [(ngModel)]='comment' style='width: 100%' />
  <div style='height: 15px;'></div>
  <div *ngIf="highestId > 0" style='margin-bottom: 15px; padding: 1em;' [style.border]="currentStatus === 'UNEVALUATED' ? '2px solid gray' : currentStatus === 'ACCEPTED' ? '2px solid #5cb85c' : currentStatus === 'ESCALATED' ? '2px solid #f0ad4e' : currentStatus === 'REJECTED' ? '2px solid #d9534f': '2px solid gray'">
    <h5 class="">Last Comment:</h5>
    {{lastComment?.createdByEmail}} commented <b> "{{lastComment?.comment}}" </b> at {{lastComment?.createdAt | date:'dd/MM/yyyy, h:mm:ss a, z'}}
  </div>
  <img *ngIf='showImage && !multiplePictures' id='safePicture' [src]='safePicture' style='width: 75%; height: auto'/>
  <div *ngIf='multiplePictures && showImage'>
    <div *ngFor="let picture of safePictures">
      <img [src]="picture" style='width: 75%; height: auto'>
      <div style='height: 15px;'></div>
    </div>
  </div>
  <video style='display:none;' id='selfie-video' width='100%' controls></video>
  <button *ngIf='showPdf' class='btn btn-primary' (click)='viewFullPdf()'>Open Full PDF</button>
  <div style='height: 5px;'></div>
  <pdf-viewer *ngIf='showPdf' [src]='pdfSrc'
  [render-text]='true'
  [show-all]='false'
  [original-size]='false'
  [autoresize]='true'
  style='display: block;'></pdf-viewer>
  <span><i>{{message}}</i></span>
  <div style='height: 15px;'></div>
  <ng-container *ngIf="currentText.length === 0">
    <table class="table table-responsive table-hover" *ngIf='showJSON' cellpadding='5' style='border: 1px solid #cacaca;background-color: #f2f2f2;width: 100%'>
      <tr *ngFor='let item of textContent | keyvalue' style='padding-left: 10px;'>
        <td style='padding: 5px;border: 1px solid #cacaca'><b>{{item.key}}</b></td>
        <td style='padding: 5px;border: 1px solid #cacaca'>{{item.value}}</td>
      </tr>
    </table>
  </ng-container>
  <ng-container *ngIf="currentText.length > 0">
    <ng-container *ngFor="let current of currentText; let i = index">
      <table class="table table-responsive table-hover" *ngIf='showJSON' cellpadding='5' style='border: 1px solid #cacaca;background-color: #f2f2f2;width: 100%'>
        <tr *ngFor='let item of current | keyvalue' style='padding-left: 10px;'>
          <td style='padding: 5px;border: 1px solid #cacaca'><b>{{item.key}}</b></td>
          <td style='padding: 5px;border: 1px solid #cacaca'>{{item.value}}</td>
        </tr>
      </table>
      <div *ngIf="i !== currentText.length - 1" style="height: 25px;"></div>
    </ng-container>
  </ng-container>
  <table class="table table-responsive table-hover" *ngIf='showPhoneJSON' cellpadding='5' style='border: 1px solid #cacaca;background-color: #f2f2f2;width: 100%'>
     <tr style='padding-left: 10px;'>
        <td style='padding: 5px;border: 1px solid #cacaca'><b>assessmentType</b></td>
        <td style='padding: 5px;border: 1px solid #cacaca'>{{textContent.assessmentType}}</td>
     </tr>
     <tr *ngIf='riskAssessmentType != "CONNECTION_ASSESSMENT"' style='padding-left: 10px;'>
        <td style='padding: 5px;border: 1px solid #cacaca'><b>description</b></td>
        <td style='padding: 5px;border: 1px solid #cacaca'>{{textContent.description}}</td>
      </tr>
      <tr *ngIf='textContent.assessment' style='padding-left: 10px;'>
        <td style='padding: 5px;border: 1px solid #cacaca'><b>assessment</b></td>
        <td style='padding: 5px;border: 1px solid #cacaca' [innerHTML]='textContent.assessment | telesignAssessment'></td>
      </tr>
      <tr *ngIf='riskAssessmentType == "PHONE_ASSESSMENT"' style='padding-left: 10px;'>
        <td style='padding: 5px;border: 1px solid #cacaca'><b>riskLevel</b></td>
        <td style='padding: 5px;border: 1px solid #cacaca'>{{textContent.riskLevel}}</td>
      </tr>
      <tr *ngIf='textContent.riskStatus' style='padding-left: 10px;'>
        <td style='padding: 5px;border: 1px solid #cacaca'><b>riskStatus</b></td>
        <td style='padding: 5px;border: 1px solid #cacaca'>{{textContent.riskStatus}}</td>
      </tr>
      <tr *ngIf='textContent.riskType' style='padding-left: 10px;'>
        <td style='padding: 5px;border: 1px solid #cacaca'><b>riskType</b></td>
        <td style='padding: 5px;border: 1px solid #cacaca'>{{textContent.riskType}}</td>
      </tr>
  </table>
</div>
<div class='modal-footer'>
  <button type='button' class='btn btn-default btn-cancel' (click)='bsModalRef.hide()'>Cancel</button>
  <button type='button' class='btn btn-save-information' style="background-color: #5cb85c; color: #fff" (click)='updateMetaData()'>Save</button>
</div>
<style>
  .btn-cancel:hover, .btn-cancel:focus{
      outline: none !important;
      border-color: #ccc !important;
      background-color: #fff !important;
    }
  </style>
  `
})

export class ViewDocumentComponent implements OnInit {
  subscriptions: Subscription[] = [];
  viewModal: BsModalRef;
  viewCommentDocument: void;
  documentId: any;
  mimeType: any;
  safePicture: SafeResourceUrl;
  safePictures: SafeResourceUrl[] = [];
  safeVideo: any;
  documentResponse: any = {};
  message = '';
  comment = '';
  currentStatus = '';
  currentText: any = [];
  currentStatusBeforeUpdatingMetaData = '';
  documentMetadataResponse: any = {};
  textContent: any = {};
  showImage = false;
  showVideo = false;
  showPdf = false;
  showJSON = false;
  showPhoneJSON = false;
  showContextualData = false;
  pdfSrc = '';
  documents: any = [];
  displayNext = false;
  displayPrevious = false;
  multiplePictures = false;
  currentDocument = 0;
  riskAssessmentType = '';

  highestId: Number = 0;
  lastComment: any;

  constructor(
    public bsModalRef: BsModalRef,
    public kybService: KYBService,
    public domSanitizer: DomSanitizer,
    public translate: TranslateService,
    private modalService: BsModalService,
  ) {}

  ngOnInit() {
    if (this.documents && this.documents.length > 1) {
      this.displayNext = true;
      this.displayPrevious = true;
    }

    this.currentDocument = 0;
    this.displayDocument(this.documents[this.currentDocument]);
    doc = this.documents[this.currentDocument];
    this.textContent = {};
  }

  displayDocument(currentDocument: any) {
    this.showImage = false;
    this.showVideo = false;
    this.showPdf = false;
    this.showJSON = false;
    this.showPhoneJSON = false;
    if (currentDocument !== undefined) {
      if (currentDocument.documentId !== undefined) {
        this.kybService.getDocumentMetadata(currentDocument.documentId).subscribe(
          data => {
            this.documentMetadataResponse = data;
            if (this.documentMetadataResponse.data.comments.length > 0) {
              this.documentMetadataResponse.data.comments.map((entries) => {
                  this.highestId = this.highestId > entries.id ? this.highestId : entries.id;
              });
              this.documentMetadataResponse.data.comments.map((elements) => {
                  if (elements.id === this.highestId) {
                    this.lastComment = elements;
                  }
              });
            } else {
              this.highestId = null;
              this.lastComment = null;
            }
            this.currentStatus = this.documentMetadataResponse.data.documentStatus;
            this.comment = this.documentMetadataResponse.data.comment;
            if (this.documentMetadataResponse.data.fileIds.length > 1) {
              this.multiplePictures = true;
              this.getMultiplePictures(this.documentMetadataResponse.data.id, this.documentMetadataResponse.data.fileIds);
            } else {
              this.multiplePictures = false;
              this.getOnePicture(this.documentMetadataResponse.data.id, this.documentMetadataResponse.data.fileIds[0]);
            }
          },
          error => {}
        );
      }
    } else {
      window.alert('You went through all the available documents.');
      this.bsModalRef.hide();
    }
  }

  getOnePicture(documentId: any, fileId: any, multiple?: boolean) {
    this.kybService.getFile(documentId, fileId).subscribe(
      data => {
        this.documentResponse = data;

        if (this.documentMetadataResponse.data.mimeType === 'image/jpeg' || this.documentMetadataResponse.data.mimeType === 'image/png') {
          const pre = document.getElementsByTagName('pre') as HTMLCollectionOf<HTMLElement>;

          if (pre.length > 0) {
            pre[0].setAttribute('style', 'display: none;');
          }

          const selfieVideo = document.getElementById('selfie-video') as HTMLVideoElement;
          selfieVideo.setAttribute('style', 'display: none');

          if (multiple) {
            // tslint:disable-next-line:max-line-length
            this.safePictures.push(this.domSanitizer.bypassSecurityTrustResourceUrl('data:image/jpg;base64,' + this.documentResponse.data.content));
          } else {
            // tslint:disable-next-line:max-line-length
            this.safePicture = this.domSanitizer.bypassSecurityTrustResourceUrl('data:image/jpg;base64,' + this.documentResponse.data.content);
          }

          this.showImage = true;
          this.showVideo = false;
          this.showContextualData = false;
          this.showPdf = false;
          this.showJSON = false;
          this.showPhoneJSON = false;
        } else if (this.documentMetadataResponse.data.mimeType === 'application/pdf') {
          const pre = document.getElementsByTagName('pre') as HTMLCollectionOf<HTMLElement>;

          if (pre.length > 0) {
            pre[0].setAttribute('style', 'display: none;');
          }

          const selfieVideo = document.getElementById('selfie-video') as HTMLVideoElement;
          selfieVideo.setAttribute('style', 'display: none');
          this.showPdf = true;
          this.downloadPDF(this.documentResponse.data.content);
          this.showImage = false;
          this.showVideo = false;
          this.showContextualData = false;
          this.showJSON = false;
          this.showPhoneJSON = false;
        } else if (this.documentMetadataResponse.data.mimeType === 'text/plain' || this.documentMetadataResponse.data.mimeType === 'custom/form') {
          const pre = document.getElementsByTagName('pre') as HTMLCollectionOf<HTMLElement>;

          if (pre.length > 0) {
            pre[0].setAttribute('style', 'display: block;');
          }

          const selfieVideo = document.getElementById('selfie-video') as HTMLVideoElement;

          if (selfieVideo) {
            selfieVideo.setAttribute('style', 'display: none');
          }

          this.textContent = JSON.parse(this.decodeBase64(this.documentResponse.data.content));
          // Removing empty key-value pairs from JSON object
          for (const key in this.textContent) {
            if (this.textContent.hasOwnProperty(key)) {
              const value = this.textContent[key];

              if (value.toString() === '') {
                delete this.textContent[key];
              }

              if (this.textContent[key] && typeof this.textContent[key] === 'object') {
                if (Object.keys(this.textContent[key])[0] === 'text') {
                  this.textContent[key] = Object.values(this.textContent[key])[0];
                } else {
                  Object.assign(this.textContent, this.textContent[key]);
                  delete this.textContent[key];
                }
              }
            }
          }
          this.currentText.push(this.textContent);
          this.showImage = false;
          this.showVideo = false;
          this.showContextualData = false;
          this.showPdf = false;
          this.showJSON = true;
          this.showPhoneJSON = false;
        } else if (this.documentMetadataResponse.data.mimeType === 'application/json') {
          const pre = document.getElementsByTagName('pre') as HTMLCollectionOf<HTMLElement>;

          if (pre.length > 0) {
            pre[0].setAttribute('style', 'display: block;');
          }

          const selfieVideo = document.getElementById('selfie-video') as HTMLVideoElement;

          if (selfieVideo) {
            selfieVideo.setAttribute('style', 'display: none');
          }

          this.textContent = JSON.parse(this.decodeBase64(this.documentResponse.data.content));

          // Removing empty key-value pairs from JSON object
          for (const key in this.textContent) {
            if (this.textContent.hasOwnProperty(key)) {
              const value = this.textContent[key];

              if (value !== null) {
                if (value.toString() === '') {
                  delete this.textContent[key];
                }
              }

              if (this.textContent[key] && typeof this.textContent[key] === 'object') {
                if (Object.keys(this.textContent[key])[0] === 'text') {
                  this.textContent[key] = Object.values(this.textContent[key])[0];
                } else {
                  Object.assign(this.textContent, this.textContent[key]);
                  delete this.textContent[key];
                }
              }
            }
          }
          this.currentText.push(this.textContent);
          this.showImage = false;
          this.showVideo = false;
          this.showContextualData = false;
          this.showPdf = false;
          this.showJSON = true;
          this.showPhoneJSON = false;

          if (this.documentMetadataResponse.data.documentType.documentTypeName === 'riskAssessment') {
            this.showJSON = false;
            this.showPhoneJSON = true;
            this.riskAssessmentType = this.textContent.assessmentType;
          }
        } else if (this.documentMetadataResponse.data.mimeType === 'video/mpeg' || this.documentMetadataResponse.data.mimeType === 'video/webm') {
          const pre = document.getElementsByTagName('pre') as HTMLCollectionOf<HTMLElement>;

          if (pre.length > 0) {
            pre[0].setAttribute('style', 'display: none;');
          }

          const selfieVideo = document.getElementById('selfie-video') as HTMLVideoElement;
          this.safeVideo = 'data:video/webm;base64,' + this.documentResponse.data.content;
          selfieVideo.src = this.safeVideo;
          selfieVideo.setAttribute('style', 'display: inline');
          this.showImage = false;
          this.showVideo = true;
          this.showContextualData = true;
          this.showPdf = false;
          this.showJSON = false;
          this.showPhoneJSON = false;
        } else if (this.documentMetadataResponse.data.mimeType === 'video/mp4') {
          const pre = document.getElementsByTagName('pre') as HTMLCollectionOf<HTMLElement>;

          if (pre.length > 0) {
            pre[0].setAttribute('style', 'display: none;');
          }

          const selfieVideo = document.getElementById('selfie-video') as HTMLVideoElement;
          this.safeVideo = 'data:video/mp4;base64,' + this.documentResponse.data.content;
          selfieVideo.src = this.safeVideo;
          selfieVideo.setAttribute('style', 'display: inline');
          this.showImage = false;
          this.showVideo = true;
          this.showContextualData = true;
          this.showPdf = false;
          this.showJSON = false;
          this.showPhoneJSON = false;
        } else {
          const pre = document.getElementsByTagName('pre') as HTMLCollectionOf<HTMLElement>;
          const selfieVideo = document.getElementById('selfie-video') as HTMLVideoElement;
          selfieVideo.setAttribute('style', 'display: none');

          if (pre.length > 0) {
            pre[0].setAttribute('style', 'display: none;');
          }

          this.message = 'Unrecognized MIME type.';
          this.showImage = false;
          this.showVideo = false;
          this.showContextualData = false;
          this.showPdf = false;
          this.showJSON = false;
          this.showPhoneJSON = false;
        }
      },
      error => {}
    );
  }

  decodeBase64(base64) {
    const text = atob(base64);
    const length = text.length;
    const bytes = new Uint8Array(length);
    for (let i = 0; i < length; i++) {
        bytes[i] = text.charCodeAt(i);
    }
    const decoder = new TextDecoder(); // default is utf-8
    return decoder.decode(bytes);
  }

  viewNullCommentPopup() {
    this.viewModal = this.modalService.show(CommentIsNullComponent, { backdrop: 'static' });
    this.subscriptions.push(
      this.modalService.onHide.subscribe(message => {
        this.unsubscribe();
      })
    );
  }

  unsubscribe() {
    this.subscriptions.forEach((subscription: Subscription) => {
      subscription.unsubscribe();
    });

    this.subscriptions = [];
  }

  getMultiplePictures(documentId: any, fileIds: any[]) {
    this.textContent = {};
    fileIds.forEach(fileId => {
      this.getOnePicture(documentId, fileId, true);
    });
  }

  goToPrevious() {
    this.textContent = {};
    if (this.currentDocument !== 0) {
      this.currentDocument--;
      this.displayDocument(this.documents[this.currentDocument]);
    } else {
      this.currentDocument = this.documents.length + 1;
      this.displayDocument(this.documents[this.currentDocument]);
    }
  }

  goToNext() {
    this.textContent = {};
    if (this.currentDocument !== this.documents.length + 1) {
      this.currentDocument++;
      this.displayDocument(this.documents[this.currentDocument]);
    } else {
      this.currentDocument = 0;
      this.displayDocument(this.documents[this.currentDocument]);
    }
  }

  accept(documentStatus: any) {
    this.currentStatusBeforeUpdatingMetaData = documentStatus;
    // tslint:disable-next-line:max-line-length
    this.kybService.updateDocumentMetadata(this.documents[this.currentDocument].documentId, this.comment, this.currentStatusBeforeUpdatingMetaData ? this.currentStatusBeforeUpdatingMetaData : this.currentStatus, null, null, null).subscribe(
      data => {
        if (this.documents.length === 1) {
          this.ngOnInit();
          if (this.comment) {
            this.bsModalRef.hide();
          } else {
            this.bsModalRef.hide();
            docStatus = `${
              this.currentStatusBeforeUpdatingMetaData ? this.currentStatusBeforeUpdatingMetaData : this.currentStatus
            }`;
            this.viewNullCommentPopup();
          }
        } else {
          this.currentStatusBeforeUpdatingMetaData = '';
          this.goToNext();
        }
      },
      error => {}
    );
  }

  escalate(documentStatus: any) {
    this.currentStatusBeforeUpdatingMetaData = documentStatus;
    // tslint:disable-next-line:max-line-length
    this.kybService.updateDocumentMetadata(this.documents[this.currentDocument].documentId, this.comment, this.currentStatusBeforeUpdatingMetaData ? this.currentStatusBeforeUpdatingMetaData : this.currentStatus, null, null, null).subscribe(
      data => {
        if (this.documents.length === 1) {
          this.ngOnInit();
          if (this.comment) {
            this.bsModalRef.hide();
          } else {
            this.bsModalRef.hide();
            docStatus = `${
              this.currentStatusBeforeUpdatingMetaData ? this.currentStatusBeforeUpdatingMetaData : this.currentStatus
            }`;
            this.viewNullCommentPopup();
          }
        } else {
          this.currentStatusBeforeUpdatingMetaData = '';
          this.goToNext();
        }
      },
      error => {}
    );
  }

  reject(documentStatus: any) {
    this.currentStatusBeforeUpdatingMetaData = documentStatus;
    // tslint:disable-next-line:max-line-length
    this.kybService.updateDocumentMetadata(this.documents[this.currentDocument].documentId, this.comment, this.currentStatusBeforeUpdatingMetaData ? this.currentStatusBeforeUpdatingMetaData : this.currentStatus, null, null, null).subscribe(
      data => {
        if (this.documents.length === 1) {
          this.ngOnInit();
          if (this.comment) {
            this.bsModalRef.hide();
          } else {
            this.bsModalRef.hide();
            docStatus = `${
              this.currentStatusBeforeUpdatingMetaData ? this.currentStatusBeforeUpdatingMetaData : this.currentStatus
            }`;
            this.viewNullCommentPopup();
          }
        } else {
          this.currentStatusBeforeUpdatingMetaData = '';
          this.goToNext();
        }
      },
      error => {}
    );
  }

  updateMetaData() {
    // tslint:disable-next-line:max-line-length
    this.kybService.updateDocumentMetadata(this.documents[this.currentDocument].documentId, this.comment, this.currentStatusBeforeUpdatingMetaData ? this.currentStatusBeforeUpdatingMetaData : this.currentStatus, null, null, null).subscribe(
      data => {
        if (this.documents.length === 1) {
          this.ngOnInit();
          if (this.comment) {
            this.bsModalRef.hide();
          } else {
            this.bsModalRef.hide();
            docStatus = `${
              this.currentStatusBeforeUpdatingMetaData ? this.currentStatusBeforeUpdatingMetaData : this.currentStatus
            }`;
            this.viewNullCommentPopup();
          }
        } else {
          this.currentStatusBeforeUpdatingMetaData = '';
          this.goToNext();
        }
      },
      error => {}
    );
  }

  downloadPDF(pdfString: any) {
    const linkSource = 'data:application/pdf;base64,' + pdfString;
    this.pdfSrc = linkSource;
  }

  viewFullPdf() {
    const byteCharacters = atob(this.pdfSrc.split('data:application/pdf;base64,')[1]);
    const byteNumbers = new Array(byteCharacters.length);

    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    const file = new Blob([byteArray], { type: 'application/pdf;base64' });
    const fileURL = URL.createObjectURL(file);
    window.open(fileURL);
  }
}


@Component({
  // tslint:disable-next-line:component-selector
  selector: 'modal-content',
  template: `
    <div class='modal-header'>
      <h4 class='modal-title pull-left'>No Comment Entered</h4>
      <button type='button' class='close pull-right' aria-label='Close' (click)='bsModalRef.hide()'>
        <span aria-hidden='true'>&times;</span>
      </button>
    </div>
    <div class='modal-body'>
      <div *ngIf='comments.length > 0'>
        <div *ngFor='let comment of comments'><b>[{{comment.createdAt | date:'dd/MM/yyyy, h:mm:ss a z'}}] {{comment.createdByEmail}}:</b> '{{comment.comment}}'<br></div>
      </div>
      <div *ngIf='comments.length == 0'>
        <span *ngFor=''>Nothing to show.</span>
      </div>
    </div>
    <div class='modal-footer' style='text-align: left;'>
        <input type='text' [(ngModel)]='newComment' style='width: 70%'>&nbsp;&nbsp;
        <button class='btn btn-success' (click)='save()' style='width: 20%;padding: 2px;margin-top: -3px;'>Add comment</button>
    </div>
  `
})

export class ShowCommentsComponent implements OnInit {

  entityId: any;
  comments: any = [];
  entityResponse: any = {};
  newComment = '';
  updatedEntity: EntityUpdate;

  @HostListener('document:keyup.enter', ['$event'])
  onKeyupHandler(event: KeyboardEvent) {
    this.save();
  }

  constructor(
    public bsModalRef: BsModalRef,
    public kybService: KYBService
  ) {}

  ngOnInit() {
    this.comments = [];
    this.entityResponse = {};

    this.kybService.getComments(this.entityId).subscribe(
      data => {
        this.entityResponse = data;
        // sorted newest at top
        this.comments = this.entityResponse.data.sort((a, b) => (a.id < b.id) ? 1 : -1);
      }
    );
  }

  save() {
    this.kybService.addComment(this.entityId, this.newComment).subscribe(
      data => {
        this.newComment = '';
        this.ngOnInit();
      }
    );
  }
}

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'modal-content',
  template: `
    <div class='modal-header'>
      <h4 class='modal-title pull-left'>Rename Entity</h4>
      <button type='button' class='close pull-right' aria-label='Close' (click)='bsModalRef.hide()'>
        <span aria-hidden='true'>&times;</span>
      </button>
    </div>
    <div class='modal-body'>
      <p>Current name is: <b>{{primaryName}}</b></p>
      <p><b><i>Please note that renaming an entity will trigger a new check against our database. Any new potential hits will be displayed in the screening results and the risk level of the entity will be adjusted accordingly.</i></b></p>
      <form [formGroup]="disableRenameBtn">
       <label> New name: *
          <div style="display: flex; align-items: center;">
            <input class='form-control input-md' style='border: 1px black solid; border-radius: 0px; margin-right: 10px;' [(ngModel)]='newPrimary' formControlName="newName">
            <mat-icon *ngIf="!disableRenameBtn.valid" style="color: red;">close</mat-icon>
            <mat-icon *ngIf="disableRenameBtn.valid" style="color: green;">check</mat-icon>
          </div>
        </label><br><br>
      </form>
    </div>
    <div class='modal-footer' style='text-align: left;'>
        <button class='btn btn-success' (click)='rename()' [disabled]='!disableRenameBtn.valid' style='width: 20%;padding: 2px;margin-top: -3px;'>Rename</button>
    </div>
  `
})

export class RenameEntityComponent implements OnInit {

  primaryName: any;
  newPrimary = '';
  entityId: any;
  entityTypeId: any;
  disableRenameBtn: any;

  constructor(
    public bsModalRef: BsModalRef,
    public kybService: KYBService,
    public alertService: AlertService
  ) {}

  ngOnInit() {
    this.disableRenameBtn = new FormGroup({
      newName: new FormControl(null, Validators.required),
    });
  }

  rename() {
    if (window.confirm('Are you sure you want to rename this entity?')) {
      // tslint:disable-next-line:max-line-length
      this.kybService.renameEntity(this.entityId, this.entityTypeId, this.primaryName, this.newPrimary).subscribe(
        data => {
          this.bsModalRef.hide();
        },
        error => {
          this.alertService.showError('Entity could not be renamed. Please try again.');
        }
      );
    }
  }
}

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'modal-content',
  template: `
    <div class='modal-header'>
      <h4 class='modal-title pull-left'>Manual Total Risk Adjustment</h4>
      <button type='button' class='close pull-right' aria-label='Close' (click)='bsModalRef.hide()'>
        <span aria-hidden='true'>&times;</span>
      </button>
    </div>
    <div class='modal-body'>
      <label style='font-weight: bold;'> This entity's risk is: * </label>
        <div class='radio'>
          <label>
              <input type='radio' [(ngModel)]='riskLevel' value='LOW'>
              Low
          </label>
        </div>
        <div class='radio'>
          <label>
            <input type='radio' [(ngModel)]='riskLevel' value='MEDIUM'>
            Medium
          </label>
        </div>
        <div class='radio'>
          <label>
          <input type='radio' [(ngModel)]='riskLevel' value='HIGH'>
          High
          </label>
        </div>
        <div class='radio'>
          <label>
          <input type='radio' [(ngModel)]='riskLevel' value='REJECTED'>
          Unacceptable
          </label>
        </div>
      <label style='font-weight: bold;'> Please enter the justification for this action: * <textarea class='form-control input-md' style='border: 1px black solid; border-radius: 0px; max-width: 100%; font-weight: normal;' [(ngModel)]='justification'></textarea></label>
    </div>
    <div class='modal-footer' style='text-align: right;'>
      <button class='btn btn-danger btn-delete' (click)='cancel()' style='width: 20%;padding: 5px;margin-top: -3px;' [disabled]='loading'>Cancel</button>
      <button class='btn btn-success' (click)='adjustAndLog()' [disabled]='riskLevel == "" || justification == "" || loading' style='width: 20%;padding: 5px;margin-top: -3px; text-wrap: wrap'>Adjust and Log</button>
    </div>
  `
})

export class ManualTotalRiskAdjustmentComponent implements OnInit {

  justification = '';
  riskLevel: any;
  entityId: any;
  riskAnalysisResponse: any = {};
  mediumRiskThreshold = 0;
  highRiskThreshold = 0;
  unacceptableRiskThreshold = 0;
  primaryName: any;
  entityTypeId: any;
  loading = false;

  risk = 0;
  updatedEntity: EntityUpdate;

  constructor(
    public bsModalRef: BsModalRef,
    public kybService: KYBService,
    public alertService: AlertService
  ) {}

  ngOnInit() {
    this.kybService.getRiskAnalysisRules().subscribe(
      data => {
        this.riskAnalysisResponse = data;
        this.mediumRiskThreshold = this.riskAnalysisResponse.data.mediumRiskThreshold;
        this.highRiskThreshold = this.riskAnalysisResponse.data.highRiskThreshold;
        this.unacceptableRiskThreshold = this.riskAnalysisResponse.data.unacceptableRiskThreshold;
      }
    );
  }

  cancel() {
    this.bsModalRef.hide();
  }

  adjustAndLog() {
    this.loading = true;

    if (this.riskLevel === 'LOW') {
      this.risk = 0;
    } else if (this.riskLevel === 'MEDIUM') {
      this.risk = this.mediumRiskThreshold;
    } else if (this.riskLevel === 'HIGH') {
      this.risk = this.highRiskThreshold;
    } else {
      this.risk = this.unacceptableRiskThreshold;
    }

    this.kybService.updateAssessedRisk(this.entityId, this.risk, null).subscribe(
      data => {
        this.kybService.addComment(parseInt(this.entityId, 10), 'User ' + localStorage.getItem('username') + ' updated custom assessed risk of entity to ' + this.riskLevel + '. Justification: ' + this.justification + '.').subscribe(
          entityData => {
            this.loading = false;
            this.alertService.showSuccess('Risk successfully adjusted.');
            this.bsModalRef.hide();
          },
          error => {
            this.loading = false;
          }
        );
      },
      error => {
        this.loading = false;
        this.alertService.showError(error.error.errors[0].message);
      }
    );
  }
}

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'modal-content',
  template: `
    <div class='modal-header'>
      <h4 class='modal-title pull-left'>Manual PEP Risk Adjustment</h4>
      <button type='button' class='close pull-right' aria-label='Close' (click)='bsModalRef.hide()'>
        <span aria-hidden='true'>&times;</span>
      </button>
    </div>
    <div class='modal-body'>
      <label style='font-weight: bold;'> This entity's risk is: * </label>
        <div class='radio'>
          <label>
              <input type='radio' [(ngModel)]='riskLevel' value='LOW'>
              Not a PEP
          </label>
        </div>
        <div class='radio'>
          <label>
            <input type='radio' [(ngModel)]='riskLevel' value='MEDIUM'>
            Medium Risk PEP
          </label>
        </div>
        <div class='radio'>
          <label>
          <input type='radio' [(ngModel)]='riskLevel' value='HIGH'>
          High Risk PEP
          </label>
        </div>
        <div class='radio'>
          <label>
          <input type='radio' [(ngModel)]='riskLevel' value='REJECTED'>
          Unacceptable PEP
          </label>
        </div>
      <label style='font-weight: bold;'> Please enter the justification for this action: * <textarea class='form-control input-md' style='border: 1px black solid; border-radius: 0px; max-width: 100%; font-weight: normal;' [(ngModel)]='justification'></textarea></label>
    </div>
    <div class='modal-footer' style='text-align: right;'>
      <button class='btn btn-danger btn-delete' (click)='cancel()' style='width: 20%;padding: 5px;margin-top: -3px;' [disabled]='loading'>Cancel</button>
      <button class='btn btn-success' (click)='adjustAndLog()' [disabled]='riskLevel == "" || justification == "" || loading' style='width: 20%;padding: 5px;margin-top: -3px; text-wrap: wrap'>Adjust and Log</button>
    </div>
  `
})

export class ManualPEPRiskAdjustmentComponent implements OnInit {

  justification = '';
  riskLevel = '';
  entityId: any;
  riskAnalysisResponse: any = {};
  mediumRiskThreshold = 0;
  highRiskThreshold = 0;
  unacceptableRiskThreshold = 0;
  primaryName: any;
  entityTypeId: any;
  loading = false;

  risk: any;
  updatedEntity: EntityUpdate;

  constructor(
    public bsModalRef: BsModalRef,
    public kybService: KYBService,
    public alertService: AlertService
  ) {}

  ngOnInit() {
    this.kybService.getRiskAnalysisRules().subscribe(
      data => {
        this.riskAnalysisResponse = data;
        this.mediumRiskThreshold = this.riskAnalysisResponse.data.mediumRiskThreshold;
        this.highRiskThreshold = this.riskAnalysisResponse.data.highRiskThreshold;
        this.unacceptableRiskThreshold = this.riskAnalysisResponse.data.unacceptableRiskThreshold;
      }
    );
  }

  cancel() {
    this.bsModalRef.hide();
  }

  adjustAndLog() {
    this.loading = true;

    if (this.riskLevel === 'LOW') {
      this.risk = 0;
    } else if (this.riskLevel === 'MEDIUM') {
      this.risk = this.mediumRiskThreshold;
    } else if (this.riskLevel === 'HIGH') {
      this.risk = this.highRiskThreshold;
    } else {
      this.risk = this.unacceptableRiskThreshold;
    }

    this.kybService.updateAssessedRisk(this.entityId, null, this.risk).subscribe(
      data => {
        this.kybService.addComment(parseInt(this.entityId, 10), 'User ' + localStorage.getItem('username') + ' updated custom PEP risk of entity to ' + this.riskLevel + '. Justification: ' + this.justification + '.').subscribe(
          entityData => {
            this.loading = false;
            this.alertService.showSuccess('Risk successfully adjusted.');
            this.bsModalRef.hide();
          },
          error => {
            this.loading = false;
          }
        );
      },
      error => {
        this.loading = false;
        this.alertService.showError(error.error.errors[0].message);
      }
    );
  }
}

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'modal-content',
  template: `
    <div class='modal-header'>
      <h4 class='modal-title pull-left'>Manual {{type}} Risk Adjustment</h4>
      <button type='button' class='close pull-right' aria-label='Close' (click)='bsModalRef.hide()'>
        <span aria-hidden='true'>&times;</span>
      </button>
    </div>
    <div class='modal-body'>
      <p style='font-size: 14px;font-weight: bold;'> Do you want to clear the manual risk assesment? <br>This will reset the '{{type}}' risk.</p>
      <label>Please enter the justification for this action: *</label><textarea class='form-control input-md' style='border: 1px black solid; border-radius: 0px;font-weight: normal;' [(ngModel)]='justification'></textarea>
    </div>
    <div class='modal-footer' style='text-align: right;'>
      <button class='btn btn-danger btn-delete' (click)='cancel()' style='width: 20%;padding: 5px;margin-top: -3px;' [disabled]='loading'>Cancel</button>
      <button class='btn btn-success' (click)='adjustAndLog()' [disabled]='justification == "" || loading' style='width: 20%;padding: 5px;margin-top: -3px; text-wrap: wrap'>Adjust and Log</button>
    </div>
  `
})

export class RemoveRiskAdjustmentComponent implements OnInit {

  type: any;
  justification = '';
  entityId: any;
  assessedRisk = false;
  pepRisk = false;
  primaryName: any;
  entityTypeId: any;
  loading = false;

  updatedEntity: EntityUpdate;

  constructor(
    public bsModalRef: BsModalRef,
    public kybService: KYBService,
    public alertService: AlertService
  ) {}

  ngOnInit() {}

  cancel() {
    this.bsModalRef.hide();
  }

  adjustAndLog() {
    this.loading = true;

    if (this.type === 'PEP') {
      this.assessedRisk = false;
      this.pepRisk = true;
    } else {
      this.assessedRisk = true;
      this.pepRisk = false;
    }

    this.kybService.deleteAssessedRisk(this.entityId, this.assessedRisk, this.pepRisk).subscribe(
      data => {
        // tslint:disable-next-line:max-line-length
        this.kybService.addComment(parseInt(this.entityId, 10), 'User ' + localStorage.getItem('username') + ' deleted custom ' + this.type + ' risk of entity. Justification: ' + this.justification + '.').subscribe(
          entityData => {
            this.loading = false;
            this.alertService.showSuccess('Risk successfully adjusted.');
            this.bsModalRef.hide();
          },
          error => {
            this.loading = false;
            this.alertService.showError(error.error.errors[0].message);
          }
        );
      },
      error => {
        this.loading = false;
      }
    );
  }
}

/**
 * Implements the user detail page
*/
@Component({
  moduleId: module.id,
  templateUrl: 'detail.component.html',
  styleUrls: ['./detail.component.css']
})

export class DetailComponent implements OnInit, OnDestroy {
  subscriptions: Subscription[] = [];
  exclamationImage = '../../../assets/images/exclamation.png';
  chart: Chart;
  chart2: Chart;
  controlChart: Chart;
  uboChart: Chart;
  model: any = {};
  loading = false;
  controlloLoading = false;
  loadingImg = 'data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==';
  progress = 22;
  username = '';
  response: any = {};
  safeSelfie: SafeResourceUrl;
  safePassportFront: SafeResourceUrl;
  safePassportBack: SafeResourceUrl;
  safeProofOfResidence: SafeResourceUrl;
  safeSelfieVideo: any;
  slideIndex = 1;
  loadingCheck = false;
  largePassportFront: BsModalRef;
  largePassportBack: BsModalRef;
  largeSelfie: BsModalRef;
  largeProofOfResidence: BsModalRef;
  htmlInput = '';
  exportStatus = '';
  exportPEPScore = '';
  exportSanctionScore = '';
  exportMRZ = '';
  exportDob = '';
  exportDoe = '';
  primaryName = '';
  entityTypeId = '';
  mainColor: any = config.mainColor;
  words: any = [];
  returnWords: any = [];
  wordsObject: any = {};
  viewModal: BsModalRef;
  corporateRiskAverage = 0;
  documentIds: any = [];
  customOverallRisk = 0;
  showRiskEdit = false;
  entityComment = '';
  showCommentsModal: BsModalRef;
  renameEntityModal: BsModalRef;
  confirmRefreshModal: BsModalRef;
  manualTotalRiskAdjustmentModal: BsModalRef;
  manualPEPRiskAdjustmentModal: BsModalRef;
  removeRiskAdjustmentModal: BsModalRef;
  emptyUBO = false;
  emptyControl = false;
  routerSub: Subscription;
  documentFound = false;
  initDocumentFound = false;
  displayEmailOnboardingTrigger = true;
  displayPhoneNumberOnboardingTrigger = true;
  displayAddRole = true;
  displayFullEDDReport = true;
  displayAddGeneralDocument = true;
  displayRolesAndDocumentation = true;
  displayValidUntil = true;
  displayPersonalContactFormAndData = true;
  displayMRZ = true;
  displayDeleteDocument = true;
  displayEditDocument = true;
  displayReplaceDocument = true;
  displayEvaluateAllDocuments = true;
  displayControlPanel = false;

  roleResponse: any;
  basicInformation: any = {};
  relationResponse: any = {};
  entityResponse: any = {};
  rolesResponse: any = {};
  connectedRoles: any = [];
  getRoleResponse: any = {};
  documentContentResponse: any = {};
  entityName = '';
  entityType = '';
  entityId = '';
  rolesDisplay: any = [];
  riskAnalysisResponse: any = {};
  riskAnalysis: any = {};
  corporateRisk = 0;
  eventResponse: any = {};
  eventLog: any = [];
  documents: any = [];
  allDocuments: any = [];
  toDoListResponse: any = {};
  toDo: any = [];
  timestampFrom: any = null;
  timestampTo: any = null;

  UBOResponse: any = {};
  UBOdata: any = [];
  controlResponse: any = {};
  pageSize = 30;
  currentPage = 0;
  sortingOption = 'timestamp,desc';
  createdBy: any = null;
  selectedTags: any = [];
  tags: any = [];
  tagsResponse: any = {};
  createTagResponse: any = {};
  isOwner = false;
  jurisdictionCounter = 0;
  analysisRulesResponse: any = {};
  mediumRiskThreshold = 0;
  highRiskThreshold = 0;
  unacceptableRiskThreshold = 0;
  numberOfModals = 0;

  showEditInformation = false;

  documentResponse: any = {};
  safeDocuments: any = [];
  safeDocument: SafeResourceUrl;

  updatedEntity: EntityUpdate;

  addModal: BsModalRef;

  totalRisk = 0;
  totalRiskLevel = '';

  longestSubstring = '';
  helperWords: any = [];
  usersToReview: any = [];

  relationshipGraphData: any = [];

  controls: any = [];
  controlData: any = [];
  controlNodes: any = [];

  owners: any = [];
  uboData: any = [];
  uboNodes: any = [];
  entityLevel = 0;
  titles: any = [];

  uboLevels: any = [];
  index: any;

  nodes: any = [];
  entityTagResponse: any;

  newChilds = [];

  counter: any = 0;

  onboardingLoading = false;

  userPermissions: any = [];

  deleteEntityResponse: any = {};

  commentsResponse: any = {};

  comments: any = [];

  point: any;

  constructor(
    private callsService: CallsService,
    private alertService: AlertService,
    private route: ActivatedRoute,
    private domSanitizer: DomSanitizer,
    private modalService: BsModalService,
    public translate: TranslateService,
    private dataService: DataService,
    private kybService: KYBService,
    private router: Router,
    private configService: ConfigService
  ) {
    if (configService.get('onboardingLanguage') !== undefined) {
      this.translate.setDefaultLang(configService.get('onboardingLanguage'));
    } else {
      this.translate.setDefaultLang('en');
    }
    this.route.params.subscribe(
      params => {
        if (params.username) {
          this.username = params.username;
        }
      }
    );

    if (configService.get('displayEmailOnboardingTrigger') !== undefined) {
      this.displayEmailOnboardingTrigger = configService.get('displayEmailOnboardingTrigger');
    }

    if (configService.get('displayPhoneNumberOnboardingTrigger') !== undefined) {
      this.displayPhoneNumberOnboardingTrigger = configService.get('displayPhoneNumberOnboardingTrigger');
    }

    if (configService.get('displayAddRole') !== undefined) {
      this.displayAddRole = configService.get('displayAddRole');
    }

    if (configService.get('displayFullEDDReport') !== undefined) {
      this.displayFullEDDReport = configService.get('displayFullEDDReport');
    }

    if (configService.get('displayAddGeneralDocument') !== undefined) {
      this.displayAddGeneralDocument = configService.get('displayAddGeneralDocument');
    }

    if (configService.get('displayRolesAndDocumentation') !== undefined) {
      this.displayRolesAndDocumentation = configService.get('displayRolesAndDocumentation');
    }

    if (configService.get('displayValidUntil') !== undefined) {
      this.displayValidUntil = configService.get('displayValidUntil');
    }

    if (configService.get('displayPersonalContactFormAndData') !== undefined) {
      this.displayPersonalContactFormAndData = configService.get('displayPersonalContactFormAndData');
    }

    if (configService.get('displayMRZ') !== undefined) {
      this.displayMRZ = configService.get('displayMRZ');
    }

    if (configService.get('displayDeleteDocument') !== undefined) {
      this.displayDeleteDocument = configService.get('displayDeleteDocument');
    }

    if (configService.get('displayEditDocument') !== undefined) {
      this.displayEditDocument = configService.get('displayEditDocument');
    }

    if (configService.get('displayReplaceDocument') !== undefined) {
      this.displayReplaceDocument = configService.get('displayReplaceDocument');
    }

    if (configService.get('displayEvaluateAllDocuments') !== undefined) {
      this.displayEvaluateAllDocuments = configService.get('displayEvaluateAllDocuments');
    }

    if (configService.get('displayControlPanel') !== undefined) {
      this.displayControlPanel = configService.get('displayControlPanel');
    }

    this.routerSub = this.router.events.subscribe(
      (event: NavigationStart) => {
        // detect Back button click and refresh values
        if (event.navigationTrigger === 'popstate') {
          this.totalRisk = 0;
          this.totalRiskLevel = '';
          this.riskAnalysis = {};
          this.riskAnalysisResponse = {};

          if (event.url.indexOf('detail') !== -1) {
            this.username = event.url.split('/')[2];
            this.ngOnInit();
          }
        }
    });
  }

  ngOnDestroy() {
    this.routerSub.unsubscribe();
  }

  ngOnInit() {
    this.totalRisk = 0;
    this.totalRiskLevel = '';

    this.kybService.getRiskAnalysisRules().subscribe(
      data => {
        this.analysisRulesResponse = data;
        this.mediumRiskThreshold = this.analysisRulesResponse.data.mediumRiskThreshold;
        this.highRiskThreshold = this.analysisRulesResponse.data.highRiskThreshold;
        this.unacceptableRiskThreshold = this.analysisRulesResponse.data.unacceptableRiskThreshold;
      }
    );

    this.kybService.getUserPermissions().subscribe(
      value => this.userPermissions = value.data.permissions
    );

    this.timestampTo = new Date();
    this.timestampFrom = new Date();
    this.timestampFrom.setDate(this.timestampFrom.getDate() - 20000);
    this.timestampFrom = this.timestampFrom.toISOString();
    this.timestampTo = this.timestampTo.toISOString();
    this.uboData = [];
    this.UBOdata = [];
    this.uboLevels = [];
    this.uboNodes = [];
    this.controlData = [];
    this.controlNodes = [];
    this.allDocuments = [];
    this.corporateRiskAverage = 0;
    this.counter = 0;
    this.corporateRisk = 0;
    this.connectedRoles = [];
    this.rolesDisplay = [];

    this.kybService.getAllTags().subscribe(
      data => {
        this.tagsResponse = data;
        this.tags = this.tagsResponse.data;
      }
    );

    this.kybService.getEntity(this.username).subscribe(
      (data: any) => {
        if (data && data.errors && data.errors.length) {
          window.location.pathname = '/404';
        }

        this.entityResponse = data;
        this.entityName = this.entityResponse.data.primaryName;
        this.primaryName = this.entityResponse.data.primaryName;
        this.entityType = this.entityResponse.data.entityType.entityType;
        this.entityTypeId = this.entityResponse.data.entityType.id;
        this.entityId = this.entityResponse.data.id;

        this.kybService.getEntityTags(this.entityId).subscribe(
          (tags: any) => {
            this.entityTagResponse = tags;
            if (this.entityTagResponse.data.length > 0) {
              this.selectedTags = [];
              this.entityTagResponse.data.forEach(obj => {
                this.selectedTags.push(obj);
              });
            }
          }
        );

        if (data && data.data) {
          this.isOwner = data.data.owner.identifier === localStorage.identifier;
        }

        if (this.entityResponse.data.entityStatus2E == null) {
          this.entityResponse.data.entityStatus2E = 'IN_REVIEW';
        }

        this.dataService.setCurrentEntityId(this.entityId);
        this.relationResponse = {};

        this.kybService.getRelationships(this.entityResponse.data.id).subscribe(
          relData => {
            this.relationResponse = relData;
            this.drawRelationshipGraph(this.relationResponse.data.roles);
            this.prepareOrganizationalChart(this.relationResponse.data.roles, []);
          },
          error => {}
        );

        this.kybService.getUBOGraph(this.username, 'control').subscribe(
          controlUBOdata => {
            this.controlResponse = controlUBOdata;
            this.drawControlChart(this.controlResponse.data);
            this.drawNewControlChart(this.controlResponse.data);
          },
          error => {}
        );

        this.kybService.getUBOGraph(this.username, 'owner').subscribe(
          ownerUBOdata => {
            this.UBOResponse = ownerUBOdata;
            this.drawUBOChart(this.UBOResponse.data);
            this.drawNewUBOChart(this.UBOResponse.data);
          },
          error => {}
        );

        this.kybService.getComments(parseInt(this.entityId, 10)).subscribe(
          commentData => {
            this.commentsResponse = commentData;
            this.comments = this.commentsResponse.data.sort((a, b) => (a.id < b.id) ? 1 : -1);
          }
        );
      },
      error => {
        window.location.pathname = '/404';
      }
    );

    this.refreshRiskIndicators();

    this.getRolesForEntity(this.username);

    // tslint:disable-next-line:max-line-length
    this.kybService.getEventsPaginated(this.pageSize, this.currentPage, this.sortingOption, this.timestampFrom, this.timestampTo, this.createdBy, this.username).subscribe(
      (res: any) => {
        // tslint:disable-next-line:max-line-length
        this.eventResponse = { data: { ...res.data.page, totalElements: res.data.totalElements, numberOfElements: res.data.totalElements } };
        this.eventLog = res.data.page;
      },
      error => {}
    );

    this.route.fragment.subscribe(f => {
      const element = document.querySelector('#' + f);

      if (element) {
        element.scrollIntoView();
      }
    });

    this.kybService.getToDoList(this.username).subscribe(
      data => {
        this.toDoListResponse = data;
        this.toDo = this.toDoListResponse.data.todos;
      },
      error => {
      }
    );
  }

  isAddDocumentEntityId(prevToDo: any, currentToDo: any, nextToDo: any) {
    return (currentToDo && nextToDo && currentToDo.documentGroup === nextToDo.documentGroup) ||
      (!nextToDo && prevToDo && currentToDo && prevToDo.documentGroup === currentToDo.documentGroup) ||
      (!nextToDo && !prevToDo);
  }

  isAddThisDocumentEntityId(prevToDo: any, currentToDo: any, nextToDo: any) {
    return (currentToDo && nextToDo && currentToDo.documentGroup !== nextToDo.documentGroup) ||
      (!nextToDo && prevToDo && currentToDo && prevToDo.documentGroup !== currentToDo.documentGroup);
  }

  isAddDocumentRoleId(prevToDo: any, currentToDo: any, nextToDo: any) {
    return (currentToDo && nextToDo && currentToDo.documentGroup === nextToDo.documentGroup) ||
    (!nextToDo && prevToDo && currentToDo && prevToDo.documentGroup === currentToDo.documentGroup);
  }

  isAddThisDocumentRoleId(prevToDo: any, currentToDo: any, nextToDo: any) {
    return (currentToDo && nextToDo && currentToDo.documentGroup !== nextToDo.documentGroup) ||
    (!nextToDo && prevToDo && currentToDo && prevToDo.documentGroup !== currentToDo.documentGroup) ||
    (!nextToDo && !prevToDo);
  }

  getRolesForEntity(username: any) {
    this.kybService.getRolesForEntity(username).subscribe(
      data => {
        this.rolesResponse = data;
        this.connectedRoles = this.rolesResponse.data;
        this.rolesDisplay = [];

        for (let i = 0; i < this.connectedRoles.length; i++) {
          this.documents = [];

          for (let k = 0; k < this.connectedRoles[i].requiredDocuments.length; k++) {
            const expired = !(this.connectedRoles[i].requiredDocuments[k].notExpired);

            // tslint:disable-next-line:max-line-length
            if (this.connectedRoles[i].requiredDocuments[k].documentType.documentTypeName === 'personalContact' || this.connectedRoles[i].requiredDocuments[k].documentType.documentTypeName === 'companyContactForm') {
              // tslint:disable-next-line:max-line-length
              if (this.connectedRoles[i].requiredDocuments[k].documentStatus !== 'REPLACED' && this.connectedRoles[i].requiredDocuments[k].documentStatus !== 'DELETED' && this.connectedRoles[i].requiredDocuments[k].documentStatus !== 'EMPTY') {
                this.showEditInformation = true;
              }
            }

            if (this.connectedRoles[i].requiredDocuments[k].documentStatus !== 'EMPTY') {
              // tslint:disable-next-line:max-line-length
              this.documents.push({ 'name': this.connectedRoles[i].requiredDocuments[k].documentType.description, 'description': this.connectedRoles[i].requiredDocuments[k].description, 'fileId': this.connectedRoles[i].requiredDocuments[k].fileId, 'mimeType': this.connectedRoles[i].requiredDocuments[k].mimeType, 'expires': this.connectedRoles[i].requiredDocuments[k].validTo, 'documentId': this.connectedRoles[i].requiredDocuments[k].id, 'parentDocumentId': this.connectedRoles[i].requiredDocuments[k].parentDocumentId, 'status': this.connectedRoles[i].requiredDocuments[k].documentStatus, 'riskLevel': this.connectedRoles[i].requiredDocuments[k].riskStatus, 'documentTypeId': this.connectedRoles[i].requiredDocuments[k].documentType.id, 'expired': expired, 'contextualData': this.connectedRoles[i].requiredDocuments[k].contextualData});
              // tslint:disable-next-line:max-line-length
              this.allDocuments.push({ 'name': this.connectedRoles[i].requiredDocuments[k].documentType.description, 'description': this.connectedRoles[i].requiredDocuments[k].description, 'fileId': this.connectedRoles[i].requiredDocuments[k].fileId, 'mimeType': this.connectedRoles[i].requiredDocuments[k].mimeType, 'expires': this.connectedRoles[i].requiredDocuments[k].validTo, 'documentId': this.connectedRoles[i].requiredDocuments[k].id, 'status': this.connectedRoles[i].requiredDocuments[k].documentStatus, 'riskLevel': this.connectedRoles[i].requiredDocuments[k].riskStatus, 'documentTypeId': this.connectedRoles[i].requiredDocuments[k].documentType.id, 'expired': expired, 'contextualData': this.connectedRoles[i].requiredDocuments[k].contextualData });
            }
          }

          for (let j = 0; j < this.connectedRoles[i].additionalDocuments.length; j++) {
            const expired = !(this.connectedRoles[i].additionalDocuments[j].notExpired);

            // tslint:disable-next-line:max-line-length
            if (this.connectedRoles[i].additionalDocuments[j].documentType.documentTypeName === 'personalContact' || this.connectedRoles[i].additionalDocuments[j].documentType.documentTypeName === 'companyContactForm') {
              // tslint:disable-next-line:max-line-length
              if (this.connectedRoles[i].additionalDocuments[j].documentStatus !== 'REPLACED' && this.connectedRoles[i].additionalDocuments[j].documentStatus !== 'DELETED' && this.connectedRoles[i].additionalDocuments[j].documentStatus !== 'EMPTY') {
                this.showEditInformation = true;
              }
            }

            if (this.connectedRoles[i].additionalDocuments[j].documentStatus !== 'EMPTY') {
              // tslint:disable-next-line:max-line-length
              this.documents.push({ 'name': this.connectedRoles[i].additionalDocuments[j].documentType.description, 'description': this.connectedRoles[i].additionalDocuments[j].description, 'fileId': this.connectedRoles[i].additionalDocuments[j].fileId, 'mimeType': this.connectedRoles[i].additionalDocuments[j].mimeType, 'expires': this.connectedRoles[i].additionalDocuments[j].validTo, 'documentId': this.connectedRoles[i].additionalDocuments[j].id, 'parentDocumentId': this.connectedRoles[i].additionalDocuments[j].parentDocumentId, 'status': this.connectedRoles[i].additionalDocuments[j].documentStatus, 'riskLevel': this.connectedRoles[i].additionalDocuments[j].riskStatus, 'documentTypeId': this.connectedRoles[i].additionalDocuments[j].documentType.id, 'expired': expired, 'contextualData': this.connectedRoles[i].additionalDocuments[j].contextualData });
              // tslint:disable-next-line:max-line-length
              this.allDocuments.push({ 'name': this.connectedRoles[i].additionalDocuments[j].documentType.description, 'description': this.connectedRoles[i].additionalDocuments[j].description, 'fileId': this.connectedRoles[i].additionalDocuments[j].fileId, 'mimeType': this.connectedRoles[i].additionalDocuments[j].mimeType, 'expires': this.connectedRoles[i].additionalDocuments[j].validTo, 'documentId': this.connectedRoles[i].additionalDocuments[j].id, 'status': this.connectedRoles[i].additionalDocuments[j].documentStatus, 'riskLevel': this.connectedRoles[i].additionalDocuments[j].riskStatus,  'documentTypeId': this.connectedRoles[i].additionalDocuments[j].documentType.id, 'expired': expired, 'contextualData': this.connectedRoles[i].additionalDocuments[j].contextualData });
            }
          }

          if (this.connectedRoles[i].e2Instance !== null) {
            // tslint:disable-next-line:max-line-length
            this.rolesDisplay.push({ 'id': this.connectedRoles[i].id, 'roleName': this.connectedRoles[i].roleName, 'roleTypeId': this.connectedRoles[i].roleTypeId, 'e1Id': this.connectedRoles[i].e1Instance.id, 'e1Type': this.connectedRoles[i].e1Instance.entityType.entityType, 'e1Instance': this.connectedRoles[i].e1Instance.primaryName, 'e2Instance': this.connectedRoles[i].e2Instance.primaryName, 'e2Id': this.connectedRoles[i].e2Instance.id, 'e2Type': this.connectedRoles[i].e2Instance.entityType.entityType, 'documents': this.documents });
          } else {
            // tslint:disable-next-line:max-line-length
            this.rolesDisplay.push({ 'id': this.connectedRoles[i].id, 'roleName': this.connectedRoles[i].roleName, 'roleTypeId': this.connectedRoles[i].roleTypeId, 'e1Id': this.connectedRoles[i].e1Instance.id, 'e1Type': this.connectedRoles[i].e1Instance.entityType.entityType, 'e1Instance': this.connectedRoles[i].e1Instance.primaryName, 'e2Instance': '', 'documents': this.documents });
          }
        }

        for (let q = 0; q < this.rolesDisplay.length; q++) {
          this.rolesDisplay[q].documents.sort((a, b) => {
            a = a.name.toLowerCase();
            b = b.name.toLowerCase();

            return a < b ? -1 : a > b ? 1 : 0;
          });
        }
      },
      error => {}
    );
  }

  addTagFn(name) {
    return { name: name, tag: true, active: true };
  }

  addTagEvent(event) {
    let exists = false;

    for (let i = 0; i < this.tags.length; i++) {
      if (this.tags[i].name === event.name) {
        exists = true;
      }
    }

    if (exists) {
      const tagIds = [];

      for (let k = 0; k < this.selectedTags.length; k++) {
        tagIds.push(this.selectedTags[k].id);
      }

      this.updatedEntity = new EntityUpdate();
      this.updatedEntity.entityId = this.entityResponse.data.id;
      this.updatedEntity.entityTypeId = this.entityResponse.data.entityType.id;
      this.updatedEntity.primaryName = this.entityResponse.data.primaryName;
      this.updatedEntity.ownerId = this.entityResponse.data.owner.identifier;
      this.updatedEntity.entityStatus2E = this.entityResponse.data.entityStatus2E;
      this.updatedEntity.tagIds = tagIds;

      this.kybService.updateEntity(this.updatedEntity).subscribe(
        data => {
          this.ngOnInit();
        },
        error => {
          this.alertService.showError(error.error.errors[0].message);
        }
      );
    } else {
      this.kybService.createTag(event.name).subscribe(
        data => {
          this.createTagResponse = data;

          for (let j = 0; j < this.selectedTags.length; j++) {
            if (this.selectedTags[j].name === event.name) {
              this.selectedTags[j].id = this.createTagResponse.data.id;
            }
          }

          const tagIds = [];

          for (let k = 0; k < this.selectedTags.length; k++) {
            tagIds.push(this.selectedTags[k].id);
          }

          this.updatedEntity = new EntityUpdate();
          this.updatedEntity.entityId = this.entityResponse.data.id;
          this.updatedEntity.entityTypeId = this.entityResponse.data.entityType.id;
          this.updatedEntity.primaryName = this.entityResponse.data.primaryName;
          this.updatedEntity.ownerId = this.entityResponse.data.owner.identifier;
          this.updatedEntity.entityStatus2E = this.entityResponse.data.entityStatus2E;
          this.updatedEntity.tagIds = tagIds;

          this.kybService.updateEntity(this.updatedEntity).subscribe(
            entityData => {
              this.ngOnInit();
            },
            error => {}
          );
        },
        error => {}
      );
    }
  }

  removeTagEvent(event) {
    const tagIds = [];

    for (let k = 0; k < this.selectedTags.length; k++) {
      tagIds.push(this.selectedTags[k].id);
    }

    this.updatedEntity = new EntityUpdate();
    this.updatedEntity.entityId = this.entityResponse.data.id;
    this.updatedEntity.entityTypeId = this.entityResponse.data.entityType.id;
    this.updatedEntity.primaryName = this.entityResponse.data.primaryName;
    this.updatedEntity.ownerId = this.entityResponse.data.owner.identifier;
    this.updatedEntity.entityStatus2E = this.entityResponse.data.entityStatus2E;
    this.updatedEntity.tagIds = tagIds;

    this.kybService.updateEntity(this.updatedEntity).subscribe(
      data => {
        this.ngOnInit();
      },
      error => {}
    );
  }

  navigateToDetails(event: any): void {
    this.openEntityDetail(event.id);
  }

  controlloOK() {
    this.controlloLoading = true;
    this.kybService.updateAssessedRisk(this.entityId, 0, null).subscribe(
      data => {
        this.updatedEntity = new EntityUpdate();
        this.updatedEntity.entityId = this.entityId;
        this.updatedEntity.primaryName = this.primaryName;
        this.updatedEntity.entityTypeId = this.entityTypeId;
        this.updatedEntity.entityStatus2E = 'ACCEPTED';

        this.kybService.updateEntity(this.updatedEntity).subscribe(
          entityData => {
            this.kybService.addComment(parseInt(this.entityId, 10), 'User ' + localStorage.getItem('username') + ' updated control of entity to OK.').subscribe(
              commentData => {
                this.controlloLoading = false;
                this.alertService.showSuccess('Successfully adjusted.');
                this.ngOnInit();
              },
              error => {
                this.controlloLoading = false;
              }
            );
          },
          error => {
            this.controlloLoading = false;
          }
        );
      },
      error => {
        this.controlloLoading = false;
      }
    );
  }

  controlloKO() {
    this.controlloLoading = true;
    this.kybService.updateAssessedRisk(this.entityId, this.unacceptableRiskThreshold, null).subscribe(
      data => {
        this.updatedEntity = new EntityUpdate();
        this.updatedEntity.entityId = this.entityId;
        this.updatedEntity.primaryName = this.primaryName;
        this.updatedEntity.entityTypeId = this.entityTypeId;
        this.updatedEntity.entityStatus2E = 'NON_COMPLIANT';

        this.kybService.updateEntity(this.updatedEntity).subscribe(
          entityData => {
            this.kybService.addComment(parseInt(this.entityId, 10), 'User ' + localStorage.getItem('username') + ' updated control of entity to KO.').subscribe(
              commentData => {
                this.controlloLoading = false;
                this.alertService.showSuccess('Successfully adjusted.');
                this.ngOnInit();
              },
              error => {
                this.controlloLoading = false;
              }
            );
          },
          error => {
            this.controlloLoading = false;
          }
        );
      },
      error => {
        this.loading = false;
      }
    );
  }

  showComments() {
    const initialState = {
      entityId: this.username
    };

    this.showCommentsModal = this.modalService.show(ShowCommentsComponent, { initialState, backdrop: 'static' });

    this.subscriptions.push(
      this.modalService.onHide.subscribe(message => {
        this.ngOnInit();
        this.unsubscribe();
      })
    );
  }

  showManualTotalRiskAdjustment() {
    this.kybService.getRiskAnalysisForEntity(this.username).subscribe(
      data => {
        this.riskAnalysisResponse = data;
        this.riskAnalysis = this.riskAnalysisResponse.data;

        if (this.riskAnalysis !== null && this.riskAnalysis.assessedRisk === null) {
          this.riskAnalysis.assessedRisk = 0;
        }

        if (this.riskAnalysisResponse.data.customOverallRisk !== null && this.riskAnalysisResponse.data.customOverallRisk.risk !== 0) {
          this.totalRisk = this.riskAnalysisResponse.data.customOverallRisk.risk;
          this.totalRiskLevel = this.riskAnalysisResponse.data.customOverallRisk.riskStatus;
        }

        let riskLevel = '';

        if (this.riskAnalysisResponse.data.customOverallRisk === null) {
          riskLevel = '';
        } else {
          riskLevel = this.riskAnalysisResponse.data.customOverallRisk.riskStatus;
        }

        const initialState = {
          'entityId': this.entityId,
          'riskLevel': riskLevel,
          'primaryName': this.primaryName,
          'entityTypeId': this.entityTypeId
        };

        // tslint:disable-next-line:max-line-length
        if (this.numberOfModals === 0) {
          this.manualTotalRiskAdjustmentModal = this.modalService.show(ManualTotalRiskAdjustmentComponent, { initialState, backdrop: 'static' });
          this.numberOfModals = 1;
        }

        this.subscriptions.push(
          this.modalService.onHide.subscribe(message => {
            this.numberOfModals = 0;
            this.ngOnInit();
            this.unsubscribe();
          })
        );
      },
      error => {
      }
    );
  }

  showManualPEPRiskAdjustment() {
    this.kybService.getRiskAnalysisForEntity(this.username).subscribe(
      data => {
        this.riskAnalysisResponse = data;
        let riskLevel = '';

        if (this.riskAnalysisResponse.data.customPepRisk === null) {
          riskLevel = '';
        } else {
          riskLevel = this.riskAnalysisResponse.data.customPepRisk.riskStatus;
        }

        const initialState = {
          'entityId': this.entityId,
          'riskLevel': riskLevel,
          'primaryName': this.primaryName,
          'entityTypeId': this.entityTypeId
        };

        if (this.numberOfModals === 0) {
          this.manualPEPRiskAdjustmentModal =
          this.modalService.show(ManualPEPRiskAdjustmentComponent, { initialState, backdrop: 'static' });
          this.numberOfModals += 1;
        }

        this.subscriptions.push(
          this.modalService.onHide.subscribe(message => {
            this.numberOfModals = 0;
            this.ngOnInit();
            this.unsubscribe();
          })
        );
      },
      error => {
      }
    );
  }

  showRemoveManualRiskAdjustment(type: any) {
    const initialState = {
      'type': type,
      'entityId': this.entityId,
      'primaryName': this.primaryName,
      'entityTypeId': this.entityTypeId
    };

    this.removeRiskAdjustmentModal = this.modalService.show(RemoveRiskAdjustmentComponent, { initialState, backdrop: 'static' });

    this.subscriptions.push(
      this.modalService.onHide.subscribe(message => {
        setTimeout(() => {
          this.ngOnInit();
          this.unsubscribe();
        }, 5000);
      })
    );
  }

  renameEntity() {
    const initialState = {
      entityTypeId: this.entityTypeId,
      entityId: this.entityId,
      primaryName: this.primaryName
    };

    this.renameEntityModal = this.modalService.show(RenameEntityComponent, { initialState, backdrop: 'static' });

    this.subscriptions.push(
      this.modalService.onHide.subscribe(message => {
        this.ngOnInit();
        this.unsubscribe();
      })
    );
  }

  confirmRefresh() {
    const initialState = {
      entityId: this.entityId
    };

    this.confirmRefreshModal = this.modalService.show(ConfirmRefreshComponent, { initialState, backdrop: 'static' });
    this.subscriptions.push(this.dataService.closeModalEvent.subscribe(
      message => {
        this.confirmRefreshModal.hide();
        this.ngOnInit();
        this.unsubscribe();
      }
    ));
  }

  onboard() {
    alert('Onboarding SMS has been sent');
  }

  takeOwnership() {
    const ownerId = localStorage.getItem('identifier');

    if (window.confirm('Are you sure you want to take ownership of this entity?')) {
      this.kybService.takeOwnership(this.entityId, ownerId, this.primaryName, this.entityTypeId).subscribe(
        data => {
          this.alertService.showSuccess('Ownership changed successfully.');
          this.ngOnInit();
        },
        error => {
          this.alertService.showError('Something went wrong while changing ownership.');
        }
      );
    }
  }

  toggleRiskInput() {
    if (this.showRiskEdit === false) {
      this.showRiskEdit = true;
    } else {
      this.showRiskEdit = false;
    }
  }

  viewDocument(documentId: any, mimeType: any, name: any, contextualData: any) {
    // tslint:disable-next-line:max-line-length
    const initialState = { documentId: documentId, mimeType: mimeType, documents: [{ documentId: documentId, mimeType: mimeType, name: name, contextualData: contextualData }] };
    this.viewModal = this.modalService.show(ViewDocumentComponent, { initialState, backdrop: 'static' });
    this.subscriptions.push(
      this.modalService.onHide.subscribe(message => {
        this.ngOnInit();
        this.unsubscribe();
      })
    );
  }

  checkDocuments() {
    const initialState = { documents: this.allDocuments };
    this.viewModal = this.modalService.show(ViewDocumentComponent, { initialState, backdrop: 'static' });
    this.subscriptions.push(
      this.modalService.onHide.subscribe(message => {
        this.allDocuments = [];
        this.ngOnInit();
        this.unsubscribe();
      })
    );
  }

  unsubscribe() {
    this.subscriptions.forEach((subscription: Subscription) => {
      subscription.unsubscribe();
    });

    this.subscriptions = [];
  }

  addDocumentToRole(role: any) {
    const initialState = {
      'mode': 'add-document',
      'title': 'Add Document',
      'entityId': this.username,
      'roleId': role.id
    };

    this.addModal = this.modalService.show(AddModalComponent, { initialState, backdrop: 'static' });
    this.subscriptions.push(this.dataService.closeModalEvent.subscribe(
      message => {
        this.addModal.hide();
        this.ngOnInit();
        this.unsubscribe();
        this.drawNewControlChart(this.controlResponse.data);
        this.drawNewUBOChart(this.UBOResponse.data);
      }
    ));
  }

  addDocumentTypeToRole(index: number, todos: any[], isORTrue: boolean) {
    let initialState = {};

    if (isORTrue) {
      const currentTodos: any[] = [];

      currentTodos.push(todos[index]);

      for (let i = index; i > 0; i--) {
        if (todos[i - 1].documentGroup === todos[i].documentGroup) {
          currentTodos.push(todos[i - 1]);
        } else {
          break;
        }
      }

      initialState = {
        'mode': 'add-document',
        'title': 'Add Document',
        'entityId': this.username,
        'roleId': todos[index].roleId,
        'documentTypeId': todos[index].documentTypeId,
        'documentId': todos[index].documentId,
        'currentTodos': currentTodos
      };
    } else {
      initialState = {
        'mode': 'add-document',
        'title': 'Add Document',
        'entityId': this.username,
        'roleId': todos[index].roleId,
        'documentTypeId': todos[index].documentTypeId,
        'documentId': todos[index].documentId
      };
    }

    this.addModal = this.modalService.show(AddModalComponent, { initialState, backdrop: 'static' });

    this.subscriptions.push(this.dataService.closeModalEvent.subscribe(
      message => {
        this.addModal.hide();
        this.ngOnInit();
        this.unsubscribe();
        this.drawNewControlChart(this.controlResponse.data);
        this.drawNewUBOChart(this.UBOResponse.data);
      }
    ));
  }

  replaceDocumentTypeForRole(roleId: any, documentId: any, documentTypeId: any) {
    const initialState = {
      'mode': 'replace-document',
      'title': 'Replace Document',
      'entityId': this.username,
      'documentId': documentId,
      'roleId': roleId,
      'documentTypeId': documentTypeId
    };

    this.addModal = this.modalService.show(AddModalComponent, { initialState, backdrop: 'static' });

    this.subscriptions.push(
      this.dataService.closeModalEvent.subscribe(
        message => {
          this.addModal.hide();
        }
      ),
      this.modalService.onHide.subscribe(message => {
        this.ngOnInit();
        this.unsubscribe();
      })
    );
  }

  editInformation(): void {
    const documentData: any = {};
    let rolesDocument: any;

    documentData.entityId = this.username;

    if (this.rolesDisplay.length && this.rolesDisplay.length > 0) {
      documentData.roleId = this.rolesDisplay[0].id;
      documentData.e1Id = this.rolesDisplay[0].e1Id;
      documentData.e2Id = this.rolesDisplay[0].e2Id;

      this.rolesDisplay[0].documents.forEach(document => {
        if (document.documentStatus !== 'REPLACED' && document.documentStatus !== 'DELETED' && document.documentStatus !== 'EMPTY') {
          rolesDocument = document;

          documentData.documentId = rolesDocument.documentId;
          documentData.documentTypeId = rolesDocument.documentTypeId;
          documentData.documentTypeName = rolesDocument.name;
        }
      });
    }

    const initialState = {
      'mode': 'edit-information',
      'title': 'Edit Information',
      'basicInformation': this.basicInformation,
      'entityResponseData': this.entityResponse.data,
      'primaryName': this.primaryName,
      'documentData': documentData
    };

    this.addModal = this.modalService.show(AddModalComponent, { initialState, backdrop: 'static' });
    this.subscriptions.push(this.dataService.closeModalEvent.subscribe(
      message => {
        Object.entries(message).forEach(entry => {
          if (entry[0] === 'primaryName' || entry[0] === 'phoneNumber' || entry[0] === 'email') {
            this.entityResponse.data[entry[0]] = entry[1];
          }
        });

        this.updatedEntity = new EntityUpdate();
        this.updatedEntity.entityId = this.entityResponse.data.id;
        this.updatedEntity.entityTypeId = this.entityResponse.data.entityType.id;
        this.updatedEntity.entityEmail = this.entityResponse.data.email;
        this.updatedEntity.entityPhoneNumber = this.entityResponse.data.phoneNumber;
        this.updatedEntity.primaryName = this.entityResponse.data.primaryName;

        this.kybService.updateEntity(this.updatedEntity).subscribe();
        this.addModal.hide();
        this.ngOnInit();
        this.unsubscribe();
      }
    ));
  }

  editJsonDocument(roleId: any, documentId: any, documentTypeId: any, e1Id: any, e2Id: any) {
    if (e2Id === undefined) { e2Id = null; }
    const initialState = {
      'mode': 'edit-document',
      'title': 'Edit Document',
      'entityId': this.username,
      'documentId': documentId,
      'roleId': roleId,
      'documentTypeId': documentTypeId,
      'entityTypeId': this.entityTypeId,
      'primaryName': this.primaryName,
      'e1Id': e1Id,
      'e2Id': e2Id
    };

    this.addModal = this.modalService.show(AddModalComponent, { initialState, backdrop: 'static' });
    this.subscriptions.push(
      this.dataService.closeModalEvent.subscribe(
        message => {
          this.addModal.hide();
        }
      ),
      this.modalService.onHide.subscribe(message => {
        this.ngOnInit();
        this.unsubscribe();
      })
    );
  }

  addRoleToEntity(entityId: any, roleTypeId: any) {
    const initialState = {
      'mode': 'add-role',
      'title': 'Add Role',
      'entityId': entityId,
      'roleTypeId': roleTypeId
    };

    this.addModal = this.modalService.show(AddModalComponent, { initialState, backdrop: 'static' });

    this.subscriptions.push(this.dataService.closeModalEvent.subscribe(
      message => {
        this.addModal.hide();
        this.ngOnInit();
        this.unsubscribe();
        this.drawNewControlChart(this.controlResponse.data);
        this.drawNewUBOChart(this.UBOResponse.data);
      }
    ));
  }

  addRole() {
    const initialState = {
      'mode': 'add-role',
      'title': 'Add role',
      'entityId': this.toDoListResponse.data.entityId
    };

    this.addModal = this.modalService.show(AddModalComponent, { initialState, backdrop: 'static' });

    this.subscriptions.push(this.dataService.closeModalEvent.subscribe(
      message => {
        this.addModal.hide();
        this.ngOnInit();
        this.unsubscribe();
      }
    ));
  }

  /**
   * Function used to save the comment
  */
  saveComment() {
    this.kybService.addComment(parseInt(this.entityId, 10), this.entityComment).subscribe(
      data => {
        this.alertService.showSuccess('Comment successfully added.');
        this.entityComment = '';
        this.ngOnInit();
      },
      error => {
        this.alertService.showError(error.error.errors[0].message);
      }
    );
  }

  openEntityDetail(id: any) {
    this.router.navigateByUrl('/tmp', { skipLocationChange: true }).then(() =>
      this.router.navigate(['/detail/' + id]));
  }

  /**
   * Function used to set the KYC status
   * @param {any} userName - user to set the status for
   * @param {any} kycStatus - the value of KYC status (1 - Low Risk, 2 - Medium Risk, 3 - High Risk)
  */
  setKYCStatus(userName: any, kycStatus: any) {
    this.callsService.setKYCStatus(userName, kycStatus).subscribe(
      data => {
      },
      error => {
        if (error.error.text === 'done.') {
          this.alertService.showSuccess('Changed status successfully.');
          this.getAccount();
        } else {
          this.alertService.showError('Something went wrong.');
        }
      }
    );
  }

  addDocument() {
    const initialState = {
      'mode': 'add-document',
      'title': 'Add Document',
      'entityId': this.username
    };

    this.addModal = this.modalService.show(AddModalComponent, { initialState, backdrop: 'static' });

    this.subscriptions.push(this.dataService.closeModalEvent.subscribe(
      message => {
        this.addModal.hide();
        this.ngOnInit();
        this.unsubscribe();
      }
    ));
  }

  setEntityStatus(entityId: any, status: any) {
    const tagIds = [];

    for (let k = 0; k < this.selectedTags.length; k++) {
      tagIds.push(this.selectedTags[k].id);
    }

    this.updatedEntity = new EntityUpdate();
    this.updatedEntity.delegateEmail = 'admin@admin.com';
    this.updatedEntity.entityId = this.username;
    this.updatedEntity.ownerId = this.entityResponse.data.owner.identifier;
    this.updatedEntity.entityStatus2E = status;
    this.updatedEntity.entityTypeId = this.entityResponse.data.entityType.id;
    this.updatedEntity.primaryName = this.entityResponse.data.primaryName;
    this.updatedEntity.tagIds = tagIds;

    this.kybService.updateEntity(this.updatedEntity).subscribe(
      data => {
        this.alertService.showSuccess('Entity status successfully set.');
        this.ngOnInit();
      },
      error => {
        this.alertService.showError(error.error.errors[0].message);
      }
    );
  }

  receiveEvent($event) {
    this.refreshRiskIndicators();
  }

  refreshRiskIndicators() {
    this.kybService.getRiskAnalysisForEntity(this.username).subscribe(
      data => {
        this.riskAnalysisResponse = data;
        this.riskAnalysis = this.riskAnalysisResponse.data;

        if (this.riskAnalysis.assessedRisk === null) {
          this.riskAnalysis.assessedRisk = 0;
        }

        if (this.riskAnalysisResponse.data.customOverallRisk !== null
          && this.riskAnalysisResponse.data.customOverallRisk.risk !== null
          && this.riskAnalysisResponse.data.customOverallRisk.riskStatus !== null
        ) {
          this.totalRisk = this.riskAnalysisResponse.data.customOverallRisk.risk;
          this.totalRiskLevel = this.riskAnalysisResponse.data.customOverallRisk.riskStatus;
        } else {
          this.totalRisk = this.riskAnalysisResponse.data.combinedOverallRisk.risk;
          this.totalRiskLevel = this.riskAnalysisResponse.data.combinedOverallRisk.riskStatus;
        }
      },
      error => {}
    );
  }

  /**
   * Function which runs checks for selected users
  */
  checkUsers() {
    this.loading = true;

    this.callsService.evalUser(this.username).subscribe(
      data => {
        this.loading = false;
        this.alertService.showSuccess('Check successful.');
        this.ngOnInit();
      },
      error => {
        this.loading = false;
        this.alertService.showError('Something went wrong.');
      }
    );
  }

  /**
   * Function used to retreive details for a specific user
  */
  getAccount() {
    this.usersToReview = [];
    this.callsService.getUserDetails(this.username).subscribe(
      data => {
        this.model = data;
        this.getDocuments(this.model);
        this.usersToReview.push(this.model.data.email);
        this.dataService.setUsersForReview(this.usersToReview);
        this.words = this.model.data.video.wordList;
        this.returnWords = this.model.data.video.videoAuthData.wordMatch;
        this.wordsObject.words = this.words;
        this.wordsObject.returnWords = this.returnWords;
        this.wordsObject.match = [];

        for (let i = 0; i < this.words.length; i++) {
          if (this.returnWords[i] === true) {
            this.wordsObject.match.push(1);
          } else {
            this.wordsObject.match.push(0);
          }
        }
      },
      error => {
      }
    );
  }

  getDocuments(model: any) {
    this.callsService.getDocument(model.data.documents.fileMapping.passportFrontFileId).subscribe(
      data => {},
      error => {
        this.safePassportFront = this.domSanitizer.bypassSecurityTrustResourceUrl('data:image/jpg;base64,' + error.error.text);
      }
    );

    if (model.data.documents.fileMapping.passportBackFileId) {
      this.callsService.getDocument(model.data.documents.fileMapping.passportBackFileId).subscribe(
        data => {},
        error => {
          this.safePassportBack = this.domSanitizer.bypassSecurityTrustResourceUrl('data:image/jpg;base64,' + error.error.text);
        }
      );
    }

    this.callsService.getDocument(model.data.documents.fileMapping.proofOfResidenceFileId).subscribe(
      data => {},
      error => {
        this.safeProofOfResidence = this.domSanitizer.bypassSecurityTrustResourceUrl('data:image/jpg;base64,' + error.error.text);
      }
    );

    this.callsService.getDocument(model.data.video.videoFileId).subscribe(
      data => {},
      error => {
        const selfieVideo = document.getElementById('selfie-video') as HTMLVideoElement;
        this.safeSelfieVideo = 'data:video/webm;base64,' + error.error.text;
        selfieVideo.src = this.safeSelfieVideo;
      }
    );
  }

  moveToFurther(entryId: any, email: any, direction: any) {
    this.callsService.moveMatch(email, entryId, direction).subscribe(
      data => {
        this.ngOnInit();
      },
      error => {}
    );
  }

  addNote(email: any, entryId: any, note: any) {
    this.callsService.updateMatchNote(email, entryId, note).subscribe(
      data => {
        this.ngOnInit();
      },
      error => {}
    );
  }

  setDocumentStatus(status: any) {
    const x = document.getElementsByClassName('uploadedImages') as HTMLCollectionOf<HTMLElement>;

    if (x[0].attributes[2].value === 'display: block;' || x[1].attributes[2].value === 'display: block;') {
      this.callsService.setIdentityDocumentStatus(this.username, status).subscribe(
        data => {
          let statusText = '';
          if (status === 'red') {
            statusText = 'REJECTED';
          }

          if (status === 'yellow') {
            statusText = 'FURTHER RESEARCH';
          }

          if (status === 'green') {
            statusText = 'APPROVED';
          }

          this.callsService.addEvent(this.username, localStorage.getItem('username'), '[IDENTITY DOCUMENT STATUS] ' + localStorage.getItem('username') + ' changed the status of the identity document to ' + statusText).subscribe();
          this.ngOnInit();
        },
        error => {}
      );
    } else {
      this.callsService.setProofOfResidenceStatus(this.username, status).subscribe(
        data => {
          let statusText = '';
          if (status === 'red') {
            statusText = 'REJECTED';
          }

          if (status === 'yellow') {
            statusText = 'FURTHER RESEARCH';
          }

          if (status === 'green') {
            statusText = 'APPROVED';
          }

          this.callsService.addEvent(this.username, localStorage.getItem('username'), '[PROOF OF RESIDENCE STATUS] ' + localStorage.getItem('username') + ' changed the status of the proof of residence document to ' + statusText).subscribe();
          this.ngOnInit();
        },
        error => {}
      );
    }
  }

  drawRelationshipGraph(relationship: any) {
    let height = '';

    this.relationshipGraphData = [];

    if (this.entityType !== 'legal') {
      height = '50%';
    } else {
      height = '100%';
    }

    if (relationship.length === 0) {
      this.relationshipGraphData.push({ 'from': this.entityName, 'to': this.entityName });
    } else {
      for (let i = 0; i < relationship.length; i++) {
        let currentColorFrom = '';
        let currentColorTo = '';
        let currentStatusFrom = '';
        let currentStatusTo = '';

        if (relationship[i].entity1.customOverallRisk.riskStatus !== null) {
          currentStatusFrom = relationship[i].entity1.customOverallRisk.riskStatus;
        } else {
          currentStatusFrom = relationship[i].entity1.combinedOverallRisk.riskStatus;
        }

        if (relationship[i].entity2.customOverallRisk.riskStatus !== null) {
          currentStatusTo = relationship[i].entity2.customOverallRisk.riskStatus;
        } else {
          currentStatusTo = relationship[i].entity2.combinedOverallRisk.riskStatus;
        }

        switch (currentStatusFrom) {
          case 'HIGH':
            currentColorFrom = 'rgb(214, 134, 15)';
            break;
          case 'LOW':
            currentColorFrom = '#72C245';
            break;
          case 'MEDIUM':
            currentColorFrom = '#FDD32B';
            break;
          case 'REJECTED':
            currentColorFrom = '#D60000';
            break;
          default:
            currentColorFrom = '#bdbdbd';
        }

        switch (currentStatusTo) {
          case 'HIGH':
            currentColorTo = 'rgb(214, 134, 15)';
            break;
          case 'LOW':
            currentColorTo = '#72C245';
            break;
          case 'MEDIUM':
            currentColorTo = '#FDD32B';
            break;
          case 'REJECTED':
            currentColorTo = '#D60000';
            break;
          default:
            currentColorTo = '#bdbdbd';
        }

        // tslint:disable-next-line:max-line-length
        this.relationshipGraphData.push({ 'from': relationship[i].entity1.entityName, 'to': relationship[i].entity2.entityName, 'name': relationship[i].roleName, 'colorFrom': currentColorFrom, 'colorTo': currentColorTo });
      }
    }

    this.chart = new Chart({
      chart: {
        type: 'networkgraph',
        events: {
          load() {
            const series = this.series[0];
            series.data.forEach(function(point, i) {
              if ((point as any).colorFrom && (series as any).nodes[i]) {
                (series as any).nodes[i].color = (point as any).colorFrom;
              }

              if ((point as any).colorTo && (series as any).nodes[i + 1]) {
                (series as any).nodes[i + 1].color = (point as any).colorTo;
              }
            });
          }
        },
        height: height
      },
      title: {
        text: ''
      },
      credits: {
        enabled: false
      },
      plotOptions: {
        networkgraph: {
          marker: {
            radius: 20
          },
          keys: ['from', 'to'],
          layoutAlgorithm: {
            enableSimulation: false,
            linkLength: 40,
            friction: -0.9
          },
          lineWidth: 4
        }
      },
      series: [{
        type: 'networkgraph',
        dataLabels: {
          enabled: true,
          linkTextPath: {
            attributes: {
              dy: 12
            }
          },
          linkFormat: 'is {point.name} of'
        },
        id: 'lang-tree',
        color: '#68799c',
        data: this.relationshipGraphData
      }],
      exporting: {
        enabled: false
      }
    });
  }

  prepareControlData(startEntity: any, entities: any) {
    let overallRisk = '';

    for (let i = 0; i < entities.length; i++) {
      this.controls.push({ from: startEntity, to: entities[i].entityName });

      if (entities[i].customOverallRisk.riskStatus !== null) {
        overallRisk = entities[i].customOverallRisk.riskStatus;
      } else {
        overallRisk = entities[i].combinedOverallRisk.riskStatus;
      }

      // tslint:disable-next-line:max-line-length
      this.controlNodes.push({ id: entities[i].entityName, title: entities[i].entityName, overallRisk, name: 'E' + (entities[i].distance + 1) + '-' + entities[i].roleName, type: entities[i].entityType, detailId: entities[i].id });

      if (entities[i].entities.length > 0) {
        this.prepareControlData(entities[i].entityName, entities[i].entities);
      }
    }

    return this.controls;
  }

  drawNewControlChart(controlData: any) {
    const newChilds: any = [];

    if (controlData.controls.length === 0) {
      this.emptyControl = true;
      this.controlNodes = [];
    } else {
      this.emptyControl = false;
      this.controlNodes = [];

      for (let i = 0; i < controlData.controls.length; i++) {
        newChilds.push({ id: controlData.controls[i].id, title: controlData.controls[i].entityName,
          name: 'E' + (controlData.controls[i].distance + 1) + '-' +
          controlData.controls[i].roleName });
      }

      controlData.controls.forEach(element => {
        newChilds.forEach(child => {
          let currentStatus = '';

          if (element.customOverallRisk.riskStatus !== null) {
            currentStatus = element.customOverallRisk.riskStatus;
          } else {
            currentStatus = element.combinedOverallRisk.riskStatus;
          }

          switch (currentStatus) {
            case 'HIGH':
              child.cssClass = 'OR-high';
              break;
            case 'LOW':
              child.cssClass = 'OR-low';
              break;
            case 'MEDIUM':
              child.cssClass = 'OR-medium';
              break;
            case 'REJECTED':
              child.cssClass = 'OR-rejected';
              break;
            default:
              child.cssClass = 'OR-unevaluated';
          }

          if (element.entityType === 'PERSON') {
            child.image = '../../assets/images/person.svg';
          } else {
            child.image = '../../assets/images/building.svg';
          }
        });
      });

      this.controlNodes.push({ id: controlData.entity.id, title: controlData.entity.entityName, name: 'FOCUS Company', childs: newChilds });
    }
  }

  drawControlChart(controlData: any) {
    if (this.controlChart !== undefined) {
      this.controlChart.destroy();
    }

    if (controlData.controls.length === 0) {
      this.emptyControl = true;
      this.controlData = [];
      this.controlNodes = [];
      this.controls = [];
    } else {
      this.emptyControl = false;
      this.controlData = [];
      this.controlNodes = [];
      this.controls = [];
      this.controlData = this.prepareControlData(controlData.entity.entityName, controlData.controls);
      // tslint:disable-next-line:max-line-length
      this.controlNodes.push({ id: controlData.entity.entityName, title: controlData.entity.entityName, name: 'FOCUS Company', detailId: controlData.entity.id });
      this.controlChart = new Chart({
        chart: {
          height: 600,
          inverted: true,
          style: {
            cursor: 'pointer'
          }
        },
        title: {
          text: 'Control'
        },
        series: [{
          type: 'organization',
          name: controlData.entity.entityName,
          keys: ['from', 'to'],
          data: this.controlData,
          levels: [{
            level: 0,
            color: 'silver',
            dataLabels: {
              color: 'white',
            },
          }, {
            level: 1,
            color: 'green',
            dataLabels: {
              color: 'white'
            },
          }, {
            level: 2,
            color: 'green',
            dataLabels: {
              color: 'white'
            }
          }, {
            level: 3,
            color: 'green',
            dataLabels: {
              color: 'white'
            }
          }, {
            level: 4,
            color: 'green',
            dataLabels: {
              color: 'white'
            }
          }],
          nodes: this.controlNodes,
          events: {
            click: (event) => {
              this.point = event;

              if (event.point['toNode'] === undefined) {
                const url = window.location.href.split('/');
                window.open(url[url.length - 2] + '/' + event.point.options['detailId']);
              }
            },
            mouseOver: (event) => {
              document.body.style.cursor = 'pointer';
            },
            mouseOut: () => {
              document.body.style.cursor = 'default';
            }
          },
          colorByPoint: false,
          color: '#007ad0',
          dataLabels: {
            color: 'white'
          },
          borderColor: 'white',
          nodeWidth: 65
        }],
        tooltip: {
          outside: true,
          formatter: () => {
            if (this.point['toNode'] !== undefined) {
              return this.point['toNode']['name'].split('-')[1];
            } else {
              return this.point['name'] + '<br/><b>' + this.point['title'] + '</b>';
            }
          }
        },
        exporting: {
          allowHTML: false,
          sourceWidth: 800,
          sourceHeight: 600
        },
        credits: {
          enabled: false
        },
      });
    }
  }

  prepareUBOData(startEntity: any, entities: any) {
    this.entityLevel++;

    let overallRisk = '';

    for (let i = 0; i < entities.length; i++) {
      let cyclesWith = '';

      this.owners.push({ from: entities[i].entityName, to: startEntity });

      if (entities[i].customOverallRisk.riskStatus !== null) {
        overallRisk = entities[i].customOverallRisk.riskStatus;
      } else {
        overallRisk = entities[i].combinedOverallRisk.riskStatus;
      }

      if (entities[i].cycles.length > 0) {
        for (let j = 0; j < entities[i].cycles.length; j++) {
          cyclesWith += entities[i].cycles[j].name + ',';
        }
      }

      if (cyclesWith !== '') {
        for (let z = 0; z < this.uboNodes.length; z++) {
          if (this.uboNodes[z].title === entities[i].entityName) {
            this.uboNodes[z].color = '#cc0000';
            this.uboNodes[z].cycles = cyclesWith.replace(/,([^,]*)$/, '$1');
            this.uboNodes[z].image = this.exclamationImage;
          }
        }

        if (this.titles.indexOf(entities[i].entityName) === -1) {
          this.titles.push(entities[i].entityName);
          // tslint:disable-next-line:max-line-length
          this.uboNodes.push({ id: entities[i].entityName, title: entities[i].entityName, overallRisk, name: 'E' + (entities[i].distance + 1) + '-' + entities[i].roleName, type: entities[i].entityType, detailId: entities[i].id, color: '#cc0000', cycles: cyclesWith.replace(/,([^,]*)$/, '$1'), image: this.exclamationImage, layout: 'hanging' });
        }
      } else {
        if (this.titles.indexOf(entities[i].entityName) === -1) {
          this.titles.push(entities[i].entityName);
            // tslint:disable-next-line:max-line-length
            this.uboNodes.push({ id: entities[i].entityName, title: entities[i].entityName, overallRisk, name: 'E' + (entities[i].distance + 1) + '-' + entities[i].roleName, type: entities[i].entityType, detailId: entities[i].id, cycles: '', layout: 'hanging' });
        } else {
          // tslint:disable-next-line:max-line-length
          this.uboNodes.push({ id: entities[i].entityName, title: entities[i].entityName, overallRisk, name: 'E' + (entities[i].distance + 1) + '-' + entities[i].roleName, type: entities[i].entityType, detailId: entities[i].id, cycles: '' });
        }
      }

      if (entities[i].entities.length > 0) {
        this.prepareUBOData(entities[i].entityName, entities[i].entities);
      }
    }

    return this.owners;
  }

  prepareNewUBOData(entity: any) {
    const childIds = [];

    if (entity.length > 0) {
      entity.forEach(element => {
        this.prepareNewUBOData(element);
      });
    } else if (entity.entities && entity.entities.length > 0) {
        this.prepareNewUBOData(entity.entities);

        for (let i = 0; i < entity.entities.length; i++) {
          if (entity.entities[i].entities.length > 0) {
            entity.entities[i].entities.forEach(element => {
              childIds.push({ childs: [{ id: element.id }], id: entity.entities[i].id });
            });
          } else {
            childIds.push({ id: entity.entities[i].id });
          }

          this.newChilds.push({ childs: childIds, id: entity.id });
        }
      } else {
        this.newChilds.push({ id: entity.id });
      }
  }

  putImageNameAndTitle(uboNode: any, child: any) {
    if (child.length > 0) {
      child.forEach(element => {
        this.putImageNameAndTitle(uboNode, element);
      });
    }

    if (uboNode.detailId === child.id) {
      child.name = uboNode.name;
      child.title = uboNode.title;

      switch (uboNode.overallRisk) {
        case 'HIGH':
          child.cssClass = 'OR-high';
          break;
        case 'LOW':
          child.cssClass = 'OR-low';
          break;
        case 'MEDIUM':
          child.cssClass = 'OR-medium';
          break;
        case 'REJECTED':
          child.cssClass = 'OR-rejected';
          break;
        default:
          child.cssClass = 'OR-unevaluated';
      }

      if (uboNode.type === 'PERSON') {
        child.image = '../../assets/images/person.svg';
      } else {
        child.image = '../../assets/images/building.svg';
      }
    }

    if (child.childs && child.childs.length > 0) {
      this.putImageNameAndTitle(uboNode, child.childs);
    } else if (uboNode.detailId === child.id) {
      child.name = uboNode.name;
      child.title = uboNode.title;

      switch (uboNode.overallRisk) {
        case 'HIGH':
          child.cssClass = 'OR-high';
          break;
        case 'LOW':
          child.cssClass = 'OR-low';
          break;
        case 'MEDIUM':
          child.cssClass = 'OR-medium';
          break;
        case 'REJECTED':
          child.cssClass = 'OR-rejected';
          break;
        default:
          child.cssClass = 'OR-unevaluated';
      }

      if (uboNode.type === 'PERSON') {
        child.image = '../../assets/images/person.svg';
      } else {
        child.image = '../../assets/images/building.svg';
      }
    }
  }

  drawNewUBOChart(UBOdata: any) {
    let currentChilds = [];
    const newNodes = [];

    UBOdata.owners.forEach(owner => {
      currentChilds = [];
      if (owner.entities.length > 0) {
        owner.entities.forEach(entity => {
          this.newChilds = [];
          this.prepareNewUBOData(entity);
          currentChilds.push(this.newChilds[this.newChilds.length - 1]);
        });
      } else {
        currentChilds = [];
      }

      this.nodes.push({ childs: currentChilds, id: owner.id });
    });

    this.uboNodes.forEach(uboNode => {
      this.nodes.forEach(node => {
        if (uboNode.detailId === node.id) {
            node.name = uboNode.name;
            node.title = uboNode.title;

            switch (uboNode.overallRisk) {
              case 'HIGH':
                node.cssClass = 'OR-high';
                break;
              case 'LOW':
                node.cssClass = 'OR-low';
                break;
              case 'MEDIUM':
                node.cssClass = 'OR-medium';
                break;
              case 'REJECTED':
                node.cssClass = 'OR-rejected';
                break;
              default:
                node.cssClass = 'OR-unevaluated';
            }

            if (uboNode.type === 'PERSON') {
              node.image = '../../assets/images/person.svg';
            } else {
              node.image = '../../assets/images/building.svg';
            }
        }

        if (node.childs) {
          this.putImageNameAndTitle(uboNode, node.childs);
        }
      });

      if (uboNode.detailId === UBOdata.entity.id) {
          newNodes.push({ id: uboNode.detailId, name: uboNode.name, title: uboNode.title, childs: this.nodes });
        }
    });

    this.nodes = newNodes;
  }

  drawUBOChart(UBOdata: any) {
    this.titles = [];

    if (this.uboChart !== undefined) {
      this.uboChart.destroy();
    }

    if (UBOdata.owners.length === 0) {
      this.emptyUBO = true;
      this.uboData = [];
      this.uboNodes = [];
      this.uboLevels = [];
      this.entityLevel = 0;
      this.owners = [];
    } else {
      this.emptyUBO = false;
      this.uboData = [];
      this.uboNodes = [];
      this.uboLevels = [];
      this.entityLevel = 0;
      this.owners = [];
      this.uboData = this.prepareUBOData(UBOdata.entity.entityName, UBOdata.owners);

      // Check this for the colors
      for (let i = 0; i < this.entityLevel; i++) {
        this.uboLevels.push({ level: i, color: 'blue', dataLabels: { color: 'white' } });
      }

      // tslint:disable-next-line:max-line-length
      this.uboNodes.push({ id: UBOdata.entity.entityName, title: UBOdata.entity.entityName, name: 'FOCUS Company', detailId: UBOdata.entity.id, color: 'silver' });
      let title = '';

      for (let x = 0; x < this.uboNodes.length; x++) {
        if (this.uboNodes[x].name === 'FOCUS Company') {
          title = this.uboNodes[x].title;
          this.index = x;
        }
      }

      for (let y = 0; y < this.uboNodes.length; y++) {
        if (this.uboNodes[y].title === title && this.uboNodes[y].name !== 'FOCUS Company') {
          this.uboNodes[this.index].color = '#cc0000';
          this.uboNodes[this.index].cycles = this.uboNodes[y].cycles;
          this.uboNodes[this.index].image = this.uboNodes[y].image;
          this.uboNodes.splice(y, 1);
        }
      }

      let cycleEntities = '';

      for (let p = 0; p < this.uboNodes.length; p++) {
        if (this.uboNodes[p].name === 'FOCUS Company') {
          this.index = p;
        }

        if (this.uboNodes[p].cycles !== '') {
          if (this.uboNodes[p].cycles !== undefined) {
            cycleEntities = this.uboNodes[p].cycles.split(',');
          }
        }
      }


      for (let q = 0; q < this.uboData.length; q++) {

        if (this.uboData[q].from === this.uboNodes[this.index].title && cycleEntities.indexOf(this.uboData[q].to) !== -1) {
          this.uboData.splice(q, 1);
        }
      }

      this.uboChart = new Chart({
        chart: {
          height: 600,
          inverted: true
        },
        title: {
          text: 'UBO'
        },
        series: [{
          type: 'organization',
          name: UBOdata.entity.entityName,
          keys: ['from', 'to'],
          data: this.uboData,
          levels: this.uboLevels,
          nodes: this.uboNodes,
          events: {
            click: (event) => {
              this.point = event;

              if (event.point['toNode'] === undefined) {
                const url = window.location.href.split('/');
                window.open(url[url.length - 2] + '/' + event.point.options['detailId']);
              }
            },
            mouseOver: () => {
              document.body.style.cursor = 'pointer';
            },
            mouseOut: () => {
              document.body.style.cursor = 'default';
            }
          },
          colorByPoint: false,
          color: '#007ad0',
          dataLabels: {
            color: 'white',
            style: {
              fontSize: '9px'
            }
          },
          borderColor: 'white'
        }],
        tooltip: {
          outside: true,
          formatter: () => {
            if (this.point['cycles'] !== '') {
              if (this.point['cycles'] !== undefined && this.point['cycles'] !== null) {
                return '<b>WARNING: Cycles detected between ' + this.point['cycles'] + '</b>';
              } else {
                if (this.point['fromNode'] !== undefined) {
                  return this.point['fromNode']['name'].split('-')[1];
                } else {
                  return this.point['name'] + '<br/><b>' + this.point['title'] + '</b>';
                }
              }
            } else {
              if (this.point['fromNode'] !== undefined) {
                return this.point['fromNode']['name'].split('-')[1];
              } else {
                return this.point['name'] + '<br/><b>' + this.point['title'] + '</b>';
              }
            }
          }
        },
        exporting: {
          allowHTML: false,
          sourceWidth: 800,
          sourceHeight: 600
        },
        credits: {
          enabled: false
        },
      });
    }
  }

  prepareOrganizationalChart(relationship: any, inputData: any) {
    if (relationship.length === 0) {
      inputData.push([this.entityName, this.entityName]);
    } else {
      this.counter += relationship.length;

      for (let i = 0; i < relationship.length; i++) {
        this.corporateRisk += relationship[i].defaultRisk;

        if (relationship[i].documentStatus === 'UNEVALUATED') {
          // tslint:disable-next-line:max-line-length
          inputData.push({ 'from': relationship[i].entity1.entityName, 'to': relationship[i].entity2.entityName, 'name': relationship[i].roleName, 'color': '#bdbdbd' });
        } else if (relationship[i].documentStatus === 'ACCEPTED') {
          // tslint:disable-next-line:max-line-length
          inputData.push({ 'from': relationship[i].entity1.entityName, 'to': relationship[i].entity2.entityName, 'name': relationship[i].roleName, 'color': '#72C245' });
        } else if (relationship[i].documentStatus === 'ESCALATED') {
          // tslint:disable-next-line:max-line-length
          inputData.push({ 'from': relationship[i].entity1.entityName, 'to': relationship[i].entity2.entityName, 'name': relationship[i].roleName, 'color': '#FDD32B' });
        } else {
          // tslint:disable-next-line:max-line-length
          inputData.push({ 'from': relationship[i].entity1.entityName, 'to': relationship[i].entity2.entityName, 'name': relationship[i].roleName, 'color': '#D60000' });
        }

        if (relationship[i].entity1.roles.length > 0) {
          this.prepareOrganizationalChart(relationship[i].entity1.roles, inputData);
        }

        if (relationship[i].entity2.roles.length > 0) {
          this.prepareOrganizationalChart(relationship[i].entity2.roles, inputData);
        }
      }
    }

    this.drawOrganizationalChart(inputData);

    this.corporateRiskAverage = this.corporateRisk / this.counter;
  }


  drawOrganizationalChart(inputData: any) {
    this.chart2 = new Chart({
      chart: {
        height: '300px',
        inverted: true,
        type: 'organization',
        events: {
          render: () => {
            const div = document.getElementsByTagName('svg') as HTMLCollectionOf<SVGSVGElement>;
            if (div !== null) {
              const svgSize = div[1].getBoundingClientRect();

              const xml = new XMLSerializer().serializeToString(div[1]);
              const svg64 = btoa(unescape(encodeURIComponent(xml)));
              const b64Start = 'data:image/svg+xml;base64,';
              let image64 = b64Start + svg64;

              const canvas = document.createElement('canvas');
              canvas.width = svgSize.width;
              canvas.height = svgSize.height;
              const ctx = canvas.getContext('2d');

              const img = document.createElement('img');
              img.setAttribute('src', 'data:image/svg+xml;base64,' + svg64);
              img.setAttribute('alt', 'pieChart');
              img.onload = () => {
                ctx.drawImage(img, 0, 0);
                image64 = canvas.toDataURL('image/png');
                sessionStorage.setItem('organizationalChart', image64);
              };
            }
          }
        }
      },
      plotOptions: {
        organization: {
          dataLabels: {
            enabled: true
          },
        }
      },
      title: {
        text: ''
      },
      credits: {
        enabled: false
      },
      series: [{
        type: undefined,
        keys: ['from', 'to', 'name', 'color'],
        label: {
          enabled: true
        },
        data: inputData
        ,
        colorByPoint: false,
        color: '#007ad0',
        dataLabels: {
          enabled: true,
          style: {
            color: 'white',
            fontSize: '8px'
          }
        },
        borderColor: 'white',
        nodeWidth: 65
      }],
      tooltip: {
        enabled: true,
        outside: true
      }
    }
    );
  }

  sendOnboardingEmail() {
    this.onboardingLoading = true;

    this.kybService.sendOnboardingInvitation(this.entityId, true, false).subscribe(
      data => {
        this.alertService.showSuccess('Email was sent.');
        this.onboardingLoading = false;
      },
      error => {
        this.alertService.showError(error.error.errors[0].cause);
        this.onboardingLoading = false;
      }
    );
  }

  sendOnboardingSMS() {
    this.onboardingLoading = true;

    this.kybService.sendOnboardingInvitation(this.entityId, false, true).subscribe(
      data => {
        this.alertService.showSuccess('SMS was sent.');
        this.onboardingLoading = false;
      },
      error => { this.onboardingLoading = false; }
    );
  }

  seeDetail(event) {
    if (event.indexOf('[SCREENING RESULT') !== -1) {
      const entityId = event.split('match ')[1];
      const name = entityId.split('Entity:')[1];
      const encoded = encodeURIComponent(name);
      const origin = window.location.origin;
      const url = origin + '/search?q=' + encoded;

      window.open(url, '_blank');
    }
  }

  fullReport() {
    let url = '';

    // tslint:disable-next-line:max-line-length
    url = 'https://loadtest.kycbox.io/fullReport?firstName=' + this.entityResponse.data.primaryName;

    window.open(url, '_blank');
  }

  formatDateForWebUI(date: any) {
    const onlyDate = date.split('T')[0];

    return onlyDate;
  }

  edit() {
    this.router.navigate(['/structure-builder'], { queryParams: { entityId: this.username } });
  }

  deleteEntity() {
    this.kybService.getOrphanedEntities(this.username).subscribe(
      entityData => {
        this.deleteEntityResponse = entityData;

        if (this.deleteEntityResponse.data.length === 0) {
          if (window.confirm('Are you sure you want to delete this entity?')) {
            this.kybService.deleteEntity(this.username).subscribe(
              data => {
                if (data) {
                  this.router.navigate(['/enterprise']);
                }
              },
              error => {}
            );
          }
        } else {
          let entitiesList = '';

          for (let i = 0; i < this.deleteEntityResponse.data.length; i++) {
            entitiesList += ' ' + this.deleteEntityResponse.data[i].primaryName;
          }

          if (window.confirm('If you delete this entity, entities ' + entitiesList + ' will become orphaned (will not have any connections to any other entities in the system). Are you sure you want to delete it?')) {
            this.kybService.deleteEntity(this.username).subscribe(
              data => {
                if (data) {
                  this.router.navigate(['/enterprise']);
                }
              },
              error => {}
            );
          }
        }
      },
      error => {}
    );
  }

  deleteRole(roleId: any) {
    if (window.confirm(`Are you sure you want to delete this role? This action can't be taken back.`)) {
      this.kybService.deleteRole(roleId).subscribe(
        data => {
          this.alertService.showSuccess('Role successfully deleted.');
          this.ngOnInit();
          this.drawNewControlChart(this.controlResponse.data);
          this.drawNewUBOChart(this.UBOResponse.data);
        },
        error => {}
      );
    }
  }

  deleteDocument(document: any) {
    if (window.confirm(`Are you sure you want to delete this document? This action can't be taken back.`)) {
      this.kybService.updateDocumentMetadata(document.documentId, null, 'DELETED', null, null, null).subscribe(
        data => {
          this.alertService.showSuccess('Document deleted.');
          this.ngOnInit();
          this.drawNewControlChart(this.controlResponse.data);
          this.drawNewUBOChart(this.UBOResponse.data);
        },
        error => {}
      );
    }
  }
}

