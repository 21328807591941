import { RouterModule } from '@angular/router';
import { LoginComponent } from './user_management/login';
import { RegisterComponent } from './user_management/register';
import { AuthGuard, UserGroupGuard } from './_guards';
import { EnterpriseComponent } from './user_management/enterprise';
import { BatchComponent } from './user_management/batch';
import { DocumentCheckerComponent } from './user_management/document-checker/documentChecker.component';
import { SettingsComponent } from './user_management/settings';
import { UserUploaderComponent } from './user_management/settings/userManagement/userUploader/userUploader.component';
import { NonAdminComponent } from './user_management/nonadmin/nonAdmin.component';
import { MyAccountComponent } from './user_management/myAccount/myAccount.component';
import { ScreeningComponent } from './user_management/screening/screening.component';
import { AddDossierComponent } from './user_management/register/addDossier/addDossier.component';
import { DocumentEditorComponent } from './user_management/document-editor/documentEditor.component';
import { Error404Component } from './error404.component';
import { MenuComponent } from './user_management/menu/menu.component';
import { SearchComponent } from './user_management/menu/search';
import { FullReportComponent } from './user_management/menu/fullReport';
import { PackageManagerComponent } from './user_management/package-manager/packageManager.component';
import { NotificationsComponent } from './user_management/notifications';
var ɵ0 = { enterpriseGuardRedirect: 'account/:username', roles: ['admin'] }, ɵ1 = { enterpriseGuardRedirect: 'account/:username' }, ɵ2 = function () { return import("./user_management/detail/detail.module.ngfactory").then(function (module) { return module.DetailModuleNgFactory; }); }, ɵ3 = { enterpriseGuardRedirect: 'account/:username' }, ɵ4 = { enterpriseGuardRedirect: 'account/:username' }, ɵ5 = function () { return import("./user_management/reporting/reporting.module.ngfactory").then(function (module) { return module.ReportingModuleNgFactory; }); }, ɵ6 = function () { return import("./user_management/dashboard/dashboard.module.ngfactory").then(function (module) { return module.DashboardModuleNgFactory; }); }, ɵ7 = { enterpriseGuardRedirect: 'account/:username' };
var appRoutes = [
    {
        path: '',
        redirectTo: '/dashboard',
        pathMatch: 'full',
        canActivate: [AuthGuard]
    },
    { path: 'login', component: LoginComponent },
    { path: 'editor', component: DocumentEditorComponent },
    { path: 'register', redirectTo: 'register/en', pathMatch: 'full' },
    { path: 'register/:lang/:redirectUrl', component: RegisterComponent },
    { path: 'register/:lang', component: RegisterComponent },
    { path: 'onboard/:token', component: RegisterComponent },
    { path: 'onboard', component: RegisterComponent },
    { path: 'account', component: NonAdminComponent, canActivate: [AuthGuard] },
    {
        path: 'enterprise',
        component: EnterpriseComponent,
        canActivate: [AuthGuard, UserGroupGuard],
        data: ɵ0
    },
    {
        path: 'notifications',
        canActivate: [AuthGuard, UserGroupGuard],
        component: NotificationsComponent
    },
    {
        path: 'fullReport',
        canActivate: [AuthGuard, UserGroupGuard],
        component: FullReportComponent
    },
    {
        path: 'research',
        component: MenuComponent,
        canActivate: [AuthGuard, UserGroupGuard]
    },
    {
        path: 'search',
        canActivate: [AuthGuard, UserGroupGuard],
        component: SearchComponent
    },
    {
        path: 'settings',
        component: SettingsComponent,
        canActivate: [AuthGuard, UserGroupGuard],
        data: ɵ1
    },
    {
        path: 'detail/:username',
        loadChildren: ɵ2,
        canActivate: [AuthGuard, UserGroupGuard],
        data: ɵ3
    },
    {
        path: 'batch',
        component: BatchComponent,
        canActivate: [AuthGuard],
        data: ɵ4
    },
    {
        path: 'document-checker',
        component: DocumentCheckerComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'structure-builder',
        component: AddDossierComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'user-loader',
        component: UserUploaderComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'screening',
        component: ScreeningComponent,
        canActivate: [AuthGuard, UserGroupGuard]
    },
    {
        path: 'reporting',
        loadChildren: ɵ5,
        canActivate: [AuthGuard, UserGroupGuard]
    },
    {
        path: 'dashboard',
        loadChildren: ɵ6,
        canActivate: [AuthGuard, UserGroupGuard]
    },
    {
        path: 'my-account',
        component: MyAccountComponent,
        canActivate: [AuthGuard, UserGroupGuard],
        data: ɵ7
    },
    {
        path: 'package-manager',
        component: PackageManagerComponent,
        canActivate: [AuthGuard, UserGroupGuard]
    },
    {
        path: '404',
        component: Error404Component
    },
    { path: '**', redirectTo: '' }
];
export var routing = RouterModule.forRoot(appRoutes);
export { ɵ0, ɵ1, ɵ2, ɵ3, ɵ4, ɵ5, ɵ6, ɵ7 };
