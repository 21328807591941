import * as tslib_1 from "tslib";
import { OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertService, CallsService, DataService, KYBService } from '../../_services';
import { DomSanitizer } from '@angular/platform-browser';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { config } from '../../../assets/configuration';
import { Chart } from 'angular-highcharts';
import { EntityUpdate } from '../../../app/_models/kyb';
import { AddModalComponent } from '../../../app/user_management/enterprise/enterprise.component';
import { ConfigService } from '../../_services/config.service';
import { TranslateService } from '@ngx-translate/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ConfirmRefreshComponent } from '../../modals/confirm-refresh/confirmRefresh.component';
/**
 * Implements the user detail page
*/
var doc;
var docStatus;
var CommentIsNullComponent = /** @class */ (function () {
    function CommentIsNullComponent(bsModalRef, kybService) {
        this.bsModalRef = bsModalRef;
        this.kybService = kybService;
        this.subscriptions = [];
        this.comment = '';
        this.commentIsNullError = false;
        this.currentDocument = doc;
        this.currentDocumentStatus = docStatus;
    }
    CommentIsNullComponent.prototype.ngOnInit = function () { };
    CommentIsNullComponent.prototype.saveComment = function () {
        var _this = this;
        if (this.comment && this.currentDocument && this.currentDocumentStatus) {
            this.kybService.updateDocumentMetadata(this.currentDocument.documentId, this.comment, this.currentDocumentStatus, null, null, null).subscribe(function (data) {
                _this.bsModalRef.hide();
            });
        }
        else {
            this.commentIsNullError = true;
        }
    };
    return CommentIsNullComponent;
}());
export { CommentIsNullComponent };
var ViewDocumentComponent = /** @class */ (function () {
    function ViewDocumentComponent(bsModalRef, kybService, domSanitizer, translate, modalService) {
        this.bsModalRef = bsModalRef;
        this.kybService = kybService;
        this.domSanitizer = domSanitizer;
        this.translate = translate;
        this.modalService = modalService;
        this.subscriptions = [];
        this.safePictures = [];
        this.documentResponse = {};
        this.message = '';
        this.comment = '';
        this.currentStatus = '';
        this.currentText = [];
        this.currentStatusBeforeUpdatingMetaData = '';
        this.documentMetadataResponse = {};
        this.textContent = {};
        this.showImage = false;
        this.showVideo = false;
        this.showPdf = false;
        this.showJSON = false;
        this.showPhoneJSON = false;
        this.showContextualData = false;
        this.pdfSrc = '';
        this.documents = [];
        this.displayNext = false;
        this.displayPrevious = false;
        this.multiplePictures = false;
        this.currentDocument = 0;
        this.riskAssessmentType = '';
        this.highestId = 0;
    }
    ViewDocumentComponent.prototype.ngOnInit = function () {
        if (this.documents && this.documents.length > 1) {
            this.displayNext = true;
            this.displayPrevious = true;
        }
        this.currentDocument = 0;
        this.displayDocument(this.documents[this.currentDocument]);
        doc = this.documents[this.currentDocument];
        this.textContent = {};
    };
    ViewDocumentComponent.prototype.displayDocument = function (currentDocument) {
        var _this = this;
        this.showImage = false;
        this.showVideo = false;
        this.showPdf = false;
        this.showJSON = false;
        this.showPhoneJSON = false;
        if (currentDocument !== undefined) {
            if (currentDocument.documentId !== undefined) {
                this.kybService.getDocumentMetadata(currentDocument.documentId).subscribe(function (data) {
                    _this.documentMetadataResponse = data;
                    if (_this.documentMetadataResponse.data.comments.length > 0) {
                        _this.documentMetadataResponse.data.comments.map(function (entries) {
                            _this.highestId = _this.highestId > entries.id ? _this.highestId : entries.id;
                        });
                        _this.documentMetadataResponse.data.comments.map(function (elements) {
                            if (elements.id === _this.highestId) {
                                _this.lastComment = elements;
                            }
                        });
                    }
                    else {
                        _this.highestId = null;
                        _this.lastComment = null;
                    }
                    _this.currentStatus = _this.documentMetadataResponse.data.documentStatus;
                    _this.comment = _this.documentMetadataResponse.data.comment;
                    if (_this.documentMetadataResponse.data.fileIds.length > 1) {
                        _this.multiplePictures = true;
                        _this.getMultiplePictures(_this.documentMetadataResponse.data.id, _this.documentMetadataResponse.data.fileIds);
                    }
                    else {
                        _this.multiplePictures = false;
                        _this.getOnePicture(_this.documentMetadataResponse.data.id, _this.documentMetadataResponse.data.fileIds[0]);
                    }
                }, function (error) { });
            }
        }
        else {
            window.alert('You went through all the available documents.');
            this.bsModalRef.hide();
        }
    };
    ViewDocumentComponent.prototype.getOnePicture = function (documentId, fileId, multiple) {
        var _this = this;
        this.kybService.getFile(documentId, fileId).subscribe(function (data) {
            _this.documentResponse = data;
            if (_this.documentMetadataResponse.data.mimeType === 'image/jpeg' || _this.documentMetadataResponse.data.mimeType === 'image/png') {
                var pre = document.getElementsByTagName('pre');
                if (pre.length > 0) {
                    pre[0].setAttribute('style', 'display: none;');
                }
                var selfieVideo = document.getElementById('selfie-video');
                selfieVideo.setAttribute('style', 'display: none');
                if (multiple) {
                    // tslint:disable-next-line:max-line-length
                    _this.safePictures.push(_this.domSanitizer.bypassSecurityTrustResourceUrl('data:image/jpg;base64,' + _this.documentResponse.data.content));
                }
                else {
                    // tslint:disable-next-line:max-line-length
                    _this.safePicture = _this.domSanitizer.bypassSecurityTrustResourceUrl('data:image/jpg;base64,' + _this.documentResponse.data.content);
                }
                _this.showImage = true;
                _this.showVideo = false;
                _this.showContextualData = false;
                _this.showPdf = false;
                _this.showJSON = false;
                _this.showPhoneJSON = false;
            }
            else if (_this.documentMetadataResponse.data.mimeType === 'application/pdf') {
                var pre = document.getElementsByTagName('pre');
                if (pre.length > 0) {
                    pre[0].setAttribute('style', 'display: none;');
                }
                var selfieVideo = document.getElementById('selfie-video');
                selfieVideo.setAttribute('style', 'display: none');
                _this.showPdf = true;
                _this.downloadPDF(_this.documentResponse.data.content);
                _this.showImage = false;
                _this.showVideo = false;
                _this.showContextualData = false;
                _this.showJSON = false;
                _this.showPhoneJSON = false;
            }
            else if (_this.documentMetadataResponse.data.mimeType === 'text/plain' || _this.documentMetadataResponse.data.mimeType === 'custom/form') {
                var pre = document.getElementsByTagName('pre');
                if (pre.length > 0) {
                    pre[0].setAttribute('style', 'display: block;');
                }
                var selfieVideo = document.getElementById('selfie-video');
                if (selfieVideo) {
                    selfieVideo.setAttribute('style', 'display: none');
                }
                _this.textContent = JSON.parse(_this.decodeBase64(_this.documentResponse.data.content));
                // Removing empty key-value pairs from JSON object
                for (var key in _this.textContent) {
                    if (_this.textContent.hasOwnProperty(key)) {
                        var value = _this.textContent[key];
                        if (value.toString() === '') {
                            delete _this.textContent[key];
                        }
                        if (_this.textContent[key] && typeof _this.textContent[key] === 'object') {
                            if (Object.keys(_this.textContent[key])[0] === 'text') {
                                _this.textContent[key] = Object.values(_this.textContent[key])[0];
                            }
                            else {
                                Object.assign(_this.textContent, _this.textContent[key]);
                                delete _this.textContent[key];
                            }
                        }
                    }
                }
                _this.currentText.push(_this.textContent);
                _this.showImage = false;
                _this.showVideo = false;
                _this.showContextualData = false;
                _this.showPdf = false;
                _this.showJSON = true;
                _this.showPhoneJSON = false;
            }
            else if (_this.documentMetadataResponse.data.mimeType === 'application/json') {
                var pre = document.getElementsByTagName('pre');
                if (pre.length > 0) {
                    pre[0].setAttribute('style', 'display: block;');
                }
                var selfieVideo = document.getElementById('selfie-video');
                if (selfieVideo) {
                    selfieVideo.setAttribute('style', 'display: none');
                }
                _this.textContent = JSON.parse(_this.decodeBase64(_this.documentResponse.data.content));
                // Removing empty key-value pairs from JSON object
                for (var key in _this.textContent) {
                    if (_this.textContent.hasOwnProperty(key)) {
                        var value = _this.textContent[key];
                        if (value !== null) {
                            if (value.toString() === '') {
                                delete _this.textContent[key];
                            }
                        }
                        if (_this.textContent[key] && typeof _this.textContent[key] === 'object') {
                            if (Object.keys(_this.textContent[key])[0] === 'text') {
                                _this.textContent[key] = Object.values(_this.textContent[key])[0];
                            }
                            else {
                                Object.assign(_this.textContent, _this.textContent[key]);
                                delete _this.textContent[key];
                            }
                        }
                    }
                }
                _this.currentText.push(_this.textContent);
                _this.showImage = false;
                _this.showVideo = false;
                _this.showContextualData = false;
                _this.showPdf = false;
                _this.showJSON = true;
                _this.showPhoneJSON = false;
                if (_this.documentMetadataResponse.data.documentType.documentTypeName === 'riskAssessment') {
                    _this.showJSON = false;
                    _this.showPhoneJSON = true;
                    _this.riskAssessmentType = _this.textContent.assessmentType;
                }
            }
            else if (_this.documentMetadataResponse.data.mimeType === 'video/mpeg' || _this.documentMetadataResponse.data.mimeType === 'video/webm') {
                var pre = document.getElementsByTagName('pre');
                if (pre.length > 0) {
                    pre[0].setAttribute('style', 'display: none;');
                }
                var selfieVideo = document.getElementById('selfie-video');
                _this.safeVideo = 'data:video/webm;base64,' + _this.documentResponse.data.content;
                selfieVideo.src = _this.safeVideo;
                selfieVideo.setAttribute('style', 'display: inline');
                _this.showImage = false;
                _this.showVideo = true;
                _this.showContextualData = true;
                _this.showPdf = false;
                _this.showJSON = false;
                _this.showPhoneJSON = false;
            }
            else if (_this.documentMetadataResponse.data.mimeType === 'video/mp4') {
                var pre = document.getElementsByTagName('pre');
                if (pre.length > 0) {
                    pre[0].setAttribute('style', 'display: none;');
                }
                var selfieVideo = document.getElementById('selfie-video');
                _this.safeVideo = 'data:video/mp4;base64,' + _this.documentResponse.data.content;
                selfieVideo.src = _this.safeVideo;
                selfieVideo.setAttribute('style', 'display: inline');
                _this.showImage = false;
                _this.showVideo = true;
                _this.showContextualData = true;
                _this.showPdf = false;
                _this.showJSON = false;
                _this.showPhoneJSON = false;
            }
            else {
                var pre = document.getElementsByTagName('pre');
                var selfieVideo = document.getElementById('selfie-video');
                selfieVideo.setAttribute('style', 'display: none');
                if (pre.length > 0) {
                    pre[0].setAttribute('style', 'display: none;');
                }
                _this.message = 'Unrecognized MIME type.';
                _this.showImage = false;
                _this.showVideo = false;
                _this.showContextualData = false;
                _this.showPdf = false;
                _this.showJSON = false;
                _this.showPhoneJSON = false;
            }
        }, function (error) { });
    };
    ViewDocumentComponent.prototype.decodeBase64 = function (base64) {
        var text = atob(base64);
        var length = text.length;
        var bytes = new Uint8Array(length);
        for (var i = 0; i < length; i++) {
            bytes[i] = text.charCodeAt(i);
        }
        var decoder = new TextDecoder(); // default is utf-8
        return decoder.decode(bytes);
    };
    ViewDocumentComponent.prototype.viewNullCommentPopup = function () {
        var _this = this;
        this.viewModal = this.modalService.show(CommentIsNullComponent, { backdrop: 'static' });
        this.subscriptions.push(this.modalService.onHide.subscribe(function (message) {
            _this.unsubscribe();
        }));
    };
    ViewDocumentComponent.prototype.unsubscribe = function () {
        this.subscriptions.forEach(function (subscription) {
            subscription.unsubscribe();
        });
        this.subscriptions = [];
    };
    ViewDocumentComponent.prototype.getMultiplePictures = function (documentId, fileIds) {
        var _this = this;
        this.textContent = {};
        fileIds.forEach(function (fileId) {
            _this.getOnePicture(documentId, fileId, true);
        });
    };
    ViewDocumentComponent.prototype.goToPrevious = function () {
        this.textContent = {};
        if (this.currentDocument !== 0) {
            this.currentDocument--;
            this.displayDocument(this.documents[this.currentDocument]);
        }
        else {
            this.currentDocument = this.documents.length + 1;
            this.displayDocument(this.documents[this.currentDocument]);
        }
    };
    ViewDocumentComponent.prototype.goToNext = function () {
        this.textContent = {};
        if (this.currentDocument !== this.documents.length + 1) {
            this.currentDocument++;
            this.displayDocument(this.documents[this.currentDocument]);
        }
        else {
            this.currentDocument = 0;
            this.displayDocument(this.documents[this.currentDocument]);
        }
    };
    ViewDocumentComponent.prototype.accept = function (documentStatus) {
        var _this = this;
        this.currentStatusBeforeUpdatingMetaData = documentStatus;
        // tslint:disable-next-line:max-line-length
        this.kybService.updateDocumentMetadata(this.documents[this.currentDocument].documentId, this.comment, this.currentStatusBeforeUpdatingMetaData ? this.currentStatusBeforeUpdatingMetaData : this.currentStatus, null, null, null).subscribe(function (data) {
            if (_this.documents.length === 1) {
                _this.ngOnInit();
                if (_this.comment) {
                    _this.bsModalRef.hide();
                }
                else {
                    _this.bsModalRef.hide();
                    docStatus = "" + (_this.currentStatusBeforeUpdatingMetaData ? _this.currentStatusBeforeUpdatingMetaData : _this.currentStatus);
                    _this.viewNullCommentPopup();
                }
            }
            else {
                _this.currentStatusBeforeUpdatingMetaData = '';
                _this.goToNext();
            }
        }, function (error) { });
    };
    ViewDocumentComponent.prototype.escalate = function (documentStatus) {
        var _this = this;
        this.currentStatusBeforeUpdatingMetaData = documentStatus;
        // tslint:disable-next-line:max-line-length
        this.kybService.updateDocumentMetadata(this.documents[this.currentDocument].documentId, this.comment, this.currentStatusBeforeUpdatingMetaData ? this.currentStatusBeforeUpdatingMetaData : this.currentStatus, null, null, null).subscribe(function (data) {
            if (_this.documents.length === 1) {
                _this.ngOnInit();
                if (_this.comment) {
                    _this.bsModalRef.hide();
                }
                else {
                    _this.bsModalRef.hide();
                    docStatus = "" + (_this.currentStatusBeforeUpdatingMetaData ? _this.currentStatusBeforeUpdatingMetaData : _this.currentStatus);
                    _this.viewNullCommentPopup();
                }
            }
            else {
                _this.currentStatusBeforeUpdatingMetaData = '';
                _this.goToNext();
            }
        }, function (error) { });
    };
    ViewDocumentComponent.prototype.reject = function (documentStatus) {
        var _this = this;
        this.currentStatusBeforeUpdatingMetaData = documentStatus;
        // tslint:disable-next-line:max-line-length
        this.kybService.updateDocumentMetadata(this.documents[this.currentDocument].documentId, this.comment, this.currentStatusBeforeUpdatingMetaData ? this.currentStatusBeforeUpdatingMetaData : this.currentStatus, null, null, null).subscribe(function (data) {
            if (_this.documents.length === 1) {
                _this.ngOnInit();
                if (_this.comment) {
                    _this.bsModalRef.hide();
                }
                else {
                    _this.bsModalRef.hide();
                    docStatus = "" + (_this.currentStatusBeforeUpdatingMetaData ? _this.currentStatusBeforeUpdatingMetaData : _this.currentStatus);
                    _this.viewNullCommentPopup();
                }
            }
            else {
                _this.currentStatusBeforeUpdatingMetaData = '';
                _this.goToNext();
            }
        }, function (error) { });
    };
    ViewDocumentComponent.prototype.updateMetaData = function () {
        var _this = this;
        // tslint:disable-next-line:max-line-length
        this.kybService.updateDocumentMetadata(this.documents[this.currentDocument].documentId, this.comment, this.currentStatusBeforeUpdatingMetaData ? this.currentStatusBeforeUpdatingMetaData : this.currentStatus, null, null, null).subscribe(function (data) {
            if (_this.documents.length === 1) {
                _this.ngOnInit();
                if (_this.comment) {
                    _this.bsModalRef.hide();
                }
                else {
                    _this.bsModalRef.hide();
                    docStatus = "" + (_this.currentStatusBeforeUpdatingMetaData ? _this.currentStatusBeforeUpdatingMetaData : _this.currentStatus);
                    _this.viewNullCommentPopup();
                }
            }
            else {
                _this.currentStatusBeforeUpdatingMetaData = '';
                _this.goToNext();
            }
        }, function (error) { });
    };
    ViewDocumentComponent.prototype.downloadPDF = function (pdfString) {
        var linkSource = 'data:application/pdf;base64,' + pdfString;
        this.pdfSrc = linkSource;
    };
    ViewDocumentComponent.prototype.viewFullPdf = function () {
        var byteCharacters = atob(this.pdfSrc.split('data:application/pdf;base64,')[1]);
        var byteNumbers = new Array(byteCharacters.length);
        for (var i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        var byteArray = new Uint8Array(byteNumbers);
        var file = new Blob([byteArray], { type: 'application/pdf;base64' });
        var fileURL = URL.createObjectURL(file);
        window.open(fileURL);
    };
    return ViewDocumentComponent;
}());
export { ViewDocumentComponent };
var ShowCommentsComponent = /** @class */ (function () {
    function ShowCommentsComponent(bsModalRef, kybService) {
        this.bsModalRef = bsModalRef;
        this.kybService = kybService;
        this.comments = [];
        this.entityResponse = {};
        this.newComment = '';
    }
    ShowCommentsComponent.prototype.onKeyupHandler = function (event) {
        this.save();
    };
    ShowCommentsComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.comments = [];
        this.entityResponse = {};
        this.kybService.getComments(this.entityId).subscribe(function (data) {
            _this.entityResponse = data;
            // sorted newest at top
            _this.comments = _this.entityResponse.data.sort(function (a, b) { return (a.id < b.id) ? 1 : -1; });
        });
    };
    ShowCommentsComponent.prototype.save = function () {
        var _this = this;
        this.kybService.addComment(this.entityId, this.newComment).subscribe(function (data) {
            _this.newComment = '';
            _this.ngOnInit();
        });
    };
    return ShowCommentsComponent;
}());
export { ShowCommentsComponent };
var RenameEntityComponent = /** @class */ (function () {
    function RenameEntityComponent(bsModalRef, kybService, alertService) {
        this.bsModalRef = bsModalRef;
        this.kybService = kybService;
        this.alertService = alertService;
        this.newPrimary = '';
    }
    RenameEntityComponent.prototype.ngOnInit = function () {
        this.disableRenameBtn = new FormGroup({
            newName: new FormControl(null, Validators.required),
        });
    };
    RenameEntityComponent.prototype.rename = function () {
        var _this = this;
        if (window.confirm('Are you sure you want to rename this entity?')) {
            // tslint:disable-next-line:max-line-length
            this.kybService.renameEntity(this.entityId, this.entityTypeId, this.primaryName, this.newPrimary).subscribe(function (data) {
                _this.bsModalRef.hide();
            }, function (error) {
                _this.alertService.showError('Entity could not be renamed. Please try again.');
            });
        }
    };
    return RenameEntityComponent;
}());
export { RenameEntityComponent };
var ManualTotalRiskAdjustmentComponent = /** @class */ (function () {
    function ManualTotalRiskAdjustmentComponent(bsModalRef, kybService, alertService) {
        this.bsModalRef = bsModalRef;
        this.kybService = kybService;
        this.alertService = alertService;
        this.justification = '';
        this.riskAnalysisResponse = {};
        this.mediumRiskThreshold = 0;
        this.highRiskThreshold = 0;
        this.unacceptableRiskThreshold = 0;
        this.loading = false;
        this.risk = 0;
    }
    ManualTotalRiskAdjustmentComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.kybService.getRiskAnalysisRules().subscribe(function (data) {
            _this.riskAnalysisResponse = data;
            _this.mediumRiskThreshold = _this.riskAnalysisResponse.data.mediumRiskThreshold;
            _this.highRiskThreshold = _this.riskAnalysisResponse.data.highRiskThreshold;
            _this.unacceptableRiskThreshold = _this.riskAnalysisResponse.data.unacceptableRiskThreshold;
        });
    };
    ManualTotalRiskAdjustmentComponent.prototype.cancel = function () {
        this.bsModalRef.hide();
    };
    ManualTotalRiskAdjustmentComponent.prototype.adjustAndLog = function () {
        var _this = this;
        this.loading = true;
        if (this.riskLevel === 'LOW') {
            this.risk = 0;
        }
        else if (this.riskLevel === 'MEDIUM') {
            this.risk = this.mediumRiskThreshold;
        }
        else if (this.riskLevel === 'HIGH') {
            this.risk = this.highRiskThreshold;
        }
        else {
            this.risk = this.unacceptableRiskThreshold;
        }
        this.kybService.updateAssessedRisk(this.entityId, this.risk, null).subscribe(function (data) {
            _this.kybService.addComment(parseInt(_this.entityId, 10), 'User ' + localStorage.getItem('username') + ' updated custom assessed risk of entity to ' + _this.riskLevel + '. Justification: ' + _this.justification + '.').subscribe(function (entityData) {
                _this.loading = false;
                _this.alertService.showSuccess('Risk successfully adjusted.');
                _this.bsModalRef.hide();
            }, function (error) {
                _this.loading = false;
            });
        }, function (error) {
            _this.loading = false;
            _this.alertService.showError(error.error.errors[0].message);
        });
    };
    return ManualTotalRiskAdjustmentComponent;
}());
export { ManualTotalRiskAdjustmentComponent };
var ManualPEPRiskAdjustmentComponent = /** @class */ (function () {
    function ManualPEPRiskAdjustmentComponent(bsModalRef, kybService, alertService) {
        this.bsModalRef = bsModalRef;
        this.kybService = kybService;
        this.alertService = alertService;
        this.justification = '';
        this.riskLevel = '';
        this.riskAnalysisResponse = {};
        this.mediumRiskThreshold = 0;
        this.highRiskThreshold = 0;
        this.unacceptableRiskThreshold = 0;
        this.loading = false;
    }
    ManualPEPRiskAdjustmentComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.kybService.getRiskAnalysisRules().subscribe(function (data) {
            _this.riskAnalysisResponse = data;
            _this.mediumRiskThreshold = _this.riskAnalysisResponse.data.mediumRiskThreshold;
            _this.highRiskThreshold = _this.riskAnalysisResponse.data.highRiskThreshold;
            _this.unacceptableRiskThreshold = _this.riskAnalysisResponse.data.unacceptableRiskThreshold;
        });
    };
    ManualPEPRiskAdjustmentComponent.prototype.cancel = function () {
        this.bsModalRef.hide();
    };
    ManualPEPRiskAdjustmentComponent.prototype.adjustAndLog = function () {
        var _this = this;
        this.loading = true;
        if (this.riskLevel === 'LOW') {
            this.risk = 0;
        }
        else if (this.riskLevel === 'MEDIUM') {
            this.risk = this.mediumRiskThreshold;
        }
        else if (this.riskLevel === 'HIGH') {
            this.risk = this.highRiskThreshold;
        }
        else {
            this.risk = this.unacceptableRiskThreshold;
        }
        this.kybService.updateAssessedRisk(this.entityId, null, this.risk).subscribe(function (data) {
            _this.kybService.addComment(parseInt(_this.entityId, 10), 'User ' + localStorage.getItem('username') + ' updated custom PEP risk of entity to ' + _this.riskLevel + '. Justification: ' + _this.justification + '.').subscribe(function (entityData) {
                _this.loading = false;
                _this.alertService.showSuccess('Risk successfully adjusted.');
                _this.bsModalRef.hide();
            }, function (error) {
                _this.loading = false;
            });
        }, function (error) {
            _this.loading = false;
            _this.alertService.showError(error.error.errors[0].message);
        });
    };
    return ManualPEPRiskAdjustmentComponent;
}());
export { ManualPEPRiskAdjustmentComponent };
var RemoveRiskAdjustmentComponent = /** @class */ (function () {
    function RemoveRiskAdjustmentComponent(bsModalRef, kybService, alertService) {
        this.bsModalRef = bsModalRef;
        this.kybService = kybService;
        this.alertService = alertService;
        this.justification = '';
        this.assessedRisk = false;
        this.pepRisk = false;
        this.loading = false;
    }
    RemoveRiskAdjustmentComponent.prototype.ngOnInit = function () { };
    RemoveRiskAdjustmentComponent.prototype.cancel = function () {
        this.bsModalRef.hide();
    };
    RemoveRiskAdjustmentComponent.prototype.adjustAndLog = function () {
        var _this = this;
        this.loading = true;
        if (this.type === 'PEP') {
            this.assessedRisk = false;
            this.pepRisk = true;
        }
        else {
            this.assessedRisk = true;
            this.pepRisk = false;
        }
        this.kybService.deleteAssessedRisk(this.entityId, this.assessedRisk, this.pepRisk).subscribe(function (data) {
            // tslint:disable-next-line:max-line-length
            _this.kybService.addComment(parseInt(_this.entityId, 10), 'User ' + localStorage.getItem('username') + ' deleted custom ' + _this.type + ' risk of entity. Justification: ' + _this.justification + '.').subscribe(function (entityData) {
                _this.loading = false;
                _this.alertService.showSuccess('Risk successfully adjusted.');
                _this.bsModalRef.hide();
            }, function (error) {
                _this.loading = false;
                _this.alertService.showError(error.error.errors[0].message);
            });
        }, function (error) {
            _this.loading = false;
        });
    };
    return RemoveRiskAdjustmentComponent;
}());
export { RemoveRiskAdjustmentComponent };
/**
 * Implements the user detail page
*/
var DetailComponent = /** @class */ (function () {
    function DetailComponent(callsService, alertService, route, domSanitizer, modalService, translate, dataService, kybService, router, configService) {
        var _this = this;
        this.callsService = callsService;
        this.alertService = alertService;
        this.route = route;
        this.domSanitizer = domSanitizer;
        this.modalService = modalService;
        this.translate = translate;
        this.dataService = dataService;
        this.kybService = kybService;
        this.router = router;
        this.configService = configService;
        this.subscriptions = [];
        this.exclamationImage = '../../../assets/images/exclamation.png';
        this.model = {};
        this.loading = false;
        this.controlloLoading = false;
        this.loadingImg = 'data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==';
        this.progress = 22;
        this.username = '';
        this.response = {};
        this.slideIndex = 1;
        this.loadingCheck = false;
        this.htmlInput = '';
        this.exportStatus = '';
        this.exportPEPScore = '';
        this.exportSanctionScore = '';
        this.exportMRZ = '';
        this.exportDob = '';
        this.exportDoe = '';
        this.primaryName = '';
        this.entityTypeId = '';
        this.mainColor = config.mainColor;
        this.words = [];
        this.returnWords = [];
        this.wordsObject = {};
        this.corporateRiskAverage = 0;
        this.documentIds = [];
        this.customOverallRisk = 0;
        this.showRiskEdit = false;
        this.entityComment = '';
        this.emptyUBO = false;
        this.emptyControl = false;
        this.documentFound = false;
        this.initDocumentFound = false;
        this.displayEmailOnboardingTrigger = true;
        this.displayPhoneNumberOnboardingTrigger = true;
        this.displayAddRole = true;
        this.displayFullEDDReport = true;
        this.displayAddGeneralDocument = true;
        this.displayRolesAndDocumentation = true;
        this.displayValidUntil = true;
        this.displayPersonalContactFormAndData = true;
        this.displayMRZ = true;
        this.displayDeleteDocument = true;
        this.displayEditDocument = true;
        this.displayReplaceDocument = true;
        this.displayEvaluateAllDocuments = true;
        this.displayControlPanel = false;
        this.basicInformation = {};
        this.relationResponse = {};
        this.entityResponse = {};
        this.rolesResponse = {};
        this.connectedRoles = [];
        this.getRoleResponse = {};
        this.documentContentResponse = {};
        this.entityName = '';
        this.entityType = '';
        this.entityId = '';
        this.rolesDisplay = [];
        this.riskAnalysisResponse = {};
        this.riskAnalysis = {};
        this.corporateRisk = 0;
        this.eventResponse = {};
        this.eventLog = [];
        this.documents = [];
        this.allDocuments = [];
        this.toDoListResponse = {};
        this.toDo = [];
        this.timestampFrom = null;
        this.timestampTo = null;
        this.UBOResponse = {};
        this.UBOdata = [];
        this.controlResponse = {};
        this.pageSize = 30;
        this.currentPage = 0;
        this.sortingOption = 'timestamp,desc';
        this.createdBy = null;
        this.selectedTags = [];
        this.tags = [];
        this.tagsResponse = {};
        this.createTagResponse = {};
        this.isOwner = false;
        this.jurisdictionCounter = 0;
        this.analysisRulesResponse = {};
        this.mediumRiskThreshold = 0;
        this.highRiskThreshold = 0;
        this.unacceptableRiskThreshold = 0;
        this.numberOfModals = 0;
        this.showEditInformation = false;
        this.documentResponse = {};
        this.safeDocuments = [];
        this.totalRisk = 0;
        this.totalRiskLevel = '';
        this.longestSubstring = '';
        this.helperWords = [];
        this.usersToReview = [];
        this.relationshipGraphData = [];
        this.controls = [];
        this.controlData = [];
        this.controlNodes = [];
        this.owners = [];
        this.uboData = [];
        this.uboNodes = [];
        this.entityLevel = 0;
        this.titles = [];
        this.uboLevels = [];
        this.nodes = [];
        this.newChilds = [];
        this.counter = 0;
        this.onboardingLoading = false;
        this.userPermissions = [];
        this.deleteEntityResponse = {};
        this.commentsResponse = {};
        this.comments = [];
        if (configService.get('onboardingLanguage') !== undefined) {
            this.translate.setDefaultLang(configService.get('onboardingLanguage'));
        }
        else {
            this.translate.setDefaultLang('en');
        }
        this.route.params.subscribe(function (params) {
            if (params.username) {
                _this.username = params.username;
            }
        });
        if (configService.get('displayEmailOnboardingTrigger') !== undefined) {
            this.displayEmailOnboardingTrigger = configService.get('displayEmailOnboardingTrigger');
        }
        if (configService.get('displayPhoneNumberOnboardingTrigger') !== undefined) {
            this.displayPhoneNumberOnboardingTrigger = configService.get('displayPhoneNumberOnboardingTrigger');
        }
        if (configService.get('displayAddRole') !== undefined) {
            this.displayAddRole = configService.get('displayAddRole');
        }
        if (configService.get('displayFullEDDReport') !== undefined) {
            this.displayFullEDDReport = configService.get('displayFullEDDReport');
        }
        if (configService.get('displayAddGeneralDocument') !== undefined) {
            this.displayAddGeneralDocument = configService.get('displayAddGeneralDocument');
        }
        if (configService.get('displayRolesAndDocumentation') !== undefined) {
            this.displayRolesAndDocumentation = configService.get('displayRolesAndDocumentation');
        }
        if (configService.get('displayValidUntil') !== undefined) {
            this.displayValidUntil = configService.get('displayValidUntil');
        }
        if (configService.get('displayPersonalContactFormAndData') !== undefined) {
            this.displayPersonalContactFormAndData = configService.get('displayPersonalContactFormAndData');
        }
        if (configService.get('displayMRZ') !== undefined) {
            this.displayMRZ = configService.get('displayMRZ');
        }
        if (configService.get('displayDeleteDocument') !== undefined) {
            this.displayDeleteDocument = configService.get('displayDeleteDocument');
        }
        if (configService.get('displayEditDocument') !== undefined) {
            this.displayEditDocument = configService.get('displayEditDocument');
        }
        if (configService.get('displayReplaceDocument') !== undefined) {
            this.displayReplaceDocument = configService.get('displayReplaceDocument');
        }
        if (configService.get('displayEvaluateAllDocuments') !== undefined) {
            this.displayEvaluateAllDocuments = configService.get('displayEvaluateAllDocuments');
        }
        if (configService.get('displayControlPanel') !== undefined) {
            this.displayControlPanel = configService.get('displayControlPanel');
        }
        this.routerSub = this.router.events.subscribe(function (event) {
            // detect Back button click and refresh values
            if (event.navigationTrigger === 'popstate') {
                _this.totalRisk = 0;
                _this.totalRiskLevel = '';
                _this.riskAnalysis = {};
                _this.riskAnalysisResponse = {};
                if (event.url.indexOf('detail') !== -1) {
                    _this.username = event.url.split('/')[2];
                    _this.ngOnInit();
                }
            }
        });
    }
    DetailComponent.prototype.ngOnDestroy = function () {
        this.routerSub.unsubscribe();
    };
    DetailComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.totalRisk = 0;
        this.totalRiskLevel = '';
        this.kybService.getRiskAnalysisRules().subscribe(function (data) {
            _this.analysisRulesResponse = data;
            _this.mediumRiskThreshold = _this.analysisRulesResponse.data.mediumRiskThreshold;
            _this.highRiskThreshold = _this.analysisRulesResponse.data.highRiskThreshold;
            _this.unacceptableRiskThreshold = _this.analysisRulesResponse.data.unacceptableRiskThreshold;
        });
        this.kybService.getUserPermissions().subscribe(function (value) { return _this.userPermissions = value.data.permissions; });
        this.timestampTo = new Date();
        this.timestampFrom = new Date();
        this.timestampFrom.setDate(this.timestampFrom.getDate() - 20000);
        this.timestampFrom = this.timestampFrom.toISOString();
        this.timestampTo = this.timestampTo.toISOString();
        this.uboData = [];
        this.UBOdata = [];
        this.uboLevels = [];
        this.uboNodes = [];
        this.controlData = [];
        this.controlNodes = [];
        this.allDocuments = [];
        this.corporateRiskAverage = 0;
        this.counter = 0;
        this.corporateRisk = 0;
        this.connectedRoles = [];
        this.rolesDisplay = [];
        this.kybService.getAllTags().subscribe(function (data) {
            _this.tagsResponse = data;
            _this.tags = _this.tagsResponse.data;
        });
        this.kybService.getEntity(this.username).subscribe(function (data) {
            if (data && data.errors && data.errors.length) {
                window.location.pathname = '/404';
            }
            _this.entityResponse = data;
            _this.entityName = _this.entityResponse.data.primaryName;
            _this.primaryName = _this.entityResponse.data.primaryName;
            _this.entityType = _this.entityResponse.data.entityType.entityType;
            _this.entityTypeId = _this.entityResponse.data.entityType.id;
            _this.entityId = _this.entityResponse.data.id;
            _this.kybService.getEntityTags(_this.entityId).subscribe(function (tags) {
                _this.entityTagResponse = tags;
                if (_this.entityTagResponse.data.length > 0) {
                    _this.selectedTags = [];
                    _this.entityTagResponse.data.forEach(function (obj) {
                        _this.selectedTags.push(obj);
                    });
                }
            });
            if (data && data.data) {
                _this.isOwner = data.data.owner.identifier === localStorage.identifier;
            }
            if (_this.entityResponse.data.entityStatus2E == null) {
                _this.entityResponse.data.entityStatus2E = 'IN_REVIEW';
            }
            _this.dataService.setCurrentEntityId(_this.entityId);
            _this.relationResponse = {};
            _this.kybService.getRelationships(_this.entityResponse.data.id).subscribe(function (relData) {
                _this.relationResponse = relData;
                _this.drawRelationshipGraph(_this.relationResponse.data.roles);
                _this.prepareOrganizationalChart(_this.relationResponse.data.roles, []);
            }, function (error) { });
            _this.kybService.getUBOGraph(_this.username, 'control').subscribe(function (controlUBOdata) {
                _this.controlResponse = controlUBOdata;
                _this.drawControlChart(_this.controlResponse.data);
                _this.drawNewControlChart(_this.controlResponse.data);
            }, function (error) { });
            _this.kybService.getUBOGraph(_this.username, 'owner').subscribe(function (ownerUBOdata) {
                _this.UBOResponse = ownerUBOdata;
                _this.drawUBOChart(_this.UBOResponse.data);
                _this.drawNewUBOChart(_this.UBOResponse.data);
            }, function (error) { });
            _this.kybService.getComments(parseInt(_this.entityId, 10)).subscribe(function (commentData) {
                _this.commentsResponse = commentData;
                _this.comments = _this.commentsResponse.data.sort(function (a, b) { return (a.id < b.id) ? 1 : -1; });
            });
        }, function (error) {
            window.location.pathname = '/404';
        });
        this.refreshRiskIndicators();
        this.getRolesForEntity(this.username);
        // tslint:disable-next-line:max-line-length
        this.kybService.getEventsPaginated(this.pageSize, this.currentPage, this.sortingOption, this.timestampFrom, this.timestampTo, this.createdBy, this.username).subscribe(function (res) {
            // tslint:disable-next-line:max-line-length
            _this.eventResponse = { data: tslib_1.__assign({}, res.data.page, { totalElements: res.data.totalElements, numberOfElements: res.data.totalElements }) };
            _this.eventLog = res.data.page;
        }, function (error) { });
        this.route.fragment.subscribe(function (f) {
            var element = document.querySelector('#' + f);
            if (element) {
                element.scrollIntoView();
            }
        });
        this.kybService.getToDoList(this.username).subscribe(function (data) {
            _this.toDoListResponse = data;
            _this.toDo = _this.toDoListResponse.data.todos;
        }, function (error) {
        });
    };
    DetailComponent.prototype.isAddDocumentEntityId = function (prevToDo, currentToDo, nextToDo) {
        return (currentToDo && nextToDo && currentToDo.documentGroup === nextToDo.documentGroup) ||
            (!nextToDo && prevToDo && currentToDo && prevToDo.documentGroup === currentToDo.documentGroup) ||
            (!nextToDo && !prevToDo);
    };
    DetailComponent.prototype.isAddThisDocumentEntityId = function (prevToDo, currentToDo, nextToDo) {
        return (currentToDo && nextToDo && currentToDo.documentGroup !== nextToDo.documentGroup) ||
            (!nextToDo && prevToDo && currentToDo && prevToDo.documentGroup !== currentToDo.documentGroup);
    };
    DetailComponent.prototype.isAddDocumentRoleId = function (prevToDo, currentToDo, nextToDo) {
        return (currentToDo && nextToDo && currentToDo.documentGroup === nextToDo.documentGroup) ||
            (!nextToDo && prevToDo && currentToDo && prevToDo.documentGroup === currentToDo.documentGroup);
    };
    DetailComponent.prototype.isAddThisDocumentRoleId = function (prevToDo, currentToDo, nextToDo) {
        return (currentToDo && nextToDo && currentToDo.documentGroup !== nextToDo.documentGroup) ||
            (!nextToDo && prevToDo && currentToDo && prevToDo.documentGroup !== currentToDo.documentGroup) ||
            (!nextToDo && !prevToDo);
    };
    DetailComponent.prototype.getRolesForEntity = function (username) {
        var _this = this;
        this.kybService.getRolesForEntity(username).subscribe(function (data) {
            _this.rolesResponse = data;
            _this.connectedRoles = _this.rolesResponse.data;
            _this.rolesDisplay = [];
            for (var i = 0; i < _this.connectedRoles.length; i++) {
                _this.documents = [];
                for (var k = 0; k < _this.connectedRoles[i].requiredDocuments.length; k++) {
                    var expired = !(_this.connectedRoles[i].requiredDocuments[k].notExpired);
                    // tslint:disable-next-line:max-line-length
                    if (_this.connectedRoles[i].requiredDocuments[k].documentType.documentTypeName === 'personalContact' || _this.connectedRoles[i].requiredDocuments[k].documentType.documentTypeName === 'companyContactForm') {
                        // tslint:disable-next-line:max-line-length
                        if (_this.connectedRoles[i].requiredDocuments[k].documentStatus !== 'REPLACED' && _this.connectedRoles[i].requiredDocuments[k].documentStatus !== 'DELETED' && _this.connectedRoles[i].requiredDocuments[k].documentStatus !== 'EMPTY') {
                            _this.showEditInformation = true;
                        }
                    }
                    if (_this.connectedRoles[i].requiredDocuments[k].documentStatus !== 'EMPTY') {
                        // tslint:disable-next-line:max-line-length
                        _this.documents.push({ 'name': _this.connectedRoles[i].requiredDocuments[k].documentType.description, 'description': _this.connectedRoles[i].requiredDocuments[k].description, 'fileId': _this.connectedRoles[i].requiredDocuments[k].fileId, 'mimeType': _this.connectedRoles[i].requiredDocuments[k].mimeType, 'expires': _this.connectedRoles[i].requiredDocuments[k].validTo, 'documentId': _this.connectedRoles[i].requiredDocuments[k].id, 'parentDocumentId': _this.connectedRoles[i].requiredDocuments[k].parentDocumentId, 'status': _this.connectedRoles[i].requiredDocuments[k].documentStatus, 'riskLevel': _this.connectedRoles[i].requiredDocuments[k].riskStatus, 'documentTypeId': _this.connectedRoles[i].requiredDocuments[k].documentType.id, 'expired': expired, 'contextualData': _this.connectedRoles[i].requiredDocuments[k].contextualData });
                        // tslint:disable-next-line:max-line-length
                        _this.allDocuments.push({ 'name': _this.connectedRoles[i].requiredDocuments[k].documentType.description, 'description': _this.connectedRoles[i].requiredDocuments[k].description, 'fileId': _this.connectedRoles[i].requiredDocuments[k].fileId, 'mimeType': _this.connectedRoles[i].requiredDocuments[k].mimeType, 'expires': _this.connectedRoles[i].requiredDocuments[k].validTo, 'documentId': _this.connectedRoles[i].requiredDocuments[k].id, 'status': _this.connectedRoles[i].requiredDocuments[k].documentStatus, 'riskLevel': _this.connectedRoles[i].requiredDocuments[k].riskStatus, 'documentTypeId': _this.connectedRoles[i].requiredDocuments[k].documentType.id, 'expired': expired, 'contextualData': _this.connectedRoles[i].requiredDocuments[k].contextualData });
                    }
                }
                for (var j = 0; j < _this.connectedRoles[i].additionalDocuments.length; j++) {
                    var expired = !(_this.connectedRoles[i].additionalDocuments[j].notExpired);
                    // tslint:disable-next-line:max-line-length
                    if (_this.connectedRoles[i].additionalDocuments[j].documentType.documentTypeName === 'personalContact' || _this.connectedRoles[i].additionalDocuments[j].documentType.documentTypeName === 'companyContactForm') {
                        // tslint:disable-next-line:max-line-length
                        if (_this.connectedRoles[i].additionalDocuments[j].documentStatus !== 'REPLACED' && _this.connectedRoles[i].additionalDocuments[j].documentStatus !== 'DELETED' && _this.connectedRoles[i].additionalDocuments[j].documentStatus !== 'EMPTY') {
                            _this.showEditInformation = true;
                        }
                    }
                    if (_this.connectedRoles[i].additionalDocuments[j].documentStatus !== 'EMPTY') {
                        // tslint:disable-next-line:max-line-length
                        _this.documents.push({ 'name': _this.connectedRoles[i].additionalDocuments[j].documentType.description, 'description': _this.connectedRoles[i].additionalDocuments[j].description, 'fileId': _this.connectedRoles[i].additionalDocuments[j].fileId, 'mimeType': _this.connectedRoles[i].additionalDocuments[j].mimeType, 'expires': _this.connectedRoles[i].additionalDocuments[j].validTo, 'documentId': _this.connectedRoles[i].additionalDocuments[j].id, 'parentDocumentId': _this.connectedRoles[i].additionalDocuments[j].parentDocumentId, 'status': _this.connectedRoles[i].additionalDocuments[j].documentStatus, 'riskLevel': _this.connectedRoles[i].additionalDocuments[j].riskStatus, 'documentTypeId': _this.connectedRoles[i].additionalDocuments[j].documentType.id, 'expired': expired, 'contextualData': _this.connectedRoles[i].additionalDocuments[j].contextualData });
                        // tslint:disable-next-line:max-line-length
                        _this.allDocuments.push({ 'name': _this.connectedRoles[i].additionalDocuments[j].documentType.description, 'description': _this.connectedRoles[i].additionalDocuments[j].description, 'fileId': _this.connectedRoles[i].additionalDocuments[j].fileId, 'mimeType': _this.connectedRoles[i].additionalDocuments[j].mimeType, 'expires': _this.connectedRoles[i].additionalDocuments[j].validTo, 'documentId': _this.connectedRoles[i].additionalDocuments[j].id, 'status': _this.connectedRoles[i].additionalDocuments[j].documentStatus, 'riskLevel': _this.connectedRoles[i].additionalDocuments[j].riskStatus, 'documentTypeId': _this.connectedRoles[i].additionalDocuments[j].documentType.id, 'expired': expired, 'contextualData': _this.connectedRoles[i].additionalDocuments[j].contextualData });
                    }
                }
                if (_this.connectedRoles[i].e2Instance !== null) {
                    // tslint:disable-next-line:max-line-length
                    _this.rolesDisplay.push({ 'id': _this.connectedRoles[i].id, 'roleName': _this.connectedRoles[i].roleName, 'roleTypeId': _this.connectedRoles[i].roleTypeId, 'e1Id': _this.connectedRoles[i].e1Instance.id, 'e1Type': _this.connectedRoles[i].e1Instance.entityType.entityType, 'e1Instance': _this.connectedRoles[i].e1Instance.primaryName, 'e2Instance': _this.connectedRoles[i].e2Instance.primaryName, 'e2Id': _this.connectedRoles[i].e2Instance.id, 'e2Type': _this.connectedRoles[i].e2Instance.entityType.entityType, 'documents': _this.documents });
                }
                else {
                    // tslint:disable-next-line:max-line-length
                    _this.rolesDisplay.push({ 'id': _this.connectedRoles[i].id, 'roleName': _this.connectedRoles[i].roleName, 'roleTypeId': _this.connectedRoles[i].roleTypeId, 'e1Id': _this.connectedRoles[i].e1Instance.id, 'e1Type': _this.connectedRoles[i].e1Instance.entityType.entityType, 'e1Instance': _this.connectedRoles[i].e1Instance.primaryName, 'e2Instance': '', 'documents': _this.documents });
                }
            }
            for (var q = 0; q < _this.rolesDisplay.length; q++) {
                _this.rolesDisplay[q].documents.sort(function (a, b) {
                    a = a.name.toLowerCase();
                    b = b.name.toLowerCase();
                    return a < b ? -1 : a > b ? 1 : 0;
                });
            }
        }, function (error) { });
    };
    DetailComponent.prototype.addTagFn = function (name) {
        return { name: name, tag: true, active: true };
    };
    DetailComponent.prototype.addTagEvent = function (event) {
        var _this = this;
        var exists = false;
        for (var i = 0; i < this.tags.length; i++) {
            if (this.tags[i].name === event.name) {
                exists = true;
            }
        }
        if (exists) {
            var tagIds = [];
            for (var k = 0; k < this.selectedTags.length; k++) {
                tagIds.push(this.selectedTags[k].id);
            }
            this.updatedEntity = new EntityUpdate();
            this.updatedEntity.entityId = this.entityResponse.data.id;
            this.updatedEntity.entityTypeId = this.entityResponse.data.entityType.id;
            this.updatedEntity.primaryName = this.entityResponse.data.primaryName;
            this.updatedEntity.ownerId = this.entityResponse.data.owner.identifier;
            this.updatedEntity.entityStatus2E = this.entityResponse.data.entityStatus2E;
            this.updatedEntity.tagIds = tagIds;
            this.kybService.updateEntity(this.updatedEntity).subscribe(function (data) {
                _this.ngOnInit();
            }, function (error) {
                _this.alertService.showError(error.error.errors[0].message);
            });
        }
        else {
            this.kybService.createTag(event.name).subscribe(function (data) {
                _this.createTagResponse = data;
                for (var j = 0; j < _this.selectedTags.length; j++) {
                    if (_this.selectedTags[j].name === event.name) {
                        _this.selectedTags[j].id = _this.createTagResponse.data.id;
                    }
                }
                var tagIds = [];
                for (var k = 0; k < _this.selectedTags.length; k++) {
                    tagIds.push(_this.selectedTags[k].id);
                }
                _this.updatedEntity = new EntityUpdate();
                _this.updatedEntity.entityId = _this.entityResponse.data.id;
                _this.updatedEntity.entityTypeId = _this.entityResponse.data.entityType.id;
                _this.updatedEntity.primaryName = _this.entityResponse.data.primaryName;
                _this.updatedEntity.ownerId = _this.entityResponse.data.owner.identifier;
                _this.updatedEntity.entityStatus2E = _this.entityResponse.data.entityStatus2E;
                _this.updatedEntity.tagIds = tagIds;
                _this.kybService.updateEntity(_this.updatedEntity).subscribe(function (entityData) {
                    _this.ngOnInit();
                }, function (error) { });
            }, function (error) { });
        }
    };
    DetailComponent.prototype.removeTagEvent = function (event) {
        var _this = this;
        var tagIds = [];
        for (var k = 0; k < this.selectedTags.length; k++) {
            tagIds.push(this.selectedTags[k].id);
        }
        this.updatedEntity = new EntityUpdate();
        this.updatedEntity.entityId = this.entityResponse.data.id;
        this.updatedEntity.entityTypeId = this.entityResponse.data.entityType.id;
        this.updatedEntity.primaryName = this.entityResponse.data.primaryName;
        this.updatedEntity.ownerId = this.entityResponse.data.owner.identifier;
        this.updatedEntity.entityStatus2E = this.entityResponse.data.entityStatus2E;
        this.updatedEntity.tagIds = tagIds;
        this.kybService.updateEntity(this.updatedEntity).subscribe(function (data) {
            _this.ngOnInit();
        }, function (error) { });
    };
    DetailComponent.prototype.navigateToDetails = function (event) {
        this.openEntityDetail(event.id);
    };
    DetailComponent.prototype.controlloOK = function () {
        var _this = this;
        this.controlloLoading = true;
        this.kybService.updateAssessedRisk(this.entityId, 0, null).subscribe(function (data) {
            _this.updatedEntity = new EntityUpdate();
            _this.updatedEntity.entityId = _this.entityId;
            _this.updatedEntity.primaryName = _this.primaryName;
            _this.updatedEntity.entityTypeId = _this.entityTypeId;
            _this.updatedEntity.entityStatus2E = 'ACCEPTED';
            _this.kybService.updateEntity(_this.updatedEntity).subscribe(function (entityData) {
                _this.kybService.addComment(parseInt(_this.entityId, 10), 'User ' + localStorage.getItem('username') + ' updated control of entity to OK.').subscribe(function (commentData) {
                    _this.controlloLoading = false;
                    _this.alertService.showSuccess('Successfully adjusted.');
                    _this.ngOnInit();
                }, function (error) {
                    _this.controlloLoading = false;
                });
            }, function (error) {
                _this.controlloLoading = false;
            });
        }, function (error) {
            _this.controlloLoading = false;
        });
    };
    DetailComponent.prototype.controlloKO = function () {
        var _this = this;
        this.controlloLoading = true;
        this.kybService.updateAssessedRisk(this.entityId, this.unacceptableRiskThreshold, null).subscribe(function (data) {
            _this.updatedEntity = new EntityUpdate();
            _this.updatedEntity.entityId = _this.entityId;
            _this.updatedEntity.primaryName = _this.primaryName;
            _this.updatedEntity.entityTypeId = _this.entityTypeId;
            _this.updatedEntity.entityStatus2E = 'NON_COMPLIANT';
            _this.kybService.updateEntity(_this.updatedEntity).subscribe(function (entityData) {
                _this.kybService.addComment(parseInt(_this.entityId, 10), 'User ' + localStorage.getItem('username') + ' updated control of entity to KO.').subscribe(function (commentData) {
                    _this.controlloLoading = false;
                    _this.alertService.showSuccess('Successfully adjusted.');
                    _this.ngOnInit();
                }, function (error) {
                    _this.controlloLoading = false;
                });
            }, function (error) {
                _this.controlloLoading = false;
            });
        }, function (error) {
            _this.loading = false;
        });
    };
    DetailComponent.prototype.showComments = function () {
        var _this = this;
        var initialState = {
            entityId: this.username
        };
        this.showCommentsModal = this.modalService.show(ShowCommentsComponent, { initialState: initialState, backdrop: 'static' });
        this.subscriptions.push(this.modalService.onHide.subscribe(function (message) {
            _this.ngOnInit();
            _this.unsubscribe();
        }));
    };
    DetailComponent.prototype.showManualTotalRiskAdjustment = function () {
        var _this = this;
        this.kybService.getRiskAnalysisForEntity(this.username).subscribe(function (data) {
            _this.riskAnalysisResponse = data;
            _this.riskAnalysis = _this.riskAnalysisResponse.data;
            if (_this.riskAnalysis !== null && _this.riskAnalysis.assessedRisk === null) {
                _this.riskAnalysis.assessedRisk = 0;
            }
            if (_this.riskAnalysisResponse.data.customOverallRisk !== null && _this.riskAnalysisResponse.data.customOverallRisk.risk !== 0) {
                _this.totalRisk = _this.riskAnalysisResponse.data.customOverallRisk.risk;
                _this.totalRiskLevel = _this.riskAnalysisResponse.data.customOverallRisk.riskStatus;
            }
            var riskLevel = '';
            if (_this.riskAnalysisResponse.data.customOverallRisk === null) {
                riskLevel = '';
            }
            else {
                riskLevel = _this.riskAnalysisResponse.data.customOverallRisk.riskStatus;
            }
            var initialState = {
                'entityId': _this.entityId,
                'riskLevel': riskLevel,
                'primaryName': _this.primaryName,
                'entityTypeId': _this.entityTypeId
            };
            // tslint:disable-next-line:max-line-length
            if (_this.numberOfModals === 0) {
                _this.manualTotalRiskAdjustmentModal = _this.modalService.show(ManualTotalRiskAdjustmentComponent, { initialState: initialState, backdrop: 'static' });
                _this.numberOfModals = 1;
            }
            _this.subscriptions.push(_this.modalService.onHide.subscribe(function (message) {
                _this.numberOfModals = 0;
                _this.ngOnInit();
                _this.unsubscribe();
            }));
        }, function (error) {
        });
    };
    DetailComponent.prototype.showManualPEPRiskAdjustment = function () {
        var _this = this;
        this.kybService.getRiskAnalysisForEntity(this.username).subscribe(function (data) {
            _this.riskAnalysisResponse = data;
            var riskLevel = '';
            if (_this.riskAnalysisResponse.data.customPepRisk === null) {
                riskLevel = '';
            }
            else {
                riskLevel = _this.riskAnalysisResponse.data.customPepRisk.riskStatus;
            }
            var initialState = {
                'entityId': _this.entityId,
                'riskLevel': riskLevel,
                'primaryName': _this.primaryName,
                'entityTypeId': _this.entityTypeId
            };
            if (_this.numberOfModals === 0) {
                _this.manualPEPRiskAdjustmentModal =
                    _this.modalService.show(ManualPEPRiskAdjustmentComponent, { initialState: initialState, backdrop: 'static' });
                _this.numberOfModals += 1;
            }
            _this.subscriptions.push(_this.modalService.onHide.subscribe(function (message) {
                _this.numberOfModals = 0;
                _this.ngOnInit();
                _this.unsubscribe();
            }));
        }, function (error) {
        });
    };
    DetailComponent.prototype.showRemoveManualRiskAdjustment = function (type) {
        var _this = this;
        var initialState = {
            'type': type,
            'entityId': this.entityId,
            'primaryName': this.primaryName,
            'entityTypeId': this.entityTypeId
        };
        this.removeRiskAdjustmentModal = this.modalService.show(RemoveRiskAdjustmentComponent, { initialState: initialState, backdrop: 'static' });
        this.subscriptions.push(this.modalService.onHide.subscribe(function (message) {
            setTimeout(function () {
                _this.ngOnInit();
                _this.unsubscribe();
            }, 5000);
        }));
    };
    DetailComponent.prototype.renameEntity = function () {
        var _this = this;
        var initialState = {
            entityTypeId: this.entityTypeId,
            entityId: this.entityId,
            primaryName: this.primaryName
        };
        this.renameEntityModal = this.modalService.show(RenameEntityComponent, { initialState: initialState, backdrop: 'static' });
        this.subscriptions.push(this.modalService.onHide.subscribe(function (message) {
            _this.ngOnInit();
            _this.unsubscribe();
        }));
    };
    DetailComponent.prototype.confirmRefresh = function () {
        var _this = this;
        var initialState = {
            entityId: this.entityId
        };
        this.confirmRefreshModal = this.modalService.show(ConfirmRefreshComponent, { initialState: initialState, backdrop: 'static' });
        this.subscriptions.push(this.dataService.closeModalEvent.subscribe(function (message) {
            _this.confirmRefreshModal.hide();
            _this.ngOnInit();
            _this.unsubscribe();
        }));
    };
    DetailComponent.prototype.onboard = function () {
        alert('Onboarding SMS has been sent');
    };
    DetailComponent.prototype.takeOwnership = function () {
        var _this = this;
        var ownerId = localStorage.getItem('identifier');
        if (window.confirm('Are you sure you want to take ownership of this entity?')) {
            this.kybService.takeOwnership(this.entityId, ownerId, this.primaryName, this.entityTypeId).subscribe(function (data) {
                _this.alertService.showSuccess('Ownership changed successfully.');
                _this.ngOnInit();
            }, function (error) {
                _this.alertService.showError('Something went wrong while changing ownership.');
            });
        }
    };
    DetailComponent.prototype.toggleRiskInput = function () {
        if (this.showRiskEdit === false) {
            this.showRiskEdit = true;
        }
        else {
            this.showRiskEdit = false;
        }
    };
    DetailComponent.prototype.viewDocument = function (documentId, mimeType, name, contextualData) {
        var _this = this;
        // tslint:disable-next-line:max-line-length
        var initialState = { documentId: documentId, mimeType: mimeType, documents: [{ documentId: documentId, mimeType: mimeType, name: name, contextualData: contextualData }] };
        this.viewModal = this.modalService.show(ViewDocumentComponent, { initialState: initialState, backdrop: 'static' });
        this.subscriptions.push(this.modalService.onHide.subscribe(function (message) {
            _this.ngOnInit();
            _this.unsubscribe();
        }));
    };
    DetailComponent.prototype.checkDocuments = function () {
        var _this = this;
        var initialState = { documents: this.allDocuments };
        this.viewModal = this.modalService.show(ViewDocumentComponent, { initialState: initialState, backdrop: 'static' });
        this.subscriptions.push(this.modalService.onHide.subscribe(function (message) {
            _this.allDocuments = [];
            _this.ngOnInit();
            _this.unsubscribe();
        }));
    };
    DetailComponent.prototype.unsubscribe = function () {
        this.subscriptions.forEach(function (subscription) {
            subscription.unsubscribe();
        });
        this.subscriptions = [];
    };
    DetailComponent.prototype.addDocumentToRole = function (role) {
        var _this = this;
        var initialState = {
            'mode': 'add-document',
            'title': 'Add Document',
            'entityId': this.username,
            'roleId': role.id
        };
        this.addModal = this.modalService.show(AddModalComponent, { initialState: initialState, backdrop: 'static' });
        this.subscriptions.push(this.dataService.closeModalEvent.subscribe(function (message) {
            _this.addModal.hide();
            _this.ngOnInit();
            _this.unsubscribe();
            _this.drawNewControlChart(_this.controlResponse.data);
            _this.drawNewUBOChart(_this.UBOResponse.data);
        }));
    };
    DetailComponent.prototype.addDocumentTypeToRole = function (index, todos, isORTrue) {
        var _this = this;
        var initialState = {};
        if (isORTrue) {
            var currentTodos = [];
            currentTodos.push(todos[index]);
            for (var i = index; i > 0; i--) {
                if (todos[i - 1].documentGroup === todos[i].documentGroup) {
                    currentTodos.push(todos[i - 1]);
                }
                else {
                    break;
                }
            }
            initialState = {
                'mode': 'add-document',
                'title': 'Add Document',
                'entityId': this.username,
                'roleId': todos[index].roleId,
                'documentTypeId': todos[index].documentTypeId,
                'documentId': todos[index].documentId,
                'currentTodos': currentTodos
            };
        }
        else {
            initialState = {
                'mode': 'add-document',
                'title': 'Add Document',
                'entityId': this.username,
                'roleId': todos[index].roleId,
                'documentTypeId': todos[index].documentTypeId,
                'documentId': todos[index].documentId
            };
        }
        this.addModal = this.modalService.show(AddModalComponent, { initialState: initialState, backdrop: 'static' });
        this.subscriptions.push(this.dataService.closeModalEvent.subscribe(function (message) {
            _this.addModal.hide();
            _this.ngOnInit();
            _this.unsubscribe();
            _this.drawNewControlChart(_this.controlResponse.data);
            _this.drawNewUBOChart(_this.UBOResponse.data);
        }));
    };
    DetailComponent.prototype.replaceDocumentTypeForRole = function (roleId, documentId, documentTypeId) {
        var _this = this;
        var initialState = {
            'mode': 'replace-document',
            'title': 'Replace Document',
            'entityId': this.username,
            'documentId': documentId,
            'roleId': roleId,
            'documentTypeId': documentTypeId
        };
        this.addModal = this.modalService.show(AddModalComponent, { initialState: initialState, backdrop: 'static' });
        this.subscriptions.push(this.dataService.closeModalEvent.subscribe(function (message) {
            _this.addModal.hide();
        }), this.modalService.onHide.subscribe(function (message) {
            _this.ngOnInit();
            _this.unsubscribe();
        }));
    };
    DetailComponent.prototype.editInformation = function () {
        var _this = this;
        var documentData = {};
        var rolesDocument;
        documentData.entityId = this.username;
        if (this.rolesDisplay.length && this.rolesDisplay.length > 0) {
            documentData.roleId = this.rolesDisplay[0].id;
            documentData.e1Id = this.rolesDisplay[0].e1Id;
            documentData.e2Id = this.rolesDisplay[0].e2Id;
            this.rolesDisplay[0].documents.forEach(function (document) {
                if (document.documentStatus !== 'REPLACED' && document.documentStatus !== 'DELETED' && document.documentStatus !== 'EMPTY') {
                    rolesDocument = document;
                    documentData.documentId = rolesDocument.documentId;
                    documentData.documentTypeId = rolesDocument.documentTypeId;
                    documentData.documentTypeName = rolesDocument.name;
                }
            });
        }
        var initialState = {
            'mode': 'edit-information',
            'title': 'Edit Information',
            'basicInformation': this.basicInformation,
            'entityResponseData': this.entityResponse.data,
            'primaryName': this.primaryName,
            'documentData': documentData
        };
        this.addModal = this.modalService.show(AddModalComponent, { initialState: initialState, backdrop: 'static' });
        this.subscriptions.push(this.dataService.closeModalEvent.subscribe(function (message) {
            Object.entries(message).forEach(function (entry) {
                if (entry[0] === 'primaryName' || entry[0] === 'phoneNumber' || entry[0] === 'email') {
                    _this.entityResponse.data[entry[0]] = entry[1];
                }
            });
            _this.updatedEntity = new EntityUpdate();
            _this.updatedEntity.entityId = _this.entityResponse.data.id;
            _this.updatedEntity.entityTypeId = _this.entityResponse.data.entityType.id;
            _this.updatedEntity.entityEmail = _this.entityResponse.data.email;
            _this.updatedEntity.entityPhoneNumber = _this.entityResponse.data.phoneNumber;
            _this.updatedEntity.primaryName = _this.entityResponse.data.primaryName;
            _this.kybService.updateEntity(_this.updatedEntity).subscribe();
            _this.addModal.hide();
            _this.ngOnInit();
            _this.unsubscribe();
        }));
    };
    DetailComponent.prototype.editJsonDocument = function (roleId, documentId, documentTypeId, e1Id, e2Id) {
        var _this = this;
        if (e2Id === undefined) {
            e2Id = null;
        }
        var initialState = {
            'mode': 'edit-document',
            'title': 'Edit Document',
            'entityId': this.username,
            'documentId': documentId,
            'roleId': roleId,
            'documentTypeId': documentTypeId,
            'entityTypeId': this.entityTypeId,
            'primaryName': this.primaryName,
            'e1Id': e1Id,
            'e2Id': e2Id
        };
        this.addModal = this.modalService.show(AddModalComponent, { initialState: initialState, backdrop: 'static' });
        this.subscriptions.push(this.dataService.closeModalEvent.subscribe(function (message) {
            _this.addModal.hide();
        }), this.modalService.onHide.subscribe(function (message) {
            _this.ngOnInit();
            _this.unsubscribe();
        }));
    };
    DetailComponent.prototype.addRoleToEntity = function (entityId, roleTypeId) {
        var _this = this;
        var initialState = {
            'mode': 'add-role',
            'title': 'Add Role',
            'entityId': entityId,
            'roleTypeId': roleTypeId
        };
        this.addModal = this.modalService.show(AddModalComponent, { initialState: initialState, backdrop: 'static' });
        this.subscriptions.push(this.dataService.closeModalEvent.subscribe(function (message) {
            _this.addModal.hide();
            _this.ngOnInit();
            _this.unsubscribe();
            _this.drawNewControlChart(_this.controlResponse.data);
            _this.drawNewUBOChart(_this.UBOResponse.data);
        }));
    };
    DetailComponent.prototype.addRole = function () {
        var _this = this;
        var initialState = {
            'mode': 'add-role',
            'title': 'Add role',
            'entityId': this.toDoListResponse.data.entityId
        };
        this.addModal = this.modalService.show(AddModalComponent, { initialState: initialState, backdrop: 'static' });
        this.subscriptions.push(this.dataService.closeModalEvent.subscribe(function (message) {
            _this.addModal.hide();
            _this.ngOnInit();
            _this.unsubscribe();
        }));
    };
    /**
     * Function used to save the comment
    */
    DetailComponent.prototype.saveComment = function () {
        var _this = this;
        this.kybService.addComment(parseInt(this.entityId, 10), this.entityComment).subscribe(function (data) {
            _this.alertService.showSuccess('Comment successfully added.');
            _this.entityComment = '';
            _this.ngOnInit();
        }, function (error) {
            _this.alertService.showError(error.error.errors[0].message);
        });
    };
    DetailComponent.prototype.openEntityDetail = function (id) {
        var _this = this;
        this.router.navigateByUrl('/tmp', { skipLocationChange: true }).then(function () {
            return _this.router.navigate(['/detail/' + id]);
        });
    };
    /**
     * Function used to set the KYC status
     * @param {any} userName - user to set the status for
     * @param {any} kycStatus - the value of KYC status (1 - Low Risk, 2 - Medium Risk, 3 - High Risk)
    */
    DetailComponent.prototype.setKYCStatus = function (userName, kycStatus) {
        var _this = this;
        this.callsService.setKYCStatus(userName, kycStatus).subscribe(function (data) {
        }, function (error) {
            if (error.error.text === 'done.') {
                _this.alertService.showSuccess('Changed status successfully.');
                _this.getAccount();
            }
            else {
                _this.alertService.showError('Something went wrong.');
            }
        });
    };
    DetailComponent.prototype.addDocument = function () {
        var _this = this;
        var initialState = {
            'mode': 'add-document',
            'title': 'Add Document',
            'entityId': this.username
        };
        this.addModal = this.modalService.show(AddModalComponent, { initialState: initialState, backdrop: 'static' });
        this.subscriptions.push(this.dataService.closeModalEvent.subscribe(function (message) {
            _this.addModal.hide();
            _this.ngOnInit();
            _this.unsubscribe();
        }));
    };
    DetailComponent.prototype.setEntityStatus = function (entityId, status) {
        var _this = this;
        var tagIds = [];
        for (var k = 0; k < this.selectedTags.length; k++) {
            tagIds.push(this.selectedTags[k].id);
        }
        this.updatedEntity = new EntityUpdate();
        this.updatedEntity.delegateEmail = 'admin@admin.com';
        this.updatedEntity.entityId = this.username;
        this.updatedEntity.ownerId = this.entityResponse.data.owner.identifier;
        this.updatedEntity.entityStatus2E = status;
        this.updatedEntity.entityTypeId = this.entityResponse.data.entityType.id;
        this.updatedEntity.primaryName = this.entityResponse.data.primaryName;
        this.updatedEntity.tagIds = tagIds;
        this.kybService.updateEntity(this.updatedEntity).subscribe(function (data) {
            _this.alertService.showSuccess('Entity status successfully set.');
            _this.ngOnInit();
        }, function (error) {
            _this.alertService.showError(error.error.errors[0].message);
        });
    };
    DetailComponent.prototype.receiveEvent = function ($event) {
        this.refreshRiskIndicators();
    };
    DetailComponent.prototype.refreshRiskIndicators = function () {
        var _this = this;
        this.kybService.getRiskAnalysisForEntity(this.username).subscribe(function (data) {
            _this.riskAnalysisResponse = data;
            _this.riskAnalysis = _this.riskAnalysisResponse.data;
            if (_this.riskAnalysis.assessedRisk === null) {
                _this.riskAnalysis.assessedRisk = 0;
            }
            if (_this.riskAnalysisResponse.data.customOverallRisk !== null
                && _this.riskAnalysisResponse.data.customOverallRisk.risk !== null
                && _this.riskAnalysisResponse.data.customOverallRisk.riskStatus !== null) {
                _this.totalRisk = _this.riskAnalysisResponse.data.customOverallRisk.risk;
                _this.totalRiskLevel = _this.riskAnalysisResponse.data.customOverallRisk.riskStatus;
            }
            else {
                _this.totalRisk = _this.riskAnalysisResponse.data.combinedOverallRisk.risk;
                _this.totalRiskLevel = _this.riskAnalysisResponse.data.combinedOverallRisk.riskStatus;
            }
        }, function (error) { });
    };
    /**
     * Function which runs checks for selected users
    */
    DetailComponent.prototype.checkUsers = function () {
        var _this = this;
        this.loading = true;
        this.callsService.evalUser(this.username).subscribe(function (data) {
            _this.loading = false;
            _this.alertService.showSuccess('Check successful.');
            _this.ngOnInit();
        }, function (error) {
            _this.loading = false;
            _this.alertService.showError('Something went wrong.');
        });
    };
    /**
     * Function used to retreive details for a specific user
    */
    DetailComponent.prototype.getAccount = function () {
        var _this = this;
        this.usersToReview = [];
        this.callsService.getUserDetails(this.username).subscribe(function (data) {
            _this.model = data;
            _this.getDocuments(_this.model);
            _this.usersToReview.push(_this.model.data.email);
            _this.dataService.setUsersForReview(_this.usersToReview);
            _this.words = _this.model.data.video.wordList;
            _this.returnWords = _this.model.data.video.videoAuthData.wordMatch;
            _this.wordsObject.words = _this.words;
            _this.wordsObject.returnWords = _this.returnWords;
            _this.wordsObject.match = [];
            for (var i = 0; i < _this.words.length; i++) {
                if (_this.returnWords[i] === true) {
                    _this.wordsObject.match.push(1);
                }
                else {
                    _this.wordsObject.match.push(0);
                }
            }
        }, function (error) {
        });
    };
    DetailComponent.prototype.getDocuments = function (model) {
        var _this = this;
        this.callsService.getDocument(model.data.documents.fileMapping.passportFrontFileId).subscribe(function (data) { }, function (error) {
            _this.safePassportFront = _this.domSanitizer.bypassSecurityTrustResourceUrl('data:image/jpg;base64,' + error.error.text);
        });
        if (model.data.documents.fileMapping.passportBackFileId) {
            this.callsService.getDocument(model.data.documents.fileMapping.passportBackFileId).subscribe(function (data) { }, function (error) {
                _this.safePassportBack = _this.domSanitizer.bypassSecurityTrustResourceUrl('data:image/jpg;base64,' + error.error.text);
            });
        }
        this.callsService.getDocument(model.data.documents.fileMapping.proofOfResidenceFileId).subscribe(function (data) { }, function (error) {
            _this.safeProofOfResidence = _this.domSanitizer.bypassSecurityTrustResourceUrl('data:image/jpg;base64,' + error.error.text);
        });
        this.callsService.getDocument(model.data.video.videoFileId).subscribe(function (data) { }, function (error) {
            var selfieVideo = document.getElementById('selfie-video');
            _this.safeSelfieVideo = 'data:video/webm;base64,' + error.error.text;
            selfieVideo.src = _this.safeSelfieVideo;
        });
    };
    DetailComponent.prototype.moveToFurther = function (entryId, email, direction) {
        var _this = this;
        this.callsService.moveMatch(email, entryId, direction).subscribe(function (data) {
            _this.ngOnInit();
        }, function (error) { });
    };
    DetailComponent.prototype.addNote = function (email, entryId, note) {
        var _this = this;
        this.callsService.updateMatchNote(email, entryId, note).subscribe(function (data) {
            _this.ngOnInit();
        }, function (error) { });
    };
    DetailComponent.prototype.setDocumentStatus = function (status) {
        var _this = this;
        var x = document.getElementsByClassName('uploadedImages');
        if (x[0].attributes[2].value === 'display: block;' || x[1].attributes[2].value === 'display: block;') {
            this.callsService.setIdentityDocumentStatus(this.username, status).subscribe(function (data) {
                var statusText = '';
                if (status === 'red') {
                    statusText = 'REJECTED';
                }
                if (status === 'yellow') {
                    statusText = 'FURTHER RESEARCH';
                }
                if (status === 'green') {
                    statusText = 'APPROVED';
                }
                _this.callsService.addEvent(_this.username, localStorage.getItem('username'), '[IDENTITY DOCUMENT STATUS] ' + localStorage.getItem('username') + ' changed the status of the identity document to ' + statusText).subscribe();
                _this.ngOnInit();
            }, function (error) { });
        }
        else {
            this.callsService.setProofOfResidenceStatus(this.username, status).subscribe(function (data) {
                var statusText = '';
                if (status === 'red') {
                    statusText = 'REJECTED';
                }
                if (status === 'yellow') {
                    statusText = 'FURTHER RESEARCH';
                }
                if (status === 'green') {
                    statusText = 'APPROVED';
                }
                _this.callsService.addEvent(_this.username, localStorage.getItem('username'), '[PROOF OF RESIDENCE STATUS] ' + localStorage.getItem('username') + ' changed the status of the proof of residence document to ' + statusText).subscribe();
                _this.ngOnInit();
            }, function (error) { });
        }
    };
    DetailComponent.prototype.drawRelationshipGraph = function (relationship) {
        var height = '';
        this.relationshipGraphData = [];
        if (this.entityType !== 'legal') {
            height = '50%';
        }
        else {
            height = '100%';
        }
        if (relationship.length === 0) {
            this.relationshipGraphData.push({ 'from': this.entityName, 'to': this.entityName });
        }
        else {
            for (var i = 0; i < relationship.length; i++) {
                var currentColorFrom = '';
                var currentColorTo = '';
                var currentStatusFrom = '';
                var currentStatusTo = '';
                if (relationship[i].entity1.customOverallRisk.riskStatus !== null) {
                    currentStatusFrom = relationship[i].entity1.customOverallRisk.riskStatus;
                }
                else {
                    currentStatusFrom = relationship[i].entity1.combinedOverallRisk.riskStatus;
                }
                if (relationship[i].entity2.customOverallRisk.riskStatus !== null) {
                    currentStatusTo = relationship[i].entity2.customOverallRisk.riskStatus;
                }
                else {
                    currentStatusTo = relationship[i].entity2.combinedOverallRisk.riskStatus;
                }
                switch (currentStatusFrom) {
                    case 'HIGH':
                        currentColorFrom = 'rgb(214, 134, 15)';
                        break;
                    case 'LOW':
                        currentColorFrom = '#72C245';
                        break;
                    case 'MEDIUM':
                        currentColorFrom = '#FDD32B';
                        break;
                    case 'REJECTED':
                        currentColorFrom = '#D60000';
                        break;
                    default:
                        currentColorFrom = '#bdbdbd';
                }
                switch (currentStatusTo) {
                    case 'HIGH':
                        currentColorTo = 'rgb(214, 134, 15)';
                        break;
                    case 'LOW':
                        currentColorTo = '#72C245';
                        break;
                    case 'MEDIUM':
                        currentColorTo = '#FDD32B';
                        break;
                    case 'REJECTED':
                        currentColorTo = '#D60000';
                        break;
                    default:
                        currentColorTo = '#bdbdbd';
                }
                // tslint:disable-next-line:max-line-length
                this.relationshipGraphData.push({ 'from': relationship[i].entity1.entityName, 'to': relationship[i].entity2.entityName, 'name': relationship[i].roleName, 'colorFrom': currentColorFrom, 'colorTo': currentColorTo });
            }
        }
        this.chart = new Chart({
            chart: {
                type: 'networkgraph',
                events: {
                    load: function () {
                        var series = this.series[0];
                        series.data.forEach(function (point, i) {
                            if (point.colorFrom && series.nodes[i]) {
                                series.nodes[i].color = point.colorFrom;
                            }
                            if (point.colorTo && series.nodes[i + 1]) {
                                series.nodes[i + 1].color = point.colorTo;
                            }
                        });
                    }
                },
                height: height
            },
            title: {
                text: ''
            },
            credits: {
                enabled: false
            },
            plotOptions: {
                networkgraph: {
                    marker: {
                        radius: 20
                    },
                    keys: ['from', 'to'],
                    layoutAlgorithm: {
                        enableSimulation: false,
                        linkLength: 40,
                        friction: -0.9
                    },
                    lineWidth: 4
                }
            },
            series: [{
                    type: 'networkgraph',
                    dataLabels: {
                        enabled: true,
                        linkTextPath: {
                            attributes: {
                                dy: 12
                            }
                        },
                        linkFormat: 'is {point.name} of'
                    },
                    id: 'lang-tree',
                    color: '#68799c',
                    data: this.relationshipGraphData
                }],
            exporting: {
                enabled: false
            }
        });
    };
    DetailComponent.prototype.prepareControlData = function (startEntity, entities) {
        var overallRisk = '';
        for (var i = 0; i < entities.length; i++) {
            this.controls.push({ from: startEntity, to: entities[i].entityName });
            if (entities[i].customOverallRisk.riskStatus !== null) {
                overallRisk = entities[i].customOverallRisk.riskStatus;
            }
            else {
                overallRisk = entities[i].combinedOverallRisk.riskStatus;
            }
            // tslint:disable-next-line:max-line-length
            this.controlNodes.push({ id: entities[i].entityName, title: entities[i].entityName, overallRisk: overallRisk, name: 'E' + (entities[i].distance + 1) + '-' + entities[i].roleName, type: entities[i].entityType, detailId: entities[i].id });
            if (entities[i].entities.length > 0) {
                this.prepareControlData(entities[i].entityName, entities[i].entities);
            }
        }
        return this.controls;
    };
    DetailComponent.prototype.drawNewControlChart = function (controlData) {
        var newChilds = [];
        if (controlData.controls.length === 0) {
            this.emptyControl = true;
            this.controlNodes = [];
        }
        else {
            this.emptyControl = false;
            this.controlNodes = [];
            for (var i = 0; i < controlData.controls.length; i++) {
                newChilds.push({ id: controlData.controls[i].id, title: controlData.controls[i].entityName,
                    name: 'E' + (controlData.controls[i].distance + 1) + '-' +
                        controlData.controls[i].roleName });
            }
            controlData.controls.forEach(function (element) {
                newChilds.forEach(function (child) {
                    var currentStatus = '';
                    if (element.customOverallRisk.riskStatus !== null) {
                        currentStatus = element.customOverallRisk.riskStatus;
                    }
                    else {
                        currentStatus = element.combinedOverallRisk.riskStatus;
                    }
                    switch (currentStatus) {
                        case 'HIGH':
                            child.cssClass = 'OR-high';
                            break;
                        case 'LOW':
                            child.cssClass = 'OR-low';
                            break;
                        case 'MEDIUM':
                            child.cssClass = 'OR-medium';
                            break;
                        case 'REJECTED':
                            child.cssClass = 'OR-rejected';
                            break;
                        default:
                            child.cssClass = 'OR-unevaluated';
                    }
                    if (element.entityType === 'PERSON') {
                        child.image = '../../assets/images/person.svg';
                    }
                    else {
                        child.image = '../../assets/images/building.svg';
                    }
                });
            });
            this.controlNodes.push({ id: controlData.entity.id, title: controlData.entity.entityName, name: 'FOCUS Company', childs: newChilds });
        }
    };
    DetailComponent.prototype.drawControlChart = function (controlData) {
        var _this = this;
        if (this.controlChart !== undefined) {
            this.controlChart.destroy();
        }
        if (controlData.controls.length === 0) {
            this.emptyControl = true;
            this.controlData = [];
            this.controlNodes = [];
            this.controls = [];
        }
        else {
            this.emptyControl = false;
            this.controlData = [];
            this.controlNodes = [];
            this.controls = [];
            this.controlData = this.prepareControlData(controlData.entity.entityName, controlData.controls);
            // tslint:disable-next-line:max-line-length
            this.controlNodes.push({ id: controlData.entity.entityName, title: controlData.entity.entityName, name: 'FOCUS Company', detailId: controlData.entity.id });
            this.controlChart = new Chart({
                chart: {
                    height: 600,
                    inverted: true,
                    style: {
                        cursor: 'pointer'
                    }
                },
                title: {
                    text: 'Control'
                },
                series: [{
                        type: 'organization',
                        name: controlData.entity.entityName,
                        keys: ['from', 'to'],
                        data: this.controlData,
                        levels: [{
                                level: 0,
                                color: 'silver',
                                dataLabels: {
                                    color: 'white',
                                },
                            }, {
                                level: 1,
                                color: 'green',
                                dataLabels: {
                                    color: 'white'
                                },
                            }, {
                                level: 2,
                                color: 'green',
                                dataLabels: {
                                    color: 'white'
                                }
                            }, {
                                level: 3,
                                color: 'green',
                                dataLabels: {
                                    color: 'white'
                                }
                            }, {
                                level: 4,
                                color: 'green',
                                dataLabels: {
                                    color: 'white'
                                }
                            }],
                        nodes: this.controlNodes,
                        events: {
                            click: function (event) {
                                _this.point = event;
                                if (event.point['toNode'] === undefined) {
                                    var url = window.location.href.split('/');
                                    window.open(url[url.length - 2] + '/' + event.point.options['detailId']);
                                }
                            },
                            mouseOver: function (event) {
                                document.body.style.cursor = 'pointer';
                            },
                            mouseOut: function () {
                                document.body.style.cursor = 'default';
                            }
                        },
                        colorByPoint: false,
                        color: '#007ad0',
                        dataLabels: {
                            color: 'white'
                        },
                        borderColor: 'white',
                        nodeWidth: 65
                    }],
                tooltip: {
                    outside: true,
                    formatter: function () {
                        if (_this.point['toNode'] !== undefined) {
                            return _this.point['toNode']['name'].split('-')[1];
                        }
                        else {
                            return _this.point['name'] + '<br/><b>' + _this.point['title'] + '</b>';
                        }
                    }
                },
                exporting: {
                    allowHTML: false,
                    sourceWidth: 800,
                    sourceHeight: 600
                },
                credits: {
                    enabled: false
                },
            });
        }
    };
    DetailComponent.prototype.prepareUBOData = function (startEntity, entities) {
        this.entityLevel++;
        var overallRisk = '';
        for (var i = 0; i < entities.length; i++) {
            var cyclesWith = '';
            this.owners.push({ from: entities[i].entityName, to: startEntity });
            if (entities[i].customOverallRisk.riskStatus !== null) {
                overallRisk = entities[i].customOverallRisk.riskStatus;
            }
            else {
                overallRisk = entities[i].combinedOverallRisk.riskStatus;
            }
            if (entities[i].cycles.length > 0) {
                for (var j = 0; j < entities[i].cycles.length; j++) {
                    cyclesWith += entities[i].cycles[j].name + ',';
                }
            }
            if (cyclesWith !== '') {
                for (var z = 0; z < this.uboNodes.length; z++) {
                    if (this.uboNodes[z].title === entities[i].entityName) {
                        this.uboNodes[z].color = '#cc0000';
                        this.uboNodes[z].cycles = cyclesWith.replace(/,([^,]*)$/, '$1');
                        this.uboNodes[z].image = this.exclamationImage;
                    }
                }
                if (this.titles.indexOf(entities[i].entityName) === -1) {
                    this.titles.push(entities[i].entityName);
                    // tslint:disable-next-line:max-line-length
                    this.uboNodes.push({ id: entities[i].entityName, title: entities[i].entityName, overallRisk: overallRisk, name: 'E' + (entities[i].distance + 1) + '-' + entities[i].roleName, type: entities[i].entityType, detailId: entities[i].id, color: '#cc0000', cycles: cyclesWith.replace(/,([^,]*)$/, '$1'), image: this.exclamationImage, layout: 'hanging' });
                }
            }
            else {
                if (this.titles.indexOf(entities[i].entityName) === -1) {
                    this.titles.push(entities[i].entityName);
                    // tslint:disable-next-line:max-line-length
                    this.uboNodes.push({ id: entities[i].entityName, title: entities[i].entityName, overallRisk: overallRisk, name: 'E' + (entities[i].distance + 1) + '-' + entities[i].roleName, type: entities[i].entityType, detailId: entities[i].id, cycles: '', layout: 'hanging' });
                }
                else {
                    // tslint:disable-next-line:max-line-length
                    this.uboNodes.push({ id: entities[i].entityName, title: entities[i].entityName, overallRisk: overallRisk, name: 'E' + (entities[i].distance + 1) + '-' + entities[i].roleName, type: entities[i].entityType, detailId: entities[i].id, cycles: '' });
                }
            }
            if (entities[i].entities.length > 0) {
                this.prepareUBOData(entities[i].entityName, entities[i].entities);
            }
        }
        return this.owners;
    };
    DetailComponent.prototype.prepareNewUBOData = function (entity) {
        var _this = this;
        var childIds = [];
        if (entity.length > 0) {
            entity.forEach(function (element) {
                _this.prepareNewUBOData(element);
            });
        }
        else if (entity.entities && entity.entities.length > 0) {
            this.prepareNewUBOData(entity.entities);
            var _loop_1 = function (i) {
                if (entity.entities[i].entities.length > 0) {
                    entity.entities[i].entities.forEach(function (element) {
                        childIds.push({ childs: [{ id: element.id }], id: entity.entities[i].id });
                    });
                }
                else {
                    childIds.push({ id: entity.entities[i].id });
                }
                this_1.newChilds.push({ childs: childIds, id: entity.id });
            };
            var this_1 = this;
            for (var i = 0; i < entity.entities.length; i++) {
                _loop_1(i);
            }
        }
        else {
            this.newChilds.push({ id: entity.id });
        }
    };
    DetailComponent.prototype.putImageNameAndTitle = function (uboNode, child) {
        var _this = this;
        if (child.length > 0) {
            child.forEach(function (element) {
                _this.putImageNameAndTitle(uboNode, element);
            });
        }
        if (uboNode.detailId === child.id) {
            child.name = uboNode.name;
            child.title = uboNode.title;
            switch (uboNode.overallRisk) {
                case 'HIGH':
                    child.cssClass = 'OR-high';
                    break;
                case 'LOW':
                    child.cssClass = 'OR-low';
                    break;
                case 'MEDIUM':
                    child.cssClass = 'OR-medium';
                    break;
                case 'REJECTED':
                    child.cssClass = 'OR-rejected';
                    break;
                default:
                    child.cssClass = 'OR-unevaluated';
            }
            if (uboNode.type === 'PERSON') {
                child.image = '../../assets/images/person.svg';
            }
            else {
                child.image = '../../assets/images/building.svg';
            }
        }
        if (child.childs && child.childs.length > 0) {
            this.putImageNameAndTitle(uboNode, child.childs);
        }
        else if (uboNode.detailId === child.id) {
            child.name = uboNode.name;
            child.title = uboNode.title;
            switch (uboNode.overallRisk) {
                case 'HIGH':
                    child.cssClass = 'OR-high';
                    break;
                case 'LOW':
                    child.cssClass = 'OR-low';
                    break;
                case 'MEDIUM':
                    child.cssClass = 'OR-medium';
                    break;
                case 'REJECTED':
                    child.cssClass = 'OR-rejected';
                    break;
                default:
                    child.cssClass = 'OR-unevaluated';
            }
            if (uboNode.type === 'PERSON') {
                child.image = '../../assets/images/person.svg';
            }
            else {
                child.image = '../../assets/images/building.svg';
            }
        }
    };
    DetailComponent.prototype.drawNewUBOChart = function (UBOdata) {
        var _this = this;
        var currentChilds = [];
        var newNodes = [];
        UBOdata.owners.forEach(function (owner) {
            currentChilds = [];
            if (owner.entities.length > 0) {
                owner.entities.forEach(function (entity) {
                    _this.newChilds = [];
                    _this.prepareNewUBOData(entity);
                    currentChilds.push(_this.newChilds[_this.newChilds.length - 1]);
                });
            }
            else {
                currentChilds = [];
            }
            _this.nodes.push({ childs: currentChilds, id: owner.id });
        });
        this.uboNodes.forEach(function (uboNode) {
            _this.nodes.forEach(function (node) {
                if (uboNode.detailId === node.id) {
                    node.name = uboNode.name;
                    node.title = uboNode.title;
                    switch (uboNode.overallRisk) {
                        case 'HIGH':
                            node.cssClass = 'OR-high';
                            break;
                        case 'LOW':
                            node.cssClass = 'OR-low';
                            break;
                        case 'MEDIUM':
                            node.cssClass = 'OR-medium';
                            break;
                        case 'REJECTED':
                            node.cssClass = 'OR-rejected';
                            break;
                        default:
                            node.cssClass = 'OR-unevaluated';
                    }
                    if (uboNode.type === 'PERSON') {
                        node.image = '../../assets/images/person.svg';
                    }
                    else {
                        node.image = '../../assets/images/building.svg';
                    }
                }
                if (node.childs) {
                    _this.putImageNameAndTitle(uboNode, node.childs);
                }
            });
            if (uboNode.detailId === UBOdata.entity.id) {
                newNodes.push({ id: uboNode.detailId, name: uboNode.name, title: uboNode.title, childs: _this.nodes });
            }
        });
        this.nodes = newNodes;
    };
    DetailComponent.prototype.drawUBOChart = function (UBOdata) {
        var _this = this;
        this.titles = [];
        if (this.uboChart !== undefined) {
            this.uboChart.destroy();
        }
        if (UBOdata.owners.length === 0) {
            this.emptyUBO = true;
            this.uboData = [];
            this.uboNodes = [];
            this.uboLevels = [];
            this.entityLevel = 0;
            this.owners = [];
        }
        else {
            this.emptyUBO = false;
            this.uboData = [];
            this.uboNodes = [];
            this.uboLevels = [];
            this.entityLevel = 0;
            this.owners = [];
            this.uboData = this.prepareUBOData(UBOdata.entity.entityName, UBOdata.owners);
            // Check this for the colors
            for (var i = 0; i < this.entityLevel; i++) {
                this.uboLevels.push({ level: i, color: 'blue', dataLabels: { color: 'white' } });
            }
            // tslint:disable-next-line:max-line-length
            this.uboNodes.push({ id: UBOdata.entity.entityName, title: UBOdata.entity.entityName, name: 'FOCUS Company', detailId: UBOdata.entity.id, color: 'silver' });
            var title = '';
            for (var x = 0; x < this.uboNodes.length; x++) {
                if (this.uboNodes[x].name === 'FOCUS Company') {
                    title = this.uboNodes[x].title;
                    this.index = x;
                }
            }
            for (var y = 0; y < this.uboNodes.length; y++) {
                if (this.uboNodes[y].title === title && this.uboNodes[y].name !== 'FOCUS Company') {
                    this.uboNodes[this.index].color = '#cc0000';
                    this.uboNodes[this.index].cycles = this.uboNodes[y].cycles;
                    this.uboNodes[this.index].image = this.uboNodes[y].image;
                    this.uboNodes.splice(y, 1);
                }
            }
            var cycleEntities = '';
            for (var p = 0; p < this.uboNodes.length; p++) {
                if (this.uboNodes[p].name === 'FOCUS Company') {
                    this.index = p;
                }
                if (this.uboNodes[p].cycles !== '') {
                    if (this.uboNodes[p].cycles !== undefined) {
                        cycleEntities = this.uboNodes[p].cycles.split(',');
                    }
                }
            }
            for (var q = 0; q < this.uboData.length; q++) {
                if (this.uboData[q].from === this.uboNodes[this.index].title && cycleEntities.indexOf(this.uboData[q].to) !== -1) {
                    this.uboData.splice(q, 1);
                }
            }
            this.uboChart = new Chart({
                chart: {
                    height: 600,
                    inverted: true
                },
                title: {
                    text: 'UBO'
                },
                series: [{
                        type: 'organization',
                        name: UBOdata.entity.entityName,
                        keys: ['from', 'to'],
                        data: this.uboData,
                        levels: this.uboLevels,
                        nodes: this.uboNodes,
                        events: {
                            click: function (event) {
                                _this.point = event;
                                if (event.point['toNode'] === undefined) {
                                    var url = window.location.href.split('/');
                                    window.open(url[url.length - 2] + '/' + event.point.options['detailId']);
                                }
                            },
                            mouseOver: function () {
                                document.body.style.cursor = 'pointer';
                            },
                            mouseOut: function () {
                                document.body.style.cursor = 'default';
                            }
                        },
                        colorByPoint: false,
                        color: '#007ad0',
                        dataLabels: {
                            color: 'white',
                            style: {
                                fontSize: '9px'
                            }
                        },
                        borderColor: 'white'
                    }],
                tooltip: {
                    outside: true,
                    formatter: function () {
                        if (_this.point['cycles'] !== '') {
                            if (_this.point['cycles'] !== undefined && _this.point['cycles'] !== null) {
                                return '<b>WARNING: Cycles detected between ' + _this.point['cycles'] + '</b>';
                            }
                            else {
                                if (_this.point['fromNode'] !== undefined) {
                                    return _this.point['fromNode']['name'].split('-')[1];
                                }
                                else {
                                    return _this.point['name'] + '<br/><b>' + _this.point['title'] + '</b>';
                                }
                            }
                        }
                        else {
                            if (_this.point['fromNode'] !== undefined) {
                                return _this.point['fromNode']['name'].split('-')[1];
                            }
                            else {
                                return _this.point['name'] + '<br/><b>' + _this.point['title'] + '</b>';
                            }
                        }
                    }
                },
                exporting: {
                    allowHTML: false,
                    sourceWidth: 800,
                    sourceHeight: 600
                },
                credits: {
                    enabled: false
                },
            });
        }
    };
    DetailComponent.prototype.prepareOrganizationalChart = function (relationship, inputData) {
        if (relationship.length === 0) {
            inputData.push([this.entityName, this.entityName]);
        }
        else {
            this.counter += relationship.length;
            for (var i = 0; i < relationship.length; i++) {
                this.corporateRisk += relationship[i].defaultRisk;
                if (relationship[i].documentStatus === 'UNEVALUATED') {
                    // tslint:disable-next-line:max-line-length
                    inputData.push({ 'from': relationship[i].entity1.entityName, 'to': relationship[i].entity2.entityName, 'name': relationship[i].roleName, 'color': '#bdbdbd' });
                }
                else if (relationship[i].documentStatus === 'ACCEPTED') {
                    // tslint:disable-next-line:max-line-length
                    inputData.push({ 'from': relationship[i].entity1.entityName, 'to': relationship[i].entity2.entityName, 'name': relationship[i].roleName, 'color': '#72C245' });
                }
                else if (relationship[i].documentStatus === 'ESCALATED') {
                    // tslint:disable-next-line:max-line-length
                    inputData.push({ 'from': relationship[i].entity1.entityName, 'to': relationship[i].entity2.entityName, 'name': relationship[i].roleName, 'color': '#FDD32B' });
                }
                else {
                    // tslint:disable-next-line:max-line-length
                    inputData.push({ 'from': relationship[i].entity1.entityName, 'to': relationship[i].entity2.entityName, 'name': relationship[i].roleName, 'color': '#D60000' });
                }
                if (relationship[i].entity1.roles.length > 0) {
                    this.prepareOrganizationalChart(relationship[i].entity1.roles, inputData);
                }
                if (relationship[i].entity2.roles.length > 0) {
                    this.prepareOrganizationalChart(relationship[i].entity2.roles, inputData);
                }
            }
        }
        this.drawOrganizationalChart(inputData);
        this.corporateRiskAverage = this.corporateRisk / this.counter;
    };
    DetailComponent.prototype.drawOrganizationalChart = function (inputData) {
        this.chart2 = new Chart({
            chart: {
                height: '300px',
                inverted: true,
                type: 'organization',
                events: {
                    render: function () {
                        var div = document.getElementsByTagName('svg');
                        if (div !== null) {
                            var svgSize = div[1].getBoundingClientRect();
                            var xml = new XMLSerializer().serializeToString(div[1]);
                            var svg64 = btoa(unescape(encodeURIComponent(xml)));
                            var b64Start = 'data:image/svg+xml;base64,';
                            var image64_1 = b64Start + svg64;
                            var canvas_1 = document.createElement('canvas');
                            canvas_1.width = svgSize.width;
                            canvas_1.height = svgSize.height;
                            var ctx_1 = canvas_1.getContext('2d');
                            var img_1 = document.createElement('img');
                            img_1.setAttribute('src', 'data:image/svg+xml;base64,' + svg64);
                            img_1.setAttribute('alt', 'pieChart');
                            img_1.onload = function () {
                                ctx_1.drawImage(img_1, 0, 0);
                                image64_1 = canvas_1.toDataURL('image/png');
                                sessionStorage.setItem('organizationalChart', image64_1);
                            };
                        }
                    }
                }
            },
            plotOptions: {
                organization: {
                    dataLabels: {
                        enabled: true
                    },
                }
            },
            title: {
                text: ''
            },
            credits: {
                enabled: false
            },
            series: [{
                    type: undefined,
                    keys: ['from', 'to', 'name', 'color'],
                    label: {
                        enabled: true
                    },
                    data: inputData,
                    colorByPoint: false,
                    color: '#007ad0',
                    dataLabels: {
                        enabled: true,
                        style: {
                            color: 'white',
                            fontSize: '8px'
                        }
                    },
                    borderColor: 'white',
                    nodeWidth: 65
                }],
            tooltip: {
                enabled: true,
                outside: true
            }
        });
    };
    DetailComponent.prototype.sendOnboardingEmail = function () {
        var _this = this;
        this.onboardingLoading = true;
        this.kybService.sendOnboardingInvitation(this.entityId, true, false).subscribe(function (data) {
            _this.alertService.showSuccess('Email was sent.');
            _this.onboardingLoading = false;
        }, function (error) {
            _this.alertService.showError(error.error.errors[0].cause);
            _this.onboardingLoading = false;
        });
    };
    DetailComponent.prototype.sendOnboardingSMS = function () {
        var _this = this;
        this.onboardingLoading = true;
        this.kybService.sendOnboardingInvitation(this.entityId, false, true).subscribe(function (data) {
            _this.alertService.showSuccess('SMS was sent.');
            _this.onboardingLoading = false;
        }, function (error) { _this.onboardingLoading = false; });
    };
    DetailComponent.prototype.seeDetail = function (event) {
        if (event.indexOf('[SCREENING RESULT') !== -1) {
            var entityId = event.split('match ')[1];
            var name_1 = entityId.split('Entity:')[1];
            var encoded = encodeURIComponent(name_1);
            var origin_1 = window.location.origin;
            var url = origin_1 + '/search?q=' + encoded;
            window.open(url, '_blank');
        }
    };
    DetailComponent.prototype.fullReport = function () {
        var url = '';
        // tslint:disable-next-line:max-line-length
        url = 'https://loadtest.kycbox.io/fullReport?firstName=' + this.entityResponse.data.primaryName;
        window.open(url, '_blank');
    };
    DetailComponent.prototype.formatDateForWebUI = function (date) {
        var onlyDate = date.split('T')[0];
        return onlyDate;
    };
    DetailComponent.prototype.edit = function () {
        this.router.navigate(['/structure-builder'], { queryParams: { entityId: this.username } });
    };
    DetailComponent.prototype.deleteEntity = function () {
        var _this = this;
        this.kybService.getOrphanedEntities(this.username).subscribe(function (entityData) {
            _this.deleteEntityResponse = entityData;
            if (_this.deleteEntityResponse.data.length === 0) {
                if (window.confirm('Are you sure you want to delete this entity?')) {
                    _this.kybService.deleteEntity(_this.username).subscribe(function (data) {
                        if (data) {
                            _this.router.navigate(['/enterprise']);
                        }
                    }, function (error) { });
                }
            }
            else {
                var entitiesList = '';
                for (var i = 0; i < _this.deleteEntityResponse.data.length; i++) {
                    entitiesList += ' ' + _this.deleteEntityResponse.data[i].primaryName;
                }
                if (window.confirm('If you delete this entity, entities ' + entitiesList + ' will become orphaned (will not have any connections to any other entities in the system). Are you sure you want to delete it?')) {
                    _this.kybService.deleteEntity(_this.username).subscribe(function (data) {
                        if (data) {
                            _this.router.navigate(['/enterprise']);
                        }
                    }, function (error) { });
                }
            }
        }, function (error) { });
    };
    DetailComponent.prototype.deleteRole = function (roleId) {
        var _this = this;
        if (window.confirm("Are you sure you want to delete this role? This action can't be taken back.")) {
            this.kybService.deleteRole(roleId).subscribe(function (data) {
                _this.alertService.showSuccess('Role successfully deleted.');
                _this.ngOnInit();
                _this.drawNewControlChart(_this.controlResponse.data);
                _this.drawNewUBOChart(_this.UBOResponse.data);
            }, function (error) { });
        }
    };
    DetailComponent.prototype.deleteDocument = function (document) {
        var _this = this;
        if (window.confirm("Are you sure you want to delete this document? This action can't be taken back.")) {
            this.kybService.updateDocumentMetadata(document.documentId, null, 'DELETED', null, null, null).subscribe(function (data) {
                _this.alertService.showSuccess('Document deleted.');
                _this.ngOnInit();
                _this.drawNewControlChart(_this.controlResponse.data);
                _this.drawNewUBOChart(_this.UBOResponse.data);
            }, function (error) { });
        }
    };
    return DetailComponent;
}());
export { DetailComponent };
