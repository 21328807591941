/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./companyForm.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./companyForm.component";
import * as i3 from "../../../../../_services/calls.service";
import * as i4 from "../../../../../_services/data.service";
import * as i5 from "../../../../../_services/kyb.service";
import * as i6 from "../../../../../_services/alert.service";
var styles_CompanyFormComponent = [i0.styles];
var RenderType_CompanyFormComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CompanyFormComponent, data: {} });
export { RenderType_CompanyFormComponent as RenderType_CompanyFormComponent };
export function View_CompanyFormComponent_0(_l) { return i1.ɵvid(0, [], null, null); }
export function View_CompanyFormComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-company-form", [], null, null, null, View_CompanyFormComponent_0, RenderType_CompanyFormComponent)), i1.ɵdid(1, 245760, null, 0, i2.CompanyFormComponent, [i3.CallsService, i4.DataService, i5.KYBService, i6.AlertService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var CompanyFormComponentNgFactory = i1.ɵccf("app-company-form", i2.CompanyFormComponent, View_CompanyFormComponent_Host_0, { currentEntity: "currentEntity", events: "events" }, {}, []);
export { CompanyFormComponentNgFactory as CompanyFormComponentNgFactory };
