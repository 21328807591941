
    <div class='modal-header'>
      <h4 class='modal-title pull-left'>Manual PEP Risk Adjustment</h4>
      <button type='button' class='close pull-right' aria-label='Close' (click)='bsModalRef.hide()'>
        <span aria-hidden='true'>&times;</span>
      </button>
    </div>
    <div class='modal-body'>
      <label style='font-weight: bold;'> This entity's risk is: * </label>
        <div class='radio'>
          <label>
              <input type='radio' [(ngModel)]='riskLevel' value='LOW'>
              Not a PEP
          </label>
        </div>
        <div class='radio'>
          <label>
            <input type='radio' [(ngModel)]='riskLevel' value='MEDIUM'>
            Medium Risk PEP
          </label>
        </div>
        <div class='radio'>
          <label>
          <input type='radio' [(ngModel)]='riskLevel' value='HIGH'>
          High Risk PEP
          </label>
        </div>
        <div class='radio'>
          <label>
          <input type='radio' [(ngModel)]='riskLevel' value='REJECTED'>
          Unacceptable PEP
          </label>
        </div>
      <label style='font-weight: bold;'> Please enter the justification for this action: * <textarea class='form-control input-md' style='border: 1px black solid; border-radius: 0px; max-width: 100%; font-weight: normal;' [(ngModel)]='justification'></textarea></label>
    </div>
    <div class='modal-footer' style='text-align: right;'>
      <button class='btn btn-danger btn-delete' (click)='cancel()' style='width: 20%;padding: 5px;margin-top: -3px;' [disabled]='loading'>Cancel</button>
      <button class='btn btn-success' (click)='adjustAndLog()' [disabled]='riskLevel == "" || justification == "" || loading' style='width: 20%;padding: 5px;margin-top: -3px; text-wrap: wrap'>Adjust and Log</button>
    </div>
  