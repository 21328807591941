/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./report.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./report.component";
import * as i3 from "../../../../_services/webAlert.service";
import * as i4 from "../../../../_services/webData.service";
import * as i5 from "../../../../_services/search.service";
var styles_ReportComponent = [i0.styles];
var RenderType_ReportComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ReportComponent, data: {} });
export { RenderType_ReportComponent as RenderType_ReportComponent };
export function View_ReportComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["style", "float: right;"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "button", [["class", "btn btn-info"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.generatePrompt() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["GENERATE FINAL REPORT"])), (_l()(), i1.ɵeld(3, 0, null, null, 0, "div", [["id", "pieChartImage"]], null, null, null, null, null))], null, null); }
export function View_ReportComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "report", [], null, null, null, View_ReportComponent_0, RenderType_ReportComponent)), i1.ɵdid(1, 114688, null, 0, i2.ReportComponent, [i3.WebAlertService, i4.WebDataService, i5.SearchService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ReportComponentNgFactory = i1.ɵccf("report", i2.ReportComponent, View_ReportComponent_Host_0, {}, {}, []);
export { ReportComponentNgFactory as ReportComponentNgFactory };
