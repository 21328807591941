import { Component, Output, EventEmitter, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { WebDataService } from '../../../../_services/index';
import { config } from '../../../../../assets/config/configuration';
import { FullSearch } from '../../../../_models/fullSearch';

@Component({
    moduleId: module.id,
    // tslint:disable-next-line:component-selector
    selector: 'new-search',
    templateUrl: 'newSearch.component.html',
    styleUrls: [ './newSearch.component.css' ]
})

export class NewSearchComponent implements OnInit {

  error: string;
  tabs = {};
  loadingImg = config.loadingGif;
  loading_search = false;
  isFullSearch = false;
  model: FullSearch;
  searchQuery: string;
  encodedQuery: string;
  obj: any = {};
  loading = false;
  firstName = '';
  lastName = '';
  dateOfBirth = '';
  country = '';
  pepHits: any = {};
  sanctionHits: any = {};

  results: any = {};
  reportResults: any = {};

  @Output() newSearchEvent = new EventEmitter();
  @Output() newFullSearchEvent = new EventEmitter();

  constructor (
    private router: Router,
    private route: ActivatedRoute,
    private webDataService: WebDataService
  ) {}

  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      this.firstName = params['firstName'];
      this.lastName = params['lastName'];
      this.dateOfBirth = params['dateOfBirth'];
      this.country = params['country'];
    });
  }

  receiveEvents($event) {
    this.firstName = $event.firstName;
    this.lastName = $event.lastName;
    this.dateOfBirth = $event.dob;

    if (this.dateOfBirth !== undefined) {
      this.dateOfBirth = this.dateOfBirth.replace('/', '-');
      this.dateOfBirth = this.dateOfBirth.replace('/', '-');
    }

    this.country = $event.country;
    this.loading = false;
  }

  search() {
    if (this.dateOfBirth === '') {
      this.dateOfBirth = undefined;
    }

    this.results = {};
    this.reportResults = {};
    this.reportResults.articles = {};
    this.reportResults.pepHits = {};
    this.reportResults.sanctionHits = {};
    this.pepHits = {};

    this.webDataService.setCurrentPEPHits(this.reportResults.pepHits);
    this.webDataService.setCurrentResults(this.results);
    this.webDataService.setCurrentReportResults(this.reportResults);
    this.webDataService.setCurrentSanctionHits(this.reportResults.sanctionHits);

    if (this.country === undefined || this.dateOfBirth === undefined) {
      this.webDataService.setIsFullSearch(false);
      this.webDataService.setfirstName(this.firstName);
      this.webDataService.setlastName(this.lastName);
      this.webDataService.setCountry('');
      this.webDataService.setQuery(this.firstName + ' ' + this.lastName);

      if ((this.country === undefined || this.country === '') && this.dateOfBirth === undefined) {
        this.results = {};
        this.reportResults = {};
        this.pepHits = {};
        this.sanctionHits = {};

        this.webDataService.setCurrentPEPHits(this.pepHits);
        this.webDataService.setCurrentResults(this.results);
        this.webDataService.setCurrentReportResults(this.reportResults);
        this.router.navigate(['/fullReport'], { queryParams: { firstName: this.firstName, lastName: this.lastName } });
      }

      if (this.country !== undefined) {
        this.webDataService.setCountry(this.country);
        this.results = {};
        this.reportResults = {};
        this.pepHits = {};
        this.sanctionHits = {};
        this.webDataService.setCurrentPEPHits(this.pepHits);
        this.webDataService.setCurrentSanctionHits(this.sanctionHits);
        this.webDataService.setCurrentResults(this.results);
        this.webDataService.setCurrentReportResults(this.reportResults);
        // tslint:disable-next-line:max-line-length
        this.router.navigate(['/fullReport'], { queryParams: { firstName: this.firstName, lastName: this.lastName, country: this.country } });
      }

      if (this.dateOfBirth !== undefined) {
        this.webDataService.setDob(this.dateOfBirth);
        this.results = {};
        this.reportResults = {};
        this.pepHits = {};
        this.sanctionHits = {};
        this.webDataService.setCurrentPEPHits(this.pepHits);
        this.webDataService.setCurrentSanctionHits(this.sanctionHits);
        this.webDataService.setCurrentResults(this.results);
        this.webDataService.setCurrentReportResults(this.reportResults);
        // tslint:disable-next-line:max-line-length
        this.router.navigate(['/fullReport'], { queryParams: { firstName: this.firstName, lastName: this.lastName, dateOfBirth: this.dateOfBirth } });
      }

      this.newSearchEvent.emit();
    } else {
        this.webDataService.setIsFullSearch(true);
        this.webDataService.setfirstName(this.firstName);
        this.webDataService.setlastName(this.lastName);
        this.webDataService.setDob(this.dateOfBirth);
        this.webDataService.setCountry(this.country);
        this.webDataService.setQuery(this.firstName + ' ' + this.lastName);
        this.results = {};
        this.reportResults = {};
        this.pepHits = {};
        this.sanctionHits = {};
        this.webDataService.setCurrentPEPHits(this.pepHits);
        this.webDataService.setCurrentSanctionHits(this.sanctionHits);
        this.webDataService.setCurrentResults(this.results);
        this.webDataService.setCurrentReportResults(this.reportResults);
        this.newFullSearchEvent.emit({ firstName: this.firstName, lastName: this.lastName, dob: this.dateOfBirth, country: this.country });
        // tslint:disable-next-line:max-line-length
        this.router.navigate(['/fullReport'], { queryParams: {firstName: this.firstName, lastName: this.lastName, dateOfBirth: this.dateOfBirth, country: this.country }});
    }
  }
}
