import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class SearchService {

  private httpOptions = {};
  private formData: FormData;
  private pdfFormData: FormData;

  constructor(private http: HttpClient) {}

  fullSearch(firstName: any, lastName: any, dateOfBirth: any, country: any) {
    this.formData = new FormData();
    this.formData.append('firstName', firstName);
    this.formData.append('lastName', lastName);
    this.formData.append('dob', dateOfBirth);
    this.formData.append('country', country);

    this.httpOptions = {
      headers: new HttpHeaders({ 'X-Auth-Token': JSON.parse(localStorage.getItem('auth_token')) })
    };

    return this.http.post('/api/webserver/riskEval/eval', this.formData, this.httpOptions);
  }

  entitySanctionDetails(searchQuery: any) {
    this.httpOptions = {
      headers: new HttpHeaders({ 'X-Auth-Token': JSON.parse(localStorage.getItem('auth_token')) })
    };

    return this.http.get('/api/webserver/entityDetails/sanction?search_query=' + searchQuery, this.httpOptions);
  }

  entityPEPDetails(searchQuery: any) {
    this.httpOptions = {
      headers: new HttpHeaders({ 'X-Auth-Token': JSON.parse(localStorage.getItem('auth_token')) })
    };

    return this.http.get('/api/webserver/entityDetails/pep?search_query=' + searchQuery, this.httpOptions);
  }

  mediaEntityName(searchQuery: any) {
    this.httpOptions = {
      headers: new HttpHeaders({ 'X-Auth-Token': JSON.parse(localStorage.getItem('auth_token')) })
    };

    return this.http.get('/api/webserver/media/byEntityName?entity_name=' + searchQuery, this.httpOptions);
  }

  search(q: any, pageIndex: any, sortOrder: any, start: any, end: any, filters: any, languageFilters: any) {
    this.httpOptions = {
      headers: new HttpHeaders({ 'X-Auth-Token': JSON.parse(localStorage.getItem('auth_token')) })
    };

    let route = '';

    route += '/api/webserver/search?q=' + q;

    if (pageIndex !== null) {
        route += '&pageIndex=' + pageIndex;
    }

    if (sortOrder !== null) {
        route += '&sortOrder=' + sortOrder;
    }

    if (start !== null) {
        route += '&start=' + start;
    }

    if (end !== null) {
        route += '&end=' + end;
    }

    if (filters !== null) {
        route += '&filters=' + filters;
    }

    if (languageFilters !== null) {
        route += '&filters=' + languageFilters;
    }

    return this.http.get(route, this.httpOptions);
  }

  countryRisk(countryCode: any) {
    this.httpOptions = {
      headers: new HttpHeaders({ 'X-Auth-Token': JSON.parse(localStorage.getItem('auth_token')) })
    };

    return this.http.get('/api/webserver/country/riskLookup?iso_country_code=' + countryCode, this.httpOptions);
  }

  getPdfReport(inputHTML: string) {
    this.httpOptions = {
      headers: new HttpHeaders({ 'X-Auth-Token': JSON.parse(localStorage.getItem('auth_token')) })
    };

    this.pdfFormData = new FormData();
    this.pdfFormData.append('htmlInput', inputHTML);

    return this.http.post('/api/webserver/getPdfReport', this.pdfFormData, this.httpOptions);
  }

  getTopicsJSON(): Observable<any> {
    return this.http.get('./assets/topics.json');
  }
}
