/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./information.component.css.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./information.component";
import * as i3 from "@angular/router";
import * as i4 from "../../../../_services/search.service";
import * as i5 from "../../../../_services/webData.service";
var styles_InformationComponent = [i0.styles];
var RenderType_InformationComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_InformationComponent, data: {} });
export { RenderType_InformationComponent as RenderType_InformationComponent };
export function View_InformationComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["First name: ", ""])), (_l()(), i1.ɵeld(2, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["Last name: ", ""])), (_l()(), i1.ɵeld(4, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, ["Date of birth: ", ""])), (_l()(), i1.ɵeld(6, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(7, null, ["Country: ", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.firstName; _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.lastName; _ck(_v, 3, 0, currVal_1); var currVal_2 = _co.dob; _ck(_v, 5, 0, currVal_2); var currVal_3 = _co.country; _ck(_v, 7, 0, currVal_3); }); }
export function View_InformationComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "information", [], null, null, null, View_InformationComponent_0, RenderType_InformationComponent)), i1.ɵdid(1, 114688, null, 0, i2.InformationComponent, [i3.ActivatedRoute, i4.SearchService, i5.WebDataService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var InformationComponentNgFactory = i1.ɵccf("information", i2.InformationComponent, View_InformationComponent_Host_0, {}, { gotResultsEvent: "gotResultsEvent" }, []);
export { InformationComponentNgFactory as InformationComponentNgFactory };
