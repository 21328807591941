<div class="col-md-12" style="padding-left: 0px;" xmlns:height="http://www.w3.org/1999/xhtml">
   <div class="row">
      <div class="col-md-12">
         <p>Select the role you want to attach this document to: <b class="required">*</b></p><br>
         <select [(ngModel)]="roleId" (change)="roleIdChanged()">
            <option *ngFor="let role of roles" [value]="role.id">[{{role.id}}] {{role?.roleType?.defaultRoleName}} -
               (<span *ngIf="role.e1Instance != null">Entity 1: {{role?.e1Instance?.primaryName}}</span><span *ngIf="role.e2Instance != null">, Entity 2:
                  {{role?.e2Instance?.primaryName}}</span>)</option>
         </select>
         <div style="height: 20px;"></div>
         <p>Select the type of document: <b class="required">*</b></p><br>
         <ng-select [(ngModel)]="documentType" class="custom" [items]="documentTypes" bindLabel="description" bindValue="id" appendTo="body" (change)="typeChanged()"></ng-select>
         <div class="alert alert-danger" [hidden]="documentType">Type of document is required.</div>
         <div *ngIf="showExisting">
            <div style="height: 20px;"></div>
            <p>Select an existing document: <b class="required">*</b></p><br>
            <select [(ngModel)]="existingDocument" (change)="existingDocumentChanged()">
               <option *ngFor="let document of existingDocuments" [value]="document.id">
                  {{document.documentType.description | shorterText}} valid until {{document.validTo | prettyTimestamp}}
               </option>
            </select>
            <div class="alert alert-danger" [hidden]="existingDocument">Existing document is required.</div>
            <div style="height: 20px;"></div>
         </div>
         <div style="height: 15px;"></div>
         <div>
            <label> Valid until (YYYY-MM-DD): <b class="required">*</b> </label> <input class="form-control input-md"
            #datepickerYMD="bsDatepicker" bsDatepicker [(ngModel)]="validTo"
            [bsConfig]="{ dateInputFormat: 'YYYY-MM-DD' }" (change)="checkValidTo()">
         </div>
         <div class="alert alert-danger" [hidden]="validTo">Valid date is required.</div>
         <div style="height: 15px;"></div>
         <div *ngIf="!showPersonForm && !showCompanyForm && existingDocument == ''">
            <label> Select file: <b class="required">*</b></label>
            <input class="form-control input-md" type="file" (change)="processDocument($event.target.files,'file1')">
            <div style="height: 15px;"></div>
            <ng-container *ngIf="multipleDocuments">
               <label> Select file:</label>
               <input class="form-control input-md" type="file" (change)="processDocument($event.target.files,'file2')">
               <div style="height: 15px;"></div>
            </ng-container>
            <div class="alert alert-danger" [hidden]="fileSelected">File is required.</div>
            <div style="height: 10px;"></div>
            <div>
               <label> Description:</label> <input class="form-control input-md" type="text" [(ngModel)]="docDescription">
            </div>
         </div>
         <div *ngIf="showPersonForm">
            <div style="height: 20px;"></div>
            <label> Date of birth (DD/MM/YYYY): <b class="required">*</b> <input class="form-control input-md"
                  #datepickerYMD="bsDatepicker" bsDatepicker [(ngModel)]="dateOfBirth"
                  [bsConfig]="{ dateInputFormat: 'DD/MM/YYYY' }">
            </label>&#160;&#160; <label> Address: <b class="required">*</b> <input class="form-control input-md"
                  type="text" [style.width]="'421px'" [(ngModel)]="address">
            </label>&#160;&#160; <div style="height: 10px;"></div>
            <label> Nationality: <b class="required">*</b>
               <ng-select id="nationality" name="nationality"
                  style="border: 1px solid #ccc;border-radius: 0px;width: 110%" [items]="nationalities"
                  #nationalityy="ngModel" [searchable]="true" [multiple]="true" [(ngModel)]="nationality"
                  appendTo="body" bindLabel="label" bindValue="value" placeholder="Start typing a nationality..."
                  (change)=isNationalityFilled()>
               </ng-select>
            </label>&#160;&#160; <div style="height: 10px;"></div>
            <label> Email: <b class="required">*</b> <input class="form-control input-md" type="text"
                  [(ngModel)]="personEmail"></label>&#160;&#160; <label> Phone: <b class="required">*</b> <input
                  class="form-control input-md" type="text" [(ngModel)]="personPhone"></label>&#160;&#160; <div
               style="height: 20px;"></div>
         </div>
         <div *ngIf="showCompanyForm">
            <div style="height: 20px;"></div>
            <label> Address: <b class="required">*</b> <input class="form-control input-md" [style.width]="'421px'"
                  [style.width]="'421px'" type="text" [(ngModel)]="address">
            </label>&#160;&#160; <label> VAT number: <b class="required">*</b> <input class="form-control input-md"
                  type="text" [(ngModel)]="vat">
            </label>&#160;&#160; <br>
            <label> Tax country: <b class="required">*</b>
               <ng-select [searchable]="true" id="taxCountry" appendTo="body" class="ng-control input-md"
                  style="border: 1px solid #ccc;width: 420%;border-radius: 0;" name="taxCountry"
                  [(ngModel)]="taxCountry" placeholder="Start typing a country..." (change)=isTaxCountryFilled()>
                  <ng-option value="AF">Afghanistan</ng-option>
                  <ng-option value="AL">Albania</ng-option>
                  <ng-option value="DZ">Algeria</ng-option>
                  <ng-option value="AS">American Samoa</ng-option>
                  <ng-option value="AD">Andorra</ng-option>
                  <ng-option value="AO">Angola</ng-option>
                  <ng-option value="AI">Anguilla</ng-option>
                  <ng-option value="AQ">Antarctica</ng-option>
                  <ng-option value="AG">Antigua and Barbuda</ng-option>
                  <ng-option value="AR">Argentina</ng-option>
                  <ng-option value="AM">Armenia</ng-option>
                  <ng-option value="AW">Aruba</ng-option>
                  <ng-option value="AU">Australia</ng-option>
                  <ng-option value="AT">Austria</ng-option>
                  <ng-option value="AZ">Azerbaijan</ng-option>
                  <ng-option value="BS">Bahamas</ng-option>
                  <ng-option value="BH">Bahrain</ng-option>
                  <ng-option value="BD">Bangladesh</ng-option>
                  <ng-option value="BB">Barbados</ng-option>
                  <ng-option value="BY">Belarus</ng-option>
                  <ng-option value="BE">Belgium</ng-option>
                  <ng-option value="BZ">Belize</ng-option>
                  <ng-option value="BJ">Benin</ng-option>
                  <ng-option value="BM">Bermuda</ng-option>
                  <ng-option value="BT">Bhutan</ng-option>
                  <ng-option value="BO">Bolivia</ng-option>
                  <ng-option value="BQ">Bonaire, Sint Eustatius and Saba</ng-option>
                  <ng-option value="BA">Bosnia and Herzegovina</ng-option>
                  <ng-option value="BW">Botswana</ng-option>
                  <ng-option value="BV">Bouvet Island</ng-option>
                  <ng-option value="BR">Brazil</ng-option>
                  <ng-option value="IO">British Indian Ocean Territory</ng-option>
                  <ng-option value="BN">Brunei Darussalam</ng-option>
                  <ng-option value="BG">Bulgaria</ng-option>
                  <ng-option value="BF">Burkina Faso</ng-option>
                  <ng-option value="BI">Burundi</ng-option>
                  <ng-option value="KH">Cambodia</ng-option>
                  <ng-option value="CM">Cameroon</ng-option>
                  <ng-option value="CA">Canada</ng-option>
                  <ng-option value="CV">Cape Verde</ng-option>
                  <ng-option value="KY">Cayman Islands</ng-option>
                  <ng-option value="CF">Central African Republic</ng-option>
                  <ng-option value="TD">Chad</ng-option>
                  <ng-option value="CL">Chile</ng-option>
                  <ng-option value="CN">China</ng-option>
                  <ng-option value="CX">Christmas Island</ng-option>
                  <ng-option value="CC">Cocos (Keeling) Islands</ng-option>
                  <ng-option value="CO">Colombia</ng-option>
                  <ng-option value="KM">Comoros</ng-option>
                  <ng-option value="CG">Congo</ng-option>
                  <ng-option value="CD">Congo, the Democratic Republic of the</ng-option>
                  <ng-option value="CK">Cook Islands</ng-option>
                  <ng-option value="CR">Costa Rica</ng-option>
                  <ng-option value="CI">Côte d'Ivoire</ng-option>
                  <ng-option value="HR">Croatia</ng-option>
                  <ng-option value="CU">Cuba</ng-option>
                  <ng-option value="CW">Curaçao</ng-option>
                  <ng-option value="CY">Cyprus</ng-option>
                  <ng-option value="CZ">Czech Republic</ng-option>
                  <ng-option value="DK">Denmark</ng-option>
                  <ng-option value="DJ">Djibouti</ng-option>
                  <ng-option value="DM">Dominica</ng-option>
                  <ng-option value="DO">Dominican Republic</ng-option>
                  <ng-option value="EC">Ecuador</ng-option>
                  <ng-option value="EG">Egypt</ng-option>
                  <ng-option value="SV">El Salvador</ng-option>
                  <ng-option value="GQ">Equatorial Guinea</ng-option>
                  <ng-option value="ER">Eritrea</ng-option>
                  <ng-option value="EE">Estonia</ng-option>
                  <ng-option value="ET">Ethiopia</ng-option>
                  <ng-option value="FK">Falkland Islands (Malvinas)</ng-option>
                  <ng-option value="FO">Faroe Islands</ng-option>
                  <ng-option value="FJ">Fiji</ng-option>
                  <ng-option value="FI">Finland</ng-option>
                  <ng-option value="FR">France</ng-option>
                  <ng-option value="GF">French Guiana</ng-option>
                  <ng-option value="PF">French Polynesia</ng-option>
                  <ng-option value="TF">French Southern Territories</ng-option>
                  <ng-option value="GA">Gabon</ng-option>
                  <ng-option value="GM">Gambia</ng-option>
                  <ng-option value="GE">Georgia</ng-option>
                  <ng-option value="DE">Germany</ng-option>
                  <ng-option value="GH">Ghana</ng-option>
                  <ng-option value="GI">Gibraltar</ng-option>
                  <ng-option value="EL">Greece</ng-option>
                  <ng-option value="GL">Greenland</ng-option>
                  <ng-option value="GD">Grenada</ng-option>
                  <ng-option value="GP">Guadeloupe</ng-option>
                  <ng-option value="GU">Guam</ng-option>
                  <ng-option value="GT">Guatemala</ng-option>
                  <ng-option value="GG">Guernsey</ng-option>
                  <ng-option value="GN">Guinea</ng-option>
                  <ng-option value="GW">Guinea-Bissau</ng-option>
                  <ng-option value="GY">Guyana</ng-option>
                  <ng-option value="HT">Haiti</ng-option>
                  <ng-option value="HM">Heard Island and McDonald Islands</ng-option>
                  <ng-option value="VA">Holy See (Vatican City State)</ng-option>
                  <ng-option value="HN">Honduras</ng-option>
                  <ng-option value="HK">Hong Kong</ng-option>
                  <ng-option value="HU">Hungary</ng-option>
                  <ng-option value="IS">Iceland</ng-option>
                  <ng-option value="IN">India</ng-option>
                  <ng-option value="ID">Indonesia</ng-option>
                  <ng-option value="IR">Iran, Islamic Republic of</ng-option>
                  <ng-option value="IQ">Iraq</ng-option>
                  <ng-option value="IE">Ireland</ng-option>
                  <ng-option value="IM">Isle of Man</ng-option>
                  <ng-option value="IL">Israel</ng-option>
                  <ng-option value="IT">Italy</ng-option>
                  <ng-option value="JM">Jamaica</ng-option>
                  <ng-option value="JP">Japan</ng-option>
                  <ng-option value="JE">Jersey</ng-option>
                  <ng-option value="JO">Jordan</ng-option>
                  <ng-option value="KZ">Kazakhstan</ng-option>
                  <ng-option value="KE">Kenya</ng-option>
                  <ng-option value="KI">Kiribati</ng-option>
                  <ng-option value="KP">Korea, Democratic People's Republic of</ng-option>
                  <ng-option value="KR">Korea, Republic of</ng-option>
                  <ng-option value="KW">Kuwait</ng-option>
                  <ng-option value="KG">Kyrgyzstan</ng-option>
                  <ng-option value="LA">Lao People's Democratic Republic</ng-option>
                  <ng-option value="LV">Latvia</ng-option>
                  <ng-option value="LB">Lebanon</ng-option>
                  <ng-option value="LS">Lesotho</ng-option>
                  <ng-option value="LR">Liberia</ng-option>
                  <ng-option value="LY">Libya</ng-option>
                  <ng-option value="LI">Liechtenstein</ng-option>
                  <ng-option value="LT">Lithuania</ng-option>
                  <ng-option value="LU">Luxembourg</ng-option>
                  <ng-option value="MO">Macao</ng-option>
                  <ng-option value="MK">North Macedonia</ng-option>
                  <ng-option value="MG">Madagascar</ng-option>
                  <ng-option value="MW">Malawi</ng-option>
                  <ng-option value="MY">Malaysia</ng-option>
                  <ng-option value="MV">Maldives</ng-option>
                  <ng-option value="ML">Mali</ng-option>
                  <ng-option value="MT">Malta</ng-option>
                  <ng-option value="MH">Marshall Islands</ng-option>
                  <ng-option value="MQ">Martinique</ng-option>
                  <ng-option value="MR">Mauritania</ng-option>
                  <ng-option value="MU">Mauritius</ng-option>
                  <ng-option value="YT">Mayotte</ng-option>
                  <ng-option value="MX">Mexico</ng-option>
                  <ng-option value="FM">Micronesia, Federated States of</ng-option>
                  <ng-option value="MD">Moldova, Republic of</ng-option>
                  <ng-option value="MC">Monaco</ng-option>
                  <ng-option value="MN">Mongolia</ng-option>
                  <ng-option value="ME">Montenegro</ng-option>
                  <ng-option value="MS">Montserrat</ng-option>
                  <ng-option value="MA">Morocco</ng-option>
                  <ng-option value="MZ">Mozambique</ng-option>
                  <ng-option value="MM">Myanmar</ng-option>
                  <ng-option value="NA">Namibia</ng-option>
                  <ng-option value="NR">Nauru</ng-option>
                  <ng-option value="NP">Nepal</ng-option>
                  <ng-option value="NL">Netherlands</ng-option>
                  <ng-option value="NC">New Caledonia</ng-option>
                  <ng-option value="NZ">New Zealand</ng-option>
                  <ng-option value="NI">Nicaragua</ng-option>
                  <ng-option value="NE">Niger</ng-option>
                  <ng-option value="NG">Nigeria</ng-option>
                  <ng-option value="NU">Niue</ng-option>
                  <ng-option value="NF">Norfolk Island</ng-option>
                  <ng-option value="MP">Northern Mariana Islands</ng-option>
                  <ng-option value="NO">Norway</ng-option>
                  <ng-option value="OM">Oman</ng-option>
                  <ng-option value="PK">Pakistan</ng-option>
                  <ng-option value="PW">Palau</ng-option>
                  <ng-option value="PS">Palestinian Territory, Occupied</ng-option>
                  <ng-option value="PA">Panama</ng-option>
                  <ng-option value="PG">Papua New Guinea</ng-option>
                  <ng-option value="PY">Paraguay</ng-option>
                  <ng-option value="PE">Peru</ng-option>
                  <ng-option value="PH">Philippines</ng-option>
                  <ng-option value="PN">Pitcairn</ng-option>
                  <ng-option value="PL">Poland</ng-option>
                  <ng-option value="PT">Portugal</ng-option>
                  <ng-option value="PR">Puerto Rico</ng-option>
                  <ng-option value="QA">Qatar</ng-option>
                  <ng-option value="RE">Réunion</ng-option>
                  <ng-option value="RO">Romania</ng-option>
                  <ng-option value="RU">Russian Federation</ng-option>
                  <ng-option value="RW">Rwanda</ng-option>
                  <ng-option value="BL">Saint Barthélemy</ng-option>
                  <ng-option value="SH">Saint Helena, Ascension and Tristan da Cunha</ng-option>
                  <ng-option value="KN">Saint Kitts and Nevis</ng-option>
                  <ng-option value="LC">Saint Lucia</ng-option>
                  <ng-option value="MF">Saint Martin (French part)</ng-option>
                  <ng-option value="PM">Saint Pierre and Miquelon</ng-option>
                  <ng-option value="VC">Saint Vincent and the Grenadines</ng-option>
                  <ng-option value="WS">Samoa</ng-option>
                  <ng-option value="SM">San Marino</ng-option>
                  <ng-option value="ST">Sao Tome and Principe</ng-option>
                  <ng-option value="SA">Saudi Arabia</ng-option>
                  <ng-option value="SN">Senegal</ng-option>
                  <ng-option value="RS">Serbia</ng-option>
                  <ng-option value="SC">Seychelles</ng-option>
                  <ng-option value="SL">Sierra Leone</ng-option>
                  <ng-option value="SG">Singapore</ng-option>
                  <ng-option value="SX">Sint Maarten (Dutch part)</ng-option>
                  <ng-option value="SK">Slovakia</ng-option>
                  <ng-option value="SI">Slovenia</ng-option>
                  <ng-option value="SB">Solomon Islands</ng-option>
                  <ng-option value="SO">Somalia</ng-option>
                  <ng-option value="ZA">South Africa</ng-option>
                  <ng-option value="GS">South Georgia and the South Sandwich Islands</ng-option>
                  <ng-option value="SS">South Sudan</ng-option>
                  <ng-option value="ES">Spain</ng-option>
                  <ng-option value="LK">Sri Lanka</ng-option>
                  <ng-option value="SD">Sudan</ng-option>
                  <ng-option value="SR">Suriname</ng-option>
                  <ng-option value="SJ">Svalbard and Jan Mayen</ng-option>
                  <ng-option value="SZ">Swaziland</ng-option>
                  <ng-option value="SE">Sweden</ng-option>
                  <ng-option value="CH">Switzerland</ng-option>
                  <ng-option value="SY">Syrian Arab Republic</ng-option>
                  <ng-option value="TW">Taiwan, Province of China</ng-option>
                  <ng-option value="TJ">Tajikistan</ng-option>
                  <ng-option value="TZ">Tanzania, United Republic of</ng-option>
                  <ng-option value="TH">Thailand</ng-option>
                  <ng-option value="TL">Timor-Leste</ng-option>
                  <ng-option value="TG">Togo</ng-option>
                  <ng-option value="TK">Tokelau</ng-option>
                  <ng-option value="TO">Tonga</ng-option>
                  <ng-option value="TT">Trinidad and Tobago</ng-option>
                  <ng-option value="TN">Tunisia</ng-option>
                  <ng-option value="TR">Turkey</ng-option>
                  <ng-option value="TM">Turkmenistan</ng-option>
                  <ng-option value="TC">Turks and Caicos Islands</ng-option>
                  <ng-option value="TV">Tuvalu</ng-option>
                  <ng-option value="UG">Uganda</ng-option>
                  <ng-option value="UA">Ukraine</ng-option>
                  <ng-option value="AE">United Arab Emirates</ng-option>
                  <ng-option value="UK">United Kingdom</ng-option>
                  <ng-option value="US">United States</ng-option>
                  <ng-option value="UM">United States Minor Outlying Islands</ng-option>
                  <ng-option value="UY">Uruguay</ng-option>
                  <ng-option value="UZ">Uzbekistan</ng-option>
                  <ng-option value="VU">Vanuatu</ng-option>
                  <ng-option value="VE">Venezuela, Bolivarian Republic of</ng-option>
                  <ng-option value="VN">Viet Nam</ng-option>
                  <ng-option value="VG">Virgin Islands, British</ng-option>
                  <ng-option value="VI">Virgin Islands, U.S.</ng-option>
                  <ng-option value="WF">Wallis and Futuna</ng-option>
                  <ng-option value="EH">Western Sahara</ng-option>
                  <ng-option value="YE">Yemen</ng-option>
                  <ng-option value="ZM">Zambia</ng-option>
                  <ng-option value="ZW">Zimbabwe</ng-option>
               </ng-select>
            </label>
            <div style="height: 20px;"></div>
            <p><b>Contact Person</b></p>
            <label> Name: <b class="required">*</b> <input class="form-control input-md" type="text"
                  [(ngModel)]="contactName"></label>&#160;&#160; <label> Email: <b class="required">*</b> <input
                  class="form-control input-md" type="text" [(ngModel)]="contactEmail"></label>&#160;&#160; <label>
               Phone: <b class="required">*</b> <input class="form-control input-md" type="text"
                  [(ngModel)]="contactPhone"></label>&#160;&#160;
         </div>
         <div style="height: 20px;"></div>
         <button *ngIf="showPersonForm" [disabled]="!validatePersonForm() || loading" class="btn btn-primary"
            (click)="uploadFormData()" style="background-color: #355B89;border-color: #355B89">Add Document</button><img
            *ngIf="loading && showPersonForm" [src]="loadingImg" style="height: 25px" />
         <button *ngIf="showCompanyForm" [disabled]="!validateCompanyForm() || loading" class="btn btn-primary"
            (click)="uploadFormData()" style="background-color: #355B89;border-color: #355B89">Add Document</button><img
            *ngIf="loading && showCompanyForm" [src]="loadingImg" style="height: 25px" />
         <button *ngIf="!showPersonForm && !showCompanyForm" class="btn btn-primary" (click)="upload()"
            [disabled]="loading || roleId == '' || documentType == '' || validTo == '' || !fileSelected"
            style="background-color: #355B89;border-color: #355B89">Add Document</button><img
            *ngIf="!showPersonForm && !showCompanyForm && loading" [src]="loadingImg" style="height: 25px" />
      </div>
   </div>
</div>
