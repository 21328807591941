import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { KYBService } from './kyb.service';

/**
 * Implements service for sharing data between components - not used right now
*/
@Injectable()
export class DataService {

    private statuses = new BehaviorSubject(null);
    public mrzData = new BehaviorSubject(null);
    public csvRow = new BehaviorSubject(null);
    public index = new BehaviorSubject(null);
    public counter = new BehaviorSubject(0);
    public imagesNo = new BehaviorSubject(0);
    public usersForReview = new BehaviorSubject(0);
    public thirdParty = new BehaviorSubject(0);
    public addDossierState = new BehaviorSubject<any>(null);
    public stack = new BehaviorSubject<any>({});
    public entityLevel = new BehaviorSubject(0);
    public role = new BehaviorSubject(null);
    public documentReady = new BehaviorSubject<boolean>(false);
    public entityId = new BehaviorSubject('');
    public delegates = new BehaviorSubject<any>([]);
    public documentIds = new BehaviorSubject<any>([]);
    public entityIdStructure = new BehaviorSubject<any>([]);
    public roleStructure = new BehaviorSubject<any>({});
    public refreshEvent = new BehaviorSubject(0);
    public closeModalEvent = new Subject();
    public closeDocumentEditModalEvent = new BehaviorSubject(null);
    public screeningRefresh = new BehaviorSubject(0);
    public toDo = new BehaviorSubject<any>({});
    public entityType = new BehaviorSubject(null);
    public entityStatus = new BehaviorSubject(null);
    public selfRoleId = new BehaviorSubject(null);
    public onboardingEntityId = new BehaviorSubject(null);
    public onboardingEntitySelfRoleId = new BehaviorSubject(null);
    public isRegistration = new BehaviorSubject(false);
    public roleId = new BehaviorSubject(null);
    public primaryName = new BehaviorSubject(null);
    public personPhone = new BehaviorSubject(null);
    public personEmail = new BehaviorSubject(null);
    public bulkSelfRoleId = new BehaviorSubject(null);
    public entityTags = new BehaviorSubject(null);
    public firstConnection = new BehaviorSubject(null);
    private personalForm = new BehaviorSubject<any>(null);

    currentStatuses = this.statuses.asObservable();
    currentMrzData = new BehaviorSubject(null);
    currentRow = this.csvRow.asObservable();
    currentIndex = this.index.asObservable();
    currentCount = this.counter.asObservable();
    currentImagesNo = this.imagesNo.asObservable();
    currentUsersForReview = this.usersForReview.asObservable();
    currentThirdParty = this.thirdParty.asObservable();
    currentAddDossierState = this.addDossierState.asObservable();
    currentStack = this.stack.asObservable();
    currentEntityLevel = this.entityLevel.asObservable();
    currentRole = this.role.asObservable();
    currentEntityId = this.entityId.asObservable();
    currentDelegates = this.delegates.asObservable();
    currentDocumentIds = this.delegates.asObservable();
    currentEntityIdStructure = this.entityIdStructure.asObservable();
    currentRoleStructure = this.roleStructure.asObservable();
    currentToDo = this.toDo.asObservable();
    currentEntityType = this.entityType.asObservable();
    currentEntityStatus = this.entityStatus.asObservable();
    currentSelfRoleId = this.selfRoleId.asObservable();
    currentOnboardingEntityId = this.onboardingEntityId.asObservable();
    currentOnboardingEntitySelfRoleId = this.onboardingEntitySelfRoleId.asObservable();
    currentRoleId = this.roleId.asObservable();
    currentPrimaryName = this.primaryName.asObservable();
    currentPersonPhone = this.personPhone.asObservable();
    currentPersonEmail = this.personEmail.asObservable();
    currentBulkSelfRoleId = this.bulkSelfRoleId.asObservable();
    currentEntityTags = this.entityTags.asObservable();
    currentFirstConnection = this.firstConnection.asObservable();
    currentPersonalForm = this.personalForm.asObservable();
    curentDocumentReady = this.documentReady.asObservable();

    toDoList: any = [];

    constructor(
        private http: HttpClient,
        private kybService: KYBService
    ) {}

    setEntityType(entityType: any) {
        this.entityType.next(entityType);
    }
    setDocumentReady(documentReady: boolean) {
        this.documentReady.next(documentReady);
    }
    setRoleId(roleId: any) {
        this.roleId.next(roleId);
    }

    setFirstConnection(isFirstCOnnection: boolean) {
        this.firstConnection.next(isFirstCOnnection);
    }

    setPersonalForm(val: any) {
        this.personalForm.next(val);
    }

    setBulkRoleId(selfRoleId: any) {
        this.bulkSelfRoleId.next(selfRoleId);
    }

    setPrimaryName(primaryName: any) {
        this.primaryName.next(primaryName);
    }

    setPersonPhone(personPhone: any) {
        this.personPhone.next(personPhone);
    }

    setPersonEmail(personEmail: any) {
        this.personEmail.next(personEmail);
    }

    setEntityStatus(entityStatus: any) {
        this.entityStatus.next(entityStatus);
    }

    setSelfRoleId(selfRoleId: any) {
        this.selfRoleId.next(selfRoleId);
    }

    setOnboardingEntityId(onboardingEntityId: any) {
        this.onboardingEntityId.next(onboardingEntityId);
    }

    setOnboardingEntitySelfRoleId(onboardingEntitySelfRoleId: any) {
        this.onboardingEntitySelfRoleId.next(onboardingEntitySelfRoleId);
    }

    setRoleStructure(roleStructure: any) {
        this.roleStructure.next(roleStructure);
    }

    setIsRegistration(isRegistration: any) {
        this.isRegistration.next(isRegistration);
    }

    setEntityTags(entityTags: any) {
        this.entityTags.next(entityTags);
    }

    updateToDo(entityIds: any) {
        entityIds = this.removeDuplicates(entityIds);
        this.toDo.next([]);
        this.toDoList = [];

        for (let i = 0; i < entityIds.length; i++) {
            this.kybService.getToDoList(entityIds[i]).subscribe(
                data => {
                    let response = <any>{};
                    response = data;
                    this.toDoList.push(response.data);
                },
                error => {
                }
            );
        }

        this.toDo.next(this.toDoList);
    }

    removeDuplicates(array) {
        return array.filter((a, b) => array.indexOf(a) === b);
    }

    triggerRefreshEvent(refreshEvent: any) {
        this.refreshEvent.next(refreshEvent);
    }

    triggerScreeningRefresh(screeningRefresh: any) {
        this.screeningRefresh.next(screeningRefresh);
    }

    triggerCloseModalEvent(closeEvent: any) {
        this.closeModalEvent.next(closeEvent);
    }

    triggerCloseEditDocumentModalEvent(closeEvent: any) {
        this.closeDocumentEditModalEvent.next(closeEvent);
    }

    setAddDossierState(addDossierState: any) {
        this.addDossierState.next(addDossierState);
    }

    setEntityIdStructure(entityIdStructure: any) {
        this.entityIdStructure.next(entityIdStructure);
    }

    setDocumentIds(documentIds: any) {
        this.documentIds.next(documentIds);
    }

    setDelegates(delegates: any) {
        this.delegates.next(delegates);
    }

    setCurrentEntityId(entityId: any) {
        this.entityId.next(entityId);
    }

    setCurrentRole(role: any) {
        this.role.next(role);
    }

    setCurrentEntityLevel(entityLevel: any) {
        this.entityLevel.next(entityLevel);
    }

    setCurrentStack(stack: any) {
        this.stack.next(stack);
    }

    setStatuses(statuses: any) {
        this.statuses.next(statuses);
    }

    setThirdParty(thirdParty: any) {
        this.thirdParty.next(thirdParty);
    }

    setMrzData(mrzData: any) {
        this.mrzData.next(mrzData);
    }

    setUsersForReview(usersForReview: any) {
        this.usersForReview.next(usersForReview);
    }

    setCsvRow(currentRow: any) {
        this.csvRow.next(currentRow);
    }

    setIndex(index: any) {
        this.index.next(index);
    }

    setCounter(counter: any) {
        this.counter.next(counter);
    }

    setImagesNo(imagesNo: any) {
        this.imagesNo.next(imagesNo);
    }
}
