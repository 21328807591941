import { Component, OnInit} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SearchService, WebDataService } from '../../../../_services/index';
import { config } from '../../../../../assets/config/configuration';
import { Subject } from 'rxjs';

@Component({
    moduleId: module.id,
    // tslint:disable-next-line:component-selector
    selector: 'report-preview',
    templateUrl: 'reportPreview.component.html',
    styleUrls: [ './reportPreview.component.css' ]
})

export class ReportPreviewComponent implements OnInit {

  results: any = {};
  topicCounts: any = {};
  KYClogo: any = config.KYC3logo;
  username = '';
  currentDate: any;
  firstName = '';
  lastName = '';
  dateOfBirth = '';
  country = '';
  reportResults: any = {};
  riskAssessment = '';
  riskAssessmentNote = '';
  countryRisk: any;
  riskLevel = '';
  date: Date;
  timestamp = '';
  noResults = false;

  public eventsSubject: Subject<void> = new Subject<void>();
  public reportResultsSubject: Subject<void> = new Subject<void>();
  public riskSubject: Subject<void> = new Subject<void>();

  constructor(
    private dataService: WebDataService,
    private route: ActivatedRoute,
    private searchService: SearchService
  ) {}

  ngOnInit() {
    this.timestamp = new Date().toLocaleString();
    this.username = localStorage.getItem('username');

    this.route.queryParams.subscribe(params => {
      if (params['firstName']) {
        this.firstName = params['firstName'];
      }

      if (params['lastName']) {
        this.lastName = params['lastName'];
      }

      if (params['dateOfBirth']) {
        this.dateOfBirth = params['dateOfBirth'];
      } else {
        this.dateOfBirth = '';
      }

      if (params['country']) {
        this.country = params['country'];
      } else {
        this.country = '';
      }

      if (this.country !== undefined && this.country !== '') {
        this.searchService.countryRisk(this.country).subscribe(
          data => {
            this.countryRisk = data;
            this.countryRisk = this.countryRisk.totalRisk;
            this.dataService.setCountryRisk(this.countryRisk);
            this.dataService.setCountry(this.country);
          },
          error => {
          }
        );
      } else {
        this.dataService.setCountry(null);
        this.country = '';
        this.countryRisk = '';
      }

      this.dataService.reportResults.subscribe(message => {
        this.reportResults = message;

        if (this.reportResults.articles === undefined) {
          this.reportResults.articles = [];
        }

        if (this.reportResults.pepHits === undefined) {
          this.reportResults.pepHits = [];
        }

        if (this.reportResults.sanctionHits === undefined) {
          this.reportResults.sanctionHits = [];
        }

        // tslint:disable-next-line:max-line-length
        if (this.reportResults.articles.length === 0 && this.reportResults.pepHits.length === 0 && this.reportResults.sanctionHits.length === 0) {
          this.noResults = true;
        } else {
          this.noResults = false;
        }

        this.riskAssessment = this.reportResults.riskAssessment;
        this.riskAssessmentNote = this.reportResults.riskAssessmentNote;
        this.riskLevel = this.reportResults.riskLevel;
      });

      this.dataService.currentTopicCounts.subscribe(
        message => this.topicCounts = message
      );
    });
  }

  receiveEvents($event) {
    this.eventsSubject.next();
  }

  receiveReportResultsEvent($event) {
    this.reportResultsSubject.next();
    const svg = document.querySelector('svg') as SVGSVGElement;

    if (svg !== null) {
      const svgSize = svg.getBoundingClientRect();
      const xml = new XMLSerializer().serializeToString(svg);
      const svg64 = btoa(unescape(encodeURIComponent(xml)));
      const b64Start = 'data:image/svg+xml;base64,';
      let image64 = b64Start + svg64;
      const canvas = document.createElement('canvas');
      canvas.width = svgSize.width;
      canvas.height = svgSize.height;
      const ctx = canvas.getContext('2d');
      const img = document.createElement('img');
      img.setAttribute('src', 'data:image/svg+xml;base64,' + svg64);
      img.setAttribute('alt', 'pieChart');
      img.onload = () => {
          ctx.drawImage(img, 0, 0);
          image64 = canvas.toDataURL('image/png');
          sessionStorage.setItem('pieChart', image64);
      };
    }
  }

  receiveRiskEvent($event) {
    this.riskSubject.next();
  }
}
