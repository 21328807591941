
    <div class='modal-header'>
      <h4 class='modal-title pull-left'>{{title}}</h4>
      <button type='button' class='close pull-right' aria-label='Close' (click)='close()'>
        <span aria-hidden='true'>&times;</span>
      </button>
    </div>
    <div class='modal-body' style='min-height: 300px; overflow:hidden'>
      <div style='height: 20px'></div>
      <add-role-simple *ngIf='addRole' [entityId]='entityId' [primaryName]='primaryName' [entities]='entities' [roleTypeId]='roleTypeId'></add-role-simple>
      <add-entity-simple *ngIf='addEntity'></add-entity-simple>
      <add-document *ngIf='addDocument' [documentId]='documentId' [roles]='roles' [entityId]='entityId' [forRoleId]='roleId' [documentTypeId]='documentTypeId' [currentTodos]="currentTodos"></add-document>
      <replace-document *ngIf='replaceDocument' [roles]='roles' [entityId]='entityId' [documentId]='documentId' [forRoleId]='roleId' [documentTypeId]='documentTypeId' [validTo]='validTo'></replace-document>
      <app-document-editor *ngIf='editDocument' [mode]='mode' [documentId]='documentId' [entityId]='entityId' [roleId]='roleId' [e1Id]='e1Id' [e2Id]='e2Id' [isGeoEntity]='geoEntityFlag' [entityTypeId]='entityTypeId' [primaryName]='primaryName'></app-document-editor>
      <app-edit-basic-information *ngIf="editInformation" [basicInformation]="basicInformation" [documentData]="documentData" [entityResponseData]="entityResponseData"></app-edit-basic-information>
      <div style='height: 100px'></div>
    </div>
    <div class='modal-footer'>
    </div>
  