
    <div class='modal-header'>
      <h4 class='modal-title pull-left'>Comments History</h4>
      <button type='button' class='close pull-right' aria-label='Close' (click)='bsModalRef.hide()'>
        <span aria-hidden='true'>&times;</span>
      </button>
    </div>
    <div class='modal-body'>
      <div class='commentIsNull_description' style="text-align: center; padding-top: 20px;">
        <div><b>Would you like to add a comment?</b></div>
      </div>
      <div class="commentIsNull" style="display: flex; flex-direction: column; padding-top: 20px">
        <span class="commentIsNull_heading" style="padding-right: 10px">Comment:</span><input type='text' [(ngModel)]='comment' style='width: 100%' />
      </div>
      <div *ngIf="commentIsNullError" class="commentIsNull_input" style="margin-top: 1em;">
        <span class="badge badge-warning text-uppercase" style="padding: 1em !important; text-align: start; width: 100%; background-color: #ffc108 !important; text-wrap: wrap">The Comment Field is empty. Please add a comment before pressing yes!</span>
      </div>
      <div class='modal-footer d-flex justify-content-end pr-0' style="text-align: left; display: flex; justify-content: end; padding-top: 30px;">
        <button class='btn btn-light' style="width: 20%; padding: 2px; margin-top: -3px;" (click)='bsModalRef.hide()' >No</button>
        <button class='btn btn-success' style="width: 20%; padding: 2px; margin-top: -3px;" (click)='saveComment()' >Yes</button>
      </div>
    </div>
  