import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class ShareUserNameService {

  private eventEmitter = new Subject<any>();
  changeEmitted$ = this.eventEmitter.asObservable();

  constructor() {}

  emitChange(change: any) {
    this.eventEmitter.next(change);
  }
}
