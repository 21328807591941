/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./step-page-wrapper.component";
var styles_StepPageWrapperComponent = [];
var RenderType_StepPageWrapperComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_StepPageWrapperComponent, data: {} });
export { RenderType_StepPageWrapperComponent as RenderType_StepPageWrapperComponent };
function View_StepPageWrapperComponent_1(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵand(0, null, null, 0))], null, null); }
export function View_StepPageWrapperComponent_0(_l) { return i0.ɵvid(0, [i0.ɵqud(671088640, 1, { myRef: 0 }), (_l()(), i0.ɵand(16777216, [[1, 3], ["dynamicComponent", 2]], null, 0, null, View_StepPageWrapperComponent_1))], null, null); }
export function View_StepPageWrapperComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "app-step-page-wrapper", [], null, null, null, View_StepPageWrapperComponent_0, RenderType_StepPageWrapperComponent)), i0.ɵdid(1, 4308992, null, 0, i1.StepPageWrapperComponent, [i0.ComponentFactoryResolver], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var StepPageWrapperComponentNgFactory = i0.ɵccf("app-step-page-wrapper", i1.StepPageWrapperComponent, View_StepPageWrapperComponent_Host_0, { item: "item" }, {}, []);
export { StepPageWrapperComponentNgFactory as StepPageWrapperComponentNgFactory };
