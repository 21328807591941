import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EntityReportComponent } from './entityReport.component';
import { FormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgxOrgChartModule } from 'ngx-org-chart';
import { ChartModule, HIGHCHARTS_MODULES } from 'angular-highcharts';
import * as networkgraph from 'highcharts/modules/networkgraph.src';
import * as sankey from 'highcharts/modules/sankey.src';
import * as organization from 'highcharts/modules/organization.src';
import * as exporting from 'highcharts/modules/exporting.src';
import * as serieslabel from 'highcharts/modules/series-label.src';


@NgModule({
  declarations: [
    EntityReportComponent
  ],
  imports: [
    ChartModule,
    CommonModule,
    FormsModule,
    NgSelectModule,
    NgxOrgChartModule
  ],
  providers: [
    {
      provide: HIGHCHARTS_MODULES,
      useFactory: () => [
        networkgraph,
        sankey,
        organization,
        exporting,
        serieslabel,
      ],
    }
  ],
  exports: [
    EntityReportComponent
  ]
})
export class EntityReportModule { }
